import { FunctionComponent, ElementType } from 'react';
import Button from '../../Button';
import { Appearance, colors } from '../../../definitions';
import { Modal } from '../../Modal';
import * as Styled from './FormLayout.styles';

type FormLayoutProps = {
  isModal?: boolean;
};

type IFormLayout = FunctionComponent<FormLayoutProps> & {
  OrStatementWrapper: ElementType;
  ActionButton: ElementType;
  Row: ElementType;
  Submit: ElementType;
  FieldSet: ElementType;
  ActionWrapper: ElementType;
  ModalFooter: ElementType;
  ModalSubmit: ElementType;
  ModalPreFooterSpacer: ElementType;
};

type ActionButtonProps = {
  blurMenu?: () => void;
  onClick: () => void;
};

export const FormLayoutView: IFormLayout = ({ children, isModal }) => {
  return <Styled.Wrapper isModal={isModal}>{children}</Styled.Wrapper>;
};

const ActionButton: FunctionComponent<ActionButtonProps> = ({
  children,
  blurMenu = () => {},
  onClick,
  ...rest
}) => {
  const handleClick = () => {
    blurMenu();
    onClick();
  };
  return (
    <Button
      color={colors.elsa}
      onClick={handleClick}
      appearance={Appearance.LinkWithIcon}
      {...rest}
    >
      {children}
    </Button>
  );
};

FormLayoutView.OrStatementWrapper = Styled.OrStatementWrapper;
FormLayoutView.ActionButton = ActionButton;
FormLayoutView.Row = Styled.Row;
FormLayoutView.Submit = Styled.Submit;
FormLayoutView.FieldSet = Styled.FieldSet;
FormLayoutView.ActionWrapper = Styled.ActionWrapper;
FormLayoutView.ModalFooter = Modal.Footer;
FormLayoutView.ModalSubmit = Modal.Action;
FormLayoutView.ModalPreFooterSpacer = Modal.PreFooterSpacer;
