import { ElementType, FunctionComponent } from 'react';
import { Breadcrumb } from '../../definitions';
import * as Styled from './Breadcrumbs.styles';

export type BreadcrumbsProps = {
  items: Breadcrumb[];
};

export const buildBreadcrumb = ({
  name,
  path,
  icon,
  isCurrent = false,
}: {
  name: string;
  path: string;
  icon?: ElementType;
  isCurrent?: boolean;
}): Breadcrumb => ({
  displayName: name,
  path,
  icon,
  isCurrent,
});

export const BreadcrumbsView: FunctionComponent<BreadcrumbsProps> = ({ items, ...rest }) => (
  <Styled.Container aria-label="Back" {...rest}>
    {items.map((item: Breadcrumb, index: number) => {
      const Icon = item.icon;
      return (
        <Styled.Wrapper key={index}>
          <Styled.Crumb to={item.path} key={item.displayName} isDisabled={item.isCurrent}>
            {Icon && <Icon />}
            <span>{item.displayName}</span>
          </Styled.Crumb>
          {index + 1 < items.length && <Styled.Divider>/</Styled.Divider>}
        </Styled.Wrapper>
      );
    })}
  </Styled.Container>
);
