import styled from 'styled-components';
import { rgba } from 'polished';

export const Content = styled.div`
  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    color: ${({ theme }) => theme.palettes.placeholder};
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 0.5rem 0;
  gap: 1rem;
`;

export const WarningIcon = styled.div`
  height: 3rem;
  min-width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${({ theme }) => rgba(theme.palettes.error, 0.1)};

  svg {
    height: 2rem;
    width: auto;
    fill: ${({ theme }) => theme.palettes.error};
  }
`;
