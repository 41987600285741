import styled from 'styled-components';

export const Title = styled.h6`
  padding: 0 1rem;
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.palettes.main};
  text-transform: none;
  font-weight: inherit;
  border: 1px solid ${({ theme }) => theme.palettes.border};
  background-color: ${({ theme }) => theme.palettes.inverse};
  padding: 2rem;
  width: 23rem;
  height: 37.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
