import { FunctionComponent, useMemo, useState, useCallback, useEffect } from 'react';
import type {} from 'react-select/base';
import Select, { components, MultiValue, OptionProps, ControlProps } from 'react-select';
import { Icon } from '../../Icon';
import { Accordion } from '../../Accordion';
import { MappedTreeSelectOptions, TreeFilterOption } from '../../../definitions';
import { CustomMenu } from '../../Form/Select/Select.view';
import { TreeFilterProps } from '../FilterMenu.view';
import * as Styled from '../FilterMenu.styles';

type TreeSelectOptionProps = OptionProps & {
  onFilter: (selectedOptions: MultiValue<TreeFilterOption>) => void;
  selectedOptions: { keyList: string[] | undefined; valueList: string[] | undefined };
};

const TreeSelectOption: FunctionComponent<TreeSelectOptionProps> = ({
  data,
  onFilter,
  children,
  selectedOptions,
  ...rest
}) => {
  const { label, childrenOptions, value } = data as TreeFilterOption;
  const hasChildren = !!childrenOptions.length;
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (selectedOptions.keyList && selectedOptions.keyList.includes(value)) {
      setIsExpanded(true);
    }
  }, [selectedOptions, value]);

  const { innerProps, selectOption } = rest;

  return (
    <div>
      <Styled.TreeSelectParentContainer
        hasChildren={hasChildren}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Styled.IconWrapper isExpanded={isExpanded}>
          {hasChildren && <Icon.SmallChevron />}
        </Styled.IconWrapper>
        <p>{label}</p>
      </Styled.TreeSelectParentContainer>

      {childrenOptions.map(nestedOption => {
        const nestedInnerProps = innerProps;
        const isSelected =
          selectedOptions.valueList && selectedOptions.valueList.includes(nestedOption.value);
        nestedInnerProps.onClick = () => {
          return selectOption(nestedOption);
        };
        return (
          <Accordion isExpanded={isExpanded} key={nestedOption.value}>
            <components.Option
              {...rest}
              label={nestedOption.label}
              innerProps={{ ...nestedInnerProps, id: nestedOption.value }}
              data={data}
            >
              <Styled.TreeSelectChildContainer onClick={() => setIsExpanded(!isExpanded)}>
                <Styled.IconWrapper isExpanded={false}>
                  {isSelected && <Icon.Check />}
                </Styled.IconWrapper>
                <p>{nestedOption.label}</p>
              </Styled.TreeSelectChildContainer>
            </components.Option>
          </Accordion>
        );
      })}
    </div>
  );
};

export const TreeFilterView: FunctionComponent<TreeFilterProps> = ({
  isFetching,
  onFilter,
  options,
  selectedOptions,
  label,
  children,
}) => {
  const Control = (props: ControlProps & { label: string }) => {
    return (
      <>
        <Styled.SelectLabel isFloating={props.isFocused || props.hasValue}>
          {props.label}
        </Styled.SelectLabel>
        <components.Control {...props} />
      </>
    );
  };

  const handleFilter = useCallback(
    (selectedOptions: MultiValue<TreeFilterOption>) => {
      const mappedOptions: MappedTreeSelectOptions = selectedOptions.reduce(
        (acc: MappedTreeSelectOptions, option: TreeFilterOption) => {
          if (option.parentValue) {
            acc.keyIds.push(option.parentValue);
            acc.valueIds.push(option.value);
          }
          return acc;
        },
        { keyIds: [], valueIds: [] },
      );

      onFilter(mappedOptions);
    },
    [onFilter],
  );

  const preselectedOptions = useMemo(() => {
    const valueList = options.reduce((acc: TreeFilterOption[], option: TreeFilterOption) => {
      return [...acc, ...option.childrenOptions];
    }, []);
    return valueList.filter(option => selectedOptions.valueList?.includes(option.value));
  }, [options, selectedOptions]);

  return (
    <Styled.Container>
      <Styled.FluidRow>
        <Select
          value={preselectedOptions}
          onChange={(value: MultiValue<TreeFilterOption>) => {
            handleFilter(value);
          }}
          isDisabled={isFetching}
          options={options}
          styles={Styled.treeSelectCustomStyles}
          isClearable={true}
          isMulti={true}
          isLoading={isFetching}
          maxMenuHeight={200}
          closeMenuOnSelect={true}
          blurInputOnSelect={true}
          menuShouldScrollIntoView={false}
          components={{
            Control: (componentProps: any) => <Control {...componentProps} label={label} />,
            Option: (componentProps: any) => (
              <TreeSelectOption
                onFilter={handleFilter}
                {...componentProps}
                selectedOptions={selectedOptions}
              />
            ),
            MenuList: (componentProps: any) => (
              <CustomMenu nestedComponents={children} {...componentProps} />
            ),
          }}
        />
      </Styled.FluidRow>
    </Styled.Container>
  );
};
