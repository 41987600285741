import { ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const registerFlow = (payload = {}): ActionType => ({
  type: actionTypes.flow.register,
  payload,
});

export const unregisterFlow = (payload = {}): ActionType => ({
  type: actionTypes.flow.unregister,
  payload,
});

export const setStep = (payload = {}): ActionType => ({
  type: actionTypes.flow.setStep,
  payload,
});

export const nextStep = (): ActionType => ({
  type: actionTypes.flow.nextStep,
});

export const previousStep = (): ActionType => ({
  type: actionTypes.flow.previousStep,
});

export const skipStep = (): ActionType => ({
  type: actionTypes.flow.skipStep,
});
