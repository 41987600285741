import { all, takeLatest, select, put, AllEffect, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  CreateTagRequest,
  AddTagValueRequest,
  LinkTagRequest,
  ListTagsRequest,
  TagTarget,
  UpdateTagRequest,
  UpdateTagValueRequest,
  TagValue,
  DeleteTagValueRequest,
  DeleteTagRequest,
  GetTagByKeyRequest,
  UnlinkTagRequest,
  GetTagRequest,
  GetTagByKeyValueRequest,
} from '../../grpc/grpcweb/tag_pb';
import { PageRequest, TextItem } from '../../grpc/grpccommon/common_pb';
import services from '../../services';
import {
  SequenceType,
  request,
  ActionType,
  AlertType,
  TagFormData,
  paths,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { TAG_LIST_PAGE_SIZE } from '../constants/pageSize';
import {
  getAccessToken,
  getCreateNewTagError,
  getFetchAllTagsError,
  getUpdateTagError,
  getLinkTagToComponentError,
  getLinkTagToSloError,
  getTagListNextPageToken,
  getTagListPrevPageToken,
  getFetchTagListPrevPageError,
  getFetchTagListNextPageError,
  getUpdateTagValueError,
  getDeleteTagValueError,
  getDeleteTagError,
  getFetchTagByKeyError,
  getTagListCurrPageToken,
  getFetchAllTagsForSearchError,
  getUnlinkTagFromComponentError,
  getFetchTagError,
  getFetchTagByKeyAndValueError,
} from '../selectors';
import {
  showAlert,
  fetchTagList,
  fetchSLO,
  resetNewTag,
  clearSuccess,
  fetchComponentById,
} from '../actions';
import { RootState } from '../reducers';

function* createNewTag(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to create a new tag.',
      }),
    );
  }

  const { tagKey, values, description }: TagFormData = action.payload;

  const newTagRequest = new CreateTagRequest();
  newTagRequest.setKey(tagKey);
  const newValues = values.map(val => val.value);
  newTagRequest.setValuesList(newValues);

  if (description) {
    const descriptionObject = new TextItem();
    descriptionObject.setValue(description);
    newTagRequest.setDescription(descriptionObject);
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.created },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .createNewTag(token, newTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchTagList());
    yield put(resetNewTag());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `"${tagKey} tag successfully created.`,
      }),
    );
    yield put(push(paths.tags));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getCreateNewTagError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* updateTag(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update tag.',
      }),
    );
  }

  const { formData, tagId }: { formData: TagFormData; tagId?: string } = action.payload;

  if (!tagId) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No tagId provided to update tag.',
      }),
    );
  }

  const { tagKey, description } = formData;

  const updateTagRequest = new UpdateTagRequest();
  tagId && updateTagRequest.setTagid(tagId);

  if (description) {
    const descriptionObject = new TextItem();
    descriptionObject.setValue(description);
    updateTagRequest.setDescription(descriptionObject);
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.updated },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .updateTag(token, updateTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchTagList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `"${tagKey}" tag successfully updated.`,
      }),
    );
    yield put(push(paths.tags));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateTagError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteTag(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete tag.',
      }),
    );
  }

  const { tagId, tagKey }: { tagId?: string; tagKey: string } = action.payload;

  if (!tagId) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No tagId provided to delete tag.',
      }),
    );
  }

  const deleteTagRequest = new DeleteTagRequest();
  tagId && deleteTagRequest.setTagid(tagId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .deleteTag(token, deleteTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchTagList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `"${tagKey}" tag successfully deleted.`,
      }),
    );
    yield put(push(paths.tags));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteTagError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* linkTagToComponent(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to link the tag.',
      }),
    );
  }

  const {
    tagId,
    componentId,
    valueId,
  }: {
    valueId?: string;
    tagId?: string;
    componentId: string;
  } = action.payload;

  const linkTagRequest = new LinkTagRequest();
  tagId && linkTagRequest.setTagid(tagId);
  linkTagRequest.setTarget(TagTarget.TAG_TARGET_COMPONENT);
  linkTagRequest.setTargetid(componentId);
  valueId && linkTagRequest.setValueid(valueId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.components.linked },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .linkTag(token, linkTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchComponentById(componentId));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Tag succesfully added to component.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getLinkTagToComponentError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* unlinkTagFromComponent(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to remove the tag.',
      }),
    );
  }

  const {
    tagId,
    componentId,
    valueId,
  }: {
    valueId: string;
    tagId: string;
    componentId: string;
  } = action.payload;

  const unlinkTagRequest = new UnlinkTagRequest();
  unlinkTagRequest.setTagid(tagId);
  unlinkTagRequest.setTarget(TagTarget.TAG_TARGET_COMPONENT);
  unlinkTagRequest.setTargetid(componentId);
  unlinkTagRequest.setValueid(valueId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.components.unlinked },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .unlinkTag(token, unlinkTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchComponentById(componentId));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Tag succesfully removed from component.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUnlinkTagFromComponentError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* addValueToTag(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to add value to tag.',
      }),
    );
  }

  const { value, tagId }: { value: string; tagId?: string } = action.payload;

  if (!tagId) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No tagId provided to add tag value.',
      }),
    );
  }

  const addValueRequest = new AddTagValueRequest();
  tagId && addValueRequest.setTagid(tagId);
  addValueRequest.setValue(value);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.values.created },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .addValueToTag(token, addValueRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchTagList());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `"${value}" value succesfully added to the tag.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateTagError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* updateTagValue(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update tag value.',
      }),
    );
  }

  const { value, tagId }: { value: TagValue.AsObject; tagId?: string } = action.payload;

  if (!tagId) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No tagId provided to update tag value.',
      }),
    );
  }

  const updateValueRequest = new UpdateTagValueRequest();
  tagId && updateValueRequest.setTagid(tagId);
  updateValueRequest.setValue(value.value);
  updateValueRequest.setValueid(value.id);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.values.updated },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .updateTagValue(token, updateValueRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchTagList());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `"${value.value}" value succesfully updated.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateTagValueError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteTagValue(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete tag value.',
      }),
    );
  }

  const { value, tagId }: { value: TagValue.AsObject; tagId?: string } = action.payload;

  if (!tagId) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No tagId provided to delete tag value.',
      }),
    );
  }

  const deleteValueRequest = new DeleteTagValueRequest();
  tagId && deleteValueRequest.setTagid(tagId);
  deleteValueRequest.setValueid(value.id);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.values.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .deleteTagValue(token, deleteValueRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchTagList());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `"${value.value}" value succesfully deleted.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteTagValueError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* linkTagToSlo(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to link tag',
      }),
    );
  }

  const { valueId, tagId, sloId } = action.payload;

  const linkTagRequest = new LinkTagRequest();
  linkTagRequest.setTagid(tagId);
  linkTagRequest.setTarget(TagTarget.TAG_TARGET_SLO);
  linkTagRequest.setTargetid(sloId);
  linkTagRequest.setValueid(valueId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.slos.linked },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .linkTag(token, linkTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchSLO(sloId));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `"Tag succesfully linked to SLO.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getLinkTagToSloError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchTagByKey(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch tag.',
      }),
    );
  }

  const tagKey = action.payload;
  const getTagRequest: GetTagByKeyRequest = new GetTagByKeyRequest();

  getTagRequest.setKey(tagKey);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.fetchedByKey },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .fetchByKey(token, getTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchTagByKeyError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchTagByKeyAndValue(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch tag.',
      }),
    );
  }

  const { keyId, valueId }: { keyId: string; valueId: string } = action.payload;
  const getTagRequest: GetTagByKeyValueRequest = new GetTagByKeyValueRequest();

  getTagRequest.setTagid(keyId);
  getTagRequest.setValueid(valueId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.fetchedByKeyAndValue },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .fetchByKeyAndValue(token, getTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchTagByKeyAndValueError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchTag(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch tag.',
      }),
    );
  }

  const tagId = action.payload;
  const getTagRequest: GetTagRequest = new GetTagRequest();

  getTagRequest.setTagid(tagId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.current.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .fetchTag(token, getTagRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchTagError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchAllTagsForSearch(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch tag list.',
      }),
    );
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.tags.fetchedAll },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .fetchAllForSearch(token)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchAllTagsForSearchError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchTags(
  action: ActionType,
  successAction: string,
  errorGetter: (state: RootState) => string,
  pageToken?: string,
) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch tag list.',
      }),
    );
  }

  const listTagsRequest: ListTagsRequest = new ListTagsRequest();
  const pageSize = action.payload || TAG_LIST_PAGE_SIZE;

  const pageData = new PageRequest();
  pageData.setPagesize(pageSize);
  pageToken && pageData.setPagetoken(pageToken);
  listTagsRequest.setPagerequest(pageData);

  const { sequence } = yield request(
    { rootAction: action, type: successAction },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tagService }) =>
          tagService
            .fetchList(token, listTagsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(errorGetter);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchList(action: ActionType) {
  const currPageToken = yield select(getTagListCurrPageToken);

  yield call(fetchTags, action, actionTypes.tags.fetchedList, getFetchAllTagsError, currPageToken);
}

function* fetchListNextPage(action: ActionType) {
  const nextPageToken = yield select(getTagListNextPageToken);

  yield call(
    fetchTags,
    action,
    actionTypes.tags.fetchedNextPage,
    getFetchTagListNextPageError,
    nextPageToken,
  );
}

function* fetchListPrevPage(action: ActionType) {
  const prevPageToken = yield select(getTagListPrevPageToken);

  yield call(
    fetchTags,
    action,
    actionTypes.tags.fetchedNextPage,
    getFetchTagListPrevPageError,
    prevPageToken,
  );
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.tags.current.fetchByKey, fetchTagByKey)]);
  yield all([takeLatest(actionTypes.tags.current.fetchByKeyAndValue, fetchTagByKeyAndValue)]);
  yield all([takeLatest(actionTypes.tags.current.fetch, fetchTag)]);
  yield all([takeLatest(actionTypes.tags.create, createNewTag)]);
  yield all([takeLatest(actionTypes.tags.fetchAll, fetchAllTagsForSearch)]);
  yield all([takeLatest(actionTypes.tags.fetchList, fetchList)]);
  yield all([takeLatest(actionTypes.tags.fetchNextPage, fetchListNextPage)]);
  yield all([takeLatest(actionTypes.tags.fetchPrevPage, fetchListPrevPage)]);
  yield all([takeLatest(actionTypes.tags.current.update, updateTag)]);
  yield all([takeLatest(actionTypes.tags.current.delete, deleteTag)]);
  yield all([takeLatest(actionTypes.tags.current.values.create, addValueToTag)]);
  yield all([takeLatest(actionTypes.tags.current.values.update, updateTagValue)]);
  yield all([takeLatest(actionTypes.tags.current.values.delete, deleteTagValue)]);
  yield all([takeLatest(actionTypes.tags.components.link, linkTagToComponent)]);
  yield all([takeLatest(actionTypes.tags.components.unlink, unlinkTagFromComponent)]);
  yield all([takeLatest(actionTypes.tags.slos.link, linkTagToSlo)]);
}
