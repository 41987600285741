import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0.5rem;
  color: ${props => props.theme.palettes.main};
`;

export const Author = styled.p`
  display: flex;
  flex-direction: column;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 0.75rem;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  align-items: baseline;
  gap: 0.5rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
