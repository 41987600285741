import { FunctionComponent, useMemo } from 'react';
import { useTransition } from 'react-spring';
import { AlertObject } from '../../definitions';
import { Icon } from '../Icon';
import * as Styled from './Alert.styles';

export type AlertProps = {
  items?: AlertObject[];
  onClose?: (item: AlertObject) => void;
};

interface MessageHubProps {
  config?: {
    tension: number;
    friction: number;
    precision: number;
  };
  timeout?: number;
  items: AlertObject[];
  onClose?: (item: AlertObject) => void;
}

function MessageHub({
  onClose,
  config = { tension: 125, friction: 20, precision: 0.1 },
  timeout = 3000,
  items,
}: MessageHubProps) {
  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0, life: '100%' },
    keys: item => item.key,
    enter: item => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ opacity: 1, height: refMap.get(item).offsetHeight });
      await next({ life: '0%' });
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    onRest: (result, ctrl, item) => {
      onClose && onClose(item);
    },
    config: (item, index, phase) => key =>
      phase === 'enter' && key === 'life' ? { duration: timeout } : config,
  });

  return (
    <Styled.Container>
      {transitions(({ life, ...style }, item) => (
        <Styled.Message style={style}>
          <Styled.Content
            ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
            type={item.type}
          >
            <Styled.Life style={{ right: life }} type={item.type} />
            <p>{item.message}</p>
            <Styled.Button
              onClick={e => {
                e.stopPropagation();
                if (cancelMap.has(item) && life.get() !== '0%') cancelMap.get(item)();
              }}
            >
              <Icon.Cross />
            </Styled.Button>
          </Styled.Content>
        </Styled.Message>
      ))}
    </Styled.Container>
  );
}

export const AlertView: FunctionComponent<AlertProps> = ({ items = [], onClose }) => {
  return <MessageHub items={items} onClose={onClose} />;
};
