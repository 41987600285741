import { all, takeLatest, select, put, AllEffect } from 'redux-saga/effects';
import { replace, push, getLocation, createMatchSelector } from 'connected-react-router';
import { generatePath, matchPath } from 'react-router';
import { ActionType, FlowStepObject } from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { nextStep } from '../actions';
import { getCurrentStep, getFlowReturnPath, getPreviousStep } from '../selectors';

function* registerFlow(action: ActionType) {
  const state = yield select();
  const location: ReturnType<typeof getLocation> = yield select(getLocation);
  const uri = location.pathname + location.hash;
  const { steps }: { steps: FlowStepObject[] } = action.payload;

  const filteredSteps = steps
    .filter(step => {
      if (typeof step.available !== 'function') {
        return true;
      }

      return step.available(state);
    })
    // Remove the component and available keys because we don't want functions in the store
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ component, available, ...step }) => step);

  let index = filteredSteps.findIndex(
    ({ path }: FlowStepObject) => path && matchPath(uri, { path, exact: true })?.isExact,
  );

  if (index === -1) {
    index = 0;
  }

  let currentStep = filteredSteps[index];

  if (currentStep) {
    if (!currentStep.id) {
      index = 0;
    }

    if (currentStep.parent) {
      index = steps.findIndex(({ id }) => id === currentStep.parent);
    }
  }

  currentStep = filteredSteps[index];

  if (currentStep.path && uri !== currentStep.path) {
    yield put(replace(currentStep.path));
  }

  yield put({
    type: actionTypes.flow.registered,
    payload: {
      ...action.payload,
      index,
      steps: filteredSteps,
    },
  });
}

function* navigateFlow() {
  const location: ReturnType<typeof getLocation> = yield select(getLocation);
  const currentStep: ReturnType<typeof getCurrentStep> = yield select(getCurrentStep);
  const previousStep: ReturnType<typeof getPreviousStep> = yield select(getPreviousStep);
  const returnPath: ReturnType<typeof getFlowReturnPath> = yield select(getFlowReturnPath);

  if (!currentStep.id) {
    yield put({ type: actionTypes.flow.complete });
    yield put(push(returnPath));
    return;
  }

  if (currentStep.path && currentStep.path !== location.pathname) {
    const matchSelector = createMatchSelector(previousStep.path);
    const match = yield select(matchSelector);

    const url = generatePath(currentStep.path, match ? match.params : {});

    yield put(replace(url));
  }
}

function* skipStep() {
  const currentStep: ReturnType<typeof getCurrentStep> = yield select(getCurrentStep);
  const returnPath: ReturnType<typeof getFlowReturnPath> = yield select(getFlowReturnPath);

  if (currentStep.skip) {
    if (typeof currentStep.skip === 'string') {
      yield put(replace(currentStep.skip));
      return;
    }

    yield put(replace(returnPath));
  } else {
    yield put(nextStep());
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([
    takeLatest(actionTypes.flow.register, registerFlow),
    takeLatest(actionTypes.flow.skipStep, skipStep),
    takeLatest(
      [actionTypes.flow.setStep, actionTypes.flow.nextStep, actionTypes.flow.previousStep],
      navigateFlow,
    ),
  ]);
}
