import { FunctionComponent, useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Appearance } from '../../definitions';
import { Icon } from '../Icon';
import Button from '../Button';
import PopConfirm from '../PopConfirm';
import * as Styled from './Tag.styles';

export const NewTagButton: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
  <Styled.NewTagButtonWrapper>
    <Button onClick={onClick} appearance={Appearance.Icon} title="Add tag">
      <Icon.Plus />
    </Button>
  </Styled.NewTagButtonWrapper>
);

export const TagContainer: FunctionComponent = ({ children }) => (
  <Styled.TagContainer>{children}</Styled.TagContainer>
);

export const RemoveTagButton: FunctionComponent<{
  onRemove?: () => void;
  isRemoving?: boolean;
  title?: string;
  contentText?: string;
}> = ({
  onRemove,
  isRemoving,
  contentText = 'Please note the tag will be removed after confirming.',
  title = 'Are you sure you want to remove tag?',
}) => (
  <PopConfirm
    onConfirm={onRemove ? onRemove : () => {}}
    isConfirming={isRemoving}
    isDisabled={isRemoving}
    title={title}
    contentText={contentText}
  >
    <Styled.ButtonWrapper>
      <Styled.RemoveButton>
        <Icon.Cross />
      </Styled.RemoveButton>
    </Styled.ButtonWrapper>
  </PopConfirm>
);

export const TagSelect: FunctionComponent<{
  options: string[] | undefined;
  onChange: (event: any) => void;
  value?: string;
}> = ({ options, onChange, value }) => {
  const mappedOptionsOption: { value: string; label: string }[] = useMemo(() => {
    if (options?.length) {
      return options.map((option: string) => ({ value: option, label: option }));
    } else {
      return [];
    }
  }, [options]);

  return (
    <CreatableSelect
      defaultValue={mappedOptionsOption[0]}
      value={{ value, label: value }}
      isDisabled={false}
      isLoading={false}
      isSearchable={true}
      name="color"
      menuPortalTarget={document.body}
      styles={Styled.selectStyles}
      options={mappedOptionsOption}
      placeholder={`...`}
      autoFocus={true}
      onChange={onChange}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};
