import { FunctionComponent } from 'react';
import { UpdateBotForm } from '../../forms/Bot';
import { BotFormData, ColorTheme, DrawerPosition, BotInTheList } from '../../definitions';
import { FormLayout } from '../../components/Form';
import { Drawer } from '../../components/Drawer';
import { Layout } from '../../components/Layout';
import MetaTable from '../../components/MetaTable';

type UpdateBotViewProps = {
  onClose: () => void;
  isOpen: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isFetching: boolean;
  onUpdate: (formData: BotFormData) => void;
  onDelete: () => void;
  initialValues: BotInTheList;
};

export const UpdateBotView: FunctionComponent<UpdateBotViewProps> = ({
  isOpen,
  onClose,
  onUpdate,
  onDelete,
  isUpdating,
  initialValues,
  isDeleting,
  isFetching,
}) => {
  return (
    <Drawer
      visible={isOpen}
      onHideMenu={onClose}
      position={DrawerPosition.Right}
      fullScreenOnSmall={true}
      colorTheme={ColorTheme.Light}
      title="Bot"
    >
      <FormLayout>
        <UpdateBotForm
          onSubmit={onUpdate}
          initialValues={initialValues}
          isFetching={isFetching}
          isUpdating={isUpdating}
          isDeleting={isDeleting}
          onCancel={onClose}
          onDelete={onDelete}
          hasTokens={Boolean(initialValues.tokens.length)}
        >
          <Layout.Section>
            <Layout.SectionHeading>Permissions</Layout.SectionHeading>
            <p>
              This bot has full access to RocketSLO API. Granular permission controls are coming
              soon...
            </p>
          </Layout.Section>
          <Layout.Section>
            <Layout.SectionHeading>Metadata</Layout.SectionHeading>
            <MetaTable>
              <MetaTable.SmallColumn>Created by</MetaTable.SmallColumn>
              <MetaTable.BigColumn>{initialValues.createdBy}</MetaTable.BigColumn>
              <MetaTable.SmallColumn>on</MetaTable.SmallColumn>
              <MetaTable.SmallColumn>{initialValues.createdAt}</MetaTable.SmallColumn>
            </MetaTable>
            {initialValues.updatedBy && (
              <MetaTable>
                <MetaTable.SmallColumn>Updated by</MetaTable.SmallColumn>
                <MetaTable.BigColumn>{initialValues.updatedBy}</MetaTable.BigColumn>
                <MetaTable.SmallColumn>on</MetaTable.SmallColumn>
                <MetaTable.SmallColumn>{initialValues.updatedAt}</MetaTable.SmallColumn>
              </MetaTable>
            )}
          </Layout.Section>
        </UpdateBotForm>
      </FormLayout>
    </Drawer>
  );
};
