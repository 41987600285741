import { SloStatusMap, SloStatus as SloStatusGrpc } from '../grpc/grpcweb/slo_pb';
import { SloStatus } from '../definitions';

export function getSloStatusLabel(sloStatus?: SloStatusMap[keyof SloStatusMap]): SloStatus {
  switch (sloStatus) {
    case SloStatusGrpc.SLO_STATUS_ACTIVE:
      return SloStatus.Active;
    case SloStatusGrpc.SLO_STATUS_FAILING:
      return SloStatus.Failing;
    case SloStatusGrpc.SLO_STATUS_DRAFT:
      return SloStatus.Draft;
    case SloStatusGrpc.SLO_STATUS_PAUSED:
      return SloStatus.Paused;
    default:
      return SloStatus.Unspecified;
  }
}
