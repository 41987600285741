import { FunctionComponent } from 'react';
import { paths } from '../../definitions';
import { Icon } from '../../components/Icon';
import { FormLayout } from '../../components/Form';

export const CreateNewSourceButton: FunctionComponent = props => {
  return (
    <FormLayout.ActionButton to={paths.eventSources} {...props}>
      <Icon.Edit />
      Manage event sources
    </FormLayout.ActionButton>
  );
};
