import { Tag, TagFormData, Tags } from '../../definitions';
import { TagValue } from '../../grpc/grpcweb/tag_pb';
import actionTypes from '../constants/actionTypes';
import { isLoading } from './loading';
import { RootState } from '../reducers';
import { wasSuccessful } from './success';
import { getErrorMessage } from './errors';

export const getAllTags = (state: RootState): Tags => {
  return state.tags.list;
};

export const getAllTagForSearch = (state: RootState): Tags => {
  return state.tags.allTagsForSearch;
};

export const getTagUsageInComponent = (state: RootState): string[] => {
  return state.tags.selected.usageInComponents;
};

export const isCreatingNewTag = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.create);
};

export const getTotalNumberOfTags = (state: RootState): number => {
  return state.tags.totalNumber;
};

export const getTagListNextPageToken = (state: RootState): string | undefined => {
  return state.tags.nextPageToken;
};

export const getTagListPrevPageToken = (state: RootState): string | undefined => {
  return state.tags.prevPageToken;
};

export const getTagListCurrPageToken = (state: RootState): string | undefined => {
  return state.tags.currPageToken;
};

export const getSavedNewTag = (state: RootState): TagFormData => {
  return state.tags.new;
};

export const getSelectedTag = (state: RootState): Tag => {
  return state.tags.selected;
};

export const getSelectedTagDescription = (state: RootState): string | undefined => {
  return state.tags.selected.description;
};

export const getSelectedTagValues = (state: RootState): TagValue.AsObject[] => {
  return state.tags.selected.values;
};

export const isFetchingTagByKey = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.fetchByKey);
};

export const getFetchTagByKeyError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.current.fetchByKey);
};

export const isFetchingTagByKeyAndValue = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.fetchByKeyAndValue);
};

export const getFetchTagByKeyAndValueError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.current.fetchByKeyAndValue);
};

export const isFetchingTag = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.fetch);
};

export const getFetchTagError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.current.fetch);
};

export const getCreateNewTagError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.create);
};

export const createdNewTagSuccesfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.tags.create);
};

export const isFetchingAllTagsForSearch = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.fetchAll);
};

export const isFetchingTagList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.fetchList);
};

export const isFetchingTagListNextPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.fetchNextPage);
};

export const isFetchingTagListPrevPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.fetchPrevPage);
};

export const getFetchAllTagsForSearchError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.fetchAll);
};

export const getFetchAllTagsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.fetchList);
};

export const getFetchTagListNextPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.fetchNextPage);
};

export const getFetchTagListPrevPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.fetchPrevPage);
};

export const isUpdatingTag = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.update);
};

export const updatedTagSuccesfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.tags.current.update);
};

export const getUpdateTagError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.current.update);
};

export const getDeleteTagValueError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.current.values.delete);
};

export const getUpdateTagValueError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.current.values.update);
};

export const isDeletingTag = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.delete);
};

export const getDeleteTagError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.current.delete);
};

export const isLinkingTagToComponent = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.components.link);
};

export const linkedTagToComponentSuccesfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.tags.components.link);
};

export const getLinkTagToComponentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.components.link);
};

export const isUnlinkingTagFromComponent = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.components.unlink);
};

export const getUnlinkTagFromComponentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.components.unlink);
};

export const isAddingNewValueToTag = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.values.create);
};

export const isDeletingTagValue = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.values.delete);
};

export const isUpdatingTagValue = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.current.values.update);
};

export const updatedTagValueSuccesfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.tags.current.values.update);
};

export const isLinkingTagToSlo = (state: RootState): boolean => {
  return isLoading(state, actionTypes.tags.slos.link);
};

export const getLinkTagToSloError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.tags.slos.link);
};
