export const externalLinks = {
  mailchimpUrl:
    'https://mailchi.mp/4b7a822637ac/rocketslo',
  googleSreBook: 'https://sre.google/sre-book/service-level-objectives',
  googleSloImplementation: 'https://sre.google/workbook/implementing-slos/',
  atlassianSlo: 'https://www.atlassian.com/engineering/handbook#A-combination-of-OKRs-and-SLOs',
  exvernoteSlo: 'https://www.blameless.com/sre/how-slos-transformed-evernote',
  gitlabSlo: 'https://www.youtube.com/watch?v=CbX1nZL7biQ',
  twitterSlo: 'https://dzone.com/articles/slo-adoption-at-twitter',
  facebookSlo: 'https://www.youtube.com/watch?v=p-9DB87qAnM',
  linkedIn: 'https://www.linkedin.com/company/rocketslo',
  discord: 'https://discord.gg/DaSDTaYC',
  docs: `https://docs.rocketslo.com`,
};
