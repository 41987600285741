import styled, { css } from 'styled-components';
import * as DependencyBlockStyles from './DependencyBlock';

export const SelectedComponentContainer = styled(DependencyBlockStyles.ComponentContainer)`
  margin-top: 0;
  margin-bottom: 0;

  ${props =>
    (props.isDependencySelected || props.isDependantSelected) &&
    css`
      margin-top: 0;
      margin-bottom: 0;
    `};
`;

export const Subtitle = styled.h4`
  text-align: center;
`;

export const SelectedEmpty = styled.div`
  ${DependencyBlockStyles.sharedFlexStyle};
  width: 100%;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.palettes.placeholder};
  gap: 1rem;
  text-align: center;
`;

export const ComponentWrapper = styled.div`
  ${DependencyBlockStyles.sharedFlexStyle};
  min-height: 45rem;
  background: ${props => props.theme.palettes.headerBackground};
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 1rem;
  margin-bottom: 5rem;
`;

export const ArrowWrapper = styled.div`
  ${DependencyBlockStyles.sharedFlexStyle};
  > svg {
    width: 2rem;
    height: auto;
    fill: ${props => props.theme.palettes.border};
    align-self: center;
  }
`;

export const SelectedComponentWrapper = styled.div`
  ${DependencyBlockStyles.sharedFlexStyle};
  border-radius: 100%;
  height: 15rem;
  width: 15rem;
  padding: 1rem 0;
  background: ${props => props.theme.palettes.inverse};
`;

export const RadioButtonGroupWrapper = styled.div`
  padding: 1rem 0;
`;
