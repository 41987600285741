import { FunctionComponent, useCallback, useEffect } from 'react';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { usePageTitle } from '../../hooks';
import {
  fetchTeamList,
  getTeamList,
  isFetchingTeamList,
  isFetchingTeamListNextPage,
  isFetchingTeamListPrevPage,
  fetchNextTeamListPage,
  fetchPrevTeamListPage,
  getTotalNumberOfTeams,
  getTeamListNextPageToken,
  getTeamListPrevPageToken,
  fetchTeamById,
} from '../../store';
import { paths } from '../../definitions';
import { TeamsView } from './Teams.view';

export const TeamsContainer: FunctionComponent = () => {
  usePageTitle('Teams');
  const dispatch = useDispatch();

  const isFetchingList = useSelector(isFetchingTeamList);
  const isFetchingNextPage = useSelector(isFetchingTeamListNextPage);
  const isFetchingPrevPage = useSelector(isFetchingTeamListPrevPage);

  const list = useSelector(getTeamList);
  const numberOfTeams = useSelector(getTotalNumberOfTeams);
  const prevPageToken = useSelector(getTeamListPrevPageToken);
  const nextPageToken = useSelector(getTeamListNextPageToken);

  useEffect(() => {
    dispatch(fetchTeamList());
  }, [dispatch]);

  const handleFetchNextPage = useCallback(() => {
    dispatch(fetchNextTeamListPage());
  }, [dispatch]);

  const handleFetchPrevtPage = useCallback(() => {
    dispatch(fetchPrevTeamListPage());
  }, [dispatch]);

  const handleViewTeam = useCallback(
    teamShortname => {
      teamShortname && dispatch(push(generatePath(paths.team, { teamShortname })));
    },
    [dispatch],
  );

  const handleFetchTeam = useCallback(
    teamId => {
      teamId && dispatch(fetchTeamById(teamId));
    },
    [dispatch],
  );

  return (
    <TeamsView
      isFetchingList={isFetchingList || isFetchingNextPage || isFetchingPrevPage}
      onNextPage={handleFetchNextPage}
      onPrevPage={handleFetchPrevtPage}
      numberOfTeams={numberOfTeams}
      isPrevPageDisabled={!prevPageToken}
      isNextPageDisabled={!nextPageToken}
      list={list}
      onViewTeam={handleViewTeam}
      onFetchTeam={handleFetchTeam}
    />
  );
};
