import { FunctionComponent, useState, useEffect } from 'react';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import Button from '../Button';
import { Icon } from '../Icon';
import { colors } from '../../definitions';
import * as Styled from './PopConfirm.styles';

export type PopConfirmProps = {
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
  contentText: string;
  isConfirming?: boolean;
  isCancelling?: boolean;
  confirmationText?: string;
  cancellationText?: string;
  isDisabled?: boolean;
  confirmColor?: string;
};

export const PopConfirmView: FunctionComponent<PopConfirmProps> = ({
  children,
  onCancel,
  onConfirm,
  title,
  contentText,
  confirmColor,
  isConfirming = false,
  isCancelling = false,
  confirmationText = 'Confirm',
  cancellationText = 'Cancel',
}) => {
  const [isOpen, setOpen] = useState(false);
  const [setToClose, setSetToClose] = useState(false);

  const handleCancel = () => {
    handleClose();
    onCancel && onCancel();
  };

  const handleClose = () => {
    setOpen(false);
    setSetToClose(false);
  };

  const handleConfirm = () => {
    setSetToClose(true);
    onConfirm();
  };

  useEffect(() => {
    if (setToClose && !isConfirming) {
      handleClose();
    }
  }, [setToClose, isConfirming]);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  return (
    <>
      <div {...triggerProps} onClick={() => setOpen(!isOpen)}>
        {children}
      </div>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <Styled.Title>
                <Styled.WarningIcon>
                  <Icon.WarningTriangle />
                </Styled.WarningIcon>
                <h3>{title}</h3>
              </Styled.Title>
              <Styled.Content>{contentText}</Styled.Content>
              <Styled.ButtonContainer>
                <Button onClick={handleCancel} isPending={isCancelling} isDisabled={isConfirming}>
                  {cancellationText}
                </Button>
                <Button
                  onClick={handleConfirm}
                  color={confirmColor ?? colors.strawberry}
                  isPending={isConfirming}
                  isDisabled={isCancelling}
                  isHighlighted={true}
                >
                  {confirmationText}
                </Button>
              </Styled.ButtonContainer>
              <Arrow {...arrowProps} />
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
