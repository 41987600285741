import { FunctionComponent } from 'react';
import Tooltip from '../Tooltip';
import { CopyText } from '../CopyText';
import * as Styled from './CopyEmail.styles';

export const CopyEmailView: FunctionComponent = ({ children }) => (
  <Tooltip content="Click to copy">
    <Styled.Wrapper>
      {children}
      <CopyText text="crew@rocketslo.com" />
    </Styled.Wrapper>
  </Tooltip>
);
