import { FunctionComponent } from 'react';
import { FlattenedTeamBatch } from '../../definitions';
import TeamList from '../../lists/TeamList';
import { Radio } from '../../components/BulkSelect';
import TableMeta from '../../components/TableMeta';

type AddTeamTableProps = {
  isFetching: boolean;
  selectedTeamId?: string;
  selectedTeamName?: string;
  teamList: FlattenedTeamBatch[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfTeams: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onSelectTeam: (team: FlattenedTeamBatch) => void;
  onClearSelectedTeam: () => void;
  currentTeamId?: string;
  currentTeamName?: string;
};

export const AddTeamTableView: FunctionComponent<AddTeamTableProps> = ({
  isFetching,
  selectedTeamId,
  teamList,
  onNextPage,
  onPrevPage,
  numberOfTeams,
  isPrevPageDisabled,
  isNextPageDisabled,
  onSelectTeam,
  onClearSelectedTeam,
  selectedTeamName,
  currentTeamId,
  currentTeamName,
}) => {
  const SelectBox = (team: FlattenedTeamBatch) => (
    <Radio
      item={team}
      onChange={() => onSelectTeam(team)}
      selectedItemId={selectedTeamId}
      currentItemId={currentTeamId}
    />
  );

  const getStatusTitle = (): string => {
    if (selectedTeamId && selectedTeamName) {
      if (selectedTeamId === currentTeamId) {
        return `Current owner team: ${currentTeamName}`;
      }
      return `Team selected: ${selectedTeamName}`;
    }

    if (currentTeamId) {
      return `Current owner team: ${currentTeamName}`;
    }

    return 'No team selected';
  };

  return (
    <>
      <TableMeta title={getStatusTitle()} onActionClick={onClearSelectedTeam} />
      <TeamList
        teamList={teamList}
        isFetching={isFetching}
        numberOfTeams={numberOfTeams}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
        preAction={SelectBox}
      />
    </>
  );
};
