import { FunctionComponent } from 'react';
import { Field } from 'react-final-form';
import { validation, parsers, CloudWatchAuthType } from '../../definitions';
import { Input, Radio } from '../../components/Form';

type CloudWatchFormProps = {
  authenticationType?: string;
  clickedSubmit: boolean;
};

const CloudWatchFormView: FunctionComponent<CloudWatchFormProps> = ({
  authenticationType,
  clickedSubmit,
}) => {
  return (
    <>
      <Radio.Container
        title="Authentication type"
        tooltip="Pick the most appropriate authentication method to access your CloudWatch account."
      >
        <Field
          id="authenticationType"
          name="authenticationType"
          label="Access key"
          value={CloudWatchAuthType.AccessKey.toString()}
          type="radio"
          component={Radio}
          showValidation={clickedSubmit}
        />
        <Field
          id="authenticationType"
          name="authenticationType"
          label="Assume role"
          value={CloudWatchAuthType.AssumeRole.toString()}
          type="radio"
          component={Radio}
          showValidation={clickedSubmit}
          isDisabled={true}
        />
      </Radio.Container>
      <Field
        id="region"
        name="region"
        label="Region"
        component={Input}
        validate={value => validation.required(value)}
        showValidation={clickedSubmit}
        format={parsers.trim}
        formatOnBlur={true}
        maxLength="120"
        description="Region refers to a specific geographical area, wherein a data center is facilitated and that can be found in your AWS account."
      />
      {authenticationType === CloudWatchAuthType.AssumeRole ? (
        <>
          <Field
            id="externalId"
            name="externalId"
            placeholder="External ID"
            label="External ID"
            component={Input}
            validate={value => validation.required(value)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            maxLength="120"
            tooltip="Display Name should be unique and represent the capability SLO stands for, ex. 'User can login'"
          />
          <Field
            id="rocketSLOAWSAccount"
            name="rocketSLOAWSAccount"
            placeholder="RocketSLO AWS Account Number"
            label="RocketSLO AWS Account Number"
            component={Input}
            validate={value => validation.required(value)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            maxLength="120"
            type="text"
            tooltip="Display Name should be unique and represent the capability SLO stands for, ex. 'User can login'"
          />
          <Field
            id="arn"
            name="arn"
            placeholder="ARN"
            label="ARN"
            component={Input}
            validate={value => validation.required(value)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            maxLength="120"
            tooltip="Display Name should be unique and represent the capability SLO stands for, ex. 'User can login'"
          />
        </>
      ) : (
        <>
          <Field
            id="accessKey"
            name="accessKey"
            label="Access key"
            component={Input}
            validate={value => validation.required(value)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            maxLength="120"
            description="AWS access key ID is a form of unique user/account identifier that can be found in your AWS account."
          />
          <Field
            id="secretAccessKey"
            name="secretAccessKey"
            label="Secret access key"
            component={Input}
            validate={value => validation.required(value)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            autoComplete="new-password"
            type="password"
            maxLength="120"
            description="AWS secret access key is a private key that can be found in your AWS account."
          />
        </>
      )}
    </>
  );
};

export default CloudWatchFormView;
