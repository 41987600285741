import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAddingUsersToTheTeam,
  isFetchingUsers,
  isFetchingUserListNextPage,
  isFetchingUserListPrevPage,
  addedUsersToTheTeamSuccesfully,
  addUsersToTheTeam,
  fetchAllUsers,
  getSelectedTeamId,
  getAllUsers,
  getTotalNumberOfUsers,
  getUserListPrevPageToken,
  getUserListNextPageToken,
  fetchNextUserListPage,
  fetchPrevUserListPage,
  getSelectedUserState,
  getNumberOfSelectedUsers,
  getSelectedTeamUsers,
  clearSelectedUsers,
  saveSelectedUserId,
} from '../../store';
import { USER_MEMBER_PAGE_SIZE } from '../../store/constants/pageSize';
import { AddMemberView } from './AddMember.view';

type AddMemberContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const AddMemberContainer: FunctionComponent<AddMemberContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const selectedUserState = useSelector(getSelectedUserState);
  const usersAlreadyInTheTeam = useSelector(getSelectedTeamUsers);
  const numberOfSelectedUsers = useSelector(getNumberOfSelectedUsers);

  const isAdding = useSelector(isAddingUsersToTheTeam);
  const isFetchingList = useSelector(isFetchingUsers);
  const isFetchingNextPage = useSelector(isFetchingUserListNextPage);
  const isFetchingPrevPage = useSelector(isFetchingUserListPrevPage);

  const finishedAdding = useSelector(addedUsersToTheTeamSuccesfully);

  const teamId = useSelector(getSelectedTeamId);
  const userList = useSelector(getAllUsers);
  const numberOfUsers = useSelector(getTotalNumberOfUsers);
  const prevPageToken = useSelector(getUserListPrevPageToken);
  const nextPageToken = useSelector(getUserListNextPageToken);

  const handleFetchNextPage = useCallback(() => {
    dispatch(fetchNextUserListPage(USER_MEMBER_PAGE_SIZE));
  }, [dispatch]);

  const handleFetchPrevPage = useCallback(() => {
    dispatch(fetchPrevUserListPage(USER_MEMBER_PAGE_SIZE));
  }, [dispatch]);

  const handleCheckUserCheckbox = useCallback(
    (selectedUser: Record<string, boolean>) => {
      dispatch(saveSelectedUserId(selectedUser));
    },
    [dispatch],
  );

  const handleClearSelectedUsers = useCallback(() => {
    dispatch(clearSelectedUsers());
  }, [dispatch]);

  const handleAddUsers = useCallback(() => {
    const userIds = Object.keys(selectedUserState).filter((userId: string) => {
      return selectedUserState[userId] === true;
    });
    teamId && dispatch(addUsersToTheTeam({ userIds, teamId }));
  }, [dispatch, selectedUserState, teamId]);

  useEffect(() => {
    if (isOpen) {
      dispatch(clearSelectedUsers());
      dispatch(fetchAllUsers(USER_MEMBER_PAGE_SIZE));
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (finishedAdding) {
      onClose();
    }
  }, [onClose, finishedAdding]);

  return (
    <AddMemberView
      isOpen={isOpen}
      onClose={onClose}
      isAdding={isAdding}
      isFetching={isFetchingList || isFetchingNextPage || isFetchingPrevPage}
      onAddUsers={handleAddUsers}
      userList={userList}
      numberOfUsers={numberOfUsers}
      onNextPage={handleFetchNextPage}
      onPrevPage={handleFetchPrevPage}
      isPrevPageDisabled={!prevPageToken}
      isNextPageDisabled={!nextPageToken}
      selectedUserState={selectedUserState}
      onCheckUserCheckbox={handleCheckUserCheckbox}
      numberOfSelectedUsers={numberOfSelectedUsers}
      disabledUserIds={usersAlreadyInTheTeam}
      onClearSelectedUsers={handleClearSelectedUsers}
    />
  );
};
