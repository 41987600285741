import { FunctionComponent } from 'react';
import { ComponentRefInTheList, TeamMap, GrouppedComponents } from '../../../definitions';
import DependencyBlock from '../DependencyBlock';
import { DependencyCard } from '../SharedComponents';
import * as Styled from '../DependencyBlock/DependencyBlock.styles';

export type GrouppedBlockProps = {
  components: ComponentRefInTheList[];
  grouppedComponents: GrouppedComponents;
  teamMap: TeamMap;
  onComponentClick: (component: ComponentRefInTheList) => void;
  noSelectedComponentTitle: string;
  noDependenciesTitle: string;
  isDependencySelected: boolean;
  isDependantSelected: boolean;
  selectedComponent?: ComponentRefInTheList;
  selectedComponentId: string;
  onSetSelectComponentModalOpen: (isOpen: boolean) => void;
  onViewComponent: (componentShortname: string) => void;
  isFetching: boolean;
};

export const GrouppedBlockView: FunctionComponent<GrouppedBlockProps> = ({
  components,
  grouppedComponents,
  noSelectedComponentTitle,
  noDependenciesTitle,
  onComponentClick,
  isDependencySelected,
  isDependantSelected,
  selectedComponent,
  selectedComponentId,
  onSetSelectComponentModalOpen,
  onViewComponent,
  isFetching,
  teamMap,
}) => {
  return (
    <DependencyBlock
      components={components}
      onViewComponent={onViewComponent}
      isDependencySelected={isDependencySelected}
      isDependantSelected={isDependantSelected}
      selectedComponent={selectedComponent}
      noDependenciesTitle={noDependenciesTitle}
      noSelectedComponentTitle={noSelectedComponentTitle}
      isFetching={isFetching}
      hasComponents={Boolean(Object.keys(grouppedComponents).length)}
      isGroup={Boolean(Object.keys(grouppedComponents).length) && true}
      isEmpty={!Object.keys(grouppedComponents).length}
    >
      {Object.keys(grouppedComponents).map((groupName: string) => {
        return (
          <Styled.Group key={groupName}>
            <h4>{groupName}</h4>
            <Styled.ComponentContainer
              isDependencySelected={components.length && isDependencySelected}
              isDependantSelected={components.length && isDependantSelected}
            >
              {grouppedComponents[groupName].map((component: ComponentRefInTheList) => (
                <DependencyCard
                  key={component.id}
                  component={component}
                  onNameClick={() => onComponentClick(component)}
                  onViewComponent={onViewComponent}
                  onSetSelectComponentModalOpen={onSetSelectComponentModalOpen}
                  isDependencySelected={isDependencySelected}
                  isDependantSelected={isDependantSelected}
                  isSelected={selectedComponentId === component.id}
                  shortname={component.shortname}
                />
              ))}
            </Styled.ComponentContainer>
          </Styled.Group>
        );
      })}
    </DependencyBlock>
  );
};
