import {
  useRef,
  useCallback,
  useLayoutEffect,
  FunctionComponent,
  MouseEvent,
  ElementType,
  ReactNode,
} from 'react';
import { createPortal } from 'react-dom';
import { ModalContext } from '../../contexts';
import { usePortal } from '../../hooks';
import { ModalView } from './Modal.view';
import * as Styled from './Modal.styles';

type ModalWrapperProps = {
  isOpen?: boolean;
  onClose?: () => void;
  isCloseVisible?: boolean;
  title?: string;
  maxWidth?: string;
  shouldUseSmartPositioning?: boolean;
  footer?: ReactNode;
  shouldCoverPopup?: boolean;
};

type IModalWrapper = FunctionComponent<ModalWrapperProps> & {
  Outer: ElementType;
  Inner: ElementType;
  Footer: ElementType;
  Action: ElementType;
  ButtonGroup: ElementType;
  PreFooterSpacer: ElementType;
};

export const ModalWrapperView: IModalWrapper = ({
  children,
  isOpen = false,
  onClose = () => {},
  isCloseVisible = true,
  title,
  maxWidth,
  shouldUseSmartPositioning = true,
  footer,
  shouldCoverPopup,
}) => {
  const target = usePortal('modal');
  const overlay = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    if (onClose && (e.target as HTMLDivElement).contains(wrapper.current)) {
      onClose();
    }
  };

  const scrollToTop = useCallback(() => {
    if (wrapper.current) {
      wrapper.current.scrollTop = 0;
    }
  }, [wrapper]);

  useLayoutEffect(() => {
    if (wrapper.current) {
      wrapper.current.style.overflowY = isOpen ? 'auto' : 'hidden';
    }
  }, [isOpen]);

  return createPortal(
    <ModalContext.Provider value={{ onClose, scrollToTop }}>
      {isOpen && (
        <div role="presentation" onClick={handleOutsideClick}>
          <Styled.Overlay ref={overlay} />
          <Styled.ModalWrapper
            ref={wrapper}
            aria-modal="true"
            role="modal"
            shouldCoverPopup={shouldCoverPopup}
          >
            <ModalView
              onClose={onClose}
              isCloseVisible={isCloseVisible}
              title={title}
              footer={footer}
              maxWidth={maxWidth}
              shouldUseSmartPositioning={shouldUseSmartPositioning}
            >
              {children}
            </ModalView>
          </Styled.ModalWrapper>
        </div>
      )}
    </ModalContext.Provider>,
    target,
  );
};

ModalWrapperView.Outer = Styled.Outer;
ModalWrapperView.Inner = Styled.Inner;
ModalWrapperView.Footer = Styled.InnerFooter;
ModalWrapperView.Action = Styled.Action;
ModalWrapperView.ButtonGroup = Styled.ButtonGroup;
ModalWrapperView.PreFooterSpacer = Styled.PreFooterSpacer;
