import { useMemo, useCallback } from 'react';
import { ComponentRefInTheList, GrouppedComponents } from '../definitions';

export const useGroupComponentsByType = (
  componentList: ComponentRefInTheList[],
): GrouppedComponents => {
  const groupComponent = useCallback((components: ComponentRefInTheList[]): GrouppedComponents => {
    return components.reduce(
      (groups: Record<string, Array<ComponentRefInTheList>>, component: ComponentRefInTheList) => {
        if (component.type) {
          const key = component.type;
          const group = groups[key];
          return {
            ...groups,
            [key]: [...(group || []), component],
          };
        } else {
          return groups;
        }
      },
      {},
    );
  }, []);

  return useMemo(() => {
    return groupComponent(componentList);
  }, [componentList, groupComponent]);
};
