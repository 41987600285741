import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTeamList,
  isFetchingTeamList,
  isFetchingTeamListNextPage,
  isFetchingTeamListPrevPage,
  getSelectedTeamId,
  getSelectedTeamShortname,
  getSelectedTeamDisplayName,
  fetchNextTeamListPage,
  fetchPrevTeamListPage,
  getTeamList,
  getTeamListPrevPageToken,
  getTeamListNextPageToken,
  getTotalNumberOfTeams,
  selectTeam,
  clearSelectedTeam,
  getSelectedTeam,
} from '../../store';
import { OWNER_TABLE_PAGE_SIZE } from '../../store/constants/pageSize';
import { FlattenedTeam, FlattenedTeamBatch } from '../../definitions';
import { SetOwnerView } from './SetOwner.view';

type SetOwnerContainerProps = {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (team: FlattenedTeam) => void;
  currentOwnerTeam?: FlattenedTeam;
};

export const SetOwnerContainer: FunctionComponent<SetOwnerContainerProps> = ({
  isOpen,
  onClose,
  onSubmit,
  currentOwnerTeam,
}) => {
  const dispatch = useDispatch();

  const selectedTeamId = useSelector(getSelectedTeamId);
  const selectedTeamShortname = useSelector(getSelectedTeamShortname);
  const selectedTeamDisplayName = useSelector(getSelectedTeamDisplayName);
  const selectedTeam = useSelector(getSelectedTeam);

  const isFetchingTeamsList = useSelector(isFetchingTeamList);
  const isFetchingTeamNextpage = useSelector(isFetchingTeamListNextPage);
  const isFetchingTeamPrevpage = useSelector(isFetchingTeamListPrevPage);

  const teamList = useSelector(getTeamList);
  const numberOfTeams = useSelector(getTotalNumberOfTeams);
  const prevTeamPageToken = useSelector(getTeamListPrevPageToken);
  const nextTeamPageToken = useSelector(getTeamListNextPageToken);

  const handleFetchTeamNextPage = useCallback(() => {
    dispatch(fetchNextTeamListPage(OWNER_TABLE_PAGE_SIZE));
  }, [dispatch]);

  const handleFetchTeamPrevPage = useCallback(() => {
    dispatch(fetchPrevTeamListPage(OWNER_TABLE_PAGE_SIZE));
  }, [dispatch]);

  const handleSetOwnerTeam = useCallback(() => {
    selectedTeam && onSubmit(selectedTeam);
    onClose();
  }, [selectedTeam, onClose, onSubmit]);

  const handleSelectTeam = useCallback(
    (team: FlattenedTeamBatch) => {
      team.id && dispatch(selectTeam(team));
    },
    [dispatch],
  );

  const handleClearSelectedTeam = useCallback(() => {
    dispatch(clearSelectedTeam());
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      dispatch(clearSelectedTeam());
      dispatch(fetchTeamList(OWNER_TABLE_PAGE_SIZE));
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (currentOwnerTeam) {
      dispatch(selectTeam({ ...currentOwnerTeam, memberList: [] }));
    }
  }, [dispatch, currentOwnerTeam]);

  return (
    <SetOwnerView
      isOpen={isOpen}
      onClose={onClose}
      isFetchingTeams={isFetchingTeamsList || isFetchingTeamNextpage || isFetchingTeamPrevpage}
      onSetTeam={handleSetOwnerTeam}
      teamList={teamList}
      numberOfTeams={numberOfTeams}
      onTeamNextPage={handleFetchTeamNextPage}
      onTeamPrevPage={handleFetchTeamPrevPage}
      isTeamPrevPageDisabled={!prevTeamPageToken}
      isTeamNextPageDisabled={!nextTeamPageToken}
      selectedTeamId={selectedTeamId}
      selectedTeamShortname={selectedTeamShortname}
      currentOwnerTeam={currentOwnerTeam}
      selectedTeamDisplayName={selectedTeamDisplayName}
      onSelectTeam={handleSelectTeam}
      onClearSelectedTeam={handleClearSelectedTeam}
    />
  );
};
