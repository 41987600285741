import { FunctionComponent } from 'react';
import { MetaPanel } from '../../../components/MetaPanel';
import { FlattenedTeam } from '../../../definitions';

type DashboardMetaPanelProps = {
  teams?: FlattenedTeam[];
  isCollapsed: boolean;
  onSetIsCollapsed: (newValue: boolean) => void;
};

export const DashboardMetaPanelView: FunctionComponent<DashboardMetaPanelProps> = ({
  teams,
  ...rest
}) => {
  return (
    <MetaPanel {...rest} panelTriggerTooltip="My teams">
      <MetaPanel.Header>My teams</MetaPanel.Header>
      <MetaPanel.Content>{teams && teams.map(team => <p>{team.displayname}</p>)}</MetaPanel.Content>
    </MetaPanel>
  );
};
