import { FunctionComponent } from 'react';
import { Layout } from '../../../../components/Layout';
import Button from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';
import { Appearance, ComponentRefInTheList, TeamMap } from '../../../../definitions';
import PopConfirm from '../../../../components/PopConfirm';
import GrouppedComponents from '../../../../components/GrouppedComponents';

type DependencySectionProps = {
  isLoading: boolean;
  components: ComponentRefInTheList[];
  description: string;
  emptyStateText: string;
  onOpenModal: () => void;
  onViewComponent: (component: ComponentRefInTheList) => void;
  modalTriggerTitle: string;
  title: string;
  unlinkActionTitle: string;
  onUnlinkClick: (id: string) => void;
  isUnlinking: boolean;
  teamMap: TeamMap;
};

export const DependencySectionView: FunctionComponent<DependencySectionProps> = ({
  isLoading,
  components,
  description,
  emptyStateText,
  onViewComponent,
  onOpenModal,
  modalTriggerTitle,
  title,
  unlinkActionTitle,
  onUnlinkClick,
  isUnlinking,
  teamMap,
}) => {
  const LinkDependencyButton: FunctionComponent<{ isCollapsible?: boolean }> = ({
    isCollapsible = false,
  }) => (
    <Button
      onClick={onOpenModal}
      appearance={
        isCollapsible ? Appearance.PrimaryWithIconCollapsible : Appearance.PrimaryWithIcon
      }
    >
      <Icon.Link />
      <span>{modalTriggerTitle}</span>
    </Button>
  );

  const UnLinkDependencyButton: FunctionComponent<ComponentRefInTheList> = ({ id }) => (
    <PopConfirm
      key="unlink-dependency-btn"
      title={`Are you sure you want to remove the dependency?`}
      onConfirm={() => onUnlinkClick(id)}
      isConfirming={isUnlinking}
      contentText="Please note that after confirming the component dependency will be lost. You will be able to link the component back at any time."
    >
      <Button appearance={Appearance.PrimaryWithIcon} disabled={isUnlinking}>
        <Icon.Unlink />
        <span>{unlinkActionTitle}</span>
      </Button>
    </PopConfirm>
  );

  return (
    <>
      <Layout.Heading
        actions={
          components.length
            ? [<LinkDependencyButton isCollapsible={true} key="link-dependency-btn" />]
            : []
        }
        subheading={description}
      >
        {title}
      </Layout.Heading>
      <GrouppedComponents
        emptyActions={[<LinkDependencyButton key="link-dependency-btn" />]}
        onNameClick={onViewComponent}
        widgetAction={UnLinkDependencyButton}
        isLoading={isLoading}
        components={components}
        teamMap={teamMap}
        emptyStateText={emptyStateText}
      />
    </>
  );
};
