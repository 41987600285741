import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TagValue } from '../../grpc/grpcweb/tag_pb';
import {
  getSavedNewTag,
  updateNewTag,
  isCreatingNewTag,
  resetNewTag,
  createTag,
  isFetchingTagList,
  updateNewTagValues,
  createdNewTagSuccesfully,
} from '../../store';
import { usePageTitle } from '../../hooks';
import { FormStateType, TagFormData } from '../../definitions';
import { CreateTagView } from './CreateTag.view';

type CreateTagContainerProps = {
  visible: boolean;
  onHide: () => void;
};

export const CreateTagContainer: FunctionComponent<CreateTagContainerProps> = ({
  visible,
  onHide,
}) => {
  const dispatch = useDispatch();
  usePageTitle('Create tag');

  const savedFormData = useSelector(getSavedNewTag);
  const isFetching = useSelector(isFetchingTagList);
  const isCreating = useSelector(isCreatingNewTag);

  const createdTagSuccesfully = useSelector(createdNewTagSuccesfully);

  const [tagValues, setTagValues] = useState(savedFormData.values);
  const [newValueErrorMessage, setNewValueErrorMessage] = useState<string | undefined>(undefined);
  const [existingValueErrorMessage, setExistingValueErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(true);

  useEffect(() => {
    setTagValues(savedFormData.values);
  }, [savedFormData]);

  const handleUpdateNewTagValues = useCallback(
    (values: TagValue.AsObject[]) => {
      dispatch(updateNewTagValues(values));
    },
    [dispatch],
  );

  const handleUpdateValues = useCallback(
    (newValues: TagValue.AsObject[]) => {
      setTagValues(newValues);
      handleUpdateNewTagValues(newValues);
    },
    [handleUpdateNewTagValues],
  );

  const handleCreateTag = useCallback(
    (formData: TagFormData) => {
      dispatch(createTag(formData));
    },
    [dispatch],
  );

  const handleUpdateTagState = useCallback(
    (formData: FormStateType | TagFormData) => {
      dispatch(updateNewTag(formData));
    },
    [dispatch],
  );

  const handleReset = useCallback(() => {
    setClickedSubmit(false);
    dispatch(resetNewTag());
  }, [dispatch]);

  useEffect(() => {
    if (createdTagSuccesfully) {
      handleUpdateValues([]);
      handleClearAllErrors();
      setClickedSubmit(false);
    }
  }, [createdTagSuccesfully, handleUpdateValues]);

  const handleClearAllErrors = () => {
    setNewValueErrorMessage(undefined);
    setExistingValueErrorMessage(undefined);
  };

  const handleCreateNewValue = (newValue: string) => {
    handleClearAllErrors();
    const hasDuplication = Boolean(tagValues.filter(val => val.value === newValue).length);

    if (hasDuplication) {
      setNewValueErrorMessage(`Value must be unique. Tag value ${newValue} already exists.`);
      return true;
    } else if (!newValue) {
      setNewValueErrorMessage(`Value can't be an empty string.`);
      return true;
    } else {
      handleUpdateValues([...tagValues, { value: newValue, id: '' }]);
    }
  };

  const handleRemoveValue = (value: TagValue.AsObject) => {
    handleClearAllErrors();
    const newValues = tagValues.filter(val => val.value !== value.value);
    handleUpdateValues(newValues);
  };

  const handleUpdateValue = (value: TagValue.AsObject, prevValue: TagValue.AsObject) => {
    handleClearAllErrors();

    const hasDuplication = Boolean(tagValues.filter(val => val.value === value.value).length);
    if (prevValue.value === value.value) {
      return false;
    } else if (hasDuplication) {
      setExistingValueErrorMessage(`Value must be unique. Tag value ${value} already exists.`);
      return true;
    } else if (!value.value) {
      setExistingValueErrorMessage(
        `Value can't be an empty string. Please use "Remove" button if you want to delete it instead.`,
      );
      return true;
    } else {
      const restValues = tagValues.filter(val => val.value !== prevValue.value);
      handleUpdateValues([...restValues, value]);
    }
  };

  const handleSubmit = (formData: TagFormData) => {
    const formDataWithValues = { ...formData, values: tagValues };
    handleCreateTag(formDataWithValues);
  };

  return (
    <CreateTagView
      isCreating={isCreating}
      isFetching={isFetching}
      savedFormData={savedFormData}
      onUpdateTagState={handleUpdateTagState}
      onReset={handleReset}
      visible={visible}
      onHide={onHide}
      onUpdateValues={handleUpdateValues}
      tagValues={tagValues}
      existingValueErrorMessage={existingValueErrorMessage}
      newValueErrorMessage={newValueErrorMessage}
      onUpdateValue={handleUpdateValue}
      onRemoveValue={handleRemoveValue}
      onCreateNewValue={handleCreateNewValue}
      onClearAllErrors={handleClearAllErrors}
      onSubmit={handleSubmit}
      clickedSubmit={clickedSubmit}
      onSetClickedSubmit={(clicked: boolean) => setClickedSubmit(clicked)}
    />
  );
};
