const number = (value: string | number): string => {
  const number = String(value).replace(/\D/g, '');

  if (!number) {
    return '';
  }

  return number;
};

export default number;
