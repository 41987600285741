import { FunctionComponent, InputHTMLAttributes } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Appearance, FlattenedTeam } from '../../../definitions';
import { InputStyles } from '../../../components/Form/Input';
import { Icon } from '../../../components/Icon';
import Button from '../../../components/Button';
import AvatarWithTitle from '../../../components/AvatarWithTitle';
import * as Styled from './SetOwnerInput.styles';

type SetOwnerInputProps = InputHTMLAttributes<HTMLInputElement> &
  FieldRenderProps<string> & {
    remoteErrorMessage?: string;
    showValidation?: boolean;
    description?: string;
    onOpenSetOwnerModal: () => {};
  };

export const SetOwnerInputView: FunctionComponent<SetOwnerInputProps> = ({
  meta,
  input,
  remoteErrorMessage,
  showValidation,
  description,
  onOpenSetOwnerModal,
}) => {
  const SetOwnerButton: FunctionComponent<{ title: string }> = ({ title }) => (
    <Button onClick={onOpenSetOwnerModal} appearance={Appearance.PrimaryWithIcon}>
      <Icon.AddTeam />
      <span>{title}</span>
    </Button>
  );

  const getOwnerTeamDisplay = (isValid: boolean, team?: any) => {
    const typedInput: FlattenedTeam | undefined = team;
    if (typedInput && typedInput.id) {
      return (
        <AvatarWithTitle
          name={typedInput.displayname || typedInput.shortname || ''}
          avatarSrc={typedInput.avatarUrl}
        />
      );
    }

    return (
      <Styled.EmptyTeam isValid={isValid}>
        <p>No owner team set.</p>
      </Styled.EmptyTeam>
    );
  };

  const errorMessage =
    (meta.touched && meta.error && 'Component must have an owner team.') ||
    (!meta.dirtySinceLastSubmit &&
      (meta.submitError || remoteErrorMessage) &&
      'Component must have an owner team.');

  const validationMessage = (
    <InputStyles.Message type="error" isHidden={!showValidation}>
      {errorMessage}
    </InputStyles.Message>
  );

  return (
    <Styled.OwnerTeamColumn>
      {description && <InputStyles.Description>{description}</InputStyles.Description>}
      {getOwnerTeamDisplay(!errorMessage, input.value)}
      <SetOwnerButton title={input.value ? 'Change owner team' : 'Set owner team'} />
      <InputStyles.MessageWrapper>{validationMessage}</InputStyles.MessageWrapper>
    </Styled.OwnerTeamColumn>
  );
};
