import styled from 'styled-components';

type RowProps = {
  maxWidth?: string;
};

export const Wrapper = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<RowProps>`
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${props => props.maxWidth};
  input {
    max-width: 26rem;
  }
`;
