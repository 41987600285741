import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSavedNewTeam,
  createdTeamSuccessfully,
  isCreatingTeam,
  createTeam,
  resetNewTeamState,
  updateNewTeam,
} from '../../store';
import { FormStateType, TeamFormData } from '../../definitions';
import { CreateTeamView } from './CreateTeam.view';

type CreateTeamContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateTeamContainer: FunctionComponent<CreateTeamContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isCreating = useSelector(isCreatingTeam);
  const finishedCreating = useSelector(createdTeamSuccessfully);
  const savedFormData = useSelector(getSavedNewTeam);

  const handleCreateTeam = useCallback(
    (formData: TeamFormData) => {
      dispatch(createTeam(formData));
    },
    [dispatch],
  );

  const handleUpdateTeamState = useCallback(
    (formData: FormStateType) => {
      dispatch(updateNewTeam(formData));
    },
    [dispatch],
  );

  const handleResetTeamState = useCallback(() => {
    dispatch(resetNewTeamState());
  }, [dispatch]);

  useEffect(() => {
    if (finishedCreating) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedCreating]);

  return (
    <CreateTeamView
      isOpen={isOpen}
      onClose={onClose}
      isCreating={isCreating}
      onCreateTeam={handleCreateTeam}
      initialValues={savedFormData}
      onUpdateTeamState={handleUpdateTeamState}
      onResetState={handleResetTeamState}
    />
  );
};
