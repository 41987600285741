import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from '../Link';
import { Grid } from '../Grid';

export const Header = styled.div`
  background: ${({ theme }) => theme.palettes.headerBackground};
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  max-height: 5rem;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
`;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  width: calc(100% - 2rem);
  background: ${({ theme }) => theme.palettes.inverse};
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  justify-content: space-between;
  color: ${({ theme }) => theme.palettes.inverse};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  min-height: 5rem;

  ${props => props.theme.media.medium`
    width: 30rem;
  `};
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1rem 1rem 1rem;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem;
  flex-direction: column;
  color: ${({ theme }) => theme.palettes.main};
`;

export const TagWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
`;

export const AvatarWrapper = styled.div`
  max-width: calc(50% - 1rem);
`;

export const Decsription = styled.p`
  max-height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const MoreLink = styled(Link)``;

export const Event = styled(Grid.Bounds)`
  align-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
`;

export const EventItem = styled(Grid.Box)<{ isStatusItem?: boolean }>`
  padding: 0;
  color: ${props => props.theme.palettes.main};
  flex: ${props => (props.isStatusItem ? 0 : 1)};
  min-width: 6rem;
  margin: 0;
`;

export const EventContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  gap: 0.25rem;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin: 0.5rem 0;
`;

export const MoreLinkWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
