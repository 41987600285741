import { ActionType, LoginFormData } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const authenticate = (payload: LoginFormData & { letMeIn: boolean }): ActionType => ({
  type: actionTypes.auth.initialise,
  payload,
});

export const authenticateWithDemo = (payload = {}): ActionType => ({
  type: actionTypes.auth.initialiseWithDemo,
  payload,
});

export const complete = (payload = {}): ActionType => ({
  type: actionTypes.auth.completeAuth,
  payload,
});

export const checkAuth = (payload = {}): ActionType => ({
  type: actionTypes.auth.check,
  payload,
});

export const logout = (payload = {}): ActionType => ({
  type: actionTypes.auth.logout,
  payload,
});
