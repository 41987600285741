/* eslint-disable @typescript-eslint/ban-types */
type Validator = (value: any, allValues?: object, meta?: any) => any | Promise<any>;

const compose =
  (...validators: Validator[]): Validator =>
  (value: any, allValues?: object, meta?: any): string | undefined =>
    validators.reduce(
      (error: string | undefined, validator: Validator) =>
        error || validator(value, allValues, meta),
      undefined,
    );

export default compose;
