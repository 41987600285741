import { FunctionComponent } from 'react';
import { Loader } from '../../../components/Loader';
import * as Styled from './WidgetContent.styles';
import { Link } from 'src/components/Link';

type WidgetContentProps = {
  isLoading: boolean;
  description?: string;
  tagUsage: string[];
};

export const WidgetContentView: FunctionComponent<WidgetContentProps> = ({
  isLoading,
  description,
  tagUsage,
}) => {
  return (
    <Styled.WidgetContent key="tag-details">
      {isLoading ? (
        <Loader.Container>
          <Loader />
        </Loader.Container>
      ) : (
        <Styled.TextContent>
          {description && (
            <p>
              {description}
              {description.endsWith('.') ? '' : '.'}
            </p>
          )}
          {!!tagUsage.length && (
            <p>
              Used in{` `}
              <Link>
                {tagUsage.length}
                {` `}component{tagUsage.length === 1 ? '' : 's'}.
              </Link>
            </p>
          )}
        </Styled.TextContent>
      )}
    </Styled.WidgetContent>
  );
};
