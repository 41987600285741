import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import { Token } from '../../definitions';
import { FormLayout } from '../../components/Form';
import TokenForm from '../../forms/Token';
import { Loader } from 'src/components/Loader';

type CreateTokenViewProps = {
  onClose: () => void;
  isOpen: boolean;
  isCreating: boolean;
  token?: Token;
  isDeletingToken: boolean;
  onDeleteToken: () => void;
};

export const CreateTokenView: FunctionComponent<CreateTokenViewProps> = ({
  children,
  onClose,
  isCreating,
  token,
  isDeletingToken,
  onDeleteToken,
  ...rest
}) => {
  return (
    <Modal onClose={onClose} title="New token" shouldUseSmartPositioning={false} {...rest}>
      <FormLayout isModal={true}>
        {isCreating ? (
          <Loader />
        ) : (
          <TokenForm
            initialValues={token}
            isLoading={isCreating}
            isDeletingToken={isDeletingToken}
            onDeleteToken={onDeleteToken}
            onCancel={onClose}
            isNewToken={true}
          />
        )}
      </FormLayout>
    </Modal>
  );
};
