import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { Appearance } from '../../definitions';
import { Link } from '../Link';
import { RippleEffect } from '../RippleEffect';

type ContainerProps = {
  isHidden: boolean;
  color?: string;
  appearance: Appearance;
  isDisabled: boolean;
  isInverseHover?: boolean;
  isHighlighted?: boolean;
};

type ContentProps = {
  isPending: boolean;
  appearance: Appearance;
};

export const Container = styled(Link)<ContainerProps>`
  display: inline-flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  line-height: 1.25rem;
  text-decoration: none;
  opacity: 1;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 4rem;
  border-radius: 0.5rem;
  align-items: center;
  font-weight: 500;
  &&& {
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  ${props => `
    color: ${
      props.isHighlighted
        ? props.theme.palettes.inverse
        : props.color
        ? props.color
        : props.theme.palettes.main
    };
    background-color: ${
      !props.isHighlighted
        ? props.theme.palettes.inverse
        : props.color
        ? props.color
        : props.theme.palettes.main
    };
    box-shadow: ${props.theme.effects.borderShadow};
    border: 1px solid ${props.theme.palettes.border};

    &:hover {
      &&& {
        text-decoration: none;
        background-color: ${
          !props.isHighlighted
            ? darken(0.025, props.theme.palettes.inverse)
            : props.color
            ? darken(0.1, props.color)
            : lighten(0.025, props.theme.palettes.main)
        };
        color: ${
          props.isHighlighted
            ? props.theme.palettes.inverse
            : props.color
            ? props.color
            : props.theme.palettes.main
        };
        box-shadow: ${props.theme.effects.delicateShadow};
      }
    }
  }
  `}

  ${props =>
    (props.appearance === Appearance.Icon ||
      props.appearance === Appearance.Link ||
      props.appearance === Appearance.LinkWithIcon) &&
    css`
      box-shadow: none;
      border: none;
      background-color: transparent;
      &:hover {
        &&& {
          border: none;
          box-shadow: none;
          background-color: transparent;
        }
      }
    `}

    ${props =>
    (props.appearance === Appearance.Icon || props.appearance === Appearance.Link) &&
    css`
      &&& {
        color: ${props.color ? props.color : props.theme.palettes.placeholder};
      }

      &:hover {
        &&& {
          color: ${props.color
            ? props.isInverseHover
              ? lighten(0.2, props.color)
              : darken(0.1, props.color)
            : props.isInverseHover
            ? lighten(0.2, props.theme.palettes.highlight)
            : darken(0.2, props.theme.palettes.placeholder)};
        }
      }
    `}

  ${props =>
    (props.appearance === Appearance.PrimaryWithIcon ||
      props.appearance === Appearance.PrimaryWithIconCollapsible ||
      props.appearance === Appearance.LinkWithIcon) &&
    css`
      ${props.isPending &&
      css`
        &&& {
          background-color: ${props.isHighlighted
            ? props.theme.palettes.main
            : props.color
            ? props.color
            : props.theme.palettes.inverse};
        }
      `};

      svg {
        fill: ${props.isHighlighted
          ? darken(0.2, props.theme.palettes.inverse)
          : props.theme.palettes.placeholder};
      }

      &:hover {
        background-color: ${!props.isHighlighted
          ? props.theme.palettes.inverse
          : props.color
          ? props.color
          : props.theme.palettes.main};
        color: ${props.isHighlighted
          ? props.theme.palettes.inverse
          : props.color
          ? props.color
          : props.theme.palettes.main};
        svg {
          fill: ${props.isPending
            ? 'none'
            : props.isHighlighted
            ? props.theme.palettes.inverse
            : props.theme.palettes.main};
        }
      }
    `}

  ${props =>
    props.appearance === Appearance.PrimaryWithIconCollapsible &&
    css`
      min-width: 0;
      span {
        display: none;
      }
      svg {
        margin-right: 0;
      }
      ${props => props.theme.media.medium`
      span {
        display: inline;
      }
    `}
    `}

  ${props =>
    props.isHidden &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

    ${props =>
    props.isDisabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
      background-color: ${props.theme.palettes.mute};
      color: ${props.isHighlighted ? props.theme.palettes.inverse : 'auto'};
    `};

  ${props =>
    props.appearance === Appearance.Icon &&
    css`
      padding: 0;
      > div {
        margin: 0;
        padding: 0;
      }
      width: 2rem;
      min-width: 2rem;
      height: 2rem;

      svg {
        margin: 0;
      }

      &:hover {
        &&& {
          background-color: transparent;
        }
      }
    `}

  ${props =>
    props.appearance === Appearance.Funky &&
    css`
      min-height: 3rem;

      border-radius: 4rem;
      color: ${props => props.theme.palettes.main};
      background-color: ${props.color ? props.color : props.theme.palettes.highlight};
      &:hover {
        &&& {
          color: ${props => props.theme.palettes.main};
          background-color: ${props.color
            ? darken(0.2, props.color)
            : darken(0.2, props.theme.palettes.highlight)};
        }
      }
    `}
`;

export const Wrapper = styled(RippleEffect)``;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  ${props =>
    props.isPending &&
    css`
      user-select: none;
      ${props.appearance !== Appearance.Icon &&
      props.appearance !== Appearance.Link &&
      css`
        color: transparent;

        svg {
          fill: none;
        }
      `}
    `}
`;

export const LoaderWrapper = styled.div<{ isHighlighted: boolean }>`
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.75em;
  margin-left: -0.75em;
  &&& {
    svg {
      fill: ${props =>
        props.isHighlighted ? props.theme.palettes.inverse : props.theme.palettes.main};
    }
  }
`;
