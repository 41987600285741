// source: grpcweb/slo.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var grpccommon_common_pb = require('../grpccommon/common_pb.js');
goog.object.extend(proto, grpccommon_common_pb);
var grpcweb_tag_pb = require('../grpcweb/tag_pb.js');
goog.object.extend(proto, grpcweb_tag_pb);
var grpcweb_user_pb = require('../grpcweb/user_pb.js');
goog.object.extend(proto, grpcweb_user_pb);
var grpcweb_catalog_item_pb = require('../grpcweb/catalog_item_pb.js');
goog.object.extend(proto, grpcweb_catalog_item_pb);
goog.exportSymbol('proto.rct.web.Activity', null, global);
goog.exportSymbol('proto.rct.web.Activity.SloBreachEnd', null, global);
goog.exportSymbol('proto.rct.web.Activity.SloBreachStart', null, global);
goog.exportSymbol('proto.rct.web.Activity.SloObjectiveChanged', null, global);
goog.exportSymbol('proto.rct.web.Activity.SloStatusChanged', null, global);
goog.exportSymbol('proto.rct.web.Activity.TriggeredBy', null, global);
goog.exportSymbol('proto.rct.web.Activity.Type', null, global);
goog.exportSymbol('proto.rct.web.CreateSloRequest', null, global);
goog.exportSymbol('proto.rct.web.CreateSloResponse', null, global);
goog.exportSymbol('proto.rct.web.DeleteSloRequest', null, global);
goog.exportSymbol('proto.rct.web.DeleteSloResponse', null, global);
goog.exportSymbol('proto.rct.web.ErrorBudgetMethod', null, global);
goog.exportSymbol('proto.rct.web.EventQuery', null, global);
goog.exportSymbol('proto.rct.web.EventQueryType', null, global);
goog.exportSymbol('proto.rct.web.GetSloHistoryRequest', null, global);
goog.exportSymbol('proto.rct.web.GetSloHistoryResponse', null, global);
goog.exportSymbol('proto.rct.web.GetSloRequest', null, global);
goog.exportSymbol('proto.rct.web.GetSloResponse', null, global);
goog.exportSymbol('proto.rct.web.LaunchSloRequest', null, global);
goog.exportSymbol('proto.rct.web.LaunchSloResponse', null, global);
goog.exportSymbol('proto.rct.web.ListActivitiesRequest', null, global);
goog.exportSymbol('proto.rct.web.ListActivitiesResponse', null, global);
goog.exportSymbol('proto.rct.web.ListSloRequest', null, global);
goog.exportSymbol('proto.rct.web.ListSloResponse', null, global);
goog.exportSymbol('proto.rct.web.Objective', null, global);
goog.exportSymbol('proto.rct.web.SloHistoryRecord', null, global);
goog.exportSymbol('proto.rct.web.SloMetadata', null, global);
goog.exportSymbol('proto.rct.web.SloStatus', null, global);
goog.exportSymbol('proto.rct.web.SloView', null, global);
goog.exportSymbol('proto.rct.web.TimeWindow', null, global);
goog.exportSymbol('proto.rct.web.TimeWindowType', null, global);
goog.exportSymbol('proto.rct.web.TimeWindowUnit', null, global);
goog.exportSymbol('proto.rct.web.UpdateSloRequest', null, global);
goog.exportSymbol('proto.rct.web.UpdateSloResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.SloMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.SloMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.SloMetadata.displayName = 'proto.rct.web.SloMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.SloView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.SloView.repeatedFields_, null);
};
goog.inherits(proto.rct.web.SloView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.SloView.displayName = 'proto.rct.web.SloView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.SloHistoryRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.SloHistoryRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.SloHistoryRecord.displayName = 'proto.rct.web.SloHistoryRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.TimeWindow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.TimeWindow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.TimeWindow.displayName = 'proto.rct.web.TimeWindow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.EventQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.EventQuery.repeatedFields_, null);
};
goog.inherits(proto.rct.web.EventQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.EventQuery.displayName = 'proto.rct.web.EventQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Objective = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Objective, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Objective.displayName = 'proto.rct.web.Objective';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateSloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateSloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateSloRequest.displayName = 'proto.rct.web.CreateSloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateSloResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateSloResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateSloResponse.displayName = 'proto.rct.web.CreateSloResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.LaunchSloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.LaunchSloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.LaunchSloRequest.displayName = 'proto.rct.web.LaunchSloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.LaunchSloResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.LaunchSloResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.LaunchSloResponse.displayName = 'proto.rct.web.LaunchSloResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListSloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListSloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListSloRequest.displayName = 'proto.rct.web.ListSloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListSloResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ListSloResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ListSloResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListSloResponse.displayName = 'proto.rct.web.ListSloResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetSloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetSloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetSloRequest.displayName = 'proto.rct.web.GetSloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetSloResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetSloResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetSloResponse.displayName = 'proto.rct.web.GetSloResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteSloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteSloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteSloRequest.displayName = 'proto.rct.web.DeleteSloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteSloResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteSloResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteSloResponse.displayName = 'proto.rct.web.DeleteSloResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateSloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateSloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateSloRequest.displayName = 'proto.rct.web.UpdateSloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateSloResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateSloResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateSloResponse.displayName = 'proto.rct.web.UpdateSloResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetSloHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetSloHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetSloHistoryRequest.displayName = 'proto.rct.web.GetSloHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetSloHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.GetSloHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.GetSloHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetSloHistoryResponse.displayName = 'proto.rct.web.GetSloHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Activity.displayName = 'proto.rct.web.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Activity.SloStatusChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Activity.SloStatusChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Activity.SloStatusChanged.displayName = 'proto.rct.web.Activity.SloStatusChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Activity.SloObjectiveChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Activity.SloObjectiveChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Activity.SloObjectiveChanged.displayName = 'proto.rct.web.Activity.SloObjectiveChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Activity.SloBreachStart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Activity.SloBreachStart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Activity.SloBreachStart.displayName = 'proto.rct.web.Activity.SloBreachStart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Activity.SloBreachEnd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Activity.SloBreachEnd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Activity.SloBreachEnd.displayName = 'proto.rct.web.Activity.SloBreachEnd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListActivitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListActivitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListActivitiesRequest.displayName = 'proto.rct.web.ListActivitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListActivitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ListActivitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ListActivitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListActivitiesResponse.displayName = 'proto.rct.web.ListActivitiesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.SloMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.SloMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.SloMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.SloMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && grpccommon_common_pb.TextItem.toObject(includeInstance, f),
    componentid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    objective: (f = msg.getObjective()) && proto.rct.web.Objective.toObject(includeInstance, f),
    errorbudgetmethod: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timewindow: (f = msg.getTimewindow()) && proto.rct.web.TimeWindow.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.SloMetadata}
 */
proto.rct.web.SloMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.SloMetadata;
  return proto.rct.web.SloMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.SloMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.SloMetadata}
 */
proto.rct.web.SloMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = new grpccommon_common_pb.TextItem;
      reader.readMessage(value,grpccommon_common_pb.TextItem.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 5:
      var value = new proto.rct.web.Objective;
      reader.readMessage(value,proto.rct.web.Objective.deserializeBinaryFromReader);
      msg.setObjective(value);
      break;
    case 6:
      var value = /** @type {!proto.rct.web.ErrorBudgetMethod} */ (reader.readEnum());
      msg.setErrorbudgetmethod(value);
      break;
    case 7:
      var value = new proto.rct.web.TimeWindow;
      reader.readMessage(value,proto.rct.web.TimeWindow.deserializeBinaryFromReader);
      msg.setTimewindow(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.SloMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.SloMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.SloMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.SloMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpccommon_common_pb.TextItem.serializeBinaryToWriter
    );
  }
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getObjective();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.rct.web.Objective.serializeBinaryToWriter
    );
  }
  f = message.getErrorbudgetmethod();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTimewindow();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.rct.web.TimeWindow.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string shortName = 1;
 * @return {string}
 */
proto.rct.web.SloMetadata.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.rct.web.SloMetadata.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional rct.common.TextItem description = 3;
 * @return {?proto.rct.common.TextItem}
 */
proto.rct.web.SloMetadata.prototype.getDescription = function() {
  return /** @type{?proto.rct.common.TextItem} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.TextItem, 3));
};


/**
 * @param {?proto.rct.common.TextItem|undefined} value
 * @return {!proto.rct.web.SloMetadata} returns this
*/
proto.rct.web.SloMetadata.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.SloMetadata.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string componentId = 4;
 * @return {string}
 */
proto.rct.web.SloMetadata.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Objective objective = 5;
 * @return {?proto.rct.web.Objective}
 */
proto.rct.web.SloMetadata.prototype.getObjective = function() {
  return /** @type{?proto.rct.web.Objective} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Objective, 5));
};


/**
 * @param {?proto.rct.web.Objective|undefined} value
 * @return {!proto.rct.web.SloMetadata} returns this
*/
proto.rct.web.SloMetadata.prototype.setObjective = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.clearObjective = function() {
  return this.setObjective(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.SloMetadata.prototype.hasObjective = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorBudgetMethod errorBudgetMethod = 6;
 * @return {!proto.rct.web.ErrorBudgetMethod}
 */
proto.rct.web.SloMetadata.prototype.getErrorbudgetmethod = function() {
  return /** @type {!proto.rct.web.ErrorBudgetMethod} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.rct.web.ErrorBudgetMethod} value
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.setErrorbudgetmethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional TimeWindow timeWindow = 7;
 * @return {?proto.rct.web.TimeWindow}
 */
proto.rct.web.SloMetadata.prototype.getTimewindow = function() {
  return /** @type{?proto.rct.web.TimeWindow} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.TimeWindow, 7));
};


/**
 * @param {?proto.rct.web.TimeWindow|undefined} value
 * @return {!proto.rct.web.SloMetadata} returns this
*/
proto.rct.web.SloMetadata.prototype.setTimewindow = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.clearTimewindow = function() {
  return this.setTimewindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.SloMetadata.prototype.hasTimewindow = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.rct.web.SloMetadata.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.SloMetadata} returns this
 */
proto.rct.web.SloMetadata.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.SloView.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.SloView.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.SloView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.SloView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.SloView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.rct.web.SloMetadata.toObject(includeInstance, f),
    type: (f = msg.getType()) && grpcweb_catalog_item_pb.CatalogItemView.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentslovalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    grpcweb_tag_pb.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.SloView}
 */
proto.rct.web.SloView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.SloView;
  return proto.rct.web.SloView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.SloView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.SloView}
 */
proto.rct.web.SloView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.SloMetadata;
      reader.readMessage(value,proto.rct.web.SloMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 3:
      var value = new grpcweb_catalog_item_pb.CatalogItemView;
      reader.readMessage(value,grpcweb_catalog_item_pb.CatalogItemView.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.rct.web.SloStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentslovalue(value);
      break;
    case 6:
      var value = new grpcweb_tag_pb.Tag;
      reader.readMessage(value,grpcweb_tag_pb.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.SloView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.SloView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.SloView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.SloView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.SloMetadata.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpcweb_catalog_item_pb.CatalogItemView.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCurrentslovalue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      grpcweb_tag_pb.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.SloView.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.SloView} returns this
 */
proto.rct.web.SloView.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SloMetadata metadata = 2;
 * @return {?proto.rct.web.SloMetadata}
 */
proto.rct.web.SloView.prototype.getMetadata = function() {
  return /** @type{?proto.rct.web.SloMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.SloMetadata, 2));
};


/**
 * @param {?proto.rct.web.SloMetadata|undefined} value
 * @return {!proto.rct.web.SloView} returns this
*/
proto.rct.web.SloView.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.SloView} returns this
 */
proto.rct.web.SloView.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.SloView.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CatalogItemView type = 3;
 * @return {?proto.rct.web.CatalogItemView}
 */
proto.rct.web.SloView.prototype.getType = function() {
  return /** @type{?proto.rct.web.CatalogItemView} */ (
    jspb.Message.getWrapperField(this, grpcweb_catalog_item_pb.CatalogItemView, 3));
};


/**
 * @param {?proto.rct.web.CatalogItemView|undefined} value
 * @return {!proto.rct.web.SloView} returns this
*/
proto.rct.web.SloView.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.SloView} returns this
 */
proto.rct.web.SloView.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.SloView.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SloStatus status = 4;
 * @return {!proto.rct.web.SloStatus}
 */
proto.rct.web.SloView.prototype.getStatus = function() {
  return /** @type {!proto.rct.web.SloStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.rct.web.SloStatus} value
 * @return {!proto.rct.web.SloView} returns this
 */
proto.rct.web.SloView.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional double currentSloValue = 5;
 * @return {number}
 */
proto.rct.web.SloView.prototype.getCurrentslovalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.SloView} returns this
 */
proto.rct.web.SloView.prototype.setCurrentslovalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated Tag tags = 6;
 * @return {!Array<!proto.rct.web.Tag>}
 */
proto.rct.web.SloView.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.rct.web.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_tag_pb.Tag, 6));
};


/**
 * @param {!Array<!proto.rct.web.Tag>} value
 * @return {!proto.rct.web.SloView} returns this
*/
proto.rct.web.SloView.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.rct.web.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Tag}
 */
proto.rct.web.SloView.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.rct.web.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.SloView} returns this
 */
proto.rct.web.SloView.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.SloHistoryRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.SloHistoryRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.SloHistoryRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.SloHistoryRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    startms: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endms: jspb.Message.getFieldWithDefault(msg, 2, 0),
    slovalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.SloHistoryRecord}
 */
proto.rct.web.SloHistoryRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.SloHistoryRecord;
  return proto.rct.web.SloHistoryRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.SloHistoryRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.SloHistoryRecord}
 */
proto.rct.web.SloHistoryRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartms(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndms(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSlovalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.SloHistoryRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.SloHistoryRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.SloHistoryRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.SloHistoryRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartms();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndms();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSlovalue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int64 startMs = 1;
 * @return {number}
 */
proto.rct.web.SloHistoryRecord.prototype.getStartms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.SloHistoryRecord} returns this
 */
proto.rct.web.SloHistoryRecord.prototype.setStartms = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 endMs = 2;
 * @return {number}
 */
proto.rct.web.SloHistoryRecord.prototype.getEndms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.SloHistoryRecord} returns this
 */
proto.rct.web.SloHistoryRecord.prototype.setEndms = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double sloValue = 3;
 * @return {number}
 */
proto.rct.web.SloHistoryRecord.prototype.getSlovalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.SloHistoryRecord} returns this
 */
proto.rct.web.SloHistoryRecord.prototype.setSlovalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.TimeWindow.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.TimeWindow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.TimeWindow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.TimeWindow.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    starttime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.TimeWindow}
 */
proto.rct.web.TimeWindow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.TimeWindow;
  return proto.rct.web.TimeWindow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.TimeWindow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.TimeWindow}
 */
proto.rct.web.TimeWindow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rct.web.TimeWindowType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.rct.web.TimeWindowUnit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStarttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.TimeWindow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.TimeWindow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.TimeWindow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.TimeWindow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional TimeWindowType type = 1;
 * @return {!proto.rct.web.TimeWindowType}
 */
proto.rct.web.TimeWindow.prototype.getType = function() {
  return /** @type {!proto.rct.web.TimeWindowType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rct.web.TimeWindowType} value
 * @return {!proto.rct.web.TimeWindow} returns this
 */
proto.rct.web.TimeWindow.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional TimeWindowUnit unit = 2;
 * @return {!proto.rct.web.TimeWindowUnit}
 */
proto.rct.web.TimeWindow.prototype.getUnit = function() {
  return /** @type {!proto.rct.web.TimeWindowUnit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rct.web.TimeWindowUnit} value
 * @return {!proto.rct.web.TimeWindow} returns this
 */
proto.rct.web.TimeWindow.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.rct.web.TimeWindow.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.TimeWindow} returns this
 */
proto.rct.web.TimeWindow.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 startTime = 4;
 * @return {number}
 */
proto.rct.web.TimeWindow.prototype.getStarttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.TimeWindow} returns this
 */
proto.rct.web.TimeWindow.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.EventQuery.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.EventQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.EventQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.EventQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    queriesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    querytype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventsourceid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.EventQuery}
 */
proto.rct.web.EventQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.EventQuery;
  return proto.rct.web.EventQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.EventQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.EventQuery}
 */
proto.rct.web.EventQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addQueries(value);
      break;
    case 2:
      var value = /** @type {!proto.rct.web.EventQueryType} */ (reader.readEnum());
      msg.setQuerytype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventsourceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.EventQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.EventQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.EventQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getQuerytype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEventsourceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated string queries = 1;
 * @return {!Array<string>}
 */
proto.rct.web.EventQuery.prototype.getQueriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rct.web.EventQuery} returns this
 */
proto.rct.web.EventQuery.prototype.setQueriesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rct.web.EventQuery} returns this
 */
proto.rct.web.EventQuery.prototype.addQueries = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.EventQuery} returns this
 */
proto.rct.web.EventQuery.prototype.clearQueriesList = function() {
  return this.setQueriesList([]);
};


/**
 * optional EventQueryType queryType = 2;
 * @return {!proto.rct.web.EventQueryType}
 */
proto.rct.web.EventQuery.prototype.getQuerytype = function() {
  return /** @type {!proto.rct.web.EventQueryType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rct.web.EventQueryType} value
 * @return {!proto.rct.web.EventQuery} returns this
 */
proto.rct.web.EventQuery.prototype.setQuerytype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string eventSourceId = 3;
 * @return {string}
 */
proto.rct.web.EventQuery.prototype.getEventsourceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.EventQuery} returns this
 */
proto.rct.web.EventQuery.prototype.setEventsourceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Objective.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Objective.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Objective} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Objective.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    goodeventquery: (f = msg.getGoodeventquery()) && proto.rct.web.EventQuery.toObject(includeInstance, f),
    badeventquery: (f = msg.getBadeventquery()) && proto.rct.web.EventQuery.toObject(includeInstance, f),
    totaleventquery: (f = msg.getTotaleventquery()) && proto.rct.web.EventQuery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Objective}
 */
proto.rct.web.Objective.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Objective;
  return proto.rct.web.Objective.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Objective} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Objective}
 */
proto.rct.web.Objective.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.rct.web.EventQuery;
      reader.readMessage(value,proto.rct.web.EventQuery.deserializeBinaryFromReader);
      msg.setGoodeventquery(value);
      break;
    case 4:
      var value = new proto.rct.web.EventQuery;
      reader.readMessage(value,proto.rct.web.EventQuery.deserializeBinaryFromReader);
      msg.setBadeventquery(value);
      break;
    case 5:
      var value = new proto.rct.web.EventQuery;
      reader.readMessage(value,proto.rct.web.EventQuery.deserializeBinaryFromReader);
      msg.setTotaleventquery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Objective.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Objective.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Objective} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Objective.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getGoodeventquery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.EventQuery.serializeBinaryToWriter
    );
  }
  f = message.getBadeventquery();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.rct.web.EventQuery.serializeBinaryToWriter
    );
  }
  f = message.getTotaleventquery();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.rct.web.EventQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional string displayName = 1;
 * @return {string}
 */
proto.rct.web.Objective.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.Objective} returns this
 */
proto.rct.web.Objective.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double value = 2;
 * @return {number}
 */
proto.rct.web.Objective.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Objective} returns this
 */
proto.rct.web.Objective.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional EventQuery goodEventQuery = 3;
 * @return {?proto.rct.web.EventQuery}
 */
proto.rct.web.Objective.prototype.getGoodeventquery = function() {
  return /** @type{?proto.rct.web.EventQuery} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventQuery, 3));
};


/**
 * @param {?proto.rct.web.EventQuery|undefined} value
 * @return {!proto.rct.web.Objective} returns this
*/
proto.rct.web.Objective.prototype.setGoodeventquery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Objective} returns this
 */
proto.rct.web.Objective.prototype.clearGoodeventquery = function() {
  return this.setGoodeventquery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Objective.prototype.hasGoodeventquery = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EventQuery badEventQuery = 4;
 * @return {?proto.rct.web.EventQuery}
 */
proto.rct.web.Objective.prototype.getBadeventquery = function() {
  return /** @type{?proto.rct.web.EventQuery} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventQuery, 4));
};


/**
 * @param {?proto.rct.web.EventQuery|undefined} value
 * @return {!proto.rct.web.Objective} returns this
*/
proto.rct.web.Objective.prototype.setBadeventquery = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Objective} returns this
 */
proto.rct.web.Objective.prototype.clearBadeventquery = function() {
  return this.setBadeventquery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Objective.prototype.hasBadeventquery = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EventQuery totalEventQuery = 5;
 * @return {?proto.rct.web.EventQuery}
 */
proto.rct.web.Objective.prototype.getTotaleventquery = function() {
  return /** @type{?proto.rct.web.EventQuery} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventQuery, 5));
};


/**
 * @param {?proto.rct.web.EventQuery|undefined} value
 * @return {!proto.rct.web.Objective} returns this
*/
proto.rct.web.Objective.prototype.setTotaleventquery = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Objective} returns this
 */
proto.rct.web.Objective.prototype.clearTotaleventquery = function() {
  return this.setTotaleventquery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Objective.prototype.hasTotaleventquery = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateSloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateSloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateSloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateSloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slo: (f = msg.getSlo()) && proto.rct.web.SloMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateSloRequest}
 */
proto.rct.web.CreateSloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateSloRequest;
  return proto.rct.web.CreateSloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateSloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateSloRequest}
 */
proto.rct.web.CreateSloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.SloMetadata;
      reader.readMessage(value,proto.rct.web.SloMetadata.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateSloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateSloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateSloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateSloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.SloMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional SloMetadata slo = 1;
 * @return {?proto.rct.web.SloMetadata}
 */
proto.rct.web.CreateSloRequest.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.SloMetadata, 1));
};


/**
 * @param {?proto.rct.web.SloMetadata|undefined} value
 * @return {!proto.rct.web.CreateSloRequest} returns this
*/
proto.rct.web.CreateSloRequest.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateSloRequest} returns this
 */
proto.rct.web.CreateSloRequest.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateSloRequest.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateSloResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateSloResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateSloResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateSloResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    slo: (f = msg.getSlo()) && proto.rct.web.SloView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateSloResponse}
 */
proto.rct.web.CreateSloResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateSloResponse;
  return proto.rct.web.CreateSloResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateSloResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateSloResponse}
 */
proto.rct.web.CreateSloResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.SloView;
      reader.readMessage(value,proto.rct.web.SloView.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateSloResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateSloResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateSloResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateSloResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.SloView.serializeBinaryToWriter
    );
  }
};


/**
 * optional SloView slo = 1;
 * @return {?proto.rct.web.SloView}
 */
proto.rct.web.CreateSloResponse.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.SloView, 1));
};


/**
 * @param {?proto.rct.web.SloView|undefined} value
 * @return {!proto.rct.web.CreateSloResponse} returns this
*/
proto.rct.web.CreateSloResponse.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateSloResponse} returns this
 */
proto.rct.web.CreateSloResponse.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateSloResponse.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.LaunchSloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.LaunchSloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.LaunchSloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.LaunchSloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.LaunchSloRequest}
 */
proto.rct.web.LaunchSloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.LaunchSloRequest;
  return proto.rct.web.LaunchSloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.LaunchSloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.LaunchSloRequest}
 */
proto.rct.web.LaunchSloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.LaunchSloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.LaunchSloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.LaunchSloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.LaunchSloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.LaunchSloRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.LaunchSloRequest} returns this
 */
proto.rct.web.LaunchSloRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.LaunchSloResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.LaunchSloResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.LaunchSloResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.LaunchSloResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.LaunchSloResponse}
 */
proto.rct.web.LaunchSloResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.LaunchSloResponse;
  return proto.rct.web.LaunchSloResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.LaunchSloResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.LaunchSloResponse}
 */
proto.rct.web.LaunchSloResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.LaunchSloResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.LaunchSloResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.LaunchSloResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.LaunchSloResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListSloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListSloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListSloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListSloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListSloRequest}
 */
proto.rct.web.ListSloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListSloRequest;
  return proto.rct.web.ListSloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListSloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListSloRequest}
 */
proto.rct.web.ListSloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListSloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListSloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListSloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListSloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.ListSloRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ListSloRequest} returns this
 */
proto.rct.web.ListSloRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ListSloResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListSloResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListSloResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListSloResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListSloResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    slosList: jspb.Message.toObjectList(msg.getSlosList(),
    proto.rct.web.SloView.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListSloResponse}
 */
proto.rct.web.ListSloResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListSloResponse;
  return proto.rct.web.ListSloResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListSloResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListSloResponse}
 */
proto.rct.web.ListSloResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.SloView;
      reader.readMessage(value,proto.rct.web.SloView.deserializeBinaryFromReader);
      msg.addSlos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListSloResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListSloResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListSloResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListSloResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.SloView.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SloView slos = 1;
 * @return {!Array<!proto.rct.web.SloView>}
 */
proto.rct.web.ListSloResponse.prototype.getSlosList = function() {
  return /** @type{!Array<!proto.rct.web.SloView>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.SloView, 1));
};


/**
 * @param {!Array<!proto.rct.web.SloView>} value
 * @return {!proto.rct.web.ListSloResponse} returns this
*/
proto.rct.web.ListSloResponse.prototype.setSlosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.SloView=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.SloView}
 */
proto.rct.web.ListSloResponse.prototype.addSlos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.SloView, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ListSloResponse} returns this
 */
proto.rct.web.ListSloResponse.prototype.clearSlosList = function() {
  return this.setSlosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetSloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetSloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetSloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shortname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetSloRequest}
 */
proto.rct.web.GetSloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetSloRequest;
  return proto.rct.web.GetSloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetSloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetSloRequest}
 */
proto.rct.web.GetSloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetSloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetSloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetSloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.GetSloRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetSloRequest} returns this
 */
proto.rct.web.GetSloRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shortName = 2;
 * @return {string}
 */
proto.rct.web.GetSloRequest.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetSloRequest} returns this
 */
proto.rct.web.GetSloRequest.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetSloResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetSloResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetSloResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    slo: (f = msg.getSlo()) && proto.rct.web.SloView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetSloResponse}
 */
proto.rct.web.GetSloResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetSloResponse;
  return proto.rct.web.GetSloResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetSloResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetSloResponse}
 */
proto.rct.web.GetSloResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.SloView;
      reader.readMessage(value,proto.rct.web.SloView.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetSloResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetSloResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetSloResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.SloView.serializeBinaryToWriter
    );
  }
};


/**
 * optional SloView slo = 1;
 * @return {?proto.rct.web.SloView}
 */
proto.rct.web.GetSloResponse.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.SloView, 1));
};


/**
 * @param {?proto.rct.web.SloView|undefined} value
 * @return {!proto.rct.web.GetSloResponse} returns this
*/
proto.rct.web.GetSloResponse.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetSloResponse} returns this
 */
proto.rct.web.GetSloResponse.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetSloResponse.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteSloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteSloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteSloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteSloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteSloRequest}
 */
proto.rct.web.DeleteSloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteSloRequest;
  return proto.rct.web.DeleteSloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteSloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteSloRequest}
 */
proto.rct.web.DeleteSloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteSloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteSloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteSloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteSloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.DeleteSloRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.DeleteSloRequest} returns this
 */
proto.rct.web.DeleteSloRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteSloResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteSloResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteSloResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteSloResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteSloResponse}
 */
proto.rct.web.DeleteSloResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteSloResponse;
  return proto.rct.web.DeleteSloResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteSloResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteSloResponse}
 */
proto.rct.web.DeleteSloResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteSloResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteSloResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteSloResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteSloResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateSloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateSloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateSloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateSloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sloid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slo: (f = msg.getSlo()) && proto.rct.web.SloMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateSloRequest}
 */
proto.rct.web.UpdateSloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateSloRequest;
  return proto.rct.web.UpdateSloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateSloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateSloRequest}
 */
proto.rct.web.UpdateSloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSloid(value);
      break;
    case 2:
      var value = new proto.rct.web.SloMetadata;
      reader.readMessage(value,proto.rct.web.SloMetadata.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateSloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateSloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateSloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateSloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSloid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.SloMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sloId = 1;
 * @return {string}
 */
proto.rct.web.UpdateSloRequest.prototype.getSloid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.UpdateSloRequest} returns this
 */
proto.rct.web.UpdateSloRequest.prototype.setSloid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SloMetadata slo = 2;
 * @return {?proto.rct.web.SloMetadata}
 */
proto.rct.web.UpdateSloRequest.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.SloMetadata, 2));
};


/**
 * @param {?proto.rct.web.SloMetadata|undefined} value
 * @return {!proto.rct.web.UpdateSloRequest} returns this
*/
proto.rct.web.UpdateSloRequest.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateSloRequest} returns this
 */
proto.rct.web.UpdateSloRequest.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateSloRequest.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateSloResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateSloResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateSloResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateSloResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    slo: (f = msg.getSlo()) && proto.rct.web.SloView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateSloResponse}
 */
proto.rct.web.UpdateSloResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateSloResponse;
  return proto.rct.web.UpdateSloResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateSloResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateSloResponse}
 */
proto.rct.web.UpdateSloResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.SloView;
      reader.readMessage(value,proto.rct.web.SloView.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateSloResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateSloResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateSloResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateSloResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.SloView.serializeBinaryToWriter
    );
  }
};


/**
 * optional SloView slo = 1;
 * @return {?proto.rct.web.SloView}
 */
proto.rct.web.UpdateSloResponse.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.SloView, 1));
};


/**
 * @param {?proto.rct.web.SloView|undefined} value
 * @return {!proto.rct.web.UpdateSloResponse} returns this
*/
proto.rct.web.UpdateSloResponse.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateSloResponse} returns this
 */
proto.rct.web.UpdateSloResponse.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateSloResponse.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetSloHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetSloHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetSloHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startms: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endms: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetSloHistoryRequest}
 */
proto.rct.web.GetSloHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetSloHistoryRequest;
  return proto.rct.web.GetSloHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetSloHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetSloHistoryRequest}
 */
proto.rct.web.GetSloHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartms(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetSloHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetSloHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetSloHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartms();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndms();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.GetSloHistoryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetSloHistoryRequest} returns this
 */
proto.rct.web.GetSloHistoryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 startMs = 2;
 * @return {number}
 */
proto.rct.web.GetSloHistoryRequest.prototype.getStartms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.GetSloHistoryRequest} returns this
 */
proto.rct.web.GetSloHistoryRequest.prototype.setStartms = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 endMs = 3;
 * @return {number}
 */
proto.rct.web.GetSloHistoryRequest.prototype.getEndms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.GetSloHistoryRequest} returns this
 */
proto.rct.web.GetSloHistoryRequest.prototype.setEndms = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.GetSloHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetSloHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetSloHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetSloHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.rct.web.SloHistoryRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetSloHistoryResponse}
 */
proto.rct.web.GetSloHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetSloHistoryResponse;
  return proto.rct.web.GetSloHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetSloHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetSloHistoryResponse}
 */
proto.rct.web.GetSloHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.SloHistoryRecord;
      reader.readMessage(value,proto.rct.web.SloHistoryRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetSloHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetSloHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetSloHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetSloHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.SloHistoryRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SloHistoryRecord records = 1;
 * @return {!Array<!proto.rct.web.SloHistoryRecord>}
 */
proto.rct.web.GetSloHistoryResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.rct.web.SloHistoryRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.SloHistoryRecord, 1));
};


/**
 * @param {!Array<!proto.rct.web.SloHistoryRecord>} value
 * @return {!proto.rct.web.GetSloHistoryResponse} returns this
*/
proto.rct.web.GetSloHistoryResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.SloHistoryRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.SloHistoryRecord}
 */
proto.rct.web.GetSloHistoryResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.SloHistoryRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.GetSloHistoryResponse} returns this
 */
proto.rct.web.GetSloHistoryResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sloid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    triggeredat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    triggeredby: jspb.Message.getFieldWithDefault(msg, 6, 0),
    slostatuschanged: (f = msg.getSlostatuschanged()) && proto.rct.web.Activity.SloStatusChanged.toObject(includeInstance, f),
    sloobjectivechanged: (f = msg.getSloobjectivechanged()) && proto.rct.web.Activity.SloObjectiveChanged.toObject(includeInstance, f),
    slobreachstart: (f = msg.getSlobreachstart()) && proto.rct.web.Activity.SloBreachStart.toObject(includeInstance, f),
    slobreachend: (f = msg.getSlobreachend()) && proto.rct.web.Activity.SloBreachEnd.toObject(includeInstance, f),
    triggeredbyuser: (f = msg.getTriggeredbyuser()) && grpcweb_user_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Activity}
 */
proto.rct.web.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Activity;
  return proto.rct.web.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Activity}
 */
proto.rct.web.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.rct.web.Activity.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSloid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTriggeredat(value);
      break;
    case 6:
      var value = /** @type {!proto.rct.web.Activity.TriggeredBy} */ (reader.readEnum());
      msg.setTriggeredby(value);
      break;
    case 8:
      var value = new proto.rct.web.Activity.SloStatusChanged;
      reader.readMessage(value,proto.rct.web.Activity.SloStatusChanged.deserializeBinaryFromReader);
      msg.setSlostatuschanged(value);
      break;
    case 9:
      var value = new proto.rct.web.Activity.SloObjectiveChanged;
      reader.readMessage(value,proto.rct.web.Activity.SloObjectiveChanged.deserializeBinaryFromReader);
      msg.setSloobjectivechanged(value);
      break;
    case 10:
      var value = new proto.rct.web.Activity.SloBreachStart;
      reader.readMessage(value,proto.rct.web.Activity.SloBreachStart.deserializeBinaryFromReader);
      msg.setSlobreachstart(value);
      break;
    case 11:
      var value = new proto.rct.web.Activity.SloBreachEnd;
      reader.readMessage(value,proto.rct.web.Activity.SloBreachEnd.deserializeBinaryFromReader);
      msg.setSlobreachend(value);
      break;
    case 12:
      var value = new grpcweb_user_pb.User;
      reader.readMessage(value,grpcweb_user_pb.User.deserializeBinaryFromReader);
      msg.setTriggeredbyuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSloid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTriggeredat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTriggeredby();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSlostatuschanged();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.rct.web.Activity.SloStatusChanged.serializeBinaryToWriter
    );
  }
  f = message.getSloobjectivechanged();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.rct.web.Activity.SloObjectiveChanged.serializeBinaryToWriter
    );
  }
  f = message.getSlobreachstart();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.rct.web.Activity.SloBreachStart.serializeBinaryToWriter
    );
  }
  f = message.getSlobreachend();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.rct.web.Activity.SloBreachEnd.serializeBinaryToWriter
    );
  }
  f = message.getTriggeredbyuser();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      grpcweb_user_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.rct.web.Activity.TriggeredBy = {
  TRIGGERED_BY_UNSPECIFIED: 0,
  TRIGGERED_BY_USER: 1,
  TRIGGERED_BY_SYSTEM: 2
};

/**
 * @enum {number}
 */
proto.rct.web.Activity.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_SLO_STATUS_CHANGED: 1,
  TYPE_SLO_OBJECTIVE_CHANGED: 2,
  TYPE_SLO_BREACH_START: 3,
  TYPE_SLO_BREACH_END: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Activity.SloStatusChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Activity.SloStatusChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Activity.SloStatusChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloStatusChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    prevstatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentstatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Activity.SloStatusChanged}
 */
proto.rct.web.Activity.SloStatusChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Activity.SloStatusChanged;
  return proto.rct.web.Activity.SloStatusChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Activity.SloStatusChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Activity.SloStatusChanged}
 */
proto.rct.web.Activity.SloStatusChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rct.web.SloStatus} */ (reader.readEnum());
      msg.setPrevstatus(value);
      break;
    case 2:
      var value = /** @type {!proto.rct.web.SloStatus} */ (reader.readEnum());
      msg.setCurrentstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Activity.SloStatusChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Activity.SloStatusChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Activity.SloStatusChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloStatusChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrevstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCurrentstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SloStatus prevStatus = 1;
 * @return {!proto.rct.web.SloStatus}
 */
proto.rct.web.Activity.SloStatusChanged.prototype.getPrevstatus = function() {
  return /** @type {!proto.rct.web.SloStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rct.web.SloStatus} value
 * @return {!proto.rct.web.Activity.SloStatusChanged} returns this
 */
proto.rct.web.Activity.SloStatusChanged.prototype.setPrevstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SloStatus currentStatus = 2;
 * @return {!proto.rct.web.SloStatus}
 */
proto.rct.web.Activity.SloStatusChanged.prototype.getCurrentstatus = function() {
  return /** @type {!proto.rct.web.SloStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rct.web.SloStatus} value
 * @return {!proto.rct.web.Activity.SloStatusChanged} returns this
 */
proto.rct.web.Activity.SloStatusChanged.prototype.setCurrentstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Activity.SloObjectiveChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Activity.SloObjectiveChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Activity.SloObjectiveChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloObjectiveChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    prevobjective: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currentobjective: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Activity.SloObjectiveChanged}
 */
proto.rct.web.Activity.SloObjectiveChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Activity.SloObjectiveChanged;
  return proto.rct.web.Activity.SloObjectiveChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Activity.SloObjectiveChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Activity.SloObjectiveChanged}
 */
proto.rct.web.Activity.SloObjectiveChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrevobjective(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCurrentobjective(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Activity.SloObjectiveChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Activity.SloObjectiveChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Activity.SloObjectiveChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloObjectiveChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrevobjective();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrentobjective();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double prevObjective = 1;
 * @return {number}
 */
proto.rct.web.Activity.SloObjectiveChanged.prototype.getPrevobjective = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloObjectiveChanged} returns this
 */
proto.rct.web.Activity.SloObjectiveChanged.prototype.setPrevobjective = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double currentObjective = 2;
 * @return {number}
 */
proto.rct.web.Activity.SloObjectiveChanged.prototype.getCurrentobjective = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloObjectiveChanged} returns this
 */
proto.rct.web.Activity.SloObjectiveChanged.prototype.setCurrentobjective = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Activity.SloBreachStart.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Activity.SloBreachStart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Activity.SloBreachStart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloBreachStart.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetobjective: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    objective: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    breachedat: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Activity.SloBreachStart}
 */
proto.rct.web.Activity.SloBreachStart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Activity.SloBreachStart;
  return proto.rct.web.Activity.SloBreachStart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Activity.SloBreachStart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Activity.SloBreachStart}
 */
proto.rct.web.Activity.SloBreachStart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetobjective(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setObjective(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBreachedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Activity.SloBreachStart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Activity.SloBreachStart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Activity.SloBreachStart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloBreachStart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetobjective();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getObjective();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBreachedat();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional double targetObjective = 1;
 * @return {number}
 */
proto.rct.web.Activity.SloBreachStart.prototype.getTargetobjective = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloBreachStart} returns this
 */
proto.rct.web.Activity.SloBreachStart.prototype.setTargetobjective = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double objective = 2;
 * @return {number}
 */
proto.rct.web.Activity.SloBreachStart.prototype.getObjective = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloBreachStart} returns this
 */
proto.rct.web.Activity.SloBreachStart.prototype.setObjective = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 breachedAt = 3;
 * @return {number}
 */
proto.rct.web.Activity.SloBreachStart.prototype.getBreachedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloBreachStart} returns this
 */
proto.rct.web.Activity.SloBreachStart.prototype.setBreachedat = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Activity.SloBreachEnd.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Activity.SloBreachEnd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Activity.SloBreachEnd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloBreachEnd.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetobjective: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    objective: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    recoveredat: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Activity.SloBreachEnd}
 */
proto.rct.web.Activity.SloBreachEnd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Activity.SloBreachEnd;
  return proto.rct.web.Activity.SloBreachEnd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Activity.SloBreachEnd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Activity.SloBreachEnd}
 */
proto.rct.web.Activity.SloBreachEnd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetobjective(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setObjective(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecoveredat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Activity.SloBreachEnd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Activity.SloBreachEnd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Activity.SloBreachEnd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Activity.SloBreachEnd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetobjective();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getObjective();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRecoveredat();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional double targetObjective = 1;
 * @return {number}
 */
proto.rct.web.Activity.SloBreachEnd.prototype.getTargetobjective = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloBreachEnd} returns this
 */
proto.rct.web.Activity.SloBreachEnd.prototype.setTargetobjective = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double objective = 2;
 * @return {number}
 */
proto.rct.web.Activity.SloBreachEnd.prototype.getObjective = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloBreachEnd} returns this
 */
proto.rct.web.Activity.SloBreachEnd.prototype.setObjective = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 recoveredAt = 3;
 * @return {number}
 */
proto.rct.web.Activity.SloBreachEnd.prototype.getRecoveredat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity.SloBreachEnd} returns this
 */
proto.rct.web.Activity.SloBreachEnd.prototype.setRecoveredat = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.Activity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.rct.web.Activity.Type}
 */
proto.rct.web.Activity.prototype.getType = function() {
  return /** @type {!proto.rct.web.Activity.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rct.web.Activity.Type} value
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string sloId = 3;
 * @return {string}
 */
proto.rct.web.Activity.prototype.getSloid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.setSloid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenantId = 4;
 * @return {string}
 */
proto.rct.web.Activity.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 triggeredAt = 5;
 * @return {number}
 */
proto.rct.web.Activity.prototype.getTriggeredat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.setTriggeredat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional TriggeredBy triggeredBy = 6;
 * @return {!proto.rct.web.Activity.TriggeredBy}
 */
proto.rct.web.Activity.prototype.getTriggeredby = function() {
  return /** @type {!proto.rct.web.Activity.TriggeredBy} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.rct.web.Activity.TriggeredBy} value
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.setTriggeredby = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional SloStatusChanged sloStatusChanged = 8;
 * @return {?proto.rct.web.Activity.SloStatusChanged}
 */
proto.rct.web.Activity.prototype.getSlostatuschanged = function() {
  return /** @type{?proto.rct.web.Activity.SloStatusChanged} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Activity.SloStatusChanged, 8));
};


/**
 * @param {?proto.rct.web.Activity.SloStatusChanged|undefined} value
 * @return {!proto.rct.web.Activity} returns this
*/
proto.rct.web.Activity.prototype.setSlostatuschanged = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.clearSlostatuschanged = function() {
  return this.setSlostatuschanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Activity.prototype.hasSlostatuschanged = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SloObjectiveChanged sloObjectiveChanged = 9;
 * @return {?proto.rct.web.Activity.SloObjectiveChanged}
 */
proto.rct.web.Activity.prototype.getSloobjectivechanged = function() {
  return /** @type{?proto.rct.web.Activity.SloObjectiveChanged} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Activity.SloObjectiveChanged, 9));
};


/**
 * @param {?proto.rct.web.Activity.SloObjectiveChanged|undefined} value
 * @return {!proto.rct.web.Activity} returns this
*/
proto.rct.web.Activity.prototype.setSloobjectivechanged = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.clearSloobjectivechanged = function() {
  return this.setSloobjectivechanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Activity.prototype.hasSloobjectivechanged = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SloBreachStart sloBreachStart = 10;
 * @return {?proto.rct.web.Activity.SloBreachStart}
 */
proto.rct.web.Activity.prototype.getSlobreachstart = function() {
  return /** @type{?proto.rct.web.Activity.SloBreachStart} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Activity.SloBreachStart, 10));
};


/**
 * @param {?proto.rct.web.Activity.SloBreachStart|undefined} value
 * @return {!proto.rct.web.Activity} returns this
*/
proto.rct.web.Activity.prototype.setSlobreachstart = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.clearSlobreachstart = function() {
  return this.setSlobreachstart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Activity.prototype.hasSlobreachstart = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SloBreachEnd sloBreachEnd = 11;
 * @return {?proto.rct.web.Activity.SloBreachEnd}
 */
proto.rct.web.Activity.prototype.getSlobreachend = function() {
  return /** @type{?proto.rct.web.Activity.SloBreachEnd} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Activity.SloBreachEnd, 11));
};


/**
 * @param {?proto.rct.web.Activity.SloBreachEnd|undefined} value
 * @return {!proto.rct.web.Activity} returns this
*/
proto.rct.web.Activity.prototype.setSlobreachend = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.clearSlobreachend = function() {
  return this.setSlobreachend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Activity.prototype.hasSlobreachend = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional User triggeredByUser = 12;
 * @return {?proto.rct.web.User}
 */
proto.rct.web.Activity.prototype.getTriggeredbyuser = function() {
  return /** @type{?proto.rct.web.User} */ (
    jspb.Message.getWrapperField(this, grpcweb_user_pb.User, 12));
};


/**
 * @param {?proto.rct.web.User|undefined} value
 * @return {!proto.rct.web.Activity} returns this
*/
proto.rct.web.Activity.prototype.setTriggeredbyuser = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Activity} returns this
 */
proto.rct.web.Activity.prototype.clearTriggeredbyuser = function() {
  return this.setTriggeredbyuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Activity.prototype.hasTriggeredbyuser = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListActivitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListActivitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListActivitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListActivitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sloid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListActivitiesRequest}
 */
proto.rct.web.ListActivitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListActivitiesRequest;
  return proto.rct.web.ListActivitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListActivitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListActivitiesRequest}
 */
proto.rct.web.ListActivitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSloid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListActivitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListActivitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListActivitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListActivitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSloid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sloId = 1;
 * @return {string}
 */
proto.rct.web.ListActivitiesRequest.prototype.getSloid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ListActivitiesRequest} returns this
 */
proto.rct.web.ListActivitiesRequest.prototype.setSloid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ListActivitiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListActivitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListActivitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListActivitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListActivitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.rct.web.Activity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListActivitiesResponse}
 */
proto.rct.web.ListActivitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListActivitiesResponse;
  return proto.rct.web.ListActivitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListActivitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListActivitiesResponse}
 */
proto.rct.web.ListActivitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Activity;
      reader.readMessage(value,proto.rct.web.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListActivitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListActivitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListActivitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListActivitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.Activity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Activity activities = 1;
 * @return {!Array<!proto.rct.web.Activity>}
 */
proto.rct.web.ListActivitiesResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.rct.web.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.Activity, 1));
};


/**
 * @param {!Array<!proto.rct.web.Activity>} value
 * @return {!proto.rct.web.ListActivitiesResponse} returns this
*/
proto.rct.web.ListActivitiesResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Activity}
 */
proto.rct.web.ListActivitiesResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ListActivitiesResponse} returns this
 */
proto.rct.web.ListActivitiesResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};


/**
 * @enum {number}
 */
proto.rct.web.ErrorBudgetMethod = {
  ERROR_BUDGET_METHOD_UNSPECIFIED: 0,
  ERROR_BUDGET_METHOD_OCCURRENCES: 1,
  ERROR_BUDGET_METHOD_TIMESLICES: 2
};

/**
 * @enum {number}
 */
proto.rct.web.TimeWindowType = {
  TIME_WINDOW_TYPE_UNSPECIFIED: 0,
  TIME_WINDOW_TYPE_ROLLING: 1,
  TIME_WINDOW_TYPE_STATIC: 2
};

/**
 * @enum {number}
 */
proto.rct.web.TimeWindowUnit = {
  TIME_WINDOW_UNIT_UNSPECIFIED: 0,
  TIME_WINDOW_UNIT_HOUR: 2,
  TIME_WINDOW_UNIT_DAY: 3
};

/**
 * @enum {number}
 */
proto.rct.web.SloStatus = {
  SLO_STATUS_UNSPECIFIED: 0,
  SLO_STATUS_DRAFT: 1,
  SLO_STATUS_ACTIVE: 2,
  SLO_STATUS_FAILING: 3,
  SLO_STATUS_PAUSED: 4
};

/**
 * @enum {number}
 */
proto.rct.web.EventQueryType = {
  QUERY_TYPE_UNSPECIFIED: 0,
  QUERY_TYPE_PLAIN_TEXT: 1,
  QUERY_TYPE_FORM: 2
};

goog.object.extend(exports, proto.rct.web);
