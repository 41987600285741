import { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../definitions';
import * as Styled from './Theme.styles';

export const ThemeView: FunctionComponent = ({ children, ...rest }) => {
  return (
    <ThemeProvider theme={theme} {...rest}>
      <Styled.Global />
      {children}
    </ThemeProvider>
  );
};
