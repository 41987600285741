import styled from 'styled-components';
import { motion } from 'framer-motion';

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  max-width: 24rem;
  background: ${({ theme }) => theme.palettes.inverse};
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  padding: 1rem;
  justify-content: space-between;
  border-radius: 1rem;
  white-space: normal;
  min-width: 18rem;
`;
