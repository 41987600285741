import { useCallback, FunctionComponent } from 'react';
import * as Styled from './ScrollIntoView.styles';

export type ScrollIntoViewProps = {
  target?: any;
  scrollToTop?: boolean;
};

export const ScrollIntoViewView: FunctionComponent<ScrollIntoViewProps> = ({
  target,
  scrollToTop,
  children,
}) => {
  const handleScrollToTop = useCallback(() => {
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    if (target?.current) {
      target.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [target]);

  return <Styled.ButtonWrapper onClick={handleScrollToTop}>{children}</Styled.ButtonWrapper>;
};
