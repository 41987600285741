// source: grpcweb/component.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var grpccommon_common_pb = require('../grpccommon/common_pb.js');
goog.object.extend(proto, grpccommon_common_pb);
var grpcweb_tag_pb = require('../grpcweb/tag_pb.js');
goog.object.extend(proto, grpcweb_tag_pb);
var grpcweb_team_pb = require('../grpcweb/team_pb.js');
goog.object.extend(proto, grpcweb_team_pb);
var grpcweb_link_pb = require('../grpcweb/link_pb.js');
goog.object.extend(proto, grpcweb_link_pb);
var grpcweb_xevent_pb = require('../grpcweb/xevent_pb.js');
goog.object.extend(proto, grpcweb_xevent_pb);
var grpcweb_catalog_item_pb = require('../grpcweb/catalog_item_pb.js');
goog.object.extend(proto, grpcweb_catalog_item_pb);
goog.exportSymbol('proto.rct.web.AddComponentLinkRequest', null, global);
goog.exportSymbol('proto.rct.web.AddComponentLinkResponse', null, global);
goog.exportSymbol('proto.rct.web.AddDependenciesRequest', null, global);
goog.exportSymbol('proto.rct.web.AddDependenciesResponse', null, global);
goog.exportSymbol('proto.rct.web.AddDependentsRequest', null, global);
goog.exportSymbol('proto.rct.web.AddDependentsResponse', null, global);
goog.exportSymbol('proto.rct.web.ComponentBatch', null, global);
goog.exportSymbol('proto.rct.web.ComponentListRelationsRequest', null, global);
goog.exportSymbol('proto.rct.web.ComponentListRelationsResponse', null, global);
goog.exportSymbol('proto.rct.web.ComponentMetadata', null, global);
goog.exportSymbol('proto.rct.web.ComponentRef', null, global);
goog.exportSymbol('proto.rct.web.ComponentRelationRequest', null, global);
goog.exportSymbol('proto.rct.web.ComponentRelationResponse', null, global);
goog.exportSymbol('proto.rct.web.ComponentView', null, global);
goog.exportSymbol('proto.rct.web.CreateComponentRequest', null, global);
goog.exportSymbol('proto.rct.web.CreateComponentResponse', null, global);
goog.exportSymbol('proto.rct.web.DeleteComponentRequest', null, global);
goog.exportSymbol('proto.rct.web.DeleteComponentResponse', null, global);
goog.exportSymbol('proto.rct.web.FilterByComponentTypes', null, global);
goog.exportSymbol('proto.rct.web.FilterByTags', null, global);
goog.exportSymbol('proto.rct.web.FilterByTeams', null, global);
goog.exportSymbol('proto.rct.web.GetByShortNameWithRelationsRequest', null, global);
goog.exportSymbol('proto.rct.web.GetByShortNameWithRelationsResponse', null, global);
goog.exportSymbol('proto.rct.web.GetComponentByShortnameRequest', null, global);
goog.exportSymbol('proto.rct.web.GetComponentByShortnameResponse', null, global);
goog.exportSymbol('proto.rct.web.GetComponentDetailsForPopupRequest', null, global);
goog.exportSymbol('proto.rct.web.GetComponentDetailsForPopupResponse', null, global);
goog.exportSymbol('proto.rct.web.GetComponentRequest', null, global);
goog.exportSymbol('proto.rct.web.GetComponentResponse', null, global);
goog.exportSymbol('proto.rct.web.ListByFilterPageRequest', null, global);
goog.exportSymbol('proto.rct.web.ListByFilterPageResponse', null, global);
goog.exportSymbol('proto.rct.web.ListComponentsByPageRequest', null, global);
goog.exportSymbol('proto.rct.web.ListComponentsByPageResponse', null, global);
goog.exportSymbol('proto.rct.web.ListComponentsOptions', null, global);
goog.exportSymbol('proto.rct.web.ListMyComponentsRequest', null, global);
goog.exportSymbol('proto.rct.web.ListMyComponentsResponse', null, global);
goog.exportSymbol('proto.rct.web.ListWithNoRelationsRequest', null, global);
goog.exportSymbol('proto.rct.web.ListWithNoRelationsResponse', null, global);
goog.exportSymbol('proto.rct.web.RemComponentLinkRequest', null, global);
goog.exportSymbol('proto.rct.web.RemComponentLinkResponse', null, global);
goog.exportSymbol('proto.rct.web.RemoveDependantRequest', null, global);
goog.exportSymbol('proto.rct.web.RemoveDependantResponse', null, global);
goog.exportSymbol('proto.rct.web.RemoveDependencyRequest', null, global);
goog.exportSymbol('proto.rct.web.RemoveDependencyResponse', null, global);
goog.exportSymbol('proto.rct.web.UpdateComponentLinkRequest', null, global);
goog.exportSymbol('proto.rct.web.UpdateComponentLinkResponse', null, global);
goog.exportSymbol('proto.rct.web.UpdateComponentRequest', null, global);
goog.exportSymbol('proto.rct.web.UpdateComponentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ComponentMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentMetadata.displayName = 'proto.rct.web.ComponentMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ComponentView.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ComponentView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentView.displayName = 'proto.rct.web.ComponentView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentRef = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ComponentRef.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ComponentRef, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentRef.displayName = 'proto.rct.web.ComponentRef';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ComponentBatch.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ComponentBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentBatch.displayName = 'proto.rct.web.ComponentBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateComponentRequest.displayName = 'proto.rct.web.CreateComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateComponentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateComponentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateComponentResponse.displayName = 'proto.rct.web.CreateComponentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateComponentRequest.displayName = 'proto.rct.web.UpdateComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateComponentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateComponentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateComponentResponse.displayName = 'proto.rct.web.UpdateComponentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListComponentsOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListComponentsOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListComponentsOptions.displayName = 'proto.rct.web.ListComponentsOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListComponentsByPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListComponentsByPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListComponentsByPageRequest.displayName = 'proto.rct.web.ListComponentsByPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListComponentsByPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListComponentsByPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListComponentsByPageResponse.displayName = 'proto.rct.web.ListComponentsByPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.FilterByTags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.FilterByTags.repeatedFields_, null);
};
goog.inherits(proto.rct.web.FilterByTags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.FilterByTags.displayName = 'proto.rct.web.FilterByTags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.FilterByTeams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.FilterByTeams.repeatedFields_, null);
};
goog.inherits(proto.rct.web.FilterByTeams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.FilterByTeams.displayName = 'proto.rct.web.FilterByTeams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.FilterByComponentTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.FilterByComponentTypes.repeatedFields_, null);
};
goog.inherits(proto.rct.web.FilterByComponentTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.FilterByComponentTypes.displayName = 'proto.rct.web.FilterByComponentTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListByFilterPageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListByFilterPageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListByFilterPageRequest.displayName = 'proto.rct.web.ListByFilterPageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListByFilterPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListByFilterPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListByFilterPageResponse.displayName = 'proto.rct.web.ListByFilterPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListWithNoRelationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListWithNoRelationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListWithNoRelationsRequest.displayName = 'proto.rct.web.ListWithNoRelationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListWithNoRelationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListWithNoRelationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListWithNoRelationsResponse.displayName = 'proto.rct.web.ListWithNoRelationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListMyComponentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListMyComponentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListMyComponentsRequest.displayName = 'proto.rct.web.ListMyComponentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListMyComponentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListMyComponentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListMyComponentsResponse.displayName = 'proto.rct.web.ListMyComponentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetComponentRequest.displayName = 'proto.rct.web.GetComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetComponentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetComponentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetComponentResponse.displayName = 'proto.rct.web.GetComponentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetComponentByShortnameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetComponentByShortnameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetComponentByShortnameRequest.displayName = 'proto.rct.web.GetComponentByShortnameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetComponentByShortnameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetComponentByShortnameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetComponentByShortnameResponse.displayName = 'proto.rct.web.GetComponentByShortnameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetByShortNameWithRelationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetByShortNameWithRelationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetByShortNameWithRelationsRequest.displayName = 'proto.rct.web.GetByShortNameWithRelationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetByShortNameWithRelationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetByShortNameWithRelationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetByShortNameWithRelationsResponse.displayName = 'proto.rct.web.GetByShortNameWithRelationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ComponentRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentRelationRequest.displayName = 'proto.rct.web.ComponentRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ComponentRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentRelationResponse.displayName = 'proto.rct.web.ComponentRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentListRelationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ComponentListRelationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentListRelationsRequest.displayName = 'proto.rct.web.ComponentListRelationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ComponentListRelationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ComponentListRelationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ComponentListRelationsResponse.displayName = 'proto.rct.web.ComponentListRelationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteComponentRequest.displayName = 'proto.rct.web.DeleteComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteComponentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteComponentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteComponentResponse.displayName = 'proto.rct.web.DeleteComponentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.AddComponentLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.AddComponentLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.AddComponentLinkRequest.displayName = 'proto.rct.web.AddComponentLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.AddComponentLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.AddComponentLinkResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.AddComponentLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.AddComponentLinkResponse.displayName = 'proto.rct.web.AddComponentLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateComponentLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateComponentLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateComponentLinkRequest.displayName = 'proto.rct.web.UpdateComponentLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateComponentLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.UpdateComponentLinkResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.UpdateComponentLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateComponentLinkResponse.displayName = 'proto.rct.web.UpdateComponentLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.RemComponentLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.RemComponentLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.RemComponentLinkRequest.displayName = 'proto.rct.web.RemComponentLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.RemComponentLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.RemComponentLinkResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.RemComponentLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.RemComponentLinkResponse.displayName = 'proto.rct.web.RemComponentLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.AddDependentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.AddDependentsRequest.repeatedFields_, null);
};
goog.inherits(proto.rct.web.AddDependentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.AddDependentsRequest.displayName = 'proto.rct.web.AddDependentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.AddDependentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.AddDependentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.AddDependentsResponse.displayName = 'proto.rct.web.AddDependentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.AddDependenciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.AddDependenciesRequest.repeatedFields_, null);
};
goog.inherits(proto.rct.web.AddDependenciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.AddDependenciesRequest.displayName = 'proto.rct.web.AddDependenciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.AddDependenciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.AddDependenciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.AddDependenciesResponse.displayName = 'proto.rct.web.AddDependenciesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.RemoveDependantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.RemoveDependantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.RemoveDependantRequest.displayName = 'proto.rct.web.RemoveDependantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.RemoveDependantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.RemoveDependantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.RemoveDependantResponse.displayName = 'proto.rct.web.RemoveDependantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.RemoveDependencyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.RemoveDependencyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.RemoveDependencyRequest.displayName = 'proto.rct.web.RemoveDependencyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.RemoveDependencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.RemoveDependencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.RemoveDependencyResponse.displayName = 'proto.rct.web.RemoveDependencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetComponentDetailsForPopupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetComponentDetailsForPopupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetComponentDetailsForPopupRequest.displayName = 'proto.rct.web.GetComponentDetailsForPopupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetComponentDetailsForPopupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.GetComponentDetailsForPopupResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.GetComponentDetailsForPopupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetComponentDetailsForPopupResponse.displayName = 'proto.rct.web.GetComponentDetailsForPopupResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && grpccommon_common_pb.TextItem.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    avatar: (f = msg.getAvatar()) && grpccommon_common_pb.Avatar.toObject(includeInstance, f),
    ownerteamid: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentMetadata}
 */
proto.rct.web.ComponentMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentMetadata;
  return proto.rct.web.ComponentMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentMetadata}
 */
proto.rct.web.ComponentMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = new grpccommon_common_pb.TextItem;
      reader.readMessage(value,grpccommon_common_pb.TextItem.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = new grpccommon_common_pb.Avatar;
      reader.readMessage(value,grpccommon_common_pb.Avatar.deserializeBinaryFromReader);
      msg.setAvatar(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerteamid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpccommon_common_pb.TextItem.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvatar();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      grpccommon_common_pb.Avatar.serializeBinaryToWriter
    );
  }
  f = message.getOwnerteamid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string shortName = 1;
 * @return {string}
 */
proto.rct.web.ComponentMetadata.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentMetadata} returns this
 */
proto.rct.web.ComponentMetadata.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.rct.web.ComponentMetadata.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentMetadata} returns this
 */
proto.rct.web.ComponentMetadata.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional rct.common.TextItem description = 3;
 * @return {?proto.rct.common.TextItem}
 */
proto.rct.web.ComponentMetadata.prototype.getDescription = function() {
  return /** @type{?proto.rct.common.TextItem} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.TextItem, 3));
};


/**
 * @param {?proto.rct.common.TextItem|undefined} value
 * @return {!proto.rct.web.ComponentMetadata} returns this
*/
proto.rct.web.ComponentMetadata.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentMetadata} returns this
 */
proto.rct.web.ComponentMetadata.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentMetadata.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.rct.web.ComponentMetadata.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentMetadata} returns this
 */
proto.rct.web.ComponentMetadata.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional rct.common.Avatar avatar = 5;
 * @return {?proto.rct.common.Avatar}
 */
proto.rct.web.ComponentMetadata.prototype.getAvatar = function() {
  return /** @type{?proto.rct.common.Avatar} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.Avatar, 5));
};


/**
 * @param {?proto.rct.common.Avatar|undefined} value
 * @return {!proto.rct.web.ComponentMetadata} returns this
*/
proto.rct.web.ComponentMetadata.prototype.setAvatar = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentMetadata} returns this
 */
proto.rct.web.ComponentMetadata.prototype.clearAvatar = function() {
  return this.setAvatar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentMetadata.prototype.hasAvatar = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string ownerTeamId = 6;
 * @return {string}
 */
proto.rct.web.ComponentMetadata.prototype.getOwnerteamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentMetadata} returns this
 */
proto.rct.web.ComponentMetadata.prototype.setOwnerteamid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ComponentView.repeatedFields_ = [5,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentView.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.rct.web.ComponentMetadata.toObject(includeInstance, f),
    type: (f = msg.getType()) && grpcweb_catalog_item_pb.CatalogItemView.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    grpcweb_tag_pb.TagView.toObject, includeInstance),
    createmeta: (f = msg.getCreatemeta()) && grpccommon_common_pb.CreateMeta.toObject(includeInstance, f),
    updatemeta: (f = msg.getUpdatemeta()) && grpccommon_common_pb.UpdateMeta.toObject(includeInstance, f),
    ownerteam: (f = msg.getOwnerteam()) && grpcweb_team_pb.Team.toObject(includeInstance, f),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    grpcweb_link_pb.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentView}
 */
proto.rct.web.ComponentView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentView;
  return proto.rct.web.ComponentView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentView}
 */
proto.rct.web.ComponentView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.ComponentMetadata;
      reader.readMessage(value,proto.rct.web.ComponentMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 3:
      var value = new grpcweb_catalog_item_pb.CatalogItemView;
      reader.readMessage(value,grpcweb_catalog_item_pb.CatalogItemView.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 5:
      var value = new grpcweb_tag_pb.TagView;
      reader.readMessage(value,grpcweb_tag_pb.TagView.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 8:
      var value = new grpccommon_common_pb.CreateMeta;
      reader.readMessage(value,grpccommon_common_pb.CreateMeta.deserializeBinaryFromReader);
      msg.setCreatemeta(value);
      break;
    case 9:
      var value = new grpccommon_common_pb.UpdateMeta;
      reader.readMessage(value,grpccommon_common_pb.UpdateMeta.deserializeBinaryFromReader);
      msg.setUpdatemeta(value);
      break;
    case 10:
      var value = new grpcweb_team_pb.Team;
      reader.readMessage(value,grpcweb_team_pb.Team.deserializeBinaryFromReader);
      msg.setOwnerteam(value);
      break;
    case 11:
      var value = new grpcweb_link_pb.Link;
      reader.readMessage(value,grpcweb_link_pb.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ComponentMetadata.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpcweb_catalog_item_pb.CatalogItemView.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      grpcweb_tag_pb.TagView.serializeBinaryToWriter
    );
  }
  f = message.getCreatemeta();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      grpccommon_common_pb.CreateMeta.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemeta();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      grpccommon_common_pb.UpdateMeta.serializeBinaryToWriter
    );
  }
  f = message.getOwnerteam();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      grpcweb_team_pb.Team.serializeBinaryToWriter
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      grpcweb_link_pb.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.ComponentView.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ComponentMetadata metadata = 2;
 * @return {?proto.rct.web.ComponentMetadata}
 */
proto.rct.web.ComponentView.prototype.getMetadata = function() {
  return /** @type{?proto.rct.web.ComponentMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentMetadata, 2));
};


/**
 * @param {?proto.rct.web.ComponentMetadata|undefined} value
 * @return {!proto.rct.web.ComponentView} returns this
*/
proto.rct.web.ComponentView.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentView.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CatalogItemView type = 3;
 * @return {?proto.rct.web.CatalogItemView}
 */
proto.rct.web.ComponentView.prototype.getType = function() {
  return /** @type{?proto.rct.web.CatalogItemView} */ (
    jspb.Message.getWrapperField(this, grpcweb_catalog_item_pb.CatalogItemView, 3));
};


/**
 * @param {?proto.rct.web.CatalogItemView|undefined} value
 * @return {!proto.rct.web.ComponentView} returns this
*/
proto.rct.web.ComponentView.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentView.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated TagView tags = 5;
 * @return {!Array<!proto.rct.web.TagView>}
 */
proto.rct.web.ComponentView.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.rct.web.TagView>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_tag_pb.TagView, 5));
};


/**
 * @param {!Array<!proto.rct.web.TagView>} value
 * @return {!proto.rct.web.ComponentView} returns this
*/
proto.rct.web.ComponentView.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.rct.web.TagView=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.TagView}
 */
proto.rct.web.ComponentView.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.rct.web.TagView, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional rct.common.CreateMeta createMeta = 8;
 * @return {?proto.rct.common.CreateMeta}
 */
proto.rct.web.ComponentView.prototype.getCreatemeta = function() {
  return /** @type{?proto.rct.common.CreateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.CreateMeta, 8));
};


/**
 * @param {?proto.rct.common.CreateMeta|undefined} value
 * @return {!proto.rct.web.ComponentView} returns this
*/
proto.rct.web.ComponentView.prototype.setCreatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.clearCreatemeta = function() {
  return this.setCreatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentView.prototype.hasCreatemeta = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional rct.common.UpdateMeta updateMeta = 9;
 * @return {?proto.rct.common.UpdateMeta}
 */
proto.rct.web.ComponentView.prototype.getUpdatemeta = function() {
  return /** @type{?proto.rct.common.UpdateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.UpdateMeta, 9));
};


/**
 * @param {?proto.rct.common.UpdateMeta|undefined} value
 * @return {!proto.rct.web.ComponentView} returns this
*/
proto.rct.web.ComponentView.prototype.setUpdatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.clearUpdatemeta = function() {
  return this.setUpdatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentView.prototype.hasUpdatemeta = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Team ownerTeam = 10;
 * @return {?proto.rct.web.Team}
 */
proto.rct.web.ComponentView.prototype.getOwnerteam = function() {
  return /** @type{?proto.rct.web.Team} */ (
    jspb.Message.getWrapperField(this, grpcweb_team_pb.Team, 10));
};


/**
 * @param {?proto.rct.web.Team|undefined} value
 * @return {!proto.rct.web.ComponentView} returns this
*/
proto.rct.web.ComponentView.prototype.setOwnerteam = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.clearOwnerteam = function() {
  return this.setOwnerteam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentView.prototype.hasOwnerteam = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated Link links = 11;
 * @return {!Array<!proto.rct.web.Link>}
 */
proto.rct.web.ComponentView.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.rct.web.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_link_pb.Link, 11));
};


/**
 * @param {!Array<!proto.rct.web.Link>} value
 * @return {!proto.rct.web.ComponentView} returns this
*/
proto.rct.web.ComponentView.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.rct.web.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Link}
 */
proto.rct.web.ComponentView.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.rct.web.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ComponentView} returns this
 */
proto.rct.web.ComponentView.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ComponentRef.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentRef.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentRef.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentRef} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentRef.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.rct.web.ComponentMetadata.toObject(includeInstance, f),
    type: (f = msg.getType()) && grpcweb_catalog_item_pb.CatalogItemView.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    grpcweb_tag_pb.TagView.toObject, includeInstance),
    createmeta: (f = msg.getCreatemeta()) && grpccommon_common_pb.CreateMeta.toObject(includeInstance, f),
    updatemeta: (f = msg.getUpdatemeta()) && grpccommon_common_pb.UpdateMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentRef}
 */
proto.rct.web.ComponentRef.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentRef;
  return proto.rct.web.ComponentRef.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentRef} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentRef}
 */
proto.rct.web.ComponentRef.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.ComponentMetadata;
      reader.readMessage(value,proto.rct.web.ComponentMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 3:
      var value = new grpcweb_catalog_item_pb.CatalogItemView;
      reader.readMessage(value,grpcweb_catalog_item_pb.CatalogItemView.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 5:
      var value = new grpcweb_tag_pb.TagView;
      reader.readMessage(value,grpcweb_tag_pb.TagView.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 8:
      var value = new grpccommon_common_pb.CreateMeta;
      reader.readMessage(value,grpccommon_common_pb.CreateMeta.deserializeBinaryFromReader);
      msg.setCreatemeta(value);
      break;
    case 9:
      var value = new grpccommon_common_pb.UpdateMeta;
      reader.readMessage(value,grpccommon_common_pb.UpdateMeta.deserializeBinaryFromReader);
      msg.setUpdatemeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentRef.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentRef.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentRef} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentRef.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ComponentMetadata.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpcweb_catalog_item_pb.CatalogItemView.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      grpcweb_tag_pb.TagView.serializeBinaryToWriter
    );
  }
  f = message.getCreatemeta();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      grpccommon_common_pb.CreateMeta.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemeta();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      grpccommon_common_pb.UpdateMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.ComponentRef.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentRef} returns this
 */
proto.rct.web.ComponentRef.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ComponentMetadata metadata = 2;
 * @return {?proto.rct.web.ComponentMetadata}
 */
proto.rct.web.ComponentRef.prototype.getMetadata = function() {
  return /** @type{?proto.rct.web.ComponentMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentMetadata, 2));
};


/**
 * @param {?proto.rct.web.ComponentMetadata|undefined} value
 * @return {!proto.rct.web.ComponentRef} returns this
*/
proto.rct.web.ComponentRef.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentRef} returns this
 */
proto.rct.web.ComponentRef.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentRef.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CatalogItemView type = 3;
 * @return {?proto.rct.web.CatalogItemView}
 */
proto.rct.web.ComponentRef.prototype.getType = function() {
  return /** @type{?proto.rct.web.CatalogItemView} */ (
    jspb.Message.getWrapperField(this, grpcweb_catalog_item_pb.CatalogItemView, 3));
};


/**
 * @param {?proto.rct.web.CatalogItemView|undefined} value
 * @return {!proto.rct.web.ComponentRef} returns this
*/
proto.rct.web.ComponentRef.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentRef} returns this
 */
proto.rct.web.ComponentRef.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentRef.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated TagView tags = 5;
 * @return {!Array<!proto.rct.web.TagView>}
 */
proto.rct.web.ComponentRef.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.rct.web.TagView>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_tag_pb.TagView, 5));
};


/**
 * @param {!Array<!proto.rct.web.TagView>} value
 * @return {!proto.rct.web.ComponentRef} returns this
*/
proto.rct.web.ComponentRef.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.rct.web.TagView=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.TagView}
 */
proto.rct.web.ComponentRef.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.rct.web.TagView, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ComponentRef} returns this
 */
proto.rct.web.ComponentRef.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional rct.common.CreateMeta createMeta = 8;
 * @return {?proto.rct.common.CreateMeta}
 */
proto.rct.web.ComponentRef.prototype.getCreatemeta = function() {
  return /** @type{?proto.rct.common.CreateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.CreateMeta, 8));
};


/**
 * @param {?proto.rct.common.CreateMeta|undefined} value
 * @return {!proto.rct.web.ComponentRef} returns this
*/
proto.rct.web.ComponentRef.prototype.setCreatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentRef} returns this
 */
proto.rct.web.ComponentRef.prototype.clearCreatemeta = function() {
  return this.setCreatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentRef.prototype.hasCreatemeta = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional rct.common.UpdateMeta updateMeta = 9;
 * @return {?proto.rct.common.UpdateMeta}
 */
proto.rct.web.ComponentRef.prototype.getUpdatemeta = function() {
  return /** @type{?proto.rct.common.UpdateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.UpdateMeta, 9));
};


/**
 * @param {?proto.rct.common.UpdateMeta|undefined} value
 * @return {!proto.rct.web.ComponentRef} returns this
*/
proto.rct.web.ComponentRef.prototype.setUpdatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentRef} returns this
 */
proto.rct.web.ComponentRef.prototype.clearUpdatemeta = function() {
  return this.setUpdatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentRef.prototype.hasUpdatemeta = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ComponentBatch.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentsList: jspb.Message.toObjectList(msg.getComponentsList(),
    proto.rct.web.ComponentRef.toObject, includeInstance),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    grpcweb_team_pb.TeamRef.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentBatch}
 */
proto.rct.web.ComponentBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentBatch;
  return proto.rct.web.ComponentBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentBatch}
 */
proto.rct.web.ComponentBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentRef;
      reader.readMessage(value,proto.rct.web.ComponentRef.deserializeBinaryFromReader);
      msg.addComponents(value);
      break;
    case 2:
      var value = new grpcweb_team_pb.TeamRef;
      reader.readMessage(value,grpcweb_team_pb.TeamRef.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.ComponentRef.serializeBinaryToWriter
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      grpcweb_team_pb.TeamRef.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ComponentRef components = 1;
 * @return {!Array<!proto.rct.web.ComponentRef>}
 */
proto.rct.web.ComponentBatch.prototype.getComponentsList = function() {
  return /** @type{!Array<!proto.rct.web.ComponentRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.ComponentRef, 1));
};


/**
 * @param {!Array<!proto.rct.web.ComponentRef>} value
 * @return {!proto.rct.web.ComponentBatch} returns this
*/
proto.rct.web.ComponentBatch.prototype.setComponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.ComponentRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.ComponentRef}
 */
proto.rct.web.ComponentBatch.prototype.addComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.ComponentRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ComponentBatch} returns this
 */
proto.rct.web.ComponentBatch.prototype.clearComponentsList = function() {
  return this.setComponentsList([]);
};


/**
 * repeated TeamRef teams = 2;
 * @return {!Array<!proto.rct.web.TeamRef>}
 */
proto.rct.web.ComponentBatch.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.rct.web.TeamRef>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_team_pb.TeamRef, 2));
};


/**
 * @param {!Array<!proto.rct.web.TeamRef>} value
 * @return {!proto.rct.web.ComponentBatch} returns this
*/
proto.rct.web.ComponentBatch.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.rct.web.TeamRef=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.TeamRef}
 */
proto.rct.web.ComponentBatch.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.rct.web.TeamRef, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ComponentBatch} returns this
 */
proto.rct.web.ComponentBatch.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.rct.web.ComponentMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateComponentRequest}
 */
proto.rct.web.CreateComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateComponentRequest;
  return proto.rct.web.CreateComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateComponentRequest}
 */
proto.rct.web.CreateComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentMetadata;
      reader.readMessage(value,proto.rct.web.ComponentMetadata.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentMetadata meta = 1;
 * @return {?proto.rct.web.ComponentMetadata}
 */
proto.rct.web.CreateComponentRequest.prototype.getMeta = function() {
  return /** @type{?proto.rct.web.ComponentMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentMetadata, 1));
};


/**
 * @param {?proto.rct.web.ComponentMetadata|undefined} value
 * @return {!proto.rct.web.CreateComponentRequest} returns this
*/
proto.rct.web.CreateComponentRequest.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateComponentRequest} returns this
 */
proto.rct.web.CreateComponentRequest.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateComponentRequest.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateComponentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateComponentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateComponentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateComponentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    component: (f = msg.getComponent()) && proto.rct.web.ComponentView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateComponentResponse}
 */
proto.rct.web.CreateComponentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateComponentResponse;
  return proto.rct.web.CreateComponentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateComponentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateComponentResponse}
 */
proto.rct.web.CreateComponentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentView;
      reader.readMessage(value,proto.rct.web.ComponentView.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateComponentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateComponentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateComponentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateComponentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentView.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentView component = 1;
 * @return {?proto.rct.web.ComponentView}
 */
proto.rct.web.CreateComponentResponse.prototype.getComponent = function() {
  return /** @type{?proto.rct.web.ComponentView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentView, 1));
};


/**
 * @param {?proto.rct.web.ComponentView|undefined} value
 * @return {!proto.rct.web.CreateComponentResponse} returns this
*/
proto.rct.web.CreateComponentResponse.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateComponentResponse} returns this
 */
proto.rct.web.CreateComponentResponse.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateComponentResponse.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meta: (f = msg.getMeta()) && proto.rct.web.ComponentMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateComponentRequest}
 */
proto.rct.web.UpdateComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateComponentRequest;
  return proto.rct.web.UpdateComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateComponentRequest}
 */
proto.rct.web.UpdateComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.ComponentMetadata;
      reader.readMessage(value,proto.rct.web.ComponentMetadata.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ComponentMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.UpdateComponentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.UpdateComponentRequest} returns this
 */
proto.rct.web.UpdateComponentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ComponentMetadata meta = 2;
 * @return {?proto.rct.web.ComponentMetadata}
 */
proto.rct.web.UpdateComponentRequest.prototype.getMeta = function() {
  return /** @type{?proto.rct.web.ComponentMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentMetadata, 2));
};


/**
 * @param {?proto.rct.web.ComponentMetadata|undefined} value
 * @return {!proto.rct.web.UpdateComponentRequest} returns this
*/
proto.rct.web.UpdateComponentRequest.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateComponentRequest} returns this
 */
proto.rct.web.UpdateComponentRequest.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateComponentRequest.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateComponentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateComponentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateComponentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    component: (f = msg.getComponent()) && proto.rct.web.ComponentView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateComponentResponse}
 */
proto.rct.web.UpdateComponentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateComponentResponse;
  return proto.rct.web.UpdateComponentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateComponentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateComponentResponse}
 */
proto.rct.web.UpdateComponentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentView;
      reader.readMessage(value,proto.rct.web.ComponentView.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateComponentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateComponentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateComponentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentView.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentView component = 1;
 * @return {?proto.rct.web.ComponentView}
 */
proto.rct.web.UpdateComponentResponse.prototype.getComponent = function() {
  return /** @type{?proto.rct.web.ComponentView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentView, 1));
};


/**
 * @param {?proto.rct.web.ComponentView|undefined} value
 * @return {!proto.rct.web.UpdateComponentResponse} returns this
*/
proto.rct.web.UpdateComponentResponse.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateComponentResponse} returns this
 */
proto.rct.web.UpdateComponentResponse.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateComponentResponse.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListComponentsOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListComponentsOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListComponentsOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListComponentsOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeteamdetails: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.ListComponentsOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListComponentsOptions;
  return proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListComponentsOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeteamdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListComponentsOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListComponentsOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListComponentsOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListComponentsOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeteamdetails();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool includeTeamDetails = 1;
 * @return {boolean}
 */
proto.rct.web.ListComponentsOptions.prototype.getIncludeteamdetails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rct.web.ListComponentsOptions} returns this
 */
proto.rct.web.ListComponentsOptions.prototype.setIncludeteamdetails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListComponentsByPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListComponentsByPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListComponentsByPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListComponentsByPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerequest: (f = msg.getPagerequest()) && grpccommon_common_pb.PageRequest.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListComponentsByPageRequest}
 */
proto.rct.web.ListComponentsByPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListComponentsByPageRequest;
  return proto.rct.web.ListComponentsByPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListComponentsByPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListComponentsByPageRequest}
 */
proto.rct.web.ListComponentsByPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpccommon_common_pb.PageRequest;
      reader.readMessage(value,grpccommon_common_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPagerequest(value);
      break;
    case 2:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListComponentsByPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListComponentsByPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListComponentsByPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListComponentsByPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      grpccommon_common_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional rct.common.PageRequest pageRequest = 1;
 * @return {?proto.rct.common.PageRequest}
 */
proto.rct.web.ListComponentsByPageRequest.prototype.getPagerequest = function() {
  return /** @type{?proto.rct.common.PageRequest} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageRequest, 1));
};


/**
 * @param {?proto.rct.common.PageRequest|undefined} value
 * @return {!proto.rct.web.ListComponentsByPageRequest} returns this
*/
proto.rct.web.ListComponentsByPageRequest.prototype.setPagerequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListComponentsByPageRequest} returns this
 */
proto.rct.web.ListComponentsByPageRequest.prototype.clearPagerequest = function() {
  return this.setPagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListComponentsByPageRequest.prototype.hasPagerequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ListComponentsOptions options = 2;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.ListComponentsByPageRequest.prototype.getOptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 2));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.ListComponentsByPageRequest} returns this
*/
proto.rct.web.ListComponentsByPageRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListComponentsByPageRequest} returns this
 */
proto.rct.web.ListComponentsByPageRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListComponentsByPageRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListComponentsByPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListComponentsByPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListComponentsByPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListComponentsByPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batch: (f = msg.getBatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f),
    pageresponse: (f = msg.getPageresponse()) && grpccommon_common_pb.PageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListComponentsByPageResponse}
 */
proto.rct.web.ListComponentsByPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListComponentsByPageResponse;
  return proto.rct.web.ListComponentsByPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListComponentsByPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListComponentsByPageResponse}
 */
proto.rct.web.ListComponentsByPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setBatch(value);
      break;
    case 2:
      var value = new grpccommon_common_pb.PageResponse;
      reader.readMessage(value,grpccommon_common_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPageresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListComponentsByPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListComponentsByPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListComponentsByPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListComponentsByPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
  f = message.getPageresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpccommon_common_pb.PageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch batch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.ListComponentsByPageResponse.prototype.getBatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.ListComponentsByPageResponse} returns this
*/
proto.rct.web.ListComponentsByPageResponse.prototype.setBatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListComponentsByPageResponse} returns this
 */
proto.rct.web.ListComponentsByPageResponse.prototype.clearBatch = function() {
  return this.setBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListComponentsByPageResponse.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional rct.common.PageResponse pageResponse = 2;
 * @return {?proto.rct.common.PageResponse}
 */
proto.rct.web.ListComponentsByPageResponse.prototype.getPageresponse = function() {
  return /** @type{?proto.rct.common.PageResponse} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageResponse, 2));
};


/**
 * @param {?proto.rct.common.PageResponse|undefined} value
 * @return {!proto.rct.web.ListComponentsByPageResponse} returns this
*/
proto.rct.web.ListComponentsByPageResponse.prototype.setPageresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListComponentsByPageResponse} returns this
 */
proto.rct.web.ListComponentsByPageResponse.prototype.clearPageresponse = function() {
  return this.setPageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListComponentsByPageResponse.prototype.hasPageresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.FilterByTags.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.FilterByTags.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.FilterByTags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.FilterByTags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.FilterByTags.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    valueidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.FilterByTags}
 */
proto.rct.web.FilterByTags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.FilterByTags;
  return proto.rct.web.FilterByTags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.FilterByTags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.FilterByTags}
 */
proto.rct.web.FilterByTags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeyids(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValueids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.FilterByTags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.FilterByTags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.FilterByTags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.FilterByTags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getValueidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string keyIds = 1;
 * @return {!Array<string>}
 */
proto.rct.web.FilterByTags.prototype.getKeyidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rct.web.FilterByTags} returns this
 */
proto.rct.web.FilterByTags.prototype.setKeyidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rct.web.FilterByTags} returns this
 */
proto.rct.web.FilterByTags.prototype.addKeyids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.FilterByTags} returns this
 */
proto.rct.web.FilterByTags.prototype.clearKeyidsList = function() {
  return this.setKeyidsList([]);
};


/**
 * repeated string valueIds = 2;
 * @return {!Array<string>}
 */
proto.rct.web.FilterByTags.prototype.getValueidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rct.web.FilterByTags} returns this
 */
proto.rct.web.FilterByTags.prototype.setValueidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rct.web.FilterByTags} returns this
 */
proto.rct.web.FilterByTags.prototype.addValueids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.FilterByTags} returns this
 */
proto.rct.web.FilterByTags.prototype.clearValueidsList = function() {
  return this.setValueidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.FilterByTeams.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.FilterByTeams.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.FilterByTeams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.FilterByTeams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.FilterByTeams.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.FilterByTeams}
 */
proto.rct.web.FilterByTeams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.FilterByTeams;
  return proto.rct.web.FilterByTeams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.FilterByTeams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.FilterByTeams}
 */
proto.rct.web.FilterByTeams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTeamids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.FilterByTeams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.FilterByTeams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.FilterByTeams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.FilterByTeams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string teamIds = 1;
 * @return {!Array<string>}
 */
proto.rct.web.FilterByTeams.prototype.getTeamidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rct.web.FilterByTeams} returns this
 */
proto.rct.web.FilterByTeams.prototype.setTeamidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rct.web.FilterByTeams} returns this
 */
proto.rct.web.FilterByTeams.prototype.addTeamids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.FilterByTeams} returns this
 */
proto.rct.web.FilterByTeams.prototype.clearTeamidsList = function() {
  return this.setTeamidsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.FilterByComponentTypes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.FilterByComponentTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.FilterByComponentTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.FilterByComponentTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.FilterByComponentTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.FilterByComponentTypes}
 */
proto.rct.web.FilterByComponentTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.FilterByComponentTypes;
  return proto.rct.web.FilterByComponentTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.FilterByComponentTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.FilterByComponentTypes}
 */
proto.rct.web.FilterByComponentTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTypeids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.FilterByComponentTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.FilterByComponentTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.FilterByComponentTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.FilterByComponentTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string typeIds = 1;
 * @return {!Array<string>}
 */
proto.rct.web.FilterByComponentTypes.prototype.getTypeidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rct.web.FilterByComponentTypes} returns this
 */
proto.rct.web.FilterByComponentTypes.prototype.setTypeidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rct.web.FilterByComponentTypes} returns this
 */
proto.rct.web.FilterByComponentTypes.prototype.addTypeids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.FilterByComponentTypes} returns this
 */
proto.rct.web.FilterByComponentTypes.prototype.clearTypeidsList = function() {
  return this.setTypeidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListByFilterPageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListByFilterPageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListByFilterPageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListByFilterPageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerequest: (f = msg.getPagerequest()) && grpccommon_common_pb.PageRequest.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f),
    tags: (f = msg.getTags()) && proto.rct.web.FilterByTags.toObject(includeInstance, f),
    teams: (f = msg.getTeams()) && proto.rct.web.FilterByTeams.toObject(includeInstance, f),
    types: (f = msg.getTypes()) && proto.rct.web.FilterByComponentTypes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListByFilterPageRequest}
 */
proto.rct.web.ListByFilterPageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListByFilterPageRequest;
  return proto.rct.web.ListByFilterPageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListByFilterPageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListByFilterPageRequest}
 */
proto.rct.web.ListByFilterPageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpccommon_common_pb.PageRequest;
      reader.readMessage(value,grpccommon_common_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPagerequest(value);
      break;
    case 2:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 3:
      var value = new proto.rct.web.FilterByTags;
      reader.readMessage(value,proto.rct.web.FilterByTags.deserializeBinaryFromReader);
      msg.setTags(value);
      break;
    case 4:
      var value = new proto.rct.web.FilterByTeams;
      reader.readMessage(value,proto.rct.web.FilterByTeams.deserializeBinaryFromReader);
      msg.setTeams(value);
      break;
    case 5:
      var value = new proto.rct.web.FilterByComponentTypes;
      reader.readMessage(value,proto.rct.web.FilterByComponentTypes.deserializeBinaryFromReader);
      msg.setTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListByFilterPageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListByFilterPageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListByFilterPageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListByFilterPageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      grpccommon_common_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
  f = message.getTags();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.FilterByTags.serializeBinaryToWriter
    );
  }
  f = message.getTeams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.rct.web.FilterByTeams.serializeBinaryToWriter
    );
  }
  f = message.getTypes();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.rct.web.FilterByComponentTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional rct.common.PageRequest pageRequest = 1;
 * @return {?proto.rct.common.PageRequest}
 */
proto.rct.web.ListByFilterPageRequest.prototype.getPagerequest = function() {
  return /** @type{?proto.rct.common.PageRequest} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageRequest, 1));
};


/**
 * @param {?proto.rct.common.PageRequest|undefined} value
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
*/
proto.rct.web.ListByFilterPageRequest.prototype.setPagerequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
 */
proto.rct.web.ListByFilterPageRequest.prototype.clearPagerequest = function() {
  return this.setPagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListByFilterPageRequest.prototype.hasPagerequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ListComponentsOptions options = 2;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.ListByFilterPageRequest.prototype.getOptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 2));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
*/
proto.rct.web.ListByFilterPageRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
 */
proto.rct.web.ListByFilterPageRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListByFilterPageRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FilterByTags tags = 3;
 * @return {?proto.rct.web.FilterByTags}
 */
proto.rct.web.ListByFilterPageRequest.prototype.getTags = function() {
  return /** @type{?proto.rct.web.FilterByTags} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.FilterByTags, 3));
};


/**
 * @param {?proto.rct.web.FilterByTags|undefined} value
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
*/
proto.rct.web.ListByFilterPageRequest.prototype.setTags = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
 */
proto.rct.web.ListByFilterPageRequest.prototype.clearTags = function() {
  return this.setTags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListByFilterPageRequest.prototype.hasTags = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FilterByTeams teams = 4;
 * @return {?proto.rct.web.FilterByTeams}
 */
proto.rct.web.ListByFilterPageRequest.prototype.getTeams = function() {
  return /** @type{?proto.rct.web.FilterByTeams} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.FilterByTeams, 4));
};


/**
 * @param {?proto.rct.web.FilterByTeams|undefined} value
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
*/
proto.rct.web.ListByFilterPageRequest.prototype.setTeams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
 */
proto.rct.web.ListByFilterPageRequest.prototype.clearTeams = function() {
  return this.setTeams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListByFilterPageRequest.prototype.hasTeams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FilterByComponentTypes types = 5;
 * @return {?proto.rct.web.FilterByComponentTypes}
 */
proto.rct.web.ListByFilterPageRequest.prototype.getTypes = function() {
  return /** @type{?proto.rct.web.FilterByComponentTypes} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.FilterByComponentTypes, 5));
};


/**
 * @param {?proto.rct.web.FilterByComponentTypes|undefined} value
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
*/
proto.rct.web.ListByFilterPageRequest.prototype.setTypes = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListByFilterPageRequest} returns this
 */
proto.rct.web.ListByFilterPageRequest.prototype.clearTypes = function() {
  return this.setTypes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListByFilterPageRequest.prototype.hasTypes = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListByFilterPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListByFilterPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListByFilterPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListByFilterPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batch: (f = msg.getBatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f),
    pageresponse: (f = msg.getPageresponse()) && grpccommon_common_pb.PageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListByFilterPageResponse}
 */
proto.rct.web.ListByFilterPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListByFilterPageResponse;
  return proto.rct.web.ListByFilterPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListByFilterPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListByFilterPageResponse}
 */
proto.rct.web.ListByFilterPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setBatch(value);
      break;
    case 2:
      var value = new grpccommon_common_pb.PageResponse;
      reader.readMessage(value,grpccommon_common_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPageresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListByFilterPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListByFilterPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListByFilterPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListByFilterPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
  f = message.getPageresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpccommon_common_pb.PageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch batch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.ListByFilterPageResponse.prototype.getBatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.ListByFilterPageResponse} returns this
*/
proto.rct.web.ListByFilterPageResponse.prototype.setBatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListByFilterPageResponse} returns this
 */
proto.rct.web.ListByFilterPageResponse.prototype.clearBatch = function() {
  return this.setBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListByFilterPageResponse.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional rct.common.PageResponse pageResponse = 2;
 * @return {?proto.rct.common.PageResponse}
 */
proto.rct.web.ListByFilterPageResponse.prototype.getPageresponse = function() {
  return /** @type{?proto.rct.common.PageResponse} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageResponse, 2));
};


/**
 * @param {?proto.rct.common.PageResponse|undefined} value
 * @return {!proto.rct.web.ListByFilterPageResponse} returns this
*/
proto.rct.web.ListByFilterPageResponse.prototype.setPageresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListByFilterPageResponse} returns this
 */
proto.rct.web.ListByFilterPageResponse.prototype.clearPageresponse = function() {
  return this.setPageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListByFilterPageResponse.prototype.hasPageresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListWithNoRelationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListWithNoRelationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListWithNoRelationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerequest: (f = msg.getPagerequest()) && grpccommon_common_pb.PageRequest.toObject(includeInstance, f),
    options: (f = msg.getOptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListWithNoRelationsRequest}
 */
proto.rct.web.ListWithNoRelationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListWithNoRelationsRequest;
  return proto.rct.web.ListWithNoRelationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListWithNoRelationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListWithNoRelationsRequest}
 */
proto.rct.web.ListWithNoRelationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpccommon_common_pb.PageRequest;
      reader.readMessage(value,grpccommon_common_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPagerequest(value);
      break;
    case 2:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListWithNoRelationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListWithNoRelationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListWithNoRelationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      grpccommon_common_pb.PageRequest.serializeBinaryToWriter
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional rct.common.PageRequest pageRequest = 1;
 * @return {?proto.rct.common.PageRequest}
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.getPagerequest = function() {
  return /** @type{?proto.rct.common.PageRequest} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageRequest, 1));
};


/**
 * @param {?proto.rct.common.PageRequest|undefined} value
 * @return {!proto.rct.web.ListWithNoRelationsRequest} returns this
*/
proto.rct.web.ListWithNoRelationsRequest.prototype.setPagerequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListWithNoRelationsRequest} returns this
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.clearPagerequest = function() {
  return this.setPagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.hasPagerequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ListComponentsOptions options = 2;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 2));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.ListWithNoRelationsRequest} returns this
*/
proto.rct.web.ListWithNoRelationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListWithNoRelationsRequest} returns this
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListWithNoRelationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListWithNoRelationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListWithNoRelationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListWithNoRelationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batch: (f = msg.getBatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f),
    pageresponse: (f = msg.getPageresponse()) && grpccommon_common_pb.PageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListWithNoRelationsResponse}
 */
proto.rct.web.ListWithNoRelationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListWithNoRelationsResponse;
  return proto.rct.web.ListWithNoRelationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListWithNoRelationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListWithNoRelationsResponse}
 */
proto.rct.web.ListWithNoRelationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setBatch(value);
      break;
    case 2:
      var value = new grpccommon_common_pb.PageResponse;
      reader.readMessage(value,grpccommon_common_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPageresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListWithNoRelationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListWithNoRelationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListWithNoRelationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
  f = message.getPageresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpccommon_common_pb.PageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch batch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.getBatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.ListWithNoRelationsResponse} returns this
*/
proto.rct.web.ListWithNoRelationsResponse.prototype.setBatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListWithNoRelationsResponse} returns this
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.clearBatch = function() {
  return this.setBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional rct.common.PageResponse pageResponse = 2;
 * @return {?proto.rct.common.PageResponse}
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.getPageresponse = function() {
  return /** @type{?proto.rct.common.PageResponse} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageResponse, 2));
};


/**
 * @param {?proto.rct.common.PageResponse|undefined} value
 * @return {!proto.rct.web.ListWithNoRelationsResponse} returns this
*/
proto.rct.web.ListWithNoRelationsResponse.prototype.setPageresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListWithNoRelationsResponse} returns this
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.clearPageresponse = function() {
  return this.setPageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListWithNoRelationsResponse.prototype.hasPageresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListMyComponentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListMyComponentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListMyComponentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListMyComponentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    options: (f = msg.getOptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListMyComponentsRequest}
 */
proto.rct.web.ListMyComponentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListMyComponentsRequest;
  return proto.rct.web.ListMyComponentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListMyComponentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListMyComponentsRequest}
 */
proto.rct.web.ListMyComponentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListMyComponentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListMyComponentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListMyComponentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListMyComponentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListComponentsOptions options = 1;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.ListMyComponentsRequest.prototype.getOptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 1));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.ListMyComponentsRequest} returns this
*/
proto.rct.web.ListMyComponentsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListMyComponentsRequest} returns this
 */
proto.rct.web.ListMyComponentsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListMyComponentsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListMyComponentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListMyComponentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListMyComponentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListMyComponentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batch: (f = msg.getBatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListMyComponentsResponse}
 */
proto.rct.web.ListMyComponentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListMyComponentsResponse;
  return proto.rct.web.ListMyComponentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListMyComponentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListMyComponentsResponse}
 */
proto.rct.web.ListMyComponentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListMyComponentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListMyComponentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListMyComponentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListMyComponentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch batch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.ListMyComponentsResponse.prototype.getBatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.ListMyComponentsResponse} returns this
*/
proto.rct.web.ListMyComponentsResponse.prototype.setBatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListMyComponentsResponse} returns this
 */
proto.rct.web.ListMyComponentsResponse.prototype.clearBatch = function() {
  return this.setBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListMyComponentsResponse.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetComponentRequest}
 */
proto.rct.web.GetComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetComponentRequest;
  return proto.rct.web.GetComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetComponentRequest}
 */
proto.rct.web.GetComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.GetComponentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetComponentRequest} returns this
 */
proto.rct.web.GetComponentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetComponentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetComponentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetComponentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    component: (f = msg.getComponent()) && proto.rct.web.ComponentView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetComponentResponse}
 */
proto.rct.web.GetComponentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetComponentResponse;
  return proto.rct.web.GetComponentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetComponentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetComponentResponse}
 */
proto.rct.web.GetComponentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentView;
      reader.readMessage(value,proto.rct.web.ComponentView.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetComponentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetComponentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetComponentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentView.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentView component = 1;
 * @return {?proto.rct.web.ComponentView}
 */
proto.rct.web.GetComponentResponse.prototype.getComponent = function() {
  return /** @type{?proto.rct.web.ComponentView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentView, 1));
};


/**
 * @param {?proto.rct.web.ComponentView|undefined} value
 * @return {!proto.rct.web.GetComponentResponse} returns this
*/
proto.rct.web.GetComponentResponse.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetComponentResponse} returns this
 */
proto.rct.web.GetComponentResponse.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetComponentResponse.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetComponentByShortnameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetComponentByShortnameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetComponentByShortnameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentByShortnameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetComponentByShortnameRequest}
 */
proto.rct.web.GetComponentByShortnameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetComponentByShortnameRequest;
  return proto.rct.web.GetComponentByShortnameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetComponentByShortnameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetComponentByShortnameRequest}
 */
proto.rct.web.GetComponentByShortnameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetComponentByShortnameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetComponentByShortnameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetComponentByShortnameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentByShortnameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string shortName = 1;
 * @return {string}
 */
proto.rct.web.GetComponentByShortnameRequest.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetComponentByShortnameRequest} returns this
 */
proto.rct.web.GetComponentByShortnameRequest.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetComponentByShortnameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetComponentByShortnameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetComponentByShortnameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentByShortnameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    component: (f = msg.getComponent()) && proto.rct.web.ComponentView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetComponentByShortnameResponse}
 */
proto.rct.web.GetComponentByShortnameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetComponentByShortnameResponse;
  return proto.rct.web.GetComponentByShortnameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetComponentByShortnameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetComponentByShortnameResponse}
 */
proto.rct.web.GetComponentByShortnameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentView;
      reader.readMessage(value,proto.rct.web.ComponentView.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetComponentByShortnameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetComponentByShortnameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetComponentByShortnameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentByShortnameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentView.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentView component = 1;
 * @return {?proto.rct.web.ComponentView}
 */
proto.rct.web.GetComponentByShortnameResponse.prototype.getComponent = function() {
  return /** @type{?proto.rct.web.ComponentView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentView, 1));
};


/**
 * @param {?proto.rct.web.ComponentView|undefined} value
 * @return {!proto.rct.web.GetComponentByShortnameResponse} returns this
*/
proto.rct.web.GetComponentByShortnameResponse.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetComponentByShortnameResponse} returns this
 */
proto.rct.web.GetComponentByShortnameResponse.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetComponentByShortnameResponse.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetByShortNameWithRelationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetByShortNameWithRelationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetByShortNameWithRelationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    options: (f = msg.getOptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetByShortNameWithRelationsRequest}
 */
proto.rct.web.GetByShortNameWithRelationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetByShortNameWithRelationsRequest;
  return proto.rct.web.GetByShortNameWithRelationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetByShortNameWithRelationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetByShortNameWithRelationsRequest}
 */
proto.rct.web.GetByShortNameWithRelationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    case 2:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetByShortNameWithRelationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetByShortNameWithRelationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetByShortNameWithRelationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shortName = 1;
 * @return {string}
 */
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetByShortNameWithRelationsRequest} returns this
 */
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ListComponentsOptions options = 2;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 2));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.GetByShortNameWithRelationsRequest} returns this
*/
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetByShortNameWithRelationsRequest} returns this
 */
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetByShortNameWithRelationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetByShortNameWithRelationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetByShortNameWithRelationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetByShortNameWithRelationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    component: (f = msg.getComponent()) && proto.rct.web.ComponentView.toObject(includeInstance, f),
    dependenciesbatch: (f = msg.getDependenciesbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f),
    dependentsbatch: (f = msg.getDependentsbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetByShortNameWithRelationsResponse;
  return proto.rct.web.GetByShortNameWithRelationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetByShortNameWithRelationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentView;
      reader.readMessage(value,proto.rct.web.ComponentView.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    case 2:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependenciesbatch(value);
      break;
    case 3:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependentsbatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetByShortNameWithRelationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetByShortNameWithRelationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetByShortNameWithRelationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentView.serializeBinaryToWriter
    );
  }
  f = message.getDependenciesbatch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
  f = message.getDependentsbatch();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentView component = 1;
 * @return {?proto.rct.web.ComponentView}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.getComponent = function() {
  return /** @type{?proto.rct.web.ComponentView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentView, 1));
};


/**
 * @param {?proto.rct.web.ComponentView|undefined} value
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse} returns this
*/
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse} returns this
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ComponentBatch dependenciesBatch = 2;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.getDependenciesbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 2));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse} returns this
*/
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.setDependenciesbatch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse} returns this
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.clearDependenciesbatch = function() {
  return this.setDependenciesbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.hasDependenciesbatch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ComponentBatch dependentsBatch = 3;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.getDependentsbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 3));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse} returns this
*/
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.setDependentsbatch = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetByShortNameWithRelationsResponse} returns this
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.clearDependentsbatch = function() {
  return this.setDependentsbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetByShortNameWithRelationsResponse.prototype.hasDependentsbatch = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependencyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dependantid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentRelationRequest}
 */
proto.rct.web.ComponentRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentRelationRequest;
  return proto.rct.web.ComponentRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentRelationRequest}
 */
proto.rct.web.ComponentRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDependencyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDependantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependencyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDependantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dependencyId = 1;
 * @return {string}
 */
proto.rct.web.ComponentRelationRequest.prototype.getDependencyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentRelationRequest} returns this
 */
proto.rct.web.ComponentRelationRequest.prototype.setDependencyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dependantId = 2;
 * @return {string}
 */
proto.rct.web.ComponentRelationRequest.prototype.getDependantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentRelationRequest} returns this
 */
proto.rct.web.ComponentRelationRequest.prototype.setDependantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentRelationResponse}
 */
proto.rct.web.ComponentRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentRelationResponse;
  return proto.rct.web.ComponentRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentRelationResponse}
 */
proto.rct.web.ComponentRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentListRelationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentListRelationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentListRelationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentListRelationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    options: (f = msg.getOptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentListRelationsRequest}
 */
proto.rct.web.ComponentListRelationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentListRelationsRequest;
  return proto.rct.web.ComponentListRelationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentListRelationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentListRelationsRequest}
 */
proto.rct.web.ComponentListRelationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentListRelationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentListRelationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentListRelationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentListRelationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.ComponentListRelationsRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ComponentListRelationsRequest} returns this
 */
proto.rct.web.ComponentListRelationsRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ListComponentsOptions options = 2;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.ComponentListRelationsRequest.prototype.getOptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 2));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.ComponentListRelationsRequest} returns this
*/
proto.rct.web.ComponentListRelationsRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentListRelationsRequest} returns this
 */
proto.rct.web.ComponentListRelationsRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentListRelationsRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ComponentListRelationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ComponentListRelationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ComponentListRelationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentListRelationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependenciesbatch: (f = msg.getDependenciesbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f),
    dependentsbatch: (f = msg.getDependentsbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ComponentListRelationsResponse}
 */
proto.rct.web.ComponentListRelationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ComponentListRelationsResponse;
  return proto.rct.web.ComponentListRelationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ComponentListRelationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ComponentListRelationsResponse}
 */
proto.rct.web.ComponentListRelationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependenciesbatch(value);
      break;
    case 2:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependentsbatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ComponentListRelationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ComponentListRelationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ComponentListRelationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ComponentListRelationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependenciesbatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
  f = message.getDependentsbatch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch dependenciesBatch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.ComponentListRelationsResponse.prototype.getDependenciesbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.ComponentListRelationsResponse} returns this
*/
proto.rct.web.ComponentListRelationsResponse.prototype.setDependenciesbatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentListRelationsResponse} returns this
 */
proto.rct.web.ComponentListRelationsResponse.prototype.clearDependenciesbatch = function() {
  return this.setDependenciesbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentListRelationsResponse.prototype.hasDependenciesbatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ComponentBatch dependentsBatch = 2;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.ComponentListRelationsResponse.prototype.getDependentsbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 2));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.ComponentListRelationsResponse} returns this
*/
proto.rct.web.ComponentListRelationsResponse.prototype.setDependentsbatch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ComponentListRelationsResponse} returns this
 */
proto.rct.web.ComponentListRelationsResponse.prototype.clearDependentsbatch = function() {
  return this.setDependentsbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ComponentListRelationsResponse.prototype.hasDependentsbatch = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteComponentRequest}
 */
proto.rct.web.DeleteComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteComponentRequest;
  return proto.rct.web.DeleteComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteComponentRequest}
 */
proto.rct.web.DeleteComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.DeleteComponentRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.DeleteComponentRequest} returns this
 */
proto.rct.web.DeleteComponentRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteComponentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteComponentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteComponentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteComponentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteComponentResponse}
 */
proto.rct.web.DeleteComponentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteComponentResponse;
  return proto.rct.web.DeleteComponentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteComponentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteComponentResponse}
 */
proto.rct.web.DeleteComponentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteComponentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteComponentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteComponentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteComponentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.AddComponentLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.AddComponentLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.AddComponentLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddComponentLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkmeta: (f = msg.getLinkmeta()) && grpcweb_link_pb.LinkMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.AddComponentLinkRequest}
 */
proto.rct.web.AddComponentLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.AddComponentLinkRequest;
  return proto.rct.web.AddComponentLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.AddComponentLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.AddComponentLinkRequest}
 */
proto.rct.web.AddComponentLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = new grpcweb_link_pb.LinkMeta;
      reader.readMessage(value,grpcweb_link_pb.LinkMeta.deserializeBinaryFromReader);
      msg.setLinkmeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.AddComponentLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.AddComponentLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.AddComponentLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddComponentLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkmeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpcweb_link_pb.LinkMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.AddComponentLinkRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.AddComponentLinkRequest} returns this
 */
proto.rct.web.AddComponentLinkRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LinkMeta linkMeta = 2;
 * @return {?proto.rct.web.LinkMeta}
 */
proto.rct.web.AddComponentLinkRequest.prototype.getLinkmeta = function() {
  return /** @type{?proto.rct.web.LinkMeta} */ (
    jspb.Message.getWrapperField(this, grpcweb_link_pb.LinkMeta, 2));
};


/**
 * @param {?proto.rct.web.LinkMeta|undefined} value
 * @return {!proto.rct.web.AddComponentLinkRequest} returns this
*/
proto.rct.web.AddComponentLinkRequest.prototype.setLinkmeta = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.AddComponentLinkRequest} returns this
 */
proto.rct.web.AddComponentLinkRequest.prototype.clearLinkmeta = function() {
  return this.setLinkmeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.AddComponentLinkRequest.prototype.hasLinkmeta = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.AddComponentLinkResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.AddComponentLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.AddComponentLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.AddComponentLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddComponentLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    grpcweb_link_pb.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.AddComponentLinkResponse}
 */
proto.rct.web.AddComponentLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.AddComponentLinkResponse;
  return proto.rct.web.AddComponentLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.AddComponentLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.AddComponentLinkResponse}
 */
proto.rct.web.AddComponentLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpcweb_link_pb.Link;
      reader.readMessage(value,grpcweb_link_pb.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.AddComponentLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.AddComponentLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.AddComponentLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddComponentLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      grpcweb_link_pb.Link.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Link links = 1;
 * @return {!Array<!proto.rct.web.Link>}
 */
proto.rct.web.AddComponentLinkResponse.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.rct.web.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_link_pb.Link, 1));
};


/**
 * @param {!Array<!proto.rct.web.Link>} value
 * @return {!proto.rct.web.AddComponentLinkResponse} returns this
*/
proto.rct.web.AddComponentLinkResponse.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Link}
 */
proto.rct.web.AddComponentLinkResponse.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.AddComponentLinkResponse} returns this
 */
proto.rct.web.AddComponentLinkResponse.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateComponentLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateComponentLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkmeta: (f = msg.getLinkmeta()) && grpcweb_link_pb.LinkMeta.toObject(includeInstance, f),
    updatemask: (f = msg.getUpdatemask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateComponentLinkRequest}
 */
proto.rct.web.UpdateComponentLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateComponentLinkRequest;
  return proto.rct.web.UpdateComponentLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateComponentLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateComponentLinkRequest}
 */
proto.rct.web.UpdateComponentLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkid(value);
      break;
    case 3:
      var value = new grpcweb_link_pb.LinkMeta;
      reader.readMessage(value,grpcweb_link_pb.LinkMeta.deserializeBinaryFromReader);
      msg.setLinkmeta(value);
      break;
    case 4:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdatemask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateComponentLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateComponentLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkmeta();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpcweb_link_pb.LinkMeta.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemask();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.UpdateComponentLinkRequest} returns this
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string linkId = 2;
 * @return {string}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.getLinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.UpdateComponentLinkRequest} returns this
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.setLinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LinkMeta linkMeta = 3;
 * @return {?proto.rct.web.LinkMeta}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.getLinkmeta = function() {
  return /** @type{?proto.rct.web.LinkMeta} */ (
    jspb.Message.getWrapperField(this, grpcweb_link_pb.LinkMeta, 3));
};


/**
 * @param {?proto.rct.web.LinkMeta|undefined} value
 * @return {!proto.rct.web.UpdateComponentLinkRequest} returns this
*/
proto.rct.web.UpdateComponentLinkRequest.prototype.setLinkmeta = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateComponentLinkRequest} returns this
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.clearLinkmeta = function() {
  return this.setLinkmeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.hasLinkmeta = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.FieldMask updateMask = 4;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.getUpdatemask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 4));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.rct.web.UpdateComponentLinkRequest} returns this
*/
proto.rct.web.UpdateComponentLinkRequest.prototype.setUpdatemask = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateComponentLinkRequest} returns this
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.clearUpdatemask = function() {
  return this.setUpdatemask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateComponentLinkRequest.prototype.hasUpdatemask = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.UpdateComponentLinkResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateComponentLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateComponentLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateComponentLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    grpcweb_link_pb.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateComponentLinkResponse}
 */
proto.rct.web.UpdateComponentLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateComponentLinkResponse;
  return proto.rct.web.UpdateComponentLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateComponentLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateComponentLinkResponse}
 */
proto.rct.web.UpdateComponentLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpcweb_link_pb.Link;
      reader.readMessage(value,grpcweb_link_pb.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateComponentLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateComponentLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateComponentLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateComponentLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      grpcweb_link_pb.Link.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Link links = 1;
 * @return {!Array<!proto.rct.web.Link>}
 */
proto.rct.web.UpdateComponentLinkResponse.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.rct.web.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_link_pb.Link, 1));
};


/**
 * @param {!Array<!proto.rct.web.Link>} value
 * @return {!proto.rct.web.UpdateComponentLinkResponse} returns this
*/
proto.rct.web.UpdateComponentLinkResponse.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Link}
 */
proto.rct.web.UpdateComponentLinkResponse.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.UpdateComponentLinkResponse} returns this
 */
proto.rct.web.UpdateComponentLinkResponse.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.RemComponentLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.RemComponentLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.RemComponentLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemComponentLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.RemComponentLinkRequest}
 */
proto.rct.web.RemComponentLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.RemComponentLinkRequest;
  return proto.rct.web.RemComponentLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.RemComponentLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.RemComponentLinkRequest}
 */
proto.rct.web.RemComponentLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.RemComponentLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.RemComponentLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.RemComponentLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemComponentLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.RemComponentLinkRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.RemComponentLinkRequest} returns this
 */
proto.rct.web.RemComponentLinkRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string linkId = 2;
 * @return {string}
 */
proto.rct.web.RemComponentLinkRequest.prototype.getLinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.RemComponentLinkRequest} returns this
 */
proto.rct.web.RemComponentLinkRequest.prototype.setLinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.RemComponentLinkResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.RemComponentLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.RemComponentLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.RemComponentLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemComponentLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    grpcweb_link_pb.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.RemComponentLinkResponse}
 */
proto.rct.web.RemComponentLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.RemComponentLinkResponse;
  return proto.rct.web.RemComponentLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.RemComponentLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.RemComponentLinkResponse}
 */
proto.rct.web.RemComponentLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpcweb_link_pb.Link;
      reader.readMessage(value,grpcweb_link_pb.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.RemComponentLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.RemComponentLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.RemComponentLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemComponentLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      grpcweb_link_pb.Link.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Link links = 1;
 * @return {!Array<!proto.rct.web.Link>}
 */
proto.rct.web.RemComponentLinkResponse.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.rct.web.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_link_pb.Link, 1));
};


/**
 * @param {!Array<!proto.rct.web.Link>} value
 * @return {!proto.rct.web.RemComponentLinkResponse} returns this
*/
proto.rct.web.RemComponentLinkResponse.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Link}
 */
proto.rct.web.RemComponentLinkResponse.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.RemComponentLinkResponse} returns this
 */
proto.rct.web.RemComponentLinkResponse.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.AddDependentsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.AddDependentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.AddDependentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.AddDependentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dependentidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    listoptions: (f = msg.getListoptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.AddDependentsRequest}
 */
proto.rct.web.AddDependentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.AddDependentsRequest;
  return proto.rct.web.AddDependentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.AddDependentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.AddDependentsRequest}
 */
proto.rct.web.AddDependentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDependentids(value);
      break;
    case 3:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setListoptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.AddDependentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.AddDependentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.AddDependentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDependentidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getListoptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.AddDependentsRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.AddDependentsRequest} returns this
 */
proto.rct.web.AddDependentsRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string dependentIds = 2;
 * @return {!Array<string>}
 */
proto.rct.web.AddDependentsRequest.prototype.getDependentidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rct.web.AddDependentsRequest} returns this
 */
proto.rct.web.AddDependentsRequest.prototype.setDependentidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rct.web.AddDependentsRequest} returns this
 */
proto.rct.web.AddDependentsRequest.prototype.addDependentids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.AddDependentsRequest} returns this
 */
proto.rct.web.AddDependentsRequest.prototype.clearDependentidsList = function() {
  return this.setDependentidsList([]);
};


/**
 * optional ListComponentsOptions listOptions = 3;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.AddDependentsRequest.prototype.getListoptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 3));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.AddDependentsRequest} returns this
*/
proto.rct.web.AddDependentsRequest.prototype.setListoptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.AddDependentsRequest} returns this
 */
proto.rct.web.AddDependentsRequest.prototype.clearListoptions = function() {
  return this.setListoptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.AddDependentsRequest.prototype.hasListoptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.AddDependentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.AddDependentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.AddDependentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependentsbatch: (f = msg.getDependentsbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.AddDependentsResponse}
 */
proto.rct.web.AddDependentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.AddDependentsResponse;
  return proto.rct.web.AddDependentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.AddDependentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.AddDependentsResponse}
 */
proto.rct.web.AddDependentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependentsbatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.AddDependentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.AddDependentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.AddDependentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependentsbatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch dependentsBatch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.AddDependentsResponse.prototype.getDependentsbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.AddDependentsResponse} returns this
*/
proto.rct.web.AddDependentsResponse.prototype.setDependentsbatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.AddDependentsResponse} returns this
 */
proto.rct.web.AddDependentsResponse.prototype.clearDependentsbatch = function() {
  return this.setDependentsbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.AddDependentsResponse.prototype.hasDependentsbatch = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.AddDependenciesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.AddDependenciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.AddDependenciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.AddDependenciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependenciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dependenciesidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    listoptions: (f = msg.getListoptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.AddDependenciesRequest}
 */
proto.rct.web.AddDependenciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.AddDependenciesRequest;
  return proto.rct.web.AddDependenciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.AddDependenciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.AddDependenciesRequest}
 */
proto.rct.web.AddDependenciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDependenciesids(value);
      break;
    case 3:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setListoptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.AddDependenciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.AddDependenciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.AddDependenciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependenciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDependenciesidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getListoptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.AddDependenciesRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.AddDependenciesRequest} returns this
 */
proto.rct.web.AddDependenciesRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string dependenciesIds = 2;
 * @return {!Array<string>}
 */
proto.rct.web.AddDependenciesRequest.prototype.getDependenciesidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rct.web.AddDependenciesRequest} returns this
 */
proto.rct.web.AddDependenciesRequest.prototype.setDependenciesidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rct.web.AddDependenciesRequest} returns this
 */
proto.rct.web.AddDependenciesRequest.prototype.addDependenciesids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.AddDependenciesRequest} returns this
 */
proto.rct.web.AddDependenciesRequest.prototype.clearDependenciesidsList = function() {
  return this.setDependenciesidsList([]);
};


/**
 * optional ListComponentsOptions listOptions = 3;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.AddDependenciesRequest.prototype.getListoptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 3));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.AddDependenciesRequest} returns this
*/
proto.rct.web.AddDependenciesRequest.prototype.setListoptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.AddDependenciesRequest} returns this
 */
proto.rct.web.AddDependenciesRequest.prototype.clearListoptions = function() {
  return this.setListoptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.AddDependenciesRequest.prototype.hasListoptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.AddDependenciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.AddDependenciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.AddDependenciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependenciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependenciesbatch: (f = msg.getDependenciesbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.AddDependenciesResponse}
 */
proto.rct.web.AddDependenciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.AddDependenciesResponse;
  return proto.rct.web.AddDependenciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.AddDependenciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.AddDependenciesResponse}
 */
proto.rct.web.AddDependenciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependenciesbatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.AddDependenciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.AddDependenciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.AddDependenciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.AddDependenciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependenciesbatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch dependenciesBatch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.AddDependenciesResponse.prototype.getDependenciesbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.AddDependenciesResponse} returns this
*/
proto.rct.web.AddDependenciesResponse.prototype.setDependenciesbatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.AddDependenciesResponse} returns this
 */
proto.rct.web.AddDependenciesResponse.prototype.clearDependenciesbatch = function() {
  return this.setDependenciesbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.AddDependenciesResponse.prototype.hasDependenciesbatch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.RemoveDependantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.RemoveDependantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.RemoveDependantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dependantcomponentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    listoptions: (f = msg.getListoptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.RemoveDependantRequest}
 */
proto.rct.web.RemoveDependantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.RemoveDependantRequest;
  return proto.rct.web.RemoveDependantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.RemoveDependantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.RemoveDependantRequest}
 */
proto.rct.web.RemoveDependantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDependantcomponentid(value);
      break;
    case 3:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setListoptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.RemoveDependantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.RemoveDependantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.RemoveDependantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDependantcomponentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getListoptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.RemoveDependantRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.RemoveDependantRequest} returns this
 */
proto.rct.web.RemoveDependantRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dependantComponentId = 2;
 * @return {string}
 */
proto.rct.web.RemoveDependantRequest.prototype.getDependantcomponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.RemoveDependantRequest} returns this
 */
proto.rct.web.RemoveDependantRequest.prototype.setDependantcomponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ListComponentsOptions listOptions = 3;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.RemoveDependantRequest.prototype.getListoptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 3));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.RemoveDependantRequest} returns this
*/
proto.rct.web.RemoveDependantRequest.prototype.setListoptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.RemoveDependantRequest} returns this
 */
proto.rct.web.RemoveDependantRequest.prototype.clearListoptions = function() {
  return this.setListoptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.RemoveDependantRequest.prototype.hasListoptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.RemoveDependantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.RemoveDependantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.RemoveDependantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependentsbatch: (f = msg.getDependentsbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.RemoveDependantResponse}
 */
proto.rct.web.RemoveDependantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.RemoveDependantResponse;
  return proto.rct.web.RemoveDependantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.RemoveDependantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.RemoveDependantResponse}
 */
proto.rct.web.RemoveDependantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependentsbatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.RemoveDependantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.RemoveDependantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.RemoveDependantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependentsbatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch dependentsBatch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.RemoveDependantResponse.prototype.getDependentsbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.RemoveDependantResponse} returns this
*/
proto.rct.web.RemoveDependantResponse.prototype.setDependentsbatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.RemoveDependantResponse} returns this
 */
proto.rct.web.RemoveDependantResponse.prototype.clearDependentsbatch = function() {
  return this.setDependentsbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.RemoveDependantResponse.prototype.hasDependentsbatch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.RemoveDependencyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.RemoveDependencyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.RemoveDependencyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependencyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dependencycomponentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    listoptions: (f = msg.getListoptions()) && proto.rct.web.ListComponentsOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.RemoveDependencyRequest}
 */
proto.rct.web.RemoveDependencyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.RemoveDependencyRequest;
  return proto.rct.web.RemoveDependencyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.RemoveDependencyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.RemoveDependencyRequest}
 */
proto.rct.web.RemoveDependencyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDependencycomponentid(value);
      break;
    case 3:
      var value = new proto.rct.web.ListComponentsOptions;
      reader.readMessage(value,proto.rct.web.ListComponentsOptions.deserializeBinaryFromReader);
      msg.setListoptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.RemoveDependencyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.RemoveDependencyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.RemoveDependencyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependencyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDependencycomponentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getListoptions();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.ListComponentsOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.RemoveDependencyRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.RemoveDependencyRequest} returns this
 */
proto.rct.web.RemoveDependencyRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dependencyComponentId = 2;
 * @return {string}
 */
proto.rct.web.RemoveDependencyRequest.prototype.getDependencycomponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.RemoveDependencyRequest} returns this
 */
proto.rct.web.RemoveDependencyRequest.prototype.setDependencycomponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ListComponentsOptions listOptions = 3;
 * @return {?proto.rct.web.ListComponentsOptions}
 */
proto.rct.web.RemoveDependencyRequest.prototype.getListoptions = function() {
  return /** @type{?proto.rct.web.ListComponentsOptions} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ListComponentsOptions, 3));
};


/**
 * @param {?proto.rct.web.ListComponentsOptions|undefined} value
 * @return {!proto.rct.web.RemoveDependencyRequest} returns this
*/
proto.rct.web.RemoveDependencyRequest.prototype.setListoptions = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.RemoveDependencyRequest} returns this
 */
proto.rct.web.RemoveDependencyRequest.prototype.clearListoptions = function() {
  return this.setListoptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.RemoveDependencyRequest.prototype.hasListoptions = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.RemoveDependencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.RemoveDependencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.RemoveDependencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dependenciesbatch: (f = msg.getDependenciesbatch()) && proto.rct.web.ComponentBatch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.RemoveDependencyResponse}
 */
proto.rct.web.RemoveDependencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.RemoveDependencyResponse;
  return proto.rct.web.RemoveDependencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.RemoveDependencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.RemoveDependencyResponse}
 */
proto.rct.web.RemoveDependencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentBatch;
      reader.readMessage(value,proto.rct.web.ComponentBatch.deserializeBinaryFromReader);
      msg.setDependenciesbatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.RemoveDependencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.RemoveDependencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.RemoveDependencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.RemoveDependencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDependenciesbatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentBatch.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentBatch dependenciesBatch = 1;
 * @return {?proto.rct.web.ComponentBatch}
 */
proto.rct.web.RemoveDependencyResponse.prototype.getDependenciesbatch = function() {
  return /** @type{?proto.rct.web.ComponentBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentBatch, 1));
};


/**
 * @param {?proto.rct.web.ComponentBatch|undefined} value
 * @return {!proto.rct.web.RemoveDependencyResponse} returns this
*/
proto.rct.web.RemoveDependencyResponse.prototype.setDependenciesbatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.RemoveDependencyResponse} returns this
 */
proto.rct.web.RemoveDependencyResponse.prototype.clearDependenciesbatch = function() {
  return this.setDependenciesbatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.RemoveDependencyResponse.prototype.hasDependenciesbatch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetComponentDetailsForPopupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetComponentDetailsForPopupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetComponentDetailsForPopupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentDetailsForPopupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetComponentDetailsForPopupRequest}
 */
proto.rct.web.GetComponentDetailsForPopupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetComponentDetailsForPopupRequest;
  return proto.rct.web.GetComponentDetailsForPopupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetComponentDetailsForPopupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetComponentDetailsForPopupRequest}
 */
proto.rct.web.GetComponentDetailsForPopupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetComponentDetailsForPopupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetComponentDetailsForPopupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetComponentDetailsForPopupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentDetailsForPopupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.GetComponentDetailsForPopupRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetComponentDetailsForPopupRequest} returns this
 */
proto.rct.web.GetComponentDetailsForPopupRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.GetComponentDetailsForPopupResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetComponentDetailsForPopupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetComponentDetailsForPopupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentDetailsForPopupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    component: (f = msg.getComponent()) && proto.rct.web.ComponentView.toObject(includeInstance, f),
    recenteventsList: jspb.Message.toObjectList(msg.getRecenteventsList(),
    grpcweb_xevent_pb.XEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetComponentDetailsForPopupResponse}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetComponentDetailsForPopupResponse;
  return proto.rct.web.GetComponentDetailsForPopupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetComponentDetailsForPopupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetComponentDetailsForPopupResponse}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ComponentView;
      reader.readMessage(value,proto.rct.web.ComponentView.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    case 2:
      var value = new grpcweb_xevent_pb.XEvent;
      reader.readMessage(value,grpcweb_xevent_pb.XEvent.deserializeBinaryFromReader);
      msg.addRecentevents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetComponentDetailsForPopupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetComponentDetailsForPopupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetComponentDetailsForPopupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ComponentView.serializeBinaryToWriter
    );
  }
  f = message.getRecenteventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      grpcweb_xevent_pb.XEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComponentView component = 1;
 * @return {?proto.rct.web.ComponentView}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.getComponent = function() {
  return /** @type{?proto.rct.web.ComponentView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ComponentView, 1));
};


/**
 * @param {?proto.rct.web.ComponentView|undefined} value
 * @return {!proto.rct.web.GetComponentDetailsForPopupResponse} returns this
*/
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetComponentDetailsForPopupResponse} returns this
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated XEvent recentEvents = 2;
 * @return {!Array<!proto.rct.web.XEvent>}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.getRecenteventsList = function() {
  return /** @type{!Array<!proto.rct.web.XEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, grpcweb_xevent_pb.XEvent, 2));
};


/**
 * @param {!Array<!proto.rct.web.XEvent>} value
 * @return {!proto.rct.web.GetComponentDetailsForPopupResponse} returns this
*/
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.setRecenteventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.rct.web.XEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.XEvent}
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.addRecentevents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.rct.web.XEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.GetComponentDetailsForPopupResponse} returns this
 */
proto.rct.web.GetComponentDetailsForPopupResponse.prototype.clearRecenteventsList = function() {
  return this.setRecenteventsList([]);
};


goog.object.extend(exports, proto.rct.web);
