import { ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const updateUser = (payload = {}): ActionType => ({
  type: actionTypes.users.current.update,
  payload,
});

export const fetchUser = (payload = {}): ActionType => ({
  type: actionTypes.users.current.fetch,
  payload,
});

export const removeUser = (payload = {}): ActionType => ({
  type: actionTypes.users.current.remove,
  payload,
});

export const fetchAllUsers = (pageSize?: number): ActionType => ({
  type: actionTypes.users.fetch,
  payload: pageSize,
});

export const fetchNextUserListPage = (pageSize?: number): ActionType => ({
  type: actionTypes.users.fetchNextPage,
  payload: pageSize,
});

export const fetchPrevUserListPage = (pageSize?: number): ActionType => ({
  type: actionTypes.users.fetchPrevPage,
  payload: pageSize,
});

export const saveSelectedUserId = (payload: Record<string, boolean>): ActionType => ({
  type: actionTypes.users.selectUserId,
  payload,
});

export const clearSelectedUsers = (payload = {}): ActionType => ({
  type: actionTypes.users.clearSelectedUsers,
  payload,
});
