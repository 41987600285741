import {
  CreateSourceFormDataWithAuthType,
  EventSourcesInTheList,
  EditSourceFormData,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { wasSuccessful } from './success';
import { isLoading } from './loading';
import { RootState } from '../reducers';
import { getErrorMessage } from './errors';

export const getEventSourceList = (state: RootState): EventSourcesInTheList[] => {
  return state.eventSources.list;
};

export const isFetchingEventSourceList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.eventSources.fetchList);
};

export const getFetchEventSourceListError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.eventSources.fetchList);
};

export const isFetchingEventSource = (state: RootState): boolean => {
  return isLoading(state, actionTypes.eventSources.fetchOne);
};

export const getFetchEventSourceError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.eventSources.fetchOne);
};

export const getSelectedEventSource = (state: RootState): EditSourceFormData => {
  return state.eventSources.selected;
};

export const getSelectedEventSourceId = (state: RootState): string | undefined => {
  return state.eventSources.selected.id;
};

export const getSavedNewEventSource = (state: RootState): CreateSourceFormDataWithAuthType => {
  return state.eventSources.new;
};

export const isCreatingEventSource = (state: RootState): boolean => {
  return isLoading(state, actionTypes.eventSources.create);
};

export const getCreateEventSourceError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.eventSources.create);
};

export const createdEventSourceSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.eventSources.create);
};

export const isUpdatingEventSource = (state: RootState): boolean => {
  return isLoading(state, actionTypes.eventSources.update);
};

export const getUpdateEventSourceError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.eventSources.update);
};

export const updatedEventSourceSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.eventSources.update);
};

export const getDeleteEventSourceError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.eventSources.delete);
};

export const isDeletingEventSource = (state: RootState): boolean => {
  return isLoading(state, actionTypes.eventSources.delete);
};

export const deletedEventSourceSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.eventSources.delete);
};
