import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

type PopupContentProps = {
  isBorderBottom: boolean;
  isBorderTop: boolean;
};

export const PopupContent = styled.div<PopupContentProps>`
  display: flex;
  min-height: 5rem;
  flex-direction: column;
  display: flex;
  border-radius: 0.5rem;
  max-height: 15.5rem;
  overflow: auto;
  position: relative;
  background: ${props => props.theme.palettes.inverse};

  ${props =>
    props.isBorderBottom &&
    css`
      box-shadow: ${props.theme.effects.shadowBottom};
    `};

  ${props =>
    props.isBorderTop &&
    css`
      box-shadow: ${props.theme.effects.shadowTop};
    `};

  ${props =>
    props.isBorderBottom &&
    props.isBorderTop &&
    css`
      box-shadow: ${props.theme.effects.shadowTop}, ${props.theme.effects.shadowBottom};
    `};
`;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  width: 100%;
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  border-radius: 0.5rem;
  background: ${props => props.theme.palettes.inverse};

  ${props => props.theme.media.medium`
    width: 26rem;
  `};
`;

export const TriggerWrapper = styled.div`
  display: inline-flex;
`;

export const PopupContentHeader = styled.div`
  background: ${props => props.theme.palettes.headerBackground};
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem 0.5rem 0 0;
  gap: 0.5rem;

  span {
    font-size: 0.75rem;
  }
`;

export const PopupMeta = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const PopupDetails = styled.div`
  padding: 0.5rem 1rem 1rem 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Metafield = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h5``;

export const Value = styled.p``;
