import styled, { css } from 'styled-components';

type EmptyProps = {
  hasChildren: boolean;
};

export const Empty = styled.div<EmptyProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  border: 2px dashed ${({ theme }) => theme.palettes.border};
  border-radius: 0.5rem;

  p {
    color: ${({ theme }) => theme.palettes.placeholder};

    ${props =>
      props.hasChildren &&
      css`
        margin-bottom: 1.5rem;
      `}
  }
`;
