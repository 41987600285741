import { FunctionComponent, ElementType, ReactNode, useRef } from 'react';
import { Loader } from '../Loader';
import { Empty } from '../Empty';
import { useIsEllipsisActive } from '../../hooks';
import { theme } from '../../definitions';
import Tooltip from '../Tooltip';
import * as Styled from './List.styles';

export type ListProps = {
  items: Array<Record<string, any>>;
  columns: Array<string | ReactNode>;
  hideOnSmall: Array<string | ReactNode>;
  itemKey: string;
  onItemClick?: (id: string) => void;
  isLoading?: boolean;
  emptyText?: string;
  emptyAction?: ReactNode;
  columnTitles?: Array<string | ReactNode>;
  minWidth?: string;
  compactFieldList?: Array<string | ReactNode>;
  compactFieldWidth?: string;
};

type IListView = FunctionComponent<ListProps> & {
  Item: ElementType;
};

const ItemInnerEllipsis: FunctionComponent = ({ children }) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const shouldDisplayTooltip = useIsEllipsisActive(ref);

  return shouldDisplayTooltip ? (
    <Tooltip content={children} color={theme.palettes.main}>
      <Styled.ItemInner ref={ref}>{children}</Styled.ItemInner>
    </Tooltip>
  ) : (
    <Styled.ItemInner ref={ref}>{children}</Styled.ItemInner>
  );
};

export const ListView: IListView = ({
  items,
  onItemClick,
  isLoading,
  emptyText = 'Empty',
  columns,
  itemKey,
  hideOnSmall,
  emptyAction,
  columnTitles,
  minWidth,
  compactFieldList,
  compactFieldWidth,
}) => {
  const isEmpty = items.length === 0;
  return (
    <Styled.Container minWidth={minWidth}>
      {isLoading ? (
        <Loader.Container>
          <Loader />
        </Loader.Container>
      ) : isEmpty ? (
        <Empty text={emptyText}>{emptyAction}</Empty>
      ) : (
        <>
          {columnTitles && (
            <Styled.TitleWrapper isClickable={false}>
              <Styled.Item>
                {columnTitles.map((columnTitle: any, index: number) => {
                  const Title = columnTitle;
                  const shouldHideOnSmall = hideOnSmall.includes(columns[index]);
                  const isCompact =
                    columnTitle === 'function' ||
                    columnTitle === '' ||
                    compactFieldList?.find(field => field === columns[index]);
                  return typeof columnTitle === 'function' ? (
                    <Styled.Declaration
                      key={index}
                      hideOnSmall={shouldHideOnSmall}
                      isCompact={isCompact}
                      compactFieldWidth={compactFieldWidth}
                    >
                      <Title />
                    </Styled.Declaration>
                  ) : (
                    <Styled.Title
                      key={index}
                      hideOnSmall={shouldHideOnSmall}
                      isCompact={isCompact}
                      compactFieldWidth={compactFieldWidth}
                    >
                      {columnTitle}
                    </Styled.Title>
                  );
                })}
              </Styled.Item>
            </Styled.TitleWrapper>
          )}
          {items.map((item: Record<string, any>, index: number) => {
            return (
              <Styled.ItemWrapper
                isClickable={Boolean(onItemClick)}
                onClick={() => onItemClick && onItemClick(item[itemKey])}
                key={item[itemKey] || index}
              >
                <Styled.Item>
                  {columns.map((column: any, index: number) => {
                    const Column = column;
                    const shouldHideOnSmall = hideOnSmall.includes(column);

                    const isCompact =
                      (columnTitles &&
                        (typeof columnTitles[index] === 'function' ||
                          columnTitles[index] === '')) ||
                      compactFieldList?.find(field => field === column);
                    return typeof column === 'function' ? (
                      <Styled.Declaration
                        key={index}
                        hideOnSmall={shouldHideOnSmall}
                        isCompact={isCompact}
                        compactFieldWidth={compactFieldWidth}
                      >
                        <Column {...item} />
                      </Styled.Declaration>
                    ) : (
                      <Styled.Declaration
                        key={index}
                        hideOnSmall={shouldHideOnSmall}
                        isCompact={isCompact}
                        compactFieldWidth={compactFieldWidth}
                      >
                        <ItemInnerEllipsis>{item[column]}</ItemInnerEllipsis>
                      </Styled.Declaration>
                    );
                  })}
                </Styled.Item>
              </Styled.ItemWrapper>
            );
          })}
        </>
      )}
    </Styled.Container>
  );
};

ListView.Item = Styled.Item;
