import styled from 'styled-components';

type DividerProps = {
  width?: string;
  disableVW: boolean;
  margin?: string;
};

export const HorizontalDivider = styled.div<DividerProps>`
  width: ${props => (props.width ? props.width : '5rem')};
  height: 0.4rem;
  margin: ${props => (props.margin ? props.margin : (props.disableVW ? '2rem' : '4vw'))} auto;
  background-color: ${({ theme }) => theme.palettes.secondaryHighlight};
`;

export const VerticalDivider = styled.div<DividerProps>`
  max-height: 8rem;
  border-right: 0.4rem solid ${({ theme }) => theme.palettes.secondaryHighlight};
  margin: 0 ${props => (props.disableVW ? '2rem' : '4vw')};
`;
