import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import { ComponentRefInTheListWithDependencyState, Appearance } from '../../definitions';
import LinkDependencyTable from '../../tables/LinkDependencyTable';
import { Icon } from '../../components/Icon';

type LinkDependencyProps = {
  onClose: () => void;
  componentName?: string;
  componentId: string;
  isFetching: boolean;
  isLinking: boolean;
  onLink: () => void;
  componentList: ComponentRefInTheListWithDependencyState[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfComponents: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onCheckComponentCheckbox: (component: Record<string, boolean>) => void;
  numberOfSelectedComponents: number;
  onClearSelectedComponents: () => void;
  disabledComponentIds: Set<string>;
  selectedDependencyState: Record<string, boolean>;
  isOpen: boolean;
  title: string;
  getActionTitle: (numberOfSelectedComponents: number) => string;
};

export const LinkDependencyView: FunctionComponent<LinkDependencyProps> = ({
  children,
  onClose,
  onLink,
  onNextPage,
  onPrevPage,
  onCheckComponentCheckbox,
  onClearSelectedComponents,
  isLinking,
  isFetching,
  componentName,
  componentList,
  componentId,
  numberOfComponents,
  isPrevPageDisabled,
  isNextPageDisabled,
  numberOfSelectedComponents,
  disabledComponentIds,
  selectedDependencyState,
  isOpen,
  title,
  getActionTitle,
  ...rest
}) => {
  return (
    <Modal
      onClose={onClose}
      title={title}
      maxWidth="50rem"
      shouldUseSmartPositioning={false}
      isOpen={isOpen}
      {...rest}
    >
      <LinkDependencyTable
        selectedDependencyState={selectedDependencyState}
        isFetching={isFetching}
        disabledComponentIds={disabledComponentIds}
        componentList={componentList}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        numberOfComponents={numberOfComponents}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
        onCheckComponentCheckbox={onCheckComponentCheckbox}
        numberOfSelectedComponents={numberOfSelectedComponents}
        onClearSelectedComponents={onClearSelectedComponents}
      />
      <Modal.Footer>
        <Modal.Action
          appearance={Appearance.PrimaryWithIcon}
          isDisabled={isLinking}
          onClick={onClose}
        >
          <Icon.Cross />
          <span>Cancel</span>
        </Modal.Action>
        <Modal.Action
          appearance={Appearance.PrimaryWithIcon}
          isHighlighted={true}
          isDisabled={!numberOfSelectedComponents}
          onClick={onLink}
          isPending={isLinking}
        >
          <Icon.Link />
          <span>{getActionTitle(numberOfSelectedComponents)}</span>
        </Modal.Action>
      </Modal.Footer>
    </Modal>
  );
};
