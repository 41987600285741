import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import ComponentTagForm from '../../forms/ComponentTagForm';
import { AddTagFormData, Tags } from '../../definitions';
import { Icon } from '../../components/Icon';
import { FormLayout } from '../../components/Form';

type AddTagProps = {
  onClose: () => void;
  isOpen: boolean;
  isAdding: boolean;
  onAddTag: (formData: AddTagFormData) => void;
  tags: Tags;
  isLoadingTags: boolean;
};

export const AddTagView: FunctionComponent<AddTagProps> = ({
  children,
  onClose,
  onAddTag,
  isAdding,
  isLoadingTags,
  tags,
  ...rest
}) => {
  return (
    <Modal onClose={onClose} title="Add tag" {...rest}>
      <FormLayout isModal={true}>
        <ComponentTagForm
          onSubmit={onAddTag}
          initialValues={{}}
          isLoading={isAdding}
          action="Add"
          onCancel={onClose}
          icon={<Icon.Create />}
          tagOptions={Object.values(tags)}
        />
      </FormLayout>
    </Modal>
  );
};
