import compose from './compose';
import email from './email';
import equals from './equals';
import { minLength, maxLength, exactLength } from './length';
import { min, max } from './number';
import password from './password';
import required from './required';
import url from './url';

const validators = {
  compose,
  email,
  equals,
  minLength,
  min,
  maxLength,
  max,
  exactLength,
  required,
  password,
  url,
};

export default validators;
