import { REACT_APP_VERSION } from '../definitions';
import Services from './helpers/Services';
import AuthenticationService from './AuthenticationService';
import BotsService from './BotsService';
import CommentService from './CommentService';
import ComponentService from './ComponentService';
import EventSourcesService from './EventSourceService';
import NotificationService from './NotificationService';
import StorageService from './StorageService';
import SloService from './SloService';
import TagService from './TagService';
import TeamsService from './TeamsService';
import TenantService from './TenantService';
import UserService from './UserService';
import XEventService from './XEventService';

let services: Services;

type Instances = {
  authenticationService: AuthenticationService;
  botsService: BotsService;
  commentService: CommentService;
  componentService: ComponentService;
  eventSourcesService: EventSourcesService;
  notificationService: NotificationService;
  sloService: SloService;
  storageService: StorageService;
  tagService: TagService;
  teamsService: TeamsService;
  tenantService: TenantService;
  userService: UserService;
  xEventService: XEventService;
};

const serviceInit = async (
  config = {
    FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    HOST: process.env.REACT_APP_HOST,
    AUTH_HOST: process.env.REACT_APP_AUTH_HOST,
  },
): Promise<Instances> => {
  if (!services) {
    services = new Services();

    services.add(
      'storageService',
      StorageService,
      window.localStorage,
      String(REACT_APP_VERSION).split('.')[0],
      'rocketSLO',
    );

    services.add(
      'authenticationService',
      AuthenticationService,
      {
        apiKey: config.FIREBASE_API_KEY,
        authDomain: config.FIREBASE_AUTH_DOMAIN,
      },
      config.HOST,
      config.AUTH_HOST,
    );

    services.add('botsService', BotsService, config.HOST);
    services.add('commentService', CommentService, config.HOST);
    services.add('componentService', ComponentService, config.HOST);
    services.add('eventSourcesService', EventSourcesService, config.HOST);
    services.add('notificationService', NotificationService, config.HOST);
    services.add('sloService', SloService, config.HOST);
    services.add('tagService', TagService, config.HOST);
    services.add('teamsService', TeamsService, config.HOST);
    services.add('tenantService', TenantService, config.HOST);
    services.add('userService', UserService, config.HOST);
    services.add('xEventService', XEventService, config.HOST);

    await services.initialise();
  }

  return services.instances as Instances;
};

export default serviceInit;
