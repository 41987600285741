import { FunctionComponent } from 'react';
import { EventSourceType } from '../../grpc/grpcweb/eventsource_pb';
import { Icon } from '../../components/Icon';
import { getEventSourceLabel } from '../../grpcMappings';
import * as Styled from './EventSourceDropdownItem.styles';

type EventSourceDropdownItemProps = {
  label: string;
  type?: string;
};

export const EventSourceDropdownItemView: FunctionComponent<EventSourceDropdownItemProps> = ({
  label,
  type,
}) => {
  const getEventSourceIcon = (sourceType?: string) => {
    if (sourceType) {
      if (sourceType === getEventSourceLabel(EventSourceType.EVENT_SOURCE_CLOUDWATCH)) {
        return <Icon.CloudWatchSquare />;
      } else if (sourceType === getEventSourceLabel(EventSourceType.EVENT_SOURCE_PROMETHEUS)) {
        return <Icon.PrometheusSquare />;
      }
    }
    return null;
  };

  return (
    <Styled.DropdownRow>
      {getEventSourceIcon(type)}
      <p>{label}</p>
    </Styled.DropdownRow>
  );
};
