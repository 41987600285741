import { FunctionComponent, ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { InputTooltip } from '../InputTooltip';
import * as Styled from './Textarea.styles';

export type TextareaProps = FieldRenderProps<string> & {
  label?: string;
  help?: string;
  remoteErrorMessage?: string;
  validMessage?: string;
  showValidation?: boolean;
  tooltip?: string;
  showRedBorderOnUnfocus?: boolean;
  description?: string | ReactNode;
  isOptional?: boolean;
  maxWidth?: string;
};

export const TextareaView: FunctionComponent<TextareaProps> = ({
  label,
  help,
  remoteErrorMessage,
  validMessage,
  meta,
  input,
  autoFocus = false,
  showValidation = false,
  readOnly = false,
  tooltip,
  placeholder,
  showRedBorderOnUnfocus = false,
  id,
  description,
  isOptional,
  maxWidth,
  ...rest
}) => {
  const errorMessage =
    (meta.touched && meta.error) ||
    (!meta.dirtySinceLastSubmit && (meta.submitError || remoteErrorMessage));

  const hasValue = Boolean(input?.value && String(input?.value).length);

  const showValidMessage =
    !readOnly && meta.valid && !meta.pristine && validMessage && !errorMessage;

  const validationMessage = showValidMessage ? (
    <Styled.Message type="success" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
      {validMessage}
    </Styled.Message>
  ) : (
    <Styled.Message type="error" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
      {errorMessage}
    </Styled.Message>
  );

  return (
    <Styled.Container
      hasLabel={Boolean(label)}
      invalid={Boolean(errorMessage)}
      hasValue={hasValue}
      readOnly={readOnly}
      valid={meta.valid && !errorMessage}
      showRedBorderOnUnfocus={showRedBorderOnUnfocus || showValidation}
      {...meta}
    >
      <Styled.Row>
        <Styled.Column>
          {(label || description) && (
            <Styled.LabelWrapper>
              {label && (
                <Styled.Label>
                  {label}
                  {isOptional && <Styled.Optional>(optional)</Styled.Optional>}
                </Styled.Label>
              )}
              {description &&
                (typeof description === 'function' ? (
                  { description }
                ) : (
                  <Styled.Description>{description}</Styled.Description>
                ))}
            </Styled.LabelWrapper>
          )}
          <Styled.Textarea
            {...input}
            placeholder={placeholder}
            autoFocus={autoFocus}
            readOnly={readOnly}
            id={id}
            {...rest}
            {...meta}
            value={input.value || ''}
            valid={meta.valid && !errorMessage}
            hasValue={hasValue}
            showRedBorderOnUnfocus={showRedBorderOnUnfocus || showValidation}
            maxWidth={maxWidth}
          />
        </Styled.Column>
        {tooltip && <InputTooltip content={tooltip} />}
      </Styled.Row>
      {validationMessage}
    </Styled.Container>
  );
};
