import { FunctionComponent } from 'react';
import * as Styled from './TagKeyDropdownItem.styles';

type TagKeyDropdownItemProps = {
  label: string;
  description?: string;
};

export const TagKeyDropdownItemView: FunctionComponent<TagKeyDropdownItemProps> = ({
  label,
  description,
}) => {
  return (
    <Styled.DropdownRow>
      <Styled.Key>{label}</Styled.Key>
      {description && <Styled.Description>{description}</Styled.Description>}
    </Styled.DropdownRow>
  );
};
