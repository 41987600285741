import { FunctionComponent } from 'react';
import { XEvent } from '../../grpc/grpcweb/xevent_pb';
import { XEventInTheList, TagState, TagAppearance } from '../../definitions';
import { Link } from '../Link';
import { Tag } from '../Tag';
import * as Styled from './xEventDetails.styles';

export const StatusView: FunctionComponent<XEventInTheList> = ({ status, sentiment }) => {
  const getTagColor = (sentiment: XEvent.SentimentMap[keyof XEvent.SentimentMap]): TagState => {
    switch (sentiment) {
      case 1:
        return TagState.Disabled;
      case 2:
        return TagState.Info;
      case 3:
        return TagState.Success;
      case 4:
        return TagState.Warning;
      case 5:
        return TagState.Danger;
      default: {
        return TagState.Info;
      }
    }
  };

  return (
    <Styled.TagWrapper>
      <Tag value={status} state={getTagColor(sentiment)} appearance={TagAppearance.Status} />
    </Styled.TagWrapper>
  );
};

export const DateTimeView: FunctionComponent<XEventInTheList> = ({ startedat }) => {
  return <span>{startedat ? startedat.toLocaleString() : ''}</span>;
};

export const DisplayNameLinkView: FunctionComponent<XEventInTheList> = ({ displayName, url }) => (
  <Link
    onClick={() => {
      window.open(url);
    }}
    title="Open event in a new tab"
  >
    {displayName}
  </Link>
);
