import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { usePageTitle } from '../../hooks';
import {
  isEditingComponent,
  editComponent,
  isFetchingComponentTypes,
  fetchComponentTypes,
  getComponentTypes,
  getSelectedComponent,
  deleteComponent,
  isDeletingComponent,
} from '../../store';
import { EditComponentFormData } from '../../definitions';
import { UpdateComponentView } from './UpdateComponent.view';

type UpdateComponentContainerProps = {
  visible: boolean;
  onHide: () => void;
} & RouteChildrenProps;

export const UpdateComponentContainer: FunctionComponent<UpdateComponentContainerProps> = ({
  visible,
  onHide,
}) => {
  usePageTitle('Update component');
  const dispatch = useDispatch();

  const isEditing = useSelector(isEditingComponent);
  const isFetching = useSelector(isFetchingComponentTypes);
  const isDeleting = useSelector(isDeletingComponent);

  const component = useSelector(getSelectedComponent);
  const componentTypes = useSelector(getComponentTypes);

  const handleEditComponent = useCallback(
    (formData: EditComponentFormData) => {
      dispatch(editComponent({ formData, id: component.id }));
    },
    [dispatch, component],
  );

  const handleDeleteComponent = useCallback(() => {
    dispatch(
      deleteComponent({
        name: component.displayName || component.shortname || '',
        componentId: component.id,
      }),
    );
  }, [dispatch, component]);

  useEffect(() => {
    dispatch(fetchComponentTypes());
  }, [dispatch]);

  return (
    <UpdateComponentView
      isEditing={isEditing}
      isFetching={isFetching}
      componentTypes={componentTypes}
      onEditComponent={handleEditComponent}
      onDeleteComponent={handleDeleteComponent}
      isDeleting={isDeleting}
      visible={visible}
      onHide={onHide}
      initialValues={{
        id: component.id,
        displayName: component.displayName,
        shortName: component.shortname,
        description: component.description,
        componentType: component.typeId,
        ownerTeam: component.ownerTeam,
      }}
    />
  );
};
