import styled, { css } from 'styled-components';
import { Grid } from '../Grid';
import { lighten } from 'polished';

type WrapperProps = {
  isClickable: boolean;
};

type DeclarationProps = {
  hideOnSmall: boolean;
  shouldHighlight: boolean;
  isCompact: boolean;
  compactFieldWidth?: string;
};

type ContainerProps = {
  minWidth?: string;
};

export const Item = styled(Grid.Bounds)`
  align-content: flex-start;
  min-height: 1.25rem;
  line-height: 1.25;
  align-items: center;
  flex-wrap: wrap;
`;

export const Declaration = styled(Grid.Box)<DeclarationProps>`
  padding: 0.75rem 0;
  color: ${props => props.theme.palettes.main};
  flex: ${props => (props.isCompact ? '0' : '1')};
  min-width: ${props => props.compactFieldWidth || '6rem'};

  small {
    opacity: 0.75;
  }

  ${props =>
    props.hideOnSmall &&
    css`
      display: none;
    `};

  ${props => props.theme.media.medium`
    display: block;
  `}
`;

export const ItemInner = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemWrapper = styled.div<WrapperProps>`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => lighten(0.15, theme.palettes.border)};
  }

  &:hover {
    ${props =>
      props.isClickable &&
      css`
        cursor: pointer;
        background: ${({ theme }) => theme.palettes.mute};
      `}
  }
`;

export const Title = styled(Declaration)`
  color: ${props => props.theme.palettes.mutedMain};
`;

export const TitleWrapper = styled(ItemWrapper)`
  border-bottom: 1px solid ${({ theme }) => lighten(0.15, theme.palettes.border)};
  background-color: ${({ theme }) => theme.palettes.headerBackground};
  margin: -1rem -1rem 0 -1rem;
  padding: 0 1rem 0 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  ${Item} {
    line-height: 2;
  }
`;

export const Container = styled.ul<ContainerProps>`
  display: flex;
  flex-direction: column;
  min-height: 7rem;
  justify-content: center;
  font-size: 0.875rem;
  padding: 0;
  min-width: ${props => props.minWidth || 'auto'};
`;
