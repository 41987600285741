import styled, { keyframes } from 'styled-components';

type RippleProps = {
  position: { x: number; y: number };
  size: number;
};

const rippleAnimation = keyframes`
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Ripple = styled.div<RippleProps>`
  position: absolute;
  left: ${props => `${props.position.x}px`};
  top: ${props => `${props.position.y}px`};
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  background-color: ${props => props.color};
  border-radius: 50%;
  user-select: none;
  pointer-events: none;
  transform: scale(0);
  opacity: 0.2;
  animation: ${rippleAnimation} 0.6s ease-out;
`;
