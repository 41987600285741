import { ElementType } from 'react';
import {
  AlertType,
  AuthErrorType,
  CloudWatchAuthType,
  PrometheusAuthType,
  SequenceType,
} from '../constants';
import {
  Activity,
  SloStatusMap,
  TimeWindowTypeMap,
  TimeWindowUnitMap,
} from '../../grpc/grpcweb/slo_pb';
import { TagView, TagValue } from '../../grpc/grpcweb/tag_pb';
import { EventSourceTypeMap } from '../../grpc/grpcweb/eventsource_pb';
import { XEvent } from '../../grpc/grpcweb/xevent_pb';
import { Avatar } from '../../grpc/grpccommon/common_pb';

export type ErrorMessages = {
  [key: string]: string;
};

export type ErrorObject = {
  message: string;
  details: string;
  fields: ErrorMessages;
  status: number;
  type: string;
};

export type ActionType<T = any> = {
  rootAction?: ActionType<T>;
  type: string;
  sequence?: SequenceType;
  error?: ErrorObject;
  payload?: T;
};

export type UserState = {
  id: string;
  username: string;
  email: string;
  fullname: string;
  avatar?: Avatar.AsObject;
  tenantid: string;
};

export type AlertObject = {
  key: number;
  timeout?: number;
  type: AlertType;
  message: string;
};

export type FlowStepObject = {
  id: string;
  parent?: string;
  path: string;
  history?: boolean;
  back?: boolean;
  skip?: boolean;
  direction?: number;
  component?: ElementType;
  available?: (state: any) => boolean;
};

export type UserObject = {
  tenantid: string;
  tenantname: string;
  userid: string;
  username: string;
  emailVerified?: boolean;
  role?: string | null;
  suspended?: boolean;
};

export type AuthenticationErrorObject = {
  error: AuthErrorType;
  message?: string;
  errorDescription?: string;
  // eslint-disable-next-line camelcase
  error_description?: string;
};

export type ErrorField = {
  data: {
    field: string;
  };
  message: string;
};

export type LoginFormData = {
  email: string;
  password: string;
  tenantName: string;
};

export type Breadcrumb = {
  displayName: string;
  path: string;
  icon?: ElementType;
  isCurrent?: boolean;
};

export type SloInTheList = {
  id: string;
  displayname?: string;
  description?: string;
  shortname?: string;
  slotargetid?: string;
  objective?: any;
  errorbudgetmethod?: number;
  status: SloStatusMap[keyof SloStatusMap];
  currentslovalue: number;
  type?: string;
  timeWindowType?: TimeWindowTypeMap[keyof TimeWindowTypeMap];
  timeWindowUnit?: TimeWindowUnitMap[keyof TimeWindowUnitMap];
  timeWindow?: number;
};

export type CommonEventSourceProps = {
  id: string;
  displayName?: string;
  description?: string;
};

export type CloudWatchProps = {
  sourceType?: EventSourceTypeMap['EVENT_SOURCE_CLOUDWATCH'];
  accessKey?: string;
  region?: string;
  secretAccessKey?: any;
  authenticationType?: CloudWatchAuthType.AccessKey;
};

export type PrometheusProps = {
  sourceType?: EventSourceTypeMap['EVENT_SOURCE_PROMETHEUS'];
  authenticationType?: PrometheusAuthType.Basic;
  url?: string;
  username?: string;
  password?: string;
};

export type EventSourcesInTheList = CommonEventSourceProps & (CloudWatchProps | PrometheusProps);

export type SLOHistoryPoint = {
  start: Date;
  end: Date;
  startString: string;
  endString: string;
  sloValue: number;
  activity?: string[];
};

export type SloStatusChanged = {
  prevstatus: SloStatusMap[keyof SloStatusMap];
  currentstatus: SloStatusMap[keyof SloStatusMap];
};

export type SloObjectiveChanged = {
  prevobjective: number;
  currentobjective: number;
};

export type SloBreached = {
  targetobjective: number;
  objective: number;
  breachedat: number;
};

export type SloRecovered = {
  targetobjective: number;
  objective: number;
  recoveredat: number;
};

export type SLOActivity = {
  id: string;
  sloBreached?: SloBreached;
  sloRecovered?: SloRecovered;
  sloId: string;
  sloObjectiveChanged?: SloObjectiveChanged;
  sloStatusChanged?: SloStatusChanged;
  tenantId: string;
  triggeredAt: Date;
  triggeredAtString: string;
  triggeredBy: number;
  triggeredByUser?: UserState;
  type: Activity.TypeMap[keyof Activity.TypeMap];
};

export type User = {
  id: string;
  username: string;
  email: string;
  fullname: string;
  avatar?: Avatar.AsObject;
  tenantid: string;
};

export type ComponentInTheList = {
  id: string;
  shortname?: string;
  displayName?: string;
  description?: string;
  type?: string;
  typeId?: string;
  sloList: SloInTheList[];
  ownerTeam?: FlattenedTeam;
  tags: TagView.AsObject[];
  avatar?: string;
  updatedBy?: string;
  updatedAt?: Date;
  createdBy?: string;
  createdAt?: Date;
  links: Link[];
};

export type ComponentRefInTheList = {
  id: string;
  shortname?: string;
  displayName?: string;
  description?: string;
  type?: string;
  typeId?: string;
  sloList: SloInTheList[];
  ownerTeamId?: string;
  tagList: TagView.AsObject[];
  avatar?: string;
  updatedBy?: string;
  updatedAt?: Date;
};

export type TeamEnrichedComponentRefInTheList = ComponentRefInTheList & {
  team?: FlattenedTeamBatch;
};

export type ComponentInTheDropdown = {
  value?: string;
  label?: string;
  type?: string;
};

export enum DependencyState {
  Dependency = 'component depends on',
  Dependant = 'depends on component',
}

export type ComponentRefInTheListWithDependencyState = ComponentRefInTheList & {
  dependencyState?: DependencyState;
};

export type SloActivityDataForList = {
  time: string;
  type: string;
  from: string;
  to: string;
  changedBy: string;
};

export type CommentData = {
  id: string;
  value?: string;
  targetId: string;
  author: string;
  addedAt: Date;
};

export type SloComponent = ComponentInTheList & {
  dependencies: ComponentRefInTheList[];
  dependents: ComponentRefInTheList[];
  comments: CommentData[];
  selectedDependencyState: Record<string, boolean>;
};

export type Tag = {
  tagKey?: string;
  keyId?: string;
  values: TagValue.AsObject[];
  description?: string;
  createdBy?: string;
  createdAt?: Date;
  updatedBy?: string;
  updatedAt?: Date;
  usageInComponents: string[];
};

export type Tags = Record<string, Tag>;

export type FlattenedTeamBatch = {
  avatarUrl?: string;
  description?: string;
  displayname?: string;
  shortname?: string;
  id: string;
  memberList: string[];
};

export type TeamMap = Record<string, FlattenedTeamBatch>;

export type FlattenedTeam = {
  avatarUrl?: string;
  description?: string;
  displayname?: string;
  shortname?: string;
  createdAt?: Date;
  createdBy?: string;
  id: string;
  users: User[];
};

export type Token = {
  id?: string;
  token: string;
};

export type TokenInTheList = {
  id: string;
  token?: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
};

export type BotInTheList = {
  id: string;
  shortname?: string;
  description?: string;
  tokens: TokenInTheList[];
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  createdToken: Token;
  selectedToken: TokenInTheList;
};

export type GrouppedComponents = Record<string, Array<ComponentRefInTheList>>;

export type Link = {
  id: string;
  url?: string;
  displayname?: string;
  avatarUrl?: string;
};

export type XEventInTheList = {
  id: string;
  displayName: string;
  description: string;
  status: string;
  sentiment: XEvent.SentimentMap[keyof XEvent.SentimentMap];
  url: string;
  metafieldsList: XEvent.MetaField.AsObject[];
  startedat?: Date;
};
