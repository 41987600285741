import { useLayoutEffect, useState, RefObject } from 'react';

export function useIsEllipsisActive(ref: RefObject<HTMLDivElement>): boolean {
  const [isActive, setIsActive] = useState(false);

  useLayoutEffect(() => {
    if (!ref?.current?.offsetWidth) {
      return setIsActive(false);
    }
    if (!ref?.current?.scrollWidth) {
      return setIsActive(false);
    }

    setIsActive(ref?.current?.offsetWidth < ref?.current?.scrollWidth);
  }, [ref]);

  return isActive;
}
