import { FunctionComponent } from 'react';
import { Drawer } from '../../components/Drawer';
import TagForm from '../../forms/TagForm';
import { DrawerPosition, TagFormData, theme, ColorTheme, Appearance } from '../../definitions';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import PopConfirm from '../../components/PopConfirm';
import { TagValue } from '../../grpc/grpcweb/tag_pb';

type EditTagProps = {
  isUpdating: boolean;
  isFetching: boolean;
  isDeleting: boolean;
  isAddingNewValue: boolean;
  isDeletingValue: boolean;
  isUpdatingValue: boolean;
  onDeleteTag: (tagKeyId: string) => void;
  initialValues: TagFormData;
  visible: boolean;
  onHide: () => void;
  onAddValueToTag: (value: string) => void;
  onReset: () => void;
  tagValues: TagValue.AsObject[];
  onSubmit: (formData: TagFormData) => void;
  existingValueErrorMessage?: string;
  newValueErrorMessage?: string;
  onUpdateValue: (value: TagValue.AsObject, prevValue: TagValue.AsObject) => void;
  onRemoveValue: (value: TagValue.AsObject) => void;
  onClearAllErrors: () => void;
  clickedSubmit: boolean;
  onSetClickedSubmit: (clicked: boolean) => void;
  updatedValueSuccesfully: boolean;
};

const DeleteTagButton: FunctionComponent<{
  tagKey: string;
  isDeleting: boolean;
  onDelete: (tagKey: string) => void;
  isDisabled: boolean;
}> = ({ tagKey, isDeleting, onDelete, isDisabled }) => (
  <PopConfirm
    title={`Are you sure you want to delete the ${tagKey} tag?`}
    onConfirm={() => onDelete(tagKey)}
    isConfirming={isDeleting}
    isDisabled={isDeleting}
    contentText="The tag cannot be deleted if it has been assigned to any entity."
    confirmColor={theme.palettes.error}
  >
    <Button isDisabled={isDisabled} appearance={Appearance.PrimaryWithIcon}>
      <Icon.Trash />
      <span>Delete</span>
    </Button>
  </PopConfirm>
);

export const EditTagView: FunctionComponent<EditTagProps> = ({
  isUpdating,
  onDeleteTag,
  isDeleting,
  initialValues,
  visible,
  onHide,
  onAddValueToTag,
  ...rest
}) => {
  return (
    <Drawer
      visible={visible}
      onHideMenu={onHide}
      position={DrawerPosition.Right}
      fullScreenOnSmall={true}
      colorTheme={ColorTheme.Light}
      title="Edit tag"
    >
      <TagForm
        initialValues={initialValues}
        isLoading={isUpdating}
        action="Update"
        onCancel={onHide}
        isDisabled={isDeleting}
        onCreateNewValue={onAddValueToTag}
        extraAction={
          <DeleteTagButton
            onDelete={onDeleteTag}
            isDeleting={isDeleting}
            isDisabled={isUpdating}
            tagKey={initialValues.tagKey}
          />
        }
        {...rest}
      />
    </Drawer>
  );
};
