import { FunctionComponent, ReactNode } from 'react';
import { User, AvatarType } from '../../definitions';
import List from '../../components/List';
import Pagination from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import AvatarWithTitle from '../../components/AvatarWithTitle';

export type UserListProps = {
  userList: User[];
  isFetching: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfUsers: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  action?: ReactNode;
  actionTitle?: ReactNode | string;
};

export const UserListView: FunctionComponent<UserListProps> = ({
  userList,
  isFetching,
  numberOfUsers,
  onNextPage,
  onPrevPage,
  isPrevPageDisabled,
  isNextPageDisabled,
  action,
  actionTitle,
}) => {
  const UserAvatar: FunctionComponent<User> = ({ avatar, username }) => (
    <AvatarWithTitle name={username} avatarType={AvatarType.User} avatarSrc={avatar?.uri} />
  );

  const getColumnTitles = () => {
    let columns: Array<string | ReactNode> = ['User', 'Email', 'Full name'];
    if (action) {
      columns.unshift(action);
    }
    return columns;
  };

  const getColumns = () => {
    let columns: Array<string | ReactNode> = [UserAvatar, 'email', 'fullname'];
    if (action) {
      actionTitle ? columns.unshift(actionTitle) : columns.unshift('');
    }
    return columns;
  };

  return (
    <Layout.Section>
      <List
        items={userList}
        columnTitles={getColumnTitles()}
        columns={getColumns()}
        hideOnSmall={['fullname']}
        itemKey="id"
        isLoading={isFetching}
        emptyText="No users found. Start by creating one."
      />
      <Pagination totalItems={numberOfUsers}>
        <Pagination.NextPrev
          onNextClick={onNextPage}
          onPrevClick={onPrevPage}
          isPrevDisabled={isPrevPageDisabled}
          isNextDisabled={isNextPageDisabled}
        />
      </Pagination>
    </Layout.Section>
  );
};
