import { FunctionComponent, ElementType } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Loading } from '../../pages/Loading';
import { Authenticate } from '../Authenticate';
import { usePageTitle } from '../../hooks';
import { AuthenticationError } from '../AuthenticationError';

type AuthenticatedRouteProps = RouteProps & {
  component: ElementType;
  fallback?: ElementType;
  waitForUser?: boolean;
  componentProps?: Record<any, any>;
  title: string;
};

export const AuthenticatedRouteContainer: FunctionComponent<AuthenticatedRouteProps> = ({
  component: Component,
  fallback: Fallback,
  waitForUser = false,
  componentProps,
  render,
  title,
  ...rest
}) => {
  usePageTitle(title);

  return (
    <Route
      {...rest}
      render={props => (
        <Authenticate autoLogin={!Fallback} waitForUser={waitForUser}>
          {(error, loading, isAuthenticated) => {
            if (loading) {
              return <Loading title="Authenticating..." />;
            }

            if (!isAuthenticated && Fallback) {
              return <Fallback {...props} />;
            }

            return (
              <AuthenticationError autoLogin={!Fallback}>
                <Component {...props} {...componentProps} />
              </AuthenticationError>
            );
          }}
        </Authenticate>
      )}
    />
  );
};
