import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  h5 {
    font-weight: 700;
    font-size: 1rem;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`;

export const SectionHeader = styled.h5`
  gap: 0.5rem;
  display: flex;
  margin-bottom: 0.5rem;
  font-weight: 700;
  justify-content: space-between;
  color: ${props => props.theme.palettes.main};
  svg {
    width: 1rem;
    height: auto;
  }
`;

export const SectionText = styled.p``;

export const Section = styled.div`
  margin-bottom: 2rem;
`;

export const MutedText = styled(SectionText)`
  color: ${props => props.theme.palettes.placeholder};
`;
