import { FunctionComponent, useState } from 'react';
import { Form } from 'react-final-form';
import { FormStateType, BotFormData, Appearance } from '../../definitions';
import { FormAutoSave, FormLayout } from '../../components/Form';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import { Layout } from '../../components/Layout';
import { BotForm } from '.';

type CreateBotFormProps = {
  isLoading: boolean;
  onSubmit: (formData: BotFormData) => void;
  onCancel: () => void;
  initialValues: BotFormData;
  onUpdateState?: (formState: FormStateType) => void;
  isDisabled?: boolean;
  isNewBot?: boolean;
  isDeleting?: boolean;
};

export const CreateBotFormView: FunctionComponent<CreateBotFormProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  onUpdateState = () => {},
  onCancel,
  isDisabled = false,
  isNewBot,
  isDeleting,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

  return (
    <Form
      autoComplete="off"
      onSubmit={(values: BotFormData) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          setClickedSubmit(true);
          handleSubmit(event);
        };

        const handleCancel = () => {
          onUpdateState({} as any);
          form.reset({});
          onCancel();
        };

        return (
          <>
            {!isNewBot && <Layout.SectionHeading>Bot Meta</Layout.SectionHeading>}
            <BotForm clickedSubmit={clickedSubmit} isNewBot={true} />
            <FormAutoSave updateFormState={onUpdateState} />
            <FormLayout.ModalPreFooterSpacer />
            <FormLayout.ModalFooter>
              <Button
                onClick={handleCancel}
                isDisabled={isLoading || isDeleting}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Cross />
                <span>Cancel</span>
              </Button>
              <FormLayout.ModalSubmit
                onClick={onSubmitClick}
                isPending={isLoading}
                isDisabled={isDisabled || isDeleting || (!isNewBot && pristine)}
                appearance={Appearance.PrimaryWithIcon}
                isHighlighted={true}
              >
                <Icon.Edit />
                <span>Create</span>
              </FormLayout.ModalSubmit>
            </FormLayout.ModalFooter>
          </>
        );
      }}
    />
  );
};
