import {
  ActionType,
  CreateComponentFormData,
  EditComponentFormData,
  FormStateType,
  AddLinkFormData,
  UpdateLinkFormData,
  ComponentRefInTheList,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const saveSelectedComponent = (selectedComponent: ComponentRefInTheList): ActionType => ({
  type: actionTypes.components.current.select,
  payload: selectedComponent,
});

export type FetchComponentListProps = {
  pageSize?: number;
  includeTeamData?: boolean;
  filterByTags?: {
    keyList: Array<string>;
    valueList: Array<string>;
  };
  filterByTeam?: string[];
  filterByType?: string[];
};

export const fetchComponentList = (payload: FetchComponentListProps): ActionType => ({
  type: actionTypes.components.fetchList,
  payload,
});

export const fetchNextComponentListPage = (payload: FetchComponentListProps): ActionType => ({
  type: actionTypes.components.fetchNextPage,
  payload,
});

export const fetchPrevComponentListPage = (payload: FetchComponentListProps): ActionType => ({
  type: actionTypes.components.fetchPrevPage,
  payload,
});

export const fetchMyComponents = (payload = {}): ActionType => ({
  type: actionTypes.components.fetchMy,
  payload,
});

export const fetchStandAloneComponents = (pageSize: number): ActionType => ({
  type: actionTypes.components.standAlone.fetchList,
  payload: pageSize,
});

export const fetchNextStandAloneComponentListPage = (pageSize: number): ActionType => ({
  type: actionTypes.components.standAlone.fetchNextPage,
  payload: pageSize,
});

export const fetchPrevStandAloneComponentListPage = (pageSize: number): ActionType => ({
  type: actionTypes.components.standAlone.fetchPrevPage,
  payload: pageSize,
});

export const saveSelectedDependencyIds = (payload: Record<string, boolean>): ActionType => ({
  type: actionTypes.components.current.dependencies.select,
  payload,
});

export const clearSelectedDependencies = (payload = {}): ActionType => ({
  type: actionTypes.components.current.dependencies.clear,
  payload,
});

export const fetchComponentTypes = (payload = {}): ActionType => ({
  type: actionTypes.components.fetchTypes,
  payload,
});

export const createComponent = (
  payload: CreateComponentFormData | EditComponentFormData,
): ActionType => ({
  type: actionTypes.components.create,
  payload,
});

export const updateNewComponent = (payload: FormStateType): ActionType => ({
  type: actionTypes.components.updateNew,
  payload,
});

export const deleteComponent = (payload: { componentId: string; name: string }): ActionType => ({
  type: actionTypes.components.current.delete,
  payload,
});

export const editComponent = (payload: {
  formData: EditComponentFormData;
  id: string;
}): ActionType => ({
  type: actionTypes.components.current.edit,
  payload,
});

export const fetchSLOListForComponent = (componentId: string): ActionType => ({
  type: actionTypes.components.current.fetchSLOs,
  payload: componentId,
});

export const fetchComponentByShortname = (componentShortname: string): ActionType => ({
  type: actionTypes.components.current.fetchByShortname,
  payload: componentShortname,
});

export const fetchComponentById = (componentId: string): ActionType => ({
  type: actionTypes.components.current.fetchById,
  payload: componentId,
});

export const fetchComponentDependencies = (componentId: string): ActionType => ({
  type: actionTypes.components.current.dependencies.fetch,
  payload: componentId,
});

export const linkComponentDependencies = (payload: {
  dependencyIds: Array<string>;
  componentId: string;
}): ActionType => ({
  type: actionTypes.components.current.dependencies.linkDependencies,
  payload,
});

export const linkComponentDependents = (payload: {
  dependentIds: Array<string>;
  componentId: string;
}): ActionType => ({
  type: actionTypes.components.current.dependencies.linkDependents,
  payload,
});

export const unlinkComponentDependency = (payload: {
  dependencyId: string;
  componentId: string;
}): ActionType => ({
  type: actionTypes.components.current.dependencies.unlinkDependency,
  payload,
});

export const unlinkComponentDependant = (payload: {
  dependantId: string;
  componentId: string;
}): ActionType => ({
  type: actionTypes.components.current.dependencies.unlinkDependant,
  payload,
});

export const addComponentComment = (payload: {
  componentId: string;
  comment: string;
}): ActionType => ({
  type: actionTypes.components.current.comments.add,
  payload,
});

export const fetchComponentComments = (componentId: string): ActionType => ({
  type: actionTypes.components.current.comments.fetch,
  payload: componentId,
});

export const addComponentLink = (payload: {
  componentId: string;
  formData: AddLinkFormData;
}): ActionType => ({
  type: actionTypes.components.current.links.add,
  payload,
});

export const updateComponentLink = (payload: {
  componentId: string;
  formData: UpdateLinkFormData;
}): ActionType => ({
  type: actionTypes.components.current.links.edit,
  payload,
});

export const deleteComponentLink = (payload: {
  componentId: string;
  linkId: string;
}): ActionType => ({
  type: actionTypes.components.current.links.delete,
  payload,
});

export const fetchDetailsForComponentPopup = (componentId: string): ActionType => ({
  type: actionTypes.components.current.popup.fetchDetails,
  payload: componentId,
});
