import { FunctionComponent, ElementType, useContext } from 'react';
import { MediaContext } from '../../../contexts';
import { PositionX } from '../../../definitions';
import Tooltip from '../../Tooltip';
import * as Styled from '../Sidebar.styles';

export type ItemProps = {
  icon?: ElementType;
  path?: string;
  title?: string;
  onItemClick?: (path: string) => void;
};

export const Item: FunctionComponent<ItemProps> = ({
  icon,
  path = undefined,
  title,
  onItemClick,
  ...rest
}) => {
  const MenuIcon = icon;
  const { isMediumLayout, isLargeLayout } = useContext(MediaContext);

  if (isMediumLayout && !isLargeLayout) {
    return (
      <Tooltip content={title} positionX={PositionX.Right} triggerOffset={11} hasArrow={false}>
        <Styled.Item
          onClick={() => onItemClick && path && onItemClick(path)}
          isMediumLayout={isMediumLayout}
          {...rest}
        >
          {MenuIcon && <MenuIcon />}
        </Styled.Item>
      </Tooltip>
    );
  }

  return (
    <Styled.Item
      onClick={() => onItemClick && path && onItemClick(path)}
      isMediumLayout={isMediumLayout}
      {...rest}
    >
      {MenuIcon && <MenuIcon />}
      <Styled.Text>{title}</Styled.Text>
    </Styled.Item>
  );
};
