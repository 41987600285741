import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import { TokenInTheList } from '../../definitions';
import { FormLayout } from '../../components/Form';
import { Loader } from '../../components/Loader';
import TokenForm from '../../forms/Token';
import MetaTable from '../../components/MetaTable';
import * as Styled from './Token.styles';

type TokenViewProps = {
  onClose: () => void;
  isOpen: boolean;
  token: TokenInTheList;
  isDeletingToken: boolean;
  onDeleteToken: () => void;
  isSelectingToken: boolean;
};

export const TokenView: FunctionComponent<TokenViewProps> = ({
  children,
  onClose,
  token,
  isDeletingToken,
  onDeleteToken,
  isSelectingToken,
  ...rest
}) => {
  return (
    <Modal onClose={onClose} title="API token" shouldUseSmartPositioning={false} {...rest}>
      <FormLayout isModal={true}>
        {isSelectingToken ? (
          <Loader />
        ) : (
          <TokenForm
            initialValues={token}
            isLoading={isSelectingToken}
            isDeletingToken={isDeletingToken}
            onDeleteToken={onDeleteToken}
            onCancel={onClose}
            isNewToken={false}
          >
            <Styled.SectionHeading>Metadata</Styled.SectionHeading>
            <MetaTable>
              <MetaTable.SmallColumn>Created by</MetaTable.SmallColumn>
              <MetaTable.BigColumn>{token.createdBy}</MetaTable.BigColumn>
              <MetaTable.SmallColumn>on</MetaTable.SmallColumn>
              <MetaTable.SmallColumn>{token.createdAt}</MetaTable.SmallColumn>
            </MetaTable>
          </TokenForm>
        )}
      </FormLayout>
    </Modal>
  );
};
