import { FunctionComponent } from 'react';
import { User } from '../../definitions';
import UserList from '../../lists/UserList';
import { Checkbox, BulkSelect } from '../../components/BulkSelect';
import TableMeta from '../../components/TableMeta';

type AddUserTableProps = {
  isFetching: boolean;
  disabledUserIds: Set<string>;
  userList: User[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfUsers: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  selectedUserState: Record<string, boolean>;
  onCheckUserCheckbox: (user: Record<string, boolean>) => void;
  numberOfSelectedUsers: number;
  onClearSelectedUsers: () => void;
};

export const AddUserTableView: FunctionComponent<AddUserTableProps> = ({
  selectedUserState,
  isFetching,
  disabledUserIds,
  userList,
  onNextPage,
  onPrevPage,
  numberOfUsers,
  isPrevPageDisabled,
  isNextPageDisabled,
  onCheckUserCheckbox,
  numberOfSelectedUsers,
  onClearSelectedUsers,
}) => {
  const SelectBox = (user: User) => (
    <Checkbox
      id={user.id}
      selectedState={selectedUserState}
      disabledIdMap={disabledUserIds}
      onChange={onCheckUserCheckbox}
    />
  );
  const BulkSelectBox = () => (
    <BulkSelect
      items={userList}
      selectedState={selectedUserState}
      disabledIdMap={disabledUserIds}
      onChange={onCheckUserCheckbox}
    />
  );

  return (
    <>
      <TableMeta
        title={`Users selected: ${numberOfSelectedUsers}`}
        onActionClick={onClearSelectedUsers}
      />
      <UserList
        userList={userList}
        isFetching={isFetching}
        numberOfUsers={numberOfUsers}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
        action={BulkSelectBox}
        actionTitle={SelectBox}
      />
    </>
  );
};
