import styled from 'styled-components';
import { rgba } from 'polished';
import Button from '../Button';

type PanelProps = {
  isCollapsed: boolean;
};

export const Panel = styled.div<PanelProps>`
  background: ${props => props.theme.palettes.inverse};
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding-bottom: 3rem;
  min-width: auto;
  box-shadow: ${props => props.theme.effects.leftShadow};
  max-width: ${props => props.theme.constants.metaPanelWidth};

  ${props => props.theme.media.medium`
    min-width: ${props.theme.constants.metaPanelWidth};  
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  min-height: 3rem;
  border-bottom: 1px solid ${props => props.theme.palettes.headerBorder};
  font-weight: 700;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem 1.5rem;
`;

export const PanelTrigger = styled(Button)<PanelProps>`
  position: fixed;
  top: 10rem;
  left: -3rem;
  width: 3rem;
  min-width: 3rem;
  background: ${props => props.theme.palettes.inverse};
  height: 3rem;
  border-radius: 1rem 0 0 1rem;
  border: 1px solid ${props => props.theme.palettes.border};
  opacity: 0.5;
  svg {
    width: 1.25rem;
    height: auto;
    transform: ${props => (props.isCollapsed ? 'rotate(-90deg)' : 'rotate(-270deg)')};
  }

  &:hover {
    opacity: 1;
    &&& {
      background: ${props => props.theme.palettes.inverse};
      border: 1px solid ${props => rgba(props.theme.palettes.border, 0.5)};
    }
  }
`;

export const Container = styled.div<PanelProps>`
  position: absolute;
  transition: transform 200ms ease-out;
  transform: translateX(${props => (props.isCollapsed ? `100%` : 0)});
  right: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  ${props => props.theme.media.medium`
    min-width: ${props.theme.constants.metaPanelWidth};
    right: auto;
    position: unset;
    height: auto;
  `}
`;
