import styled, { css } from 'styled-components';

export const Container = styled.img`
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  display: block;

  ${props =>
    props.theme.media.small &&
    css`
      width: 2.5rem;
      height: 2.5rem;
    `}

  ${props => props.theme.media.medium`
    width: 12rem;
    height: 12rem;
  `}
`;

export const BackgroundImage = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 50%;
`;
