import { FunctionComponent } from 'react';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import ComponentForm from '../../forms/ComponentForm';
import {
  ColorTheme,
  DrawerPosition,
  EditComponentFormData,
  CreateComponentFormData,
  FormStateType,
} from '../../definitions';
import { Icon } from '../../components/Icon';
import { Drawer } from '../../components/Drawer';

type CreateComponentProps = {
  isCreating: boolean;
  onCreateComponent: (formData: CreateComponentFormData | EditComponentFormData) => void;
  isFetching: boolean;
  componentTypes: CatalogItemView.AsObject[];
  visible: boolean;
  onHide: () => void;
  onUpdateComponentState: (formState: FormStateType) => void;
  savedFormData: CreateComponentFormData;
};

export const CreateComponentView: FunctionComponent<CreateComponentProps> = ({
  onCreateComponent,
  isCreating,
  isFetching,
  componentTypes,
  onHide,
  visible,
  onUpdateComponentState,
  savedFormData,
}) => {
  return (
    <Drawer
      visible={visible}
      onHideMenu={onHide}
      position={DrawerPosition.Right}
      fullScreenOnSmall={true}
      colorTheme={ColorTheme.Light}
      title="Create component"
    >
      <ComponentForm
        isFetching={isFetching}
        isSubmitting={isCreating}
        submitButtonText="Create"
        onSubmit={onCreateComponent}
        componentTypes={componentTypes}
        onClose={onHide}
        submitButtonIcon={<Icon.Create />}
        onUpdateComponentState={onUpdateComponentState}
        initialValues={savedFormData}
        isShortnameDisabled={false}
      />
    </Drawer>
  );
};
