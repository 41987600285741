import { FlowStepObject } from '../../definitions';
import { RootState } from '../reducers';

export const getCurrentStep = (state: RootState): FlowStepObject => {
  return state.flow.steps[state.flow.index] || ({} as FlowStepObject);
};

export const getPreviousStep = (state: RootState): FlowStepObject => {
  return state.flow.steps[state.flow.previousIndex] || ({} as FlowStepObject);
};

export const getSteps = (state: RootState): FlowStepObject[] => {
  return state.flow.steps;
};

export const getFlowId = (state: RootState): string => {
  return state.flow.id || '';
};

export const getStepIndex = (state: RootState): number => {
  return state.flow.index;
};

export const getFlowReturnPath = (state: RootState): string => {
  return state.flow.returnPath;
};

export const hasNextStep = (state: RootState): boolean => {
  return state.flow.index + 1 < state.flow.steps.length;
};

export const hasPreviousStep = (state: RootState): boolean => {
  return state.flow.index - 1 >= 0 && getCurrentStep(state).back !== false;
};

export const isStepSkippable = (state: RootState): boolean => {
  return Boolean(getCurrentStep(state).skip);
};
