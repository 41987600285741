import { FunctionComponent } from 'react';
import { ComponentRefInTheList, TeamMap, RelationCluster } from '../../definitions';
import {
  useGroupComponentsByTeamName,
  useGroupComponentsByType,
  useGroupComponentsByTag,
} from '../../hooks';
import GrouppedBlock from './GrouppedBlock';
import UngrouppedBlock from './UngrouppedBlock';

type ClusterEnrichedDependencyBlockProps = {
  relationCluster: RelationCluster;
  components: ComponentRefInTheList[];
  onComponentClick: (component: ComponentRefInTheList) => void;
  noSelectedComponentTitle: string;
  noDependenciesTitle: string;
  teamMap: TeamMap;
  isDependencySelected: boolean;
  isDependantSelected: boolean;
  selectedComponent?: ComponentRefInTheList;
  selectedComponentId: string;
  onSetSelectComponentModalOpen: (isOpen: boolean) => void;
  onViewComponent: (componentShortname: string) => void;
  isFetching: boolean;
  selectedTag: string;
};

export const ClusterEnrichedDependencyBlock: FunctionComponent<ClusterEnrichedDependencyBlockProps> =
  ({ relationCluster, teamMap, selectedTag, components, ...rest }) => {
    const grouppedByTeamComponents = useGroupComponentsByTeamName(components, teamMap);
    const grouppedByTypeComponents = useGroupComponentsByType(components);
    const grouppedByTagComponents = useGroupComponentsByTag(components, selectedTag);

    switch (relationCluster) {
      case RelationCluster.Team:
        return (
          <GrouppedBlock
            teamMap={teamMap}
            components={components}
            grouppedComponents={grouppedByTeamComponents}
            {...rest}
          />
        );
      case RelationCluster.Type:
        return (
          <GrouppedBlock
            teamMap={teamMap}
            components={components}
            grouppedComponents={grouppedByTypeComponents}
            {...rest}
          />
        );
      case RelationCluster.Tag:
        return (
          <GrouppedBlock
            teamMap={teamMap}
            components={components}
            grouppedComponents={grouppedByTagComponents}
            {...rest}
          />
        );
      default:
        return <UngrouppedBlock components={components} {...rest} />;
    }
  };
