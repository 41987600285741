import { all, takeLatest, select, put, AllEffect } from 'redux-saga/effects';
import services from '../../services';
import { SequenceType, request, ActionType, AlertType } from '../../definitions';
import { MarkAllNotificationsAsReadRequest } from '../../grpc/grpcweb/notification_pb';
import actionTypes from '../constants/actionTypes';
import {
  getAccessToken,
  getFetchNotificationsError,
  getMarkAllNotificationsAsReadError,
} from '../selectors';
import { showAlert, fetchAllNotifications } from '../actions';

function* fetchNotifications(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch notifications.',
      }),
    );
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.notifications.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ notificationService }) =>
          notificationService
            .getNotificationList(token)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchNotificationsError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* markNotificationsAsRead(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to mark notifications as read.',
      }),
    );
  }

  const markAsReedRequest = new MarkAllNotificationsAsReadRequest();
  markAsReedRequest.setLastreadnotificationcreationtime(action.payload);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.notifications.markedAsRead },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ notificationService }) =>
          notificationService
            .clearAllNotifications(token, markAsReedRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchAllNotifications());
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getMarkAllNotificationsAsReadError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.notifications.fetch, fetchNotifications)]);
  yield all([takeLatest(actionTypes.notifications.markAsRead, markNotificationsAsRead)]);
}
