import { FunctionComponent, useMemo, ElementType, useState, useCallback } from 'react';
import type {} from 'react-select/base';
import Select, { components, MultiValue, ControlProps } from 'react-select';
import Button from '../Button';
import { Icon } from '../Icon';
import { Accordion } from '../Accordion';
import {
  Appearance,
  theme,
  FilterOption,
  TreeFilterOption,
  MappedTreeSelectOptions,
} from '../../definitions';
import { CustomMenu } from '../Form/Select/Select.view';
import TreeFilter from './TreeFilter';
import * as Styled from './FilterMenu.styles';

type FilterProps = {
  isFetching: boolean;
  onFilter: (options: string[]) => void;
  options: FilterOption[];
  selectedOptions: string[];
  label: string;
};

export type TreeFilterProps = {
  isFetching: boolean;
  onFilter: (mappedOptions: MappedTreeSelectOptions) => void;
  options: TreeFilterOption[];
  selectedOptions: { keyList: string[] | undefined; valueList: string[] | undefined };
  label: string;
};

type FilterMenuProps = {
  numberOfActiveFilters: number;
  onClearAllFilters: () => void;
};

type IFilterMenu = FunctionComponent<FilterMenuProps> & {
  Filter: ElementType<FilterProps>;
  TreeFilter: ElementType<TreeFilterProps>;
};

export const FilterMenuView: IFilterMenu = ({
  numberOfActiveFilters,
  onClearAllFilters,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Styled.Filter>
      <Styled.FilterMenu>
        <Styled.FilterTriggger
          onClick={() => setIsExpanded(!isExpanded)}
          isActive={numberOfActiveFilters > 0}
        >
          {numberOfActiveFilters > 0 ? <Icon.FilterOn /> : <Icon.Filter />}
          <Styled.FilterMeta>
            <span>{numberOfActiveFilters}</span>
            <p>Filters</p>
          </Styled.FilterMeta>
        </Styled.FilterTriggger>
        <Styled.Divider>|</Styled.Divider>
        <Button
          appearance={Appearance.Link}
          color={theme.palettes.placeholder}
          onClick={onClearAllFilters}
        >
          Clear all
        </Button>
      </Styled.FilterMenu>
      <Accordion isExpanded={isExpanded}>
        <Styled.SelectWrapper>{children}</Styled.SelectWrapper>
      </Accordion>
    </Styled.Filter>
  );
};

export const FilterView: FunctionComponent<FilterProps> = ({
  isFetching,
  onFilter,
  options,
  selectedOptions,
  label,
  children,
}) => {
  const preselectedOptions = useMemo(
    () => options.filter(option => selectedOptions.includes(option.value)),
    [options, selectedOptions],
  );

  const [selectRef, setSelectRef] = useState<any>(null);

  const blurMenu = () => {
    selectRef!.blur();
  };

  const Control = (props: ControlProps & { label: string }) => {
    return (
      <>
        <Styled.SelectLabel isFloating={props.isFocused || props.hasValue}>
          {props.label}
        </Styled.SelectLabel>
        <components.Control {...props} />
      </>
    );
  };

  const handleFilter = useCallback(
    (selectedOptions: MultiValue<FilterOption>) => {
      const options = selectedOptions.map((option: FilterOption) => option.value);
      onFilter(options);
    },
    [onFilter],
  );

  return (
    <Styled.Container>
      <Styled.FluidRow>
        <Select
          ref={ref => {
            setSelectRef(ref);
          }}
          value={preselectedOptions}
          onChange={(value: MultiValue<FilterOption>) => {
            handleFilter(value);
          }}
          isDisabled={isFetching}
          options={options}
          styles={Styled.customStyles}
          isClearable={true}
          isMulti={true}
          isLoading={isFetching}
          maxMenuHeight={200}
          closeMenuOnSelect={true}
          blurInputOnSelect={true}
          menuShouldScrollIntoView={false}
          components={{
            Control: (componentProps: any) => <Control label={label} {...componentProps} />,
            MenuList: (componentProps: any) => (
              <CustomMenu nestedComponents={children} {...componentProps} blurMenu={blurMenu} />
            ),
          }}
        />
      </Styled.FluidRow>
    </Styled.Container>
  );
};

FilterMenuView.Filter = FilterView;
FilterMenuView.TreeFilter = TreeFilter;
