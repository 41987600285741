import { FunctionComponent, useState, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { RelationCluster, validation, Appearance } from '../../../definitions';
import { RadioButtonItem, Select, FormLayout } from '../../../components/Form';
import { Icon } from '../../../components/Icon';
import Button from '../../../components/Button';
import * as Styled from './ClusterByTagRadioButton.styles';

type ClusterByTagRadioButtonProps = {
  onResetTag: () => void;
  selectedTag?: string;
  onSelectTag: (tagKey: string) => void;
  clusterBy: RelationCluster;
  onSetClusterByTag: () => void;
  tags: string[];
  onFetchTags: () => void;
  isFetchingTags: boolean;
};

export const ClusterByTagRadioButtonView: FunctionComponent<ClusterByTagRadioButtonProps> = ({
  clusterBy,
  tags,
  onSetClusterByTag,
  onSelectTag,
  selectedTag = '',
  onResetTag,
  onFetchTags,
  isFetchingTags,
}) => {
  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false), // close the menu when the user clicks outside
    onDisappear: () => setOpen(false), // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  const handleClusterByTag = (tagKey: string) => {
    if (!tagKey) {
      onResetTag();
    } else {
      onSetClusterByTag();
      onSelectTag(tagKey);
      setOpen(false);
    }
  };

  const mappedOptions: { label: string; value: string }[] = useMemo(() => {
    const options: { label: string; value: string }[] = tags.map((tag: string) => ({
      label: tag,
      value: tag,
    }));
    return options;
  }, [tags]);

  const handleReset = () => {
    onResetTag();
    setOpen(false);
  };

  const handlePressTrigger = () => {
    if (!isOpen) {
      onFetchTags();
    }
    setOpen(!isOpen);
  };

  return (
    <>
      <div {...triggerProps} onClick={() => setOpen(!isOpen)}>
        <RadioButtonItem
          isChecked={clusterBy === RelationCluster.Tag}
          label={selectedTag ? `Tag: ${selectedTag}` : 'Tag'}
          onClick={handlePressTrigger}
        />
      </div>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <Form
                initialValues={{ tag: selectedTag }}
                onSubmit={(values: { tag: string }) => {
                  handleClusterByTag(values.tag);
                }}
                render={({ handleSubmit, pristine, valid, values, form }) => {
                  const onSubmitClick = (
                    event: Partial<
                      Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>
                    >,
                  ) => {
                    handleSubmit(event);
                  };
                  return (
                    <>
                      <Field
                        id="tag"
                        name="tag"
                        label="Tag"
                        component={Select}
                        options={mappedOptions}
                        validate={value => validation.required(value)}
                        showValidation={true}
                        onReset={() => form.change('tag', undefined)}
                        description="Select a tag to group components based on its values."
                        isLoadingOptions={isFetchingTags}
                      />
                      <FormLayout.ActionWrapper>
                        <Button onClick={handleReset} appearance={Appearance.PrimaryWithIcon}>
                          <Icon.Cross />
                          <span>Reset</span>
                        </Button>
                        <FormLayout.Submit
                          onClick={onSubmitClick}
                          appearance={Appearance.Primary}
                          isHighlighted={true}
                          isDisabled={isFetchingTags}
                        >
                          Cluster
                        </FormLayout.Submit>
                      </FormLayout.ActionWrapper>
                    </>
                  );
                }}
              />
              <Arrow {...arrowProps} />
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
