import styled from 'styled-components';
import { darken, lighten } from 'polished';
import Button from '../Button';

export const Wrapper = styled.div`
  flex: 1;
  text-align: left;
  border-radius: 0.5rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => lighten(0.25, theme.palettes.inverse)};
  position: relative;
  padding: 0.5rem 0.75rem;
  gap: 0.25rem;
`;

export const CardWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.effects.borderShadow};
  border: 1px solid ${({ theme }) => lighten(0.15, theme.palettes.border)};
  border-radius: 0.5rem;
`;

export const CardContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(5em, 12em));
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.25rem;
  flex: 1;
`;

export const MoreButton = styled(Button)`
  height: 1.3rem;
  border-radius: 0.4rem;

  &:hover {
    &&& {
      text-decoration: none;
      background-color: ${props => darken(0.025, props.theme.palettes.inverse)};
      color: ${props => darken(0.2, props.theme.palettes.placeholder)};
      box-shadow: ${props => props.theme.effects.delicateShadow};
    }
  }
`;
