import { FunctionComponent } from 'react';
import { ComponentInTheDropdown } from '../../definitions';
import * as Styled from './ComponentSelectOption.styles';

export const ComponentSelectOptionView: FunctionComponent<ComponentInTheDropdown> = ({
  label,
  type,
}) => {
  return (
    <Styled.ComponentDropdownRow>
      <div>
        <Styled.Label>{label}</Styled.Label>
        <span>type: {type}</span>
      </div>
    </Styled.ComponentDropdownRow>
  );
};
