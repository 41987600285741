import styled from 'styled-components';

export const TooltipContainer = styled.div`
  background: ${({ theme }) => theme.palettes.inverse};
  padding: 0.5rem;
  opacity: 0.83;

  h6 {
    font-weight: 600;
  }
`;
