import styled from 'styled-components';
import { Link } from '../../components/Link';
import { Image } from '../../components/Image';
import Button from '../../components/Button';
import stars from '../../components/Image/stars.png';

type StackedSectionProps = {
  isInverted?: boolean;
};

export const Notification = styled.div`
  background: ${({ theme }) => theme.palettes.main};
  padding: 1rem 4vw 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: inherit;
    color: ${({ theme }) => theme.palettes.mute};
    text-align: center;
    margin: 0;
    margin-bottom: 0.5rem;
    align-self: flex-end;
    max-width: 42rem;
    font-size: 0.75rem;
  }
  ${props => props.theme.media.medium`
    flex-direction: row;
    padding: 1vw 4vw 3vw;
    p {
      font-size: 1.25rem;
      margin-right: 1rem;
      margin-bottom: 0;
      text-align: right;
    }
  `}
`;

export const NotifyMeButton = styled(Button)`
  align-self: center;
  width: 16rem;
  min-height: 3rem;
`;

export const Section = styled.div`
  padding: 8vw 8vw 0;
  display: flex;
  flex-direction: column;
  text-align: center;

  h2 {
  svg {
    max-width: 2rem;
  }
  }
`;

export const NegativeSection = styled(Section)`
  color: ${({ theme }) => theme.palettes.inverse};
`;

export const StackedSection = styled(Section)<StackedSectionProps>`
  .heading {
    margin-bottom: 6vw;
    line-height: 1.1;
  }

  .heading {
    ${props => props.theme.media.medium`
      min-width: 15rem;
      margin-bottom: 0;
    `}
  }

  ${props => props.theme.media.medium`
    flex-direction: ${props.isInverted ? 'row-reverse' : 'row'};
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    text-align: justify;
  }
`;

export const Subscribe = styled.footer`
  padding: 8vw 8vw 12vw;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palettes.main};
  color: ${({ theme }) => theme.palettes.highlight};
  align-items: center;

  h6 {
    color: ${({ theme }) => theme.palettes.inverse};
    margin: 2vw auto 4vw;
  }
`;

export const LaunchBckWrapper = styled.div`
  background: ${({ theme }) => theme.palettes.inverse};
  background: ${({ theme }) => theme.effects.launchBackground};
`;

export const Help = styled.p`
  padding: 1rem 4vw;
  margin-top: 3rem;
`;

export const SpaceWrapper = styled.div`
  background: ${({ theme }) => theme.palettes.main};
  color: ${({ theme }) => theme.palettes.inverse};
  background-image: url(${stars});
  background-attachment: fixed;
  background-size: auto;
  background-position: top left;
  padding-bottom: 6vw;
  margin-top: 8vw;
`;

export const Mila = styled(Image).attrs({
  name: 'mila',
  alt: 'mila avatar',
})``;

export const Nadia = styled(Image).attrs({
  name: 'nadia',
  alt: 'nadia avatar',
})``;

export const Leo = styled(Image).attrs({
  name: 'leo',
  alt: 'leo avatar',
})``;

export const Sergejs = styled(Image).attrs({
  name: 'serzh',
  alt: 'serzh avatar',
})``;

export const Evgeny = styled(Image).attrs({
  name: 'evgeny',
  alt: 'evgeny avatar',
})``;

export const TreeImage = styled(Image).attrs({
  name: 'tree',
  alt: 'tree structure',
})`
  height: auto;
  width: 100%;
  margin-top: 2rem;
  max-width: 30rem;
`;

export const ArrowImage = styled(Image).attrs({
  name: 'arrow',
  alt: 'arrow down',
})`
  height: auto;
  width: 100%;
  max-width: 30rem;
`;

export const AstronautImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem 0 0;

  ${props => props.theme.media.medium`
    padding: 4rem 0 0;
  `}
`;

export const DiscordLink = styled(Link)`
  font-size: inherit;
  cursor: pointer;
  color: ${(props) => props.theme.palettes.highlight};
  &:hover {
    text-decoration: underline;
  }
`;

export const AstronautImage = styled(Image).attrs({
  name: 'flag',
  alt: 'astronaut carry flag',
})`
  width: auto;
  height: 7rem;
  z-index: 1;

  ${props => props.theme.media.medium`
    height: 10rem;
  `}
`;

export const ScrollToTopWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
`;

export const Integration = styled(Link)`
  color: ${({ theme }) => theme.palettes.inverse};
  cursor: ${props => (props.to ? 'pointer' : 'default')};

  svg {
    height: 5rem;
    width: auto;
    max-width: 9rem;
    margin: 0 0.5rem;
    z-index: 2;
  }
`;

export const AnchorLink = styled.a`
  font-size: inherit;
  cursor: pointer;
  color: ${(props) => props.theme.palettes.highlight};
  &:hover {
    text-decoration: underline;
  }
`;

export const EmailWrapper = styled.div`
  display: inline-flex;
`;
