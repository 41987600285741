import { FunctionComponent } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Loader } from '../../components/Loader';
import { Empty } from '../../components/Empty';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import CreateTagDrawer from '../CreateTag';
import EditTagDrawer from '../EditTag';
import Button from '../../components/Button';
import { Layout } from '../../components/Layout';
import { Icon } from '../../components/Icon';
import TagList from '../../lists/TagList';
import { Appearance, Tags, Tag } from '../../definitions';

type TagListProps = {
  onEditTag: (tag: Tag) => void;
  isFetchingTags: boolean;
  tagList: Tags;
  isCreateDrawerVisible: boolean;
  onOpenCreateTag: () => void;
  isEditDrawerVisible: boolean;
  onOpenEditTag: (tagKey: string) => void;
  onBackToTagList: () => void;
  numberOfTags: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
} & RouteChildrenProps;

export const TagListView: FunctionComponent<TagListProps> = ({
  onEditTag,
  isFetchingTags,
  tagList,
  isCreateDrawerVisible,
  onOpenCreateTag,
  isEditDrawerVisible,
  onOpenEditTag,
  onBackToTagList,
  numberOfTags,
  onNextPage,
  onPrevPage,
  isPrevPageDisabled,
  isNextPageDisabled,
  ...rest
}) => {
  const CreateTagAction: FunctionComponent<{ isCollapsible?: boolean }> = ({ isCollapsible }) => (
    <Button
      onClick={onOpenCreateTag}
      appearance={
        isCollapsible ? Appearance.PrimaryWithIconCollapsible : Appearance.PrimaryWithIcon
      }
    >
      <Icon.Plus />
      <span>Create tag</span>
    </Button>
  );

  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>Tags</Layout.HeaderTitle>
      <Layout.HeaderActions>
        <CreateTagAction isCollapsible={true} />
      </Layout.HeaderActions>
    </Layout.Header>
  );

  return (
    <>
      <AuthenticatedLayout header={Header}>
        {isFetchingTags ? (
          <Loader.Container>
            <Loader />
          </Loader.Container>
        ) : Object.keys(tagList).length ? (
          <TagList
            tagList={Object.values(tagList)}
            isFetching={isFetchingTags}
            numberOfTags={numberOfTags}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            isPrevPageDisabled={isPrevPageDisabled}
            isNextPageDisabled={isNextPageDisabled}
            onEditTag={onEditTag}
          />
        ) : (
          <Empty text="There are no tags. Start by creating one.">
            <CreateTagAction />
          </Empty>
        )}
      </AuthenticatedLayout>
      <CreateTagDrawer visible={isCreateDrawerVisible} onHide={onBackToTagList} {...rest} />
      <EditTagDrawer visible={isEditDrawerVisible} onHide={onBackToTagList} {...rest} />
    </>
  );
};
