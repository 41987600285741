import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import actionTypes from '../constants/actionTypes';
import { isLoading } from './loading';
import { RootState } from '../reducers';
import { wasSuccessful } from './success';
import { getErrorMessage } from './errors';
import {
  SloFormData,
  EditSloFormData,
  SloInTheList,
  SLOHistoryPoint,
  SLOActivity,
  CommentData,
} from '../../definitions';

export const getSLOList = (state: RootState): SloInTheList[] => {
  return state.slos.list;
};

export const getListSLOError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.fetchList);
};

export const isFetchingSLOList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.fetchList);
};

export const getSelectedSLO = (state: RootState): EditSloFormData => {
  return state.slos.selected;
};

export const getSelectedSLOId = (state: RootState): EditSloFormData['id'] => {
  return state.slos.selected.id;
};

export const getSelectedSLODisplayName = (state: RootState): EditSloFormData['displayName'] => {
  return state.slos.selected.displayName;
};

export const getSelectedSLODescription = (state: RootState): EditSloFormData['description'] => {
  return state.slos.selected.description;
};

export const getSelectedSLOComponentId = (state: RootState): EditSloFormData['sloComponentId'] => {
  return state.slos.selected.sloComponentId;
};

export const getSavedNewSLO = (state: RootState): SloFormData => {
  return state.slos.new;
};

export const isFetchingSLO = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.fetchOne);
};

export const getFetchSLOError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.fetchOne);
};

export const getSLOComments = (state: RootState): CommentData[] => {
  return state.slos.selectedSLOComments;
};

export const isFetchingSLOComments = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.comments.fetch);
};

export const getFetchSLOCommentsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.comments.fetch);
};

export const isAddingSLOComment = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.comments.add);
};

export const getAddSLOCommentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.comments.add);
};

export const getSLOHistory = (state: RootState): SLOHistoryPoint[] => {
  return state.slos.selectedSLOHistory;
};

export const isFetchingSLOHistory = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.fetchHistory);
};

export const getFetchSLOHistoryError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.fetchHistory);
};

export const getSLOActivity = (state: RootState): SLOActivity[] => {
  return state.slos.selectedSLOActivity;
};

export const isFetchingSLOActivity = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.activity.fetch);
};

export const getFetchSLOActivityError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.activity.fetch);
};

export const isFetchingSLOActivityComments = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.activity.fetchComments);
};

export const getFetchSLOActivityCommentsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.activity.fetchComments);
};

export const isCreatingSLO = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.create);
};

export const getCreateSLOError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.create);
};

export const createdSLOSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.slos.create);
};

export const isUpdatingSLO = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.update);
};

export const getUpdateSLOError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.update);
};

export const getSLOTypes = (state: RootState): CatalogItemView.AsObject[] => {
  return state.slos.types;
};

export const isFetchingSLOTypes = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.fetchTypes);
};

export const getFetchSLOTypesError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.fetchTypes);
};

export const isLaunchingSLO = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.launch);
};

export const getLaunchSLOError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.launch);
};

export const getDeleteSLOError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.slos.delete);
};

export const isDeletingSLO = (state: RootState): boolean => {
  return isLoading(state, actionTypes.slos.delete);
};
