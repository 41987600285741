import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import EventSourceForm from '../../forms/EventSource';
import { Appearance, EditSourceFormData } from '../../definitions';
import { FormLayout } from '../../components/Form';
import PopConfirm from '../../components/PopConfirm';
import { Icon } from '../../components/Icon';
import Button from '../../components/Button';

type CreateEventSourceProps = {
  onClose: () => void;
  onDelete: (sourceId?: string, displayName?: string) => void;
  isOpen: boolean;
  isUpdating: boolean;
  isFetching: boolean;
  isDeleting: boolean;
  onUpdateEventSource: (formData: EditSourceFormData) => void;
  initialValues: EditSourceFormData;
};

export const EditEventSourceView: FunctionComponent<CreateEventSourceProps> = ({
  children,
  onClose,
  onUpdateEventSource,
  isUpdating,
  initialValues,
  isFetching,
  onDelete,
  isDeleting,
  ...rest
}) => {
  const DeleteButton: FunctionComponent = () => (
    <Button
      isDisabled={isFetching || isUpdating}
      isPending={isDeleting}
      appearance={Appearance.PrimaryWithIcon}
    >
      <Icon.Trash />
      <span>Delete</span>
    </Button>
  );

  return (
    <Modal onClose={onClose} title="Update event source" {...rest}>
      <FormLayout isModal={true}>
        <EventSourceForm
          onSubmit={onUpdateEventSource}
          initialValues={initialValues}
          isLoading={isUpdating}
          action="Update"
          isDisabled={isFetching || isDeleting}
          onCancel={onClose}
          extraAction={
            <PopConfirm
              title={`Are you sure you want to delete the event source?`}
              onConfirm={() => onDelete(initialValues.id, initialValues.displayName)}
              isConfirming={isDeleting}
              isDisabled={isFetching || isUpdating}
              contentText="Please note that after confirming it will be permanently deleted. You will not be
                  able to restore the deleted event source."
            >
              <DeleteButton />
            </PopConfirm>
          }
        />
      </FormLayout>
    </Modal>
  );
};
