import { FunctionComponent, ElementType, useRef, useState } from 'react';
import { Appearance, PositionX, externalLinks } from '../../definitions';
import Header from '../../components/Header';
import CopyEmail from '../../components/CopyEmail';
import { Grid } from '../../components/Grid';
import Button from '../../components/Button';
import FactBox from '../../components/FactBox';
import SocialButtons from '../../components/SocialButtons';
import { Icon } from '../../components/Icon';
import ScrollIntoView from '../../components/ScrollIntoView';
import Tooltip from '../../components/Tooltip';
import Divider from '../../components/Divider';
import { facts, integrations, tools, Fact, Integration } from './landingData';
import CharacterBox from '../../components/CharacterBox';
import LandingLayout from '../../layouts/LandingLayout';
import * as Styled from './Landing.styles';

type LandingViewProps = {
  isLoggedIn: boolean;
  onLogout: () => void;
  onDemoLoginClick: () => void;
  isLoadingDemoAccount: boolean;
};

export const LandingView: FunctionComponent<LandingViewProps> = ({ isLoggedIn, onLogout, onDemoLoginClick, isLoadingDemoAccount }) => {
  const refs = {
    home: useRef<HTMLDivElement>(null),
    platform: useRef<HTMLDivElement>(null),
    crew: useRef<HTMLDivElement>(null),
    integrations: useRef<HTMLDivElement>(null),
    follow: useRef<HTMLDivElement>(null),
  };

  const [activePath, setActivePath] = useState<string | undefined>(undefined);

  const handleFocusOnSocials = () => {
    refs.follow?.current?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    setActivePath('follow');
  };

  return (
    <LandingLayout
      onLogout={onLogout}
      isLoggedIn={isLoggedIn}
      refs={refs}
      activePath={activePath}
      onSetActivePath={(path: string) => setActivePath(path)}
    >
      <Header ref={refs.home} />
      <Styled.Notification>
        <p>
          Follow{` `}
          <Styled.AnchorLink onClick={handleFocusOnSocials}>socials</Styled.AnchorLink>
          {` `}for updates, drop a note to our{` `}
          <Styled.EmailWrapper>
            <Styled.DiscordLink to={'https://discord.gg/DaSDTaYC'}>Discord</Styled.DiscordLink>
          </Styled.EmailWrapper>
          {` `}for any feedback or questions, or join waiting list and be notified of the launch:
        </p>
        <Styled.NotifyMeButton appearance={Appearance.Funky} to={externalLinks.mailchimpUrl}>
          Notify me of the launch
        </Styled.NotifyMeButton>
      </Styled.Notification>
      <Styled.LaunchBckWrapper>
        <Styled.StackedSection>
          <h2 className="heading">
            Discover the Platform: addressing client needs with precision
          </h2>
          <Divider isHorizontal={false} />
          <Styled.Column>
            <p>
              Welcome to our platform, where innovation meets efficiency to transform your processes like never before. Let's take a journey through the key features that empower our clients to streamline their operations and elevate their performance.
              <br />
              <br />
              At the core of our platform lies the Components Graph, a dynamic framework that provides a comprehensive view of your system architecture. With different abstraction layers, from products to modules, it offers unparalleled visibility into your components. Teams and users have clear ownership, ensuring accountability and driving collaboration. Dive deeper into component details effortlessly with links, tags, and descriptions, while understanding their inter-relations through dependencies and dependents. Stay ahead of the curve with real-time updates on recent changes, including deployments, feature flag toggles, and incidents, allowing you to adapt swiftly to evolving landscapes.
              <br />
              <br />
              Coming soon to our platform is SLO-based quality controls, revolutionizing how you manage system performance. Utilizing metrics from leading systems like Prometheus, CloudWatch, and Datadog, you'll gain insights into error budgets and objective breaches. Receive instant notifications when thresholds are exceeded, empowering you to take proactive measures to restore quality. Analyze the ripple effects of breached component quality on dependent components, enabling strategic decision-making to mitigate risks and optimize performance.
              <br />
              <br />
              With our platform, the possibilities are endless. Embrace the future of process optimization and embark on a journey towards unparalleled success.
            </p>
          </Styled.Column>
        </Styled.StackedSection>
        <Styled.Section ref={refs.platform}>
          <h2>Are You Ready to Reach the Stars?</h2>
          <h5>SLO management universe two clicks away</h5>
          <Divider />
          <Grid.Bounds>
            {facts.map(({ label, icon, text }: Fact) => (
              <Grid.Box key={label}>
                <FactBox label={label} icon={icon} text={text} />
              </Grid.Box>
            ))}
          </Grid.Bounds>
        </Styled.Section>
        <Styled.SpaceWrapper ref={refs.integrations}>
          <Styled.StackedSection isInverted={true}>
            <div className="heading">
              <h2>Integrations</h2>
              <h5>
                RocketSLO constantly pulls metrics from your Monitors systems, calculates SLOs and
                Error Budgets and notifies about any anomalies
              </h5>
              <Styled.Help>
                Don't see your desired tool here? No worries!{' '}
                <Styled.AnchorLink onClick={handleFocusOnSocials}>Let's discus</Styled.AnchorLink>{' '}
                adding another integration to make your journey enjoyable
              </Styled.Help>
            </div>
            <Divider isHorizontal={false} />
            <Styled.Column>
              <Grid.Bounds>
                {integrations.map(({ tooltip, link, icon }: Integration) => {
                  const IntegrationIcon = icon;
                  return (
                    <Grid.Box key={tooltip}>
                      <Styled.Integration to={link}>
                        <IntegrationIcon />
                      </Styled.Integration>
                    </Grid.Box>
                  );
                })}
              </Grid.Bounds>
              <Styled.TreeImage />
              <Grid.Bounds>
                {tools.map(({ tooltip, link, icon }: Integration) => {
                  const ToolIcon = icon;
                  return (
                    <Grid.Box key={tooltip}>
                      <Styled.Integration to={link}>
                        <ToolIcon />
                      </Styled.Integration>
                    </Grid.Box>
                  );
                })}
              </Grid.Bounds>
            </Styled.Column>
          </Styled.StackedSection>
        </Styled.SpaceWrapper>
        <Styled.NegativeSection ref={refs.crew}>
          <h2>
            Learn More{` `}<Icon.Rocket />
          </h2>
          <h5>Seeking further details or eager to test out the product firsthand?</h5>
          <Divider />
          <Grid.Bounds>
            <Grid.Box>
              <CharacterBox label="Docs & Guides" icon={Icon.Book} url={"https://docs.rocketslo.com"} description="Explore the link for a deeper dive into our product, complete with developer guides to help you navigate every feature seamlessly" />
            </Grid.Box>
            <Grid.Box>
              <CharacterBox label="API Reference" icon={Icon.Build} url={"https://github.com/rocketslo/apiref"} description="Access the repository link housing proto files, granting you seamless integration with RocketSLO's public gRPC API" />
            </Grid.Box>
            <Grid.Box>
              <CharacterBox label="Get Access" icon={Icon.Access} url={"https://discord.com/invite/DaSDTaYC"} description="Secure Early Bird access to our exclusive private Beta version, and join us as a valued partner in shaping the product's vision" />
            </Grid.Box>
            <Grid.Box>
              <CharacterBox label="Request Demo" icon={Icon.Demo} url={"https://discord.com/invite/DaSDTaYC"} description="Craving more? Schedule a complimentary demo with one of our team members to guide you through our product firsthand" />
            </Grid.Box>
            <Grid.Box>
              <CharacterBox label="Try it now!" isLoading={isLoadingDemoAccount} isButton={true} icon={Icon.Try} onClick={onDemoLoginClick} description="Feeling eager and impatient to get started? Take a sneak peek and experience it right now with our demo account" />
            </Grid.Box>
          </Grid.Bounds>
        </Styled.NegativeSection>
      </Styled.LaunchBckWrapper>
      <Styled.Subscribe ref={refs.follow}>
        <br />
        <h2>Get In Touch</h2>
        <h6>
          At the moment RocketSLO crew is working hard on private BETA that is planned to be
          released in early 2025.
        </h6>
        <Styled.NotifyMeButton appearance={Appearance.Funky} to={externalLinks.mailchimpUrl}>
          Notify me of the launch
        </Styled.NotifyMeButton>
        <Styled.AstronautImageWrapper>
          <Styled.AstronautImage />
        </Styled.AstronautImageWrapper>
        <SocialButtons />
        <CopyEmail>
          <Icon.EmailFilled />
        </CopyEmail>
        <Styled.ScrollToTopWrapper>
          <Tooltip content="Scroll to top" positionX={PositionX.Left}>
            <ScrollIntoView scrollToTop={true}>
              <Button appearance={Appearance.Icon}>
                <Icon.Chevron />
              </Button>
            </ScrollIntoView>
          </Tooltip>
        </Styled.ScrollToTopWrapper>
      </Styled.Subscribe>
    </LandingLayout>
  );
};
