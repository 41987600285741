import styled from 'styled-components';

export const DropdownRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Key = styled.p`
  font-weight: bold;
  p {
    font-size: 0.75rem;
    color: ${props => props.theme.palettes.placeholder};
  }
`;

export const Description = styled.p`
  font-size: 0.75rem;
  color: ${props => props.theme.palettes.placeholder};
`;
