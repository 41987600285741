import { all, takeLatest, select, put, AllEffect, call } from 'redux-saga/effects';
import { ListEventsByComponentRequest } from '../../grpc/grpcweb/xevent_pb';
import { PageRequest } from '../../grpc/grpccommon/common_pb';
import services from '../../services';
import { SequenceType, request, ActionType, AlertType } from '../../definitions';
import actionTypes from '../constants/actionTypes';
import {
  getAccessToken,
  getFetchXEventListError,
  getFetchXEventListNextPageError,
  getXEventListCurrPageToken,
  getXEventListNextPageToken,
} from '../selectors';
import { showAlert } from '../actions';
import { RootState } from '../reducers';
import { X_EVENT_LIST_PAGE_SIZE } from '../constants/pageSize';

function* fetchList(
  action: ActionType,
  successAction: string,
  errorGetter: (state: RootState) => string,
  pageToken?: string,
) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch xEvent list.',
      }),
    );
  }

  const { pageSize, componentId } = action.payload;

  const fetchXEventsRequest = new ListEventsByComponentRequest();
  fetchXEventsRequest.setComponentid(componentId);

  const pageData = new PageRequest();
  pageData.setPagesize(pageSize || X_EVENT_LIST_PAGE_SIZE);
  pageToken && pageData.setPagetoken(pageToken);
  fetchXEventsRequest.setPagerequest(pageData);

  const { sequence } = yield request(
    { rootAction: action, type: successAction },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ xEventService }) =>
          xEventService
            .fetchListByPage(token, fetchXEventsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(errorGetter);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchFirstPage(action: ActionType) {
  const currPageToken = yield select(getXEventListCurrPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.xEvents.fetchedList,
    getFetchXEventListError,
    currPageToken,
  );
}

function* fetchListNextPage(action: ActionType) {
  const nextPageToken = yield select(getXEventListNextPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.xEvents.fetchedNextPage,
    getFetchXEventListNextPageError,
    nextPageToken,
  );
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.xEvents.fetchList, fetchFirstPage)]);
  yield all([takeLatest(actionTypes.xEvents.fetchNextPage, fetchListNextPage)]);
}
