import styled, { css } from 'styled-components';
import Button from '../../Button';
import { StatusMessage } from '../StatusMessage';

type ContainerProps = {
  hasLabel?: boolean;
  invalid?: boolean;
  valid?: boolean;
  active?: boolean;
  dirty?: boolean;
  hasValue?: boolean;
  touched?: boolean;
  readOnly?: boolean;
  showRedBorderOnUnfocus: boolean;
  hasPrefix?: boolean;
  hasIcon?: boolean;
};

type ToggleTypeProps = {
  isActive: boolean;
};

type LabelProps = {
  color?: string;
};

type InputProps = {
  pristine?: boolean;
  active?: boolean;
  maxWidth?: string;
};

export const Label = styled.label<LabelProps>`
  font-weight: 500;
  color: ${({ theme }) => theme.palettes.main};
  font-size: 0.875rem;
  line-height: 1.75rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const Field = styled.input<InputProps>`
  padding: 0.5rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.palettes.border};
  color: ${({ theme }) => theme.palettes.main};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.effects.inputShadow};
  font-size: 0.875rem;
  line-height: 1.5rem;
  position: relative;
  width: 100%;
  max-width: ${props => props.maxWidth || 'auto'};

  &:focus {
    outline: 0;
    border-color: ${props => props.theme.palettes.mutedMain};
  }

  ::-webkit-calendar-picker-indicator {
    opacity: 0.5;
    cursor: pointer;
  }

  ::-webkit-datetime-edit {
    ${props =>
      props.pristine &&
      !props.active &&
      !props.value &&
      css`
        color: ${({ theme }) => theme.palettes.placeholder};
      `}
  }

  ::placeholder {
    color: ${({ theme }) => theme.palettes.placeholder};
  }

  &:not(:placeholder-shown) + ${Label}, &:-webkit-autofill + ${Label}, &:focus + ${Label} {
    color: ${({ theme }) => theme.palettes.secondaryHighlight};
    opacity: 1;
  }
`;

export const Optional = styled.span`
  font-weight: 300;
  font-size: 0.75rem;
  margin-left: 0.5rem;
`;

export const Message = styled(StatusMessage)`
  width: auto;
  margin-left: 0.5rem;
`;

const floatRightElementStyle = css`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palettes.placeholder};
`;

const floatLeftElementStyle = css`
  ${floatRightElementStyle};
  right: auto;
  left: 0;
`;

export const Toggle = styled(Button)<ToggleTypeProps>`
  ${floatRightElementStyle};
  margin: 0.375rem;
  ${props =>
    props.isActive &&
    css`
      svg {
        fill: ${({ theme }) => theme.palettes.main};
      }
    `}
`;

export const Postfix = styled.span`
  ${floatRightElementStyle};
  margin: 0.5625rem 0.75rem;
`;

export const Prefix = styled.span`
  ${floatLeftElementStyle};
  margin: 0.5625rem 0.75rem;
`;

export const InputIcon = styled.div`
  ${floatLeftElementStyle};
  margin: 0.6875rem 0.75rem;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  ${props =>
    (props.hasPrefix || props.hasIcon) &&
    css`
      ${Field} {
        padding-left: 2.5rem;
      }
    `}

  ${props =>
    props.invalid &&
    props.touched &&
    props.showRedBorderOnUnfocus &&
    css`
      ${Field} {
        border-color: ${props.theme.palettes.error};
      }
    `}

  ${props =>
    props.valid &&
    props.hasValue &&
    !props.readOnly &&
    css`
      ${Field} {
        border-color: ${props.theme.palettes.mutedMain};
      }
    `}

    ${props =>
    props.active &&
    css`
      ${Field} {
        border-color: ${props.theme.palettes.secondaryHighlight};
      }
    `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  text-align: left;
`;

export const Description = styled.p`
  color: ${props => props.theme.palettes.mutedMain};
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  align-content: flex-end;
`;

export const MessageWrapper = styled.div`
  height: 2rem;
`;

export const FloatWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;
