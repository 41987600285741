import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteChildrenProps, generatePath } from 'react-router';
import { usePageTitle } from '../../hooks';
import {
  fetchTagList,
  getAllTags,
  isFetchingTagList,
  isFetchingTagListNextPage,
  isFetchingTagListPrevPage,
  getTotalNumberOfTags,
  getTagListPrevPageToken,
  getTagListNextPageToken,
  fetchNextTagListPage,
  fetchPrevTagListPage,
  saveSelectedTag,
} from '../../store';
import { paths, Tag } from '../../definitions';
import { TagListView } from './TagList.view';

type TagListContainerProps = {
  isCreateDrawerOpen?: boolean;
  isEditDrawerOpen?: boolean;
} & RouteChildrenProps;

export const TagListContainer: FunctionComponent<TagListContainerProps> = ({
  isCreateDrawerOpen,
  isEditDrawerOpen,
  ...rest
}) => {
  usePageTitle('Tags');
  const dispatch = useDispatch();

  const isFetchingTags = useSelector(isFetchingTagList);
  const isFetchingNextPage = useSelector(isFetchingTagListNextPage);
  const isFetchingPrevPage = useSelector(isFetchingTagListPrevPage);

  const tagList = useSelector(getAllTags);
  const numberOfTags = useSelector(getTotalNumberOfTags);
  const prevPageToken = useSelector(getTagListPrevPageToken);
  const nextPageToken = useSelector(getTagListNextPageToken);

  const [isCreateDrawerVisible, setIsCreateDrawerVisible] = useState(false);
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);

  useEffect(() => {
    if (isCreateDrawerOpen) {
      setIsCreateDrawerVisible(true);
    } else {
      setIsCreateDrawerVisible(false);
    }
  }, [isCreateDrawerOpen]);

  useEffect(() => {
    if (isEditDrawerOpen) {
      setIsEditDrawerVisible(true);
    } else {
      setIsEditDrawerVisible(false);
    }
  }, [isEditDrawerOpen]);

  const handleEditTag = useCallback(
    (tag: Tag) => {
      dispatch(saveSelectedTag(tag));
      tag.tagKey && dispatch(push(generatePath(paths.editTag, { tagKey: tag.tagKey })));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchTagList());
  }, [dispatch]);

  const handleFetchNextPage = useCallback(() => {
    dispatch(fetchNextTagListPage());
  }, [dispatch]);

  const handleFetchPrevPage = useCallback(() => {
    dispatch(fetchPrevTagListPage());
  }, [dispatch]);

  const handleOpenCreateTag = useCallback(() => {
    dispatch(push(paths.createTag));
  }, [dispatch]);

  const handleBackToTagList = useCallback(() => {
    dispatch(push(paths.tags));
  }, [dispatch]);

  const handleOpenEditTag = useCallback(
    (tagKey: string) => {
      dispatch(push(generatePath(paths.editTag, { tagKey })));
    },
    [dispatch],
  );

  return (
    <TagListView
      tagList={tagList}
      isFetchingTags={isFetchingTags || isFetchingNextPage || isFetchingPrevPage}
      onNextPage={handleFetchNextPage}
      onPrevPage={handleFetchPrevPage}
      numberOfTags={numberOfTags}
      isCreateDrawerVisible={isCreateDrawerVisible}
      onOpenCreateTag={handleOpenCreateTag}
      isEditDrawerVisible={isEditDrawerVisible}
      onOpenEditTag={handleOpenEditTag}
      onBackToTagList={handleBackToTagList}
      isPrevPageDisabled={!prevPageToken}
      isNextPageDisabled={!nextPageToken}
      onEditTag={handleEditTag}
      {...rest}
    />
  );
};
