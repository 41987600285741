import { FunctionComponent, ElementType, forwardRef, Ref, useContext } from 'react';
import { MediaContext } from '../../../contexts';
import * as Styled from '../Sidebar.styles';

export type ButtonItemProps = {
  icon?: ElementType;
  path?: string;
  title?: string;
  onItemClick: () => void;
  isPopupTrigger?: boolean;
};

export const ButtonItem: FunctionComponent<ButtonItemProps> = forwardRef(
  (
    { icon, path = undefined, title, onItemClick, children, isPopupTrigger = false, ...rest },
    ref: Ref<HTMLDivElement>,
  ) => {
    const MenuIcon = icon;
    const { isMediumLayout, isLargeLayout } = useContext(MediaContext);

    return (
      <Styled.ButtonItem onClick={onItemClick} ref={ref} isPopupTrigger={isPopupTrigger} {...rest}>
        {MenuIcon && <MenuIcon />}
        {(!isMediumLayout || isLargeLayout) && <Styled.Text>{title}</Styled.Text>}
        {children}
      </Styled.ButtonItem>
    );
  },
);
