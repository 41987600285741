import styled from 'styled-components';
import { ButtonGroup } from '../ButtonGroup';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palettes.main};

  ${({ theme }) => theme.media.medium`
    align-items: center;
    background-color: ${theme.palettes.inverse};
  `}
`;

export const Wrapper = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.palettes.content};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 4rem 0 2rem 0;

  ${ButtonGroup} {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.media.medium`
    max-width: 50rem;
    padding: 4rem 8rem;
  `}
`;

export const Title = styled.h6`
  font-weight: 400;
  text-align: center;
  margin: 0 0 2rem;
  font-size: 1rem;
  max-width: 100vw;
  word-wrap: break-word;
  padding: 0 0.5rem;
`;

export const Description = styled.p`
  font-weight: 200;
  line-height: 1.45;
  text-align: center;
  flex: 1 0 auto;
  margin: 0 0 2rem;
  padding: 0 0.5rem;
`;
