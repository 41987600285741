import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { rgba, lighten, darken } from 'polished';
import { AlertType } from '../../definitions';

export const Container = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndexes.alert};
  width: 0 auto;
  bottom: 1.5rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  align-items: flex-end;
  min-height: 2rem;
  align-items: center;

  ${props => props.theme.media.medium`
      align-items: flex-end;
      left: 1.5rem;
      right: 1.5rem;
    `};
`;

export const Message = styled(animated.div)`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  width: 100%;

  ${props =>
    props.theme.media.medium`
      width: 40ch;
    `};
`;

export const Content = styled.div<{ type: AlertType }>`
  color: ${props => props.theme.palettes.inverse};
  background: ${props => rgba(props.theme.palettes.main, 0.75)};
  padding: 0.75rem 1rem 1.25rem 1rem;
  font-size: 1em;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  overflow: hidden;
  height: auto;
  border-radius: 0.15rem;
  margin-top: 0.25rem;
`;

export const Button = styled.button`
  cursor: pointer;
  pointer-events: all;
  outline: 0;
  border: none;
  background: transparent;
  display: flex;
  align-self: flex-end;
  overflow: hidden;
  margin: 0;
  padding: 0;
  margin-bottom: 0.75rem;
  svg {
    width: 1.25rem;
    height: auto;
    fill: ${props => props.theme.palettes.placeholder};
    :hover {
      fill: ${props => lighten(0.2, props.theme.palettes.placeholder)};
    }
  }
`;

export const Life = styled(animated.div)<{ type: AlertType }>`
  position: absolute;
  bottom: 0;
  left: 0px;
  width: auto;
  background-image: linear-gradient(130deg, #00b4e6, #00f0e0);
  height: 0.5rem;

  ${props =>
    props.type === AlertType.Warning &&
    css`
      background-image: linear-gradient(
        130deg,
        ${props.theme.palettes.highlight},
        ${darken(0.15, props.theme.palettes.highlight)}
      );
    `};
  ${props =>
    props.type === AlertType.Danger &&
    css`
      background-image: linear-gradient(
        130deg,
        ${props.theme.palettes.error},
        ${darken(0.15, props.theme.palettes.error)}
      );
    `};

  ${props =>
    props.type === AlertType.Success &&
    css`
      background-image: linear-gradient(
        130deg,
        ${props.theme.palettes.success},
        ${darken(0.15, props.theme.palettes.success)}
      );
    `};
`;
