import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteChildrenProps } from 'react-router';
import {
  getTeamMap,
  fetchComponentTypes,
  getComponentTypes,
  isFetchingComponentTypes,
  getAllTagForSearch,
  isFetchingAllTagsForSearch,
  fetchAllTagsForSearch,
} from '../../../store';
import {
  paths,
  Filters,
  getFilterQueryString,
  MappedTreeSelectOptions,
  buildQuery,
} from '../../../definitions';
import { ComponentListFiltersView } from './Filters.view';

type ComponentListFiltersContainerProps = {
  isFetchingComponentList: boolean;
  filterByType: string[] | undefined;
  filterByTeam: string[] | undefined;
  filterByTag: { keyList: string[] | undefined; valueList: string[] | undefined };
  onClearAllFilters: () => void;
  typeFilterQueryString: string;
  teamFilterQueryString: string;
  tagFilterQueryString: string;
} & RouteChildrenProps;

export const ComponentListFiltersContainer: FunctionComponent<ComponentListFiltersContainerProps> =
  ({
    isFetchingComponentList,
    filterByType,
    filterByTeam,
    filterByTag,
    typeFilterQueryString,
    teamFilterQueryString,
    tagFilterQueryString,
    onClearAllFilters,
    ...rest
  }) => {
    const dispatch = useDispatch();

    const teamMap = useSelector(getTeamMap);

    const componentTypes = useSelector(getComponentTypes);
    const isFetchingTypes = useSelector(isFetchingComponentTypes);

    const tagMap = useSelector(getAllTagForSearch);
    const isFetchingTags = useSelector(isFetchingAllTagsForSearch);

    const handleSetFilterByTeam = useCallback(
      (teamIds: string[]) => {
        const teamIdParams = getFilterQueryString(Filters.Team, teamIds);
        dispatch(
          push(
            `${paths.components}${buildQuery([
              tagFilterQueryString,
              teamIdParams,
              typeFilterQueryString,
            ])}`,
          ),
        );
      },
      [dispatch, typeFilterQueryString, tagFilterQueryString],
    );

    const handleSetFilterByType = useCallback(
      (typeIds: string[]) => {
        const typeIdParams = getFilterQueryString(Filters.Type, typeIds);
        dispatch(
          push(
            `${paths.components}${buildQuery([
              tagFilterQueryString,
              teamFilterQueryString,
              typeIdParams,
            ])}`,
          ),
        );
      },
      [dispatch, teamFilterQueryString, tagFilterQueryString],
    );

    const handleSetFilterByTag = useCallback(
      (mappedOptions: MappedTreeSelectOptions) => {
        const { keyIds, valueIds } = mappedOptions;
        const tagParams = Boolean(keyIds.length)
          ? `${getFilterQueryString(Filters.TagKey, keyIds)}&${getFilterQueryString(
              Filters.TagValue,
              valueIds,
            )}`
          : '';
        dispatch(
          push(
            `${paths.components}${buildQuery([
              tagParams,
              teamFilterQueryString,
              typeFilterQueryString,
            ])}`,
          ),
        );
      },
      [dispatch, teamFilterQueryString, typeFilterQueryString],
    );

    const numberOfActiveFilters = useMemo(() => {
      let numberOfFilters = 0;
      if (filterByType && filterByType.length) {
        numberOfFilters++;
      }
      if (filterByTeam && filterByTeam.length) {
        numberOfFilters++;
      }
      if (Boolean(filterByTag.keyList && filterByTag.keyList.length)) {
        numberOfFilters++;
      }
      return numberOfFilters;
    }, [filterByTeam, filterByType, filterByTag]);

    useEffect(() => {
      dispatch(fetchComponentTypes());
      dispatch(fetchAllTagsForSearch());
    }, [dispatch]);

    return (
      <ComponentListFiltersView
        isFetchingComponentList={isFetchingComponentList}
        onSetFilterByTeam={handleSetFilterByTeam}
        onSetFilterByType={handleSetFilterByType}
        onClearAllFilters={onClearAllFilters}
        componentTypes={componentTypes}
        selectedTypes={filterByType}
        selectedTeams={filterByTeam}
        selectedTags={filterByTag}
        numberOfActiveFilters={numberOfActiveFilters}
        isFetchingTypes={isFetchingTypes}
        isFetchingTags={isFetchingTags}
        teamMap={teamMap}
        tagMap={tagMap}
        onSetFilterByTag={handleSetFilterByTag}
        {...rest}
      />
    );
  };
