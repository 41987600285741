import React, { Children, cloneElement, FunctionComponent } from 'react';
import * as Styled from './Bounds.styles';

export type BoundsProps = {
  gutter?: number;
};

export const BoundsView: FunctionComponent<BoundsProps> = ({ children, gutter = 1, ...rest }) => (
  <Styled.Bounds gutter={gutter} {...rest}>
    {Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }

      return cloneElement(child, { gutter });
    })}
  </Styled.Bounds>
);
