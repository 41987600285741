const snowflake = '#FCFCFC';
const space = '#001130';
const gold = '#F8B432';
const aubergine = '#35205e';
const frost = `#f5f7f9`;
const silver = `#afbbc9`;
const strawberry = `#f72b3e`;
const elsa = `#3f9bc2`;
const lawn = `#3fc255`;
const elephant = `#929ca8`;
const mrgray = '#666d75';
const blend = '#164060';

export const colors: { [key: string]: string } = {
  snowflake,
  space,
  blend,
  elephant,
  gold,
  aubergine,
  mrgray,
  frost,
  silver,
  strawberry,
  elsa,
  lawn,
};
