import { useState, useEffect, MutableRefObject, useCallback } from 'react';

export const useScrollWithShadow = (
  scrollRef: MutableRefObject<any>,
  containerRef: MutableRefObject<any>,
) => {
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isBorderBottom, setIsBorderBottom] = useState(false);
  const [isBorderTop, setIsBorderTop] = useState(false);

  useEffect(() => {
    scrollRef?.current?.clientHeight && setScrollHeight(scrollRef.current.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef?.current, scrollRef?.current?.clientHeight]);

  useEffect(() => {
    scrollRef?.current?.clientHeight &&
      containerRef?.current?.clientHeight &&
      setIsScrollable(scrollRef.current.clientHeight > containerRef.current.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef?.current, containerRef?.current, scrollRef?.current?.clientHeight]);

  useEffect(() => {
    if (!isScrollable) {
      setIsBorderTop(false);
      setIsBorderBottom(false);
    } else {
      setIsBorderBottom(true);
    }
  }, [isScrollable]);

  const onScrollHandler = useCallback(
    (event: any) => {
      if (!isScrollable) {
        return;
      }

      const isBottom = event.target?.clientHeight < scrollHeight - event.target?.scrollTop;
      const isTop = event.target?.scrollTop !== 0;
      setIsBorderTop(isTop);
      setIsBorderBottom(isBottom);
    },
    [scrollHeight, isScrollable],
  );

  return { isBorderBottom, isBorderTop, onScrollHandler };
};
