import { useState, useEffect, useCallback } from 'react';
import { breakpoints } from '../definitions';

/**
 * Switches a value based on the current breakpoint. Updates on page resize.
 * @param {Object} values - Key is a breakpoint defined by constants, value is your desired result for that breakpoint.
 * @param {*} defaultValue - If nothing matches, this value is returned
 * @returns {*} - Value matched against breakpoint
 */
export function useMedia<T>(values: { [k: string]: T }, defaultValue: T): T {
  const mediaQueryLists = Object.keys(values).map(size =>
    window.matchMedia(`(min-width: ${breakpoints[size]}px)`),
  );

  const getValue = useCallback(() => {
    const match = mediaQueryLists.find(mql => mql.matches);

    if (!match) {
      return defaultValue;
    }

    const breakpoint = Object.keys(breakpoints).find(
      key => `(min-width: ${breakpoints[key]}px)` === match.media,
    );

    if (breakpoint && typeof values[breakpoint] !== 'undefined') {
      return values[breakpoint];
    }

    return defaultValue;
  }, [defaultValue, mediaQueryLists, values]);

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);

    mediaQueryLists.forEach(mql => mql.addListener(handler));

    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, [mediaQueryLists, getValue]);

  return value;
}
