import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isCreatingBotToken,
  getCreatedBotToken,
  isDeletingBotToken,
  deleteBotToken,
  getSelectedBotId,
  getCreatedTokenId,
  deletedBotTokenSuccessfully,
} from '../../store';
import { CreateTokenView } from './CreateToken.view';

type CreateTokenContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateTokenContainer: FunctionComponent<CreateTokenContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const isCreating = useSelector(isCreatingBotToken);
  const createdToken = useSelector(getCreatedBotToken);
  const isDeletingToken = useSelector(isDeletingBotToken);
  const botId = useSelector(getSelectedBotId);
  const tokenId = useSelector(getCreatedTokenId);

  const succesfullyDeletedToken = useSelector(deletedBotTokenSuccessfully);

  const handleDeleteToken = useCallback(() => {
    if (tokenId && botId) {
      dispatch(deleteBotToken({ tokenId, botId }));
    }
  }, [dispatch, botId, tokenId]);

  useEffect(() => {
    if (succesfullyDeletedToken) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succesfullyDeletedToken]);

  return (
    <CreateTokenView
      isOpen={isOpen}
      onClose={onClose}
      isCreating={isCreating}
      token={createdToken}
      isDeletingToken={isDeletingToken}
      onDeleteToken={handleDeleteToken}
    />
  );
};
