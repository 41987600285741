import { grpc } from '@improbable-eng/grpc-web';
import { TenantService as TenantServiceGRPC } from '../grpc/grpcweb/tenant_pb_service';
import { GetTenantIdRequest, GetTenantIdResponse } from '../grpc/grpcweb/tenant_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class TenantService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  getTenantId = async (tenantName: string): Promise<string> => {
    const request = new GetTenantIdRequest();
    request.setTenantname(tenantName);
    return new Promise((resolve, reject) => {
      grpc.unary(TenantServiceGRPC.GetTenantId, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata(),
        onEnd: (out: grpc.UnaryOutput<GetTenantIdResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject().tenantid);
          } else {
            reject(out.statusMessage);
            console.log(
              'get tenant id failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
