import { FunctionComponent, useState } from 'react';
import { Layout } from '../../components/Layout';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { Appearance, FlattenedTeamBatch, theme } from '../../definitions';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import CreateTeamModal from '../../modals/CreateTeam';
import UpdateTeamModal from '../../modals/UpdateTeam';
import TeamList from '../../lists/TeamList';
import * as Styled from './Teams.styles';

type TeamsViewProps = {
  list: FlattenedTeamBatch[];
  isFetchingList: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfTeams: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onViewTeam: (teamShortname: string) => void;
  onFetchTeam: (teamId: string) => void;
};

export const TeamsView: FunctionComponent<TeamsViewProps> = ({
  list,
  isFetchingList,
  numberOfTeams,
  onNextPage,
  onPrevPage,
  isPrevPageDisabled,
  isNextPageDisabled,
  onViewTeam,
  onFetchTeam,
}) => {
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [isUpdateTeamModalOpen, setIsUpdateTeamModalOpen] = useState(false);

  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>Teams</Layout.HeaderTitle>
      <Layout.HeaderActions>
        <Button
          onClick={() => setIsCreateTeamModalOpen(true)}
          appearance={Appearance.PrimaryWithIconCollapsible}
          aria-label="Create new team"
          title="Create new team"
        >
          <Icon.Plus />
          <span>Create new team</span>
        </Button>
      </Layout.HeaderActions>
    </Layout.Header>
  );

  const onFetchSelectedTeam = (teamId: string) => {
    onFetchTeam(teamId);
    setIsUpdateTeamModalOpen(true);
  };

  const EditTeamButton: FunctionComponent<{ id: string }> = ({ id }) => (
    <Styled.ButtonWrapper>
      <Button
        appearance={Appearance.Link}
        onClick={() => onFetchSelectedTeam(id)}
        color={theme.palettes.secondaryHighlight}
      >
        Edit
      </Button>
    </Styled.ButtonWrapper>
  );

  return (
    <>
      <AuthenticatedLayout header={Header}>
        <Layout.SubHeading>
          The table below contains a list of all teams that belong to the organization.
        </Layout.SubHeading>
        <Layout.Section>
          <TeamList
            teamList={list}
            isFetching={isFetchingList}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            isPrevPageDisabled={isPrevPageDisabled}
            isNextPageDisabled={isNextPageDisabled}
            numberOfTeams={numberOfTeams}
            postAction={EditTeamButton}
            onViewTeam={onViewTeam}
          />
        </Layout.Section>

        <CreateTeamModal
          isOpen={isCreateTeamModalOpen}
          onClose={() => setIsCreateTeamModalOpen(false)}
        />
        <UpdateTeamModal
          isOpen={isUpdateTeamModalOpen}
          onClose={() => setIsUpdateTeamModalOpen(false)}
        />
      </AuthenticatedLayout>
    </>
  );
};
