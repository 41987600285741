import { FunctionComponent } from 'react';
import Button from '../../../components/Button';
import { Appearance, ComponentRefInTheList } from '../../../definitions';
import { Icon } from '../../../components/Icon';
import ComponentCard from '../../../components/ComponentCard';
import * as DependencyBlockStyles from '../DependencyBlock';

export const ViewComponentButton: FunctionComponent<{
  componentShortname?: string;
  title?: string;
  onViewComponent: (componentShortname: string) => void;
}> = ({ componentShortname, title, onViewComponent }) => {
  return (
    <Button
      onClick={() => componentShortname && onViewComponent(componentShortname)}
      appearance={Appearance.PrimaryWithIcon}
      key="view-component-button"
    >
      <Icon.Collapse />
      <span>{title || 'View component'}</span>
    </Button>
  );
};

export const OpenSelectComponentModalButton: FunctionComponent<{
  onSetSelectComponentModalOpen: (isOpen: boolean) => void;
}> = ({ onSetSelectComponentModalOpen }) => (
  <Button
    onClick={() => onSetSelectComponentModalOpen(true)}
    appearance={Appearance.PrimaryWithIcon}
    key="change-component-btn"
  >
    <Icon.Create />
    <span>Select different component</span>
  </Button>
);

type DependencyCardProps = {
  component: ComponentRefInTheList;
  onNameClick: (component: ComponentRefInTheList) => void;
  onSetSelectComponentModalOpen: (isOpen: boolean) => void;
  isDependencySelected: boolean;
  isDependantSelected: boolean;
  shortname?: string;
  onViewComponent: (componentShortname: string) => void;
  isSelected: boolean;
};

export const DependencyCard: FunctionComponent<DependencyCardProps> = ({
  component,
  onNameClick,
  onSetSelectComponentModalOpen,
  isDependantSelected,
  isDependencySelected,
  shortname,
  onViewComponent,
  isSelected,
}) => {
  return (
    <DependencyBlockStyles.ComponentCardWrapper
      isSelected={isSelected}
      isDependencySelected={isDependencySelected}
      isDependantSelected={isDependantSelected}
    >
      <ComponentCard
        nameClickTitle="Select component"
        component={component}
        onNameClick={() => onNameClick(component)}
        componentId={component.id}
        widgetActions={[
          <OpenSelectComponentModalButton
            onSetSelectComponentModalOpen={onSetSelectComponentModalOpen}
            key="set-component-btn"
          />,
          <ViewComponentButton
            componentShortname={shortname}
            onViewComponent={onViewComponent}
            key="view-component-btn"
          />,
        ]}
      />
    </DependencyBlockStyles.ComponentCardWrapper>
  );
};
