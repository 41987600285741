import { darken } from 'polished';
import styled from 'styled-components';

type AvatarWrapperProps = {
  isNameClickable: boolean;
  isSmall: boolean;
};

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  width: 100%;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    margin: 0;
    color: inherit;
    font-size: ${props => (props.isSmall ? '0.875rem' : '0.9375rem')};
    cursor: pointer;
    color: ${props =>
      props.isNameClickable ? props.theme.palettes.secondaryHighlight : props.theme.palettes.main};
    display: inline-block;
    &:hover {
      text-decoration: ${props => (props.isNameClickable ? 'underline' : 'none')};
      color: ${props =>
        props.isNameClickable
          ? darken(0.1, props.theme.palettes.secondaryHighlight)
          : props.theme.palettes.main};
    }
  }
`;
