import { FunctionComponent, ReactNode } from 'react';
import { FlattenedTeamBatch, AvatarType } from '../../definitions';
import List from '../../components/List';
import Pagination from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import AvatarWithTitle from '../../components/AvatarWithTitle';

export type TeamListProps = {
  teamList: FlattenedTeamBatch[];
  isFetching: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfTeams: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  preAction?: ReactNode;
  postAction?: ReactNode;
  preActionTitle?: ReactNode | string;
  postActionTitle?: ReactNode | string;
  onViewTeam?: (shortname: string) => void;
};

export const TeamListView: FunctionComponent<TeamListProps> = ({
  teamList,
  isFetching,
  numberOfTeams,
  onNextPage,
  onPrevPage,
  isPrevPageDisabled,
  isNextPageDisabled,
  preAction,
  preActionTitle,
  postAction,
  postActionTitle,
  onViewTeam,
}) => {
  const TeamAvatar: FunctionComponent<FlattenedTeamBatch> = ({
    avatarUrl,
    displayname,
    shortname,
  }) => {
    const handleViewTeam = () => shortname && onViewTeam && onViewTeam(shortname);
    return (
      <AvatarWithTitle
        name={displayname || shortname || ''}
        avatarType={AvatarType.Team}
        avatarSrc={avatarUrl}
        onNameClick={onViewTeam ? handleViewTeam : undefined}
      />
    );
  };

  const NumberOfUsers = (team: FlattenedTeamBatch) => <p>{team?.memberList?.length}</p>;

  const getColumnTitles = () => {
    let columns: Array<string | ReactNode> = ['Team', 'Member count'];
    if (preAction) {
      preActionTitle ? columns.unshift(preActionTitle) : columns.unshift('');
    }
    if (postAction) {
      postActionTitle ? columns.push(postActionTitle) : columns.push('');
    }
    return columns;
  };

  const getColumns = () => {
    let columns: Array<string | ReactNode> = [TeamAvatar, NumberOfUsers];
    if (preAction) {
      columns.unshift(preAction);
    }
    if (postAction) {
      columns.push(postAction);
    }
    return columns;
  };

  return (
    <Layout.Section>
      <List
        items={teamList}
        columnTitles={getColumnTitles()}
        columns={getColumns()}
        hideOnSmall={['fullname']}
        itemKey="id"
        isLoading={isFetching}
        emptyText="You don't have any teams yet. Start by creating one."
      />
      <Pagination totalItems={numberOfTeams}>
        <Pagination.NextPrev
          onNextClick={onNextPage}
          onPrevClick={onPrevPage}
          isPrevDisabled={isPrevPageDisabled}
          isNextDisabled={isNextPageDisabled}
        />
      </Pagination>
    </Layout.Section>
  );
};
