import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function usePageTitle(title: string): void {
  const location = useLocation();

  useEffect(() => {
    document.title = `Rocket SLO - ${title}`;
  }, [location.pathname, location.hash, title]);
}
