import actionTypes from '../constants/actionTypes';
import { isLoading } from './loading';
import { getErrorMessage, getErrorType } from './errors';
import { RootState } from '../reducers';

export const getAuthError = (state: RootState): string => {
  return (
    getErrorMessage(state, actionTypes.auth.check) ||
    getErrorMessage(state, actionTypes.auth.initialise) ||
    getErrorMessage(state, actionTypes.auth.initialiseWithDemo) ||
    getErrorMessage(state, actionTypes.auth.complete)
  );
};

export const getAuthErrorType = (state: RootState): string => {
  return (
    getErrorType(state, actionTypes.auth.check) ||
    getErrorType(state, actionTypes.auth.initialise) ||
    getErrorMessage(state, actionTypes.auth.initialiseWithDemo) ||
    getErrorType(state, actionTypes.auth.complete)
  );
};

export const isLoadingAuth = (state: RootState): boolean => {
  return (
    isLoading(state, actionTypes.auth.initialise) ||
    isLoading(state, actionTypes.auth.initialiseWithDemo) ||
    isLoading(state, actionTypes.auth.check) ||
    isLoading(state, actionTypes.auth.logout)
  );
};

export const isAuthenticated = (state: RootState): boolean => {
  return state.auth.isAuthenticated;
};

export const getAccessToken = (state: RootState): string => {
  return state.auth.accessToken;
};

export const getRedirectUri = (state: RootState): string => {
  return state.auth.redirectUri;
};
