import styled from 'styled-components';
import { Grid } from '../../components/Grid';

export const TableRow = styled(Grid.Bounds)`
  font-size: 0.875rem;
`;

export const SmallColumn = styled(Grid.Box)`
  max-width: 5rem;
`;

export const BigColumn = styled(Grid.Box)`
  max-width: 24rem;
`;
