import styled from 'styled-components';

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  a,
  button {
    text-align: left;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 1.5rem;
`;
