// source: grpcweb/eventsource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var grpccommon_common_pb = require('../grpccommon/common_pb.js');
goog.object.extend(proto, grpccommon_common_pb);
goog.exportSymbol('proto.rct.web.CloudWatchAssumeRoleSettings', null, global);
goog.exportSymbol('proto.rct.web.CloudWatchMetadata', null, global);
goog.exportSymbol('proto.rct.web.CloudWatchMetadata.AccessKeyMetadata', null, global);
goog.exportSymbol('proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata', null, global);
goog.exportSymbol('proto.rct.web.CloudWatchMetadata.AuthType', null, global);
goog.exportSymbol('proto.rct.web.CreateEventSourceRequest', null, global);
goog.exportSymbol('proto.rct.web.CreateEventSourceResponse', null, global);
goog.exportSymbol('proto.rct.web.DeleteEventSourceRequest', null, global);
goog.exportSymbol('proto.rct.web.DeleteEventSourceResponse', null, global);
goog.exportSymbol('proto.rct.web.Event', null, global);
goog.exportSymbol('proto.rct.web.EventQueryResult', null, global);
goog.exportSymbol('proto.rct.web.EventSourceMetadata', null, global);
goog.exportSymbol('proto.rct.web.EventSourceType', null, global);
goog.exportSymbol('proto.rct.web.EventSourceView', null, global);
goog.exportSymbol('proto.rct.web.GetEventSourceRequest', null, global);
goog.exportSymbol('proto.rct.web.GetEventSourceResponse', null, global);
goog.exportSymbol('proto.rct.web.ListEventSourceRequest', null, global);
goog.exportSymbol('proto.rct.web.ListEventSourceResponse', null, global);
goog.exportSymbol('proto.rct.web.PrometheusMetadata', null, global);
goog.exportSymbol('proto.rct.web.PrometheusMetadata.AuthType', null, global);
goog.exportSymbol('proto.rct.web.PrometheusMetadata.BasicAuthMetadata', null, global);
goog.exportSymbol('proto.rct.web.QueryTimePeriod', null, global);
goog.exportSymbol('proto.rct.web.UpdateEventSourceRequest', null, global);
goog.exportSymbol('proto.rct.web.UpdateEventSourceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.EventSourceMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.EventSourceMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.EventSourceMetadata.displayName = 'proto.rct.web.EventSourceMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.EventSourceView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.EventSourceView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.EventSourceView.displayName = 'proto.rct.web.EventSourceView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.EventQueryResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.EventQueryResult.repeatedFields_, null);
};
goog.inherits(proto.rct.web.EventQueryResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.EventQueryResult.displayName = 'proto.rct.web.EventQueryResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.QueryTimePeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.QueryTimePeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.QueryTimePeriod.displayName = 'proto.rct.web.QueryTimePeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Event.displayName = 'proto.rct.web.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CloudWatchMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CloudWatchMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CloudWatchMetadata.displayName = 'proto.rct.web.CloudWatchMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CloudWatchMetadata.AccessKeyMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.displayName = 'proto.rct.web.CloudWatchMetadata.AccessKeyMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.displayName = 'proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CloudWatchAssumeRoleSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CloudWatchAssumeRoleSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CloudWatchAssumeRoleSettings.displayName = 'proto.rct.web.CloudWatchAssumeRoleSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.PrometheusMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.PrometheusMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.PrometheusMetadata.displayName = 'proto.rct.web.PrometheusMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.PrometheusMetadata.BasicAuthMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.PrometheusMetadata.BasicAuthMetadata.displayName = 'proto.rct.web.PrometheusMetadata.BasicAuthMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateEventSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateEventSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateEventSourceRequest.displayName = 'proto.rct.web.CreateEventSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateEventSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateEventSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateEventSourceResponse.displayName = 'proto.rct.web.CreateEventSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListEventSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListEventSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListEventSourceRequest.displayName = 'proto.rct.web.ListEventSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListEventSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ListEventSourceResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ListEventSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListEventSourceResponse.displayName = 'proto.rct.web.ListEventSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetEventSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetEventSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetEventSourceRequest.displayName = 'proto.rct.web.GetEventSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetEventSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetEventSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetEventSourceResponse.displayName = 'proto.rct.web.GetEventSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteEventSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteEventSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteEventSourceRequest.displayName = 'proto.rct.web.DeleteEventSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteEventSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteEventSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteEventSourceResponse.displayName = 'proto.rct.web.DeleteEventSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateEventSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateEventSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateEventSourceRequest.displayName = 'proto.rct.web.UpdateEventSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateEventSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateEventSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateEventSourceResponse.displayName = 'proto.rct.web.UpdateEventSourceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.EventSourceMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.EventSourceMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.EventSourceMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventSourceMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && grpccommon_common_pb.TextItem.toObject(includeInstance, f),
    cloudwatchmetadata: (f = msg.getCloudwatchmetadata()) && proto.rct.web.CloudWatchMetadata.toObject(includeInstance, f),
    cloudwatchassumerolesettings: (f = msg.getCloudwatchassumerolesettings()) && proto.rct.web.CloudWatchAssumeRoleSettings.toObject(includeInstance, f),
    prometheusmetadata: (f = msg.getPrometheusmetadata()) && proto.rct.web.PrometheusMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.EventSourceMetadata}
 */
proto.rct.web.EventSourceMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.EventSourceMetadata;
  return proto.rct.web.EventSourceMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.EventSourceMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.EventSourceMetadata}
 */
proto.rct.web.EventSourceMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rct.web.EventSourceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = new grpccommon_common_pb.TextItem;
      reader.readMessage(value,grpccommon_common_pb.TextItem.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.rct.web.CloudWatchMetadata;
      reader.readMessage(value,proto.rct.web.CloudWatchMetadata.deserializeBinaryFromReader);
      msg.setCloudwatchmetadata(value);
      break;
    case 5:
      var value = new proto.rct.web.CloudWatchAssumeRoleSettings;
      reader.readMessage(value,proto.rct.web.CloudWatchAssumeRoleSettings.deserializeBinaryFromReader);
      msg.setCloudwatchassumerolesettings(value);
      break;
    case 6:
      var value = new proto.rct.web.PrometheusMetadata;
      reader.readMessage(value,proto.rct.web.PrometheusMetadata.deserializeBinaryFromReader);
      msg.setPrometheusmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.EventSourceMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.EventSourceMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.EventSourceMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventSourceMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpccommon_common_pb.TextItem.serializeBinaryToWriter
    );
  }
  f = message.getCloudwatchmetadata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.rct.web.CloudWatchMetadata.serializeBinaryToWriter
    );
  }
  f = message.getCloudwatchassumerolesettings();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.rct.web.CloudWatchAssumeRoleSettings.serializeBinaryToWriter
    );
  }
  f = message.getPrometheusmetadata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.rct.web.PrometheusMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventSourceType type = 1;
 * @return {!proto.rct.web.EventSourceType}
 */
proto.rct.web.EventSourceMetadata.prototype.getType = function() {
  return /** @type {!proto.rct.web.EventSourceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rct.web.EventSourceType} value
 * @return {!proto.rct.web.EventSourceMetadata} returns this
 */
proto.rct.web.EventSourceMetadata.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.rct.web.EventSourceMetadata.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.EventSourceMetadata} returns this
 */
proto.rct.web.EventSourceMetadata.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional rct.common.TextItem description = 3;
 * @return {?proto.rct.common.TextItem}
 */
proto.rct.web.EventSourceMetadata.prototype.getDescription = function() {
  return /** @type{?proto.rct.common.TextItem} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.TextItem, 3));
};


/**
 * @param {?proto.rct.common.TextItem|undefined} value
 * @return {!proto.rct.web.EventSourceMetadata} returns this
*/
proto.rct.web.EventSourceMetadata.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.EventSourceMetadata} returns this
 */
proto.rct.web.EventSourceMetadata.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.EventSourceMetadata.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CloudWatchMetadata cloudWatchMetadata = 4;
 * @return {?proto.rct.web.CloudWatchMetadata}
 */
proto.rct.web.EventSourceMetadata.prototype.getCloudwatchmetadata = function() {
  return /** @type{?proto.rct.web.CloudWatchMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CloudWatchMetadata, 4));
};


/**
 * @param {?proto.rct.web.CloudWatchMetadata|undefined} value
 * @return {!proto.rct.web.EventSourceMetadata} returns this
*/
proto.rct.web.EventSourceMetadata.prototype.setCloudwatchmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.EventSourceMetadata} returns this
 */
proto.rct.web.EventSourceMetadata.prototype.clearCloudwatchmetadata = function() {
  return this.setCloudwatchmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.EventSourceMetadata.prototype.hasCloudwatchmetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CloudWatchAssumeRoleSettings cloudWatchAssumeRoleSettings = 5;
 * @return {?proto.rct.web.CloudWatchAssumeRoleSettings}
 */
proto.rct.web.EventSourceMetadata.prototype.getCloudwatchassumerolesettings = function() {
  return /** @type{?proto.rct.web.CloudWatchAssumeRoleSettings} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CloudWatchAssumeRoleSettings, 5));
};


/**
 * @param {?proto.rct.web.CloudWatchAssumeRoleSettings|undefined} value
 * @return {!proto.rct.web.EventSourceMetadata} returns this
*/
proto.rct.web.EventSourceMetadata.prototype.setCloudwatchassumerolesettings = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.EventSourceMetadata} returns this
 */
proto.rct.web.EventSourceMetadata.prototype.clearCloudwatchassumerolesettings = function() {
  return this.setCloudwatchassumerolesettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.EventSourceMetadata.prototype.hasCloudwatchassumerolesettings = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PrometheusMetadata prometheusMetadata = 6;
 * @return {?proto.rct.web.PrometheusMetadata}
 */
proto.rct.web.EventSourceMetadata.prototype.getPrometheusmetadata = function() {
  return /** @type{?proto.rct.web.PrometheusMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.PrometheusMetadata, 6));
};


/**
 * @param {?proto.rct.web.PrometheusMetadata|undefined} value
 * @return {!proto.rct.web.EventSourceMetadata} returns this
*/
proto.rct.web.EventSourceMetadata.prototype.setPrometheusmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.EventSourceMetadata} returns this
 */
proto.rct.web.EventSourceMetadata.prototype.clearPrometheusmetadata = function() {
  return this.setPrometheusmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.EventSourceMetadata.prototype.hasPrometheusmetadata = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.EventSourceView.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.EventSourceView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.EventSourceView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventSourceView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.rct.web.EventSourceMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.EventSourceView}
 */
proto.rct.web.EventSourceView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.EventSourceView;
  return proto.rct.web.EventSourceView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.EventSourceView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.EventSourceView}
 */
proto.rct.web.EventSourceView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.EventSourceMetadata;
      reader.readMessage(value,proto.rct.web.EventSourceMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.EventSourceView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.EventSourceView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.EventSourceView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventSourceView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.EventSourceMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.EventSourceView.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.EventSourceView} returns this
 */
proto.rct.web.EventSourceView.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EventSourceMetadata metadata = 2;
 * @return {?proto.rct.web.EventSourceMetadata}
 */
proto.rct.web.EventSourceView.prototype.getMetadata = function() {
  return /** @type{?proto.rct.web.EventSourceMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventSourceMetadata, 2));
};


/**
 * @param {?proto.rct.web.EventSourceMetadata|undefined} value
 * @return {!proto.rct.web.EventSourceView} returns this
*/
proto.rct.web.EventSourceView.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.EventSourceView} returns this
 */
proto.rct.web.EventSourceView.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.EventSourceView.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.EventQueryResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.EventQueryResult.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.EventQueryResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.EventQueryResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventQueryResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.rct.web.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.EventQueryResult}
 */
proto.rct.web.EventQueryResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.EventQueryResult;
  return proto.rct.web.EventQueryResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.EventQueryResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.EventQueryResult}
 */
proto.rct.web.EventQueryResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Event;
      reader.readMessage(value,proto.rct.web.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.EventQueryResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.EventQueryResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.EventQueryResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.EventQueryResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.rct.web.Event>}
 */
proto.rct.web.EventQueryResult.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.rct.web.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.Event, 1));
};


/**
 * @param {!Array<!proto.rct.web.Event>} value
 * @return {!proto.rct.web.EventQueryResult} returns this
*/
proto.rct.web.EventQueryResult.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Event}
 */
proto.rct.web.EventQueryResult.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.EventQueryResult} returns this
 */
proto.rct.web.EventQueryResult.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.QueryTimePeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.QueryTimePeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.QueryTimePeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.QueryTimePeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    startms: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endms: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.QueryTimePeriod}
 */
proto.rct.web.QueryTimePeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.QueryTimePeriod;
  return proto.rct.web.QueryTimePeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.QueryTimePeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.QueryTimePeriod}
 */
proto.rct.web.QueryTimePeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartms(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.QueryTimePeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.QueryTimePeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.QueryTimePeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.QueryTimePeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartms();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getEndms();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 startMs = 1;
 * @return {number}
 */
proto.rct.web.QueryTimePeriod.prototype.getStartms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.QueryTimePeriod} returns this
 */
proto.rct.web.QueryTimePeriod.prototype.setStartms = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 endMs = 2;
 * @return {number}
 */
proto.rct.web.QueryTimePeriod.prototype.getEndms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.QueryTimePeriod} returns this
 */
proto.rct.web.QueryTimePeriod.prototype.setEndms = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    starttime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Event}
 */
proto.rct.web.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Event;
  return proto.rct.web.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Event}
 */
proto.rct.web.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStarttime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getStarttime();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 value = 1;
 * @return {number}
 */
proto.rct.web.Event.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Event} returns this
 */
proto.rct.web.Event.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 startTime = 2;
 * @return {number}
 */
proto.rct.web.Event.prototype.getStarttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Event} returns this
 */
proto.rct.web.Event.prototype.setStarttime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CloudWatchMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CloudWatchMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CloudWatchMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    authtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accesskeyauthmetadata: (f = msg.getAccesskeyauthmetadata()) && proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.toObject(includeInstance, f),
    assumerolemetadata: (f = msg.getAssumerolemetadata()) && proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CloudWatchMetadata}
 */
proto.rct.web.CloudWatchMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CloudWatchMetadata;
  return proto.rct.web.CloudWatchMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CloudWatchMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CloudWatchMetadata}
 */
proto.rct.web.CloudWatchMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rct.web.CloudWatchMetadata.AuthType} */ (reader.readEnum());
      msg.setAuthtype(value);
      break;
    case 2:
      var value = new proto.rct.web.CloudWatchMetadata.AccessKeyMetadata;
      reader.readMessage(value,proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.deserializeBinaryFromReader);
      msg.setAccesskeyauthmetadata(value);
      break;
    case 3:
      var value = new proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata;
      reader.readMessage(value,proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.deserializeBinaryFromReader);
      msg.setAssumerolemetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CloudWatchMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CloudWatchMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CloudWatchMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAccesskeyauthmetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.serializeBinaryToWriter
    );
  }
  f = message.getAssumerolemetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.rct.web.CloudWatchMetadata.AuthType = {
  AUTH_TYPE_UNSPECIFIED: 0,
  AUTH_TYPE_ACCESS_KEY: 1,
  AUTH_TYPE_ASSUME_ROLE: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    region: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accesskey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    secretaccesskey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata}
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CloudWatchMetadata.AccessKeyMetadata;
  return proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata}
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesskey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretaccesskey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccesskey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecretaccesskey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string region = 1;
 * @return {string}
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata} returns this
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accessKey = 2;
 * @return {string}
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.getAccesskey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata} returns this
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.setAccesskey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string secretAccessKey = 3;
 * @return {string}
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.getSecretaccesskey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CloudWatchMetadata.AccessKeyMetadata} returns this
 */
proto.rct.web.CloudWatchMetadata.AccessKeyMetadata.prototype.setSecretaccesskey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    arn: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata}
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata;
  return proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata}
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string arn = 1;
 * @return {string}
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.prototype.getArn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata} returns this
 */
proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata.prototype.setArn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AuthType authType = 1;
 * @return {!proto.rct.web.CloudWatchMetadata.AuthType}
 */
proto.rct.web.CloudWatchMetadata.prototype.getAuthtype = function() {
  return /** @type {!proto.rct.web.CloudWatchMetadata.AuthType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rct.web.CloudWatchMetadata.AuthType} value
 * @return {!proto.rct.web.CloudWatchMetadata} returns this
 */
proto.rct.web.CloudWatchMetadata.prototype.setAuthtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional AccessKeyMetadata accessKeyAuthMetadata = 2;
 * @return {?proto.rct.web.CloudWatchMetadata.AccessKeyMetadata}
 */
proto.rct.web.CloudWatchMetadata.prototype.getAccesskeyauthmetadata = function() {
  return /** @type{?proto.rct.web.CloudWatchMetadata.AccessKeyMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CloudWatchMetadata.AccessKeyMetadata, 2));
};


/**
 * @param {?proto.rct.web.CloudWatchMetadata.AccessKeyMetadata|undefined} value
 * @return {!proto.rct.web.CloudWatchMetadata} returns this
*/
proto.rct.web.CloudWatchMetadata.prototype.setAccesskeyauthmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CloudWatchMetadata} returns this
 */
proto.rct.web.CloudWatchMetadata.prototype.clearAccesskeyauthmetadata = function() {
  return this.setAccesskeyauthmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CloudWatchMetadata.prototype.hasAccesskeyauthmetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AssumeRoleMetadata assumeRoleMetadata = 3;
 * @return {?proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata}
 */
proto.rct.web.CloudWatchMetadata.prototype.getAssumerolemetadata = function() {
  return /** @type{?proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata, 3));
};


/**
 * @param {?proto.rct.web.CloudWatchMetadata.AssumeRoleMetadata|undefined} value
 * @return {!proto.rct.web.CloudWatchMetadata} returns this
*/
proto.rct.web.CloudWatchMetadata.prototype.setAssumerolemetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CloudWatchMetadata} returns this
 */
proto.rct.web.CloudWatchMetadata.prototype.clearAssumerolemetadata = function() {
  return this.setAssumerolemetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CloudWatchMetadata.prototype.hasAssumerolemetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CloudWatchAssumeRoleSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CloudWatchAssumeRoleSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CloudWatchAssumeRoleSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchAssumeRoleSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rocketsloawsaccountid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CloudWatchAssumeRoleSettings}
 */
proto.rct.web.CloudWatchAssumeRoleSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CloudWatchAssumeRoleSettings;
  return proto.rct.web.CloudWatchAssumeRoleSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CloudWatchAssumeRoleSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CloudWatchAssumeRoleSettings}
 */
proto.rct.web.CloudWatchAssumeRoleSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRocketsloawsaccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CloudWatchAssumeRoleSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CloudWatchAssumeRoleSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CloudWatchAssumeRoleSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CloudWatchAssumeRoleSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRocketsloawsaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string externalId = 1;
 * @return {string}
 */
proto.rct.web.CloudWatchAssumeRoleSettings.prototype.getExternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CloudWatchAssumeRoleSettings} returns this
 */
proto.rct.web.CloudWatchAssumeRoleSettings.prototype.setExternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rocketSloAwsAccountId = 2;
 * @return {string}
 */
proto.rct.web.CloudWatchAssumeRoleSettings.prototype.getRocketsloawsaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CloudWatchAssumeRoleSettings} returns this
 */
proto.rct.web.CloudWatchAssumeRoleSettings.prototype.setRocketsloawsaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.PrometheusMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.PrometheusMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.PrometheusMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.PrometheusMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    authtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    basicauthmetadata: (f = msg.getBasicauthmetadata()) && proto.rct.web.PrometheusMetadata.BasicAuthMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.PrometheusMetadata}
 */
proto.rct.web.PrometheusMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.PrometheusMetadata;
  return proto.rct.web.PrometheusMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.PrometheusMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.PrometheusMetadata}
 */
proto.rct.web.PrometheusMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rct.web.PrometheusMetadata.AuthType} */ (reader.readEnum());
      msg.setAuthtype(value);
      break;
    case 2:
      var value = new proto.rct.web.PrometheusMetadata.BasicAuthMetadata;
      reader.readMessage(value,proto.rct.web.PrometheusMetadata.BasicAuthMetadata.deserializeBinaryFromReader);
      msg.setBasicauthmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.PrometheusMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.PrometheusMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.PrometheusMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.PrometheusMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBasicauthmetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.PrometheusMetadata.BasicAuthMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.rct.web.PrometheusMetadata.AuthType = {
  AUTH_TYPE_UNSPECIFIED: 0,
  AUTH_TYPE_BASIC: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.PrometheusMetadata.BasicAuthMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pwd: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata}
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.PrometheusMetadata.BasicAuthMetadata;
  return proto.rct.web.PrometheusMetadata.BasicAuthMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata}
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPwd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.PrometheusMetadata.BasicAuthMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPwd();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata} returns this
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata} returns this
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pwd = 3;
 * @return {string}
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.getPwd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.PrometheusMetadata.BasicAuthMetadata} returns this
 */
proto.rct.web.PrometheusMetadata.BasicAuthMetadata.prototype.setPwd = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AuthType authType = 1;
 * @return {!proto.rct.web.PrometheusMetadata.AuthType}
 */
proto.rct.web.PrometheusMetadata.prototype.getAuthtype = function() {
  return /** @type {!proto.rct.web.PrometheusMetadata.AuthType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rct.web.PrometheusMetadata.AuthType} value
 * @return {!proto.rct.web.PrometheusMetadata} returns this
 */
proto.rct.web.PrometheusMetadata.prototype.setAuthtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional BasicAuthMetadata basicAuthMetadata = 2;
 * @return {?proto.rct.web.PrometheusMetadata.BasicAuthMetadata}
 */
proto.rct.web.PrometheusMetadata.prototype.getBasicauthmetadata = function() {
  return /** @type{?proto.rct.web.PrometheusMetadata.BasicAuthMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.PrometheusMetadata.BasicAuthMetadata, 2));
};


/**
 * @param {?proto.rct.web.PrometheusMetadata.BasicAuthMetadata|undefined} value
 * @return {!proto.rct.web.PrometheusMetadata} returns this
*/
proto.rct.web.PrometheusMetadata.prototype.setBasicauthmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.PrometheusMetadata} returns this
 */
proto.rct.web.PrometheusMetadata.prototype.clearBasicauthmetadata = function() {
  return this.setBasicauthmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.PrometheusMetadata.prototype.hasBasicauthmetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateEventSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateEventSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateEventSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateEventSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsource: (f = msg.getEventsource()) && proto.rct.web.EventSourceMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateEventSourceRequest}
 */
proto.rct.web.CreateEventSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateEventSourceRequest;
  return proto.rct.web.CreateEventSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateEventSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateEventSourceRequest}
 */
proto.rct.web.CreateEventSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.EventSourceMetadata;
      reader.readMessage(value,proto.rct.web.EventSourceMetadata.deserializeBinaryFromReader);
      msg.setEventsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateEventSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateEventSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateEventSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateEventSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.EventSourceMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventSourceMetadata eventSource = 1;
 * @return {?proto.rct.web.EventSourceMetadata}
 */
proto.rct.web.CreateEventSourceRequest.prototype.getEventsource = function() {
  return /** @type{?proto.rct.web.EventSourceMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventSourceMetadata, 1));
};


/**
 * @param {?proto.rct.web.EventSourceMetadata|undefined} value
 * @return {!proto.rct.web.CreateEventSourceRequest} returns this
*/
proto.rct.web.CreateEventSourceRequest.prototype.setEventsource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateEventSourceRequest} returns this
 */
proto.rct.web.CreateEventSourceRequest.prototype.clearEventsource = function() {
  return this.setEventsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateEventSourceRequest.prototype.hasEventsource = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateEventSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateEventSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateEventSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateEventSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsource: (f = msg.getEventsource()) && proto.rct.web.EventSourceView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateEventSourceResponse}
 */
proto.rct.web.CreateEventSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateEventSourceResponse;
  return proto.rct.web.CreateEventSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateEventSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateEventSourceResponse}
 */
proto.rct.web.CreateEventSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.EventSourceView;
      reader.readMessage(value,proto.rct.web.EventSourceView.deserializeBinaryFromReader);
      msg.setEventsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateEventSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateEventSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateEventSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateEventSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.EventSourceView.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventSourceView eventSource = 1;
 * @return {?proto.rct.web.EventSourceView}
 */
proto.rct.web.CreateEventSourceResponse.prototype.getEventsource = function() {
  return /** @type{?proto.rct.web.EventSourceView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventSourceView, 1));
};


/**
 * @param {?proto.rct.web.EventSourceView|undefined} value
 * @return {!proto.rct.web.CreateEventSourceResponse} returns this
*/
proto.rct.web.CreateEventSourceResponse.prototype.setEventsource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateEventSourceResponse} returns this
 */
proto.rct.web.CreateEventSourceResponse.prototype.clearEventsource = function() {
  return this.setEventsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateEventSourceResponse.prototype.hasEventsource = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListEventSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListEventSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListEventSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListEventSourceRequest}
 */
proto.rct.web.ListEventSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListEventSourceRequest;
  return proto.rct.web.ListEventSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListEventSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListEventSourceRequest}
 */
proto.rct.web.ListEventSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListEventSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListEventSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListEventSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ListEventSourceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListEventSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListEventSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListEventSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsourcesList: jspb.Message.toObjectList(msg.getEventsourcesList(),
    proto.rct.web.EventSourceView.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListEventSourceResponse}
 */
proto.rct.web.ListEventSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListEventSourceResponse;
  return proto.rct.web.ListEventSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListEventSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListEventSourceResponse}
 */
proto.rct.web.ListEventSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.EventSourceView;
      reader.readMessage(value,proto.rct.web.EventSourceView.deserializeBinaryFromReader);
      msg.addEventsources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListEventSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListEventSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListEventSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.EventSourceView.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventSourceView eventSources = 1;
 * @return {!Array<!proto.rct.web.EventSourceView>}
 */
proto.rct.web.ListEventSourceResponse.prototype.getEventsourcesList = function() {
  return /** @type{!Array<!proto.rct.web.EventSourceView>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.EventSourceView, 1));
};


/**
 * @param {!Array<!proto.rct.web.EventSourceView>} value
 * @return {!proto.rct.web.ListEventSourceResponse} returns this
*/
proto.rct.web.ListEventSourceResponse.prototype.setEventsourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.EventSourceView=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.EventSourceView}
 */
proto.rct.web.ListEventSourceResponse.prototype.addEventsources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.EventSourceView, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ListEventSourceResponse} returns this
 */
proto.rct.web.ListEventSourceResponse.prototype.clearEventsourcesList = function() {
  return this.setEventsourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetEventSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetEventSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetEventSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetEventSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetEventSourceRequest}
 */
proto.rct.web.GetEventSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetEventSourceRequest;
  return proto.rct.web.GetEventSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetEventSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetEventSourceRequest}
 */
proto.rct.web.GetEventSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetEventSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetEventSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetEventSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetEventSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.GetEventSourceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetEventSourceRequest} returns this
 */
proto.rct.web.GetEventSourceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetEventSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetEventSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetEventSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetEventSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsource: (f = msg.getEventsource()) && proto.rct.web.EventSourceView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetEventSourceResponse}
 */
proto.rct.web.GetEventSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetEventSourceResponse;
  return proto.rct.web.GetEventSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetEventSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetEventSourceResponse}
 */
proto.rct.web.GetEventSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.EventSourceView;
      reader.readMessage(value,proto.rct.web.EventSourceView.deserializeBinaryFromReader);
      msg.setEventsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetEventSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetEventSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetEventSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetEventSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.EventSourceView.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventSourceView eventSource = 1;
 * @return {?proto.rct.web.EventSourceView}
 */
proto.rct.web.GetEventSourceResponse.prototype.getEventsource = function() {
  return /** @type{?proto.rct.web.EventSourceView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventSourceView, 1));
};


/**
 * @param {?proto.rct.web.EventSourceView|undefined} value
 * @return {!proto.rct.web.GetEventSourceResponse} returns this
*/
proto.rct.web.GetEventSourceResponse.prototype.setEventsource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetEventSourceResponse} returns this
 */
proto.rct.web.GetEventSourceResponse.prototype.clearEventsource = function() {
  return this.setEventsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetEventSourceResponse.prototype.hasEventsource = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteEventSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteEventSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteEventSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteEventSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteEventSourceRequest}
 */
proto.rct.web.DeleteEventSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteEventSourceRequest;
  return proto.rct.web.DeleteEventSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteEventSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteEventSourceRequest}
 */
proto.rct.web.DeleteEventSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteEventSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteEventSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteEventSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteEventSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.DeleteEventSourceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.DeleteEventSourceRequest} returns this
 */
proto.rct.web.DeleteEventSourceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteEventSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteEventSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteEventSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteEventSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteEventSourceResponse}
 */
proto.rct.web.DeleteEventSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteEventSourceResponse;
  return proto.rct.web.DeleteEventSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteEventSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteEventSourceResponse}
 */
proto.rct.web.DeleteEventSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteEventSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteEventSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteEventSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteEventSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateEventSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateEventSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateEventSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateEventSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventsource: (f = msg.getEventsource()) && proto.rct.web.EventSourceMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateEventSourceRequest}
 */
proto.rct.web.UpdateEventSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateEventSourceRequest;
  return proto.rct.web.UpdateEventSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateEventSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateEventSourceRequest}
 */
proto.rct.web.UpdateEventSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.EventSourceMetadata;
      reader.readMessage(value,proto.rct.web.EventSourceMetadata.deserializeBinaryFromReader);
      msg.setEventsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateEventSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateEventSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateEventSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateEventSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventsource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.EventSourceMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.UpdateEventSourceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.UpdateEventSourceRequest} returns this
 */
proto.rct.web.UpdateEventSourceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EventSourceMetadata eventSource = 2;
 * @return {?proto.rct.web.EventSourceMetadata}
 */
proto.rct.web.UpdateEventSourceRequest.prototype.getEventsource = function() {
  return /** @type{?proto.rct.web.EventSourceMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventSourceMetadata, 2));
};


/**
 * @param {?proto.rct.web.EventSourceMetadata|undefined} value
 * @return {!proto.rct.web.UpdateEventSourceRequest} returns this
*/
proto.rct.web.UpdateEventSourceRequest.prototype.setEventsource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateEventSourceRequest} returns this
 */
proto.rct.web.UpdateEventSourceRequest.prototype.clearEventsource = function() {
  return this.setEventsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateEventSourceRequest.prototype.hasEventsource = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateEventSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateEventSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateEventSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateEventSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsource: (f = msg.getEventsource()) && proto.rct.web.EventSourceView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateEventSourceResponse}
 */
proto.rct.web.UpdateEventSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateEventSourceResponse;
  return proto.rct.web.UpdateEventSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateEventSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateEventSourceResponse}
 */
proto.rct.web.UpdateEventSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.EventSourceView;
      reader.readMessage(value,proto.rct.web.EventSourceView.deserializeBinaryFromReader);
      msg.setEventsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateEventSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateEventSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateEventSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateEventSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.EventSourceView.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventSourceView eventSource = 1;
 * @return {?proto.rct.web.EventSourceView}
 */
proto.rct.web.UpdateEventSourceResponse.prototype.getEventsource = function() {
  return /** @type{?proto.rct.web.EventSourceView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.EventSourceView, 1));
};


/**
 * @param {?proto.rct.web.EventSourceView|undefined} value
 * @return {!proto.rct.web.UpdateEventSourceResponse} returns this
*/
proto.rct.web.UpdateEventSourceResponse.prototype.setEventsource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateEventSourceResponse} returns this
 */
proto.rct.web.UpdateEventSourceResponse.prototype.clearEventsource = function() {
  return this.setEventsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateEventSourceResponse.prototype.hasEventsource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.rct.web.EventSourceType = {
  EVENT_SOURCE_UNSPECIFIED: 0,
  EVENT_SOURCE_CLOUDWATCH: 1,
  EVENT_SOURCE_PROMETHEUS: 2,
  EVENT_SOURCE_FAKE: 3
};

goog.object.extend(exports, proto.rct.web);
