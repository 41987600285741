import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import Button from '../../components/Button';
import { DrawerPosition, ColorTheme } from '../../definitions';

type ContainerProps = {
  visible?: boolean;
  position: DrawerPosition;
  width?: string;
  fullScreenOnSmall: boolean;
  colorTheme: ColorTheme;
  zIndex?: number;
  hasTopMarginOnMedium?: boolean;
};

type HeaderProps = {
  colorTheme: ColorTheme;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  height: calc(100vh - ${props => props.theme.constants.topbarHeight});
  overflow: auto;
  background-color: ${({ theme, colorTheme }) =>
    colorTheme === ColorTheme.Dark ? theme.palettes.main : darken(0.025, theme.palettes.inverse)};
  color: ${({ theme, colorTheme }) =>
    colorTheme === ColorTheme.Dark ? theme.palettes.inverse : theme.palettes.main};
  z-index: ${props => (props.zIndex ? props.zIndex : props.theme.zIndexes.drawer)};
  transition: transform 200ms ease-out;
  box-shadow: ${({ theme }) => theme.effects.navShadow};
  min-width: ${props => props.width || `16rem`};
  max-width: 100%;

  ${props =>
    props.position === DrawerPosition.Left &&
    css`
      left: 0;
      width: 100%;
      transform: translateX(${props.visible ? 0 : `-100%`});
    `}

  ${props =>
    props.position === DrawerPosition.Right &&
    css`
      right: 0;
      width: 100%;
      transform: translateX(${props.visible ? 0 : `100%`});
    `}

    ${props => props.theme.media.medium`
    height: 100vh;
    width: ${
      props.width ? props.width : `calc(100% - ${props.theme.constants.mediumSidebarWidth})`
    }};
    max-width: ${props.width ? props.width : `80rem`};

    ${
      props.position === DrawerPosition.Left &&
      css`
        transform: translateX(${props.visible ? 0 : '-100%'});
      `
    }

    ${
      props.position === DrawerPosition.Right &&
      css`
        transform: translateX(${props.visible ? 0 : '100%'});
      `
    }
  `}

  ${props => props.theme.media.large`
    width: ${
      props.width ? props.width : `calc(100% - ${props.theme.constants.largeSidebarWidth})`
    }};   
    min-width:  ${
      props.width ? props.width : `calc(100% - ${props.theme.constants.largeSidebarWidth})`
    }};  
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
`;

export const Header = styled.div<HeaderProps>`
  padding: 1rem 1.5rem;
  min-height: 5.5rem;
  display: flex;
  background-color: ${({ theme, colorTheme }) =>
    colorTheme === ColorTheme.Dark ? lighten(0.25, theme.palettes.main) : theme.palettes.inverse};
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid ${props => props.theme.palettes.headerBorder};
  ${CloseButton} {
    position: relative;
    top: 0;
    left: 0;
  }
`;
