import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from './store/reducers';
import SagaManager from './store/sagas';

export const history = createBrowserHistory();

const reduxStore = (): Store => {
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();
  const rootReducer = createRootReducer(history);

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  );

  SagaManager.startSagas(sagaMiddleware);

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./store/reducers', () => {
      store.replaceReducer(require('./store/reducers').default(history));
    });

    module.hot.accept('./store/sagas', () => {
      SagaManager.cancelSagas(store);
      require('./store/sagas').default.startSagas(sagaMiddleware);
    });
  }

  return store;
};

export default reduxStore;
