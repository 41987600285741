import { FunctionComponent } from 'react';
import { ComponentRefInTheList } from '../../../definitions';
import { ViewComponentButton } from '../SharedComponents';
import { Loader } from '../../../components/Loader';
import * as Styled from './DependencyBlock.styles';

type DependencyBlockProps = {
  components: ComponentRefInTheList[];
  noSelectedComponentTitle: string;
  noDependenciesTitle: string;
  isDependencySelected: boolean;
  isDependantSelected: boolean;
  selectedComponent?: ComponentRefInTheList;
  onViewComponent: (componentShortname: string) => void;
  isFetching: boolean;
  hasComponents: boolean;
  isGroup?: boolean;
  isEmpty?: boolean;
};

export const DependencyBlockView: FunctionComponent<DependencyBlockProps> = ({
  noSelectedComponentTitle,
  noDependenciesTitle,
  isDependencySelected,
  isDependantSelected,
  selectedComponent,
  onViewComponent,
  isFetching,
  hasComponents,
  children,
  isGroup,
  isEmpty,
}) => {
  return (
    <Styled.Block isGroup={isGroup} isEmpty={isEmpty}>
      {hasComponents ? (
        children
      ) : selectedComponent?.id ? (
        <Styled.EmptyText
          isDependencySelected={isDependencySelected}
          isDependantSelected={isDependantSelected}
          text={noDependenciesTitle}
        >
          <ViewComponentButton
            componentShortname={selectedComponent.shortname}
            title="Navigate to component"
            onViewComponent={onViewComponent}
          />
        </Styled.EmptyText>
      ) : isFetching ? (
        <Styled.LoaderWrapper>
          <Loader />
        </Styled.LoaderWrapper>
      ) : (
        <Styled.EmptyText
          isDependencySelected={isDependencySelected}
          isDependantSelected={isDependantSelected}
          text={noSelectedComponentTitle}
        />
      )}
    </Styled.Block>
  );
};
