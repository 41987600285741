import { ActionType, BotFormData } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const fetchBotList = (payload = {}): ActionType => ({
  type: actionTypes.bots.fetchList,
  payload,
});

export const updateNewBot = (payload = {}): ActionType => ({
  type: actionTypes.bots.updateNew,
  payload,
});

export const resetNewBot = (payload = {}): ActionType => ({
  type: actionTypes.bots.resetNew,
  payload,
});

export const createBot = (payload: BotFormData): ActionType => ({
  type: actionTypes.bots.create,
  payload,
});

export const updateExistingBot = (payload: {
  formData: BotFormData;
  botId: string;
}): ActionType => ({
  type: actionTypes.bots.current.update,
  payload,
});

export const fetchBot = (botShortname: string): ActionType => ({
  type: actionTypes.bots.current.fetch,
  payload: botShortname,
});

export const deleteBot = (payload: { botId: string; shortName: string }): ActionType => ({
  type: actionTypes.bots.current.delete,
  payload,
});

export const createBotToken = (botId: string): ActionType => ({
  type: actionTypes.bots.current.createToken,
  payload: botId,
});

export const selectBotToken = (payload: { tokenId: string; botId: string }): ActionType => ({
  type: actionTypes.bots.current.selectToken,
  payload,
});

export const deleteBotToken = (payload: { botId: string; tokenId: string }): ActionType => ({
  type: actionTypes.bots.current.deleteToken,
  payload,
});
