import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import { logout, getAuthError } from '../../store';
import { paths, AuthErrorType } from '../../definitions';
import { AuthenticationErrorView } from './AuthenticationError.view';

type AuthenticationErrorProps = {
  children: JSX.Element;
  history?: History<{ error: string; errorType: string }>;
  autoLogin?: boolean;
};

export const AuthenticationErrorContainer: FunctionComponent<AuthenticationErrorProps> = ({
  history,
  children,
  autoLogin = true,
  ...rest
}) => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(getAuthError) || history?.location?.state?.error;
  const showLogout = errorMessage === AuthErrorType.UNAUTHORIZED;
  const handleLogout = useCallback(
    () => dispatch(logout({ returnUrl: paths.landing })),
    [dispatch],
  );

  if (
    (children && !errorMessage) ||
    (!autoLogin && errorMessage === AuthErrorType.LOGIN_REQUIRED)
  ) {
    return children;
  }

  return (
    <AuthenticationErrorView
      error={errorMessage}
      showLogout={showLogout}
      onLogout={handleLogout}
      {...rest}
    />
  );
};
