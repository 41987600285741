import { FunctionComponent, useEffect, useState, ReactElement, useCallback, useMemo } from 'react';
import { Arrow, useLayer } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { TagView } from '../../grpc/grpcweb/tag_pb';
import {
  AvatarType,
  XEventInTheList,
  ComponentInTheList,
  TagState,
  TagAppearance,
  AvatarAppearance,
} from '../../definitions';
import AvatarWithTitle from '../AvatarWithTitle';
import { Loader } from '../Loader';
import { Tag, TagContainer } from '../Tag';
import { DateTime, Status, DisplayNameLink } from '../xEventDetails';
import * as Styled from './ComponentWidget.styles';

export type ComponentWidgetProps = {
  name: string;
  actions?: ReactElement[];
  onFetchDetails: () => void;
  isLoadingContent?: boolean;
  componentDetails: ComponentInTheList;
  xEvents: XEventInTheList[];
  type?: string;
  onOpenTeamPage: (teamShortname?: string) => void;
  onViewComponent: (componentShortname?: string) => void;
};

export const ComponentWidgetView: FunctionComponent<ComponentWidgetProps> = ({
  children,
  name,
  actions,
  onFetchDetails,
  isLoadingContent,
  componentDetails,
  xEvents,
  onOpenTeamPage,
  onViewComponent,
  type,
}) => {
  const { ownerTeam, id, description, tags, shortname } = componentDetails;

  const [isOpen, setOpen] = useState(false);
  const [setToClose, setSetToClose] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSetToClose(false);
  };

  const handleTriggerClick = useCallback(() => {
    if (!isOpen) {
      onFetchDetails();
    }

    setOpen(!isOpen);
  }, [isOpen, onFetchDetails]);

  useEffect(() => {
    if (setToClose) {
      handleClose();
    }
  }, [setToClose]);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onParentClose: handleClose, // close the menu when the parent closes
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  const slicedTags = useMemo(() => tags.slice(0, 6), [tags]);
  const leftoverTags = useMemo(() => tags.length - slicedTags.length, [tags, slicedTags]);

  return (
    <>
      <div {...triggerProps} onClick={handleTriggerClick}>
        {children}
      </div>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <Styled.Header>
                {type && (
                  <Styled.TagWrapper>
                    <Tag value={type} state={TagState.Info} appearance={TagAppearance.Badge} />
                  </Styled.TagWrapper>
                )}
                <Styled.AvatarRow>
                  <Styled.AvatarWrapper>
                    <AvatarWithTitle
                      title="Open component"
                      name={name}
                      onNameClick={() => onViewComponent(shortname)}
                      avatarAppearance={AvatarAppearance.Small}
                      avatarType={AvatarType.Component}
                    />
                  </Styled.AvatarWrapper>
                  <Styled.AvatarWrapper>
                    {ownerTeam && (
                      <AvatarWithTitle
                        title="Open team"
                        onNameClick={() => onOpenTeamPage(ownerTeam?.shortname)}
                        avatarAppearance={AvatarAppearance.Small}
                        name={ownerTeam?.displayname || ownerTeam?.shortname || ''}
                        avatarType={AvatarType.Team}
                      />
                    )}
                  </Styled.AvatarWrapper>
                </Styled.AvatarRow>
              </Styled.Header>
              {isLoadingContent ? (
                <Loader.Container>
                  <Loader />
                </Loader.Container>
              ) : (
                id && (
                  <Styled.Content>
                    {description && <Styled.Decsription>{description}</Styled.Decsription>}
                    {Boolean(slicedTags.length) && (
                      <TagContainer>
                        {slicedTags.map((tag: TagView.AsObject) => (
                          <Tag value={tag.value} tagKey={tag.displayname} key={tag.displayname} />
                        ))}
                        {leftoverTags > 0 && (
                          <Styled.MoreLink
                            onClick={() => onViewComponent(shortname)}
                            title="Open component"
                          >{`...${leftoverTags} more`}</Styled.MoreLink>
                        )}
                      </TagContainer>
                    )}
                    {Boolean(xEvents.length) && (
                      <div>
                        <h4>Recent events</h4>
                        <Styled.EventContainer>
                          {xEvents.map(event => (
                            <Styled.Event key={event.id}>
                              <Styled.EventItem>
                                <DateTime {...event} />
                              </Styled.EventItem>
                              <Styled.EventItem isStatusItem={true}>
                                <Status {...event} />
                              </Styled.EventItem>
                              <Styled.EventItem>
                                <DisplayNameLink {...event} />
                              </Styled.EventItem>
                            </Styled.Event>
                          ))}
                        </Styled.EventContainer>
                        <Styled.MoreLinkWrapper>
                          <Styled.MoreLink
                            onClick={() => onViewComponent(shortname)}
                            title="Open component"
                          >
                            ...see all events
                          </Styled.MoreLink>
                        </Styled.MoreLinkWrapper>
                      </div>
                    )}
                  </Styled.Content>
                )
              )}
              {Boolean(actions && actions.length) && (
                <Styled.ActionWrapper>{actions}</Styled.ActionWrapper>
              )}
              <Arrow {...arrowProps} />
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
