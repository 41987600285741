import { FunctionComponent, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isLoadingAuth, authenticate, authenticateWithDemo } from '../../store';
import { usePageTitle } from '../../hooks';
import { LoginFormData } from '../../definitions';
import { LoginSceneView } from './Login.view';

export const LoginSceneContainer: FunctionComponent = () => {
  usePageTitle('Login to platform');

  const dispatch = useDispatch();
  const isAuthenticating = useSelector(isLoadingAuth);

  const handleSubmit = useCallback(
    (loginData: LoginFormData) => dispatch(authenticate({ ...loginData, letMeIn: true })),
    [dispatch],
  );

  const handleLoginWithDemo = useCallback(
    () => dispatch(authenticateWithDemo()),
    [dispatch],
  );

  return <LoginSceneView onSubmit={handleSubmit} isLoading={isAuthenticating} onLoginWithDemo={handleLoginWithDemo} />;
};
