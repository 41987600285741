import styled from 'styled-components';
import { Appearance } from '../../../definitions';
import Button from '../../Button';

export const PushArrayItemButton = styled(Button).attrs(props => ({
  appearance: Appearance.PrimaryWithIcon,
}))`
  align-self: flex-end;
`;

export const RemoveArrayItemButton = styled(Button).attrs(props => ({
  appearance: Appearance.Icon,
}))`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ArrayItemRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  div:first-child {
    width: 100%;
  }
`;
