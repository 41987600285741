import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  ${props => props.theme.media.medium`
    flex-direction: row;
  `}
`;
