import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';
import { TopbarMode } from '../../definitions/constants/TopbarMode';

type TitleProps = {
  mode: TopbarMode;
};

type LinkProps = {
  isActive: boolean;
};

export const Container = styled.nav<TitleProps>`
  background: ${({ theme }) => theme.palettes.main};
  height: ${({ theme }) => theme.constants.topbarHeight};
  width: 100vw;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndexes.topBar};
  ${props =>
    props.mode === TopbarMode.Landing &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const activeStyles = {
  textDecoration: 'underline',
};

export const NavBar = styled.div<TitleProps>`
  display: none;
  padding-top: ${({ theme }) => theme.constants.topbarHeight};

  ${props => props.theme.media.medium`
    padding-top: 0;
    display: flex;
    flex-basis: 80%;
    align-items: center;
    justify-content: flex-end;

    ${
      props.mode === TopbarMode.Landing &&
      css`
        flex-basis: 100%;
      `
    }
  `}
`;

export const Title = styled.div<TitleProps>`
  margin: 0;
  cursor: pointer;
  display: flex;

  svg {
    height: 2.688rem;
    width: auto;
    fill: ${({ theme }) => theme.palettes.inverse};
    transition: ${({ theme }) => theme.effects.transition};

    &:hover {
      fill: ${({ theme }) => theme.palettes.highlight};
    }
  }

  ${props => props.theme.media.medium`
    svg {
      display: block;
    }
  `}
`;

export const ClickableLink = styled.a<LinkProps>`
  text-decoration: ${props => (props.isActive ? 'underline' : 'none')};
`;

export const NavLink = styled(Link)<LinkProps>`
  text-decoration: ${props => (props.isActive ? 'underline' : 'none')};
  display: flex;
`;

export const Navigation = styled.div`
  display: flex;

  ${props => props.theme.media.medium`
    flex-basis: 80%;
    justify-content: flex-start;
  `}
`;

export const Notification = styled.div`
  display: flex;

  ${props => props.theme.media.medium`
    flex-basis: 10%;
    justify-content: flex-end;
  `}
`;

export const SidebarTrigger = styled.div`
  order: 1;
  svg {
    width: 1rem;
    height: auto;
    fill: ${props => darken(0.2, props.theme.palettes.inverse)};
    &:hover {
      fill: ${props => props.theme.palettes.inverse};
    }
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  svg {
    margin: 0;
  }
`;
