// package: rct.web
// file: grpcweb/bot.proto

var grpcweb_bot_pb = require("../grpcweb/bot_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BotService = (function () {
  function BotService() {}
  BotService.serviceName = "rct.web.BotService";
  return BotService;
}());

BotService.Create = {
  methodName: "Create",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.CreateBotRequest,
  responseType: grpcweb_bot_pb.CreateBotResponse
};

BotService.Delete = {
  methodName: "Delete",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.DeleteBotRequest,
  responseType: grpcweb_bot_pb.DeleteBotResponse
};

BotService.Update = {
  methodName: "Update",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.UpdateBotRequest,
  responseType: grpcweb_bot_pb.UpdateBotResponse
};

BotService.List = {
  methodName: "List",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.ListBotRequest,
  responseType: grpcweb_bot_pb.ListBotResponse
};

BotService.CreateToken = {
  methodName: "CreateToken",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.CreateTokenRequest,
  responseType: grpcweb_bot_pb.CreateTokenResponse
};

BotService.DeleteToken = {
  methodName: "DeleteToken",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.DeleteTokenRequest,
  responseType: grpcweb_bot_pb.DeleteTokenResponse
};

BotService.GetById = {
  methodName: "GetById",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.GetBotByIdRequest,
  responseType: grpcweb_bot_pb.GetBotByIdResponse
};

BotService.GetByShortName = {
  methodName: "GetByShortName",
  service: BotService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_bot_pb.GetBotByShortNameRequest,
  responseType: grpcweb_bot_pb.GetBotByShortNameResponse
};

exports.BotService = BotService;

function BotServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BotServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BotServiceClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BotServiceClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BotServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BotServiceClient.prototype.createToken = function createToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.CreateToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BotServiceClient.prototype.deleteToken = function deleteToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.DeleteToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BotServiceClient.prototype.getById = function getById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.GetById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BotServiceClient.prototype.getByShortName = function getByShortName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BotService.GetByShortName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BotServiceClient = BotServiceClient;

