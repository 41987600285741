import { LOCATION_CHANGE } from 'connected-react-router';
import { SequenceType, ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export type SuccessState = {
  [k: string]: boolean;
};

const initialState = {};

const success = (
  state: SuccessState = initialState,
  { rootAction, sequence, type }: ActionType,
): SuccessState => {
  // Clear successes on route change
  if (type === LOCATION_CHANGE || type === actionTypes.successes.clear) {
    return initialState;
  }

  if (!rootAction || !rootAction.type) {
    return state;
  }

  switch (sequence) {
    case SequenceType.Start:
    case SequenceType.Error:
    case SequenceType.Cancel:
      return {
        ...state,
        [rootAction.type]: false,
      };

    case SequenceType.Success:
      return {
        ...state,
        [rootAction.type]: true,
      };

    default:
      return state;
  }
};

export default success;
