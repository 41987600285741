import { FunctionComponent, ReactElement } from 'react';
import { Icon } from '../../Icon';
import * as Styled from './StatusMessage.styles';

export type StatusMessageProps = {
  type?: string;
  isHidden?: boolean;
};

const icons: { [key: string]: ReactElement } = {
  error: <Icon.Caution />,
  pending: <Icon.Hourglass />,
  success: <Icon.TickCircle />,
};

export const StatusMessageView: FunctionComponent<StatusMessageProps> = ({
  children,
  type = 'error',
  isHidden = false,
  ...rest
}) => (
  <Styled.Message type={type} isHidden={isHidden} {...rest}>
    {children && icons[type] && <Styled.StatusIcon>{icons[type]}</Styled.StatusIcon>}
    {children}
  </Styled.Message>
);
