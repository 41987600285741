import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated, getAuthError, isLoadingAuth, checkAuth, fetchUser } from '../../store';

type AuthenticateProps = {
  children: (error: string, loading: boolean, status: boolean) => JSX.Element;
  autoLogin?: boolean;
  waitForUser?: boolean;
};

export const AuthenticateContainer: FunctionComponent<AuthenticateProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isAuthenticated);
  const isCheckingAuth = useSelector(isLoadingAuth);
  const error = useSelector(getAuthError);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUser());
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    dispatch(checkAuth({ autoLogin: true }));
  }, [dispatch]);

  if (!children) {
    return null;
  }

  return children(error, isCheckingAuth || (!error && !isLoggedIn), isLoggedIn);
};
