import { all, takeLatest, select, put, AllEffect } from 'redux-saga/effects';
import {
  CreateEventSourceRequest,
  UpdateEventSourceRequest,
  EventSourceMetadata,
  EventSourceType,
  CloudWatchMetadata,
  PrometheusMetadata,
  DeleteEventSourceRequest,
} from '../../grpc/grpcweb/eventsource_pb';
import { TextItem } from '../../grpc/grpccommon/common_pb';
import services from '../../services';
import {
  SequenceType,
  request,
  ActionType,
  AlertType,
  CreateSourceFormDataWithAuthType,
  EditSourceFormData,
  CloudWatchAuthType,
  PrometheusAuthType,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';
import {
  getAccessToken,
  getFetchEventSourceListError,
  getCreateEventSourceError,
  getUpdateEventSourceError,
  getFetchEventSourceError,
  getDeleteEventSourceError,
} from '../selectors';
import { showAlert, fetchEventSourceList, clearSuccess } from '../actions';

function* fetchList(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch event source list.',
      }),
    );
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.eventSources.fetchedList },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ eventSourcesService }) =>
          eventSourcesService
            .fetchList(token)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchEventSourceListError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchOne(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch event source.',
      }),
    );
  }

  const eventSourceId = action.payload;

  if (!eventSourceId) {
    return;
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.eventSources.fetchedOne },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ eventSourcesService }) =>
          eventSourcesService
            .fetchOneEventSource(token, eventSourceId)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchEventSourceError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* createNewEventSource(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to create event source.',
      }),
    );
  }

  const formData: CreateSourceFormDataWithAuthType = action.payload;

  const eventSourceMetadata = new EventSourceMetadata();
  eventSourceMetadata.setDisplayname(formData.displayName);
  // eslint-disable-next-line
  const sourceType =
    formData.sourceType === 1 || formData.sourceType === '1'
      ? EventSourceType.EVENT_SOURCE_CLOUDWATCH
      : EventSourceType.EVENT_SOURCE_PROMETHEUS;
  eventSourceMetadata.setType(sourceType);

  const description = new TextItem();
  description.setValue(formData.description);
  eventSourceMetadata.setDescription(description);

  if (formData.authenticationType === CloudWatchAuthType.AccessKey) {
    const cwMeta = new CloudWatchMetadata();
    cwMeta.setAuthtype(CloudWatchMetadata.AuthType.AUTH_TYPE_ACCESS_KEY);
    const accessKeyMeta = new CloudWatchMetadata.AccessKeyMetadata();
    accessKeyMeta.setRegion(formData.region);
    accessKeyMeta.setAccesskey(formData.accessKey);
    accessKeyMeta.setSecretaccesskey(formData.secretAccessKey);
    cwMeta.setAccesskeyauthmetadata(accessKeyMeta);
    eventSourceMetadata.setCloudwatchmetadata(cwMeta);
  } else if (formData.authenticationType === PrometheusAuthType.Basic) {
    const pmMeta = new PrometheusMetadata();
    pmMeta.setAuthtype(PrometheusMetadata.AuthType.AUTH_TYPE_BASIC);
    const basicMetadata = new PrometheusMetadata.BasicAuthMetadata();
    basicMetadata.setUrl(formData.url);
    basicMetadata.setUsername(formData.username);
    basicMetadata.setPwd(formData.password);
    pmMeta.setBasicauthmetadata(basicMetadata);
    eventSourceMetadata.setPrometheusmetadata(pmMeta);
  }

  const createEventSourceRequest = new CreateEventSourceRequest();
  createEventSourceRequest.setEventsource(eventSourceMetadata);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.eventSources.created },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ eventSourcesService }) =>
          eventSourcesService
            .createNewEventSource(token, createEventSourceRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchEventSourceList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.displayName} event source successfully created.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getCreateEventSourceError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* updateEventSource(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update event source',
      }),
    );
  }

  const { sourceId, formData }: { formData: EditSourceFormData; sourceId: string } = action.payload;

  const eventSourceMetadata = new EventSourceMetadata();

  if (formData.authenticationType === CloudWatchAuthType.AccessKey) {
    const cwMeta = new CloudWatchMetadata();
    cwMeta.setAuthtype(CloudWatchMetadata.AuthType.AUTH_TYPE_ACCESS_KEY);
    const accessKeyMeta = new CloudWatchMetadata.AccessKeyMetadata();
    formData.region && accessKeyMeta.setRegion(formData.region);
    formData.accessKey && accessKeyMeta.setAccesskey(formData.accessKey);
    formData.secretAccessKey && accessKeyMeta.setSecretaccesskey(formData.secretAccessKey);
    cwMeta.setAccesskeyauthmetadata(accessKeyMeta);
    eventSourceMetadata.setCloudwatchmetadata(cwMeta);
  } else if (formData.authenticationType === PrometheusAuthType.Basic) {
    const pmMeta = new PrometheusMetadata();
    pmMeta.setAuthtype(PrometheusMetadata.AuthType.AUTH_TYPE_BASIC);
    const basicMetadata = new PrometheusMetadata.BasicAuthMetadata();
    formData.url && basicMetadata.setUrl(formData.url);
    formData.username && basicMetadata.setUsername(formData.username);
    formData.password && basicMetadata.setPwd(formData.password);
    pmMeta.setBasicauthmetadata(basicMetadata);
    eventSourceMetadata.setPrometheusmetadata(pmMeta);
  }

  formData.displayName && eventSourceMetadata.setDisplayname(formData.displayName);
  // eslint-disable-next-line eqeqeq
  const sourceType =
    formData.sourceType === 1 || formData.sourceType === '1'
      ? EventSourceType.EVENT_SOURCE_CLOUDWATCH
      : EventSourceType.EVENT_SOURCE_PROMETHEUS;
  eventSourceMetadata.setType(sourceType);

  const description = new TextItem();
  formData.description && description.setValue(formData.description);
  eventSourceMetadata.setDescription(description);

  const updateEventSourceRequest = new UpdateEventSourceRequest();
  updateEventSourceRequest.setEventsource(eventSourceMetadata);
  updateEventSourceRequest.setId(sourceId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.eventSources.updated },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ eventSourcesService }) =>
          eventSourcesService
            .updateEventSource(token, updateEventSourceRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchEventSourceList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.displayName} event source successfully updated.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateEventSourceError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteEventSource(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete event source.',
      }),
    );
  }

  const { sourceId, displayName }: { sourceId: string; displayName: string } = action.payload;

  const deleteEventSourceRequest = new DeleteEventSourceRequest();
  deleteEventSourceRequest.setId(sourceId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.eventSources.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ eventSourcesService }) =>
          eventSourcesService
            .deleteEventSource(token, deleteEventSourceRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchEventSourceList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${displayName} Event Source deleted successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteEventSourceError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.eventSources.fetchList, fetchList)]);
  yield all([takeLatest(actionTypes.eventSources.fetchOne, fetchOne)]);
  yield all([takeLatest(actionTypes.eventSources.create, createNewEventSource)]);
  yield all([takeLatest(actionTypes.eventSources.update, updateEventSource)]);
  yield all([takeLatest(actionTypes.eventSources.delete, deleteEventSource)]);
}
