import actionTypes from '../constants/actionTypes';
import { Notification } from '../../grpc/grpcweb/notification_pb';
import { isLoading } from './loading';
import { getErrorMessage } from './errors';
import { RootState } from '../reducers';

export const getNotifications = (state: RootState): Notification.AsObject[] => {
  return state.notifications;
};

export const isFetchingNotifications = (state: RootState): boolean => {
  return isLoading(state, actionTypes.notifications.fetch);
};

export const getFetchNotificationsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.notifications.fetch);
};

export const isMarkingAllNotificationsAsRead = (state: RootState): boolean => {
  return isLoading(state, actionTypes.notifications.markAsRead);
};

export const getMarkAllNotificationsAsReadError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.notifications.markAsRead);
};
