import { FunctionComponent, useState, useRef } from 'react';
import { Form } from 'react-final-form';
import { BotFormData, Appearance, theme } from '../../definitions';
import { FormLayout } from '../../components/Form';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import { Layout } from '../../components/Layout';
import PopConfirm from '../../components/PopConfirm';
import Tooltip from '../../components/Tooltip';
import { BotForm } from '.';

type UpdateBotFormProps = {
  isFetching: boolean;
  isUpdating: boolean;
  onSubmit: (formData: BotFormData) => void;
  onCancel: () => void;
  initialValues: BotFormData;
  isDisabled?: boolean;
  isDeleting?: boolean;
  onDelete: () => void;
  hasTokens: boolean;
};

export const UpdateBotFormView: FunctionComponent<UpdateBotFormProps> = ({
  onSubmit,
  isFetching,
  isUpdating,
  initialValues,
  onCancel,
  isDisabled = false,
  isDeleting,
  onDelete,
  children,
  hasTokens,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

  const DeleteButton: FunctionComponent<{ isDisabled: boolean }> = ({ isDisabled }) => (
    <Button isDisabled={isDisabled} isPending={isDeleting} appearance={Appearance.PrimaryWithIcon}>
      <Icon.Trash />
      <span>Delete</span>
    </Button>
  );

  const DeletePopConfirm: FunctionComponent = () => {
    const ref = useRef<HTMLDivElement>(null);

    if (hasTokens) {
      return (
        <Tooltip
          content="A bot with assigned API tokens cannot be deleted. Please deleta all tokens first."
          color={theme.palettes.main}
        >
          <div ref={ref}>
            <DeleteButton isDisabled={true} />
          </div>
        </Tooltip>
      );
    }

    return (
      <PopConfirm
        title={`Are you sure you want to delete the ${initialValues.shortname || 'selected'} bot?`}
        onConfirm={onDelete}
        isConfirming={isDeleting}
        isDisabled={isFetching || isUpdating}
        contentText="Please note that after confirming bot and its owned tokens will be permanently deleted. You will not be
                  able to restore the deleted bot."
      >
        <DeleteButton isDisabled={isFetching || isUpdating} />
      </PopConfirm>
    );
  };

  return (
    <Form
      autoComplete="off"
      onSubmit={(values: BotFormData) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          setClickedSubmit(true);
          handleSubmit(event);
        };

        return (
          <>
            <Layout.Section>
              <BotForm clickedSubmit={clickedSubmit} isNewBot={false} />
            </Layout.Section>
            {children}
            <FormLayout.ActionWrapper>
              <Button
                onClick={onCancel}
                isDisabled={isUpdating || isDeleting}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Collapse />
                <span>Close</span>
              </Button>
              <DeletePopConfirm />
              <Button
                onClick={onSubmitClick}
                isPending={isUpdating || isFetching}
                isDisabled={isDisabled || isDeleting || pristine}
                appearance={Appearance.PrimaryWithIcon}
                isHighlighted={true}
              >
                <Icon.Edit />
                <span>Update</span>
              </Button>
            </FormLayout.ActionWrapper>
          </>
        );
      }}
    />
  );
};
