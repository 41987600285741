import { FunctionComponent } from 'react';
import { Tag } from '../../definitions';
import List from '../../components/List';
import Pagination from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { TagValue } from '../../grpc/grpcweb/tag_pb';
import { Link } from '../../components/Link';

export type TagListProps = {
  isFetching: boolean;
  tagList: Tag[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfTags: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onEditTag: (tag: Tag) => void;
};

export const TagListView: FunctionComponent<TagListProps> = ({
  isFetching,
  tagList,
  onNextPage,
  onPrevPage,
  numberOfTags,
  isPrevPageDisabled,
  isNextPageDisabled,
  onEditTag,
}) => {
  const UsedMeta: FunctionComponent<Tag> = () => (
    <span key="updated-meta">Used in X components</span>
  );

  const ValueList: FunctionComponent<Tag> = ({ values }) => {
    return (
      <ul key="values">
        {values?.map((value: TagValue.AsObject) => (
          <li key={value.id}>{value.value}</li>
        ))}
      </ul>
    );
  };

  const EditTagButton: FunctionComponent<Tag> = props => (
    <Link onClick={() => onEditTag(props)} key="edit-button">
      {props.tagKey}
    </Link>
  );

  const columns = [EditTagButton, ValueList, 'description', UsedMeta];

  return (
    <Layout.Section>
      <List
        items={tagList}
        columnTitles={['Name', 'Values', 'Description', 'Usage']}
        columns={columns}
        hideOnSmall={['description', UsedMeta]}
        itemKey="key"
        isLoading={isFetching}
        emptyText="You don't have any tags yet. Start by creating one."
      />
      <Pagination totalItems={numberOfTags}>
        <Pagination.NextPrev
          onNextClick={onNextPage}
          onPrevClick={onPrevPage}
          isPrevDisabled={isPrevPageDisabled}
          isNextDisabled={isNextPageDisabled}
        />
      </Pagination>
    </Layout.Section>
  );
};
