import { FunctionComponent, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Layout } from '../../components/Layout';
import List from '../../components/List';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { Appearance, BotInTheList, TokenInTheList, theme } from '../../definitions';
import PopConfirm from '../../components/PopConfirm';
import Button from '../../components/Button';
import { Link } from '../../components/Link';
import { Icon } from '../../components/Icon';
import CreateBotModal from '../../modals/CreateBot';
import CreateTokenModal from '../../modals/CreateToken';
import UpdateBot from '../UpdateBot';
import Overlay from '../../components/Overlay';
import TokenModal from '../../modals/Token';
import * as Styled from './Bots.styles';

type BotsViewProps = {
  list: BotInTheList[];
  isFetchingList: boolean;
  onCreateBotToken: (id: string) => void;
  onCloseUpdateBot: () => void;
  isCreatingToken: boolean;
  isEditDrawerVisible: boolean;
  onOpenUpdateBot: (botShortname: string) => void;
  onSelectBotToken: (tokenId: string, botId: string) => void;
} & RouteChildrenProps;

export const BotsView: FunctionComponent<BotsViewProps> = ({
  list,
  isFetchingList,
  onCreateBotToken,
  isCreatingToken,
  onCloseUpdateBot,
  onOpenUpdateBot,
  isEditDrawerVisible,
  onSelectBotToken,
  ...rest
}) => {
  const [isCreateBotModalOpen, setIsCreateBotModalOpen] = useState(false);
  const [isCreateTokenModalOpen, setIsCreateTokenModalOpen] = useState(false);
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);

  const BotButton: FunctionComponent<{ shortname: string }> = ({ shortname }) => (
    <Link onClick={() => onOpenUpdateBot(shortname)}>{shortname}</Link>
  );

  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>Bots & API tokens</Layout.HeaderTitle>
      <Layout.HeaderActions>
        <Button
          onClick={() => setIsCreateBotModalOpen(true)}
          appearance={Appearance.PrimaryWithIconCollapsible}
          aria-label="Create new bot"
          title="Create new bot"
        >
          <Icon.Plus />
          <span>Create new bot</span>
        </Button>
      </Layout.HeaderActions>
    </Layout.Header>
  );

  const GenerateTokenButton: FunctionComponent<{ id: string }> = ({ id }) => {
    const onOpenCreateTokenModal = (botId: string) => {
      onCreateBotToken(botId);
      setIsCreateTokenModalOpen(true);
    };

    return (
      <PopConfirm
        title={`Are you sure you want to generate new token?`}
        onConfirm={() => onOpenCreateTokenModal(id)}
        isConfirming={isCreatingToken}
        isDisabled={isCreatingToken}
        contentText="Clicking 'Confirm' will generate a new token and assign it to the bot. We will display the token only once after it has been created. Please copy and store it in a secure location."
        confirmColor={theme.palettes.highlight}
      >
        <Styled.ButtonWrapper>
          <Button appearance={Appearance.Link} color={theme.palettes.secondaryHighlight}>
            New token
          </Button>
        </Styled.ButtonWrapper>
      </PopConfirm>
    );
  };

  const TokenList: FunctionComponent<{ tokens: TokenInTheList[]; id: string }> = ({
    tokens,
    id,
  }) => (
    <Styled.LinkWrapper>
      {tokens.length ? (
        tokens.map((token: TokenInTheList) => {
          const handleSelectToken = () => {
            onSelectBotToken(token.id, id);
            setIsTokenModalOpen(true);
          };

          return (
            <Link key={token.id} onClick={handleSelectToken}>
              {token.id}
            </Link>
          );
        })
      ) : (
        <p>none</p>
      )}
    </Styled.LinkWrapper>
  );

  return (
    <>
      <AuthenticatedLayout header={Header}>
        <Layout.SubHeading>
          Bots are used to manage access to RocketSLO public API. A bot is granted specific set of
          permissions and holds a set of API tokens that can be used to authenticate requests to
          public API.
        </Layout.SubHeading>
        <Layout.Section>
          <List
            items={list}
            columnTitles={['Bot', 'API tokens', '']}
            columns={[BotButton, TokenList, GenerateTokenButton]}
            hideOnSmall={[]}
            itemKey="id"
            isLoading={isFetchingList}
            emptyText="You don't have any bots yet. Start by creating one."
          />
        </Layout.Section>

        <CreateBotModal
          isOpen={isCreateBotModalOpen}
          onClose={() => setIsCreateBotModalOpen(false)}
        />
        <CreateTokenModal
          isOpen={isCreateTokenModalOpen}
          onClose={() => setIsCreateTokenModalOpen(false)}
        />
        <TokenModal isOpen={isTokenModalOpen} onClose={() => setIsTokenModalOpen(false)} />

        <UpdateBot isOpen={isEditDrawerVisible} onClose={onCloseUpdateBot} {...rest} />
        <Overlay onClick={onCloseUpdateBot} isVisible={isEditDrawerVisible} />
      </AuthenticatedLayout>
    </>
  );
};
