import { SequenceType, ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export type AuthState = {
  isAuthenticated: boolean;
  accessToken: string;
  redirectUri: string;
};

const initialState = {
  isAuthenticated: false,
  accessToken: '',
  redirectUri: '',
};

const auth = (
  state: AuthState = initialState,
  { type, sequence, payload }: ActionType,
): AuthState => {
  switch (type) {
    case actionTypes.auth.redirect: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          ...payload,
        };
      }

      return state;
    }

    case actionTypes.auth.checked: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          isAuthenticated: Boolean(payload),
          accessToken: payload,
        };
      }

      return state;
    }

    case actionTypes.auth.loggedOut:
    case actionTypes.users.current.remove:
      return initialState;

    default:
      return state;
  }
};

export default auth;
