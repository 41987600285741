import { FunctionComponent, ReactElement, ElementType } from 'react';
import { AvatarAppearance, FlattenedTeam, User, AvatarType } from '../../definitions';
import UserProfilePopupView from '../UserProfilePopup';
import Avatar from '../Avatar';
import AvatarAction from './AvatarAction';
import * as Styled from './AvatarGroup.styles';

type AvatarGroupProps = {
  borderColor?: string;
};

type IAvatarGroup = FunctionComponent<AvatarGroupProps> & {
  Avatar: ElementType;
  Action: ElementType;
};

type ActionProps = {
  actionText: string;
  borderColor: string;
  appearance?: AvatarAppearance;
  actionTooltip: string;
};

type AvatarInGroupProps = {
  user: User | FlattenedTeam;
  userActions?: ReactElement[];
  onSelectUser?: (user: User | FlattenedTeam) => void;
  appearance?: AvatarAppearance;
  avatarType?: AvatarType;
};

export const AvatarGroupView: IAvatarGroup = ({ borderColor, children }) => (
  <Styled.Container borderColor={borderColor}>{children}</Styled.Container>
);

const AvatarInGroup: FunctionComponent<AvatarInGroupProps> = ({
  user,
  onSelectUser,
  userActions,
  appearance = AvatarAppearance.Medium,
  avatarType = AvatarType.User,
}) => {
  const handleOnAvatarClick = () => {
    onSelectUser && onSelectUser(user);
  };

  const getUserName = (user: User | FlattenedTeam) => {
    if ('username' in user) {
      return user.fullname;
    }
    return user.displayname || user.shortname || 'Team';
  };

  return (
    <Styled.Wrapper key={user.id} appearance={appearance}>
      <UserProfilePopupView user={user} actions={userActions} avatarType={avatarType}>
        <Avatar
          avatarType={avatarType}
          name={getUserName(user)}
          appearance={appearance}
          onClick={handleOnAvatarClick}
        />
      </UserProfilePopupView>
    </Styled.Wrapper>
  );
};

const Action: FunctionComponent<ActionProps> = ({
  actionText,
  borderColor,
  actionTooltip,
  appearance,
}) => (
  <Styled.Wrapper appearance={appearance}>
    <AvatarAction
      actionText={actionText}
      borderColor={borderColor}
      appearance={appearance}
      tooltip={actionTooltip}
    />
  </Styled.Wrapper>
);

AvatarGroupView.Avatar = AvatarInGroup;
AvatarGroupView.Action = Action;
