import { useMemo } from 'react';
import {
  TimeWindowUnitMap,
  TimeWindowTypeMap,
  TimeWindowUnit,
  TimeWindowType as TimeWindowTypeGrpc,
} from '../grpc/grpcweb/slo_pb';
import { TimeWindowUnitType, TimeWindowType } from '../definitions';

export function useTimeWindowUnitType(
  timeUnit?: TimeWindowUnitMap[keyof TimeWindowUnitMap],
): TimeWindowUnitType {
  const getUnitType = useMemo(
    () =>
      (timeUnit?: TimeWindowUnitMap[keyof TimeWindowUnitMap]): TimeWindowUnitType => {
        switch (timeUnit) {
          case TimeWindowUnit.TIME_WINDOW_UNIT_HOUR:
            return TimeWindowUnitType.Hour;
          case TimeWindowUnit.TIME_WINDOW_UNIT_DAY:
            return TimeWindowUnitType.Day;
          default:
            return TimeWindowUnitType.Unspecified;
        }
      },
    [],
  );

  return getUnitType(timeUnit);
}

export function useTimeWindowType(
  type?: TimeWindowTypeMap[keyof TimeWindowTypeMap],
): TimeWindowType {
  const getUnitType = useMemo(
    () =>
      (timeUnit?: TimeWindowTypeMap[keyof TimeWindowTypeMap]): TimeWindowType => {
        switch (timeUnit) {
          case TimeWindowTypeGrpc.TIME_WINDOW_TYPE_ROLLING:
            return TimeWindowType.Rolling;
          case TimeWindowTypeGrpc.TIME_WINDOW_TYPE_STATIC:
            return TimeWindowType.Static;
          default:
            return TimeWindowType.Unspecified;
        }
      },
    [],
  );

  return getUnitType(type);
}
