import { ActionType, SequenceType, XEventInTheList } from '../../definitions';
import { ListEventsByComponentResponse, XEvent } from '../../grpc/grpcweb/xevent_pb';
import actionTypes from '../constants/actionTypes';

export type XEventState = {
  list: XEventInTheList[];
  totalNumber: number;
  prevPageToken?: string;
  nextPageToken?: string;
  currPageToken?: string;
};

const initialState = {
  list: [],
  totalNumber: 0,
  prevPageToken: undefined,
  nextPageToken: undefined,
  currPageToken: undefined,
};

export const mapEventGRPCToStateObject = (event: XEvent.AsObject): XEventInTheList => {
  return {
    id: event.id,
    displayName: event.displayname,
    description: event.description,
    status: event.status,
    sentiment: event.sentiment,
    url: event.url,
    metafieldsList: event.metafieldsList,
    startedat: event.startedat ? new Date(event.startedat) : undefined,
  };
};

// const res: XEventInTheList[] = [{
//   id: '1',
//   displayName: 'hello 1',
//   description: 'hello',
//   status: 'yo',
//   sentiment: 1,
//   url: 'efef',
//   metafieldsList: [],
//   startedat: new Date()
// }, {
//   id: '2d',
//   displayName: 'Hello hello 2',
//   description: 'hello 2',
//   status: 'Yo yo',
//   sentiment: 2,
//   url: 'vdvd',
//   metafieldsList: [{label:'vdvdvvddfd', value: 'vevevv svvdsvsdv'}, {label:'sefbsrb', value: 'vevevv svvdsvsdv'}, {label:'vdvsfbsfbdvvd', value: 'vevevv svvdsvsdv'}, {label:'sddrgg', value: 'vevevv svvdsvsdv'}],
//   startedat: new Date()
// }, {
//   id: '2s',
//   displayName: 'Hello hello 2',
//   description: 'hello 2',
//   status: 'Yo yo',
//   sentiment: 2,
//   url: 'vdvd',
//   metafieldsList: [],
//   startedat: new Date()
// }, {
//   id: '2',
//   displayName: 'hello hello 2',
//   description: 'hello 2',
//   status: 'Yo yo',
//   sentiment: 2,
//   url: 'vdvd',
//   metafieldsList: [],
//   startedat: new Date()
// }, {
//   id: '3',
//   displayName: 'hello hello hello 3',
//   description: 'hello 3',
//   status: 'Yo yo yo',
//   sentiment: 3,
//   url: 'vdvdvfvf',
//   metafieldsList: [],
//   startedat: new Date()
// }, {
//   id: '4',
//   displayName: 'hello hello hello hello 4',
//   description: 'hello hello hello hello 4',
//   status: 'yo yo yo yo',
//   sentiment: 4,
//   url: 'vdvdfvfv',
//   metafieldsList: [],
//   startedat: new Date()
// }, {
//   id: '5',
//   displayName: 'hello hello hello hello hello 5',
//   description: 'hello hello hello hello hello 5',
//   status: 'yo yo yo yo yo',
//   sentiment: 5,
//   url: 'vdvdfvfv',
//   metafieldsList: [],
//   startedat: new Date()
// }]

const eventSources = (
  state: XEventState = initialState,
  { type, payload, sequence }: ActionType,
): XEventState => {
  switch (type) {
    case actionTypes.xEvents.fetchedList: {
      if (sequence === SequenceType.Success) {
        const rawList: ListEventsByComponentResponse.AsObject = payload;
        const { batch, pageresponse } = rawList;

        const list = batch?.eventsList
          ? batch?.eventsList?.map((event: XEvent.AsObject) => mapEventGRPCToStateObject(event))
          : [];

        return {
          ...state,
          list,
          totalNumber: pageresponse?.totalcount || 0,
          nextPageToken: pageresponse?.nextpagetoken,
          prevPageToken: pageresponse?.prevpagetoken,
          currPageToken: pageresponse?.curpagetoken,
        };
      }

      return state;
    }

    case actionTypes.xEvents.fetchedNextPage: {
      if (sequence === SequenceType.Success) {
        const rawList: ListEventsByComponentResponse.AsObject = payload;
        const { batch, pageresponse } = rawList;

        const list = batch?.eventsList
          ? batch?.eventsList?.map((event: XEvent.AsObject) => mapEventGRPCToStateObject(event))
          : [];

        return {
          ...state,
          list: [...state.list, ...list],
          totalNumber: pageresponse?.totalcount || 0,
          nextPageToken: pageresponse?.nextpagetoken,
          prevPageToken: pageresponse?.prevpagetoken,
          currPageToken: pageresponse?.curpagetoken,
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default eventSources;
