import styled from 'styled-components';
import { motion } from 'framer-motion';
import { User as AvatarType } from '../../definitions';

type UserProps = {
  onClick?: (user: AvatarType) => void;
};

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  flex-grow: 1;
  word-break: break-all;
`;

export const TriggerWrapper = styled.div``;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  width: 20rem;
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  background: ${({ theme }) => theme.palettes.inverse};
  justify-content: space-between;
`;

export const ActionWrapper = styled.div`
  display: flex;
  margin-left: 1rem;
  align-items: center;
  flex-basis: 2rem;
`;

export const User = styled.div<UserProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palettes.border};
  }
`;

export const Search = styled.input`
  border-radius: 0;
  font-size: 100%;
  line-height: 2.5rem;
  text-shadow: none;
  margin: 0 1rem;
  padding-left: 1.5rem;
  border: 0;
  background: inherit;
  border-bottom: 0.15rem solid ${({ theme }) => theme.palettes.secondaryHighlight};
  color: ${({ theme }) => theme.palettes.main};
  flex: 1 1 auto;

  ::placeholder {
    color: ${({ theme }) => theme.palettes.border};
  }
`;

export const SearchField = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;

  svg {
    margin-top: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
`;

export const SearchIconWrapper = styled.div`
  appearance: none;
  border: 0;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0.5rem;
  position: absolute;
  left: 0.5rem;
  bottom: 0;
  color: ${({ theme }) => theme.palettes.border};
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const EmptyText = styled.p`
  padding: 1rem;
  color: ${props => props.theme.palettes.placeholder};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListWrapper = styled.div`
  height: 100%;
  min-height: 5rem;
`;

export const CentredWrapper = styled(ListWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
