// package: rct.web
// file: grpcweb/tenant.proto

var grpcweb_tenant_pb = require("../grpcweb/tenant_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TenantService = (function () {
  function TenantService() {}
  TenantService.serviceName = "rct.web.TenantService";
  return TenantService;
}());

TenantService.GetTenantId = {
  methodName: "GetTenantId",
  service: TenantService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tenant_pb.GetTenantIdRequest,
  responseType: grpcweb_tenant_pb.GetTenantIdResponse
};

exports.TenantService = TenantService;

function TenantServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TenantServiceClient.prototype.getTenantId = function getTenantId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TenantService.GetTenantId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TenantServiceClient = TenantServiceClient;

