import { SagaIterator } from 'redux-saga';
import { select, call, cancelled, put, delay } from 'redux-saga/effects';
import actions from '../../store/constants/actionTypes';
import { SequenceType } from '../constants';
import { ActionType, ErrorMessages, ErrorField } from '../types';

export function* request(
  action: ActionType,
  fn: () => void,
  ttl = 0,
  debounce = 0,
  autoDispatch = true,
  handleUnauthorized = true,
): SagaIterator<ActionType> | ActionType {
  // Only start a new request if the last one has expired
  if (ttl) {
    const lastFetched: number = yield select(
      (state: { cache: { [key: string]: number } }): number =>
        action.rootAction ? state.cache[action.rootAction.type] : 0,
    );

    if (Date.now() - lastFetched < ttl) {
      return {
        ...action,
        sequence: SequenceType.Cached,
      };
    }
  }

  yield put({
    ...action,
    sequence: SequenceType.Start,
  });

  if (debounce) {
    yield delay(debounce);
  }

  try {
    const payload = yield call(fn);
    const newAction = {
      ...action,
      payload,
      sequence: SequenceType.Success,
    };

    if (autoDispatch) {
      yield put(newAction);
    }

    return newAction;
  } catch (error) {
    const statusCode = error?.response?.status || error?.context?.response?.status;

    if (handleUnauthorized && statusCode === 401) {
      yield put({ type: actions.auth.logout });
    }

    let message = error?.response?.data?.message || error?.message || error;
    const details = error?.details || '';
    const errorType = error?.type || '';
    let fields = {};

    if (Array.isArray(error?.response?.data?.data)) {
      fields = error?.response?.data?.data.reduce(
        (result: ErrorMessages, field: ErrorField) => ({
          ...result,
          [field?.data?.field]: field?.message,
        }),
        {},
      );
    } else if (error?.field) {
      fields = {
        [error?.field]: message,
      };

      message = null;
    }

    const newAction = {
      ...action,
      error: {
        message,
        details,
        fields,
        status: statusCode,
        type: errorType,
      },
      sequence: SequenceType.Error,
    };

    if (autoDispatch) {
      yield put(newAction);
    }

    return newAction;
  } finally {
    if (yield cancelled()) {
      yield put({
        ...action,
        sequence: SequenceType.Cancel,
      });
    }
  }
}
