import { FunctionComponent } from 'react';
import * as Styled from './LoginFormSection.styles';

type LoginFormSectionProps = {
  title?: string;
};

export const LoginFormSectionView: FunctionComponent<LoginFormSectionProps> = ({
  children,
  title,
  ...rest
}) => {
  return (
    <div {...rest}>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Content>{children}</Styled.Content>
    </div>
  );
};
