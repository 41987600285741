import { LOCATION_CHANGE } from 'connected-react-router';
import { SequenceType, ActionType, ErrorObject } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export type ErrorsState = {
  [k: string]: ErrorObject | null;
};

const initialState: ErrorsState = {};

const errors = (
  state: ErrorsState = initialState,
  { rootAction, sequence, type, error }: ActionType,
): ErrorsState => {
  if (type === actionTypes.errors.clear || type === LOCATION_CHANGE) {
    return initialState;
  }

  if (!rootAction || !rootAction.type) {
    return state;
  }

  switch (sequence) {
    case SequenceType.Start:
    case SequenceType.Success:
    case SequenceType.Cancel:
      return {
        ...state,
        [rootAction.type]: null,
      };

    case SequenceType.Error: {
      if (error) {
        console.log(JSON.stringify(error));
        return {
          ...state,
          [rootAction.type]: error,
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default errors;
