import {
  FunctionComponent,
  InputHTMLAttributes,
  useMemo,
  useState,
  isValidElement,
  cloneElement,
  ReactNode,
} from 'react';
import Select, { components, SingleValue, ControlProps } from 'react-select';
import { FieldRenderProps } from 'react-final-form';
import { InputTooltip } from '../InputTooltip';
import * as Styled from './Select.styles';

type Option = {
  value: string | number;
  label: string;
  type?: string;
};

export type SelectProps = InputHTMLAttributes<HTMLSelectElement> &
  FieldRenderProps<string> & {
    label?: string;
    additionalChild?: JSX.Element;
    remoteErrorMessage?: string;
    validMessage?: string;
    showValidation?: boolean;
    tooltip?: string;
    disabled?: boolean;
    isLoadingOptions?: boolean;
    showRedBorderOnUnfocus?: boolean;
    options: Array<Option>;
    formatOptionLabel?: FunctionComponent<Option>;
    reservedSpace?: string;
    onChange?: () => void;
    description?: string;
    isOptional?: boolean;
    getNoOptionsMessage?: (inputValue: { inputValue: string }) => ReactNode;
  };

export const Control = (props: ControlProps & { label: string }) => {
  return <components.Control {...props} />;
};

export const CustomMenu: FunctionComponent<any> = ({
  children,
  blur,
  nestedComponents,
  clearValue,
  innerProps,
  id,
  blurMenu,
  ...rest
}) => {
  const ChildrenWithProps: FunctionComponent<any> = ({ children, ...rest }) => {
    if (isValidElement(children)) {
      return cloneElement(children, { blurMenu, ...rest });
    }
    return null;
  };

  return (
    <components.MenuList id={id} {...innerProps} {...rest}>
      <ChildrenWithProps>{nestedComponents}</ChildrenWithProps>
      {children}
    </components.MenuList>
  );
};

export const SelectView: FunctionComponent<SelectProps> = ({
  label,
  meta,
  input,
  tooltip,
  disabled,
  remoteErrorMessage,
  validMessage,
  showValidation = false,
  readOnly = false,
  isLoadingOptions = false,
  showRedBorderOnUnfocus = false,
  options,
  children,
  formatOptionLabel,
  reservedSpace = 'none',
  onChange,
  id,
  description,
  placeholder,
  isOptional,
  getNoOptionsMessage,
}) => {
  const [selectRef, setSelectRef] = useState<any>(null);

  const blurMenu = () => {
    selectRef!.blur();
  };

  const mappedValueToOptionLabel = useMemo(() => {
    const { value } = input;
    return options.find((val: Option) => val.value === value);
  }, [options, input]);

  const errorMessage =
    (meta.touched && meta.error) ||
    (!meta.dirtySinceLastSubmit && (meta.submitError || remoteErrorMessage));

  const hasValue = Boolean(input?.value && String(input?.value).length);

  const showValidMessage =
    !readOnly && meta.valid && !meta.pristine && validMessage && !errorMessage;
  const validationMessage = showValidMessage ? (
    <Styled.Message type="success" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
      {validMessage}
    </Styled.Message>
  ) : (
    <Styled.Message type="error" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
      {errorMessage}
    </Styled.Message>
  );

  const conditionalProps = formatOptionLabel ? { formatOptionLabel } : {};

  return (
    <Styled.Container
      hasLabel={Boolean(label)}
      invalid={Boolean(errorMessage)}
      hasValue={hasValue}
      readOnly={readOnly}
      valid={meta.valid && !errorMessage}
      showRedBorderOnUnfocus={showRedBorderOnUnfocus || showValidation}
    >
      <Styled.Row>
        <Styled.Column>
          <Styled.LabelWrapper>
            <Styled.Label>
              {label}
              {isOptional && <Styled.Optional>(optional)</Styled.Optional>}
            </Styled.Label>
            {description && <Styled.Description>{description}</Styled.Description>}
          </Styled.LabelWrapper>
          <Styled.ReserveSpace reservedSpace={reservedSpace}>
            <Select
              ref={ref => {
                setSelectRef(ref);
              }}
              noOptionsMessage={inputValue =>
                getNoOptionsMessage && getNoOptionsMessage(inputValue)
              }
              value={mappedValueToOptionLabel}
              onChange={(value: SingleValue<Option>) => {
                input.onChange(value?.value);
                onChange && onChange();
              }}
              placeholder={placeholder}
              isDisabled={disabled || isLoadingOptions}
              options={options}
              styles={Styled.customStyles}
              isClearable={true}
              isLoading={isLoadingOptions}
              maxMenuHeight={200}
              closeMenuOnSelect={true}
              blurInputOnSelect={true}
              menuShouldScrollIntoView={false}
              components={{
                Control: (componentProps: any) => (
                  <Control label={label} {...componentProps} isInvalid={errorMessage} />
                ),
                MenuList: (componentProps: any) => (
                  <CustomMenu nestedComponents={children} {...componentProps} blurMenu={blurMenu} />
                ),
              }}
              {...conditionalProps}
            />
          </Styled.ReserveSpace>
        </Styled.Column>
        {tooltip && <InputTooltip content={tooltip} />}
      </Styled.Row>
      <Styled.MessageWrapper>{validationMessage}</Styled.MessageWrapper>
    </Styled.Container>
  );
};
