declare let process: {
  env: {
    REACT_APP_FIREBASE_API_KEY: string;
    REACT_APP_FIREBASE_AUTH_DOMAIN: string;
    REACT_APP_VERSION: string;
    REACT_APP_HOST: string;
    REACT_APP_AUTH_HOST: string;
  };
};

export const { REACT_APP_VERSION } = process.env;
export const { REACT_APP_FIREBASE_API_KEY } = process.env;
export const { REACT_APP_FIREBASE_AUTH_DOMAIN } = process.env;
export const { REACT_APP_HOST } = process.env;
export const { REACT_APP_AUTH_HOST } = process.env;
