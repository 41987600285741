import styled, { css } from 'styled-components';
import { rgba, darken } from 'polished';
import { BannerAppearance } from 'src/definitions';

type ContainerProps = {
  appearance: BannerAppearance;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 1rem;
  flex-direction: row;
  flex: 1;
  border-radius: 0.5rem;
  font-size: 0.75rem;

  ${props =>
    props.appearance === BannerAppearance.Success &&
    css`
      background: ${rgba(props.theme.palettes.success, 0.1)};
      color: ${darken(0.2, props.theme.palettes.success)};

      svg {
        fill: ${props.theme.palettes.success};
      }
    `}

  ${props =>
    props.appearance === BannerAppearance.Warning &&
    css`
      background: ${rgba(props.theme.palettes.warning, 0.1)};
      color: ${darken(0.2, props.theme.palettes.warning)};

      svg {
        fill: ${props.theme.palettes.warning};
      }
    `}

      ${props =>
    props.appearance === BannerAppearance.Info &&
    css`
      background: ${rgba(props.theme.palettes.info, 0.1)};
      color: ${darken(0.2, props.theme.palettes.info)};

      svg {
        fill: ${props.theme.palettes.info};
      }
    `}

          ${props =>
    props.appearance === BannerAppearance.Danger &&
    css`
      background: ${rgba(props.theme.palettes.error, 0.1)};
      color: ${darken(0.2, props.theme.palettes.error)};

      svg {
        fill: ${props.theme.palettes.error};
      }
    `}

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const WhiteBackground = styled.div`
  background-color: ${({ theme }) => theme.palettes.inverse};
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
`;

export const ChildrenConatiner = styled.div`
  display: flex;
  align-items: center;
`;
