import { FunctionComponent, InputHTMLAttributes } from 'react';
import { colors } from '../../../definitions';
import * as Styled from './Checkbox.styles';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  isDisabled?: boolean;
  isChecked: boolean;
  isRadio?: boolean;
};

export const CheckboxView: FunctionComponent<CheckboxProps> = ({
  className,
  label,
  isDisabled = false,
  isChecked,
  isRadio,
  id,
  ...rest
}) => {
  return (
    <Styled.Wrapper
      className={className}
      checked={isChecked}
      isRadio={isRadio}
      disabled={isDisabled}
    >
      <Styled.Input type="checkbox" disabled={isDisabled} checked={isChecked} id={id} {...rest} />
      <Styled.Box isRadio={isRadio} />
      {label && <Styled.Value color={colors.text}>{label}</Styled.Value>}
    </Styled.Wrapper>
  );
};
