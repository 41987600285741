import { grpc } from '@improbable-eng/grpc-web';
import { XEventsService } from '../grpc/grpcweb/xevent_pb_service';
import {
  ListEventsByComponentRequest,
  ListEventsByComponentResponse,
} from '../grpc/grpcweb/xevent_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class XEventService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  fetchListByPage = async (
    RctToken: string,
    listComponentsRequest: ListEventsByComponentRequest,
  ) => {
    const request: ListEventsByComponentRequest = listComponentsRequest;

    return new Promise((resolve, reject) => {
      grpc.unary(XEventsService.ListByComponent, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListEventsByComponentResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch external events list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
