import styled, { css } from 'styled-components';
import { RippleEffect } from '../../RippleEffect';
export { Message, MessageWrapper, Column, Label } from '../Input/Input.styles';

type WrapperProps = {
  checked?: boolean;
  disabled?: boolean;
};

export const Optional = styled.span`
  font-weight: 300;
  font-size: 0.75rem;
  margin-left: 0.5rem;
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 2;
`;

export const Input = styled.input`
  border: 0;
  background: none;
  padding: 0;
  outline: none;
  opacity: 0;
  position: absolute;
`;

export const Box = styled(RippleEffect)`
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palettes.border};
  box-shadow: ${({ theme }) => theme.effects.inputShadow};
  border-radius: 100%;
  position: relative;
  display: inline-block;
  align-self: center;
  padding: 0;

  &::after {
    content: '';
    left: 10%;
    top: 10%;
    bottom: 10%;
    right: 10%;
    position: absolute;
    border-radius: 100%;
    background-color: currentColor;
    transform: scale(0);
    transition: transform 0.1s cubic-bezier(0.68, -0.55, 0.265, 1.55),
      color 0.1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
`;

export const Wrapper = styled.label<WrapperProps>`
  position: relative;
  display: flex;
  cursor: pointer;
  padding: 0;

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      ${Box} {
        border-color: ${({ theme }) => theme.palettes.placeholder};
      }

      ${Value} {
        color: ${props.theme.palettes.placeholder};
      }
    `}

  ${props =>
    props.checked &&
    css`
      ${Box} {
        color: ${props.theme.palettes.highlight};

        &::after {
          transform: scale(1);
        }
      }
    `}
`;

export const Container = styled.div`
  display: flex;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
`;
