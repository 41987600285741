import React, { ElementType, FunctionComponent } from 'react';
import * as Styled from './Notice.styles';

type INotice = FunctionComponent & {
  Title: ElementType;
  Wrapper: ElementType;
  Description: ElementType;
};

export const NoticeView: INotice = ({ children, ...rest }) => (
  <Styled.Container {...rest}>
    <Styled.Wrapper>{children}</Styled.Wrapper>
  </Styled.Container>
);

NoticeView.Title = Styled.Title;
NoticeView.Wrapper = Styled.Wrapper;
NoticeView.Description = Styled.Description;
