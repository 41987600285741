import { FunctionComponent } from 'react';
import * as Styled from './AvatarAction.styles';
import { AvatarAppearance, AvatarType } from '../../../definitions';
import Avatar from '../../Avatar';

type AvatarActionProps = {
  actionText: string;
  borderColor?: string;
  appearance?: AvatarAppearance;
  tooltip: string;
};

export const AvatarActionView: FunctionComponent<AvatarActionProps> = ({
  actionText,
  borderColor,
  appearance,
  tooltip,
}) => {
  return (
    <Avatar
      borderColor={borderColor}
      appearance={appearance}
      name={tooltip}
      avatarType={AvatarType.User}
    >
      <Styled.ActionText appearance={appearance}>{actionText}</Styled.ActionText>
    </Avatar>
  );
};
