import { createGlobalStyle } from 'styled-components';
import './fonts/font-face.css';

export const Global = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamily};
  }

  html {
    height: 100%;
  }

  #root,
  body {
    min-height: calc(100vh - ${({ theme }) => theme.constants.topbarHeight});
    color: ${({ theme }) => theme.palettes.main};
    background-color: ${({ theme }) => theme.palettes.inverse};
    position: relative;
    overflow-x: hidden;
  }

  body {
    overflow-y: scroll !important;
  }

  #app {
    display: flex;
    flex-direction: column;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
  }

  textarea:focus, input:focus{
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
      -webkit-transition-delay: 9999s;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    line-height: 1.25;
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.0625rem;
    font-weight: 600;
    line-height: 1.75rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  h4 {
    font-size: 0.9375rem;
    line-height: 1.375;
    font-weight: 500;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25;
    color: ${({ theme }) => theme.palettes.placeholder};
  }

  h6 {
    font-size: 0.875rem;
    font-weight: 200;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
  }

  ul, ol, ul {
    list-style-position: inside;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  button {
    outline: none;
    font-size: 0.875rem;
  }

  a {
    font-size: 0.875rem;
  }

  svg {
    fill: currentColor;
    min-width: 1em;
    min-height: 1em;
  }
`;
