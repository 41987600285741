import { FunctionComponent, ReactElement, ReactNode, useRef, ElementType } from 'react';
import Tooltip from '../Tooltip';
import { AvatarAppearance, colors, theme, User } from '../../definitions';
import { useIsEllipsisActive } from '../../hooks';
import AvatarGroup from '../AvatarGroup/ViewAvatarGroup';
import * as Styled from './Card.styles';

type ICard = FunctionComponent<CardProps> & {
  CardContainer: ElementType;
  CardWrapper: ElementType;
};

type CardProps = {
  title?: string;
  color?: string;
  upperSectionChildren?: ReactNode;
  lowerSectionChildren?: ReactNode;
  onEditClick: () => void;
  isCompact?: boolean;
  owners?: User[];
  ownerActions?: ReactElement[];
};

export const CardView: ICard = ({
  title,
  upperSectionChildren,
  lowerSectionChildren,
  color,
  isCompact,
  owners,
  ownerActions,
  onEditClick,
}) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const shouldDisplayTooltip = useIsEllipsisActive(ref);

  return (
    <Styled.Wrapper color={color} isCompact={isCompact}>
      <Styled.UpperSection>
        <Styled.NameWrapper color={theme.palettes.mutedMain}>
          {shouldDisplayTooltip ? (
            <Tooltip content={title} color={colors.space}>
              <h5 ref={ref} onClick={onEditClick}>
                {title}
              </h5>
            </Tooltip>
          ) : (
            <h5 ref={ref} onClick={onEditClick}>
              {title}
            </h5>
          )}
          {upperSectionChildren && upperSectionChildren}
        </Styled.NameWrapper>
        {owners && (
          <Styled.OwnerContainer>
            <AvatarGroup
              users={owners}
              userActions={ownerActions}
              appearance={AvatarAppearance.Small}
            />
          </Styled.OwnerContainer>
        )}
      </Styled.UpperSection>
      {lowerSectionChildren && <Styled.LowerSection>{lowerSectionChildren}</Styled.LowerSection>}
    </Styled.Wrapper>
  );
};

CardView.CardContainer = Styled.CardContainer;
CardView.CardWrapper = Styled.CardWrapper;
