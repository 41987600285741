import { Activity } from '../../grpc/grpcweb/slo_pb';
import { SLOActivity, SloActivityDataForList } from '../types';
import { SloStatus } from '../constants';

export const getSLOActivityData = (
  activity: SLOActivity,
  activityType: Activity.TypeMap[keyof Activity.TypeMap],
  sloStatuFrom: SloStatus | number,
  sloStatusTo: SloStatus | number,
): string => {
  const changedByUser = activity.triggeredBy === 1;
  if (activityType === Activity.Type.TYPE_SLO_OBJECTIVE_CHANGED) {
    return `${activity.triggeredAtString} - SLO objective changed from ${
      activity.sloObjectiveChanged?.prevobjective
    } to ${activity.sloObjectiveChanged?.currentobjective} by ${
      changedByUser ? activity.triggeredByUser?.username : 'System'
    }.`;
  } else if (activityType === Activity.Type.TYPE_SLO_STATUS_CHANGED) {
    return `${
      activity.triggeredAtString
    } - SLO status changed from ${sloStatuFrom} to ${sloStatusTo} by ${
      changedByUser ? activity.triggeredByUser?.username : 'System'
    }.`;
  }
  return '';
};

export const getSLOActivityDataForList = (
  activity: SLOActivity,
  activityType: Activity.TypeMap[keyof Activity.TypeMap],
  changedFrom: SloStatus | number,
  changedTo: SloStatus | number,
): SloActivityDataForList => {
  const changedByUser = activity.triggeredBy === 1;

  let type;
  if (activityType === Activity.Type.TYPE_SLO_STATUS_CHANGED) {
    type = 'status changed';
  } else if (activityType === Activity.Type.TYPE_SLO_OBJECTIVE_CHANGED) {
    type = 'objective changed';
  } else if (activityType === Activity.Type.TYPE_SLO_BREACH_START) {
    type = 'breach start';
  } else if (activityType === Activity.Type.TYPE_SLO_BREACH_END) {
    type = 'breach end';
  } else {
    type = 'changed';
  }

  return {
    time: activity.triggeredAt.toLocaleString(),
    type,
    from: `from ${changedFrom}`,
    to: `to ${changedTo}`,
    changedBy: `by ${changedByUser ? activity.triggeredByUser?.username : 'System'}`,
  };
};
