import { take, fork, cancel } from 'redux-saga/effects';
import { Store } from 'redux';
import auth from './auth';
import bots from './bots';
import components from './components';
import eventSources from './eventSources';
import flow from './flow';
import modal from './modal';
import notifications from './notifications';
import slos from './slos';
import tags from './tags';
import teams from './teams';
import users from './users';
import xEvents from './xEvents';

const sagas = [
  auth,
  bots,
  components,
  eventSources,
  flow,
  modal,
  notifications,
  slos,
  tags,
  teams,
  users,
  xEvents,
];

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga: any) {
  if (process.env.NODE_ENV === 'development') {
    return function* main() {
      const sagaTask = yield fork(saga);

      yield take(CANCEL_SAGAS_HMR);
      yield cancel(sagaTask);
    };
  }

  return saga;
}

const combinedSagas = {
  startSagas(sagaMiddleware: { run: (saga: any) => void }): void {
    sagas.map(createAbortableSaga).forEach(saga => sagaMiddleware.run(saga));
  },

  cancelSagas(store: Store): void {
    store.dispatch({
      type: CANCEL_SAGAS_HMR,
    });
  },
};

export default combinedSagas;
