import { FunctionComponent, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticated, logout, checkAuth, authenticateWithDemo, isLoadingAuth } from '../../store';
import { usePageTitle } from '../../hooks';
import { LandingView } from './Landing.view';

export const LandingContainer: FunctionComponent = () => {
  usePageTitle('SLO management platform');

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isAuthenticated);
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const isLoadingDemoAccount = useSelector(isLoadingAuth);

  useEffect(() => {
    dispatch(checkAuth({ autoLogin: false }));
  }, [dispatch]);

  const handleDemoLogin = useCallback(
    () => dispatch(authenticateWithDemo()),
    [dispatch],
  );

  return <LandingView isLoggedIn={isLoggedIn} onLogout={handleLogout} isLoadingDemoAccount={isLoadingDemoAccount} onDemoLoginClick={handleDemoLogin} />;
};
