import styled from 'styled-components';
import { rgba } from 'polished';
import { Link } from '../Link';

export const Container = styled.div`
  line-height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 14rem;
  &:hover {
    svg{
      height: 3.5rem;
    }
  }

  ${props => props.theme.media.medium`
  &:hover {
    svg{
      height: 5rem;
    }
  }
`}
`;

export const IconWrapper = styled.div`
  border-radius: 100%;
  height: 5rem;
  width: 5rem;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  background:${props => rgba(props.theme.palettes.inverse, 0.3)};

  svg {
    height: 3rem;
    width: auto;
    transition: ${({ theme }) => theme.effects.transition};
  }

  ${props => props.theme.media.medium`
    height: 7rem;
    width: 7rem;
    svg {
      height: 4rem;
    }
  `}
`;

export const Label = styled.div`
  display: none;
`;

export const LinkIcon = styled(Link)`
&&& {
  svg {
    margin-left: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }
}
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  margin 1rem 0;
`;

export const LoaderWrapper = styled.div`
  margin-left: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  &&&{
    svg {
      margin-left: 0;
    }
  }
`;
