import styled, { css } from 'styled-components';
import { RippleEffect } from '../../RippleEffect';
import { darken } from 'polished';
export { Label } from '../Input/Input.styles';

type WrapperProps = {
  checked?: boolean;
  disabled?: boolean;
  isRadio?: boolean;
};

type BoxProps = {
  isRadio?: boolean;
};

export const Value = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  text-align: left;
  font-size: 0.875rem;
  line-height: 2;
`;

export const Input = styled.input`
  border: 0;
  background: none;
  padding: 0;
  outline: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Box = styled(RippleEffect)<BoxProps>`
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palettes.border};
  box-shadow: ${({ theme }) => theme.effects.inputShadow};
  border-radius: ${props => (props.isRadio ? '100%' : '0.25rem')};

  position: relative;
  display: inline-block;
  align-self: center;
  padding: 0;
  background-color: ${props => props.theme.palettes.inverse};

  &::after {
    content: '';
    position: absolute;
    display: none;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: display 0.1s cubic-bezier(0.68, -0.55, 0.265, 1.55),
      color 0.1s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    ${props =>
      !props.isRadio &&
      css`
        left: 0.425rem;
        top: 0.15rem;
        width: 0.375rem;
        height: 0.75rem;
        border: solid ${props => props.theme.palettes.inverse};
        border-width: 0 3px 3px 0;
      `};

    ${props =>
      props.isRadio &&
      css`
        left: 30%;
        top: 30%;
        bottom: 30%;
        right: 30%;
        border-radius: 100%;
        background-color: ${props => props.theme.palettes.inverse};
      `};
  }
`;

export const Wrapper = styled.label<WrapperProps>`
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex;
  user-select: none;

  &:hover {
    ${Box} {
      background-color: ${props => !props.disabled && props.theme.palettes.mute};
    }
  }

  ${props =>
    props.checked &&
    css`
      ${Box} {
        background-color: ${props.theme.palettes.secondaryHighlight};

        &::after {
          display: flex;
        }
      }

      &:hover {
        ${Box} {
          background-color: ${props.theme.palettes.secondaryHighlight};
        }
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      ${Box} {
        background-color: ${props => darken(0.1, props.theme.palettes.mute)};

        &::after {
          display: flex;
        }
      }

      ${Value} {
        color: ${props.theme.palettes.placeholder};
      }
    `}
`;
