import { all, takeLatest, select, put, AllEffect } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import services from '../../services';
import {
  paths,
  SequenceType,
  request,
  ActionType,
  AlertType,
  AuthErrorType,
  LoginFormData,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { getAuthError, isAuthenticated, getPreviousLocation } from '../selectors';
import { showAlert } from '../actions';

function* authenticate(action: ActionType) {
  const isLoggedIn = yield select(isAuthenticated);
  const redirectUri = yield select(getPreviousLocation);
  const { tenantName, email, password, letMeIn }: LoginFormData & { letMeIn: boolean } =
    action.payload;
  const path = letMeIn ? paths.components : redirectUri;

  if (isLoggedIn) {
    return yield put(push(generatePath(path)));
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.auth.complete },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tenantService, authenticationService }) =>
          tenantService
            .getTenantId(tenantName)
            .then((tenantId: string) =>
              authenticationService.handleAuthentication({ email, tenantId, password }),
            )
            .catch((error: any) => reject(error))
            .then(resolve),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(push(generatePath(path)));
    yield put(showAlert({ type: AlertType.Success, message: `Welcome ${email}!` }));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getAuthError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* authenticateWithDemo(action: ActionType) {
  const isLoggedIn = yield select(isAuthenticated);
  const email =  `udemo${Math.ceil(Math.random()*15)}@example.com`;
  const path = paths.components;

  if (isLoggedIn) {
    return yield put(push(generatePath(path)));
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.auth.complete },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ tenantService, authenticationService }) =>
          tenantService
            .getTenantId('demo')
            .then((tenantId: string) =>
              authenticationService.handleAuthentication({ email, tenantId, password: 'fVM2Rg>GZ;dSFw\\m_48&' }),
            )
            .catch((error: any) => reject(error))
            .then(resolve),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(push(generatePath(path)));
    yield put(showAlert({ type: AlertType.Success, message: `Welcome to demo account!` }));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getAuthError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
};


function* checkAuth(action: ActionType) {
  const isLoggedIn = yield select(isAuthenticated);
  const { autoLogin } = action.payload;

  if (isLoggedIn) {
    return;
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.auth.checked },
    () =>
      new Promise((resolve, reject) => {
        services()
          .then(({ authenticationService }) => authenticationService.getAuth())
          .then(user => {
            if (user) {
              resolve(user.getIdToken());
            }
          })
          .catch((error: { type: AuthErrorType }) => {
            reject(error);
          });
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getAuthError);
    if (autoLogin && errorMessage === AuthErrorType.LOGIN_REQUIRED) {
      yield put(push(generatePath(paths.authenticate)));
      yield put(showAlert({ type: AlertType.Warning, message: `Authentication required.` }));
    }
  }
}

function* logout(action: ActionType) {
  const isLoggedIn = yield select(isAuthenticated);

  if (!isLoggedIn) {
    return;
  }
  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.auth.loggedOut },
    () =>
      new Promise(resolve => {
        services()
          .then(({ authenticationService }) => authenticationService.logout())
          .catch((error: any) => console.log(error))
          .then(resolve);
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(push(generatePath(paths.authenticate)));
    yield put(showAlert({ type: AlertType.Success, message: `User logged out.` }));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getAuthError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

// function* checkAuth(action: ActionType) {
// const isLoggedIn = yield select(isAuthenticated);
// const location = yield select(getLocation);
// const redirectUri = location.pathname + location.search + location.hash;

// if (isLoggedIn) {
//   return;
// }

// yield request(
//   { rootAction: action, type: actionTypes.auth.complete },
//   () =>
//     new Promise((resolve, reject) => {
//       services().then(({ authenticationService }) =>
//         authenticationService
//           .silentLogin()
//           .catch((error: { type: AuthErrorType }) => {
//             if (action?.payload?.autoLogin && error.type === AuthErrorType.LOGIN_REQUIRED) {
//               return authenticationService.login(action?.payload?.redirectUri || redirectUri);
//             }

//             return reject(error);
//           })
//           .then(resolve)
//       );
//     })
// );

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([
    takeLatest(actionTypes.auth.initialise, authenticate),
    takeLatest(actionTypes.auth.initialiseWithDemo, authenticateWithDemo),
    takeLatest(actionTypes.auth.check, checkAuth),
    takeLatest(actionTypes.auth.logout, logout),
  ]);
}
