import { grpc } from '@improbable-eng/grpc-web';
import { SloService } from '../grpc/grpcweb/slo_pb_service';
import { CatalogItemService } from '../grpc/grpcweb/catalog_item_pb_service';
import { CatalogItemType } from '../grpc/grpccommon/common_pb';
import {
  ListCatalogItemsRequest,
  ListCatalogItemsResponse,
} from '../grpc/grpcweb/catalog_item_pb';
import {
  CreateSloRequest,
  CreateSloResponse,
  ListSloRequest,
  ListSloResponse,
  GetSloRequest,
  GetSloResponse,
  UpdateSloRequest,
  UpdateSloResponse,
  LaunchSloResponse,
  LaunchSloRequest,
  DeleteSloRequest,
  DeleteSloResponse,
  GetSloHistoryRequest,
  GetSloHistoryResponse,
  ListActivitiesRequest,
  ListActivitiesResponse,
} from '../grpc/grpcweb/slo_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';
export default class SlosService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  fetchList = async (token: string, fetchSLOListRequest: ListSloRequest) => {
    const request = fetchSLOListRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(SloService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken: token }),
        onEnd: (out: grpc.UnaryOutput<ListSloResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getSlosList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch slo list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchOneSLO = async (RctToken: string, request: GetSloRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(SloService.Get, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetSloResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getSlo());
          } else {
            reject(out.statusMessage);
            console.log(
              `fetch slo failed`,
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchSLOTypes = async (RctToken: string) => {
    const request = new ListCatalogItemsRequest();
    request.setType(CatalogItemType.CATALOG_ITEM_SLO_TYPE);
    return new Promise((resolve, reject) => {
      grpc.unary(CatalogItemService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListCatalogItemsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getCatalogitemsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch slo types failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  createNewSlo = async (RctToken: string, createSlo: CreateSloRequest) => {
    return new Promise((resolve, reject) => {
      const request: CreateSloRequest = createSlo;
      grpc.unary(SloService.Create, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<CreateSloResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'create slo failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  updateSlo = async (RctToken: string, updateSlo: UpdateSloRequest) => {
    return new Promise((resolve, reject) => {
      const request: UpdateSloRequest = updateSlo;
      grpc.unary(SloService.Update, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateSloResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'update slo failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  launchSLO = async (RctToken: string, sloId: string) => {
    const request = new LaunchSloRequest();
    request.setId(sloId);

    return new Promise((resolve, reject) => {
      grpc.unary(SloService.Launch, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<LaunchSloResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'launch slo failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  getSloHistoricValues = async (RctToken: string, sloId: string) => {
    const request = new GetSloHistoryRequest();
    request.setId(sloId);

    return new Promise((resolve, reject) => {
      grpc.unary(SloService.GetHistory, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetSloHistoryResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getRecordsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'get slo history failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  getSloActivity = async (RctToken: string, sloId: string) => {
    const request = new ListActivitiesRequest();
    request.setSloid(sloId);

    return new Promise((resolve, reject) => {
      grpc.unary(SloService.ListActivities, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListActivitiesResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getActivitiesList());
          } else {
            reject(out.statusMessage);
            console.log(
              'get slo activity stream failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteSlo = async (RctToken: string, deleteSlo: DeleteSloRequest) => {
    return new Promise((resolve, reject) => {
      const request: DeleteSloRequest = deleteSlo;
      grpc.unary(SloService.Delete, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteSloResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'delete slo failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
