import { FunctionComponent } from 'react';
import { Appearance, colors } from '../../definitions';
import Button from '../Button';
import { socials, Social } from './socialData';
import * as Styled from './SocialButtons.styles';

export type SocialButtonsProps = {
  color?: string;
};

export const SocialButtonsView: FunctionComponent<SocialButtonsProps> = ({
  color = colors.frost,
}) => {
  return (
    <Styled.Container>
      {socials.map(({ name, path, icon }: Social) => {
        const Icon = icon;
        return (
          <Button appearance={Appearance.Link} to={path} color={color} key={name}>
            <Icon />
            {name}
          </Button>
        );
      })}
    </Styled.Container>
  );
};
