import { ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const fetchEventSourceList = (payload = {}): ActionType => ({
  type: actionTypes.eventSources.fetchList,
  payload,
});

export const updateNewEventSource = (payload = {}): ActionType => ({
  type: actionTypes.eventSources.updateNew,
  payload,
});

export const resetNewEventSource = (payload = {}): ActionType => ({
  type: actionTypes.eventSources.resetNew,
  payload,
});

export const createEventSource = (payload = {}): ActionType => ({
  type: actionTypes.eventSources.create,
  payload,
});

export const updateExistingEventSource = (payload = {}): ActionType => ({
  type: actionTypes.eventSources.update,
  payload,
});

export const fetchEventSource = (payload = {}): ActionType => ({
  type: actionTypes.eventSources.fetchOne,
  payload,
});

export const deleteEventSource = (payload = {}): ActionType => ({
  type: actionTypes.eventSources.delete,
  payload,
});
