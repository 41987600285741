import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0.25rem 0;
  max-width: 100%;

  button {
    min-width: 1.5rem;
    width: 1.5rem;
  }
`;

export const AvatarWrapper = styled.div`
  flex: 1;
  max-width: calc(100% - 3rem);
  display: flex;
  flex-direction: row;
`;
