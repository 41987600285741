import { FunctionComponent, ReactNode } from 'react';
import { generatePath } from 'react-router';
import { AvatarType, TeamEnrichedComponentRefInTheList, paths } from '../../definitions';
import { TagView } from '../../grpc/grpcweb/tag_pb';
import List from '../../components/List';
import Pagination from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import AvatarWithTitle from '../../components/AvatarWithTitle';
import { Tag, TagContainer } from '../../components/Tag';
import { Link } from '../../components/Link';

export type ComponentListProps = {
  isFetching: boolean;
  componentList: TeamEnrichedComponentRefInTheList[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfComponents: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  action?: ReactNode;
  actionTitle?: ReactNode | string;
  isSelectable?: boolean;
  isTeamDataIncluded?: boolean;
  onViewComponent?: (shortname?: string) => void;
};

export const ComponentListView: FunctionComponent<ComponentListProps> = ({
  isFetching,
  componentList,
  onNextPage,
  onPrevPage,
  numberOfComponents,
  isPrevPageDisabled,
  isNextPageDisabled,
  action,
  actionTitle,
  isSelectable = false,
  isTeamDataIncluded = false,
  onViewComponent,
}) => {
  const getColumnTitles = () => {
    let columns: Array<string | ReactNode> = ['Name', 'Type'];
    isSelectable && columns.unshift(actionTitle);
    isTeamDataIncluded && columns.push('Tags', 'Team');
    !isSelectable && columns.push('Updated');

    return columns;
  };

  const UpdatedMeta: FunctionComponent<TeamEnrichedComponentRefInTheList> = ({
    updatedAt,
    updatedBy,
  }) => (
    <span key="updated-meta">
      Updated on {updatedAt?.toLocaleDateString()} by {updatedBy}
    </span>
  );

  const ComponentAvatar: FunctionComponent<TeamEnrichedComponentRefInTheList> = ({
    avatar,
    displayName,
    shortname,
  }) => (
    <AvatarWithTitle
      name={displayName || shortname || 'Component'}
      avatarType={AvatarType.Component}
      avatarSrc={avatar}
      onNameClick={onViewComponent ? () => onViewComponent(shortname) : undefined}
    />
  );

  const TagList: FunctionComponent<TeamEnrichedComponentRefInTheList> = ({ tagList }) => {
    return (
      <TagContainer>
        {tagList.map((tag: TagView.AsObject) => (
          <Tag value={tag.value} key={tag.tagid} tagKey={tag.displayname} />
        ))}
      </TagContainer>
    );
  };

  const Team: FunctionComponent<TeamEnrichedComponentRefInTheList> = ({ team }) => {
    if (team) {
      const path = generatePath(paths.team, { teamShortname: team?.shortname });
      return <Link to={path}>{team.displayname || team.shortname}</Link>;
    }

    return null;
  };

  const getColumns = () => {
    let columns: Array<string | ReactNode> = [ComponentAvatar, 'type'];
    if (action) {
      action ? columns.unshift(action) : columns.unshift('');
    }
    isTeamDataIncluded && columns.push(TagList, Team);
    !isSelectable && columns.push(UpdatedMeta);

    return columns;
  };

  const getHideOnSmall = () => {
    let columns: Array<string | ReactNode> = [];
    isTeamDataIncluded && columns.push(TagList);
    !isSelectable && columns.push(UpdatedMeta);

    return columns;
  };

  return (
    <Layout.Section>
      <List
        items={componentList}
        columnTitles={getColumnTitles()}
        columns={getColumns()}
        hideOnSmall={getHideOnSmall()}
        itemKey="id"
        isLoading={isFetching}
        emptyText="You don't have any components yet. Start by creating one."
      />
      <Pagination totalItems={numberOfComponents}>
        <Pagination.NextPrev
          onNextClick={onNextPage}
          onPrevClick={onPrevPage}
          isPrevDisabled={isPrevPageDisabled}
          isNextDisabled={isNextPageDisabled}
        />
      </Pagination>
    </Layout.Section>
  );
};
