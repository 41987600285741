const url = (value: string): string => {
  if (!value) {
    return value;
  }

  const url = value.replace(/[|&;$%@"<>()+,]/g, '').trim();

  if (!url.startsWith('http')) {
    return `https://${url}`;
  }

  return url;
};

export default url;
