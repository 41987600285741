import { FunctionComponent } from 'react';
import * as Styled from './Overlay.styles';

export type OverlayProps = {
  isVisible?: boolean;
  onClick?: () => void;
};

export const OverlayView: FunctionComponent<OverlayProps> = ({
  onClick = () => {},
  isVisible = false,
}) => {
  if (isVisible) {
    return <Styled.Container onClick={onClick} />;
  }

  return null;
};
