import { FunctionComponent, MouseEvent } from 'react';
import { Link } from '../Link';
import Avatar from '../Avatar';
import { AvatarType, colors } from '../../definitions';
import * as Styled from './Comment.styles';

type CommentProps = {
  onClick?: (e: MouseEvent) => void;
  avatarSrc?: string;
  author: string;
  text?: string;
  addedAt: string;
};

export const CommentView: FunctionComponent<CommentProps> = ({
  onClick,
  avatarSrc,
  author,
  text,
  addedAt,
  ...rest
}) => {
  return (
    <Styled.Wrapper>
      <Styled.AvatarWrapper>
        <Avatar name={author} avatarSrc={avatarSrc} avatarType={AvatarType.User} />
      </Styled.AvatarWrapper>
      <Styled.CommentSection>
        <Styled.Meta>
          {author}
          {`,  `}
          {addedAt}
        </Styled.Meta>
        <p>{text}</p>
        <Styled.Actions>
          <Link color={colors.silver}>Reply</Link>
          <Styled.Divider>·</Styled.Divider>
          <Link color={colors.silver}>Edit</Link>
          <Styled.Divider>·</Styled.Divider>
          <Link color={colors.silver}>Like</Link>
        </Styled.Actions>
      </Styled.CommentSection>
    </Styled.Wrapper>
  );
};
