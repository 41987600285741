import styled from 'styled-components';
import { motion } from 'framer-motion';

export const PopupContent = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 5rem;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TriggerWrapper = styled.div``;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  max-width: 20rem;
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palettes.placeholder} 50%, ${theme.palettes.inverse} 0%)`};
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  padding: 1rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.palettes.inverse};
`;

export const ActionWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-end;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;
