import { FunctionComponent } from 'react';
import { ButtonGroup } from '../ButtonGroup';
import { Notice } from '../Notice';
import Button from '../Button';
import * as Styled from './AuthenticationError.styles';

type AuthenticationErrorProps = {
  error?: string;
  showLogout?: boolean;
  onLogout?: () => void;
};

export const AuthenticationErrorView: FunctionComponent<AuthenticationErrorProps> = ({
  error,
  showLogout = false,
  onLogout = () => {},
  ...rest
}) => (
  <Notice {...rest}>
    <Notice.Title>
      Authentication Failed.
      {error && <Styled.Error>{error}</Styled.Error>}
    </Notice.Title>
    <Notice.Description>
      Try refreshing the page, or click the back arrow and try again. If you continue to experience
      issues, please contact us.
    </Notice.Description>
    <ButtonGroup stack={false} reverse={true}>
      {showLogout ? <Button onClick={onLogout}>Logout</Button> : <Button to="/">Go Home</Button>}
    </ButtonGroup>
  </Notice>
);
