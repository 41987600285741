import { FunctionComponent } from 'react';
import { ComponentRefInTheListWithDependencyState } from '../../definitions';
import ComponentList from '../../lists/ComponentList';
import TableMeta from '../../components/TableMeta';
import { Checkbox, BulkSelect } from '../../components/BulkSelect';

type LinkDependencyTableProps = {
  isFetching: boolean;
  componentList: ComponentRefInTheListWithDependencyState[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfComponents: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onCheckComponentCheckbox: (component: Record<string, boolean>) => void;
  numberOfSelectedComponents: number;
  onClearSelectedComponents: () => void;
  disabledComponentIds: Set<string>;
  selectedDependencyState: Record<string, boolean>;
};

export const LinkDependencyTableView: FunctionComponent<LinkDependencyTableProps> = ({
  isFetching,
  selectedDependencyState,
  componentList,
  disabledComponentIds,
  onNextPage,
  onPrevPage,
  onCheckComponentCheckbox,
  numberOfSelectedComponents,
  numberOfComponents,
  isPrevPageDisabled,
  isNextPageDisabled,
  onClearSelectedComponents,
}) => {
  const SelectBox = (component: ComponentRefInTheListWithDependencyState) => (
    <Checkbox
      id={component.id}
      selectedState={selectedDependencyState}
      disabledIdMap={disabledComponentIds}
      onChange={onCheckComponentCheckbox}
    />
  );
  const BulkSelectBox = () => (
    <BulkSelect
      items={componentList}
      selectedState={selectedDependencyState}
      disabledIdMap={disabledComponentIds}
      onChange={onCheckComponentCheckbox}
    />
  );

  return (
    <>
      <TableMeta
        title={`Components selected: ${numberOfSelectedComponents}`}
        onActionClick={onClearSelectedComponents}
      />
      <ComponentList
        componentList={componentList}
        isFetching={isFetching}
        numberOfComponents={numberOfComponents > 0 ? numberOfComponents - 1 : 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
        action={SelectBox}
        actionTitle={BulkSelectBox}
        isSelectable={true}
      />
    </>
  );
};
