import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatedEventSourceSuccessfully,
  isUpdatingEventSource,
  updateExistingEventSource,
  getSelectedEventSource,
  isFetchingEventSource,
  isDeletingEventSource,
  deleteEventSource,
  deletedEventSourceSuccessfully,
} from '../../store';
import { EditSourceFormData } from '../../definitions';
import { EditEventSourceView } from './EditEventSource.view';

type EditEventSourceContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const EditEventSourceContainer: FunctionComponent<EditEventSourceContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isUpdating = useSelector(isUpdatingEventSource);
  const selectedSource = useSelector(getSelectedEventSource);
  const isFetching = useSelector(isFetchingEventSource);
  const isDeleting = useSelector(isDeletingEventSource);

  const finishedUpdating = useSelector(updatedEventSourceSuccessfully);
  const finishedDeleting = useSelector(deletedEventSourceSuccessfully);

  const handleDeleteSource = useCallback(
    (sourceId?: string, displayName?: string) => {
      dispatch(deleteEventSource({ displayName, sourceId }));
    },
    [dispatch],
  );

  const handleUpdateEventSource = useCallback(
    (formData: EditSourceFormData) => {
      dispatch(updateExistingEventSource({ formData, sourceId: formData.id }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (finishedUpdating || finishedDeleting) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedUpdating, finishedDeleting]);

  return (
    <EditEventSourceView
      isOpen={isOpen}
      onClose={onClose}
      isUpdating={isUpdating}
      onUpdateEventSource={handleUpdateEventSource}
      initialValues={selectedSource}
      isFetching={isFetching}
      onDelete={handleDeleteSource}
      isDeleting={isDeleting}
    />
  );
};
