import { FunctionComponent } from 'react';
import { darken } from 'polished';
import { Form, Field } from 'react-final-form';
import {
  validation,
  parsers,
  Token,
  Appearance,
  BannerAppearance,
  theme,
  TokenInTheList,
} from '../../definitions';
import { Input, FormLayout, Textarea } from '../../components/Form';
import Button from '../../components/Button';
import Banner from '../../components/Banner';
import { Icon } from '../../components/Icon';
import PopConfirm from '../../components/PopConfirm';
import { CopyText } from '../../components/CopyText';
import * as Styled from './TokenForm.styled';

type TokenFormProps = {
  isLoading: boolean;
  onCancel: () => void;
  initialValues?: Token | TokenInTheList;
  isDeletingToken: boolean;
  onDeleteToken: () => void;
  isNewToken: boolean;
};

export const TokenFormView: FunctionComponent<TokenFormProps> = ({
  isLoading,
  initialValues = undefined,
  onCancel,
  isDeletingToken,
  onDeleteToken,
  isNewToken,
  children,
}) => {
  const CopyTokenBanner: FunctionComponent = () => (
    <Banner appearance={BannerAppearance.Warning}>
      <Styled.BannerContent>
        <p>We will show this token only once. Please copy token and store it in secure place.</p>
      </Styled.BannerContent>
    </Banner>
  );

  return (
    <Form
      autoComplete="off"
      onSubmit={() => {}}
      initialValues={initialValues}
      render={() => {
        return (
          <>
            <FormLayout.FieldSet>
              <Field
                id="id"
                name="id"
                label="API token id"
                component={Input}
                validate={value => validation.required(value)}
                format={parsers.trim}
                formatOnBlur={true}
                maxLength="70"
                disabled={true}
                description="Unique API Token identificator. Every request made using this API Token will be matched to this token ID."
              />
              {isNewToken && (
                <>
                  <Field
                    id="token"
                    name="token"
                    label="API token"
                    component={Textarea}
                    format={parsers.trim}
                    formatOnBlur={true}
                    disabled={true}
                    maxLength="70"
                    description={<CopyTokenBanner />}
                  />
                  <Styled.CopyButtonContainer>
                    <CopyText
                      title="Copy"
                      text={initialValues?.token || ''}
                      color={darken(0.2, theme.palettes.highlight)}
                    />
                  </Styled.CopyButtonContainer>
                </>
              )}
            </FormLayout.FieldSet>
            {children}
            <FormLayout.ModalPreFooterSpacer />
            <FormLayout.ModalFooter>
              <PopConfirm
                title={
                  isNewToken
                    ? `Are you sure you want to delete the token you have just created?`
                    : `Are you sure you want to delete selected API token?`
                }
                onConfirm={onDeleteToken}
                isConfirming={isDeletingToken}
                isDisabled={isDeletingToken}
                contentText={
                  isNewToken
                    ? 'If the token was created by mistake, you can delete it. Alternatively, you can remove the token by accessing its ID from the bot list.'
                    : "Please note that once the token is deleted, the bot that had access to it will no longer be able to use it, and you won't have the option to restore the deleted token."
                }
                confirmColor={theme.palettes.error}
              >
                <Button isDisabled={isLoading} appearance={Appearance.PrimaryWithIcon}>
                  <Icon.Trash />
                  <span>Delete</span>
                </Button>
              </PopConfirm>

              <Button
                onClick={onCancel}
                isDisabled={isLoading}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Cross />
                <span>Close</span>
              </Button>
            </FormLayout.ModalFooter>
          </>
        );
      }}
    />
  );
};
