import { FunctionComponent, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLayer } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { MediaContext } from '../../contexts';
import { Link } from '../Link';
import { Icon } from '../Icon';
import { Route, paths, AvatarAppearance, theme, AvatarType } from '../../definitions';
import { ButtonItem, SecondaryButtonItem } from './Items';
import NavigationLink from './NavigationLink';
import { Drawer } from '../Drawer';
import Avatar from '../Avatar';
import * as Styled from './Sidebar.styles';

export type SidebarProps = {
  isVisible: boolean;
  onHide: () => void;
  onLogout: () => void;
  navigationRoutes: Route[];
  isLoggedIn: boolean;
  isPersistentOnMedium?: boolean;
  onItemClick?: (path: string) => void;
  activePath?: string;
  tenantName?: string;
};

export const SidebarView: FunctionComponent<SidebarProps> = ({
  isVisible,
  onHide,
  onLogout,
  navigationRoutes,
  isLoggedIn,
  children,
  isPersistentOnMedium = false,
  onItemClick,
  activePath,
  tenantName,
}) => {
  const history = useHistory();
  const handleLogin = () => history.push(paths.authenticate);
  const { isMediumLayout, isLargeLayout } = useContext(MediaContext);

  const [isOrgMenuOpen, setOrgMenuOpen] = useState(false);
  const handleClose = () => {
    setOrgMenuOpen(false);
  };
  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: isOrgMenuOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: isMediumLayout && !isLargeLayout, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-start', // we prefer to place the menu "top-end"
    triggerOffset: 16, // keep some distance to the trigger
    containerOffset: 8, // give the menu some room to breath relative to the container
  });

  const sideBarWidth = useMemo(() => {
    if (isLargeLayout) {
      return theme.constants.largeSidebarWidth;
    } else if (isMediumLayout) {
      return theme.constants.mediumSidebarWidth;
    }

    return isPersistentOnMedium ? '100%' : theme.constants.largeSidebarWidth;
  }, [isLargeLayout, isPersistentOnMedium, isMediumLayout]);

  return (
    <Drawer
      onHideMenu={onHide}
      visible={
        (isPersistentOnMedium && isLargeLayout) ||
        (isMediumLayout && isPersistentOnMedium) ||
        isVisible
      }
      fullScreenOnSmall={true}
      width={sideBarWidth}
      zIndex={theme.zIndexes.sideBar}
    >
      {(isLargeLayout || isMediumLayout) && (
        <Styled.NavHeader>
          <Link to={paths.landing}>
            {isLargeLayout ? <Icon.LogoWithText width="5rem" /> : <Icon.Logo width="2.5rem" />}
          </Link>
          {children}
        </Styled.NavHeader>
      )}
      {navigationRoutes.map(({ path, label, icon }) => (
        <NavigationLink
          path={path}
          key={path}
          label={label}
          icon={icon}
          onItemClick={onItemClick}
          activePath={activePath}
        />
      ))}
      <Styled.ResponsiveNavLogin>
        {isLoggedIn ? (
          isLargeLayout || isMediumLayout ? (
            <>
              {isMediumLayout && !isLargeLayout ? (
                <Styled.AvatarAsATrigger {...triggerProps}>
                  <Avatar
                    name=""
                    appearance={AvatarAppearance.Small}
                    hasBorder={false}
                    avatarType={AvatarType.User}
                    onClick={() => setOrgMenuOpen(!isOrgMenuOpen)}
                  />
                </Styled.AvatarAsATrigger>
              ) : (
                <ButtonItem
                  isPopupTrigger={true}
                  title={tenantName || 'Organisation'}
                  icon={Icon.Organisation}
                  onItemClick={() => setOrgMenuOpen(!isOrgMenuOpen)}
                  {...triggerProps}
                >
                  <Styled.AvatarWrapper>
                    <Avatar
                      name=""
                      appearance={AvatarAppearance.Small}
                      hasBorder={false}
                      avatarType={AvatarType.User}
                    />
                  </Styled.AvatarWrapper>
                </ButtonItem>
              )}

              {renderLayer(
                <AnimatePresence>
                  {isOrgMenuOpen && (
                    <Styled.MotionDiv {...layerProps}>
                      <SecondaryButtonItem
                        title="Sign out"
                        icon={Icon.Login}
                        onItemClick={onLogout}
                      />
                      <Styled.Separator role="separator" />
                      <SecondaryButtonItem
                        title={tenantName || 'Organisation'}
                        icon={Icon.Organisation}
                      />
                    </Styled.MotionDiv>
                  )}
                </AnimatePresence>,
              )}
            </>
          ) : (
            <ButtonItem title="Sign out" icon={Icon.Login} onItemClick={onLogout} />
          )
        ) : (
          <ButtonItem title="Sign in" icon={Icon.Login} onItemClick={handleLogin} />
        )}
      </Styled.ResponsiveNavLogin>
    </Drawer>
  );
};
