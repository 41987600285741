import { ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const fetchXEventList = (payload: {
  componentId: string;
  pageSize?: number;
}): ActionType => ({
  type: actionTypes.xEvents.fetchList,
  payload,
});

export const fetchNextXEventListPage = (payload: {
  componentId: string;
  pageSize?: number;
}): ActionType => ({
  type: actionTypes.xEvents.fetchNextPage,
  payload,
});

export const fetchPrevXEventListPage = (payload: {
  componentId: string;
  pageSize?: number;
}): ActionType => ({
  type: actionTypes.xEvents.fetchPrevPage,
  payload,
});
