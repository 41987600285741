import styled from 'styled-components';
import { motion } from 'framer-motion';
import { darken, rgba } from 'polished';

type TriggerWrapperProps = {
  hasNotifications: boolean;
};

type ScrollContainerProps = {
  isBorderBottom: boolean;
  isBorderTop: boolean;
};

export const Header = styled.h3`
  color: ${props => props.theme.palettes.main};
`;

export const TriggerWrapper = styled.div<TriggerWrapperProps>`
  display: flex;
  align-items: center;
  svg {
    height: 2rem;
    width: 2rem;
    fill: ${props =>
      props.hasNotifications
        ? rgba(props.theme.palettes.highlight, 0.8)
        : darken(0.2, props.theme.palettes.inverse)};
    cursor: pointer;

    &:hover {
      text-decoration: none;
      fill: ${props =>
        props.hasNotifications ? props.theme.palettes.highlight : props.theme.palettes.inverse};
    }
  }
`;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  background: ${({ theme }) => theme.palettes.inverse};
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  max-height: 20rem;
  width: 25rem;
  max-width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;

  h3 {
    padding: 1rem;
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const ScrollContainer = styled.div<ScrollContainerProps>`
  height: 13rem;
  overflow-y: auto;
  box-shadow: ${props => {
    const { isBorderBottom, isBorderTop, theme } = props;
    if (isBorderBottom && isBorderTop) {
      return `${theme.effects.shadowBottom}, ${theme.effects.shadowTop}`;
    }
    if (isBorderBottom) {
      return theme.effects.shadowBottom;
    }
    if (isBorderTop) {
      return theme.effects.shadowTop;
    }
    return null;
  }};
`;

export const MarkAsReadButton = styled.div`
  justify-content: flex-end;
  display: flex;
  padding: 0.5rem;
`;

export const StateManagerWrapper = styled.div`
  height: 13rem;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 1rem;
  div {
    max-height: 10rem;
  }
`;
