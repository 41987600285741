import { SequenceType, ActionType } from '../../definitions';

export type CacheState = {
  [k: string]: number | null;
};

const cache = (state: CacheState = {}, { rootAction, sequence }: ActionType): CacheState => {
  if (!rootAction || !rootAction.type) {
    return state;
  }

  switch (sequence) {
    case SequenceType.Success:
      return {
        ...state,
        [rootAction.type]: Date.now(),
      };

    case SequenceType.Start:
    case SequenceType.Error:
    case SequenceType.Cancel:
      return {
        ...state,
        [rootAction.type]: null,
      };

    default:
      return state;
  }
};

export default cache;
