import { FunctionComponent, ReactNode } from 'react';
import { Icon } from '../Icon';
import { AvatarAppearance, AvatarType } from '../../definitions';
import * as Styled from './Avatar.styles';

type AvatarProps = {
  avatarSrc?: string;
  name: string;
  onClick?: () => void;
  appearance?: AvatarAppearance;
  hasBorder?: boolean;
  borderColor?: string;
  avatarType: AvatarType;
};

export const AvatarView: FunctionComponent<AvatarProps> = ({
  avatarSrc,
  name,
  onClick,
  appearance = AvatarAppearance.Medium,
  hasBorder = true,
  borderColor,
  avatarType = AvatarType.User,
}) => {
  const getAvatarIcon = (avatarType: AvatarType): ReactNode => {
    switch (avatarType) {
      case AvatarType.User:
        return <Icon.User />;
      case AvatarType.System:
        return <Icon.Robot />;
      case AvatarType.Team:
        return <Icon.TeamAvatar />;
      case AvatarType.Component:
        return <Icon.Chip />;
      case AvatarType.Link:
        return <Icon.Url />;
    }
  };
  return (
    <Styled.Avatar
      title={name}
      onClick={onClick && onClick}
      isDisabled={!onClick}
      appearance={appearance}
      hasBorder={hasBorder}
      borderColor={borderColor}
    >
      {getAvatarIcon(avatarType)}
    </Styled.Avatar>
  );
};
