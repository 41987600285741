import { FunctionComponent, useState, ElementType } from 'react';
import { Notification } from '../../grpc/grpcweb/notification_pb';
import { MediaContext } from '../../contexts';
import { useMedia } from '../../hooks';
import Topbar from '../../components/Topbar';
import { Layout } from '../../components/Layout';
import NotificationPopup from '../../components/NotificationPopup';
import { TopbarMode } from '../../definitions';
import { Sidebar } from '../../components/Sidebar';
import { navigationRoutes } from './navigationRoutes';

type AuthenticatedLayoutProps = {
  onLogout: () => void;
  notifications: Notification.AsObject[];
  isLoadingNotifications: boolean;
  isClearingNotifications: boolean;
  onClearNotifications: (dateStamp: number) => void;
  header?: ElementType;
  metaPanel?: ElementType;
  tenantName: string;
};

export const AuthenticatedLayoutView: FunctionComponent<AuthenticatedLayoutProps> = ({
  children,
  onLogout,
  notifications,
  isLoadingNotifications,
  isClearingNotifications,
  onClearNotifications,
  metaPanel,
  header,
  tenantName,
  ...rest
}) => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const isMediumLayout = useMedia({ medium: true }, false);
  const isLargeLayout = useMedia({ large: true }, false);

  return (
    <MediaContext.Provider value={{ isLargeLayout, isMediumLayout }}>
      <Sidebar
        onHide={() => setSidebarVisibility(false)}
        isVisible={isSidebarVisible}
        onLogout={onLogout}
        isLoggedIn={true}
        navigationRoutes={navigationRoutes}
        isPersistentOnMedium={true}
        tenantName={tenantName}
        {...rest}
      >
        {/* <NotificationPopup
          notifications={notifications}
          isLoading={isLoadingNotifications}
          isClearing={isClearingNotifications}
          onClear={onClearNotifications}
        /> */}
      </Sidebar>
      <div onClick={() => isSidebarVisible && setSidebarVisibility(false)}>
        <Topbar
          mode={TopbarMode.Portal}
          onLogout={onLogout}
          isSidebarVisible={isSidebarVisible}
          routes={navigationRoutes}
          setSidebarVisible={setSidebarVisibility}
        >
          <NotificationPopup
            notifications={notifications}
            isLoading={isLoadingNotifications}
            isClearing={isClearingNotifications}
            onClear={onClearNotifications}
          />
        </Topbar>
        <Layout isSidebarPersistentOnMedium={true} header={header} metaPanel={metaPanel}>
          {children}
        </Layout>
      </div>
    </MediaContext.Provider>
  );
};
