import styled from 'styled-components';
import { Image } from '../../components/Image';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.palettes.main};
  color: ${({ theme }) => theme.palettes.inverse};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  ${props => props.theme.media.medium`
    flex-direction: row;
  `}

  p {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    svg {
      width: 1.5rem;
      margin-left: 0.5rem;
    }
  }
`;

export const NotFoundImage = styled(Image).attrs({
  name: 'notFound',
  alt: 'astronaut lost in space',
})`
  width: 50%;
  height: auto;
  min-width: 20rem;
  max-width: 30rem;
  margin: 0;
`;
