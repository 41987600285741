import styled, { css } from 'styled-components';

type ContainerProps = {
  reverse?: boolean;
  sticky?: boolean;
  stack?: boolean;
  compact?: boolean;
};

export const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  width: 100%;
  display: flex;
  opacity: 1;
  transition: opacity 200ms ease-out;
  margin: 2rem auto 1rem;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};

  > * + * {
    margin-${props => (props.reverse ? 'bottom' : 'top')}: 1rem;
  }

  p {
    padding-top: 1rem;
    margin-bottom: 0;
    text-align: center !important;
  }

  ${props =>
    props.sticky &&
    css`
      position: sticky;
      bottom: 0;
    `};

  ${props => props.theme.media.medium`
    ${
      props.sticky &&
      css`
        position: relative;
      `
    };

    ${
      !props.stack &&
      `
      flex-direction: ${props.reverse ? 'row-reverse' : 'row'};

      > * + * {
        margin-top: 0;
        margin-bottom: 0;
        margin-${props.reverse ? 'right' : 'left'}: 1rem;
        max-width: auto;
      }
    }`
    }
  `}

  ${props =>
    props.compact &&
    css`
    flex-direction: ${props.reverse ? 'row-reverse' : 'row'};

    > * + * {
      margin-top: 0;
      margin-bottom: 0;
      margin-${props.reverse ? 'right' : 'left'}: 1rem;
      max-width: auto;
    }
`}
`;

Container.defaultProps = {
  stack: true,
};
