import { FunctionComponent } from 'react';
import { Appearance, AvatarAppearance, AvatarType } from 'src/definitions';
import AvatarWithTitle from '../AvatarWithTitle';
import Button from '../Button';
import * as Styled from './Url.styles';
import { Icon } from '../Icon';

export type UrlProps = {
  avatar?: string;
  url?: string;
  displayName: string;
  onEditClick: () => void;
};

export const UrlView: FunctionComponent<UrlProps> = ({ avatar, url, displayName, onEditClick }) => {
  return (
    <Styled.Container>
      <Styled.AvatarWrapper>
        <AvatarWithTitle
          avatarType={AvatarType.Link}
          avatarAppearance={AvatarAppearance.Small}
          name={displayName}
          onNameClick={() => window.open(url, '_blank')}
          avatarSrc={avatar}
        ></AvatarWithTitle>
      </Styled.AvatarWrapper>
      <Button appearance={Appearance.Icon} title="Edit" onClick={onEditClick}>
        <Icon.Edit />
      </Button>
      <Button
        appearance={Appearance.Icon}
        title="Copy"
        onClick={() => url && window.navigator.clipboard.writeText(url)}
      >
        <Icon.Copy />
      </Button>
    </Styled.Container>
  );
};
