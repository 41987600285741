import { FunctionComponent, ReactElement, ElementType } from 'react';
import { ComponentRefInTheList, TeamMap } from '../../definitions';
import { useGroupComponentsByTeamName } from '../../hooks';
import ComponentCard from '../ComponentCard';
import { Loader } from '../Loader';
import { Empty } from '../Empty';
import * as Styled from './GrouppedComponents.styles';

export type GrouppedComponentsProps = {
  isLoading: boolean;
  components: ComponentRefInTheList[];
  teamMap: TeamMap;
  emptyStateText: string;
  onNameClick: (component: ComponentRefInTheList) => void;
  widgetAction?: ElementType<ComponentRefInTheList>;
  emptyActions?: ReactElement[];
};

export const GrouppedComponentsView: FunctionComponent<GrouppedComponentsProps> = ({
  isLoading,
  components,
  teamMap,
  emptyStateText,
  onNameClick,
  widgetAction,
  emptyActions,
}) => {
  const grouppedComponents = useGroupComponentsByTeamName(components, teamMap);
  const Action: ElementType | undefined = widgetAction;

  return (
    <Styled.Wrapper>
      {isLoading ? (
        <Loader.Container>
          <Loader />
        </Loader.Container>
      ) : Object.keys(grouppedComponents).length ? (
        Object.keys(grouppedComponents).map((teamName: string) => {
          return (
            <Styled.Group key={teamName}>
              <h4>{teamName}</h4>
              <ComponentCard.Container>
                {grouppedComponents[teamName].map((component: ComponentRefInTheList) => (
                  <ComponentCard.Wrapper key={component.id}>
                    <ComponentCard
                      onNameClick={() => onNameClick(component)}
                      component={component}
                      componentId={component.id}
                      widgetActions={
                        Action ? [<Action {...component} key="widget-action-btn" />] : []
                      }
                    />
                  </ComponentCard.Wrapper>
                ))}
              </ComponentCard.Container>
            </Styled.Group>
          );
        })
      ) : (
        <Empty text={emptyStateText}>{emptyActions}</Empty>
      )}
    </Styled.Wrapper>
  );
};
