import styled from 'styled-components';
import { motion } from 'framer-motion';

type ActionWrapperProps = {
  shouldStackActions?: boolean;
};

export const Header = styled.div`
  background: ${({ theme }) => theme.palettes.headerBackground};
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  max-height: 4rem;
  width: 100%;
`;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  max-width: 20rem;
  background: ${({ theme }) => theme.palettes.inverse};
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  justify-content: space-between;
  color: ${({ theme }) => theme.palettes.inverse};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  min-width: 15rem;
`;

export const ActionWrapper = styled.div<ActionWrapperProps>`
  display: flex;
  flex-direction: ${props => (props.shouldStackActions ? 'column' : 'row')};
  justify-content: flex-end;
  padding: 1rem;
  gap: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1rem 0 1rem;
  gap: 0.5rem;
  color: ${({ theme }) => theme.palettes.main};
`;

export const Name = styled.h5`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin: 0;
  color: inherit;
  font-size: 0.9375rem;
  cursor: pointer;
  color: ${props => props.theme.palettes.main};
  display: inline-block;
`;
