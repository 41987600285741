import { FunctionComponent, MouseEvent, PropsWithoutRef } from 'react';
import { Appearance } from '../../definitions';
import { Loader } from '../Loader';
import * as Styled from './Button.styles';

export type ButtonProps = PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  to?: string;
  onClick?: (e: MouseEvent) => void;
  color?: string;
  rippleColor?: string;
  isPending?: boolean;
  isHidden?: boolean;
  appearance?: Appearance;
  isDisabled?: boolean;
  isInverseHover?: boolean;
  isHighlighted?: boolean;
};

export const ButtonView: FunctionComponent<ButtonProps> = ({
  children,
  to = undefined,
  onClick = undefined,
  isPending = false,
  isHidden = false,
  appearance = Appearance.Primary,
  rippleColor,
  isDisabled = false,
  isInverseHover,
  isHighlighted = false,
  ...rest
}) => {
  return (
    <Styled.Container
      isDisabled={isPending || isHidden || isDisabled}
      isInverseHover={isInverseHover}
      appearance={appearance}
      disabled={isDisabled}
      isHidden={isHidden}
      to={to}
      onClick={onClick}
      isHighlighted={isHighlighted}
      isPending={isPending}
      {...rest}
    >
      <Styled.Wrapper color={rippleColor}>
        <Styled.Content isPending={isPending} appearance={appearance}>
          {isPending && (
            <Styled.LoaderWrapper isHighlighted={isHighlighted}>
              <Loader />
            </Styled.LoaderWrapper>
          )}
          {children}
        </Styled.Content>
      </Styled.Wrapper>
    </Styled.Container>
  );
};
