import { FunctionComponent } from 'react';
import Tooltip from '../../Tooltip';
import { Icon } from '../../Icon';
import { colors } from '../../../definitions';
import * as Styled from './InputTooltip.styles';

type InputTooltipProps = {
  content: string;
};

export const InputTooltipView: FunctionComponent<InputTooltipProps> = ({ content }) => {
  return (
    <Styled.TooltipWrapper>
      <Tooltip content={content} color={colors.space}>
        <Icon.Help />
      </Tooltip>
    </Styled.TooltipWrapper>
  );
};
