// package: rct.web
// file: grpcweb/xevent.proto

var grpcweb_xevent_pb = require("../grpcweb/xevent_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var XEventsService = (function () {
  function XEventsService() {}
  XEventsService.serviceName = "rct.web.XEventsService";
  return XEventsService;
}());

XEventsService.ListByComponent = {
  methodName: "ListByComponent",
  service: XEventsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_xevent_pb.ListEventsByComponentRequest,
  responseType: grpcweb_xevent_pb.ListEventsByComponentResponse
};

exports.XEventsService = XEventsService;

function XEventsServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

XEventsServiceClient.prototype.listByComponent = function listByComponent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(XEventsService.ListByComponent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.XEventsServiceClient = XEventsServiceClient;

