import { FunctionComponent, useLayoutEffect, useContext } from 'react';
import Button from '../Button';
import { Icon } from '../Icon';
import {
  Appearance,
  paths,
  TopbarMode,
  Route,
  AvatarAppearance,
  AvatarType,
} from '../../definitions';
import * as Styled from './Topbar.styles';
import Avatar from '../Avatar';
import { MediaContext } from 'src/contexts';

export type TopbarProps = {
  isLoggedIn?: boolean;
  mode?: TopbarMode;
  onLogout?: () => void;
  isSidebarVisible?: boolean;
  setSidebarVisible?: (isVisible: boolean) => void;
  routes?: Route[];
  activePath?: string;
  onItemClick?: (path: string) => void;
};

export const TopbarView: FunctionComponent<TopbarProps> = ({
  mode = TopbarMode.Landing,
  isLoggedIn,
  isSidebarVisible = false,
  setSidebarVisible = () => {},
  routes = [],
  activePath,
  onItemClick,
  children,
}) => {
  const { isMediumLayout } = useContext(MediaContext);

  useLayoutEffect(() => {
    if (isMediumLayout && mode === TopbarMode.Landing && isSidebarVisible) {
      setSidebarVisible(false);
    }
  }, [isSidebarVisible, isMediumLayout, mode, setSidebarVisible]);

  const SidebarTrigger = () => (
    <Styled.SidebarTrigger>
      <Button onClick={() => setSidebarVisible(!isSidebarVisible)} appearance={Appearance.Icon}>
        {isSidebarVisible ? <Icon.CrossLarge /> : <Icon.Hamburger />}
      </Button>
    </Styled.SidebarTrigger>
  );

  const HomePageNavigation = () => (
    <>
      {isMediumLayout ? (
        <Styled.NavBar mode={TopbarMode.Landing}>
          {routes.map(({ path, label }) => (
            <Styled.ClickableLink
              key={path}
              onClick={() => onItemClick && onItemClick(path)}
              isActive={activePath === path}
            >
              <Button appearance={Appearance.Link}>{label}</Button>
            </Styled.ClickableLink>
          ))}
          {isLoggedIn ? (
            <Button
              to={paths.components}
              isHighlighted={true}
              appearance={Appearance.PrimaryWithIcon}
            >
              <Icon.Rocket />
              <span>To platform</span>
            </Button>
          ) : (
            <Button
              to={paths.authenticate}
              isHighlighted={true}
              appearance={Appearance.PrimaryWithIcon}
            >
              <Icon.Login />
              Login
            </Button>
          )}
        </Styled.NavBar>
      ) : (
        <SidebarTrigger />
      )}
    </>
  );

  const AuthPageNavigation = () => (
    <Styled.SidebarTrigger>
      <Button to={paths.landing} appearance={Appearance.Icon}>
        <Icon.CrossLarge />
      </Button>
    </Styled.SidebarTrigger>
  );

  const PortalNavigation = () => <SidebarTrigger />;

  const getNavigation = (topbarMode: TopbarMode) => {
    switch (topbarMode) {
      case TopbarMode.Portal:
        return <PortalNavigation />;
      case TopbarMode.Authenticate:
        return <AuthPageNavigation />;
      default:
        return <HomePageNavigation />;
    }
  };

  if (mode === TopbarMode.Portal && isMediumLayout) {
    return null;
  }

  return (
    <Styled.Container mode={mode}>
      <Styled.Navigation>{getNavigation(mode)}</Styled.Navigation>
      <Styled.Title mode={mode}>
        <Styled.NavLink to={paths.landing} isActive={false}>
          {isMediumLayout ? <Icon.LogoWithText /> : <Icon.Logo />}
        </Styled.NavLink>
      </Styled.Title>
      {mode === TopbarMode.Portal && (
        <Styled.Notification>
          {children}
          <Styled.AvatarWrapper>
            <Avatar
              name=""
              appearance={AvatarAppearance.Small}
              hasBorder={false}
              avatarType={AvatarType.User}
            />
          </Styled.AvatarWrapper>
        </Styled.Notification>
      )}
    </Styled.Container>
  );
};
