import { FunctionComponent, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { TagView } from '../../grpc/grpcweb/tag_pb';
import { Layout } from '../../components/Layout';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import { NewTagButton, Tag, TagContainer } from '../../components/Tag';
import { Breadcrumbs, buildBreadcrumb } from '../../components/Breadcrumbs';
import Widget from '../../components/Widget';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import AddTagModal from '../../modals/AddTag';
import { Appearance, paths, SloComponent, ComponentRefInTheList } from '../../definitions';
import ComponentMetaPanel from './ComponentMetaPanel';
import WidgetContent from './TagWidgetContent';
import UpdateComponent from '../UpdateComponent';
import Dependencies from './Dependencies';
import XEvents from './XEvents';

type ComponentViewProps = {
  // sloList: SloInTheList[];
  // onViewSLO: (shortName?: string) => void;
  // onCreateSlo: (componentId: string) => void;
  // isLoadingSLOs: boolean;
  onViewComponent: (component: ComponentRefInTheList) => void;
  isLoadingComponent: boolean;
  component: SloComponent;
  // onPublishComment: (fromData: PublishCommentFormData) => void;
  // componentComments: CommentData[];
  // isLoadingComponentComments: boolean;
  // isPublishingComponentComment: boolean;
  onOpenUpdateComponent: () => void;
  onCloseUpdateComponent: () => void;
  isUpdateDrawerVisible: boolean;
  isRemovingTag: boolean;
  onRemoveTag: (tagId: string, valueId: string) => void;
  tags: TagView.AsObject[];
  onFetchTag: (tagId: string, valueId: string) => void;
  isLoadingSelectedTag: boolean;
  selectedTagDescription?: string;
  tagUsage: string[];
  onEditTag: (tagKey: string) => void;
} & RouteChildrenProps;

export const ComponentView: FunctionComponent<ComponentViewProps> = ({
  // sloList,
  // onViewSLO,
  // onCreateSlo,
  // isLoadingSLOs,
  onViewComponent,
  component,
  onOpenUpdateComponent,
  onCloseUpdateComponent,
  isUpdateDrawerVisible,
  isRemovingTag,
  onRemoveTag,
  tags,
  onFetchTag,
  isLoadingSelectedTag,
  selectedTagDescription,
  isLoadingComponent,
  tagUsage,
  onEditTag,
  ...rest
}) => {
  const {
    displayName,
    description,
    id,
    shortname,
    ownerTeam,
    avatar,
    type,
    links,
    updatedAt,
    updatedBy,
    createdAt,
    createdBy,
  } = component;
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);

  const Header = () => (
    <Layout.Header>
      <Breadcrumbs
        items={[
          buildBreadcrumb({ name: 'Components', path: paths.components, icon: Icon.Home }),
          buildBreadcrumb({
            name: displayName || shortname || '',
            path: paths.component,
            isCurrent: true,
          }),
        ]}
      />
      <Layout.HeaderActions>
        <Button appearance={Appearance.PrimaryWithIconCollapsible} onClick={onOpenUpdateComponent}>
          <Icon.Edit />
          <span>Update component</span>
        </Button>
      </Layout.HeaderActions>
    </Layout.Header>
  );

  // const CreateSloButton: FunctionComponent<{ isCollapsible?: boolean }> = ({
  //   isCollapsible = false,
  // }) => (
  //   <Button
  //     onClick={() => onCreateSlo(id)}
  //     appearance={
  //       isCollapsible ? Appearance.PrimaryWithIconCollapsible : Appearance.PrimaryWithIcon
  //     }
  //   >
  //     <Icon.Plus />
  //     <span>Create SLO</span>
  //   </Button>
  // );

  const MetaPanel: FunctionComponent<{
    isCollapsed: boolean;
    onSetIsCollapsed: (newValue: boolean) => void;
  }> = props => {
    return (
      <ComponentMetaPanel
        name={shortname || ''}
        avatarSrc={avatar}
        type={type || ''}
        description={description}
        openEditComponentModal={onOpenUpdateComponent}
        createdAt={createdAt}
        createdBy={createdBy}
        updatedBy={updatedBy}
        updatedAt={updatedAt}
        ownerTeam={ownerTeam}
        componentId={id}
        links={links}
        {...props}
      />
    );
  };

  return (
    <AuthenticatedLayout header={Header} metaPanel={MetaPanel}>
      {/* <Styled.CategoryContainer>
        <Layout.Heading
          actions={sloList.length ? [<CreateSloButton isCollapsible={true} />] : []}
          subheading="The list includes all SLOs created for the component or its parts."
        >
          SLOs
        </Layout.Heading>
        {isLoadingSLOs ? (
          <Loader.Container>
            <Loader />
          </Loader.Container>
        ) : sloList.length ? (
          <Card.CardContainer>
            {sloList.map((slo: SloInTheList) => (
              <Card.CardWrapper key={slo.id}>
                <SloCard onViewSLO={onViewSLO} slo={slo} />
              </Card.CardWrapper>
            ))}
          </Card.CardContainer>
        ) : (
          <Empty text="This component doesn't have any SLOs. Start by creating one.">
            <CreateSloButton />
          </Empty>
        )}
      </Styled.CategoryContainer> */}
      <TagContainer>
        {!isLoadingComponent &&
          tags &&
          tags.map((tag: TagView.AsObject) => (
            <Tag
              isRemoving={isRemovingTag}
              onRemove={() => onRemoveTag(tag.tagid, tag.valueid)}
              value={tag.value}
              tagKey={tag.displayname}
              key={tag.displayname}
              removePopupTitle={`Are you sure you want to remove "${tag.displayname}" tag?`}
              removePopupText="You can add this tag back anytime you like."
            >
              <Widget
                name={`${tag.displayname}: ${tag.value}`}
                content={
                  <WidgetContent
                    key={tag.tagid}
                    isLoading={isLoadingSelectedTag}
                    description={selectedTagDescription}
                    tagUsage={tagUsage}
                  />
                }
                actions={[
                  <Button
                    key="edit-tag-btn"
                    isDisabled={isLoadingSelectedTag}
                    onClick={() => onEditTag(tag.displayname)}
                  >
                    Edit tag
                  </Button>,
                ]}
                openCallback={() => onFetchTag(tag.tagid, tag.valueid)}
              >
                <Tag.CustomContent
                  value={tag.value}
                  tagKey={tag.displayname}
                  isContentClickable={true}
                />
              </Widget>
            </Tag>
          ))}
        <NewTagButton onClick={() => setIsAddTagModalOpen(true)} />
      </TagContainer>
      <Dependencies onViewComponent={onViewComponent} component={component} componentId={id} />

      <Layout.Heading
        actions={[]}
        subheading="An event within the component can be a success, failure, or information-only activity, each shaping the component's narrative and impact."
      >
        Events
      </Layout.Heading>
      <XEvents componentId={component.id} />
      <AddTagModal
        onClose={() => setIsAddTagModalOpen(false)}
        isOpen={isAddTagModalOpen}
        componentId={id}
      />
      <UpdateComponent visible={isUpdateDrawerVisible} onHide={onCloseUpdateComponent} {...rest} />
    </AuthenticatedLayout>
  );
};
