import { FunctionComponent } from 'react';
import CommentForm from '../../forms/Comment';
import { Loader } from '../Loader';
import Comment from '../Comment';
import { CommentData, PublishCommentFormData } from '../../definitions';
import * as Styled from './CommentSection.styles';
import { Empty } from '../Empty';

export type CommentSectionProps = {
  onPublish: (fromData: PublishCommentFormData) => void;
  isLoading: boolean;
  isPublishing: boolean;
  comments: CommentData[];
};

export const CommentSectionView: FunctionComponent<CommentSectionProps> = ({
  onPublish,
  isLoading,
  isPublishing,
  comments,
}) => {
  return (
    <>
      {isLoading && !comments.length ? (
        <Loader.Container>
          <Loader />
        </Loader.Container>
      ) : comments.length ? (
        comments.map((comment: CommentData) => (
          <Styled.CommentWrapper key={comment.id}>
            <Comment
              author={comment.author}
              text={comment.value}
              addedAt={comment.addedAt.toUTCString()}
            />
          </Styled.CommentWrapper>
        ))
      ) : (
        <Empty text="No comments yet. Add one to start conversation." />
      )}
      <Styled.FormWrapper>
        <CommentForm isLoading={isPublishing} isDisabled={false} onSubmit={onPublish} />
      </Styled.FormWrapper>
    </>
  );
};
