import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteChildrenProps, generatePath } from 'react-router';
import { ComponentMetadata } from '../../grpc/grpcweb/component_pb';
import {
  getSelectedComponentId,
  fetchSLOListForComponent,
  fetchComponentByShortname,
  isFetchingComponentByShortname,
  getSelectedComponent,
  fetchComponentComments,
  getSelectedComponentTags,
  isUnlinkingTagFromComponent,
  unlinkTagFromComponent,
  isFetchingTagByKeyAndValue,
  fetchTagByKeyAndValue,
  getSelectedTagDescription,
  isFetchingXEventList,
  isFetchingComponentDependencies,
  getTagUsageInComponent,
} from '../../store';
import { useScrollToAnchor, usePageTitle } from '../../hooks';
import { ComponentRefInTheList, paths } from '../../definitions';
import { ComponentView } from './Component.view';

type ComponentContainerProps = {
  isUpdateDrawerOpen?: boolean;
} & RouteChildrenProps;

export const ComponentContainer: FunctionComponent<ComponentContainerProps> = ({
  match,
  isUpdateDrawerOpen = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  usePageTitle('Component');

  // const isLoadingSLOs = useSelector(isFetchingSLOListforComponent);
  // const sloList = useSelector(getSLOListForComponent);

  const component = useSelector(getSelectedComponent);
  const componentId = useSelector(getSelectedComponentId);
  const isLoadingComponent = useSelector(isFetchingComponentByShortname);

  const isLoadingDependencies = useSelector(isFetchingComponentDependencies);
  const isFetchingXEvents = useSelector(isFetchingXEventList);
  useScrollToAnchor(!Boolean(isLoadingComponent || isFetchingXEvents || isLoadingDependencies));

  const tags = useSelector(getSelectedComponentTags);
  const isRemovingTag = useSelector(isUnlinkingTagFromComponent);
  const isLoadingSelectedTag = useSelector(isFetchingTagByKeyAndValue);
  const selectedTagDescription = useSelector(getSelectedTagDescription);
  const tagUsage = useSelector(getTagUsageInComponent);

  // const componentComments = useSelector(getComponentComments);
  // const isLoadingComponentComments = useSelector(isFetchingComponentComments);
  // const isPublishingComment = useSelector(isAddingSLOComment);

  const { componentShortname } = match?.params as {
    componentShortname: ComponentMetadata.AsObject['shortname'];
  };

  useEffect(() => {
    dispatch(fetchComponentByShortname(componentShortname));
  }, [dispatch, componentShortname]);

  const [isUpdateDrawerVisible, setIsUpdateDrawerVisible] = useState(false);

  useEffect(() => {
    if (isUpdateDrawerOpen) {
      setIsUpdateDrawerVisible(true);
    } else {
      setIsUpdateDrawerVisible(false);
    }
  }, [isUpdateDrawerOpen]);

  useEffect(() => {
    if (componentId) {
      dispatch(fetchComponentComments(componentId));
      dispatch(fetchSLOListForComponent(componentId));
    }
  }, [dispatch, componentId]);

  // const handleViewSLO = useCallback(
  //   sloShortName => {
  //     dispatch(push(generatePath(paths.slo, { sloShortName })));
  //   },
  //   [dispatch],
  // );

  const handleViewComponent = useCallback(
    (component: ComponentRefInTheList) => {
      component.shortname &&
        dispatch(push(generatePath(paths.component, { componentShortname: component.shortname })));
    },
    [dispatch],
  );

  const handleOpenUpdateComponent = useCallback(() => {
    componentShortname &&
      dispatch(push(generatePath(paths.updateComponent, { componentShortname })));
  }, [dispatch, componentShortname]);

  const handleCloseUpdateComponent = useCallback(() => {
    componentShortname && dispatch(push(generatePath(paths.component, { componentShortname })));
  }, [dispatch, componentShortname]);

  const handleRemoveTag = useCallback(
    (tagId: string, valueId: string) => {
      dispatch(unlinkTagFromComponent({ tagId, componentId, valueId }));
    },
    [dispatch, componentId],
  );

  const handleFetchTag = useCallback(
    (keyId: string, valueId: string) => {
      dispatch(fetchTagByKeyAndValue({ keyId, valueId }));
    },
    [dispatch],
  );

  const handleEditTag = useCallback(
    (tagKey: string) => {
      dispatch(push(generatePath(paths.editTag, { tagKey })));
    },
    [dispatch],
  );

  // const handleCreateSlo = useCallback(
  //   (componentId: string) => {
  //     dispatch(setSelectedComponent(componentId));
  //     dispatch(push(paths.drawer.createSlo));
  //   },
  //   [dispatch],
  // );

  // const handlePublishComment = useCallback(
  //   (commentFormData: PublishCommentFormData) => {
  //     dispatch(addComponentComment({ componentId, comment: commentFormData.comment }));
  //   },
  //   [dispatch, componentId],
  // );

  return (
    <ComponentView
      // sloList={sloList}
      // onViewSLO={handleViewSLO}
      // isLoadingSLOs={isLoadingSLOs}
      // onCreateSlo={handleCreateSlo}
      onViewComponent={handleViewComponent}
      isLoadingComponent={isLoadingComponent}
      component={component}
      isRemovingTag={isRemovingTag}
      onRemoveTag={handleRemoveTag}
      tags={tags}
      onFetchTag={handleFetchTag}
      isLoadingSelectedTag={isLoadingSelectedTag}
      selectedTagDescription={selectedTagDescription}
      tagUsage={tagUsage}
      // onPublishComment={handlePublishComment}
      // componentComments={componentComments}
      // isLoadingComponentComments={isLoadingComponentComments}
      // isPublishingComponentComment={isPublishingComment}
      onOpenUpdateComponent={handleOpenUpdateComponent}
      onCloseUpdateComponent={handleCloseUpdateComponent}
      isUpdateDrawerVisible={isUpdateDrawerVisible}
      match={match}
      onEditTag={handleEditTag}
      {...rest}
    />
  );
};
