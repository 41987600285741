import { grpc } from '@improbable-eng/grpc-web';
import { BotService } from '../grpc/grpcweb/bot_pb_service';
import {
  ListBotRequest,
  ListBotResponse,
  CreateBotRequest,
  CreateBotResponse,
  CreateTokenRequest,
  CreateTokenResponse,
  DeleteBotRequest,
  DeleteBotResponse,
  UpdateBotRequest,
  UpdateBotResponse,
  DeleteTokenRequest,
  DeleteTokenResponse,
  GetBotByShortNameRequest,
  GetBotByShortNameResponse,
  GetBotByIdRequest,
  GetBotByIdResponse,
} from '../grpc/grpcweb/bot_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class EventSourcesService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  fetchList = async (RctToken: string) => {
    const request = new ListBotRequest();

    return new Promise((resolve, reject) => {
      grpc.unary(BotService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListBotResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getBotsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch bot list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  createNewBot = async (RctToken: string, createBotRequest: CreateBotRequest) => {
    return new Promise((resolve, reject) => {
      const request: CreateBotRequest = createBotRequest;
      console.log(request);
      grpc.unary(BotService.Create, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<CreateBotResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'create bot failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  createNewToken = async (RctToken: string, createTokenRequest: CreateTokenRequest) => {
    return new Promise((resolve, reject) => {
      const request: CreateTokenRequest = createTokenRequest;
      console.log(request);
      grpc.unary(BotService.CreateToken, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<CreateTokenResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'create bot token failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchOneBot = async (RctToken: string, shortname: string) => {
    const request = new GetBotByShortNameRequest();
    request.setShortname(shortname);
    return new Promise((resolve, reject) => {
      grpc.unary(BotService.GetByShortName, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetBotByShortNameResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getBot());
          } else {
            reject(out.statusMessage);
            console.log(
              `fetch bot with shortname ${shortname} failed`,
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchBotById = async (RctToken: string, botId: string) => {
    const request = new GetBotByIdRequest();
    request.setBotid(botId);
    return new Promise((resolve, reject) => {
      grpc.unary(BotService.GetById, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetBotByIdResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getBot());
          } else {
            reject(out.statusMessage);
            console.log(
              `fetch bot by id failed`,
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  updateBot = async (RctToken: string, updateBotRequest: UpdateBotRequest) => {
    return new Promise((resolve, reject) => {
      const request: UpdateBotRequest = updateBotRequest;
      grpc.unary(BotService.Update, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateBotResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getBot());
          } else {
            reject(out.statusMessage);
            console.log(
              'update bot failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteBot = async (RctToken: string, deleteBotRequest: DeleteBotRequest) => {
    return new Promise((resolve, reject) => {
      const request: DeleteBotRequest = deleteBotRequest;
      grpc.unary(BotService.Delete, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteBotResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'delete bot failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteToken = async (RctToken: string, deleteTokenRequest: DeleteTokenRequest) => {
    return new Promise((resolve, reject) => {
      const request: DeleteTokenRequest = deleteTokenRequest;
      grpc.unary(BotService.DeleteToken, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteTokenResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            console.log(out.message);
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'delete API token failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
