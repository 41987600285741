import styled from 'styled-components';
import { Image } from '../Image';
import stars from '../Image/stars.png';

export const AstronautImage = styled(Image).attrs({
  name: 'astronaut',
  alt: 'astronaut in space',
})`
  display: none;
  ${props => props.theme.media.medium`
    width: auto;
    position: absolute;
    right: 4vw;
    height: 14vw;
    display: flex;
    align-self: center;
  `}
`;

export const StarrySkyWrapper = styled.div`
  padding: 0 4vw;
  position: relative;
  margin-top: ${({ theme }) => theme.constants.topbarHeight};
  background: transparent;
  background-image: url(${stars});
  background-attachment: fixed;
  background-size: auto;
  background-position: top left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.header`
  background: ${({ theme }) => theme.palettes.main};
  background: ${({ theme }) => theme.effects.backgroundShadow};
  min-height: 14rem;
  ${props => props.theme.media.medium`
    height: 24vw;
  `}
`;

export const Moto = styled.h2`
  color: ${({ theme }) => theme.palettes.inverse};
  z-index: ${({ theme }) => theme.zIndexes.moto};
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  && {
    font-size: 1.5rem;
  }
  margin: 0;
  text-align: center;

  ${props => props.theme.media.medium`
  && {
    font-size: 2.5vw;
  }
  `}
`;

export const Submoto = styled(Moto)`
  padding-top: 2vw;
  && {
    font-size: 1rem;
  }
  color: ${({ theme }) => theme.palettes.highlight};
  && {
    ${props => props.theme.media.medium`
    font-size: 1.5vw;
  `}  
}
`;

export const MotoContainer = styled.div`
  min-height: 14rem;
  max-width: 60vw;
  ${props => props.theme.media.medium`
    height: 24vw;
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
