// package: rct.web
// file: grpcweb/component.proto

var grpcweb_component_pb = require("../grpcweb/component_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ComponentService = (function () {
  function ComponentService() {}
  ComponentService.serviceName = "rct.web.ComponentService";
  return ComponentService;
}());

ComponentService.Create = {
  methodName: "Create",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.CreateComponentRequest,
  responseType: grpcweb_component_pb.CreateComponentResponse
};

ComponentService.Update = {
  methodName: "Update",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.UpdateComponentRequest,
  responseType: grpcweb_component_pb.UpdateComponentResponse
};

ComponentService.Delete = {
  methodName: "Delete",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.DeleteComponentRequest,
  responseType: grpcweb_component_pb.DeleteComponentResponse
};

ComponentService.ListByPage = {
  methodName: "ListByPage",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.ListComponentsByPageRequest,
  responseType: grpcweb_component_pb.ListComponentsByPageResponse
};

ComponentService.ListByFilter = {
  methodName: "ListByFilter",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.ListByFilterPageRequest,
  responseType: grpcweb_component_pb.ListByFilterPageResponse
};

ComponentService.ListMy = {
  methodName: "ListMy",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.ListMyComponentsRequest,
  responseType: grpcweb_component_pb.ListMyComponentsResponse
};

ComponentService.ListWithNoRelations = {
  methodName: "ListWithNoRelations",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.ListWithNoRelationsRequest,
  responseType: grpcweb_component_pb.ListWithNoRelationsResponse
};

ComponentService.Get = {
  methodName: "Get",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.GetComponentRequest,
  responseType: grpcweb_component_pb.GetComponentResponse
};

ComponentService.GetByShortname = {
  methodName: "GetByShortname",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.GetComponentByShortnameRequest,
  responseType: grpcweb_component_pb.GetComponentByShortnameResponse
};

ComponentService.GetByShortNameWithRelations = {
  methodName: "GetByShortNameWithRelations",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.GetByShortNameWithRelationsRequest,
  responseType: grpcweb_component_pb.GetByShortNameWithRelationsResponse
};

ComponentService.AddRelation = {
  methodName: "AddRelation",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.ComponentRelationRequest,
  responseType: grpcweb_component_pb.ComponentRelationResponse
};

ComponentService.RemoveDependant = {
  methodName: "RemoveDependant",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.RemoveDependantRequest,
  responseType: grpcweb_component_pb.RemoveDependantResponse
};

ComponentService.RemoveDependency = {
  methodName: "RemoveDependency",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.RemoveDependencyRequest,
  responseType: grpcweb_component_pb.RemoveDependencyResponse
};

ComponentService.ListRelations = {
  methodName: "ListRelations",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.ComponentListRelationsRequest,
  responseType: grpcweb_component_pb.ComponentListRelationsResponse
};

ComponentService.AddDependents = {
  methodName: "AddDependents",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.AddDependentsRequest,
  responseType: grpcweb_component_pb.AddDependentsResponse
};

ComponentService.AddDependencies = {
  methodName: "AddDependencies",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.AddDependenciesRequest,
  responseType: grpcweb_component_pb.AddDependenciesResponse
};

ComponentService.AddLink = {
  methodName: "AddLink",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.AddComponentLinkRequest,
  responseType: grpcweb_component_pb.AddComponentLinkResponse
};

ComponentService.UpdateLink = {
  methodName: "UpdateLink",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.UpdateComponentLinkRequest,
  responseType: grpcweb_component_pb.UpdateComponentLinkResponse
};

ComponentService.RemLink = {
  methodName: "RemLink",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.RemComponentLinkRequest,
  responseType: grpcweb_component_pb.RemComponentLinkResponse
};

ComponentService.GetDetailsForPopup = {
  methodName: "GetDetailsForPopup",
  service: ComponentService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_component_pb.GetComponentDetailsForPopupRequest,
  responseType: grpcweb_component_pb.GetComponentDetailsForPopupResponse
};

exports.ComponentService = ComponentService;

function ComponentServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ComponentServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.listByPage = function listByPage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.ListByPage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.listByFilter = function listByFilter(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.ListByFilter, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.listMy = function listMy(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.ListMy, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.listWithNoRelations = function listWithNoRelations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.ListWithNoRelations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.get = function get(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.Get, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.getByShortname = function getByShortname(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.GetByShortname, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.getByShortNameWithRelations = function getByShortNameWithRelations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.GetByShortNameWithRelations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.addRelation = function addRelation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.AddRelation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.removeDependant = function removeDependant(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.RemoveDependant, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.removeDependency = function removeDependency(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.RemoveDependency, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.listRelations = function listRelations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.ListRelations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.addDependents = function addDependents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.AddDependents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.addDependencies = function addDependencies(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.AddDependencies, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.addLink = function addLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.AddLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.updateLink = function updateLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.UpdateLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.remLink = function remLink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.RemLink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ComponentServiceClient.prototype.getDetailsForPopup = function getDetailsForPopup(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ComponentService.GetDetailsForPopup, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ComponentServiceClient = ComponentServiceClient;

