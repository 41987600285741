import { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import Topbar from '../../components/Topbar';
import Divider from '../../components/Divider';
import { Input } from '../../components/Form/Input';
import { LoginFormSection } from '../../components/Form/LoginFormSection';
import { Link } from '../../components/Link';
import { parsers, validation, TopbarMode, Appearance, LoginFormData, externalLinks } from '../../definitions';
import * as Styled from './Login.styles';

type LoginSceneProps = {
  onSubmit: (loginData: LoginFormData) => void;
  isLoading: boolean;
  onLoginWithDemo: () => void;
};

export const LoginSceneView: FunctionComponent<LoginSceneProps> = ({
  onSubmit,
  isLoading,
  onLoginWithDemo,
  ...rest
}) => {
  return (
    <>
      <Topbar mode={TopbarMode.Authenticate} isLoggedIn={false} />
      <Styled.Container>
        <Form
          {...rest}
          onSubmit={values => {
            onSubmit({
              email: values.email || null,
              password: values.password || null,
              tenantName: values.tenantName || null,
            });
          }}
          render={({ handleSubmit, pristine, valid }) => (
            <LoginFormSection>
              <div>
                <h2>Login</h2>
                <Divider width="8rem" margin="1rem" disableVW={true} />
              </div>
              <Field
                id="tenantName"
                name="tenantName"
                placeholder="Tenant"
                label="Tenant"
                component={Input}
                validate={value => validation.required(value)}
                format={parsers.trim}
                formatOnBlur={true}
                showValidation={true}
              />
              <Field
                id="email"
                name="email"
                placeholder="Email"
                label="Email"
                component={Input}
                validate={validation.compose(value => validation.required(value), validation.email)}
                format={parsers.trim}
                formatOnBlur={true}
                showValidation={true}
              />
              <Field
                id="password"
                name="password"
                placeholder="Password"
                label="Password"
                component={Input}
                validate={value => validation.required(value)}
                type="password"
                minLength="7"
                showValidation={true}
              />
              <Styled.LoginButton
                appearance={Appearance.Funky}
                onClick={handleSubmit}
                isDisabled={pristine || !valid || isLoading}
                isPending={isLoading}
              >
                Sign in
              </Styled.LoginButton>
              <Styled.Caption>Not a member yet?{` `}<Link to={externalLinks.mailchimpUrl}>Sign up</Link>{` `}for our mailing list to be the first to hear about the launch, or explore our product with a{` `}<Link onClick={onLoginWithDemo}>demo account</Link>{` `}now.</Styled.Caption>
            </LoginFormSection>
          )}
        />
      </Styled.Container>
    </>
  );
};
