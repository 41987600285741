import { ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const fetchAllNotifications = (payload = {}): ActionType => ({
  type: actionTypes.notifications.fetch,
  payload,
});

export const markAllNotificationsAsRead = (payload: number): ActionType => ({
  type: actionTypes.notifications.markAsRead,
  payload,
});
