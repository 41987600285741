import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addedComponentLinkSuccessfully,
  addComponentLink,
  isAddingLinkToComponent,
} from '../../store';
import { AddLinkFormData } from '../../definitions';
import { AddLinkView } from './AddLink.view';

type AddLinkContainerProps = {
  onClose: () => void;
  isOpen: boolean;
  componentId: string;
};

export const AddLinkContainer: FunctionComponent<AddLinkContainerProps> = ({
  isOpen,
  onClose,
  componentId,
}) => {
  const dispatch = useDispatch();
  const isAdding = useSelector(isAddingLinkToComponent);
  const finishedCreating = useSelector(addedComponentLinkSuccessfully);

  const handleAddLink = useCallback(
    (formData: AddLinkFormData) => {
      dispatch(addComponentLink({ componentId, formData }));
    },
    [dispatch, componentId],
  );

  useEffect(() => {
    if (finishedCreating) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedCreating]);

  return (
    <AddLinkView isOpen={isOpen} onClose={onClose} isAdding={isAdding} onAddLink={handleAddLink} />
  );
};
