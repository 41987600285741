import { all, takeLatest, select, put, AllEffect, call } from 'redux-saga/effects';
import { ListUsersRequest } from '../../grpc/grpcweb/user_pb';
import { PageRequest } from '../../grpc/grpccommon/common_pb';
import services from '../../services';
import { SequenceType, request, ActionType, AlertType } from '../../definitions';
import actionTypes from '../constants/actionTypes';
import {
  getAccessToken,
  getFetchUsersError,
  getUserListNextPageToken,
  getUserListPrevPageToken,
  getFetchUserListNextPageError,
  getFetchUserListPrevPageError,
  getUserListCurrPageToken,
  getFetchUserError,
} from '../selectors';
import { USER_LIST_PAGE_SIZE } from '../constants/pageSize';
import { showAlert } from '../actions';
import { RootState } from '../reducers';

function* fetchList(
  action: ActionType,
  successAction: string,
  errorGetter: (state: RootState) => string,
  pageToken?: string,
) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch user list.',
      }),
    );
  }

  const pageSize = action.payload || USER_LIST_PAGE_SIZE;

  const fetchUsersRequest = new ListUsersRequest();
  const pageData = new PageRequest();
  pageData.setPagesize(pageSize);
  pageToken && pageData.setPagetoken(pageToken);
  fetchUsersRequest.setPagerequest(pageData);

  const { sequence } = yield request(
    { rootAction: action, type: successAction },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ userService }) =>
          userService
            .fetchList(token, fetchUsersRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(errorGetter);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchAllUsers(action: ActionType) {
  const currentPageToken = yield select(getUserListCurrPageToken);
  yield call(fetchList, action, actionTypes.users.fetched, getFetchUsersError, currentPageToken);
}

function* fetchListNextPage(action: ActionType) {
  const nextPageToken = yield select(getUserListNextPageToken);
  yield call(
    fetchList,
    action,
    actionTypes.users.fetchedNextPage,
    getFetchUserListNextPageError,
    nextPageToken,
  );
}

function* fetchListPrevPage(action: ActionType) {
  const prevPageToken = yield select(getUserListPrevPageToken);
  yield call(
    fetchList,
    action,
    actionTypes.users.fetchedNextPage,
    getFetchUserListPrevPageError,
    prevPageToken,
  );
}

function* fetchUser(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch user details.',
      }),
    );
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.users.current.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ userService }) =>
          userService
            .fetchUser(token)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchUserError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.users.fetch, fetchAllUsers)]);
  yield all([takeLatest(actionTypes.users.current.fetch, fetchUser)]);
  yield all([takeLatest(actionTypes.users.fetchNextPage, fetchListNextPage)]);
  yield all([takeLatest(actionTypes.users.fetchPrevPage, fetchListPrevPage)]);
}
