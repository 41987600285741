import { CloudWatchAuthType } from '../../definitions';
import { EventSourceType } from '../../grpc/grpcweb/eventsource_pb';

export const newEventSource = {
  displayName: '',
  sourceType: EventSourceType.EVENT_SOURCE_CLOUDWATCH,
  region: '',
  authenticationType: CloudWatchAuthType.AccessKey,
  accessKey: '',
  secretAccessKey: '',
  externalId: '',
  rocketSLOAWSAccount: '',
  arn: '',
  description: '',
};
