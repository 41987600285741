import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageTitle } from '../../hooks';
import {
  isFetchingUsers,
  isFetchingUserListNextPage,
  isFetchingUserListPrevPage,
  fetchAllUsers,
  getAllUsers,
  getTotalNumberOfUsers,
  getUserListPrevPageToken,
  getUserListNextPageToken,
  fetchNextUserListPage,
  fetchPrevUserListPage,
} from '../../store';
import { UsersView } from './Users.view';

export const UsersContainer: FunctionComponent = () => {
  usePageTitle('Users');

  const dispatch = useDispatch();

  const isFetchingList = useSelector(isFetchingUsers);
  const isFetchingNextpage = useSelector(isFetchingUserListNextPage);
  const isFetchingPrevpage = useSelector(isFetchingUserListPrevPage);

  const userList = useSelector(getAllUsers);
  const numberOfUsers = useSelector(getTotalNumberOfUsers);
  const prevPageToken = useSelector(getUserListPrevPageToken);
  const nextPageToken = useSelector(getUserListNextPageToken);

  const handleFetchNextPage = useCallback(() => {
    dispatch(fetchNextUserListPage());
  }, [dispatch]);

  const handleFetchPrevtPage = useCallback(() => {
    dispatch(fetchPrevUserListPage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  return (
    <UsersView
      isFetching={isFetchingList || isFetchingNextpage || isFetchingPrevpage}
      userList={userList}
      numberOfUsers={numberOfUsers}
      onNextPage={handleFetchNextPage}
      onPrevPage={handleFetchPrevtPage}
      isPrevPageDisabled={!prevPageToken}
      isNextPageDisabled={!nextPageToken}
    />
  );
};
