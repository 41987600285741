import { FunctionComponent, useRef } from 'react';
import { Modal } from '../../components/Modal';
import TeamForm from '../../forms/Team';
import { TeamFormData, Appearance, theme } from '../../definitions';
import { FormLayout } from '../../components/Form';
import { Icon } from '../../components/Icon';
import Button from '../../components/Button';
import PopConfirm from '../../components/PopConfirm';
import Tooltip from '../../components/Tooltip';

type UpdateTeamProps = {
  onClose: () => void;
  isOpen: boolean;
  isUpdating: boolean;
  isFetching: boolean;
  isDeleting: boolean;
  onUpdateTeam: (formData: TeamFormData) => void;
  onDeleteTeam: () => void;
  initialValues: TeamFormData;
  hasMembers: boolean;
};

export const UpdateTeamView: FunctionComponent<UpdateTeamProps> = ({
  children,
  onClose,
  onUpdateTeam,
  onDeleteTeam,
  initialValues,
  isUpdating,
  isFetching,
  isDeleting,
  hasMembers,
  ...rest
}) => {
  const DeleteButton: FunctionComponent<{ isDisabled: boolean }> = ({ isDisabled }) => (
    <Button isDisabled={isDisabled} isPending={isDeleting} appearance={Appearance.PrimaryWithIcon}>
      <Icon.Trash />
      <span>Delete</span>
    </Button>
  );

  const DeletePopConfirm: FunctionComponent = () => {
    const ref = useRef<HTMLDivElement>(null);

    if (hasMembers) {
      return (
        <Tooltip
          content="A team with members cannot be deleted. Please reassign (remove) all members first."
          color={theme.palettes.main}
        >
          <div ref={ref}>
            <DeleteButton isDisabled={true} />
          </div>
        </Tooltip>
      );
    }

    return (
      <PopConfirm
        title={`Are you sure you want to delete the ${initialValues.displayname} team?`}
        onConfirm={() => onDeleteTeam()}
        isConfirming={isDeleting}
        isDisabled={isFetching || isUpdating}
        contentText="Please note that after confirming it will be permanently deleted. You will not be
                  able to restore the deleted team."
      >
        <DeleteButton isDisabled={isFetching || isUpdating} />
      </PopConfirm>
    );
  };

  return (
    <Modal onClose={onClose} title="Update team" {...rest}>
      <FormLayout isModal={true}>
        <TeamForm
          onSubmit={onUpdateTeam}
          initialValues={initialValues}
          isLoading={isUpdating}
          isDisabled={isFetching}
          isDeleting={isDeleting}
          action="Update"
          onCancel={onClose}
          isNewTeam={false}
          extraAction={<DeletePopConfirm />}
        />
      </FormLayout>
    </Modal>
  );
};
