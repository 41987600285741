import { FunctionComponent, ReactNode } from 'react';
import { Form, Field } from 'react-final-form';
import { TagValue } from '../../grpc/grpcweb/tag_pb';
import { validation, parsers, FormStateType, Appearance, TagFormData } from '../../definitions';
import { Input, FormLayout, FormAutoSave, Textarea } from '../../components/Form';
import { Icon } from '../../components/Icon';
import Button from '../../components/Button';
import NewValueInput from './NewValueInput';
import ExistingValueInput from './ExistingValueInput';

type TagFormProps = {
  isLoading: boolean;
  onSubmit: (formData: TagFormData) => void;
  onCancel: () => void;
  onReset: () => void;
  initialValues: TagFormData;
  onUpdateTagState?: (formState: FormStateType | TagFormData) => void;
  action: string;
  extraAction?: ReactNode;
  isDisabled?: boolean;
  existingValueErrorMessage?: string;
  newValueErrorMessage?: string;
  onUpdateValue: (value: TagValue.AsObject, prevValue: TagValue.AsObject) => void;
  onRemoveValue: (value: TagValue.AsObject) => void;
  onCreateNewValue: (newValue: string) => void;
  onClearAllErrors: () => void;
  tagValues: TagValue.AsObject[];
  onUpdateValues?: (values: TagValue.AsObject[]) => void;
  clickedSubmit: boolean;
  onSetClickedSubmit: (clicked: boolean) => void;
  isCreateForm?: boolean;
  isAddingNewValue?: boolean;
  isDeletingValue?: boolean;
  isUpdatingValue?: boolean;
  updatedValueSuccesfully?: boolean;
};

export const TagFormView: FunctionComponent<TagFormProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  onUpdateTagState = () => {},
  action,
  extraAction,
  isDisabled = false,
  onCancel,
  onReset,
  existingValueErrorMessage,
  newValueErrorMessage,
  onUpdateValue,
  onRemoveValue,
  onCreateNewValue,
  onClearAllErrors,
  onUpdateValues,
  tagValues,
  clickedSubmit,
  onSetClickedSubmit,
  isCreateForm,
  isAddingNewValue,
  isDeletingValue,
  isUpdatingValue,
  updatedValueSuccesfully,
}) => {
  return (
    <Form
      autoComplete="off"
      onSubmit={(values: TagFormData) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          onSetClickedSubmit(true);
          handleSubmit(event);
        };

        const handleReset = () => {
          if (isCreateForm) {
            form.reset({});
            onUpdateValues && onUpdateValues([]);
          } else {
            form.restart();
            onUpdateValues && onUpdateValues(initialValues.values);
          }

          onReset();
        };

        return (
          <FormLayout>
            <Field
              id="tagKey"
              name="tagKey"
              label="Name"
              disabled={!isCreateForm}
              component={Input}
              validate={value => validation.required(value)}
              showValidation={clickedSubmit}
              format={parsers.trim}
              formatOnBlur={true}
              maxLength="120"
              maxWidth="40rem"
              description="This is the unique identifier for a tag, representing the key in a key-value pair. Each tag can have only one key, but it can have multiple values associated with it. Choose a descriptive and unique name that clearly conveys the purpose or meaning of the tag."
            />
            <Field
              id="values"
              name="values"
              label="Values"
              component={NewValueInput}
              showValidation={clickedSubmit}
              validate={value => validation.required(value)}
              formatOnBlur={true}
              maxLength="120"
              onCreateNewValue={onCreateNewValue}
              errorMessage={newValueErrorMessage}
              isLoading={isAddingNewValue}
              maxWidth="40rem"
              description="These are the available options that can be assigned to a tag. Each tag has a single key (name) but can have multiple values associated with it. When assigning a tag to a component, you'll be presented with a list of these available values to choose from."
            >
              {tagValues &&
                tagValues.map(tagValue => (
                  <Field
                    id="values"
                    name="values"
                    label="Values"
                    component={ExistingValueInput}
                    showValidation={clickedSubmit}
                    validate={value => validation.required(value)}
                    formatOnBlur={true}
                    maxLength="120"
                    key={tagValue.id}
                    tagValue={tagValue}
                    errorMessage={existingValueErrorMessage}
                    onUpdateValue={onUpdateValue}
                    onRemoveValue={onRemoveValue}
                    maxWidth="40rem"
                    onClearError={onClearAllErrors}
                    isUpdating={isUpdatingValue}
                    isDeleting={isDeletingValue}
                    updatedValueSuccesfully={updatedValueSuccesfully}
                    isCreateForm={isCreateForm}
                  />
                ))}
            </Field>

            <Field
              id="description"
              name="description"
              label="Description"
              component={Textarea}
              showValidation={clickedSubmit}
              format={parsers.trim}
              formatOnBlur={true}
              maxLength="120"
              isOptional={true}
              maxWidth="40rem"
              description="Use this field to provide information about the tag purpose and any necessary and helpful details about it."
            />
            <FormAutoSave
              debounce={300}
              updateFormState={formValues => onUpdateTagState({ ...formValues, values: tagValues })}
            />
            <FormLayout.ActionWrapper>
              <Button
                onClick={onCancel}
                isDisabled={isLoading}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Cross />
                <span>Cancel</span>
              </Button>
              <Button
                onClick={handleReset}
                isDisabled={isLoading || pristine}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Reset />
                <span>Reset</span>
              </Button>
              {extraAction}
              <FormLayout.ModalSubmit
                onClick={onSubmitClick}
                isPending={isLoading}
                isDisabled={isDisabled}
                appearance={Appearance.PrimaryWithIcon}
                isHighlighted={true}
              >
                <Icon.Edit />
                <span>{action}</span>
              </FormLayout.ModalSubmit>
            </FormLayout.ActionWrapper>
          </FormLayout>
        );
      }}
    />
  );
};
