import {
  ActionType,
  SequenceType,
  SloComponent,
  SloInTheList,
  ComponentInTheList,
  ComponentRefInTheList,
  CreateComponentFormData,
  Link,
  XEventInTheList,
} from '../../definitions';
import {
  ComponentBatch,
  ComponentRef,
  ComponentView,
  GetComponentDetailsForPopupResponse,
  ListComponentsByPageResponse,
  ListMyComponentsResponse,
} from '../../grpc/grpcweb/component_pb';
import { XEvent } from '../../grpc/grpcweb/xevent_pb';
import { Link as LinkGrpc } from '../../grpc/grpcweb/link_pb';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import { SloView } from '../../grpc/grpcweb/slo_pb';
import { Comment } from '../../grpc/grpcweb/comment_pb';
import actionTypes from '../constants/actionTypes';
import { mapEventGRPCToStateObject } from './xEvents';

export type ComponentsState = {
  list: ComponentRefInTheList[];
  totalNumber: number;
  prevPageToken?: string;
  nextPageToken?: string;
  currPageToken?: string;
  standAlone: {
    list: ComponentRefInTheList[];
    totalNumber: number;
    prevPageToken?: string;
    nextPageToken?: string;
    currPageToken?: string;
  };
  popupDetails: {
    component: ComponentInTheList;
    xEvents: XEventInTheList[];
  };
  my: ComponentRefInTheList[];
  types: CatalogItemView.AsObject[];
  selected: SloComponent;
  new: CreateComponentFormData;
};

const initialState = {
  list: [],
  totalNumber: 0,
  prevPageToken: undefined,
  nextPageToken: undefined,
  currPageToken: undefined,
  standAlone: {
    list: [],
    totalNumber: 0,
    prevPageToken: undefined,
    nextPageToken: undefined,
    currPageToken: undefined,
  },
  popupDetails: {
    component: {
      id: '',
      shortname: '',
      displayName: '',
      description: '',
      type: '',
      typeId: '',
      sloList: [],
      tags: [],
      links: [],
    },
    xEvents: [],
  },
  my: [],
  types: [],
  new: {
    shortName: '',
    displayName: '',
    description: '',
    componentType: '',
    ownerTeam: undefined,
  },
  selected: {
    id: '',
    shortname: '',
    displayName: '',
    description: '',
    type: '',
    typeId: '',
    sloList: [],
    dependencies: [],
    dependents: [],
    comments: [],
    tags: [],
    selectedDependencyState: {},
    avatar: undefined,
    createdBy: undefined,
    updatedBy: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    links: [],
  },
};

const mapLinkToState = (link: LinkGrpc.AsObject): Link => {
  return {
    id: link.id,
    displayname: link.meta?.displayname,
    url: link.meta?.url,
    avatarUrl: link.meta?.avatar?.uri,
  };
};

const mapComponentGRPCToStateObject = (componentView: ComponentView): ComponentInTheList => {
  const component = componentView.toObject();
  return {
    id: component.id,
    shortname: component.metadata?.shortname,
    displayName: component.metadata?.displayname,
    avatar: component.metadata?.avatar?.uri,
    description: component.metadata?.description?.value,
    type: component.type?.metadata?.displayname,
    typeId: component.type?.id,
    sloList: [],
    tags: component.tagsList || [],
    createdBy: component.createmeta?.createdby?.displayname,
    createdAt: component.createmeta?.createdat
      ? new Date(component.createmeta?.createdat)
      : undefined,
    updatedBy: component.updatemeta?.updatedby?.displayname,
    updatedAt: component.updatemeta?.updatedat
      ? new Date(component.updatemeta?.updatedat)
      : undefined,
    ownerTeam: component.ownerteam
      ? {
          avatarUrl: component.ownerteam.meta?.avatar?.uri,
          description: component.ownerteam.meta?.description?.value,
          shortname: component.ownerteam.meta?.shortname,
          displayname: component.ownerteam.meta?.displayname,
          id: component.ownerteam.id,
          users: component.ownerteam.membersList,
        }
      : undefined,
    links: component.linksList?.map((link: LinkGrpc.AsObject) => mapLinkToState(link)) || [],
  };
};

const mapComponentRefGRPCToStateObject = (
  component: ComponentRef.AsObject,
): ComponentRefInTheList => {
  return {
    id: component.id,
    shortname: component.metadata?.shortname,
    displayName: component.metadata?.displayname,
    description: component.metadata?.description?.value,
    type: component.type?.metadata?.displayname,
    typeId: component.type?.id,
    sloList: [],
    tagList: component.tagsList,
    ownerTeamId: component.metadata?.ownerteamid,
    avatar: component.metadata?.avatar?.uri,
    updatedAt: component.updatemeta?.updatedat
      ? new Date(component.updatemeta?.updatedat)
      : undefined,
    updatedBy: component.updatemeta?.updatedby?.displayname,
  };
};

const components = (
  state: ComponentsState = initialState,
  { type, payload, sequence }: ActionType,
): ComponentsState => {
  switch (type) {
    case actionTypes.components.fetchedList:
    case actionTypes.components.fetchedNextPage:
    case actionTypes.components.fetchedPrevPage: {
      if (sequence === SequenceType.Success) {
        const listResponse: ListComponentsByPageResponse.AsObject = payload;
        const { batch, pageresponse } = listResponse;

        const list = batch?.componentsList
          ? batch?.componentsList?.map((component: ComponentRef.AsObject) =>
              mapComponentRefGRPCToStateObject(component),
            )
          : [];

        return {
          ...state,
          list,
          totalNumber: pageresponse?.totalcount || 0,
          nextPageToken: pageresponse?.nextpagetoken,
          prevPageToken: pageresponse?.prevpagetoken,
          currPageToken: pageresponse?.curpagetoken,
        };
      }
      return state;
    }

    case actionTypes.components.fetchedMy: {
      if (sequence === SequenceType.Success) {
        const listResponse: ListMyComponentsResponse.AsObject = payload;
        const { batch } = listResponse;

        const my = batch?.componentsList
          ? batch?.componentsList?.map((component: ComponentRef.AsObject) =>
              mapComponentRefGRPCToStateObject(component),
            )
          : [];

        return {
          ...state,
          my,
        };
      }
      return state;
    }

    case actionTypes.components.standAlone.fetchedList:
    case actionTypes.components.standAlone.fetchedNextPage:
    case actionTypes.components.standAlone.fetchedPrevPage: {
      if (sequence === SequenceType.Success) {
        const listResponse: ListComponentsByPageResponse.AsObject = payload;
        const { batch, pageresponse } = listResponse;

        const list = batch?.componentsList
          ? batch?.componentsList?.map((component: ComponentRef.AsObject) =>
              mapComponentRefGRPCToStateObject(component),
            )
          : [];

        return {
          ...state,
          standAlone: {
            list,
            totalNumber: pageresponse?.totalcount || 0,
            nextPageToken: pageresponse?.nextpagetoken,
            prevPageToken: pageresponse?.prevpagetoken,
            currPageToken: pageresponse?.curpagetoken,
          },
        };
      }
      return state;
    }

    case actionTypes.components.fetchedTypes: {
      if (sequence === SequenceType.Success) {
        const rawList = payload;
        const types = rawList?.map((type: CatalogItemView) => type.toObject());

        return {
          ...state,
          types,
        };
      }

      return state;
    }

    case actionTypes.components.current.select: {
      const typedPayload: ComponentRefInTheList = payload;

      return {
        ...state,
        selected: {
          ...state.selected,
          ...typedPayload,
        },
      };
    }

    case actionTypes.components.current.dependencies.select: {
      const typedPayload: Record<string, boolean> = payload;
      const selectedDependencyState = state.selected.selectedDependencyState;
      Object.keys(typedPayload).forEach((componentId: string) => {
        selectedDependencyState[componentId] = typedPayload[componentId];
      });
      return {
        ...state,
        selected: {
          ...state.selected,
          selectedDependencyState,
        },
      };
    }

    case actionTypes.components.current.dependencies.clear: {
      return {
        ...state,
        selected: {
          ...state.selected,
          selectedDependencyState: {},
        },
      };
    }

    case actionTypes.components.current.deleted: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          selected: {
            ...initialState.selected,
          },
        };
      }

      return state;
    }

    case actionTypes.components.current.edited:
    case actionTypes.components.current.fetchedById:
    case actionTypes.components.current.fetchedByShortname: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: ComponentView = payload;
        const component = mapComponentGRPCToStateObject(payloadWithType);

        return {
          ...state,
          selected: {
            ...state.selected,
            ...component,
          },
        };
      }

      return state;
    }

    case actionTypes.components.created: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: ComponentView = payload;
        const component = mapComponentGRPCToStateObject(payloadWithType);

        return {
          ...state,
          new: {
            shortName: '',
            displayName: '',
            description: '',
            componentType: '',
            ownerTeam: undefined,
          },
          selected: {
            ...state.selected,
            ...component,
          },
        };
      }

      return state;
    }

    case actionTypes.components.updateNew: {
      return {
        ...state,
        new: payload,
      };
    }

    case actionTypes.components.current.fetchedSLOs: {
      if (sequence === SequenceType.Success) {
        const rawList = payload;
        const originalList = rawList?.map((slo: SloView) => slo.toObject());
        const sloList = originalList?.map(
          (slo: SloView.AsObject): SloInTheList => ({
            displayname: slo.metadata?.displayname,
            shortname: slo.metadata?.shortname,
            objective: slo.metadata?.objective,
            status: slo.status,
            description: slo.metadata?.description?.value,
            errorbudgetmethod: slo.metadata?.errorbudgetmethod,
            id: slo.id,
            slotargetid: slo.metadata?.componentid,
            currentslovalue: slo.currentslovalue,
            type: slo.type?.metadata?.displayname,
            timeWindowType: slo.metadata?.timewindow?.type,
            timeWindowUnit: slo.metadata?.timewindow?.unit,
            timeWindow: slo.metadata?.timewindow?.count,
          }),
        );

        return {
          ...state,
          selected: {
            ...state.selected,
            sloList,
          },
        };
      }

      return state;
    }

    case actionTypes.components.current.dependencies.fetched: {
      if (sequence === SequenceType.Success) {
        const {
          dependentBatch,
          dependencyBatch,
        }: { dependentBatch: ComponentBatch; dependencyBatch: ComponentBatch } = payload;

        const mappedDependencies = dependencyBatch
          .getComponentsList()
          .map((component: ComponentRef) => mapComponentRefGRPCToStateObject(component.toObject()));

        const mappedDependents = dependentBatch
          .getComponentsList()
          .map((component: ComponentRef) => mapComponentRefGRPCToStateObject(component.toObject()));

        const newState = {
          ...state,
          selected: {
            ...state.selected,
            dependencies: mappedDependencies,
            dependents: mappedDependents,
          },
        };

        return newState;
      }

      return state;
    }

    case actionTypes.components.current.comments.fetched: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: Comment[] = payload;
        const comments = payloadWithType.map((comment: Comment) => {
          const data = comment.toObject();
          return {
            addedAt: new Date(data.addedat),
            id: data.id,
            value: data.value?.value,
            targetId: data.targetid,
            author: 'test',
          };
        });

        return {
          ...state,
          selected: {
            ...state.selected,
            comments,
          },
        };
      }

      return state;
    }

    case actionTypes.components.current.links.added:
    case actionTypes.components.current.links.edited:
    case actionTypes.components.current.links.deleted: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: LinkGrpc.AsObject[] = payload;
        const links: Link[] = payloadWithType.map((link: LinkGrpc.AsObject) =>
          mapLinkToState(link),
        );

        return {
          ...state,
          selected: {
            ...state.selected,
            links,
          },
        };
      }

      return state;
    }

    case actionTypes.components.current.popup.fetchedDetails: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: GetComponentDetailsForPopupResponse = payload;
        const componentGrpc = payloadWithType.getComponent();
        const component = componentGrpc
          ? mapComponentGRPCToStateObject(componentGrpc)
          : initialState.selected;
        const xEventGrpc = payloadWithType.getRecenteventsList();
        const xEvents = xEventGrpc.map((event: XEvent) =>
          mapEventGRPCToStateObject(event.toObject()),
        );

        return {
          ...state,
          popupDetails: {
            component,
            xEvents,
          },
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default components;
