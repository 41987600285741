import { FunctionComponent } from 'react';
import { Notification } from '../../grpc/grpcweb/notification_pb';
import Avatar from '../Avatar';
import * as Styled from './Notification.styles';
import { AvatarType } from 'src/definitions';

export type NotificationProps = {
  notification: Notification.AsObject;
};

export const NotificationView: FunctionComponent<NotificationProps> = ({ notification }) => {
  if (notification.datacommentadded) {
    return (
      <Styled.Wrapper>
        <Styled.Details>
          <Avatar
            name={notification.datacommentadded.comment?.author?.fullname || 'User'}
            avatarType={AvatarType.User}
          />
          <Styled.TextContainer>
            <Styled.Meta>
              <Styled.Author>
                {notification.datacommentadded.comment?.author?.fullname}
              </Styled.Author>
              <p>
                {notification.datacommentadded.comment?.addedat
                  ? new Date(notification.datacommentadded.comment?.addedat).toLocaleString()
                  : new Date().toLocaleString()}
              </p>
            </Styled.Meta>
            <div>{notification.datacommentadded.comment?.value?.value}</div>
          </Styled.TextContainer>
        </Styled.Details>
      </Styled.Wrapper>
    );
  }

  if (notification.dataslobreachstarted) {
    return (
      <Styled.Wrapper>
        <Styled.Details>
          <Avatar name="System" avatarType={AvatarType.System} />
          <Styled.TextContainer>
            <Styled.Meta>
              <p>
                {notification.dataslobreachstarted.activity?.slobreachstart
                  ? new Date(
                      notification.dataslobreachstarted.activity?.slobreachstart.breachedat,
                    ).toLocaleString()
                  : new Date().toLocaleString()}
              </p>
              <Styled.Author>{`SLO ${notification.dataslobreachstarted.activity?.sloid} breached`}</Styled.Author>
            </Styled.Meta>
          </Styled.TextContainer>
        </Styled.Details>
      </Styled.Wrapper>
    );
  }

  if (notification.dataslobreachended) {
    return (
      <Styled.Wrapper>
        <Styled.Details>
          <Avatar name="System" avatarType={AvatarType.System} />
          <Styled.TextContainer>
            <Styled.Meta>
              <Styled.Author>{`SLO ${notification.dataslobreachended.activity?.sloid} breach recovered`}</Styled.Author>
              <p>
                {notification.dataslobreachended.activity?.slobreachend
                  ? new Date(
                      notification.dataslobreachended.activity?.slobreachend.recoveredat,
                    ).toLocaleString()
                  : new Date().toLocaleString()}
              </p>
            </Styled.Meta>
          </Styled.TextContainer>
        </Styled.Details>
      </Styled.Wrapper>
    );
  }

  return null;
};
