import { grpc } from '@improbable-eng/grpc-web';
import { CatalogItemService } from '../grpc/grpcweb/catalog_item_pb_service';
import { ComponentService } from '../grpc/grpcweb/component_pb_service';
import { CatalogItemType } from '../grpc/grpccommon/common_pb';
import {
  ListCatalogItemsRequest,
  ListCatalogItemsResponse,
} from '../grpc/grpcweb/catalog_item_pb';
import {
  CreateComponentRequest,
  UpdateComponentRequest,
  UpdateComponentResponse,
  CreateComponentResponse,
  GetComponentRequest,
  GetComponentResponse,
  GetComponentByShortnameRequest,
  ComponentListRelationsRequest,
  ComponentListRelationsResponse,
  ListComponentsByPageRequest,
  ListComponentsByPageResponse,
  DeleteComponentRequest,
  DeleteComponentResponse,
  AddDependenciesRequest,
  AddDependenciesResponse,
  AddDependentsRequest,
  AddDependentsResponse,
  RemoveDependantRequest,
  RemoveDependencyRequest,
  RemoveDependantResponse,
  RemoveDependencyResponse,
  AddComponentLinkRequest,
  AddComponentLinkResponse,
  UpdateComponentLinkRequest,
  UpdateComponentLinkResponse,
  RemComponentLinkRequest,
  RemComponentLinkResponse,
  ListMyComponentsRequest,
  ListMyComponentsResponse,
  ListWithNoRelationsRequest,
  ListWithNoRelationsResponse,
  GetComponentDetailsForPopupRequest,
  GetComponentDetailsForPopupResponse,
  ListByFilterPageRequest,
  ListByFilterPageResponse,
} from '../grpc/grpcweb/component_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class ComponentsService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;
  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  fetchListByPage = async (
    RctToken: string,
    listComponentsRequest: ListComponentsByPageRequest,
  ) => {
    const request: ListComponentsByPageRequest = listComponentsRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.ListByPage, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListComponentsByPageResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch component list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchFilteredListByPage = async (
    RctToken: string,
    listFilteredComponentsRequest: ListByFilterPageRequest,
  ) => {
    const request: ListByFilterPageRequest = listFilteredComponentsRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.ListByFilter, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListByFilterPageResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch component list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchStandAloneListByPage = async (
    RctToken: string,
    listStandAloneComponentsRequest: ListWithNoRelationsRequest,
  ) => {
    const request: ListWithNoRelationsRequest = listStandAloneComponentsRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.ListWithNoRelations, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListWithNoRelationsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch stand-alone component list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchMyComponents = async (RctToken: string, listComponentsRequest: ListMyComponentsRequest) => {
    const request: ListMyComponentsRequest = listComponentsRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.ListMy, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListMyComponentsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch my components failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchComponentTypes = async (RctToken: string) => {
    const request = new ListCatalogItemsRequest();
    request.setType(CatalogItemType.CATALOG_ITEM_SLO_TARGET_TYPE);
    return new Promise((resolve, reject) => {
      grpc.unary(CatalogItemService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListCatalogItemsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getCatalogitemsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch component types failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  createComponent = async (RctToken: string, createComponentRequest: CreateComponentRequest) => {
    return new Promise((resolve, reject) => {
      const request: CreateComponentRequest = createComponentRequest;
      setTimeout(() => resolve('nice'), 3000);
      grpc.unary(ComponentService.Create, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<CreateComponentResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'create component failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteComponent = async (RctToken: string, deleteComponentRequest: DeleteComponentRequest) => {
    return new Promise((resolve, reject) => {
      const request: DeleteComponentRequest = deleteComponentRequest;
      setTimeout(() => resolve('nice'), 3000);
      grpc.unary(ComponentService.Delete, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteComponentResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'delete component failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  editComponent = async (RctToken: string, updateComponentRequest: UpdateComponentRequest) => {
    return new Promise((resolve, reject) => {
      const request: UpdateComponentRequest = updateComponentRequest;
      setTimeout(() => resolve('nice'), 3000);
      grpc.unary(ComponentService.Update, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateComponentResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getComponent());
          } else {
            reject(out.statusMessage);
            console.log(
              'update component failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchComponent = async (RctToken: string, getComponentRequest: GetComponentRequest) => {
    const request: GetComponentRequest = getComponentRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.Get, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetComponentResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getComponent());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch component by id failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchComponentByShortname = async (
    RctToken: string,
    getComponentRequest: GetComponentByShortnameRequest,
  ) => {
    const request: GetComponentByShortnameRequest = getComponentRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.GetByShortname, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetComponentResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getComponent());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch component by shortname failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchDependencies = async (
    RctToken: string,
    listRelationsRequest: ComponentListRelationsRequest,
  ) => {
    const request: ComponentListRelationsRequest = listRelationsRequest;

    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.ListRelations, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ComponentListRelationsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            const dependentBatch = out.message.getDependentsbatch();
            const dependencyBatch = out.message.getDependenciesbatch();

            return resolve({ dependentBatch, dependencyBatch });
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch component dependencies failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  setDependencies = async (RctToken: string, addDependenciesRequest: AddDependenciesRequest) => {
    const request: AddDependenciesRequest = addDependenciesRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.AddDependencies, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<AddDependenciesResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'add component dependencies failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  setDependents = async (RctToken: string, addDependenciesRequest: AddDependentsRequest) => {
    const request: AddDependentsRequest = addDependenciesRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.AddDependents, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<AddDependentsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'add component dependents failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  unlinkDependency = async (RctToken: string, removeDependencyRequest: RemoveDependencyRequest) => {
    const request: RemoveDependencyRequest = removeDependencyRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.RemoveDependency, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<RemoveDependencyResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'remove component dependency failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  unlinkDependant = async (RctToken: string, removeDependantRequest: RemoveDependantRequest) => {
    const request: RemoveDependantRequest = removeDependantRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.RemoveDependant, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<RemoveDependantResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'remove component dependant failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  addLink = async (RctToken: string, addLinkRequest: AddComponentLinkRequest) => {
    const request: AddComponentLinkRequest = addLinkRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.AddLink, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<AddComponentLinkResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject().linksList);
          } else {
            reject(out.statusMessage);
            console.log(
              'add component link failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  updateLink = async (RctToken: string, updateLinkRequest: UpdateComponentLinkRequest) => {
    const request: UpdateComponentLinkRequest = updateLinkRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.UpdateLink, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateComponentLinkResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject().linksList);
          } else {
            reject(out.statusMessage);
            console.log(
              'update component link failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteLink = async (RctToken: string, deleteLinkRequest: RemComponentLinkRequest) => {
    const request: RemComponentLinkRequest = deleteLinkRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.RemLink, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<RemComponentLinkResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject().linksList);
          } else {
            reject(out.statusMessage);
            console.log(
              'delete component link failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchDetailsForPopup = async (
    RctToken: string,
    getDetailsRequest: GetComponentDetailsForPopupRequest,
  ) => {
    const request: GetComponentDetailsForPopupRequest = getDetailsRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(ComponentService.GetDetailsForPopup, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetComponentDetailsForPopupResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'get component popup details failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
