import { grpc } from '@improbable-eng/grpc-web';
import { CommentsService } from '../grpc/grpcweb/comment_pb_service';
import {
  AddCommentRequest,
  AddCommentResponse,
  ListCommentsRequest,
  ListCommentsResponse,
} from '../grpc/grpcweb/comment_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class CommentService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  getSLOCommentsList = async (token: string, fetchSLOCommentsRequest: ListCommentsRequest) => {
    const request = fetchSLOCommentsRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(CommentsService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken: token }),
        onEnd: (out: grpc.UnaryOutput<ListCommentsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getCommentsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch slo comments failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  addSLOComment = async (token: string, addSLOCommentRequest: AddCommentRequest) => {
    const request = addSLOCommentRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(CommentsService.Add, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken: token }),
        onEnd: (out: grpc.UnaryOutput<AddCommentResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getComment());
          } else {
            reject(out.statusMessage);
            console.log(
              'publish slo comment failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
