import { FunctionComponent, useState, ReactNode, useMemo, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { validation, Appearance, AddTagFormData, Tag } from '../../definitions';
import { FormLayout, Select } from '../../components/Form';
import Tooltip from '../../components/Tooltip';
import { Icon } from '../../components/Icon';
import Button from '../../components/Button';
import TagKeyDropdownItem from './TagKeyDropdownItem';

type ComponentTagFormProps = {
  isLoading: boolean;
  onSubmit: (formData: AddTagFormData) => void;
  onCancel: () => void;
  initialValues: AddTagFormData;
  action: string;
  extraAction?: ReactNode;
  isDisabled?: boolean;
  icon: ReactNode;
  tagOptions: Tag[];
};

export const ComponentTagFormView: FunctionComponent<ComponentTagFormProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  action,
  extraAction,
  isDisabled = false,
  onCancel,
  icon,
  tagOptions,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const tagKeyOptions = useMemo(
    () =>
      tagOptions.map(tag => ({
        value: tag.keyId,
        label: tag.tagKey,
        description: tag.description,
      })),
    [tagOptions],
  );

  return (
    <Form
      autoComplete="off"
      onSubmit={(values: AddTagFormData) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          setClickedSubmit(true);
          handleSubmit(event);
        };

        const handleCancel = () => {
          form.reset({});
          onCancel();
        };

        const tagKeyId = form.getFieldState('tagId')?.value;

        const selectedTag = tagOptions.find(tag => tag.keyId === tagKeyId);
        const valueOptions =
          selectedTag?.values.map(value => ({
            value: value.id,
            label: value.value,
          })) || [];

        const getNoOptionsMessage = () => {
          if (!tagKeyId) {
            return <p>Please select tag first</p>;
          }
          return <p>The tag has no values assigned</p>;
        };

        return (
          <>
            <FormLayout.FieldSet>
              <Field
                id="tagId"
                name="tagId"
                label="Tag name"
                component={Select}
                options={tagKeyOptions}
                validate={value => validation.required(value)}
                showValidation={clickedSubmit}
                onReset={() => form.change('tagId', undefined)}
                formatOptionLabel={TagKeyDropdownItem}
                reservedSpace="3.125rem"
                description="Please choose the tag from the provided list."
                isSearchable={true}
              />
              <Field
                id="valueId"
                name="valueId"
                label="Value"
                component={Select}
                options={valueOptions}
                validate={value => validation.required(value)}
                showValidation={clickedSubmit}
                onReset={() => form.change('valueId', undefined)}
                getNoOptionsMessage={getNoOptionsMessage}
                description="Please select a value from the tag's value list. Tags without values cannot be assigned to the component."
              />
            </FormLayout.FieldSet>
            <FormLayout.ModalPreFooterSpacer />
            <FormLayout.ModalFooter>
              <Button
                onClick={handleCancel}
                isDisabled={isLoading}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Cross />
                <span>Cancel</span>
              </Button>
              {extraAction}
              {form.getFieldState('valueId')?.value ? (
                <FormLayout.ModalSubmit
                  onClick={onSubmitClick}
                  isPending={isLoading}
                  isDisabled={isDisabled || !Boolean(form.getFieldState('valueId')?.value)}
                  appearance={Appearance.PrimaryWithIcon}
                  isHighlighted={true}
                >
                  {icon}
                  <span>{action}</span>
                </FormLayout.ModalSubmit>
              ) : (
                <Tooltip
                  content={
                    form.getFieldState('tagId')?.value
                      ? 'The tag assigned to the component must contain a value.'
                      : 'Please select a tag.'
                  }
                >
                  <div ref={ref}>
                    <FormLayout.ModalSubmit
                      onClick={onSubmitClick}
                      isPending={isLoading}
                      isDisabled={isDisabled || !Boolean(form.getFieldState('valueId')?.value)}
                      appearance={Appearance.PrimaryWithIcon}
                      isHighlighted={true}
                    >
                      {icon}
                      <span>{action}</span>
                    </FormLayout.ModalSubmit>
                  </div>
                </Tooltip>
              )}
            </FormLayout.ModalFooter>
          </>
        );
      }}
    />
  );
};
