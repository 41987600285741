import { RootState } from '../reducers';

export const wasSuccessful = (
  state: RootState,
  actionType: string,
  defaultValue = false,
): boolean => {
  const success = state.success[actionType];
  return (success === undefined && defaultValue) || Boolean(success);
};
