import styled, { css } from 'styled-components';
import { rgba, darken } from 'polished';
import { RippleEffect } from '../RippleEffect';
import { TagAppearance, colors } from '../../definitions';

type ContentProps = {
  colour: string;
  appearance: TagAppearance;
  isRemovable: boolean;
};

type TagTextContainerProps = {
  colour: string;
  isContentClickable: boolean;
  appearance: TagAppearance;
};

type TextProps = {
  appearance: TagAppearance;
};

export const selectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: 'none !important',
    border: 'none',
    fontSize: '0.75rem',
    color: colors.secondaryHighlight,
    margin: 0,
    padding: 0,
    boxShadow: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: `1px solid ${colors.elsa}`,
    color: colors.elsa,
    border: 'none',
    background: state.isSelected ? colors.frost : colors.snowflake,
    padding: '0.5rem 1rem',
    fontSize: '0.75rem',
  }),
  placeholder: (provided: any) => ({
    ...provided,
  }),
  container: (provided: any) => ({
    ...provided,
    border: 'none',
    margin: 0,
    padding: 0,
    minWidth: '5rem',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
    border: 'none',
    margin: 0,
    padding: 0,
    minWidth: '7rem',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: colors.elsa,
    border: 'none',
    margin: 0,
    padding: 0,
    height: '1.875rem',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      color: colors.elsa,
      border: 'none',
      margin: 0,
      padding: 0,
    };
  },
};

export const Wrapper = styled.div<{ appearance: TagAppearance }>`
  max-width: 17rem;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  height: 1.75rem;

  ${props =>
    props.appearance === TagAppearance.Badge &&
    css`
      border-radius: 0.4rem;
      height: 1.25rem;
    `};

  ${props =>
    props.appearance === TagAppearance.Status &&
    css`
      border-radius: 0;
      height: 1.5rem;
    `};
`;

export const WhiteBackground = styled.div<{ appearance: TagAppearance }>`
  background-color: ${({ theme }) => theme.palettes.inverse};
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  width: 100%;

  ${props =>
    props.appearance === TagAppearance.Badge &&
    css`
      border-radius: 0.4rem;
    `};

  ${props =>
    props.appearance === TagAppearance.Status &&
    css`
      border-radius: 0;
    `};
`;

export const Content = styled.div<ContentProps>`
  padding: 0.25rem 0.5rem;
  background-color: ${props => rgba(props.colour, 0.1)};
  border-radius: 0.5rem;
  border: 1px solid ${props => props.colour};
  text-align: left;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  gap: 0.2rem;
  width: 100%;

  ${props =>
    props.appearance === TagAppearance.Badge &&
    css`
      padding: 0.15rem 0.25rem;
      border-radius: 0.4rem;
    `};

  ${props =>
    props.appearance === TagAppearance.Status &&
    css`
      border-radius: 0;
      border: none;
      background-color: ${props.colour};
    `};
`;

export const NewTagButtonWrapper = styled.div`
  display: flex;
  margin-left: -0.5rem;
`;

export const Text = styled.div<TextProps>`
  max-width: 4.5rem;
  min-width: 1.75rem;
  display: block;
  position: relative;

  p {
    font-size: 0.75rem;
    padding-top: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props =>
      props.appearance === TagAppearance.Badge &&
      css`
        font-size: 0.625rem;
      `};
  }
`;

export const TagKey = styled(Text)`
  transition: width 0.3s linear;
  display: block;
`;

export const ButtonWrapper = styled(RippleEffect)`
  height: auto;
  width: auto;
`;

export const RemoveButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-content: center;
  margin: 0 -0.25rem 0 -0.1rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${props => props.theme.palettes.placeholder};
  }

  &:hover {
    svg {
      fill: ${props => darken(0.2, props.theme.palettes.placeholder)};
    }
  }
`;

export const TagTextContainer = styled.div<TagTextContainerProps>`
  gap: 0.2rem;
  display: flex;
  cursor: ${props => (props.isContentClickable ? 'pointer' : 'default')};
  color: ${props => props.colour};

  ${props =>
    props.isContentClickable &&
    css`
      &:hover {
        color: ${darken(0.15, props.colour)};
      }
    `};

  ${props =>
    props.appearance === TagAppearance.Status &&
    css`
      color: ${props.theme.palettes.inverse};
    `};
`;

export const TagContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
`;
