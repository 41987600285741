import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { RouteChildrenProps, generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { usePageTitle } from '../../hooks';
import {
  createBotToken,
  fetchBotList,
  getBotList,
  isFetchingBotList,
  isCreatingBotToken,
  fetchBot,
  selectBotToken,
} from '../../store';
import { BotsView } from './Bots.view';
import { BotInTheList, paths } from '../../definitions';

type BotsContainerProps = {
  isEditDrawerOpen?: boolean;
} & RouteChildrenProps;

export const BotsContainer: FunctionComponent<BotsContainerProps> = ({
  isEditDrawerOpen,
  match,
  ...rest
}) => {
  usePageTitle('Bots');
  const dispatch = useDispatch();

  const { botShortname } = match?.params as { botShortname: BotInTheList['shortname'] };

  const isFetchingList = useSelector(isFetchingBotList);
  const list = useSelector(getBotList);
  const isCreatingToken = useSelector(isCreatingBotToken);

  useEffect(() => {
    dispatch(fetchBotList());
  }, [dispatch]);

  const handleCreateToken = useCallback(
    (botId: string) => {
      botId && dispatch(createBotToken(botId));
    },
    [dispatch],
  );

  const handleCloseUpdateBot = useCallback(() => {
    dispatch(push(paths.bots));
  }, [dispatch]);

  const handleOpenUpdateBot = useCallback(
    (shortname: string) => {
      if (shortname) {
        dispatch(push(generatePath(paths.bot, { botShortname: shortname })));
      }
    },
    [dispatch],
  );

  const handleSelectBotToken = useCallback(
    (tokenId: string, botId: string) => {
      if (tokenId && botId) {
        dispatch(selectBotToken({ tokenId, botId }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (isEditDrawerOpen) {
      setIsEditDrawerVisible(true);
    } else {
      setIsEditDrawerVisible(false);
    }
  }, [isEditDrawerOpen]);

  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);

  useEffect(() => {
    if (botShortname) {
      dispatch(fetchBot(botShortname));
    }
  }, [dispatch, botShortname, isEditDrawerVisible]);

  return (
    <BotsView
      isFetchingList={isFetchingList}
      list={list}
      onCreateBotToken={handleCreateToken}
      isCreatingToken={isCreatingToken}
      onCloseUpdateBot={handleCloseUpdateBot}
      match={match}
      isEditDrawerVisible={isEditDrawerVisible}
      onOpenUpdateBot={handleOpenUpdateBot}
      onSelectBotToken={handleSelectBotToken}
      {...rest}
    />
  );
};
