import { FunctionComponent } from 'react';
import Topbar from '../../components/Topbar';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import { Appearance, paths, theme, TopbarMode } from '../../definitions';
import * as Styled from './NotFound.styles';

type NotFoundProps = {
  isLoggedIn: boolean;
  onLogout: () => {};
};

export const NotFoundView: FunctionComponent<NotFoundProps> = ({ isLoggedIn, onLogout }) => {
  return (
    <>
      <Topbar isLoggedIn={isLoggedIn} onLogout={onLogout} mode={TopbarMode.Authenticate} />
      <Styled.Container>
        <Styled.NotFoundImage />
        <div>
          <p>
            YOU GOT LOST
            <Icon.Emoji />
          </p>
          <Button to={paths.landing} color={theme.palettes.highlight} appearance={Appearance.Funky}>
            <Icon.Rocket />
            <span>Take me home</span>
          </Button>
        </div>
      </Styled.Container>
    </>
  );
};
