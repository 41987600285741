// package: rct.web
// file: grpcweb/catalog_item.proto

var grpcweb_catalog_item_pb = require("../grpcweb/catalog_item_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CatalogItemService = (function () {
  function CatalogItemService() {}
  CatalogItemService.serviceName = "rct.web.CatalogItemService";
  return CatalogItemService;
}());

CatalogItemService.Create = {
  methodName: "Create",
  service: CatalogItemService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_catalog_item_pb.CreateCatalogItemRequest,
  responseType: grpcweb_catalog_item_pb.CreateCatalogItemResponse
};

CatalogItemService.List = {
  methodName: "List",
  service: CatalogItemService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_catalog_item_pb.ListCatalogItemsRequest,
  responseType: grpcweb_catalog_item_pb.ListCatalogItemsResponse
};

exports.CatalogItemService = CatalogItemService;

function CatalogItemServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CatalogItemServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CatalogItemService.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CatalogItemServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CatalogItemService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CatalogItemServiceClient = CatalogItemServiceClient;

