// package: rct.web
// file: grpcweb/tag.proto

var grpcweb_tag_pb = require("../grpcweb/tag_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TagsService = (function () {
  function TagsService() {}
  TagsService.serviceName = "rct.web.TagsService";
  return TagsService;
}());

TagsService.Create = {
  methodName: "Create",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.CreateTagRequest,
  responseType: grpcweb_tag_pb.CreateTagResponse
};

TagsService.Update = {
  methodName: "Update",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.UpdateTagRequest,
  responseType: grpcweb_tag_pb.UpdateTagResponse
};

TagsService.Delete = {
  methodName: "Delete",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.DeleteTagRequest,
  responseType: grpcweb_tag_pb.DeleteTagResponse
};

TagsService.Get = {
  methodName: "Get",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.GetTagRequest,
  responseType: grpcweb_tag_pb.GetTagResponse
};

TagsService.GetByKey = {
  methodName: "GetByKey",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.GetTagByKeyRequest,
  responseType: grpcweb_tag_pb.GetTagByKeyResponse
};

TagsService.GetByKeyValue = {
  methodName: "GetByKeyValue",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.GetTagByKeyValueRequest,
  responseType: grpcweb_tag_pb.GetTagByKeyValueResponse
};

TagsService.AddValue = {
  methodName: "AddValue",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.AddTagValueRequest,
  responseType: grpcweb_tag_pb.AddTagValueResponse
};

TagsService.UpdateValue = {
  methodName: "UpdateValue",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.UpdateTagValueRequest,
  responseType: grpcweb_tag_pb.UpdateTagValueResponse
};

TagsService.DeleteValue = {
  methodName: "DeleteValue",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.DeleteTagValueRequest,
  responseType: grpcweb_tag_pb.DeleteTagValueResponse
};

TagsService.ListAll = {
  methodName: "ListAll",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.ListAllTagsRequest,
  responseType: grpcweb_tag_pb.ListAllTagsResponse
};

TagsService.List = {
  methodName: "List",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.ListTagsRequest,
  responseType: grpcweb_tag_pb.ListTagsResponse
};

TagsService.Link = {
  methodName: "Link",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.LinkTagRequest,
  responseType: grpcweb_tag_pb.LinkTagResponse
};

TagsService.Unlink = {
  methodName: "Unlink",
  service: TagsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_tag_pb.UnlinkTagRequest,
  responseType: grpcweb_tag_pb.UnlinkTagResponse
};

exports.TagsService = TagsService;

function TagsServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TagsServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.get = function get(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.Get, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.getByKey = function getByKey(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.GetByKey, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.getByKeyValue = function getByKeyValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.GetByKeyValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.addValue = function addValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.AddValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.updateValue = function updateValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.UpdateValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.deleteValue = function deleteValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.DeleteValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.listAll = function listAll(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.ListAll, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.link = function link(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.Link, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TagsServiceClient.prototype.unlink = function unlink(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TagsService.Unlink, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TagsServiceClient = TagsServiceClient;

