export const TAG_LIST_PAGE_SIZE = 20;

export const TEAM_LIST_PAGE_SIZE = 100;
export const OWNER_TABLE_PAGE_SIZE = 20;

export const USER_LIST_PAGE_SIZE = 100;
export const USER_MEMBER_PAGE_SIZE = 20;

export const COMPONENT_LIST_PAGE_SIZE = 100;
export const DEPENDENCY_COMPONENT_PAGE_SIZE = 20;
export const SELECT_COMPONENT_PAGE_SIZE = 20;

export const X_EVENT_LIST_PAGE_SIZE = 10;
