import { FunctionComponent, InputHTMLAttributes } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Appearance } from '../../../definitions';
import { InputStyles } from '../../../components/Form/Input';
import { Icon } from '../../../components/Icon';
import Button from '../../../components/Button';
import * as Styled from './NewValue.styles';

type NewValueInputProps = InputHTMLAttributes<HTMLInputElement> &
  FieldRenderProps<string> & {
    remoteErrorMessage?: string;
    showValidation?: boolean;
    onSave: (value: string, valueId?: string) => void;
    errorMessage?: string;
    tagValue: string;
    valueId?: string;
    onSetValue: (value: string, valueId?: string) => void;
    maxWidth?: string;
    actionTitle: string;
    isLoading: boolean;
  };

const SaveValueButton: FunctionComponent<{
  onSave: () => void;
  actionTitle: string;
  isLoading: boolean;
}> = ({ onSave, actionTitle, isLoading }) => {
  return (
    <Button
      onClick={() => {
        onSave();
      }}
      appearance={Appearance.PrimaryWithIcon}
      isPending={isLoading}
    >
      <Icon.Save />
      <span>{actionTitle}</span>
    </Button>
  );
};

export const NewValueInputView: FunctionComponent<NewValueInputProps> = ({
  meta,
  remoteErrorMessage,
  showValidation,
  onSave,
  errorMessage,
  tagValue,
  onSetValue,
  maxWidth,
  children,
  valueId,
  actionTitle,
  isLoading,
}) => {
  const message =
    (meta.touched && meta.error && 'Tag must have at least one value assigned.') ||
    (!meta.dirtySinceLastSubmit &&
      (meta.submitError || remoteErrorMessage) &&
      'Tag must have at least one value assigned.');

  const validationMessage = (
    <InputStyles.Message type="error" isHidden={!showValidation && !errorMessage}>
      {errorMessage || message}
    </InputStyles.Message>
  );

  return (
    <>
      <Styled.Row maxWidth={maxWidth}>
        <InputStyles.Field
          type="text"
          value={tagValue}
          onChange={event => onSetValue(event.target.value, valueId)}
        />
        <Styled.Row>
          {children}
          <SaveValueButton
            isLoading={isLoading}
            onSave={() => onSave(tagValue, valueId)}
            actionTitle={actionTitle}
          />
        </Styled.Row>
      </Styled.Row>
      <InputStyles.MessageWrapper>{validationMessage}</InputStyles.MessageWrapper>
    </>
  );
};
