import { FunctionComponent, useState, useEffect, FormEvent } from 'react';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { debounce } from 'throttle-debounce';
import Avatar from '../Avatar';
import PopConfirm from '../PopConfirm';
import Button from '../Button';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import { User, colors, Appearance, AvatarType, FlattenedTeam } from '../../definitions';
import * as Styled from './UserListPopup.styles';

export type UserListPopupProps = {
  users: User[] | FlattenedTeam[];
  isEditMode?: boolean;
  onAddUser?: (userId: string) => void;
  isAddingUser?: boolean;
  addUserConfirmText?: string;
  isLoadingSuggestions?: boolean;
};

export const UserListPopupView: FunctionComponent<UserListPopupProps> = ({
  children,
  users,
  isEditMode,
  onAddUser,
  isAddingUser,
  addUserConfirmText,
  isLoadingSuggestions,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [setToClose, setSetToClose] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchString, setSearchString] = useState('');

  const handleClose = () => {
    setOpen(false);
    setSetToClose(false);
  };

  useEffect(() => {
    if (setToClose) {
      handleClose();
    }
  }, [setToClose]);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'bottom-start',
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
    onParentClose: () => {},
  });

  const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
    if ('username' in users[0]) {
      const typedUsers = users as User[];
      setSearchString(e.currentTarget.value);
      debounce(500, (e: any) => {
        setFilteredUsers(
          typedUsers.filter((user: User) =>
            user.fullname.toLowerCase().includes(e.target.value.toLocaleLowerCase().trim()),
          ),
        );
      })(e);
    }
  };

  const getAvatars = (usersOrTeams: User[] | FlattenedTeam[]) => {
    if ('username' in usersOrTeams[0]) {
      const typedUsers = usersOrTeams as User[];
      return typedUsers.map((user: User) => (
        <Styled.User key={user.id}>
          <Avatar name={user.username} avatarType={AvatarType.User} />
          <Styled.Meta>
            <h6>{user.fullname}</h6>
            <p>{user.email}</p>
          </Styled.Meta>
          {isEditMode && onAddUser && addUserConfirmText && (
            <PopConfirm
              title={`Are you sure you want to add this owner?`}
              onConfirm={() => onAddUser(user.id)}
              isConfirming={isAddingUser}
              isDisabled={isAddingUser}
              contentText={addUserConfirmText}
              confirmColor={colors.gold}
            >
              <Styled.ActionWrapper>
                <Button title="Add user" appearance={Appearance.Icon}>
                  <Icon.AddUser />
                </Button>
              </Styled.ActionWrapper>
            </PopConfirm>
          )}
        </Styled.User>
      ));
    }

    const typedUsers = usersOrTeams as FlattenedTeam[];
    return typedUsers.map((team: FlattenedTeam) => (
      <Styled.User key={team.id}>
        <Avatar name={team.displayname || team.shortname || 'Team'} avatarType={AvatarType.User} />
        <Styled.Meta>
          <h6>{team.displayname || team.shortname}</h6>
          <p>Number of members: {team.users.length}</p>
        </Styled.Meta>
        {isEditMode && onAddUser && addUserConfirmText && (
          <PopConfirm
            title={`Are you sure you want to add this owner?`}
            onConfirm={() => onAddUser(team.id)}
            isConfirming={isAddingUser}
            isDisabled={isAddingUser}
            contentText={addUserConfirmText}
            confirmColor={colors.gold}
          >
            <Styled.ActionWrapper>
              <Button title="Add user" appearance={Appearance.Icon}>
                <Icon.AddUser />
              </Button>
            </Styled.ActionWrapper>
          </PopConfirm>
        )}
      </Styled.User>
    ));
  };

  return (
    <>
      <Styled.TriggerWrapper {...triggerProps} onClick={() => setOpen(!isOpen)}>
        {children}
      </Styled.TriggerWrapper>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <Styled.PopupContent>
                {isEditMode && (
                  <Styled.SearchField>
                    <Styled.SearchIconWrapper>
                      <Icon.Search />
                    </Styled.SearchIconWrapper>
                    <Styled.Search
                      value={searchString}
                      onChange={handleNameChange}
                      placeholder="Search"
                    />
                  </Styled.SearchField>
                )}
                <Styled.ListWrapper>
                  {isLoadingSuggestions ? (
                    <Styled.CentredWrapper>
                      <Loader.Container>
                        <Loader />
                      </Loader.Container>
                    </Styled.CentredWrapper>
                  ) : filteredUsers.length ? (
                    getAvatars(filteredUsers)
                  ) : (
                    <Styled.CentredWrapper>
                      <Styled.EmptyText>No users found</Styled.EmptyText>
                    </Styled.CentredWrapper>
                  )}
                </Styled.ListWrapper>
              </Styled.PopupContent>
              <Arrow {...arrowProps} />
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
