import { XEventInTheList } from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { isLoading } from './loading';
import { RootState } from '../reducers';
import { getErrorMessage } from './errors';

export const getXEventList = (state: RootState): XEventInTheList[] => {
  // return [{
  //   id: '1',
  //   displayName: 'hello 1',
  //   description: 'hello',
  //   status: 'yo',
  //   sentiment: 1,
  //   url: 'efef',
  //   metafieldsList: [],
  //   startedat: new Date()
  // }, {
  //   id: '2d',
  //   displayName: 'Hello hello 2',
  //   description: 'hello 2',
  //   status: 'Yo yo',
  //   sentiment: 2,
  //   url: 'vdvd',
  //   metafieldsList: [{ label: 'vdvdvvddfd', value: 'vevevv svvdsvsdv' }, { label: 'sefbsrb', value: 'vevevv svvdsvsdv' }, { label: 'vdvsfbsfbdvvd', value: 'vevevv svvdsvsdv' }, { label: 'sddrgg', value: 'vevevv svvdsvsdv' }],
  //   startedat: new Date()
  // }];
  return state.xEvents.list;
};

export const isFetchingXEventList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.xEvents.fetchList);
};

export const getFetchXEventListError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.xEvents.fetchList);
};

export const isFetchingXEventListNextPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.xEvents.fetchNextPage);
};

export const isFetchingXEventListPrevPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.xEvents.fetchPrevPage);
};

export const getFetchXEventListNextPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.xEvents.fetchNextPage);
};

export const getFetchXEventListPrevPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.xEvents.fetchPrevPage);
};

export const getTotalNumberOfXEvents = (state: RootState): number => {
  return state.xEvents.totalNumber;
};

export const getXEventListNextPageToken = (state: RootState): string | undefined => {
  return state.xEvents.nextPageToken;
};

export const getXEventListPrevPageToken = (state: RootState): string | undefined => {
  return state.xEvents.prevPageToken;
};

export const getXEventListCurrPageToken = (state: RootState): string | undefined => {
  return state.xEvents.currPageToken;
};
