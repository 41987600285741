// package: rct.web
// file: grpcweb/team.proto

var grpcweb_team_pb = require("../grpcweb/team_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TeamService = (function () {
  function TeamService() {}
  TeamService.serviceName = "rct.web.TeamService";
  return TeamService;
}());

TeamService.Create = {
  methodName: "Create",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.CreateTeamRequest,
  responseType: grpcweb_team_pb.CreateTeamResponse
};

TeamService.Update = {
  methodName: "Update",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.UpdateTeamRequest,
  responseType: grpcweb_team_pb.UpdateTeamResponse
};

TeamService.Delete = {
  methodName: "Delete",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.DeleteTeamRequest,
  responseType: grpcweb_team_pb.DeleteTeamResponse
};

TeamService.GetByShortName = {
  methodName: "GetByShortName",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.GetTeamByShortNameRequest,
  responseType: grpcweb_team_pb.GetTeamByShortNameResponse
};

TeamService.GetById = {
  methodName: "GetById",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.GetTeamByIdRequest,
  responseType: grpcweb_team_pb.GetTeamByIdResponse
};

TeamService.List = {
  methodName: "List",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.ListTeamsRequest,
  responseType: grpcweb_team_pb.ListTeamsResponse
};

TeamService.ListAll = {
  methodName: "ListAll",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.ListAllTeamsRequest,
  responseType: grpcweb_team_pb.ListAllTeamsResponse
};

TeamService.ListMy = {
  methodName: "ListMy",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.ListMyTeamsRequest,
  responseType: grpcweb_team_pb.ListMyTeamsResponse
};

TeamService.AddMembers = {
  methodName: "AddMembers",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.AddMembersRequest,
  responseType: grpcweb_team_pb.AddMembersResponse
};

TeamService.DeleteMember = {
  methodName: "DeleteMember",
  service: TeamService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_team_pb.DeleteMemberRequest,
  responseType: grpcweb_team_pb.DeleteMemberResponse
};

exports.TeamService = TeamService;

function TeamServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TeamServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.getByShortName = function getByShortName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.GetByShortName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.getById = function getById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.GetById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.listAll = function listAll(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.ListAll, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.listMy = function listMy(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.ListMy, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.addMembers = function addMembers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.AddMembers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TeamServiceClient.prototype.deleteMember = function deleteMember(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TeamService.DeleteMember, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TeamServiceClient = TeamServiceClient;

