import { ActionType, SloFormData, FormStateType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const fetchSLOList = (payload = {}): ActionType => ({
  type: actionTypes.slos.fetchList,
  payload,
});

export const fetchSLOTypes = (payload = {}): ActionType => ({
  type: actionTypes.slos.fetchTypes,
  payload,
});

export const fetchSLO = (payload = {}): ActionType => ({
  type: actionTypes.slos.fetchOne,
  payload,
});

export const fetchSLOComments = (payload = {}): ActionType => ({
  type: actionTypes.slos.comments.fetch,
  payload,
});

export const addSLOComment = (payload = {}): ActionType => ({
  type: actionTypes.slos.comments.add,
  payload,
});

export const fetchSLOHistory = (payload = {}): ActionType => ({
  type: actionTypes.slos.fetchHistory,
  payload,
});

export const fetchSLOActivity = (payload = {}): ActionType => ({
  type: actionTypes.slos.activity.fetch,
  payload,
});

export const fetchSLOActivityComments = (payload = {}): ActionType => ({
  type: actionTypes.slos.activity.fetchComments,
  payload,
});

export const createSLO = (payload: {
  formData: SloFormData;
  componentId?: string;
}): ActionType => ({
  type: actionTypes.slos.create,
  payload,
});

export const updateExistingSlo = (payload: {
  formData: SloFormData;
  sloId: string;
}): ActionType => ({
  type: actionTypes.slos.update,
  payload,
});

export const updateNewSLO = (payload: FormStateType): ActionType => ({
  type: actionTypes.slos.updateNew,
  payload,
});

export const launchSLO = (payload = {}): ActionType => ({
  type: actionTypes.slos.launch,
  payload,
});

export const deleteSLO = (payload: { name: string; sloId: string }): ActionType => ({
  type: actionTypes.slos.delete,
  payload,
});

export const setSelectedComponent = (payload = {}): ActionType => ({
  type: actionTypes.slos.setComponent,
  payload,
});
