import React, { useState, FunctionComponent, Ref, InputHTMLAttributes } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Icon } from '../../Icon';
import { InputTooltip } from '../InputTooltip';
import * as Styled from './Input.styles';
import { Appearance } from '../../../definitions';

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  FieldRenderProps<string> & {
    label?: string;
    prefix?: string;
    postfix?: string;
    maxWidth?: string;
    help?: string;
    remoteErrorMessage?: string;
    validMessage?: string;
    showValidation?: boolean;
    icon?: typeof Icon;
    tooltip?: string;
    showRedBorderOnUnfocus?: boolean;
    autoComplete?: string;
    description?: string;
    isOptional?: boolean;
  };

export const InputView: FunctionComponent<InputProps> = React.forwardRef(
  (
    {
      className,
      label,
      prefix,
      postfix,
      help,
      maxWidth,
      remoteErrorMessage,
      validMessage,
      meta,
      input,
      autoFocus = false,
      showValidation = false,
      icon,
      readOnly = false,
      tooltip,
      placeholder,
      showRedBorderOnUnfocus = false,
      autoComplete,
      id,
      description,
      isOptional,
      ...rest
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const [type, setType] = useState(input.type);

    const errorMessage =
      (meta.touched && meta.error) ||
      (!meta.dirtySinceLastSubmit && (meta.submitError || remoteErrorMessage));

    const hasValue = Boolean(input?.value && String(input?.value).length);

    const showValidMessage =
      !readOnly && meta.valid && !meta.pristine && validMessage && !errorMessage;

    const validationMessage = showValidMessage ? (
      <Styled.Message type="success" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
        {validMessage}
      </Styled.Message>
    ) : (
      <Styled.Message type="error" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
        {errorMessage}
      </Styled.Message>
    );

    return (
      <Styled.Container
        ref={ref}
        className={className}
        hasLabel={Boolean(label)}
        invalid={Boolean(errorMessage)}
        hasValue={hasValue}
        readOnly={readOnly}
        valid={meta.valid && !errorMessage}
        showRedBorderOnUnfocus={showRedBorderOnUnfocus || showValidation}
        hasPrefix={!!prefix}
        hasIcon={!!icon}
        {...meta}
      >
        <Styled.Row>
          <Styled.Column>
            {(label || description) && (
              <Styled.LabelWrapper>
                {label && (
                  <Styled.Label>
                    {label}
                    {isOptional && <Styled.Optional>(optional)</Styled.Optional>}
                  </Styled.Label>
                )}
                {description && <Styled.Description>{description}</Styled.Description>}
              </Styled.LabelWrapper>
            )}
            <Styled.FloatWrapper>
              <Styled.Field
                {...input}
                {...meta}
                placeholder={placeholder}
                type={type}
                value={input.value || ''}
                autoFocus={autoFocus}
                {...rest}
                autoComplete={autoComplete}
                id={id}
                maxWidth={maxWidth}
              />
              {icon && <Styled.InputIcon>{icon}</Styled.InputIcon>}
              {prefix && <Styled.Prefix>{prefix}</Styled.Prefix>}
              {input.type === 'password' && (
                <Styled.Toggle
                  appearance={Appearance.Icon}
                  isActive={type === 'text'}
                  onClick={() => setType(state => (state === 'password' ? 'text' : 'password'))}
                >
                  <Icon.Eye />
                </Styled.Toggle>
              )}
              {postfix && <Styled.Postfix>{postfix}</Styled.Postfix>}
            </Styled.FloatWrapper>
          </Styled.Column>
          {tooltip && <InputTooltip content={tooltip} />}
        </Styled.Row>
        <Styled.MessageWrapper>{validationMessage}</Styled.MessageWrapper>
      </Styled.Container>
    );
  },
);
