import styled from 'styled-components';
import { lighten } from 'polished';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  border-top: 1px solid ${({ theme }) => lighten(0.15, theme.palettes.border)};
  padding: 0.75rem 0;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.palettes.placeholder};
  }
`;
