import { useLayoutEffect } from 'react';

export function useScrollLock(): void {
  useLayoutEffect(() => {
    const scrollBarWidth = window.innerWidth - document.body.clientWidth;

    document.body.style.overflowY = 'hidden';
    document.body.style.paddingRight = `${scrollBarWidth}px`;

    return () => {
      document.body.style.overflowY = 'scroll';
      document.body.style.paddingRight = '';
    };
  }, []);
}
