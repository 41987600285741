import { FunctionComponent, ElementType } from 'react';
import { Icon } from '../Icon';
import * as Styled from './Loader.styles';

type LoaderProps = {};

type ILoader = FunctionComponent<LoaderProps> & {
  Container: ElementType;
};

export const LoaderView: ILoader = () => {
  return (
    <Styled.InfiniteRotationDiv>
      <Icon.Spinner />
    </Styled.InfiniteRotationDiv>
  );
};

LoaderView.Container = Styled.LoaderContainer;
