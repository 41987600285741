import { FunctionComponent, useState, ReactNode } from 'react';
import { Form, Field } from 'react-final-form';
import {
  validation,
  parsers,
  UpdateLinkFormData,
  Appearance,
  AddLinkFormData,
} from '../../definitions';
import { Input, FormLayout } from '../../components/Form';
import { Icon } from '../../components/Icon';
import Button from '../../components/Button';

type LinkFormProps = {
  isLoading: boolean;
  onSubmit: (formData: AddLinkFormData | UpdateLinkFormData) => void;
  onCancel: () => void;
  initialValues: AddLinkFormData | UpdateLinkFormData;
  action: string;
  extraAction?: ReactNode;
  isDisabled?: boolean;
  icon: ReactNode;
};

export const LinkFormView: FunctionComponent<LinkFormProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  action,
  extraAction,
  isDisabled = false,
  onCancel,
  icon,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

  return (
    <Form
      autoComplete="off"
      onSubmit={(values: AddLinkFormData) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          setClickedSubmit(true);
          handleSubmit(event);
        };

        const handleCancel = () => {
          form.reset({});
          onCancel();
        };

        return (
          <>
            <FormLayout.FieldSet>
              <Field
                id="url"
                name="url"
                label="URL"
                component={Input}
                validate={validation.compose(
                  value => validation.required(value),
                  value => validation.url(value),
                )}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                maxLength="120"
                description="Enter the complete web address (URL) of the resource you wish to link to."
              />
              <Field
                id="displayname"
                name="displayname"
                label="Display text"
                component={Input}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                maxLength="120"
                isOptional={true}
                description="Provide the text that will be displayed in place of the URL."
              />
            </FormLayout.FieldSet>
            <FormLayout.ModalPreFooterSpacer />
            <FormLayout.ModalFooter>
              <Button
                onClick={handleCancel}
                isDisabled={isLoading}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Cross />
                <span>Cancel</span>
              </Button>
              {extraAction}
              <FormLayout.ModalSubmit
                onClick={onSubmitClick}
                isPending={isLoading}
                isDisabled={isDisabled}
                appearance={Appearance.PrimaryWithIcon}
                isHighlighted={true}
              >
                {icon}
                <span>{action}</span>
              </FormLayout.ModalSubmit>
            </FormLayout.ModalFooter>
          </>
        );
      }}
    />
  );
};
