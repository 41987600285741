import { grpc } from '@improbable-eng/grpc-web';
import { EventSourceService } from '../grpc/grpcweb/eventsource_pb_service';
import {
  ListEventSourceRequest,
  ListEventSourceResponse,
  CreateEventSourceRequest,
  CreateEventSourceResponse,
  UpdateEventSourceRequest,
  UpdateEventSourceResponse,
  GetEventSourceRequest,
  GetEventSourceResponse,
  DeleteEventSourceRequest,
  DeleteEventSourceResponse,
} from '../grpc/grpcweb/eventsource_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class EventSourcesService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  fetchList = async (RctToken: string) => {
    const request = new ListEventSourceRequest();

    return new Promise((resolve, reject) => {
      grpc.unary(EventSourceService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListEventSourceResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getEventsourcesList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch event source list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  createNewEventSource = async (RctToken: string, createEventSource: CreateEventSourceRequest) => {
    return new Promise((resolve, reject) => {
      const request: CreateEventSourceRequest = createEventSource;
      console.log(request);
      grpc.unary(EventSourceService.Create, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<CreateEventSourceResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'create event source failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchOneEventSource = async (RctToken: string, sourceId: string) => {
    const request = new GetEventSourceRequest();
    request.setId(sourceId);
    return new Promise((resolve, reject) => {
      grpc.unary(EventSourceService.Get, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetEventSourceResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getEventsource());
          } else {
            reject(out.statusMessage);
            console.log(
              `fetch event source with id ${sourceId} failed`,
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  updateEventSource = async (RctToken: string, updateEventSource: UpdateEventSourceRequest) => {
    return new Promise((resolve, reject) => {
      const request: UpdateEventSourceRequest = updateEventSource;
      grpc.unary(EventSourceService.Update, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateEventSourceResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'update event source failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteEventSource = async (RctToken: string, deleteEventSource: DeleteEventSourceRequest) => {
    return new Promise((resolve, reject) => {
      const request: DeleteEventSourceRequest = deleteEventSource;
      grpc.unary(EventSourceService.Delete, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteEventSourceResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'delete event source failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
