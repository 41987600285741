import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import EventSourceForm from '../../forms/EventSource';
import {
  CreateSourceFormDataWithAuthType,
  FormStateType,
  EditSourceFormData,
} from '../../definitions';
import { FormLayout } from '../../components/Form';

type CreateEventSourceProps = {
  onClose: () => void;
  onResetState: () => void;
  isOpen: boolean;
  isCreating: boolean;
  onCreateEventSource: (formData: CreateSourceFormDataWithAuthType | EditSourceFormData) => void;
  initialValues: CreateSourceFormDataWithAuthType;
  onUpdateEventSourceState: (formState: FormStateType) => void;
};

export const CreateEventSourceView: FunctionComponent<CreateEventSourceProps> = ({
  children,
  onClose,
  onCreateEventSource,
  isCreating,
  initialValues,
  onUpdateEventSourceState,
  onResetState,
  ...rest
}) => {
  const handleCancel = () => {
    onClose();
    onResetState();
  };

  return (
    <Modal onClose={onClose} title="Create event source" {...rest}>
      <FormLayout isModal={true}>
        <EventSourceForm
          onSubmit={onCreateEventSource}
          initialValues={initialValues}
          isLoading={isCreating}
          onUpdateEventSourceState={onUpdateEventSourceState}
          action="Create"
          onCancel={handleCancel}
        />
      </FormLayout>
    </Modal>
  );
};
