import { FunctionComponent } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePrevious, useMeasure } from '../../hooks';
import * as Styled from './Accordion.styles';

export type AccordionProps = {
  isExpanded?: boolean;
};

export const AccordionView: FunctionComponent<AccordionProps> = ({
  isExpanded = false,
  children,
  ...rest
}) => {
  const previous = usePrevious(isExpanded);
  const [bind, { height: viewHeight }] = useMeasure();
  const { height } = useSpring({
    from: { height: 0 },
    to: { height: isExpanded ? viewHeight : 0 },
  });

  return (
    <Styled.Container
      aria-hidden={isExpanded ? 'false' : 'true'}
      style={{
        height: isExpanded && (previous === undefined || previous === isExpanded) ? 'auto' : height,
        overflow: isExpanded ? 'visible' : 'hidden',
      }}
      {...rest}
    >
      <animated.div {...bind}>{children}</animated.div>
    </Styled.Container>
  );
};
