import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { darken } from 'polished';

type LinkProps = {
  isPending: boolean;
  disabled?: boolean;
  color?: string;
};

const linkStyles = css<LinkProps>`
  color: ${props => props.color ?? props.theme.palettes.secondaryHighlight};
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &:hover {
    &&& {
      text-decoration: underline;
      color: ${props =>
        props.color
          ? darken(0.1, props.color)
          : darken(0.1, props.theme.palettes.secondaryHighlight)};
    }
  }

  &[disabled] {
    color: ${({ theme }) => theme.palettes.placeholder};
    pointer-events: none;
  }

  ${props =>
    props.isPending &&
    css`
      pointer-events: none;
    `}

  ${props =>
    props.disabled &&
    css`
      color: ${({ theme }) => theme.palettes.placeholder};
      pointer-events: none;
    `}
`;

export const RouterLink = styled(({ to, isActive, exact, className, children }) => (
  <NavLink
    className={className}
    to={to}
    exact={exact}
    isActive={(match, location) => {
      const hash = to.split('#')?.[1];

      if (hash) {
        return `#${hash}` === location.hash;
      }

      return isActive ? isActive(match, location) : match;
    }}
  >
    {children}
  </NavLink>
))`
  ${linkStyles}
`;

export const Anchor = styled.a`
  ${linkStyles}
`;

export const Button = styled.button`
  ${linkStyles}
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  appearance: none;
  cursor: pointer;
  align-items: center;
  align-content: center;
  display: inline-flex;
`;
