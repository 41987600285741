import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import {
  getSelectedTeam,
  updatedTeamSuccessfully,
  isUpdatingTeam,
  updateTeam,
  deleteTeam,
  deletedTeamSuccessfully,
  getSelectedTeamId,
  isDeletingTeam,
  isFetchingTeamById,
  isFetchingTeam,
} from '../../store';
import { TeamFormData, paths } from '../../definitions';
import { UpdateTeamView } from './UpdateTeam.view';

type UpdateTeamContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const UpdateTeamContainer: FunctionComponent<UpdateTeamContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isUpdating = useSelector(isUpdatingTeam);
  const isDeleting = useSelector(isDeletingTeam);
  const isFetchingById = useSelector(isFetchingTeamById);
  const isFetching = useSelector(isFetchingTeam);

  const finishedUpdating = useSelector(updatedTeamSuccessfully);
  const finishedDeleting = useSelector(deletedTeamSuccessfully);
  const initialValues = useSelector(getSelectedTeam);
  const teamId = useSelector(getSelectedTeamId);

  const formData: TeamFormData = {
    displayname: initialValues.displayname,
    shortname: initialValues.shortname,
    description: initialValues.description,
    avatarUrl: initialValues.avatarUrl,
  };

  const handleUpdateTeam = useCallback(
    (formData: TeamFormData) => {
      const filteredDataToUpdate: TeamFormData = {
        shortname: formData.shortname,
      };

      if (formData.description !== initialValues.description) {
        filteredDataToUpdate.description = !formData.description ? '' : formData.description;
      }
      if (formData.displayname !== initialValues.displayname) {
        filteredDataToUpdate.displayname = !formData.displayname ? '' : formData.displayname;
      }

      teamId && dispatch(updateTeam({ formData: filteredDataToUpdate, teamId }));
    },
    [dispatch, teamId, initialValues.description, initialValues.displayname],
  );

  const handleDeleteTeam = useCallback(() => {
    teamId && dispatch(deleteTeam(teamId));
  }, [dispatch, teamId]);

  useEffect(() => {
    if (finishedUpdating) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedUpdating]);

  useEffect(() => {
    if (finishedDeleting) {
      onClose();

      if (location.pathname !== paths.teams) {
        dispatch(push(paths.teams));
      }
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedDeleting]);

  return (
    <UpdateTeamView
      isOpen={isOpen}
      onClose={onClose}
      onUpdateTeam={handleUpdateTeam}
      initialValues={formData}
      isUpdating={isUpdating}
      isFetching={isFetching || isFetchingById}
      onDeleteTeam={handleDeleteTeam}
      isDeleting={isDeleting}
      hasMembers={Boolean(initialValues.users.length)}
    />
  );
};
