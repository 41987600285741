// source: grpcweb/notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var grpcweb_slo_pb = require('../grpcweb/slo_pb.js');
goog.object.extend(proto, grpcweb_slo_pb);
var grpcweb_comment_pb = require('../grpcweb/comment_pb.js');
goog.object.extend(proto, grpcweb_comment_pb);
goog.exportSymbol('proto.rct.web.ListNotificationsRequest', null, global);
goog.exportSymbol('proto.rct.web.ListNotificationsResponse', null, global);
goog.exportSymbol('proto.rct.web.MarkAllNotificationsAsReadRequest', null, global);
goog.exportSymbol('proto.rct.web.MarkAllNotificationsAsReadResponse', null, global);
goog.exportSymbol('proto.rct.web.Notification', null, global);
goog.exportSymbol('proto.rct.web.Notification.CommentAdded', null, global);
goog.exportSymbol('proto.rct.web.Notification.SloBreachEnded', null, global);
goog.exportSymbol('proto.rct.web.Notification.SloBreachStarted', null, global);
goog.exportSymbol('proto.rct.web.Notification.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Notification.displayName = 'proto.rct.web.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Notification.CommentAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Notification.CommentAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Notification.CommentAdded.displayName = 'proto.rct.web.Notification.CommentAdded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Notification.SloBreachStarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Notification.SloBreachStarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Notification.SloBreachStarted.displayName = 'proto.rct.web.Notification.SloBreachStarted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Notification.SloBreachEnded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.Notification.SloBreachEnded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Notification.SloBreachEnded.displayName = 'proto.rct.web.Notification.SloBreachEnded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListNotificationsRequest.displayName = 'proto.rct.web.ListNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListNotificationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ListNotificationsResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ListNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListNotificationsResponse.displayName = 'proto.rct.web.ListNotificationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.MarkAllNotificationsAsReadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.MarkAllNotificationsAsReadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.MarkAllNotificationsAsReadRequest.displayName = 'proto.rct.web.MarkAllNotificationsAsReadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.MarkAllNotificationsAsReadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.MarkAllNotificationsAsReadResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.MarkAllNotificationsAsReadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.MarkAllNotificationsAsReadResponse.displayName = 'proto.rct.web.MarkAllNotificationsAsReadResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdat: jspb.Message.getFieldWithDefault(msg, 3, 0),
    datacommentadded: (f = msg.getDatacommentadded()) && proto.rct.web.Notification.CommentAdded.toObject(includeInstance, f),
    dataslobreachstarted: (f = msg.getDataslobreachstarted()) && proto.rct.web.Notification.SloBreachStarted.toObject(includeInstance, f),
    dataslobreachended: (f = msg.getDataslobreachended()) && proto.rct.web.Notification.SloBreachEnded.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Notification}
 */
proto.rct.web.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Notification;
  return proto.rct.web.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Notification}
 */
proto.rct.web.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.rct.web.Notification.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 4:
      var value = new proto.rct.web.Notification.CommentAdded;
      reader.readMessage(value,proto.rct.web.Notification.CommentAdded.deserializeBinaryFromReader);
      msg.setDatacommentadded(value);
      break;
    case 5:
      var value = new proto.rct.web.Notification.SloBreachStarted;
      reader.readMessage(value,proto.rct.web.Notification.SloBreachStarted.deserializeBinaryFromReader);
      msg.setDataslobreachstarted(value);
      break;
    case 6:
      var value = new proto.rct.web.Notification.SloBreachEnded;
      reader.readMessage(value,proto.rct.web.Notification.SloBreachEnded.deserializeBinaryFromReader);
      msg.setDataslobreachended(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDatacommentadded();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.rct.web.Notification.CommentAdded.serializeBinaryToWriter
    );
  }
  f = message.getDataslobreachstarted();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.rct.web.Notification.SloBreachStarted.serializeBinaryToWriter
    );
  }
  f = message.getDataslobreachended();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.rct.web.Notification.SloBreachEnded.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.rct.web.Notification.Type = {
  TYPE_UNSPECIFIED: 0,
  TYPE_COMMENT_ADDED: 1,
  TYPE_SLO_BREACH_STARTED: 2,
  TYPE_SLO_BREACH_ENDED: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Notification.CommentAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Notification.CommentAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Notification.CommentAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.CommentAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    comment: (f = msg.getComment()) && grpcweb_comment_pb.Comment.toObject(includeInstance, f),
    slo: (f = msg.getSlo()) && grpcweb_slo_pb.SloView.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && grpcweb_slo_pb.Activity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Notification.CommentAdded}
 */
proto.rct.web.Notification.CommentAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Notification.CommentAdded;
  return proto.rct.web.Notification.CommentAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Notification.CommentAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Notification.CommentAdded}
 */
proto.rct.web.Notification.CommentAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpcweb_comment_pb.Comment;
      reader.readMessage(value,grpcweb_comment_pb.Comment.deserializeBinaryFromReader);
      msg.setComment(value);
      break;
    case 2:
      var value = new grpcweb_slo_pb.SloView;
      reader.readMessage(value,grpcweb_slo_pb.SloView.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    case 3:
      var value = new grpcweb_slo_pb.Activity;
      reader.readMessage(value,grpcweb_slo_pb.Activity.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Notification.CommentAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Notification.CommentAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Notification.CommentAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.CommentAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      grpcweb_comment_pb.Comment.serializeBinaryToWriter
    );
  }
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpcweb_slo_pb.SloView.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      grpcweb_slo_pb.Activity.serializeBinaryToWriter
    );
  }
};


/**
 * optional Comment comment = 1;
 * @return {?proto.rct.web.Comment}
 */
proto.rct.web.Notification.CommentAdded.prototype.getComment = function() {
  return /** @type{?proto.rct.web.Comment} */ (
    jspb.Message.getWrapperField(this, grpcweb_comment_pb.Comment, 1));
};


/**
 * @param {?proto.rct.web.Comment|undefined} value
 * @return {!proto.rct.web.Notification.CommentAdded} returns this
*/
proto.rct.web.Notification.CommentAdded.prototype.setComment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification.CommentAdded} returns this
 */
proto.rct.web.Notification.CommentAdded.prototype.clearComment = function() {
  return this.setComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.CommentAdded.prototype.hasComment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SloView slo = 2;
 * @return {?proto.rct.web.SloView}
 */
proto.rct.web.Notification.CommentAdded.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloView} */ (
    jspb.Message.getWrapperField(this, grpcweb_slo_pb.SloView, 2));
};


/**
 * @param {?proto.rct.web.SloView|undefined} value
 * @return {!proto.rct.web.Notification.CommentAdded} returns this
*/
proto.rct.web.Notification.CommentAdded.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification.CommentAdded} returns this
 */
proto.rct.web.Notification.CommentAdded.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.CommentAdded.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Activity activity = 3;
 * @return {?proto.rct.web.Activity}
 */
proto.rct.web.Notification.CommentAdded.prototype.getActivity = function() {
  return /** @type{?proto.rct.web.Activity} */ (
    jspb.Message.getWrapperField(this, grpcweb_slo_pb.Activity, 3));
};


/**
 * @param {?proto.rct.web.Activity|undefined} value
 * @return {!proto.rct.web.Notification.CommentAdded} returns this
*/
proto.rct.web.Notification.CommentAdded.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification.CommentAdded} returns this
 */
proto.rct.web.Notification.CommentAdded.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.CommentAdded.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Notification.SloBreachStarted.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Notification.SloBreachStarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Notification.SloBreachStarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.SloBreachStarted.toObject = function(includeInstance, msg) {
  var f, obj = {
    activity: (f = msg.getActivity()) && grpcweb_slo_pb.Activity.toObject(includeInstance, f),
    slo: (f = msg.getSlo()) && grpcweb_slo_pb.SloView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Notification.SloBreachStarted}
 */
proto.rct.web.Notification.SloBreachStarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Notification.SloBreachStarted;
  return proto.rct.web.Notification.SloBreachStarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Notification.SloBreachStarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Notification.SloBreachStarted}
 */
proto.rct.web.Notification.SloBreachStarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpcweb_slo_pb.Activity;
      reader.readMessage(value,grpcweb_slo_pb.Activity.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    case 2:
      var value = new grpcweb_slo_pb.SloView;
      reader.readMessage(value,grpcweb_slo_pb.SloView.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Notification.SloBreachStarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Notification.SloBreachStarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Notification.SloBreachStarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.SloBreachStarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      grpcweb_slo_pb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpcweb_slo_pb.SloView.serializeBinaryToWriter
    );
  }
};


/**
 * optional Activity activity = 1;
 * @return {?proto.rct.web.Activity}
 */
proto.rct.web.Notification.SloBreachStarted.prototype.getActivity = function() {
  return /** @type{?proto.rct.web.Activity} */ (
    jspb.Message.getWrapperField(this, grpcweb_slo_pb.Activity, 1));
};


/**
 * @param {?proto.rct.web.Activity|undefined} value
 * @return {!proto.rct.web.Notification.SloBreachStarted} returns this
*/
proto.rct.web.Notification.SloBreachStarted.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification.SloBreachStarted} returns this
 */
proto.rct.web.Notification.SloBreachStarted.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.SloBreachStarted.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SloView slo = 2;
 * @return {?proto.rct.web.SloView}
 */
proto.rct.web.Notification.SloBreachStarted.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloView} */ (
    jspb.Message.getWrapperField(this, grpcweb_slo_pb.SloView, 2));
};


/**
 * @param {?proto.rct.web.SloView|undefined} value
 * @return {!proto.rct.web.Notification.SloBreachStarted} returns this
*/
proto.rct.web.Notification.SloBreachStarted.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification.SloBreachStarted} returns this
 */
proto.rct.web.Notification.SloBreachStarted.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.SloBreachStarted.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Notification.SloBreachEnded.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Notification.SloBreachEnded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Notification.SloBreachEnded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.SloBreachEnded.toObject = function(includeInstance, msg) {
  var f, obj = {
    activity: (f = msg.getActivity()) && grpcweb_slo_pb.Activity.toObject(includeInstance, f),
    slo: (f = msg.getSlo()) && grpcweb_slo_pb.SloView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Notification.SloBreachEnded}
 */
proto.rct.web.Notification.SloBreachEnded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Notification.SloBreachEnded;
  return proto.rct.web.Notification.SloBreachEnded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Notification.SloBreachEnded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Notification.SloBreachEnded}
 */
proto.rct.web.Notification.SloBreachEnded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new grpcweb_slo_pb.Activity;
      reader.readMessage(value,grpcweb_slo_pb.Activity.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    case 2:
      var value = new grpcweb_slo_pb.SloView;
      reader.readMessage(value,grpcweb_slo_pb.SloView.deserializeBinaryFromReader);
      msg.setSlo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Notification.SloBreachEnded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Notification.SloBreachEnded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Notification.SloBreachEnded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Notification.SloBreachEnded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      grpcweb_slo_pb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getSlo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpcweb_slo_pb.SloView.serializeBinaryToWriter
    );
  }
};


/**
 * optional Activity activity = 1;
 * @return {?proto.rct.web.Activity}
 */
proto.rct.web.Notification.SloBreachEnded.prototype.getActivity = function() {
  return /** @type{?proto.rct.web.Activity} */ (
    jspb.Message.getWrapperField(this, grpcweb_slo_pb.Activity, 1));
};


/**
 * @param {?proto.rct.web.Activity|undefined} value
 * @return {!proto.rct.web.Notification.SloBreachEnded} returns this
*/
proto.rct.web.Notification.SloBreachEnded.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification.SloBreachEnded} returns this
 */
proto.rct.web.Notification.SloBreachEnded.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.SloBreachEnded.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SloView slo = 2;
 * @return {?proto.rct.web.SloView}
 */
proto.rct.web.Notification.SloBreachEnded.prototype.getSlo = function() {
  return /** @type{?proto.rct.web.SloView} */ (
    jspb.Message.getWrapperField(this, grpcweb_slo_pb.SloView, 2));
};


/**
 * @param {?proto.rct.web.SloView|undefined} value
 * @return {!proto.rct.web.Notification.SloBreachEnded} returns this
*/
proto.rct.web.Notification.SloBreachEnded.prototype.setSlo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification.SloBreachEnded} returns this
 */
proto.rct.web.Notification.SloBreachEnded.prototype.clearSlo = function() {
  return this.setSlo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.SloBreachEnded.prototype.hasSlo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.Notification.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.Notification} returns this
 */
proto.rct.web.Notification.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.rct.web.Notification.Type}
 */
proto.rct.web.Notification.prototype.getType = function() {
  return /** @type {!proto.rct.web.Notification.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rct.web.Notification.Type} value
 * @return {!proto.rct.web.Notification} returns this
 */
proto.rct.web.Notification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 createdAt = 3;
 * @return {number}
 */
proto.rct.web.Notification.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.Notification} returns this
 */
proto.rct.web.Notification.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional CommentAdded dataCommentAdded = 4;
 * @return {?proto.rct.web.Notification.CommentAdded}
 */
proto.rct.web.Notification.prototype.getDatacommentadded = function() {
  return /** @type{?proto.rct.web.Notification.CommentAdded} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Notification.CommentAdded, 4));
};


/**
 * @param {?proto.rct.web.Notification.CommentAdded|undefined} value
 * @return {!proto.rct.web.Notification} returns this
*/
proto.rct.web.Notification.prototype.setDatacommentadded = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification} returns this
 */
proto.rct.web.Notification.prototype.clearDatacommentadded = function() {
  return this.setDatacommentadded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.prototype.hasDatacommentadded = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SloBreachStarted dataSloBreachStarted = 5;
 * @return {?proto.rct.web.Notification.SloBreachStarted}
 */
proto.rct.web.Notification.prototype.getDataslobreachstarted = function() {
  return /** @type{?proto.rct.web.Notification.SloBreachStarted} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Notification.SloBreachStarted, 5));
};


/**
 * @param {?proto.rct.web.Notification.SloBreachStarted|undefined} value
 * @return {!proto.rct.web.Notification} returns this
*/
proto.rct.web.Notification.prototype.setDataslobreachstarted = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification} returns this
 */
proto.rct.web.Notification.prototype.clearDataslobreachstarted = function() {
  return this.setDataslobreachstarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.prototype.hasDataslobreachstarted = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SloBreachEnded dataSloBreachEnded = 6;
 * @return {?proto.rct.web.Notification.SloBreachEnded}
 */
proto.rct.web.Notification.prototype.getDataslobreachended = function() {
  return /** @type{?proto.rct.web.Notification.SloBreachEnded} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Notification.SloBreachEnded, 6));
};


/**
 * @param {?proto.rct.web.Notification.SloBreachEnded|undefined} value
 * @return {!proto.rct.web.Notification} returns this
*/
proto.rct.web.Notification.prototype.setDataslobreachended = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Notification} returns this
 */
proto.rct.web.Notification.prototype.clearDataslobreachended = function() {
  return this.setDataslobreachended(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Notification.prototype.hasDataslobreachended = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListNotificationsRequest}
 */
proto.rct.web.ListNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListNotificationsRequest;
  return proto.rct.web.ListNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListNotificationsRequest}
 */
proto.rct.web.ListNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ListNotificationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListNotificationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListNotificationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListNotificationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListNotificationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.rct.web.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListNotificationsResponse}
 */
proto.rct.web.ListNotificationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListNotificationsResponse;
  return proto.rct.web.ListNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListNotificationsResponse}
 */
proto.rct.web.ListNotificationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Notification;
      reader.readMessage(value,proto.rct.web.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListNotificationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListNotificationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListNotificationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Notification notifications = 1;
 * @return {!Array<!proto.rct.web.Notification>}
 */
proto.rct.web.ListNotificationsResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.rct.web.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.Notification, 1));
};


/**
 * @param {!Array<!proto.rct.web.Notification>} value
 * @return {!proto.rct.web.ListNotificationsResponse} returns this
*/
proto.rct.web.ListNotificationsResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Notification}
 */
proto.rct.web.ListNotificationsResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ListNotificationsResponse} returns this
 */
proto.rct.web.ListNotificationsResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.MarkAllNotificationsAsReadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.MarkAllNotificationsAsReadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastreadnotificationcreationtime: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.MarkAllNotificationsAsReadRequest}
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.MarkAllNotificationsAsReadRequest;
  return proto.rct.web.MarkAllNotificationsAsReadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.MarkAllNotificationsAsReadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.MarkAllNotificationsAsReadRequest}
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastreadnotificationcreationtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.MarkAllNotificationsAsReadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.MarkAllNotificationsAsReadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastreadnotificationcreationtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 lastReadNotificationCreationTime = 1;
 * @return {number}
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.prototype.getLastreadnotificationcreationtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.MarkAllNotificationsAsReadRequest} returns this
 */
proto.rct.web.MarkAllNotificationsAsReadRequest.prototype.setLastreadnotificationcreationtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.MarkAllNotificationsAsReadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.MarkAllNotificationsAsReadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.rct.web.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.MarkAllNotificationsAsReadResponse}
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.MarkAllNotificationsAsReadResponse;
  return proto.rct.web.MarkAllNotificationsAsReadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.MarkAllNotificationsAsReadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.MarkAllNotificationsAsReadResponse}
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Notification;
      reader.readMessage(value,proto.rct.web.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.MarkAllNotificationsAsReadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.MarkAllNotificationsAsReadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Notification notifications = 1;
 * @return {!Array<!proto.rct.web.Notification>}
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.rct.web.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.Notification, 1));
};


/**
 * @param {!Array<!proto.rct.web.Notification>} value
 * @return {!proto.rct.web.MarkAllNotificationsAsReadResponse} returns this
*/
proto.rct.web.MarkAllNotificationsAsReadResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Notification}
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.MarkAllNotificationsAsReadResponse} returns this
 */
proto.rct.web.MarkAllNotificationsAsReadResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};


goog.object.extend(exports, proto.rct.web);
