import { FunctionComponent } from 'react';
import { RouteChildrenProps } from 'react-router';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import CreateComponent from '../CreateComponent';
import Button from '../../components/Button';
import { Layout } from '../../components/Layout';
import { Icon } from '../../components/Icon';
import DashboardMetaPanel from './DashboardMetaPanel';
import { Appearance, ComponentRefInTheList, TeamMap } from '../../definitions';
import GrouppedComponents from '../../components/GrouppedComponents';

type DashboardProps = {
  onViewComponent: (component: ComponentRefInTheList) => void;
  isFetchingComponents: boolean;
  componentList: ComponentRefInTheList[];
  isCreateDrawerVisible: boolean;
  onCloseCreateComponent: () => void;
  onOpenCreateComponent: () => void;
  numberOfComponents: number;
  teamMap: TeamMap;
} & RouteChildrenProps;

export const DashboardView: FunctionComponent<DashboardProps> = ({
  onViewComponent,
  isFetchingComponents,
  componentList,
  isCreateDrawerVisible,
  onCloseCreateComponent,
  onOpenCreateComponent,
  numberOfComponents,
  teamMap,
  ...rest
}) => {
  const CreateComponentAction: FunctionComponent<{ isCollapsible?: boolean }> = ({
    isCollapsible,
  }) => (
    <Button
      onClick={onOpenCreateComponent}
      appearance={
        isCollapsible ? Appearance.PrimaryWithIconCollapsible : Appearance.PrimaryWithIcon
      }
    >
      <Icon.Plus />
      <span>Create component</span>
    </Button>
  );

  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>My dashboard</Layout.HeaderTitle>
    </Layout.Header>
  );

  return (
    <AuthenticatedLayout header={Header} metaPanel={DashboardMetaPanel}>
      <Layout.Heading
        actions={
          componentList.length
            ? [<CreateComponentAction isCollapsible={true} key="create-component-btn" />]
            : []
        }
        subheading="The inventory of components owned by your teams."
      >
        My components ({numberOfComponents})
      </Layout.Heading>
      <GrouppedComponents
        isLoading={isFetchingComponents}
        components={componentList}
        teamMap={teamMap}
        emptyStateText="You don't have any components yet. Start by creating one"
        emptyActions={[<CreateComponentAction key="create-component" />]}
        onNameClick={onViewComponent}
      />

      <CreateComponent visible={isCreateDrawerVisible} onHide={onCloseCreateComponent} {...rest} />
    </AuthenticatedLayout>
  );
};
