import { FunctionComponent } from 'react';
import { FormSpy } from 'react-final-form';
import { useDebounce } from '../../../hooks';
import { FormStateType } from '../../../definitions';

type FormAutoSaveProps = {
  updateFormState: (formState: FormStateType) => void;
  debounce?: number;
};

export const FormAutoSaveView: FunctionComponent<FormAutoSaveProps> = ({
  updateFormState,
  debounce = 1000,
}) => {
  const handleUpdateFormState = useDebounce(values => updateFormState(values), debounce);

  return (
    <FormSpy
      onChange={({ values }) => {
        if (values) handleUpdateFormState(values);
      }}
    />
  );
};
