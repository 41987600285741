import { ActionType, SequenceType } from '../../definitions';
import { Notification } from '../../grpc/grpcweb/notification_pb';
import actionTypes from '../constants/actionTypes';

export type NotificationsState = Notification.AsObject[];

const initialState: NotificationsState = [];

const notifications = (
  state: NotificationsState = initialState,
  { type, payload, sequence }: ActionType,
): NotificationsState => {
  switch (type) {
    case actionTypes.notifications.fetched: {
      const rawList = payload;
      const originalList = rawList?.map((notification: Notification) => notification.toObject());
      if (sequence === SequenceType.Success) {
        return originalList;
      }

      return state;
    }

    default:
      return state;
  }
};

export default notifications;
