import { GetUserConfigResponse } from '../../grpc/grpcweb/user_pb';
import { SequenceType, ActionType, User, UserObject } from '../../definitions';
import { PageResponse } from '../../grpc/grpccommon/common_pb';
import actionTypes from '../constants/actionTypes';

export type UsersState = {
  list: User[];
  isReady: boolean;
  totalNumber: number;
  prevPageToken?: string;
  nextPageToken?: string;
  currPageToken?: string;
  current: UserObject;
  selectedUserState: Record<string, boolean>;
};

const initialState = {
  list: [],
  totalNumber: 0,
  prevPageToken: undefined,
  nextPageToken: undefined,
  currPageToken: undefined,
  isReady: false,
  current: {
    tokenId: '',
    tenantid: '',
    tenantname: '',
    userid: '',
    username: '',
    emailVerified: false,
    suspended: false,
  },
  selectedUserState: {},
};

const users = (
  state: UsersState = initialState,
  { type, sequence, payload }: ActionType,
): UsersState => {
  switch (type) {
    case actionTypes.auth.checked: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          isReady: payload,
        };
      }

      return state;
    }

    case actionTypes.users.fetched:
    case actionTypes.users.fetchedNextPage:
    case actionTypes.users.fetchedPrevPage: {
      if (sequence === SequenceType.Success) {
        const typedPayload: {
          usersList: User[] | undefined;
          pageresponse: PageResponse.AsObject | undefined;
        } = payload;
        const { usersList, pageresponse } = typedPayload;

        const users: User[] | undefined = usersList || [];
        const totalNumber = pageresponse?.totalcount || 0;
        const prevPageToken = pageresponse?.prevpagetoken;
        const nextPageToken = pageresponse?.nextpagetoken;
        const currPageToken = pageresponse?.curpagetoken;

        return {
          ...state,
          list: users,
          totalNumber,
          prevPageToken,
          nextPageToken,
          currPageToken,
        };
      }

      return state;
    }

    case actionTypes.users.current.fetched: {
      if (sequence === SequenceType.Success) {
        const typedPayload: GetUserConfigResponse = payload;

        return {
          ...state,
          current: {
            ...state.current,
            ...typedPayload,
          },
        };
      }

      return state;
    }

    case actionTypes.auth.complete: {
      // if (sequence === SequenceType.Success) {
      //   const typedPayload: UserCredential = payload;
      //   const { user } = typedPayload;

      //   return {
      //     ...state,
      //     current: {
      //       ...state.current,
      //     },
      //   };
      // }

      return state;
    }

    case actionTypes.teams.current.fetched:
    case actionTypes.teams.current.addedUsers:
    case actionTypes.teams.current.removedUser: {
      return {
        ...state,
        selectedUserState: {},
      };
    }

    case actionTypes.users.selectUserId: {
      const typedPayload: Record<string, boolean> = payload;
      const selectedUserState = state.selectedUserState;
      Object.keys(typedPayload).forEach((userId: string) => {
        selectedUserState[userId] = typedPayload[userId];
      });
      return {
        ...state,
        selectedUserState,
      };
    }

    case actionTypes.users.clearSelectedUsers: {
      return {
        ...state,
        selectedUserState: {},
      };
    }

    // case actionTypes.auth.checked: {
    //   if (sequence === SequenceType.Success) {
    //     return {
    //       ...state,
    //       current: {
    //         ...state.current,
    //         email: payload.email,
    //         emailVerified: payload.emailVerified,
    //       },
    //     };
    //   }

    //   return state;
    // }

    case actionTypes.auth.loggedOut:
      return initialState;

    default:
      return state;
  }
};

export default users;
