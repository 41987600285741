import styled from 'styled-components';
import { Position } from '../../../definitions';
import Button from '../../Button';

type WrapperProps = {
  isModal?: boolean;
};

type OrStatementWrapperProps = {
  position?: Position;
};

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  > * {
    flex-basis: 100%;
  }

  ${props => props.theme.media.medium`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    >* {
      flex-basis: 46%;
    }

    input {
      dipslay: flex;
      align-self: flex-end;
      width: 100%;
    }
  `};
`;

export const OrStatementWrapper = styled.div<OrStatementWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  p {
    margin-bottom: 0.5rem;
  }

  ${props => props.theme.media.medium`
    flex-direction: ${props.position === Position.Column ? 'column' : 'row'};
    p {
      margin-bottom: 0;
    }
  `};
`;

export const Submit = styled(Button)``;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.isModal ? 0 : '1rem 1.5rem')};
  color: ${({ theme }) => theme.palettes.main};
  max-width: calc(${props => props.theme.constants.maxWidth} - 3rem);
  height: ${props => (props.isModal ? '100%' : 'auto')};
  > * {
    margin-bottom: ${props => (props.isModal ? 0 : '1rem')};
  }
`;

export const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
`;
