import {
  ActionType,
  SequenceType,
  FlattenedTeamBatch,
  FlattenedTeam,
  TeamFormData,
  TeamMap,
} from '../../definitions';
import { PageResponse } from '../../grpc/grpccommon/common_pb';
import { TeamBatch, TeamRef, Team } from '../../grpc/grpcweb/team_pb';
import { ComponentBatch, ListMyComponentsResponse } from '../../grpc/grpcweb/component_pb';
import actionTypes from '../constants/actionTypes';

export type TeamsState = {
  list: TeamMap;
  totalNumber: number;
  prevPageToken?: string;
  nextPageToken?: string;
  currPageToken?: string;
  selected: FlattenedTeam;
  new: TeamFormData;
};

const initialState = {
  list: {},
  totalNumber: 0,
  prevPageToken: undefined,
  nextPageToken: undefined,
  currPageToken: undefined,
  selected: {
    id: '',
    shortname: '',
    description: '',
    displayname: '',
    avatarUrl: '',
    createdBy: '',
    users: [],
  },
  new: {
    shortname: '',
    description: '',
    displayname: '',
    avatarUrl: '',
  },
};

const mapTeamRefToState = (team: TeamRef.AsObject) => ({
  avatarUrl: team.meta?.avatar,
  description: team.meta?.description?.value,
  shortname: team.meta?.shortname,
  displayname: team.meta?.displayname,
  id: team.id,
  memberList: team.memberuseridsList,
});

const teams = (
  state: TeamsState = initialState,
  { type, payload, sequence }: ActionType,
): TeamsState => {
  switch (type) {
    case actionTypes.teams.current.select: {
      const typedPayload: FlattenedTeamBatch = payload;
      return {
        ...state,
        selected: {
          ...typedPayload,
          users: [],
        },
      };
    }

    case actionTypes.teams.current.clear: {
      return {
        ...state,
        selected: {
          ...initialState.selected,
        },
      };
    }

    case actionTypes.teams.updateNew: {
      return {
        ...state,
        new: payload,
      };
    }

    case actionTypes.teams.resetNew: {
      return {
        ...state,
        new: initialState.new,
      };
    }

    case actionTypes.teams.current.deleted: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          selected: initialState.selected,
        };
      }

      return state;
    }

    case actionTypes.teams.created: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          new: initialState.new,
        };
      }

      return state;
    }

    case actionTypes.teams.fetchedList:
    case actionTypes.teams.fetchedNextPage:
    case actionTypes.teams.fetchedPrevPage: {
      if (sequence === SequenceType.Success) {
        const typedPayload: { batch: TeamBatch | undefined; page: PageResponse | undefined } =
          payload;
        const { batch, page } = typedPayload;
        const teamList: TeamRef[] | undefined = batch?.getTeamsList() || [];
        const mappedTeams = teamList.map(team => team.toObject());
        const list = mappedTeams.reduce(
          (acc, team) => ({
            ...acc,
            [team.id]: mapTeamRefToState(team),
          }),
          {},
        );

        const totalNumber = page?.getTotalcount() || 0;
        const prevPageToken = page?.getPrevpagetoken();
        const nextPageToken = page?.getNextpagetoken();
        const currPageToken = page?.getCurpagetoken();

        return {
          ...state,
          list,
          totalNumber,
          prevPageToken,
          nextPageToken,
          currPageToken,
        };
      }

      return state;
    }

    case actionTypes.components.current.dependencies.fetched: {
      if (sequence === SequenceType.Success) {
        const {
          dependentBatch,
          dependencyBatch,
        }: { dependentBatch: ComponentBatch; dependencyBatch: ComponentBatch } = payload;

        const dependencyTeams = dependencyBatch
          .getTeamsList()
          .map(team => mapTeamRefToState(team.toObject()));
        const dependentTeams = dependentBatch
          .getTeamsList()
          .map(team => mapTeamRefToState(team.toObject()));
        const teams = [...dependencyTeams, ...dependentTeams];

        const mappedTeams = teams.reduce((accum, curr) => ({ ...accum, [curr.id]: curr }), {});

        return {
          ...state,
          list: mappedTeams,
        };
      }

      return state;
    }

    case actionTypes.components.fetchedMy:
    case actionTypes.components.fetchedList:
    case actionTypes.components.fetchedNextPage:
    case actionTypes.components.fetchedPrevPage: {
      if (sequence === SequenceType.Success) {
        const listResponse: ListMyComponentsResponse.AsObject = payload;
        const { batch } = listResponse;
        const teamsList = batch?.teamsList;

        const teams = teamsList?.map(team => mapTeamRefToState(team));
        const mappedTeams = teams?.reduce((accum, curr) => ({ ...accum, [curr.id]: curr }), {});

        return {
          ...state,
          list: mappedTeams || {},
        };
      }

      return state;
    }

    case actionTypes.teams.current.fetched:
    case actionTypes.teams.current.updated:
    case actionTypes.teams.current.fetchedById:
    case actionTypes.teams.current.addedUsers:
    case actionTypes.teams.current.removedUser: {
      if (sequence === SequenceType.Success) {
        const typedPayload: Team = payload;
        const team = typedPayload.toObject();
        const mappedTeam = {
          avatarUrl: team.meta?.avatar?.uri,
          description: team.meta?.description?.value,
          shortname: team.meta?.shortname,
          displayname: team.meta?.displayname,
          id: team.id,
          createdBy: team.createmeta?.createdby?.id,
          createdAt: team.createmeta?.createdat ? new Date(team.createmeta?.createdat) : undefined,
          users: team.membersList,
        };

        return {
          ...state,
          selected: {
            ...state.selected,
            ...mappedTeam,
          },
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default teams;
