import { FunctionComponent, ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import {
  fetchDetailsForComponentPopup,
  isFetchingComponentPopupDetails,
  getComponentDetailsForPopup,
  getXEventDetailsForPopup,
} from '../../store';
import { paths } from '../../definitions';
import { ComponentWidgetView } from './ComponentWidget.view';

export type ComponentWidgetContainerProps = {
  name: string;
  actions?: ReactElement[];
  type?: string;
  componentId: string;
};

export const ComponentWidgetContainer: FunctionComponent<ComponentWidgetContainerProps> = ({
  componentId,
  ...rest
}) => {
  const dispatch = useDispatch();

  const handleOpenTeamPage = useCallback(
    (teamShortname?: string) => {
      teamShortname && dispatch(push(generatePath(paths.team, { teamShortname })));
    },
    [dispatch],
  );

  const handleViewComponent = useCallback(
    (componentShortname?: string) => {
      componentShortname &&
        dispatch(push(`${generatePath(paths.component, { componentShortname })}#external-events`));
    },
    [dispatch],
  );

  const isFetchingPopupDetails = useSelector(isFetchingComponentPopupDetails);
  const componentDetails = useSelector(getComponentDetailsForPopup);
  const xEvents = useSelector(getXEventDetailsForPopup);

  const handleFetchComponentPopupDetails = useCallback(() => {
    componentId && dispatch(fetchDetailsForComponentPopup(componentId));
  }, [dispatch, componentId]);

  return (
    <ComponentWidgetView
      onFetchDetails={handleFetchComponentPopupDetails}
      componentDetails={componentDetails}
      xEvents={xEvents}
      isLoadingContent={isFetchingPopupDetails}
      onOpenTeamPage={handleOpenTeamPage}
      onViewComponent={handleViewComponent}
      {...rest}
    />
  );
};
