import { useCallback, useMemo } from 'react';
import { ComponentRefInTheList, GrouppedComponents, TeamMap } from '../definitions';

export const useGroupComponentsByTeamName = (
  componentList: ComponentRefInTheList[],
  teamMap: TeamMap,
): GrouppedComponents => {
  const groupComponent = useCallback(
    (components: ComponentRefInTheList[]): GrouppedComponents => {
      return components.reduce(
        (
          groups: Record<string, Array<ComponentRefInTheList>>,
          component: ComponentRefInTheList,
        ) => {
          if (component.ownerTeamId) {
            const teamName = teamMap[component.ownerTeamId]?.shortname;
            const key = teamName ? teamName : component.ownerTeamId;
            const group = groups[key];
            return {
              ...groups,
              [key]: [...(group || []), component],
            };
          } else {
            return groups;
          }
        },
        {},
      );
    },
    [teamMap],
  );

  return useMemo(() => {
    return groupComponent(componentList);
  }, [componentList, groupComponent]);
};
