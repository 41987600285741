import { ElementType } from 'react';
import { Icon } from '../../components/Icon';
import { externalLinks, paths } from '../../definitions';

export type Social = {
  name: string;
  path: string;
  icon: ElementType;
};

export const socials: Array<Social> = [
  {
    name: 'LinkedIn',
    path: externalLinks.linkedIn,
    icon: Icon.Linkedin,
  },
  {
    name: 'Discord',
    path: externalLinks.discord,
    icon: Icon.Discord,
  },
  {
    name: 'Twitter',
    path: paths.social.twitter,
    icon: Icon.Twitter,
  },
  {
    name: 'Facebook',
    path: paths.social.facebook,
    icon: Icon.Facebook,
  },
];
