import { useEffect, useRef, useState, FunctionComponent } from 'react';
import * as Styled from './CopyText.styles';

export type CopyTextProps = {
  text: string;
  title?: string;
  color?: string;
};

export const CopyTextView: FunctionComponent<CopyTextProps> = ({ text, title, color }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [hasCopied, setCopied] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();

  const handleCopyCode = () => {
    if (ref.current && !hasCopied) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      navigator.clipboard.writeText(text);
      setCopied(true);

      timeout.current = global.setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <Styled.Wrapper onClick={handleCopyCode} ref={ref} color={color}>
      {!hasCopied ? (title ? title : text) : 'Copied!'}
    </Styled.Wrapper>
  );
};
