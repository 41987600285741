import { FunctionComponent } from 'react';
import * as Styled from './Empty.styles';

type EmptyProps = {
  text?: string;
};

export const EmptyView: FunctionComponent<EmptyProps> = ({ text, children }) => (
  <Styled.Empty hasChildren={!!children}>
    {text && <p>{text}</p>}
    {children}
  </Styled.Empty>
);
