import { FunctionComponent, ElementType, forwardRef, Ref } from 'react';
import * as Styled from '../Sidebar.styles';

export type SecondaryButtonItemProps = {
  icon?: ElementType;
  path?: string;
  title?: string;
  onItemClick?: () => void;
};

export const SecondaryButtonItem: FunctionComponent<SecondaryButtonItemProps> = forwardRef(
  (
    { icon, path = undefined, title, onItemClick = undefined, children, ...rest },
    ref: Ref<HTMLDivElement>,
  ) => {
    const MenuIcon = icon;

    return (
      <Styled.SecondaryButtonItem onClick={onItemClick} ref={ref} {...rest}>
        {MenuIcon && <MenuIcon />}
        <Styled.Text>{title}</Styled.Text>
        {children}
      </Styled.SecondaryButtonItem>
    );
  },
);
