import { ActionType, SequenceType, Tags, Tag, TagFormData } from '../../definitions';
import {
  Tag as TagView,
  ListTagsResponse,
  GetTagByKeyValueResponse,
} from '../../grpc/grpcweb/tag_pb';

import actionTypes from '../constants/actionTypes';

export type TagState = {
  list: Tags;
  allTagsForSearch: Tags;
  totalNumber: number;
  prevPageToken?: string;
  nextPageToken?: string;
  currPageToken?: string;
  new: TagFormData;
  selected: Tag;
};

const initialState = {
  list: {},
  allTagsForSearch: {},
  totalNumber: 0,
  prevPageToken: '',
  nextPageToken: '',
  currPageToken: '',
  new: {
    tagKey: '',
    values: [],
    description: undefined,
  },
  selected: {
    tagKey: '',
    values: [],
    description: undefined,
    usageInComponents: [],
  },
};

export const mapTagToStateObject = (tag: TagView.AsObject): Tag => {
  return {
    tagKey: tag.tagkey?.key,
    keyId: tag.tagkey?.id,
    values: tag.valuesList,
    description: tag.description?.value,
    updatedAt: tag.updatemeta?.updatedat ? new Date(tag.updatemeta?.updatedat) : undefined,
    updatedBy: tag.updatemeta?.updatedby?.displayname,
    createdAt: tag.createmeta?.createdat ? new Date(tag.createmeta?.createdat) : undefined,
    createdBy: tag.createmeta?.createdby?.displayname,
    usageInComponents: [],
  };
};

const tags = (
  state: TagState = initialState,
  { type, payload, sequence }: ActionType,
): TagState => {
  switch (type) {
    case actionTypes.tags.fetchedList:
    case actionTypes.tags.fetchedNextPage:
    case actionTypes.tags.fetchedPrevPage: {
      if (sequence === SequenceType.Success) {
        const response: ListTagsResponse.AsObject = payload;
        const { pageresponse, tagsList } = response;

        const list = tagsList.reduce((accum, curr) => {
          const tag = mapTagToStateObject(curr);
          return { ...accum, [tag.tagKey || 'undefined']: tag };
        }, {});

        const totalNumber = pageresponse?.totalcount || 0;
        const prevPageToken = pageresponse?.prevpagetoken;
        const nextPageToken = pageresponse?.nextpagetoken;
        const currPageToken = pageresponse?.curpagetoken;

        return {
          ...state,
          list,
          totalNumber,
          prevPageToken,
          nextPageToken,
          currPageToken,
        };
      }

      return state;
    }

    case actionTypes.tags.fetchedAll: {
      if (sequence === SequenceType.Success) {
        const rawList: TagView[] = payload;
        const mappedList: TagView.AsObject[] = rawList?.map((tag: TagView) => tag.toObject());
        const allTagsForSearch = mappedList.reduce((accum, curr) => {
          const tag = mapTagToStateObject(curr);
          return { ...accum, [tag.tagKey || 'undefined']: tag };
        }, {});
        return {
          ...state,
          allTagsForSearch,
        };
      }

      return state;
    }

    case actionTypes.tags.updateNew: {
      return {
        ...state,
        new: payload,
      };
    }

    case actionTypes.tags.updateNewValues: {
      return {
        ...state,
        new: {
          ...state.new,
          values: payload,
        },
      };
    }

    case actionTypes.tags.resetNew: {
      return {
        ...state,
        new: initialState.new,
      };
    }

    case actionTypes.tags.current.updated:
    case actionTypes.tags.current.fetchedByKey:
    case actionTypes.tags.current.fetched:
    case actionTypes.tags.current.values.updated:
    case actionTypes.tags.current.values.deleted:
    case actionTypes.tags.current.values.created: {
      if (sequence === SequenceType.Success) {
        const typedPayload: TagView = payload;
        const mappedTag = mapTagToStateObject(typedPayload.toObject());

        return {
          ...state,
          selected: {
            ...state.selected,
            ...mappedTag,
          },
        };
      }

      return state;
    }

    case actionTypes.tags.current.fetchedByKeyAndValue: {
      if (sequence === SequenceType.Success) {
        const typedPayload: GetTagByKeyValueResponse = payload;
        const tag: TagView.AsObject | undefined = typedPayload.getTag()?.toObject();
        const mappedTag = tag && mapTagToStateObject(tag);

        const usageInComponents =
          typedPayload.getTagusage()?.toObject().usedbycomponentidsList || [];

        return {
          ...state,
          selected: {
            ...state.selected,
            ...mappedTag,
            usageInComponents,
          },
        };
      }

      return state;
    }

    case actionTypes.tags.current.deleted: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          selected: initialState.selected,
        };
      }

      return state;
    }

    case actionTypes.tags.created: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          new: initialState.new,
        };
      }

      return state;
    }

    case actionTypes.tags.current.select: {
      const typedPayload: Tag = payload;
      return {
        ...state,
        selected: {
          ...typedPayload,
        },
      };
    }

    case actionTypes.tags.current.clear: {
      return {
        ...state,
        selected: {
          ...initialState.selected,
        },
      };
    }

    default:
      return state;
  }
};

export default tags;
