import styled from 'styled-components';

export const Container = styled.div`
  line-height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 14rem;

  h3 {
    margin 1rem 0;
  }
`;

export const IconWrapper = styled.div`
  svg {
    height: 4rem;
  }

  img {
    margin: 0 auto;
    height: 4rem;
    width: auto;
    &:hover {
      height: 5rem;
    }
    transition: ${({ theme }) => theme.effects.transition};
  }

  ${props => props.theme.media.medium`
    img {
      height: 8rem;
      &:hover {
        height: 9rem;
      }
    }
  `}
`;

export const Label = styled.div`
  display: none;
`;
