import { FunctionComponent, InputHTMLAttributes, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { InputStyles } from '../../../components/Form/Input';
import NewValue from '../NewValue';
import * as Styled from '../NewValue/NewValue.styles';

type NewValueInputProps = InputHTMLAttributes<HTMLInputElement> &
  FieldRenderProps<string> & {
    remoteErrorMessage?: string;
    showValidation?: boolean;
    description?: string;
    onCreateNewValue: (value: string) => boolean;
    errorMessage?: string;
    maxWidth?: string;
    isLoading: boolean;
  };

export const NewValueInputView: FunctionComponent<NewValueInputProps> = ({
  meta,
  input,
  label,
  remoteErrorMessage,
  showValidation,
  description,
  onCreateNewValue,
  errorMessage,
  children,
  maxWidth,
  isLoading,
}) => {
  const [value, setValue] = useState<string>('');

  const handleCreateValue = (val: string) => {
    const hasError = onCreateNewValue(val);
    if (!hasError) {
      setValue('');
    }
  };

  return (
    <Styled.Wrapper>
      <InputStyles.Label {...input} {...meta}>
        {label}
      </InputStyles.Label>
      {description && <InputStyles.Description>{description}</InputStyles.Description>}
      {children}
      <NewValue
        remoteErrorMessage={remoteErrorMessage}
        input={input}
        meta={meta}
        showValidation={showValidation}
        onSave={handleCreateValue}
        errorMessage={errorMessage}
        onSetValue={(value: string) => setValue(value)}
        maxWidth={maxWidth}
        tagValue={value}
        actionTitle="Add"
        isLoading={isLoading}
      />
    </Styled.Wrapper>
  );
};
