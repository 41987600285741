import { FunctionComponent, useCallback, useEffect, ElementType } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout,
  isFetchingNotifications,
  isMarkingAllNotificationsAsRead,
  getNotifications,
  fetchAllNotifications,
  markAllNotificationsAsRead,
  getTenantName,
} from '../../store';
import { AuthenticatedLayoutView } from './AuthenticatedLayout.view';

type AuthenticatedLayoutContainerProps = {
  header?: ElementType;
  metaPanel?: ElementType;
};

export const AuthenticatedLayoutContainer: FunctionComponent<AuthenticatedLayoutContainerProps> = ({
  metaPanel,
  header,
  ...rest
}) => {
  const notifications = useSelector(getNotifications);
  const isLoadingNotifications = useSelector(isFetchingNotifications);
  const isClearingNotifications = useSelector(isMarkingAllNotificationsAsRead);

  const tenantName = useSelector(getTenantName);

  const dispatch = useDispatch();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const handleMarkNotificationsRead = useCallback(
    (dateStamp: number) => dispatch(markAllNotificationsAsRead(dateStamp)),
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchAllNotifications());
  }, [dispatch]);

  return (
    <AuthenticatedLayoutView
      onLogout={handleLogout}
      notifications={notifications}
      isLoadingNotifications={isLoadingNotifications}
      isClearingNotifications={isClearingNotifications}
      onClearNotifications={handleMarkNotificationsRead}
      metaPanel={metaPanel}
      header={header}
      tenantName={tenantName}
      {...rest}
    />
  );
};
