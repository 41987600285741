import { all, takeEvery, put, AllEffect } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

function* closeModal(action: ActionType) {
  yield put(replace(action.payload.pathname));
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeEvery(actionTypes.modal.close, closeModal)]);
}
