import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSavedNewBot,
  createdBotSuccessfully,
  isCreatingBot,
  createBot,
  resetNewBot,
  updateNewBot,
} from '../../store';
import { FormStateType, BotFormData } from '../../definitions';
import { CreateBotView } from './CreateBot.view';

type CreateBotContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateBotContainer: FunctionComponent<CreateBotContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isCreating = useSelector(isCreatingBot);
  const finishedCreating = useSelector(createdBotSuccessfully);
  const savedFormData = useSelector(getSavedNewBot);

  const handleCreateBot = useCallback(
    (formData: BotFormData) => {
      dispatch(createBot(formData));
    },
    [dispatch],
  );

  const handleUpdateBotState = useCallback(
    (formData: FormStateType) => {
      dispatch(updateNewBot(formData));
    },
    [dispatch],
  );

  const handleResetBotState = useCallback(() => {
    dispatch(resetNewBot());
  }, [dispatch]);

  useEffect(() => {
    if (finishedCreating) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedCreating]);

  return (
    <CreateBotView
      isOpen={isOpen}
      onClose={onClose}
      isCreating={isCreating}
      onCreate={handleCreateBot}
      initialValues={savedFormData}
      onUpdateState={handleUpdateBotState}
      onResetState={handleResetBotState}
    />
  );
};
