import { FunctionComponent } from 'react';
import { Appearance } from '../../definitions';
import Button from '../Button';
import * as Styled from './TableMeta.styles';

export type TableMetaProps = {
  title: string;
  onActionClick: () => void;
  actionTitle?: string;
};

export const TableMetaView: FunctionComponent<TableMetaProps> = ({
  title,
  onActionClick,
  actionTitle = 'Clear selection',
}) => (
  <Styled.Row>
    <h5>{title}</h5>
    <Button onClick={onActionClick} appearance={Appearance.Link}>
      {actionTitle}
    </Button>
  </Styled.Row>
);
