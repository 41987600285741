import {
  ActionType,
  SequenceType,
  CreateSourceFormDataWithAuthType,
  EditSourceFormData,
  EventSourcesInTheList,
  CloudWatchAuthType,
  PrometheusAuthType,
} from '../../definitions';
import { EventSourceView, EventSourceType } from '../../grpc/grpcweb/eventsource_pb';
import actionTypes from '../constants/actionTypes';
import { newEventSource } from '../constants/emptyStates';

export type EventSourcesState = {
  list: EventSourcesInTheList[];
  new: CreateSourceFormDataWithAuthType;
  selected: EditSourceFormData;
};

const initialState = {
  list: [],
  new: newEventSource,
  selected: newEventSource,
};

const eventSources = (
  state: EventSourcesState = initialState,
  { type, payload, sequence }: ActionType,
): EventSourcesState => {
  switch (type) {
    case actionTypes.eventSources.fetchedList: {
      if (sequence === SequenceType.Success) {
        const rawList = payload;
        const originalList = rawList?.map((source: EventSourceView) => source.toObject());
        const list = originalList?.map(
          (source: EventSourceView.AsObject): EventSourcesInTheList => {
            const currentSource: EventSourcesInTheList = {
              displayName: source.metadata?.displayname,
              description: source.metadata?.description?.value,
              id: source.id,
              sourceType:
                source.metadata?.type === 1 || source.metadata?.type === 2
                  ? source.metadata?.type
                  : 1,
            };
            if (currentSource.sourceType === EventSourceType.EVENT_SOURCE_CLOUDWATCH) {
              currentSource.accessKey =
                source.metadata?.cloudwatchmetadata?.accesskeyauthmetadata?.accesskey;
              currentSource.region =
                source.metadata?.cloudwatchmetadata?.accesskeyauthmetadata?.region;
              currentSource.secretAccessKey =
                source.metadata?.cloudwatchmetadata?.accesskeyauthmetadata?.region;
              currentSource.authenticationType = CloudWatchAuthType.AccessKey;
            }
            if (currentSource.sourceType === EventSourceType.EVENT_SOURCE_PROMETHEUS) {
              currentSource.url = source.metadata?.prometheusmetadata?.basicauthmetadata?.url;
              currentSource.username =
                source.metadata?.prometheusmetadata?.basicauthmetadata?.username;
              currentSource.password = source.metadata?.prometheusmetadata?.basicauthmetadata?.pwd;
              currentSource.authenticationType = PrometheusAuthType.Basic;
            }
            return currentSource;
          },
        );
        return {
          ...state,
          list,
        };
      }

      return state;
    }

    case actionTypes.eventSources.fetchedOne: {
      if (sequence === SequenceType.Start) {
        return {
          ...state,
          selected: newEventSource,
        };
      }

      if (sequence === SequenceType.Success) {
        const payloadWithType: EventSourceView = payload;
        const source = payloadWithType.toObject();
        const selectedMappedToState: EventSourcesInTheList = {
          displayName: source.metadata?.displayname,
          description: source.metadata?.description?.value,
          id: source.id,
          sourceType:
            source.metadata?.type === 1 || source.metadata?.type === 2 ? source.metadata?.type : 1,
        };
        if (selectedMappedToState.sourceType === EventSourceType.EVENT_SOURCE_CLOUDWATCH) {
          selectedMappedToState.accessKey =
            source.metadata?.cloudwatchmetadata?.accesskeyauthmetadata?.accesskey;
          selectedMappedToState.region =
            source.metadata?.cloudwatchmetadata?.accesskeyauthmetadata?.region;
          selectedMappedToState.secretAccessKey =
            source.metadata?.cloudwatchmetadata?.accesskeyauthmetadata?.region;
          selectedMappedToState.authenticationType = CloudWatchAuthType.AccessKey;
        }
        if (selectedMappedToState.sourceType === EventSourceType.EVENT_SOURCE_PROMETHEUS) {
          selectedMappedToState.url = source.metadata?.prometheusmetadata?.basicauthmetadata?.url;
          selectedMappedToState.username =
            source.metadata?.prometheusmetadata?.basicauthmetadata?.username;
          selectedMappedToState.password =
            source.metadata?.prometheusmetadata?.basicauthmetadata?.pwd;
          selectedMappedToState.authenticationType = PrometheusAuthType.Basic;
        }

        return {
          ...state,
          selected: selectedMappedToState,
        };
      }
      return state;
    }

    case actionTypes.eventSources.updateNew: {
      return {
        ...state,
        new: payload,
      };
    }

    case actionTypes.eventSources.resetNew: {
      return {
        ...state,
        new: initialState.new,
      };
    }

    case actionTypes.eventSources.created: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          new: initialState.new,
        };
      }

      return state;
    }

    case actionTypes.eventSources.updated: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          selected: initialState.selected,
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default eventSources;
