import React, { Children, cloneElement, FunctionComponent, ReactNode } from 'react';
import { Item } from '../Sidebar/Items/Item.view';
import { SecondaryItem } from '../Sidebar/Items/SecondaryItem.view';
import { Icon } from '../../components/Icon';
import { Appearance } from '../../definitions';
import { DrawerPosition, ColorTheme } from '../../definitions';
import * as Styled from './Drawer.styles';

export type DrawerProps = {
  visible?: boolean;
  onHideMenu?: () => void;
  children: ReactNode;
  position?: DrawerPosition;
  width?: string;
  fullScreenOnSmall?: boolean;
  colorTheme?: ColorTheme;
  title?: string;
  zIndex?: number;
};

export const DrawerView: FunctionComponent<DrawerProps> = ({
  visible = false,
  onHideMenu = () => {},
  children,
  position = DrawerPosition.Left,
  width,
  fullScreenOnSmall = false,
  colorTheme = ColorTheme.Dark,
  title,
  ...rest
}) => {
  const getHeader = () => {
    if (title) {
      return (
        <Styled.Header colorTheme={colorTheme}>
          <Styled.CloseButton appearance={Appearance.Icon} onClick={onHideMenu}>
            <Icon.Collapse />
          </Styled.CloseButton>
          <h2>{title}</h2>
        </Styled.Header>
      );
    }
  };
  return (
    <Styled.Container
      visible={visible}
      position={position}
      width={width}
      fullScreenOnSmall={fullScreenOnSmall}
      colorTheme={colorTheme}
      {...rest}
    >
      {getHeader()}
      <Styled.Wrapper>
        {Children.map(children, child => {
          if (!React.isValidElement(child)) {
            return child;
          }

          if (child && (child.type === Item || child.type === SecondaryItem)) {
            return cloneElement(child, {
              onItemClick: onHideMenu,
            });
          }

          return child;
        })}
      </Styled.Wrapper>
    </Styled.Container>
  );
};
