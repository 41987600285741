import { FunctionComponent, ElementType } from 'react';
import * as Styled from './FactBox.styles';

export type FactBoxProps = {
  label: string;
  icon: ElementType;
  text: string;
};

export const FactBoxView: FunctionComponent<FactBoxProps> = ({ label, icon, text }) => {
  const Icon = icon;
  return (
    <Styled.Container>
      <Styled.IconWrapper>
        <Icon />
      </Styled.IconWrapper>
      <h3>{label}</h3>
      <p>{text}</p>
    </Styled.Container>
  );
};
