import { FunctionComponent, useState, useEffect, ReactElement } from 'react';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import Avatar from '../Avatar';
import { AvatarAppearance, User, AvatarType, FlattenedTeam } from '../../definitions';
import * as Styled from './UserProfilePopup.styles';

export type UserProfilePopupProps = {
  user: User | FlattenedTeam;
  actions?: ReactElement[];
  avatarType?: AvatarType;
};

export const UserProfilePopupView: FunctionComponent<UserProfilePopupProps> = ({
  children,
  actions,
  user,
  avatarType = AvatarType.User,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [setToClose, setSetToClose] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSetToClose(false);
  };

  useEffect(() => {
    if (setToClose) {
      handleClose();
    }
  }, [setToClose]);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  const getUserName = (user: User | FlattenedTeam) => {
    if ('username' in user) {
      return user.fullname;
    }
    return user.displayname || user.shortname || 'Team';
  };

  return (
    <>
      <Styled.TriggerWrapper {...triggerProps} onClick={() => setOpen(!isOpen)}>
        {children}
      </Styled.TriggerWrapper>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <Styled.PopupContent>
                <Avatar
                  name={getUserName(user)}
                  appearance={AvatarAppearance.Large}
                  avatarType={avatarType}
                />
                {'username' in user ? (
                  <Styled.Meta>
                    <h6>{user.fullname}</h6>
                    <p>{user.email}</p>
                    {actions && <Styled.ActionWrapper>{actions}</Styled.ActionWrapper>}
                  </Styled.Meta>
                ) : (
                  <Styled.Meta>
                    <h6>{user.displayname || user.shortname}</h6>
                    <p>Number of members: {user.users.length}</p>
                    {actions && <Styled.ActionWrapper>{actions}</Styled.ActionWrapper>}
                  </Styled.Meta>
                )}
              </Styled.PopupContent>
              <Arrow {...arrowProps} />
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
