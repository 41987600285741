import styled from 'styled-components';

type WrapperProps = {
  color?: string;
};

export const Wrapper = styled.span<WrapperProps>`
  color: ${props => props.color || props.theme.palettes.highlight};
  cursor: pointer;
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }
`;
