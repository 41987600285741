import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLinkingTagToComponent,
  linkTagToComponent,
  fetchAllTagsForSearch,
  getAllTagForSearch,
  isFetchingAllTagsForSearch,
  linkedTagToComponentSuccesfully,
} from '../../store';
import { AddTagFormData } from '../../definitions';
import { AddTagView } from './AddTag.view';

type AddTagContainerProps = {
  onClose: () => void;
  isOpen: boolean;
  componentId: string;
};

export const AddTagContainer: FunctionComponent<AddTagContainerProps> = ({
  isOpen,
  onClose,
  componentId,
}) => {
  const dispatch = useDispatch();
  const isAdding = useSelector(isLinkingTagToComponent);
  const finishedAdding = useSelector(linkedTagToComponentSuccesfully);
  const allTags = useSelector(getAllTagForSearch);
  const isLoadingAllTags = useSelector(isFetchingAllTagsForSearch);

  const handleAddTag = useCallback(
    (formData: AddTagFormData) => {
      dispatch(linkTagToComponent({ componentId, ...formData }));
    },
    [dispatch, componentId],
  );

  useEffect(() => {
    if (finishedAdding) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedAdding]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAllTagsForSearch());
    }
  }, [dispatch, isOpen]);

  return (
    <AddTagView
      isOpen={isOpen}
      onClose={onClose}
      isAdding={isAdding}
      onAddTag={handleAddTag}
      tags={allTags}
      isLoadingTags={isLoadingAllTags}
    />
  );
};
