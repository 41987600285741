import { ActionType, AlertObject } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export type AlertsState = AlertObject[];

const initialState: AlertsState = [];

const alerts = (state: AlertsState = initialState, { type, payload }: ActionType): AlertsState => {
  switch (type) {
    case actionTypes.alerts.show:
      return [
        ...state,
        {
          key: Math.random().toString(16).slice(2),
          timeout: 3000,
          ...payload,
        },
      ];

    case actionTypes.alerts.hide:
      return state.filter(i => i.key !== payload.key);

    default:
      return state;
  }
};

export default alerts;
