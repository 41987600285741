import { useState, useRef, FunctionComponent, MouseEvent, PropsWithoutRef } from 'react';
import * as Styled from './RippleEffect.styles';

export type RippleEffectProps = PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  color?: string;
};

type Ripple = {
  key: number;
  position: { x: number; y: number };
  size: number;
  onAnimationEnd: () => void;
};

/**
 * Creates a "ripple" effect whenever clicked or tapped.
 */
export const RippleEffectView: FunctionComponent<RippleEffectProps> = ({
  children,
  color = 'currentColor',
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const id = useRef(0);
  const [ripples, set] = useState<Ripple[]>([]);

  const removeRipple = (key: number) =>
    set(prev => prev.filter((ripple: Ripple) => ripple.key !== key));

  const createRipple = (e: MouseEvent) => {
    if (!ref.current) {
      return;
    }

    const { width, height, left, top } = ref.current.getBoundingClientRect();
    const size = Math.max(width, height);
    const center = size / 2;
    let position = {
      x: width / 2 - center,
      y: height / 2 - center,
    };

    if (e.clientX && e.clientY) {
      position = {
        x: e.clientX - left - center,
        y: e.clientY - top - center,
      };
    }

    set((prev: Ripple[]) => {
      const key = id.current;

      id.current += 1;

      return [
        ...prev,
        {
          key,
          position,
          size,
          onAnimationEnd: () => {
            removeRipple(key);
          },
        },
      ];
    });
  };

  return (
    <Styled.Wrapper ref={ref} onMouseDown={createRipple} {...rest}>
      {children}
      {ripples.map(ripple => (
        <Styled.Ripple {...ripple} color={color} />
      ))}
    </Styled.Wrapper>
  );
};
