import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEventSourceList,
  getEventSourceList,
  isFetchingEventSourceList,
  fetchEventSource,
} from '../../store';
import { usePageTitle } from '../../hooks';
import { EventSourcesView } from './EventSources.view';

export const EventSourcesContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  usePageTitle('Event sources');

  const isFetchingSources = useSelector(isFetchingEventSourceList);
  const sourceList = useSelector(getEventSourceList);

  useEffect(() => {
    dispatch(fetchEventSourceList());
  }, [dispatch]);

  const handleFetchEventSource = useCallback(
    (sourceId: string) => {
      dispatch(fetchEventSource(sourceId));
    },
    [dispatch],
  );

  return (
    <EventSourcesView
      isFetchingSources={isFetchingSources}
      sourceList={sourceList}
      onFetchEventSource={handleFetchEventSource}
    />
  );
};
