import { FunctionComponent } from 'react';
import Button from '../../Button';
import { Appearance } from '../../../definitions';
import { Icon } from '../../Icon';
import * as Styled from './NextPrev.styles';

export type NextPrevProps = {
  onNextClick: () => void;
  onPrevClick: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  prevText?: string;
  nextText?: string;
};

export const NextPrevView: FunctionComponent<NextPrevProps> = ({
  onNextClick,
  onPrevClick,
  isPrevDisabled,
  isNextDisabled,
  prevText = 'Previous',
  nextText = 'Next',
}) => {
  return (
    <Styled.Container>
      <Button
        appearance={Appearance.LinkWithIcon}
        isDisabled={isPrevDisabled}
        onClick={onPrevClick}
      >
        <Icon.ArrowLeft />
        <span>{prevText}</span>
      </Button>
      <Button
        appearance={Appearance.LinkWithIcon}
        isDisabled={isNextDisabled}
        onClick={onNextClick}
      >
        <span>{nextText}</span>
        <Icon.ArrowRight />
      </Button>
    </Styled.Container>
  );
};
