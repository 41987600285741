import { all, takeLatest, select, put, AllEffect } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import {
  CreateSloRequest,
  DeleteSloRequest,
  ErrorBudgetMethod,
  EventQuery,
  EventQueryType,
  GetSloRequest,
  ListSloRequest,
  Objective,
  SloMetadata,
  TimeWindow,
  TimeWindowType,
  UpdateSloRequest,
} from '../../grpc/grpcweb/slo_pb';
import {
  AddCommentRequest,
  CommentTarget,
  ListCommentsRequest,
} from '../../grpc/grpcweb/comment_pb';
import { TextItem, TextType } from '../../grpc/grpccommon/common_pb';
import {
  getAccessToken,
  getCreateSLOError,
  getListSLOError,
  getFetchSLOTypesError,
  getFetchSLOError,
  getUpdateSLOError,
  getLaunchSLOError,
  getDeleteSLOError,
  getSelectedSLOId,
  getFetchSLOHistoryError,
  getFetchSLOActivityError,
  getFetchSLOActivityCommentsError,
  getFetchSLOCommentsError,
  getAddSLOCommentError,
  getFetchSLOListforComponentError,
  getSelectedSLOComponentId,
} from '../selectors';
import { closeModal } from './../actions/modal';
import {
  SequenceType,
  request,
  ActionType,
  AlertType,
  SloFormData,
  numberFromDate,
  paths,
  SloComponent,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';
import services from '../../services';
import {
  showAlert,
  fetchSLOList,
  fetchSLO,
  fetchSLOHistory,
  fetchSLOActivity,
  fetchSLOComments,
  fetchSLOListForComponent,
  fetchComponentById,
} from '../actions';

function* fetchList(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO list.',
      }),
    );
  }

  const listSLOsByTragetRequest = new ListSloRequest();

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.fetchedList },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .fetchList(token, listSLOsByTragetRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getListSLOError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchTypes(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO types.',
      }),
    );
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.fetchedTypes },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .fetchSLOTypes(token)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchSLOTypesError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchOneByShortName(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO.',
      }),
    );
  }

  const shortName: string = action.payload;

  if (!shortName) {
    return;
  }

  const getSloRequest = new GetSloRequest();
  getSloRequest.setShortname(shortName);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.fetchedOne },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .fetchOneSLO(token, getSloRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    const sloId = yield select(getSelectedSLOId);
    const componentId = yield select(getSelectedSLOComponentId);
    if (componentId) {
      yield put(fetchComponentById(componentId));
    }
    yield put(fetchSLOHistory(sloId));
    yield put(fetchSLOComments(sloId));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchSLOError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchSLOsForComponent(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO list.',
      }),
    );
  }

  const componentId: SloComponent['id'] = action.payload;

  if (!componentId) {
    return;
  }

  const listSLOsByTragetRequest = new ListSloRequest();
  listSLOsByTragetRequest.setComponentid(componentId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.fetchedSLOs },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .fetchList(token, listSLOsByTragetRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchSLOListforComponentError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchHistory(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO history.',
      }),
    );
  }

  const sloId: string = action.payload;

  if (!sloId) {
    return;
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.fetchedHistory },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .getSloHistoricValues(token, sloId)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchSLOActivity(sloId));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchSLOHistoryError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchActivity(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO activity.',
      }),
    );
  }

  const sloId: string = action.payload;

  if (!sloId) {
    return;
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.activity.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .getSloActivity(token, sloId)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchSLOActivityError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* addSLOComment(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to publish SLO comment.',
      }),
    );
  }

  const { sloId, comment }: { sloId: string; comment: string } = action.payload;

  if (!sloId) {
    return;
  }

  const addCommentRequest = new AddCommentRequest();
  addCommentRequest.setTargetid(sloId);
  addCommentRequest.setTarget(CommentTarget.TARGET_SLO);

  if (comment) {
    const commentItem = new TextItem();
    commentItem.setValue(comment);
    commentItem.setType(TextType.TEXT_TYPE_PLAIN);
    addCommentRequest.setValue(commentItem);
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.comments.added },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ commentService }) =>
          commentService
            .addSLOComment(token, addCommentRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchSLOComments(sloId));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `The comment was published succesfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getAddSLOCommentError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchCommentsBySLO(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO comments.',
      }),
    );
  }

  const sloId: string = action.payload;

  if (!sloId) {
    return;
  }

  const listCommentsIdRequest = new ListCommentsRequest();
  listCommentsIdRequest.setTargetid(sloId);
  listCommentsIdRequest.setTarget(CommentTarget.TARGET_SLO);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.comments.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ commentService }) =>
          commentService
            .getSLOCommentsList(token, listCommentsIdRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchSLOCommentsError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchActivityComments(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch activity comments.',
      }),
    );
  }

  const activityId: string = action.payload;

  if (!activityId) {
    return;
  }

  const listCommentsByActivityIdRequest = new ListCommentsRequest();
  listCommentsByActivityIdRequest.setTargetid(activityId);
  listCommentsByActivityIdRequest.setTarget(CommentTarget.TARGET_ACTIVITY);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.activity.fetchedComments },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ commentService }) =>
          commentService
            .getSLOCommentsList(token, listCommentsByActivityIdRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchSLOActivityCommentsError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* createNewSlo(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to create new SLO.',
      }),
    );
  }

  const { formData, componentId } = action.payload;
  const mappedTimeWindowType = Object.values(TimeWindowType).find(
    value => Number(formData.timeWindowType) === value,
  );
  const timeWindow = new TimeWindow();
  formData.timeCount && timeWindow.setCount(formData.timeCount);
  timeWindow.setStarttime(numberFromDate(formData.startTime));
  timeWindow.setType(mappedTimeWindowType);
  timeWindow.setUnit(formData.timeUnit);

  const description = new TextItem();
  description.setValue(formData.description);
  description.setType(TextType.TEXT_TYPE_PLAIN);

  const objective = new Objective();
  objective.setDisplayname(formData.objectiveDisplayName);
  objective.setValue(formData.objectiveValue);

  const badEventQuery = new EventQuery();
  formData.badQuery.forEach((query: string) => {
    badEventQuery.addQueries(query);
  });
  badEventQuery.setEventsourceid(formData.badQueryEventSource);
  badEventQuery.setQuerytype(EventQueryType.QUERY_TYPE_PLAIN_TEXT);
  objective.setBadeventquery(badEventQuery);

  const totalEvenQuery = new EventQuery();
  formData.totalQuery.forEach((query: string) => {
    totalEvenQuery.addQueries(query);
  });
  totalEvenQuery.setEventsourceid(formData.totalQueryEventSource);
  totalEvenQuery.setQuerytype(EventQueryType.QUERY_TYPE_PLAIN_TEXT);
  objective.setTotaleventquery(totalEvenQuery);

  const sloMetadata = new SloMetadata();
  sloMetadata.setDescription(description);
  sloMetadata.setDisplayname(formData.displayName);
  sloMetadata.setErrorbudgetmethod(ErrorBudgetMethod.ERROR_BUDGET_METHOD_OCCURRENCES);
  sloMetadata.setShortname(formData.shortName);
  sloMetadata.setComponentid(formData.sloComponentId);
  sloMetadata.setTimewindow(timeWindow);
  sloMetadata.setObjective(objective);
  sloMetadata.setType(formData.type);

  const createSloRequest = new CreateSloRequest();
  createSloRequest.setSlo(sloMetadata);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.created },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .createNewSlo(token, createSloRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    if (componentId) {
      yield put(fetchSLOListForComponent(componentId));
    }
    yield put(fetchSLOList());
    yield put(closeModal());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.shortName} SLO successfully created.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getCreateSLOError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* updateSelectedSlo(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update SLO.',
      }),
    );
  }

  const { formData, sloId }: { formData: SloFormData; sloId: string } = action.payload;
  const mappedTimeWindowType = Object.values(TimeWindowType).find(
    value => Number(formData.timeWindowType) === value,
  );

  const timeWindow = new TimeWindow();
  formData.timeCount && timeWindow.setCount(formData.timeCount);
  formData.startTime && timeWindow.setStarttime(numberFromDate(formData.startTime));
  mappedTimeWindowType && timeWindow.setType(mappedTimeWindowType);
  formData.timeUnit && timeWindow.setUnit(formData.timeUnit);

  const description = new TextItem();
  formData.description && description.setValue(formData.description);
  description.setType(TextType.TEXT_TYPE_PLAIN);

  const objective = new Objective();
  formData.objectiveDisplayName && objective.setDisplayname(formData.objectiveDisplayName);
  formData.objectiveValue && objective.setValue(formData.objectiveValue);

  const badEventQuery = new EventQuery();
  formData.badQuery.forEach((query: string) => {
    badEventQuery.addQueries(query);
  });
  formData.badQueryEventSource && badEventQuery.setEventsourceid(formData.badQueryEventSource);
  EventQueryType.QUERY_TYPE_PLAIN_TEXT &&
    badEventQuery.setQuerytype(EventQueryType.QUERY_TYPE_PLAIN_TEXT);
  badEventQuery && objective.setBadeventquery(badEventQuery);

  const totalEvenQuery = new EventQuery();
  formData.totalQuery.forEach((query: string) => {
    totalEvenQuery.addQueries(query);
  });
  formData.totalQueryEventSource && totalEvenQuery.setEventsourceid(formData.totalQueryEventSource);
  EventQueryType.QUERY_TYPE_PLAIN_TEXT &&
    totalEvenQuery.setQuerytype(EventQueryType.QUERY_TYPE_PLAIN_TEXT);
  totalEvenQuery && objective.setTotaleventquery(totalEvenQuery);

  const sloMetadata = new SloMetadata();
  description && sloMetadata.setDescription(description);
  formData.displayName && sloMetadata.setDisplayname(formData.displayName);
  sloMetadata.setErrorbudgetmethod(ErrorBudgetMethod.ERROR_BUDGET_METHOD_OCCURRENCES);
  formData.shortName && sloMetadata.setShortname(formData.shortName);
  formData.sloComponentId && sloMetadata.setComponentid(formData.sloComponentId);
  timeWindow && sloMetadata.setTimewindow(timeWindow);
  objective && sloMetadata.setObjective(objective);
  formData.type && sloMetadata.setType(formData.type);

  const updateSloRequest = new UpdateSloRequest();
  updateSloRequest.setSlo(sloMetadata);
  updateSloRequest.setSloid(sloId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.updated },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .updateSlo(token, updateSloRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchSLOList());
    yield put(push(generatePath(paths.slo, { sloShortName: formData.shortName })));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.displayName || formData.shortName} SLO successfully updated.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateSLOError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* launchSelectedSlo(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to launch SLO.',
      }),
    );
  }

  const sloId = action.payload;

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.launched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .launchSLO(token, sloId)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchSLO(sloId));
    yield put(push(generatePath(paths.slo, { sloId })));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `SLO successfully launched.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getLaunchSLOError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteSlo(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete SLO.',
      }),
    );
  }

  const { sloId, name }: { sloId: string; name: string } = action.payload;

  const deleteSloRequest = new DeleteSloRequest();
  deleteSloRequest.setId(sloId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.slos.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ sloService }) =>
          sloService
            .deleteSlo(token, deleteSloRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(push(generatePath(paths.components)));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${name} SLO successfully deleted.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteSLOError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.slos.fetchList, fetchList)]);
  yield all([takeLatest(actionTypes.slos.fetchTypes, fetchTypes)]);
  yield all([takeLatest(actionTypes.slos.fetchOne, fetchOneByShortName)]);
  yield all([takeLatest(actionTypes.components.current.fetchSLOs, fetchSLOsForComponent)]);
  yield all([takeLatest(actionTypes.slos.fetchHistory, fetchHistory)]);
  yield all([takeLatest(actionTypes.slos.activity.fetch, fetchActivity)]);
  yield all([takeLatest(actionTypes.slos.comments.fetch, fetchCommentsBySLO)]);
  yield all([takeLatest(actionTypes.slos.comments.add, addSLOComment)]);
  yield all([takeLatest(actionTypes.slos.activity.fetchComments, fetchActivityComments)]);
  yield all([takeLatest(actionTypes.slos.create, createNewSlo)]);
  yield all([takeLatest(actionTypes.slos.update, updateSelectedSlo)]);
  yield all([takeLatest(actionTypes.slos.launch, launchSelectedSlo)]);
  yield all([takeLatest(actionTypes.slos.delete, deleteSlo)]);
}
