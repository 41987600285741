import styled from 'styled-components';
import { colors, theme } from '../../../definitions';
export * from '../Input/Input.styles';

type ReserveSpaceProps = {
  reservedSpace: string;
};

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: colors.space,
    background: state.isSelected ? theme.palettes.mute : theme.palettes.inverse,
    padding: '0.5rem 0.75rem',
    fontSize: '0.875rem',
    '&:hover': {
      background: theme.palettes.mute,
    },
    '&:active': {
      background: theme.palettes.mute,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '0.875rem',
    color: theme.palettes.placeholder,
  }),
  container: (provided: any) => ({
    ...provided,
    display: 'flex',
    width: '100%',
    minHeight: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: theme.zIndexes.select,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0.5rem 0.75rem',
    fontSize: '0.875rem',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: `1px solid ${
      state.isInvalid
        ? theme.palettes.error
        : state.isFocused
        ? theme.palettes.secondaryHighlight
        : state.hasValue
        ? theme.palettes.mutedMain
        : theme.palettes.border
    }`,
    boxShadow: 'none',
    borderRadius: '0.5rem',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${
        state.isInvalid
          ? theme.palettes.error
          : state.isFocused
          ? theme.palettes.secondaryHighlight
          : state.hasValue
          ? theme.palettes.mutedMain
          : theme.palettes.border
      }`,
    },
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const ReserveSpace = styled.div<ReserveSpaceProps>`
  min-height: ${props => props.reservedSpace};
`;
