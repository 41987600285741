import { forwardRef } from 'react';
import * as Styled from './Header.styles';

export const HeaderView = forwardRef<HTMLDivElement>((props, ref) => (
  <Styled.Container ref={ref} {...props}>
    <Styled.StarrySkyWrapper>
      <Styled.MotoContainer>
        <Styled.Moto>Quality management solution</Styled.Moto>
        <Styled.Moto>for distributed software architectures</Styled.Moto>
        <Styled.Submoto>Ensure a consistently high software quality with RocketSLO's platform, leveraging SLO-based controls for precise oversight of distributed system components</Styled.Submoto>
      </Styled.MotoContainer>
      <Styled.AstronautImage />
    </Styled.StarrySkyWrapper>
  </Styled.Container>
));
