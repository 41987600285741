import React, { FunctionComponent } from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { colors, PositionX, PositionY } from '../../definitions';
import * as Styled from './Tooltip.styles';

export type TooltipProps = {
  content?: any;
  positionY?: PositionY;
  positionX?: PositionX;
  color?: string;
  triggerOffset?: number;
  hasArrow?: boolean;
};

export const TooltipView: FunctionComponent<TooltipProps> = ({
  children,
  content,
  positionY = PositionY.Top,
  positionX,
  color = colors.space,
  hasArrow = true,
  triggerOffset = 10,
}) => {
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 150 });
  const getPlacement = () => {
    if (positionX) {
      return positionX === PositionX.Left ? `left-center` : `right-center`;
    }
    return positionY === PositionY.Top ? `top-center` : `bottom-center`;
  };

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    triggerOffset,
    preferY: positionY,
    preferX: positionX,
    placement: getPlacement(),
  });

  let trigger;
  if (React.isValidElement(children)) {
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    });
  } else {
    // In case of an react-element, we need to clone it in order to attach our own props
    trigger = (
      <span className="tooltip-text-wrapper" {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  }

  return (
    <>
      <Styled.Trigger>{trigger}</Styled.Trigger>
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <Styled.Wrapper
              className="tooltip-box"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 0.8, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              color={color}
              {...layerProps}
            >
              {content}
              {hasArrow && (
                <Arrow
                  {...arrowProps}
                  borderColor={color}
                  backgroundColor={color}
                  borderWidth={1}
                  size={6}
                />
              )}
            </Styled.Wrapper>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
