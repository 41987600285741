import { FunctionComponent, useState } from 'react';
import { Tag } from '../../../components/Tag';
import { Link } from '../../../components/Link';
import { MetaPanel } from '../../../components/MetaPanel';
import { AvatarType, FlattenedTeam, Link as LinkType, TagAppearance } from '../../../definitions';
import AvatarWithTitle from '../../../components/AvatarWithTitle';
import { Icon } from '../../../components/Icon';
import AddLinkModal from '../../../modals/AddLink';
import EditLinkModal from '../../../modals/EditLink';
import Url from '../../../components/Url';
import * as Styled from './ComponentMetaPanel.styles';

type ContentProps = {
  description?: string;
  componentId: string;
  openEditComponentModal: () => void;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  ownerTeam?: FlattenedTeam;
  links: LinkType[];
  onSetIsAddLinkModalOpen: (isOpen: boolean) => void;
  onEditLinkClick: (linkId: string) => void;
};

type ComponentMetaPanelProps = {
  name: string;
  avatarSrc?: string;
  type: string;
  description?: string;
  componentId: string;
  links: LinkType[];
  openEditComponentModal: () => void;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  ownerTeam?: FlattenedTeam;
  isCollapsed: boolean;
  onSetIsCollapsed: (newValue: boolean) => void;
};

const getTimeStringInfoFromDate = (date: Date) => {
  return `${date.toLocaleDateString()} at ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

const Content: FunctionComponent<ContentProps> = ({
  onEditLinkClick,
  updatedAt,
  updatedBy,
  createdAt,
  createdBy,
  ownerTeam,
  description,
  openEditComponentModal,
  onSetIsAddLinkModalOpen,
  links,
}) => {
  return (
    <>
      <Styled.Section>
        <Styled.SectionHeader>Owner team</Styled.SectionHeader>
        {ownerTeam && (
          <AvatarWithTitle
            name={ownerTeam.displayname || ownerTeam.shortname || ''}
            avatarSrc={ownerTeam.avatarUrl}
          />
        )}
      </Styled.Section>
      <Styled.Section>
        <Styled.SectionHeader>Description</Styled.SectionHeader>
        {description ? (
          <Styled.SectionText>{description}</Styled.SectionText>
        ) : (
          <Styled.MutedText>
            <Link onClick={openEditComponentModal}>Update component</Link> to add a description.
          </Styled.MutedText>
        )}
      </Styled.Section>
      <Styled.Section>
        <Styled.SectionHeader>
          Links
          <Link onClick={() => onSetIsAddLinkModalOpen(true)}>
            <Icon.Plus />
            Add link
          </Link>
        </Styled.SectionHeader>
        {links &&
          links.map(link => (
            <Url
              key={link.id}
              displayName={link.displayname || link.url || ''}
              url={link.url}
              avatar={link.avatarUrl}
              onEditClick={() => onEditLinkClick(link.id)}
            ></Url>
          ))}
      </Styled.Section>
      <Styled.Section>
        <Styled.SectionHeader>Meta information</Styled.SectionHeader>
        {createdAt && (
          <Styled.SectionText>
            Created by {createdBy} on {getTimeStringInfoFromDate(createdAt)}.
          </Styled.SectionText>
        )}
        {updatedAt && (
          <Styled.SectionText>
            Updated by {updatedBy} on {getTimeStringInfoFromDate(updatedAt)}.
          </Styled.SectionText>
        )}
      </Styled.Section>
    </>
  );
};

export const ComponentMetaPanelView: FunctionComponent<ComponentMetaPanelProps> = ({
  name,
  avatarSrc,
  type,
  componentId,
  links,
  ...rest
}) => {
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);
  const [isEditLinkModalOpen, setIsEditLinkModalOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState<LinkType | undefined>(undefined);

  const onEditLinkClick = (linkId: string) => {
    const link = links.find(link => link.id === linkId);
    setIsEditLinkModalOpen(true);
    if (link) {
      setSelectedLink(link);
    }
  };

  return (
    <MetaPanel {...rest} panelTriggerTooltip="Component details">
      <MetaPanel.Header>
        <Styled.TagWrapper>
          <Tag value={type} appearance={TagAppearance.Badge} />
        </Styled.TagWrapper>
        <Styled.AvatarWrapper>
          <AvatarWithTitle name={name} avatarType={AvatarType.Component} avatarSrc={avatarSrc} />
        </Styled.AvatarWrapper>
      </MetaPanel.Header>
      <MetaPanel.Content>
        <Content
          {...rest}
          onEditLinkClick={onEditLinkClick}
          componentId={componentId}
          links={links}
          onSetIsAddLinkModalOpen={setIsAddLinkModalOpen}
        />
      </MetaPanel.Content>

      <AddLinkModal
        isOpen={isAddLinkModalOpen}
        onClose={() => setIsAddLinkModalOpen(false)}
        componentId={componentId}
      />
      <EditLinkModal
        isOpen={isEditLinkModalOpen}
        onClose={() => setIsEditLinkModalOpen(false)}
        link={selectedLink}
        componentId={componentId}
      />
    </MetaPanel>
  );
};
