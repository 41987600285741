import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import {
  isUpdatingSLO,
  updateExistingSlo,
  getComponentList,
  isFetchingComponentList,
  isFetchingSLOTypes,
  getSLOTypes,
  isFetchingEventSourceList,
  getEventSourceList,
  getSelectedSLO,
  isFetchingSLO,
  isDeletingSLO,
  deleteSLO,
  getSelectedSLOId,
} from '../../store';
import { usePageTitle } from '../../hooks';
import { SloFormData } from '../../definitions';
import { EditSloView } from './EditSlo.view';

type EditSloContainerProps = {
  visible: boolean;
  onHide: () => void;
} & RouteChildrenProps;

export const EditSloContainer: FunctionComponent<EditSloContainerProps> = ({ visible, onHide }) => {
  const dispatch = useDispatch();
  usePageTitle('Edit SLO');

  const componentList = useSelector(getComponentList);
  const eventSourceList = useSelector(getEventSourceList);
  const sloTypes = useSelector(getSLOTypes);
  const selectedSlo = useSelector(getSelectedSLO);
  const sloId = useSelector(getSelectedSLOId);

  const isUpdating = useSelector(isUpdatingSLO);
  const isDeleting = useSelector(isDeletingSLO);
  const isFetching = useSelector(isFetchingSLO);
  const isLoadingComponentList = useSelector(isFetchingComponentList);
  const isLoadingEventSource = useSelector(isFetchingEventSourceList);
  const isLoadingSLOTypes = useSelector(isFetchingSLOTypes);

  const handleUpdateSLO = useCallback(
    (formData: SloFormData) => {
      dispatch(updateExistingSlo({ formData, sloId }));
    },
    [dispatch, sloId],
  );

  const handleDeleteSLO = useCallback(
    (name = 'SLO') => {
      dispatch(deleteSLO({ name, sloId }));
    },
    [dispatch, sloId],
  );

  return (
    <EditSloView
      onUpdateSlo={handleUpdateSLO}
      isUpdating={isUpdating}
      isDeleting={isDeleting}
      isDisabled={isFetching || isLoadingComponentList || isLoadingSLOTypes || isLoadingEventSource}
      isLoadingComponentList={isLoadingComponentList}
      isLoadingSLOTypes={isLoadingSLOTypes}
      isLoadingEventSource={isLoadingEventSource}
      componentList={componentList}
      eventSourceList={eventSourceList}
      sloTypes={sloTypes}
      selectedSlo={selectedSlo}
      visible={visible}
      onHide={onHide}
      onDelete={handleDeleteSLO}
    />
  );
};
