import { ElementType, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { closeModal, isUserReady } from '../../store';
import { DrawerPosition, ColorTheme } from '../../definitions';
import { Drawer } from '../../components/Drawer';
import Overlay from '../../components/Overlay';

type DrawerRouteProps = {
  component: ElementType;
  path: string;
};

export const DrawerRouteContainer: FunctionComponent<DrawerRouteProps> = ({
  component: Component,
  path,
  ...rest
}) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const isMatch = matchPath(location.hash, {
    path,
    ...rest,
  });

  const isReady = useSelector(isUserReady);
  const launchAnimation = isReady && isMatch;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (launchAnimation) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [launchAnimation]);

  const handleClose = () => dispatch(closeModal(location));

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => {
        const match = matchPath(location.hash, {
          path,
          ...rest,
        });

        if (!match) {
          return null;
        }

        return (
          isReady && (
            <>
              <Drawer
                onHideMenu={handleClose}
                visible={visible}
                position={DrawerPosition.Right}
                fullScreenOnSmall={true}
                colorTheme={ColorTheme.Light}
                title="Create new SLO"
                {...rest}
              >
                <Component location={location} {...props} {...match.params} />
              </Drawer>
              <Overlay onClick={handleClose} isVisible={visible} />
            </>
          )
        );
      }}
    />
  );
};
