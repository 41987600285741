export enum PositionY {
  // eslint-disable-next-line no-restricted-globals
  Top = 'top',
  Bottom = 'bottom',
}
export enum PositionX {
  Left = 'left',
  Right = 'right',
}

export enum Position {
  Column = 'column',
  Row = 'row',
}
