import { FunctionComponent, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import CreateComponent from '../CreateComponent';
import Button from '../../components/Button';
import { Layout } from '../../components/Layout';
import { Icon } from '../../components/Icon';
import { Appearance, ComponentRefInTheList, TeamMap, RelationCluster } from '../../definitions';
import SelectComponentModal from '../../modals/SelectComponent';
import { Loader } from '../../components/Loader';
import { RadioButtonGroup, RadioButtonItem } from '../../components/Form';
import ComponentList from '../../lists/ComponentList';
import { ClusterEnrichedDependencyBlock } from './ClusterEnrichedDependencyBlock';
import ClusterByTagButton from './ClusterByTagRadioButton';
import { DependencyCard } from './SharedComponents';
import * as DependencyBlockStyles from './DependencyBlock';
import * as Styled from './Relations.styles';

type RelationsProps = {
  onViewComponent: (shorname?: string) => void;
  isFetchingComponent: boolean;
  isFetchingDependencies: boolean;
  isCreateDrawerVisible: boolean;
  onCloseCreateComponent: () => void;
  onSelectComponent: (component: ComponentRefInTheList) => void;
  onOpenCreateComponent: () => void;
  numberOfComponents: number;
  teamMap: TeamMap;
  selectedComponent: ComponentRefInTheList | undefined;
  dependencies: ComponentRefInTheList[];
  dependents: ComponentRefInTheList[];
  selectedTagKey?: string;
  clusterBy: RelationCluster;
  onSetClusterBy: (relationCluster: RelationCluster) => void;
  onSetSelectedTagKey: (tagKey: string | undefined) => void;
  onResetClusterByTag: () => void;
  allTags: string[];
  onFetchTags: () => void;
  isFetchingTags: boolean;
  numberOfStandAloneComponents: number;
  isFetchingStandAloneComponents: boolean;
  isStandAlonePrevPageDisabled: boolean;
  isStandAloneNextPageDisabled: boolean;
  standAloneComponentList: ComponentRefInTheList[];
  onFetchStandAloneNextPage: () => void;
  onFetchStandAlonePrevPage: () => void;
} & RouteChildrenProps;

export const RelationsView: FunctionComponent<RelationsProps> = ({
  onViewComponent,
  isFetchingComponent,
  isFetchingDependencies,
  isCreateDrawerVisible,
  onCloseCreateComponent,
  onOpenCreateComponent,
  onSelectComponent,
  numberOfComponents,
  selectedComponent,
  teamMap,
  dependencies,
  dependents,
  selectedTagKey,
  clusterBy,
  onSetClusterBy,
  onSetSelectedTagKey,
  onResetClusterByTag,
  allTags,
  onFetchTags,
  isFetchingTags,
  numberOfStandAloneComponents,
  isFetchingStandAloneComponents,
  isStandAlonePrevPageDisabled,
  isStandAloneNextPageDisabled,
  standAloneComponentList,
  onFetchStandAloneNextPage,
  onFetchStandAlonePrevPage,
  ...rest
}) => {
  const [isSelectComponentModalOpen, setIsSelectComponentModalOpen] = useState(false);
  const [isDependencySelected, setIsDependencySelected] = useState(false);
  const [isDependantSelected, setIsDependantSelected] = useState(false);
  const [selectedComponentId, setSelectedComponentId] = useState('');

  const timeout = (delay: number) => {
    return new Promise(res => setTimeout(res, delay));
  };

  const handleDependencyClick = (component: ComponentRefInTheList) => {
    setSelectedComponentId(component.id);
    onSelectComponent(component);
    return timeout(500);
  };

  const onDependencyClick = (component: ComponentRefInTheList) => {
    setIsDependencySelected(true);
    handleDependencyClick(component).then(() => {
      setIsDependencySelected(false);
      setSelectedComponentId('');
    });
  };

  const onDependantClick = (component: ComponentRefInTheList) => {
    setIsDependantSelected(true);
    handleDependencyClick(component).then(() => {
      setIsDependantSelected(false);
      setSelectedComponentId('');
    });
  };

  const CreateComponentAction: FunctionComponent = () => (
    <Button onClick={onOpenCreateComponent} appearance={Appearance.PrimaryWithIconCollapsible}>
      <Icon.Plus />
      <span>Create component</span>
    </Button>
  );

  const Header: FunctionComponent = () => (
    <Layout.Header>
      <Layout.HeaderTitle>Relations</Layout.HeaderTitle>
    </Layout.Header>
  );

  const getComponentGraph = () => {
    return (
      <Styled.ComponentWrapper>
        <ClusterEnrichedDependencyBlock
          selectedTag={selectedTagKey || ''}
          relationCluster={clusterBy}
          components={dependents}
          onComponentClick={onDependantClick}
          noSelectedComponentTitle="Component dependents will be displayed here."
          noDependenciesTitle="This component doesn't have any dependents yet. You can link dependant on the Component page."
          isDependencySelected={isDependencySelected}
          isDependantSelected={isDependantSelected}
          selectedComponent={selectedComponent}
          selectedComponentId={selectedComponentId}
          onSetSelectComponentModalOpen={setIsSelectComponentModalOpen}
          onViewComponent={onViewComponent}
          isFetching={isFetchingDependencies || isFetchingComponent}
          teamMap={teamMap}
        />
        <Styled.SelectedComponentWrapper>
          <DependencyBlockStyles.Block>
            <Styled.SelectedComponentContainer
              isDependencySelected={isDependencySelected}
              isDependantSelected={isDependantSelected}
            >
              {selectedComponent?.id ? (
                <>
                  <Styled.ArrowWrapper>
                    <Icon.ArrowUp />
                  </Styled.ArrowWrapper>
                  <Styled.Subtitle>Dependents</Styled.Subtitle>
                  <DependencyCard
                    component={selectedComponent}
                    onNameClick={() => onSelectComponent(selectedComponent)}
                    onViewComponent={onViewComponent}
                    onSetSelectComponentModalOpen={setIsSelectComponentModalOpen}
                    isDependencySelected={isDependencySelected}
                    isDependantSelected={isDependantSelected}
                    isSelected={false}
                    shortname={selectedComponent.shortname}
                  />
                  <Styled.Subtitle>Dependencies</Styled.Subtitle>
                  <Styled.ArrowWrapper>
                    <Icon.ArrowUp />
                  </Styled.ArrowWrapper>
                </>
              ) : isFetchingComponent || isFetchingDependencies ? (
                <DependencyBlockStyles.LoaderWrapper>
                  <Loader />
                </DependencyBlockStyles.LoaderWrapper>
              ) : (
                <Styled.SelectedEmpty>
                  <p>Select a component to build dependency tree.</p>
                  <Button
                    onClick={() => setIsSelectComponentModalOpen(true)}
                    appearance={Appearance.PrimaryWithIcon}
                  >
                    <Icon.Create />
                    <span>Select</span>
                  </Button>
                </Styled.SelectedEmpty>
              )}
            </Styled.SelectedComponentContainer>
          </DependencyBlockStyles.Block>
        </Styled.SelectedComponentWrapper>
        <ClusterEnrichedDependencyBlock
          selectedTag={selectedTagKey || ''}
          relationCluster={clusterBy}
          teamMap={teamMap}
          isDependencySelected={isDependencySelected}
          isDependantSelected={isDependantSelected}
          selectedComponent={selectedComponent}
          selectedComponentId={selectedComponentId}
          onSetSelectComponentModalOpen={setIsSelectComponentModalOpen}
          onViewComponent={onViewComponent}
          components={dependencies}
          onComponentClick={onDependencyClick}
          isFetching={isFetchingDependencies}
          noSelectedComponentTitle="Component dependencies will be displayed here."
          noDependenciesTitle="This component doesn't have any dependencies yet. You can link dependancy on the Component page."
        />
      </Styled.ComponentWrapper>
    );
  };

  return (
    <AuthenticatedLayout header={Header}>
      <Layout.Heading
        actions={[<CreateComponentAction key="create-component-btn" />]}
        subheading="A graph of all components currently registered within the system."
      >
        All components ({numberOfComponents})
      </Layout.Heading>
      <h4>Cluster by</h4>
      <Styled.RadioButtonGroupWrapper>
        <RadioButtonGroup>
          <RadioButtonItem
            isChecked={clusterBy === RelationCluster.Team}
            label="Team"
            onClick={() => onSetClusterBy(RelationCluster.Team)}
          />
          <RadioButtonItem
            isChecked={clusterBy === RelationCluster.Type}
            label="Component type"
            onClick={() => onSetClusterBy(RelationCluster.Type)}
          />
          <ClusterByTagButton
            onResetTag={onResetClusterByTag}
            selectedTag={selectedTagKey}
            tags={allTags}
            clusterBy={clusterBy}
            onSetClusterByTag={() => onSetClusterBy(RelationCluster.Tag)}
            onSelectTag={onSetSelectedTagKey}
            onFetchTags={onFetchTags}
            isFetchingTags={isFetchingTags}
          />
          <RadioButtonItem
            isChecked={clusterBy === RelationCluster.None}
            label="None"
            onClick={() => onSetClusterBy(RelationCluster.None)}
          />
        </RadioButtonGroup>
      </Styled.RadioButtonGroupWrapper>
      {getComponentGraph()}

      <Layout.Heading subheading="A list of all components that don't have any dependencies or dependents. Add component relation on the dedicated component page, accessible by clicking the component's name link.">
        Stand-alone components ({numberOfStandAloneComponents})
      </Layout.Heading>
      <ComponentList
        isFetching={isFetchingStandAloneComponents}
        componentList={standAloneComponentList}
        onNextPage={onFetchStandAloneNextPage}
        onPrevPage={onFetchStandAlonePrevPage}
        numberOfComponents={numberOfStandAloneComponents}
        isPrevPageDisabled={isStandAlonePrevPageDisabled}
        isNextPageDisabled={isStandAloneNextPageDisabled}
        onViewComponent={onViewComponent}
      />
      <CreateComponent visible={isCreateDrawerVisible} onHide={onCloseCreateComponent} {...rest} />
      <SelectComponentModal
        isOpen={isSelectComponentModalOpen}
        onClose={() => setIsSelectComponentModalOpen(false)}
        onSelectComponent={onSelectComponent}
        {...rest}
      />
    </AuthenticatedLayout>
  );
};
