import { ElementType, FunctionComponent, useContext, useState } from 'react';
import { MediaContext } from '../../contexts';
import * as Styled from './Layout.styles';

type LayoutProps = {
  isSidebarPersistentOnMedium?: boolean;
  header?: ElementType;
  metaPanel?: ElementType;
};

type ILayoutView = FunctionComponent<LayoutProps> & {
  Header: ElementType;
  HeaderActions: ElementType;
  HeaderTitle: ElementType;

  Container: ElementType;
  Heading: ElementType;
  SubHeading: ElementType;

  Section: ElementType;
  SectionHeading: ElementType;
};

type HeadingProps = {
  icon: ElementType;
  actions?: ElementType[];
  isSubtle?: boolean;
  subheading?: string;
};

const Heading: FunctionComponent<HeadingProps> = ({ icon, actions, children, subheading }) => (
  <Styled.HeadingWrapper>
    <Styled.HeadingRow hasSubheading={!!subheading}>
      <Styled.Heading>
        {icon}
        <h2>{children}</h2>
      </Styled.Heading>
      <Styled.Actions>
        {!!(actions && actions.length) &&
          actions.map((action: ElementType, index: number) => (
            <Styled.ActionWrapper key={index}>{action}</Styled.ActionWrapper>
          ))}
      </Styled.Actions>
    </Styled.HeadingRow>
    {subheading && <Styled.SubHeading>{subheading}</Styled.SubHeading>}
  </Styled.HeadingWrapper>
);

const Header: FunctionComponent = ({ children }) => (
  <Styled.Header>
    <Styled.HeaderInner>{children}</Styled.HeaderInner>
  </Styled.Header>
);

export const LayoutView: ILayoutView = ({
  children,
  header,
  metaPanel,
  isSidebarPersistentOnMedium = false,
  ...rest
}) => {
  const HeaderComponent = header;
  const { isMediumLayout } = useContext(MediaContext);
  const [isCollapsed, setIsCollapsed] = useState(!isMediumLayout);

  const MetaPanel = metaPanel;

  return (
    <Styled.Wrapper isSidebarPersistentOnMedium={isSidebarPersistentOnMedium} {...rest}>
      {header && HeaderComponent && <HeaderComponent />}
      <Styled.Container>
        <Styled.ContainerInner>{children}</Styled.ContainerInner>
        {MetaPanel && (
          <MetaPanel
            isCollapsed={isCollapsed}
            onSetIsCollapsed={(newValue: boolean) => setIsCollapsed(newValue)}
          />
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

LayoutView.Header = Header;
LayoutView.HeaderActions = Styled.Actions;
LayoutView.HeaderTitle = Styled.HeaderTitle;

LayoutView.Heading = Heading;
LayoutView.SubHeading = Styled.SubHeading;
LayoutView.Container = Styled.Container;

LayoutView.SectionHeading = Styled.SectionHeading;
LayoutView.Section = Styled.Section;
