import styled from 'styled-components';
import { rgba } from 'polished';

export const Container = styled.div`
  background-color: ${props => rgba(props.theme.palettes.main, 0.25)};
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.drawerOverlay};
  animation: ${({ theme }) => theme.effects.fadeIn} 0.5s linear forwards;
`;
