import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

type LinkProps = {
  isActive: boolean;
};

export const ResponsiveNavLogin = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-bottom: 2rem;
  flex-direction: column;
  align-items: center;

  svg {
    width: 1.5rem;
    height: auto;
  }

  a {
    height: 3rem;
    max-width: 10rem;
    color: ${({ theme }) => theme.palettes.highlight};
    ${props => `
    &:hover {
      text-decoration: none;
      color: ${darken(0.15, props.theme.palettes.highlight)};
    }
  `}
  }
`;

export const ClickableLink = styled.a<LinkProps>`
  background: ${props => (props.isActive ? props.theme.palettes.highlight : 'none')};
`;

export const NavLink = styled(Link)<LinkProps>`
  background: ${props => (props.isActive ? props.theme.palettes.highlight : 'none')};
`;

export const Wrapper = styled.div`
  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
`;
