import styled from 'styled-components';

type WrapperProps = {
  overlay: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  padding: 10rem 0;
  height: 100vh;
  background: ${props => props.theme.palettes.main};
  color: ${({ theme }) => theme.palettes.inverse};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled.h5`
  margin: 0;
  padding-top: 1.5rem;

  ${props => props.theme.media.medium`
    padding-top: 2.5rem;
  `}
`;
