import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getXEventList,
  isFetchingXEventList,
  isFetchingXEventListNextPage,
  getTotalNumberOfXEvents,
  getXEventListNextPageToken,
  fetchXEventList,
  fetchNextXEventListPage,
} from '../../../store';
import { XEventsView } from './XEvents.view';

type XEventContainerProps = {
  componentId: string;
};

export const XEventContainer: FunctionComponent<XEventContainerProps> = ({
  componentId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isFetchingXEvents = useSelector(isFetchingXEventList);
  const isFetchingNextPage = useSelector(isFetchingXEventListNextPage);

  const xEventList = useSelector(getXEventList);
  const numberOfXEvents = useSelector(getTotalNumberOfXEvents);
  const nextPageToken = useSelector(getXEventListNextPageToken);

  useEffect(() => {
    componentId && dispatch(fetchXEventList({ componentId }));
  }, [dispatch, componentId]);

  const handleFetchNextPage = useCallback(() => {
    componentId && dispatch(fetchNextXEventListPage({ componentId }));
  }, [dispatch, componentId]);

  return (
    <XEventsView
      xEventList={xEventList}
      isFetchingList={isFetchingXEvents || isFetchingNextPage}
      onNextPage={handleFetchNextPage}
      numberOfXEvents={numberOfXEvents}
      hasMoreToLoad={!!nextPageToken}
      {...rest}
    />
  );
};
