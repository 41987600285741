import { FunctionComponent, ReactNode, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import {
  EditComponentFormData,
  parsers,
  validation,
  Appearance,
  FormStateType,
  FlattenedTeam,
} from '../../definitions';
import { FormAutoSave, FormLayout, Input, Select, Textarea } from '../../components/Form';
import SetOwnerModal from '../../modals/SetOwner';
import { Icon } from '../../components/Icon';
import Button from '../../components/Button';
import { Layout } from '../../components/Layout';
import SetOwnerInput from './SetOwnerInput';

type ComponentFormProps = {
  isFetching: boolean;
  isDeleting?: boolean;
  onSubmit: (formData: EditComponentFormData) => void;
  initialValues?: EditComponentFormData;
  submitButtonText: string;
  extraAction?: ReactNode;
  isSubmitting?: boolean;
  componentTypes: CatalogItemView.AsObject[];
  onClose: () => void;
  submitButtonIcon?: ReactNode;
  onUpdateComponentState?: (formState: FormStateType) => void;
  isShortnameDisabled?: boolean;
};

export const ComponentFormView: FunctionComponent<ComponentFormProps> = ({
  onSubmit,
  isFetching,
  isDeleting,
  initialValues,
  submitButtonText,
  componentTypes,
  isSubmitting,
  extraAction,
  onClose,
  submitButtonIcon,
  onUpdateComponentState,
  isShortnameDisabled = false,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
  const [isSetOwnerModalOpen, setIsSetOwnerModalOpen] = useState(false);

  const componentTypesOptions = useMemo(
    () =>
      componentTypes.map(type => ({
        value: type.id,
        label: type.metadata?.displayname,
      })),
    [componentTypes],
  );

  return (
    <FormLayout>
      <Form
        initialValues={initialValues}
        onSubmit={(values: EditComponentFormData) => {
          onSubmit(values);
        }}
        render={({ handleSubmit, pristine, valid, values, form }) => {
          const onSubmitClick = (
            event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
          ) => {
            setClickedSubmit(true);
            handleSubmit(event);
          };
          return (
            <>
              <Layout.Section>
                <Layout.SectionHeading>Component type</Layout.SectionHeading>
                <FormLayout.Row>
                  <Field
                    id="componentType"
                    name="componentType"
                    component={Select}
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    onReset={() => form.change('componentType', undefined)}
                    isLoadingOptions={isFetching}
                    options={componentTypesOptions}
                    description="The component type refers to a component's functionality description. Anything can be considered a component, including services, products, capabilities, or even experiences."
                  />
                </FormLayout.Row>
              </Layout.Section>
              <Layout.Section>
                <Layout.SectionHeading>Owner team</Layout.SectionHeading>
                <FormLayout.Row>
                  <Field
                    id="ownerTeam"
                    name="ownerTeam"
                    component={SetOwnerInput}
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    onOpenSetOwnerModal={() => setIsSetOwnerModalOpen(true)}
                    description="An owner team is a group or department that is responsible for the management, maintenance, and well-being of that particular component. Every component must have one owner team assigned."
                  />
                  <SetOwnerModal
                    isOpen={isSetOwnerModalOpen}
                    onClose={() => setIsSetOwnerModalOpen(false)}
                    onSubmit={(ownerTeam: FlattenedTeam) => form.change('ownerTeam', ownerTeam)}
                    currentOwnerTeam={initialValues?.ownerTeam}
                  />
                </FormLayout.Row>
              </Layout.Section>
              <Layout.Section>
                <Layout.SectionHeading>Metadata</Layout.SectionHeading>
                <FormLayout.Row>
                  <Field
                    id="shortname"
                    name="shortName"
                    description="The shortname is a unique identifier that will be used as part of the component URL."
                    label="Shortname"
                    component={Input}
                    validate={value => validation.required(value)}
                    // prefix="https://andromeda.rocketslo.com/slo/"
                    format={parsers.trim}
                    maxLength="120"
                    formatOnBlur={true}
                    showValidation={clickedSubmit}
                    disabled={isShortnameDisabled}
                  />
                  <Field
                    id="displayName"
                    name="displayName"
                    description="The display name should be unique and representative of the service or capability that the components stands for."
                    label="Display name"
                    component={Input}
                    showValidation={clickedSubmit}
                    format={parsers.trim}
                    formatOnBlur={true}
                    maxLength="120"
                    isOptional={true}
                  />
                </FormLayout.Row>
                <FormLayout.Row>
                  <Field
                    id="description"
                    name="description"
                    description="Use this field to provide information about the component characteristics and any necessary and helpful details about the capability."
                    label="Description"
                    component={Textarea}
                    showValidation={clickedSubmit}
                    format={parsers.trim}
                    formatOnBlur={true}
                    isOptional={true}
                    maxLength="120"
                  />
                </FormLayout.Row>
              </Layout.Section>
              <FormLayout.ActionWrapper>
                <Button
                  onClick={onClose}
                  isDisabled={isSubmitting}
                  appearance={Appearance.PrimaryWithIcon}
                >
                  <Icon.Collapse />
                  <span>Cancel</span>
                </Button>
                {extraAction}
                <FormLayout.Submit
                  isPending={isSubmitting}
                  isDisabled={isFetching || isDeleting}
                  onClick={onSubmitClick}
                  appearance={submitButtonIcon ? Appearance.PrimaryWithIcon : Appearance.Primary}
                  isHighlighted={true}
                >
                  {submitButtonIcon && submitButtonIcon}
                  <span>{submitButtonText}</span>
                </FormLayout.Submit>
              </FormLayout.ActionWrapper>
              {onUpdateComponentState && <FormAutoSave updateFormState={onUpdateComponentState} />}
            </>
          );
        }}
      />
    </FormLayout>
  );
};
