import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import Button from '../../Button';
export { Label } from '../Input/Input.styles';

type WrapperProps = {
  checked?: boolean;
  disabled?: boolean;
  isRadio?: boolean;
};

type RadioButtonProps = {
  isChecked?: boolean;
};

export const Wrapper = styled.label<WrapperProps>`
  position: relative;
  cursor: pointer;
  padding: 0;
  display: flex;
  user-select: none;

  &:hover {
  }

  ${props =>
    props.checked &&
    css`
      &:hover {
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
    `}
`;

export const RadioButton = styled(Button)<RadioButtonProps>`
  border-radius: 0;
  margin: -1px;

  &:hover {
    &&& {
      box-shadow: none;
      background: ${props => props.theme.palettes.inverse};
      color: ${props => props.theme.palettes.secondaryHighlight};
    }
  }

  ${props =>
    props.isChecked &&
    css`
      background: ${props.theme.palettes.secondaryHighlight};
      color: ${props.theme.palettes.inverse};

      &:hover {
        &&& {
          background: ${lighten(0.1, props.theme.palettes.secondaryHighlight)};
          color: ${props.theme.palettes.inverse};
        }
      }
    `}
`;

export const Container = styled.div`
  display: inline-flex;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid ${props => props.theme.palettes.border};
  box-shadow: ${props => props.theme.effects.borderShadow};
`;
