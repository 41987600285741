import { FunctionComponent, useRef, useState } from 'react';
import { useLayer, Arrow } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { XEventInTheList, Appearance } from '../../../definitions';
import { useScrollWithShadow } from '../../../hooks';
import { DateTime, Status, DisplayNameLink } from '../../../components/xEventDetails';
import List from '../../../components/List';
import { XEvent } from '../../../grpc/grpcweb/xevent_pb';
import Button from '../../../components/Button';
import Pagination from '../../../components/Pagination';
import * as Styled from './XEvents.styles';

type XEventsViewProps = {
  xEventList: XEventInTheList[];
  isFetchingList: boolean;
  onNextPage: () => void;
  numberOfXEvents: number;
  hasMoreToLoad: boolean;
};

const DisplayName: FunctionComponent<XEventInTheList> = props => {
  return (
    <>
      <DisplayNameLink {...props} />
      <MorePopup {...props} />
    </>
  );
};

const MorePopup: FunctionComponent<XEventInTheList> = ({ ...rest }) => {
  const [isOpen, setOpen] = useState(false);
  const scrollRef = useRef(null);
  const containerRef = useRef(null);
  const { isBorderBottom, isBorderTop, onScrollHandler } = useScrollWithShadow(
    scrollRef,
    containerRef,
  );

  const handleClose = () => {
    setOpen(false);
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  return (
    <>
      <Styled.TriggerWrapper {...triggerProps}>
        <Button appearance={Appearance.Link} onClick={() => setOpen(!isOpen)}>
          ...more
        </Button>
      </Styled.TriggerWrapper>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <div ref={containerRef}>
                <Styled.PopupContent
                  isBorderBottom={isBorderBottom}
                  isBorderTop={isBorderTop}
                  onScroll={onScrollHandler}
                >
                  <div ref={scrollRef}>
                    <Styled.PopupContentHeader>
                      <Styled.PopupMeta>
                        <Status {...rest} />
                        <DateTime {...rest} />
                      </Styled.PopupMeta>
                      <DisplayNameLink {...rest} />
                    </Styled.PopupContentHeader>
                    <Styled.PopupDetails>
                      {rest.description && <p>{rest.description}</p>}
                      {rest.metafieldsList.map((field: XEvent.MetaField.AsObject) => (
                        <Styled.Metafield key={field.label}>
                          <Styled.Label>{field.label}</Styled.Label>
                          <Styled.Value>{field.value}</Styled.Value>
                        </Styled.Metafield>
                      ))}
                    </Styled.PopupDetails>
                  </div>
                </Styled.PopupContent>
              </div>
              <Arrow {...arrowProps} />
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};

export const XEventsView: FunctionComponent<XEventsViewProps> = ({
  xEventList,
  isFetchingList,
  onNextPage,
  numberOfXEvents,
  hasMoreToLoad,
}) => {
  return (
    <div id="external-events">
      <List
        items={xEventList}
        columns={[DateTime, Status, DisplayName]}
        hideOnSmall={[]}
        itemKey="id"
        emptyText="There have been no events for the component yet."
        compactFieldList={[DateTime, Status]}
        compactFieldWidth="12rem"
      />
      {numberOfXEvents > xEventList.length && (
        <Pagination.LoadMore
          onLoadMoreClick={onNextPage}
          isLoadMoreDisabled={!hasMoreToLoad}
          isLoading={isFetchingList}
        />
      )}
    </div>
  );
};
