import styled from 'styled-components';
import { Row } from '../NewValue/NewValue.styles';
export * from '../NewValue/NewValue.styles';

export const CurrentValuesContainer = styled.p`
  padding-left: 0.75rem;
`;

export const NoGapRow = styled(Row)`
  gap: 0;
`;
