import { useState, FunctionComponent } from 'react';
import { Checkbox } from '../Form/Checkbox';

export type BulkSelectProps = {
  items: { id: string }[];
  disabledIdMap: Set<string>;
  onChange: (newState: Record<string, boolean>) => void;
  selectedState: Record<string, boolean>;
};

export const BulkSelectView: FunctionComponent<BulkSelectProps> = ({
  items,
  onChange,
  disabledIdMap,
  selectedState,
}) => {
  const filteredItems = items.filter((item: { id: string }) => !disabledIdMap.has(item.id));
  const preSelected = filteredItems.filter((item: { id: string }) =>
    Boolean(selectedState[item.id]),
  );
  const areAllSelected = preSelected.length === filteredItems.length;
  const [isChecked, setIsChecked] = useState(areAllSelected);
  const handleCheckboxChange = () => {
    if (filteredItems.length > 0) {
      filteredItems.forEach((item: { id: string }) => onChange({ [item.id]: !isChecked }));
      return setIsChecked(prev => !prev);
    }
  };

  return (
    <Checkbox
      type="checkbox"
      isChecked={isChecked}
      isDisabled={filteredItems.length === 0}
      onChange={() => handleCheckboxChange()}
    ></Checkbox>
  );
};
