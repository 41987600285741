import styled from 'styled-components';
import { motion } from 'framer-motion';
export * from '../Confirmation/Confirmation.styles';

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const Content = styled.p`
  margin: 1rem 0;
  font-weight: 300;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  h3 {
    line-height: 1.5rem;
  }
`;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  max-width: 20rem;
  background: ${({ theme }) => theme.palettes.inverse};
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  padding: 1rem;
  justify-content: space-between;
  border-radius: 1rem;
  white-space: normal;
`;
