/**
 * Container for services, an instance of the container is passed to any child services
 */
export default class Services {
  private readonly deviceId: string | undefined;

  private readonly apiHost: string | undefined;

  private readonly platform: string | undefined;

  private readonly version: string | undefined;

  public readonly instances: { [key: string]: any };

  constructor(apiHost?: string, deviceId?: string, platform?: string, version?: string) {
    this.apiHost = apiHost;
    this.deviceId = deviceId;
    this.platform = platform;
    this.version = version;
    this.instances = {};
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  add(name: string, Service: any, ...args: any[]): void {
    this.instances[name] = new Service(...args);
  }

  initialise(): Promise<any> {
    return Promise.all(
      Object.values(this.instances).reduce((result, instance) => {
        if (typeof instance.initialise === 'function') {
          return [
            ...result,
            instance.initialise({
              apiHost: this.apiHost,
              deviceId: this.deviceId,
              platform: this.platform,
              version: this.version,
              ...this.instances,
            }),
          ];
        }

        return result;
      }, []),
    );
  }
}
