import { FunctionComponent } from 'react';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import { Drawer } from '../../components/Drawer';
import Button from '../../components/Button';
import PopConfirm from '../../components/PopConfirm';
import {
  ColorTheme,
  DrawerPosition,
  EditSloFormData,
  EventSourcesInTheList,
  SloFormData,
  ComponentRefInTheList,
  Appearance,
} from '../../definitions';
import SloForm from '../../forms/SloForm';
import { Icon } from '../../components/Icon';

type EditSloProps = {
  onUpdateSlo: (formData: SloFormData) => void;
  componentList: ComponentRefInTheList[];
  isUpdating: boolean;
  isDisabled: boolean;
  isLoadingComponentList: boolean;
  isLoadingSLOTypes: boolean;
  isLoadingEventSource: boolean;
  eventSourceList: EventSourcesInTheList[];
  sloTypes: CatalogItemView.AsObject[];
  selectedSlo: EditSloFormData;
  onDelete: (displayName?: string) => void;
  isDeleting: boolean;
  visible: boolean;
  onHide: () => void;
};

export const EditSloView: FunctionComponent<EditSloProps> = ({
  onUpdateSlo,
  isUpdating,
  isDisabled,
  isLoadingComponentList,
  componentList,
  isLoadingSLOTypes,
  sloTypes,
  selectedSlo,
  eventSourceList,
  isLoadingEventSource,
  visible,
  onHide,
  onDelete,
  isDeleting,
}) => {
  const DeleteButton: FunctionComponent = () => (
    <Button
      isDisabled={isDeleting || isUpdating}
      isPending={isDeleting}
      appearance={Appearance.PrimaryWithIcon}
    >
      <Icon.Trash />
      <span>Delete</span>
    </Button>
  );

  return (
    <Drawer
      visible={visible}
      onHideMenu={onHide}
      position={DrawerPosition.Right}
      fullScreenOnSmall={true}
      colorTheme={ColorTheme.Light}
      title="Edit SLO"
    >
      <SloForm
        onSubmitSlo={onUpdateSlo}
        componentList={componentList}
        isLoading={isUpdating}
        isDisabled={isDisabled || isDeleting}
        isLoadingComponentList={isLoadingComponentList}
        isLoadingSLOTypes={isLoadingSLOTypes}
        sloTypes={sloTypes}
        initialValues={selectedSlo}
        eventSourceList={eventSourceList}
        isLoadingEventSource={isLoadingEventSource}
        submitButtonText="Update"
        submitButtonIcon={<Icon.Save />}
        onCancel={onHide}
        extraAction={
          <PopConfirm
            title={`Are you sure you want to delete SLO ${selectedSlo.displayName}?`}
            onConfirm={() => onDelete(selectedSlo.displayName || selectedSlo.shortName)}
            isConfirming={isDeleting}
            isDisabled={isDeleting || isUpdating}
            contentText={`Please note that after confirming SLO ${
              selectedSlo.displayName || selectedSlo.shortName
            } will be permanently deleted.
            You will not be able to restore the deleted SLO.`}
          >
            <DeleteButton />
          </PopConfirm>
        }
      />
    </Drawer>
  );
};
