import { FunctionComponent, ElementType } from 'react';
import NextPrev from './NextPrev';
import LoadMore from './LoadMore';
import { NextPrevProps } from './NextPrev/NextPrev.view';
import { LoadMoreProps } from './LoadMore/LoadMore.view';

import * as Styled from './Pagination.styles';

type PaginationProps = FunctionComponent<{ totalItems: number }> & {
  NextPrev: ElementType<NextPrevProps>;
  LoadMore: ElementType<LoadMoreProps>;
};

export const PaginationView: PaginationProps = ({ children, totalItems }) => {
  return (
    <Styled.PaginationWrapper>
      <p>{`Found ${totalItems} item${totalItems === 1 ? '' : 's'}`}</p>
      {children}
    </Styled.PaginationWrapper>
  );
};

PaginationView.NextPrev = NextPrev;
PaginationView.LoadMore = LoadMore;
