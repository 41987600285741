import styled from 'styled-components';

export const DropdownRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  svg {
    width: 1.25rem;
    height: auto;
    margin-right: 0.5rem;
  }
`;
