import { FunctionComponent, useState, ReactNode } from 'react';
import { Form, Field } from 'react-final-form';
import { EventSourceType as EventSourceTypeGRPC } from '../../grpc/grpcweb/eventsource_pb';
import {
  validation,
  parsers,
  CreateSourceFormDataWithAuthType,
  FormStateType,
  EditSourceFormData,
  EventSourceType,
  PrometheusAuthType,
  CloudWatchAuthType,
  Appearance,
} from '../../definitions';
import { Input, Select, FormLayout, FormAutoSave, Textarea } from '../../components/Form';
import CloudWatchForm from './CloudWatchForm.view';
import PrometheusForm from './PrometheusForm.view';
import EventSourceDropdownItem from '../EventSourceDropdownItem';
import { Icon } from '../../components/Icon';
import Button from '../../components/Button';

type CreateEventSourceProps = {
  isLoading: boolean;
  onSubmit: (formData: CreateSourceFormDataWithAuthType | EditSourceFormData) => void;
  onCancel: () => void;
  initialValues: CreateSourceFormDataWithAuthType | EditSourceFormData;
  onUpdateEventSourceState?: (formState: FormStateType) => void;
  action: string;
  extraAction?: ReactNode;
  isDisabled?: boolean;
};

export const CreateEventSourceView: FunctionComponent<CreateEventSourceProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  onUpdateEventSourceState = () => {},
  action,
  extraAction,
  isDisabled = false,
  onCancel,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
  const eventSourceTypes = [
    {
      value: EventSourceTypeGRPC.EVENT_SOURCE_CLOUDWATCH,
      label: EventSourceType.CloudWatch,
      type: EventSourceTypeGRPC.EVENT_SOURCE_CLOUDWATCH,
    },
    {
      value: EventSourceTypeGRPC.EVENT_SOURCE_PROMETHEUS,
      label: EventSourceType.Prometheus,
      type: EventSourceTypeGRPC.EVENT_SOURCE_PROMETHEUS,
    },
  ];

  return (
    <Form
      autoComplete="off"
      onSubmit={(values: CreateSourceFormDataWithAuthType | EditSourceFormData) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          setClickedSubmit(true);
          handleSubmit(event);
        };

        const handleCancel = () => {
          form.reset({});
          onCancel();
        };

        return (
          <>
            <FormLayout.FieldSet>
              <Field
                id="displayName"
                name="displayName"
                label="Display name"
                component={Input}
                validate={value => validation.required(value)}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                maxLength="120"
                description="Display name should be unique and represent the event source type."
              />
              <Field
                id="sourceType"
                name="sourceType"
                label="Event source type"
                component={Select}
                options={eventSourceTypes}
                validate={value => validation.required(value)}
                showValidation={clickedSubmit}
                onReset={() => form.change('sourceType', undefined)}
                formatOptionLabel={EventSourceDropdownItem}
                onChange={() => {
                  const fieldState = form.getFieldState('sourceType')?.value;
                  if (fieldState === EventSourceTypeGRPC.EVENT_SOURCE_CLOUDWATCH) {
                    form.change('authenticationType', CloudWatchAuthType.AccessKey);
                  } else if (fieldState === EventSourceTypeGRPC.EVENT_SOURCE_PROMETHEUS) {
                    form.change('authenticationType', PrometheusAuthType.Basic);
                  }
                }}
                description="Select the event source from the supported services. If you don't see a desired service, reach out to our team, and we will consider adding it in the future."
              />
              <Field
                id="description"
                name="description"
                label="Description"
                component={Textarea}
                validate={value => validation.required(value)}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                maxLength="120"
                description="Use this field to provide information about the event source characteristics and any necessary and helpful details about it."
              />
              {form.getFieldState('sourceType')?.value ===
              EventSourceTypeGRPC.EVENT_SOURCE_CLOUDWATCH ? (
                <CloudWatchForm
                  authenticationType={CloudWatchAuthType.AccessKey}
                  clickedSubmit={clickedSubmit}
                />
              ) : (
                <PrometheusForm
                  authenticationType={PrometheusAuthType.Basic}
                  clickedSubmit={clickedSubmit}
                />
              )}
            </FormLayout.FieldSet>
            <FormAutoSave updateFormState={onUpdateEventSourceState} />
            <FormLayout.ModalPreFooterSpacer />
            <FormLayout.ModalFooter>
              <Button
                onClick={handleCancel}
                isDisabled={isLoading}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Cross />
                <span>Cancel</span>
              </Button>
              {extraAction}
              <FormLayout.ModalSubmit
                onClick={onSubmitClick}
                isPending={isLoading}
                isDisabled={isDisabled}
                appearance={Appearance.PrimaryWithIcon}
                isHighlighted={true}
              >
                <Icon.Edit />
                <span>{action}</span>
              </FormLayout.ModalSubmit>
            </FormLayout.ModalFooter>
          </>
        );
      }}
    />
  );
};
