import { useState, FunctionComponent, ElementType } from 'react';
import { Checkbox } from '../../Form/Checkbox';
import * as Styled from './BulkSelectRadio.styles';

export type BulkSelectRadioProps = {
  item: { id: string };
  onChange: (item: { id: string }) => void;
  currentItemId?: string;
  selectedItemId?: string;
  label?: string;
};

type IBulkSelectRadioView = FunctionComponent<BulkSelectRadioProps> & {
  Container: ElementType;
};

export const BulkSelectRadioView: IBulkSelectRadioView = ({
  onChange,
  selectedItemId,
  currentItemId,
  item,
  label,
}) => {
  const prioritySelectionId = selectedItemId || currentItemId;
  const isSelected = item.id === prioritySelectionId;
  const [isChecked, setIsChecked] = useState(isSelected);

  const handleRadioChange = () => {
    onChange(item);
    return setIsChecked(prev => !prev);
  };

  if (label) {
    return (
      <Styled.LabelWrapper>
        <Checkbox isChecked={isChecked} onChange={() => handleRadioChange()} isRadio={true} />
        <p>{label}</p>
      </Styled.LabelWrapper>
    );
  }

  return <Checkbox isChecked={isChecked} onChange={() => handleRadioChange()} isRadio={true} />;
};

BulkSelectRadioView.Container = Styled.Container;
