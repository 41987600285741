export enum TimeWindowUnitType {
  Unspecified = 'unspecified',
  Hour = 'hour',
  Day = 'day',
}

export enum TimeWindowType {
  Unspecified = 'unspecified',
  Rolling = 'rolling',
  Static = 'static',
}
