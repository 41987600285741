import { SequenceType, ActionType } from '../../definitions';

export type LoadingState = {
  [k: string]: boolean;
};

const loading = (state: LoadingState = {}, { rootAction, sequence }: ActionType): LoadingState => {
  if (!rootAction || !rootAction.type) {
    return state;
  }

  switch (sequence) {
    case SequenceType.Start:
      return {
        ...state,
        [rootAction.type]: true,
      };

    case SequenceType.Cancel:
    case SequenceType.Error:
    case SequenceType.Success:
      return {
        ...state,
        [rootAction.type]: false,
      };

    default:
      return state;
  }
};

export default loading;
