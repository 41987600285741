import { all, takeLatest, select, put, AllEffect, call } from 'redux-saga/effects';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import {
  ListTeamsRequest,
  ListTeamsOptions,
  GetTeamByShortNameRequest,
  AddMembersRequest,
  DeleteMemberRequest,
  CreateTeamRequest,
  TeamMeta,
  UpdateTeamRequest,
  GetTeamByIdRequest,
  DeleteTeamRequest,
} from '../../grpc/grpcweb/team_pb';
import services from '../../services';
import { SequenceType, request, ActionType, AlertType, TeamFormData } from '../../definitions';
import actionTypes from '../constants/actionTypes';
import {
  getAccessToken,
  getFetchTeamError,
  getFetchTeamListError,
  getFetchTeamListNextPageError,
  getFetchTeamListPrevPageError,
  getTeamListNextPageToken,
  getTeamListPrevPageToken,
  getAddUsesrToTheTeamError,
  getRemoveUserFromTheTeamError,
  getCreateTeamError,
  getUpdateTeamError,
  getFetchTeamByIdError,
  getDeleteTeamError,
  getTeamListCurrPageToken,
} from '../selectors';
import { clearSuccess, fetchTeamList, resetNewTeamState, showAlert } from '../actions';
import { PageRequest, TextItem, Avatar } from '../../grpc/grpccommon/common_pb';
import { RootState } from '../reducers';
import { TEAM_LIST_PAGE_SIZE } from '../constants/pageSize';

function* createTeam(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to create new team.',
      }),
    );
  }

  const formData: TeamFormData = action.payload;
  const { description, shortname, displayname, avatarUrl } = formData;

  const createTeamRequest = new CreateTeamRequest();
  const teamMeta = new TeamMeta();

  shortname && teamMeta.setShortname(shortname);
  displayname && teamMeta.setDisplayname(displayname);
  if (description) {
    const textItem = new TextItem();
    textItem.setValue(description);
    textItem.setType(1);
    teamMeta.setDescription(textItem);
  }

  const avatarData = new Avatar();
  avatarData.setType(1);
  avatarUrl ? avatarData.setUri(avatarUrl) : avatarData.setUri('avatar');
  teamMeta.setAvatar(avatarData);

  createTeamRequest.setMeta(teamMeta);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.teams.created },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .createTeam(token, createTeamRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchTeamList());
    yield put(resetNewTeamState());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${displayname || shortname} team successfully created.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getCreateTeamError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* updateTeam(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update the team.',
      }),
    );
  }

  const { formData, teamId }: { formData: TeamFormData; teamId: string } = action.payload;
  const { description, shortname, displayname, avatarUrl } = formData;

  const updateTeamRequest = new UpdateTeamRequest();
  updateTeamRequest.setTeamid(teamId);

  const teamMeta = new TeamMeta();
  const mask = new FieldMask();

  if (typeof displayname === 'string') {
    teamMeta.setDisplayname(displayname);
    mask.addPaths('displayName');
  }

  if (typeof description === 'string') {
    const textItem = new TextItem();
    textItem.setValue(description);
    textItem.setType(1);
    teamMeta.setDescription(textItem);

    mask.addPaths('description');
  }

  updateTeamRequest.setUpdatemask(mask);

  const avatarData = new Avatar();
  avatarData.setType(1);
  avatarUrl ? avatarData.setUri(avatarUrl) : avatarData.setUri('avatar');
  teamMeta.setAvatar(avatarData);

  updateTeamRequest.setMeta(teamMeta);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.teams.current.updated },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .updateTeam(token, updateTeamRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchTeamList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${displayname || shortname} team successfully updated.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateTeamError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteTeam(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete the team.',
      }),
    );
  }

  const teamId: string = action.payload;

  const deleteTeamRequest = new DeleteTeamRequest();
  deleteTeamRequest.setTeamid(teamId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.teams.current.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .deleteTeam(token, deleteTeamRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchTeamList());
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteTeamError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchList(
  action: ActionType,
  successAction: string,
  errorGetter: (state: RootState) => string,
  pageToken?: string,
) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch team list.',
      }),
    );
  }

  const fetchTeamsRequest = new ListTeamsRequest();
  const pageData = new PageRequest();

  const pageSize = action.payload || TEAM_LIST_PAGE_SIZE;

  pageData.setPagesize(pageSize);
  pageToken && pageData.setPagetoken(pageToken);
  fetchTeamsRequest.setPagerequest(pageData);
  const options = new ListTeamsOptions();
  options.setIncludeuserdetails(false);
  fetchTeamsRequest.setOptions(options);

  const { sequence } = yield request(
    { rootAction: action, type: successAction },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .fetchList(token, fetchTeamsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(errorGetter);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchListFirstPage(action: ActionType) {
  const currPageToken = yield select(getTeamListCurrPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.teams.fetchedList,
    getFetchTeamListError,
    currPageToken,
  );
}

function* fetchListNextPage(action: ActionType) {
  const nextPageToken = yield select(getTeamListNextPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.teams.fetchedNextPage,
    getFetchTeamListNextPageError,
    nextPageToken,
  );
}

function* fetchListPrevPage(action: ActionType) {
  const prevPageToken = yield select(getTeamListPrevPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.teams.fetchedNextPage,
    getFetchTeamListPrevPageError,
    prevPageToken,
  );
}

function* fetchTeam(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch team data.',
      }),
    );
  }

  const teamShortname: string = action.payload;

  if (!teamShortname) {
    return;
  }

  const getTeamRequest = new GetTeamByShortNameRequest();
  getTeamRequest.setShortname(teamShortname);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.teams.current.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .fetchTeam(token, getTeamRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchTeamError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchTeamById(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch team data.',
      }),
    );
  }

  const teamId: string = action.payload;

  if (!teamId) {
    return;
  }

  const getTeamRequest = new GetTeamByIdRequest();
  getTeamRequest.setTeamid(teamId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.teams.current.fetchedById },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .fetchTeamById(token, getTeamRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchTeamByIdError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* addUsersToTheTeam(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to add member to the team.',
      }),
    );
  }

  const { userIds, teamId }: { userIds: string[]; teamId: string } = action.payload;

  if (!userIds.length) {
    return;
  }

  const addTeamMemberRequest = new AddMembersRequest();
  addTeamMemberRequest.setTeamid(teamId);
  addTeamMemberRequest.setUseridsList(userIds);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.teams.current.addedUsers },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .addUsers(token, addTeamMemberRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `New members succesfully added to the team.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getAddUsesrToTheTeamError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* removeUserFromTheTeam(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to remove member from the team.',
      }),
    );
  }

  const { userId, teamId }: { userId: string; teamId: string } = action.payload;

  const removeUserRequest = new DeleteMemberRequest();
  removeUserRequest.setTeamid(teamId);
  removeUserRequest.setUserid(userId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.teams.current.removedUser },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ teamsService }) =>
          teamsService
            .removeUser(token, removeUserRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `User removed from the team.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getRemoveUserFromTheTeamError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.teams.create, createTeam)]);
  yield all([takeLatest(actionTypes.teams.current.update, updateTeam)]);
  yield all([takeLatest(actionTypes.teams.current.delete, deleteTeam)]);
  yield all([takeLatest(actionTypes.teams.fetchList, fetchListFirstPage)]);
  yield all([takeLatest(actionTypes.teams.fetchNextPage, fetchListNextPage)]);
  yield all([takeLatest(actionTypes.teams.fetchPrevPage, fetchListPrevPage)]);
  yield all([takeLatest(actionTypes.teams.current.fetch, fetchTeam)]);
  yield all([takeLatest(actionTypes.teams.current.fetchById, fetchTeamById)]);
  yield all([takeLatest(actionTypes.teams.current.addUsers, addUsersToTheTeam)]);
  yield all([takeLatest(actionTypes.teams.current.removeUser, removeUserFromTheTeam)]);
}
