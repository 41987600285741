import { FunctionComponent, ElementType } from 'react';
import { GridContext } from './GridContext';
import { BoundsView } from './Bounds.view';
import * as Styled from './Grid.styles';

type IGrid = FunctionComponent & {
  Box: ElementType;
  Bounds: ElementType;
};

export const GridView: IGrid = ({ children }) => (
  <GridContext.Provider value={{}}>{children}</GridContext.Provider>
);

GridView.Box = Styled.Box;
GridView.Bounds = BoundsView;
