import actionTypes from '../constants/actionTypes';
import { FlattenedTeamBatch, FlattenedTeam, User, TeamFormData, TeamMap } from '../../definitions';
import { isLoading } from './loading';
import { RootState } from '../reducers';
import { getErrorMessage } from './errors';
import { wasSuccessful } from './success';

export const getTeamList = (state: RootState): FlattenedTeamBatch[] => {
  return Object.values(state.teams.list);
};

export const getTeamMap = (state: RootState): TeamMap => {
  return state.teams.list;
};

export const getTeamById = (state: RootState, teamId: string): FlattenedTeamBatch => {
  return state.teams.list[teamId];
};

export const getTotalNumberOfTeams = (state: RootState): number => {
  return state.teams.totalNumber;
};

export const getTeamListNextPageToken = (state: RootState): string | undefined => {
  return state.teams.nextPageToken;
};

export const getTeamListPrevPageToken = (state: RootState): string | undefined => {
  return state.teams.prevPageToken;
};

export const getTeamListCurrPageToken = (state: RootState): string | undefined => {
  return state.teams.currPageToken;
};

export const getSelectedTeam = (state: RootState): FlattenedTeam => {
  return state.teams.selected;
};

export const getSavedNewTeam = (state: RootState): TeamFormData => {
  return state.teams.new;
};

export const getSelectedTeamId = (state: RootState): string | undefined => {
  return state.teams.selected.id;
};

export const getSelectedTeamShortname = (state: RootState): string | undefined => {
  return state.teams.selected.shortname;
};

export const getSelectedTeamDisplayName = (state: RootState): string | undefined => {
  return state.teams.selected.displayname;
};

export const getSelectedTeamUsers = (state: RootState): Set<string> => {
  const userIds: Set<string> = new Set();
  state.teams.selected.users.forEach((user: User) => userIds.add(user.id));
  return userIds;
};

export const getNumberOfUsersInTheTeam = (state: RootState): number => {
  return state.teams.selected.users.length;
};

export const isCreatingTeam = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.create);
};

export const createdTeamSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.teams.create);
};

export const isUpdatingTeam = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.current.update);
};

export const isDeletingTeam = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.current.delete);
};

export const updatedTeamSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.teams.current.update);
};

export const deletedTeamSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.teams.current.delete);
};

export const isFetchingTeamList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.fetchList);
};

export const isFetchingTeamListNextPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.fetchNextPage);
};

export const isFetchingTeamListPrevPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.fetchPrevPage);
};

export const isFetchingTeam = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.current.fetch);
};

export const isFetchingTeamById = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.current.fetchById);
};

export const isAddingUsersToTheTeam = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.current.addUsers);
};

export const isRemovingUserFromTheTeam = (state: RootState): boolean => {
  return isLoading(state, actionTypes.teams.current.removeUser);
};

export const getCreateTeamError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.create);
};

export const getUpdateTeamError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.current.update);
};

export const getDeleteTeamError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.current.delete);
};

export const getFetchTeamListError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.fetchList);
};

export const getFetchTeamListNextPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.fetchNextPage);
};

export const getFetchTeamListPrevPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.fetchPrevPage);
};

export const getFetchTeamError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.current.fetch);
};

export const getFetchTeamByIdError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.current.fetchById);
};

export const getAddUsesrToTheTeamError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.current.addUsers);
};

export const getRemoveUserFromTheTeamError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.teams.current.removeUser);
};

export const addedUsersToTheTeamSuccesfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.teams.current.addUsers);
};
