// package: rct.web
// file: grpcweb/comment.proto

var grpcweb_comment_pb = require("../grpcweb/comment_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CommentsService = (function () {
  function CommentsService() {}
  CommentsService.serviceName = "rct.web.CommentsService";
  return CommentsService;
}());

CommentsService.Add = {
  methodName: "Add",
  service: CommentsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_comment_pb.AddCommentRequest,
  responseType: grpcweb_comment_pb.AddCommentResponse
};

CommentsService.List = {
  methodName: "List",
  service: CommentsService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_comment_pb.ListCommentsRequest,
  responseType: grpcweb_comment_pb.ListCommentsResponse
};

exports.CommentsService = CommentsService;

function CommentsServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CommentsServiceClient.prototype.add = function add(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CommentsService.Add, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CommentsServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CommentsService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CommentsServiceClient = CommentsServiceClient;

