import { FunctionComponent, ReactElement } from 'react';
import { User, AvatarAppearance, FlattenedTeam } from '../../../definitions';
import UserListPopup from '../../UserListPopup';
import AvatarGroup from '../index';

type ViewAvatarGroupProps = {
  users: User[] | FlattenedTeam[];
  borderColor?: string;
  userActions?: ReactElement[];
  appearance?: AvatarAppearance;
};

export const ViewAvatarGroupView: FunctionComponent<ViewAvatarGroupProps> = ({
  users,
  borderColor,
  userActions,
  appearance = AvatarAppearance.Medium,
}) => {
  const Avatar: FunctionComponent<{ user: User | FlattenedTeam; appearance?: AvatarAppearance }> =
    ({ user, appearance }) => (
      <AvatarGroup.Avatar user={user} userActions={userActions} appearance={appearance} />
    );

  const getAvatars = (usersOrTeams: User[] | FlattenedTeam[]) => {
    if ('username' in usersOrTeams[0]) {
      const typedUsers = usersOrTeams as User[];
      return typedUsers.map((user: User) => (
        <Avatar user={user} key={user.id} appearance={appearance} />
      ));
    }

    const typedUsers = usersOrTeams as FlattenedTeam[];
    return typedUsers.map((team: FlattenedTeam) => (
      <Avatar user={team} key={team.id} appearance={appearance} />
    ));
  };

  if (users.length <= 3) {
    return <AvatarGroup borderColor={borderColor}>{getAvatars(users)}</AvatarGroup>;
  }

  const firstTwoUser = users.slice(0, 2);
  const otherUsers = users.slice(2, users.length);

  return (
    <AvatarGroup borderColor={borderColor}>
      {getAvatars(firstTwoUser)}
      <UserListPopup users={otherUsers}>
        <AvatarGroup.Action
          actionText={`+${otherUsers.length}`}
          borderColor={borderColor}
          actionTooltip={`See the rest ${otherUsers.length} users`}
          appearance={appearance}
        />
      </UserListPopup>
    </AvatarGroup>
  );
};
