import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedBotToken,
  isDeletingBotToken,
  deleteBotToken,
  getSelectedBotId,
  getSelectedBotTokenId,
  deletedBotTokenSuccessfully,
  isSelectingBotToken,
} from '../../store';
import { TokenView } from './Token.view';

type TokenContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const TokenContainer: FunctionComponent<TokenContainerProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const selectedToken = useSelector(getSelectedBotToken);
  const isDeletingToken = useSelector(isDeletingBotToken);
  const botId = useSelector(getSelectedBotId);
  const tokenId = useSelector(getSelectedBotTokenId);
  const isSelectingToken = useSelector(isSelectingBotToken);

  const succesfullyDeletedToken = useSelector(deletedBotTokenSuccessfully);

  const handleDeleteToken = useCallback(() => {
    if (tokenId && botId) {
      dispatch(deleteBotToken({ tokenId, botId }));
    }
  }, [dispatch, botId, tokenId]);

  useEffect(() => {
    if (succesfullyDeletedToken) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [succesfullyDeletedToken]);

  return (
    <TokenView
      isOpen={isOpen}
      onClose={onClose}
      token={selectedToken}
      isDeletingToken={isDeletingToken}
      onDeleteToken={handleDeleteToken}
      isSelectingToken={isSelectingToken}
    />
  );
};
