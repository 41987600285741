import { ElementType } from 'react';
import { Icon } from '../../components/Icon';

type Routes = {
  label: string;
  path: string;
  icon: ElementType;
  
};

export const navigationRoutes: Array<Routes> = [
  {
    label: 'Platform',
    path: 'platform',
    icon: Icon.Rocket,
  },
  {
    label: 'Integrations',
    path: 'integrations',
    icon: Icon.Plug,
  },
  {
    label: 'Learn more',
    path: 'crew',
    icon: Icon.Astronaut,
  },
  {
    label: 'Contacts',
    path: 'follow',
    icon: Icon.Subscribe,
  },
];
