import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps, generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { usePageTitle } from '../../hooks';
import {
  fetchSLO,
  getSelectedSLO,
  getSelectedSLODisplayName,
  getSelectedSLODescription,
  fetchComponentList,
  fetchSLOTypes,
  fetchEventSourceList,
  getSelectedSLOId,
  isLaunchingSLO,
  launchSLO,
  getSLOHistory,
  isFetchingSLOHistory,
  getSLOActivity,
  isFetchingSLOActivity,
  isFetchingSLOComments,
  isAddingSLOComment,
  getSLOComments,
  addSLOComment,
  getSelectedComponentDisplayName,
  getSelectedComponentShortname,
} from '../../store';
import { paths, SloFormData, PublishCommentFormData } from '../../definitions';
import { SloView as SloViewComponent } from './Slo.view';

type SloContainerProps = {
  isEditDrawerOpen?: boolean;
} & RouteChildrenProps;

export const SloContainer: FunctionComponent<SloContainerProps> = ({
  match,
  isEditDrawerOpen = false,
  ...rest
}) => {
  usePageTitle('SLO');

  const dispatch = useDispatch();
  const { sloShortName } = match?.params as { sloShortName: SloFormData['shortName'] };

  const isLaunching = useSelector(isLaunchingSLO);
  const isLoadingHistory = useSelector(isFetchingSLOHistory);
  const isLoadingActivity = useSelector(isFetchingSLOActivity);
  const isLoadingSloComments = useSelector(isFetchingSLOComments);
  const isPublishingSloComment = useSelector(isAddingSLOComment);

  const sloId = useSelector(getSelectedSLOId);
  const displayName = useSelector(getSelectedSLODisplayName);
  const description = useSelector(getSelectedSLODescription);
  const componentDisplayName = useSelector(getSelectedComponentDisplayName);
  const componentShortname = useSelector(getSelectedComponentShortname);

  const sloData = useSelector(getSelectedSLO);
  const sloHistory = useSelector(getSLOHistory);
  const sloActivityList = useSelector(getSLOActivity);
  const sloComments = useSelector(getSLOComments);

  useEffect(() => {
    if (isEditDrawerOpen) {
      setIsEditDrawerVisible(true);
    } else {
      setIsEditDrawerVisible(false);
    }
  }, [isEditDrawerOpen]);

  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);

  const handleCloseEditSLO = useCallback(() => {
    sloShortName && dispatch(push(generatePath(paths.slo, { sloShortName })));
  }, [dispatch, sloShortName]);

  const handleOpenEditSLO = useCallback(() => {
    sloShortName && dispatch(push(generatePath(paths.editSlo, { sloShortName })));
  }, [dispatch, sloShortName]);

  const handleSloLaunch = useCallback(() => {
    dispatch(launchSLO(sloId));
  }, [dispatch, sloId]);

  const handlePublishSloComment = useCallback(
    (commentFormData: PublishCommentFormData) => {
      dispatch(addSLOComment({ sloId, comment: commentFormData.comment }));
    },
    [dispatch, sloId],
  );

  useEffect(() => {
    dispatch(fetchSLO(sloShortName));
    if (isEditDrawerVisible) {
      dispatch(fetchComponentList({}));
      dispatch(fetchSLOTypes());
      dispatch(fetchEventSourceList());
    }
  }, [dispatch, sloShortName, isEditDrawerVisible]);

  return (
    <SloViewComponent
      slo={sloData}
      isEditDrawerVisible={isEditDrawerVisible}
      onCloseEditSlo={handleCloseEditSLO}
      onOpenEditSlo={handleOpenEditSLO}
      match={match}
      description={description}
      displayName={displayName}
      onSloLaunch={handleSloLaunch}
      onPublishComment={handlePublishSloComment}
      isLaunchingSLO={isLaunching}
      isLoadingHistory={isLoadingHistory}
      isLoadingActivity={isLoadingActivity}
      sloHistory={sloHistory}
      sloActivity={sloActivityList}
      sloComments={sloComments}
      isLoadingSloComments={isLoadingSloComments}
      isPublishingSloComment={isPublishingSloComment}
      componentDisplayName={componentDisplayName}
      componentShortname={componentShortname}
      {...rest}
    />
  );
};
