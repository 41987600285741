import { FunctionComponent, ElementType, ComponentType } from 'react';
import { Field } from 'react-final-form';
import { FieldRenderProps } from 'react-final-form';
import { Icon } from '../../Icon';
import { validation, parsers } from '../../../definitions';
import * as Styled from './InputArray.styles';

export type InputArrayProps = {
  tooltip?: string;
  fields: any;
  index: number;
  maxLength?: number;
  name: string;
  id: string;
  label: string;
  placeholder?: string;
  showValidation: boolean;
  component: ComponentType<FieldRenderProps<string>>;
  description?: string;
};

type IInputArray = FunctionComponent<InputArrayProps> & {
  PushArrayItemButton: ElementType;
  RemoveArrayItemButton: ElementType;
  ArrayItemRow: ElementType;
};

const PushArrayItemButton: FunctionComponent<{
  push: (name: string, value: any) => void;
  name: string;
}> = ({ children, push, name }) => {
  return (
    <Styled.PushArrayItemButton onClick={() => push(name, '')}>
      <Icon.Plus />
      {children}
    </Styled.PushArrayItemButton>
  );
};

export const InputArrayView: IInputArray = ({
  tooltip,
  maxLength,
  fields,
  index,
  name,
  id,
  label,
  placeholder,
  showValidation,
  component,
  description,
  ...rest
}) => {
  const removeItemFromQueryArray = (fields: any, index: number) => {
    fields.value.forEach((field: string, i: number) => {
      if (i <= index) return;
      fields.update(i - 1, field);
    });
    return fields.pop();
  };

  return (
    <Styled.ArrayItemRow>
      <Field
        name={name}
        component={component}
        id={id}
        placeholder={placeholder}
        label={label}
        validate={value => validation.required(value)}
        showValidation={showValidation}
        format={parsers.trim}
        formatOnBlur={true}
        tooltip={tooltip}
        maxLength={maxLength}
        description={description}
        {...rest}
      />
      {index > 0 && (
        <Styled.RemoveArrayItemButton
          onClick={() => removeItemFromQueryArray(fields, index)}
          title="Remove"
        >
          <Icon.Cross />
        </Styled.RemoveArrayItemButton>
      )}
    </Styled.ArrayItemRow>
  );
};

InputArrayView.PushArrayItemButton = PushArrayItemButton;
InputArrayView.RemoveArrayItemButton = Styled.RemoveArrayItemButton;
InputArrayView.ArrayItemRow = Styled.ArrayItemRow;
