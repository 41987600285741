import { FunctionComponent, useState, useMemo } from 'react';
import { Layout } from '../../components/Layout';
import List from '../../components/List';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';
import EditEventSourceModal from '../../modals/EditEventSource';
import CreateEventSourceModal from '../../modals/CreateEventSource';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { Appearance, EventSourcesInTheList, theme } from '../../definitions';
import { getEventSourceLabel } from '../../grpcMappings';
import * as Styled from './EventSources.styles';

type EventSourcesViewProps = {
  sourceList: EventSourcesInTheList[];
  isFetchingSources: boolean;
  onFetchEventSource: (sourceId: string) => void;
};

export const EventSourcesView: FunctionComponent<EventSourcesViewProps> = ({
  sourceList,
  isFetchingSources,
  onFetchEventSource,
}) => {
  const [isEditSourceModalOpen, setIsEditSourceModalOpen] = useState(false);
  const [isCreateSourceModalOpen, setIsCreateSourceModalOpen] = useState(false);

  const mappedToTypeSourceList = useMemo(() => {
    return sourceList.map(source => ({
      ...source,
      sourceType: getEventSourceLabel(source.sourceType),
    }));
  }, [sourceList]);

  const onFetchSelectedEventSource = (sourceId: string) => {
    onFetchEventSource(sourceId);
    setIsEditSourceModalOpen(true);
  };

  const EditSourceButton: FunctionComponent<{ id: string }> = ({ id }) => (
    <Styled.ButtonWrapper>
      <Button
        appearance={Appearance.Link}
        onClick={() => onFetchSelectedEventSource(id)}
        color={theme.palettes.secondaryHighlight}
      >
        Edit
      </Button>
    </Styled.ButtonWrapper>
  );

  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>Event Sources</Layout.HeaderTitle>
      <Layout.HeaderActions>
        <Button
          onClick={() => setIsCreateSourceModalOpen(true)}
          appearance={Appearance.PrimaryWithIconCollapsible}
          aria-label="Create event source"
          title="Create event source"
        >
          <Icon.Plus />
          <span>Create event source</span>
        </Button>
      </Layout.HeaderActions>
    </Layout.Header>
  );

  return (
    <>
      <AuthenticatedLayout header={Header}>
        <Layout.SubHeading>
          This is the list of all available data sources that can be used to fetch events.
        </Layout.SubHeading>
        <Layout.Section>
          <List
            items={mappedToTypeSourceList}
            columns={['displayName', 'description', 'sourceType', EditSourceButton]}
            columnTitles={['Display name', 'Description', 'Source Type', '']}
            hideOnSmall={['description', 'sourceType']}
            itemKey="id"
            isLoading={isFetchingSources}
            emptyText="You don't have any Event Sources yet. Start by creating one."
          />
        </Layout.Section>

        <EditEventSourceModal
          isOpen={isEditSourceModalOpen}
          onClose={() => setIsEditSourceModalOpen(false)}
        />
        <CreateEventSourceModal
          isOpen={isCreateSourceModalOpen}
          onClose={() => setIsCreateSourceModalOpen(false)}
        />
      </AuthenticatedLayout>
    </>
  );
};
