import { useMemo, useCallback } from 'react';
import { TagView } from '../grpc/grpcweb/tag_pb';
import { ComponentRefInTheList, GrouppedComponents } from '../definitions';

export const useGroupComponentsByTag = (
  componentList: ComponentRefInTheList[],
  selectedTag: string,
): GrouppedComponents => {
  const groupComponent = useCallback(
    (components: ComponentRefInTheList[]): GrouppedComponents => {
      return components.reduce(
        (
          groups: Record<string, Array<ComponentRefInTheList>>,
          component: ComponentRefInTheList,
        ) => {
          const theTag = component.tagList.find(
            (tag: TagView.AsObject) => tag.displayname === selectedTag,
          );
          const key = theTag ? theTag.value : `NO TAG FOUND`;
          const group = groups[key];
          return {
            ...groups,
            [key]: [...(group || []), component],
          };
        },
        {},
      );
    },
    [selectedTag],
  );

  return useMemo(() => {
    return groupComponent(componentList);
  }, [componentList, groupComponent]);
};
