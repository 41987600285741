import { FunctionComponent } from 'react';
import { Layout } from '../../components/Layout';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { User } from '../../definitions';
import UserList from '../../lists/UserList';

type UsersViewProps = {
  userList: User[];
  isFetching: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfUsers: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
};

export const UsersView: FunctionComponent<UsersViewProps> = ({
  userList,
  isFetching,
  numberOfUsers,
  onNextPage,
  onPrevPage,
  isPrevPageDisabled,
  isNextPageDisabled,
}) => {
  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>Users</Layout.HeaderTitle>
    </Layout.Header>
  );

  return (
    <AuthenticatedLayout header={Header}>
      <Layout.SubHeading>
        The table below contains a list of all users that belong to the organization.
      </Layout.SubHeading>
      <UserList
        userList={userList}
        isFetching={isFetching}
        numberOfUsers={numberOfUsers}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
      />
    </AuthenticatedLayout>
  );
};
