import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getComponentDependencies,
  getComponentDependents,
  fetchComponentDependencies,
  isFetchingComponentDependencies,
  isLinkingComponentDependencies,
  isLinkingComponentDependents,
  getSelectedComponentDependencyIds,
  getSelectedComponentDependentIds,
  linkComponentDependencies,
  linkComponentDependents,
  unlinkComponentDependency,
  unlinkComponentDependant,
  isUnlinkingComponentDependency,
  isUnlinkingComponentDependant,
  getTeamMap,
} from '../../../store';
import { ComponentRefInTheList, SloComponent } from '../../../definitions';
import { DependenciesView } from './Dependencies.view';

type DependenciesContainerProps = {
  onViewComponent: (component: ComponentRefInTheList) => void;
  component: SloComponent;
  componentId: string;
};

export const DependenciesContainer: FunctionComponent<DependenciesContainerProps> = ({
  onViewComponent,
  component,
  componentId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoadingDependencies = useSelector(isFetchingComponentDependencies);

  const dependencies = useSelector(getComponentDependencies);
  const isLinkingDependencies = useSelector(isLinkingComponentDependencies);
  const isUnlinkingDependency = useSelector(isUnlinkingComponentDependency);
  const componentDependencyIds = useSelector(getSelectedComponentDependencyIds);

  const dependents = useSelector(getComponentDependents);
  const isLinkingDependents = useSelector(isLinkingComponentDependents);
  const isUnlinkingDependant = useSelector(isUnlinkingComponentDependant);
  const componentDependentIds = useSelector(getSelectedComponentDependentIds);

  const teamMap = useSelector(getTeamMap);

  useEffect(() => {
    if (componentId) {
      dispatch(fetchComponentDependencies(componentId));
    }
  }, [dispatch, componentId]);

  const handleLinkDependencies = useCallback(
    (dependencyIds: string[]) => {
      dispatch(linkComponentDependencies({ dependencyIds: dependencyIds, componentId }));
    },
    [dispatch, componentId],
  );

  const handleLinkDependents = useCallback(
    (dependentIds: string[]) => {
      dispatch(linkComponentDependents({ dependentIds: dependentIds, componentId }));
    },
    [dispatch, componentId],
  );

  const handleUnlinkDependency = useCallback(
    (dependencyId: string) => {
      dispatch(unlinkComponentDependency({ dependencyId, componentId }));
    },
    [dispatch, componentId],
  );

  const handleUnlinkDependant = useCallback(
    (dependantId: string) => {
      dispatch(unlinkComponentDependant({ dependantId, componentId }));
    },
    [dispatch, componentId],
  );

  return (
    <DependenciesView
      onViewComponent={onViewComponent}
      isLoadingDependencies={!component.id || isLoadingDependencies}
      dependencies={dependencies}
      dependents={dependents}
      component={component}
      onLinkDependencies={handleLinkDependencies}
      onLinkDependents={handleLinkDependents}
      isLinkingDependencies={isLinkingDependencies}
      isLinkingDependents={isLinkingDependents}
      onUnlinkDependency={handleUnlinkDependency}
      onUnlinkDependant={handleUnlinkDependant}
      isUnlinkingDependency={isUnlinkingDependency}
      isUnlinkingDependant={isUnlinkingDependant}
      componentDependencyIds={componentDependencyIds}
      componentDependentIds={componentDependentIds}
      teamMap={teamMap}
      {...rest}
    />
  );
};
