import { FunctionComponent, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Appearance, parsers, PublishCommentFormData } from '../../definitions';
import { FormLayout, Textarea } from '../../components/Form';
import { Icon } from '../../components/Icon';

type CommentFormProps = {
  isLoading: boolean;
  onSubmit: (formData: PublishCommentFormData) => void;
  isDisabled?: boolean;
};

export const CommentFormView: FunctionComponent<CommentFormProps> = ({
  onSubmit,
  isLoading,
  isDisabled = false,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
  return (
    <Form
      autoComplete="off"
      onSubmit={(values: PublishCommentFormData) => {
        onSubmit(values);
      }}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          setClickedSubmit(true);
          handleSubmit(event);
          form.reset();
        };
        return (
          <>
            <FormLayout.FieldSet>
              <Field
                id="comment"
                name="comment"
                placeholder="New comment"
                component={Textarea}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                maxLength="1000"
              />
            </FormLayout.FieldSet>
            <FormLayout.ActionWrapper>
              <FormLayout.Submit
                onClick={onSubmitClick}
                isPending={isLoading}
                isDisabled={isDisabled || !values.comment}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Plus />
                <span>Add comment</span>
              </FormLayout.Submit>
            </FormLayout.ActionWrapper>
          </>
        );
      }}
    />
  );
};
