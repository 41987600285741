import { FunctionComponent } from 'react';
import Button from '../../Button';
import { Appearance } from '../../../definitions';
import { Icon } from '../../Icon';
import * as Styled from './LoadMore.styles';

export type LoadMoreProps = {
  onLoadMoreClick: () => void;
  isLoadMoreDisabled?: boolean;
  loadMoreText?: string;
  isLoading?: boolean;
};

export const LoadMoreView: FunctionComponent<LoadMoreProps> = ({
  onLoadMoreClick,
  isLoadMoreDisabled,
  loadMoreText = 'Load more',
  isLoading,
}) => {
  return (
    <Styled.Container>
      <Button
        appearance={Appearance.PrimaryWithIcon}
        isDisabled={isLoadMoreDisabled}
        onClick={onLoadMoreClick}
        isPending={isLoading}
      >
        <span>{loadMoreText}</span>
        <Icon.Plus />
      </Button>
    </Styled.Container>
  );
};
