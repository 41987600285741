import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import { ComponentRefInTheList, Appearance } from '../../definitions';
import SelectComponentTable from '../../tables/SelectComponentTable';
import { Icon } from '../../components/Icon';

type SelectComponentProps = {
  onClose: () => void;
  isFetching: boolean;
  isLoading: boolean;
  onSave: () => void;
  componentList: ComponentRefInTheList[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfComponents: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onSelectComponent: (component: ComponentRefInTheList) => void;
  onClearSelectedComponent: () => void;
  isOpen: boolean;
  selectedComponentName?: string;
  currentComponentName?: string;
  currentComponentId?: string;
  selectedComponentId?: string;
};

export const SelectComponentView: FunctionComponent<SelectComponentProps> = ({
  onClose,
  onSave,
  onNextPage,
  onPrevPage,
  onClearSelectedComponent,
  onSelectComponent,
  isFetching,
  isLoading,
  componentList,
  numberOfComponents,
  isPrevPageDisabled,
  isNextPageDisabled,
  isOpen,
  selectedComponentName,
  currentComponentName,
  currentComponentId,
  selectedComponentId,
}) => {
  return (
    <Modal
      onClose={onClose}
      title="Select component"
      maxWidth="50rem"
      shouldUseSmartPositioning={false}
      isOpen={isOpen}
      shouldCoverPopup={true}
    >
      <SelectComponentTable
        isFetching={isFetching}
        disabledComponentIds={new Set()}
        componentList={componentList}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        numberOfComponents={numberOfComponents}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
        onSelectComponent={onSelectComponent}
        onClearSelectedComponent={onClearSelectedComponent}
        selectedComponentName={selectedComponentName}
        currentComponentId={currentComponentId}
        selectedComponentId={selectedComponentId}
        currentComponentName={currentComponentName}
      />
      <Modal.Footer>
        <Modal.Action appearance={Appearance.PrimaryWithIcon} onClick={onClose}>
          <Icon.Cross />
          <span>Cancel</span>
        </Modal.Action>
        <Modal.Action
          appearance={Appearance.PrimaryWithIcon}
          isHighlighted={true}
          isDisabled={!selectedComponentId}
          onClick={onSave}
          isPending={isLoading}
        >
          <Icon.Create />
          <span>Select</span>
        </Modal.Action>
      </Modal.Footer>
    </Modal>
  );
};
