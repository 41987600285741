export const paths = {
  authenticate: '/authenticate',
  bots: '/bots',
  bot: '/bots/:botShortname',
  landing: '/',
  dashboard: '/dashboard',
  relations: '/relations',
  relationsSelectedComponent: '/relations/:selectedComponentShortname',
  landingSpace: '/space',
  components: '/components',
  editSlo: '/slo/:sloShortName/edit',
  eventSources: '/event-sources',
  component: '/components/:componentShortname',
  updateComponent: '/components/:componentShortname/edit',
  createComponent: '/components/create',
  createComponentFromDashboard: '/dashboard/create-component',
  sloList: '/slo',
  slo: '/slo/:sloShortName',
  teams: '/teams',
  team: '/teams/:teamShortname',
  users: '/users',
  social: {
    youTube: 'https://www.youtube.com/',
    twitter: 'https://twitter.com/?lang=en',
    facebook: 'https://www.facebook.com/',
    instagram: 'https://www.instagram.com/',
    linkedIn: 'https://www.linkedin.com/',
  },
  tags: '/tags',
  editTag: '/tags/:tagKey/edit',
  createTag: '/tags/create',
  integrations: {
    datadog: 'https://www.datadoghq.com/',
    signalFx: 'https://docs.signalfx.com/en/latest/',
    newRelic: 'https://newrelic.com/',
    prometheus: 'https://prometheus.io/',
    graphite: 'https://graphiteapp.org/',
    elasticSearch: 'https://www.elastic.co/',
    amazonCloudWatch: 'https://aws.amazon.com/cloudwatch/',
    opsgenie: 'https://www.atlassian.com/software/opsgenie',
    slack: 'https://slack.com/intl/en-au/',
    pagerDuty: 'https://www.pagerduty.com/',
  },
  drawer: {
    createSlo: '#create-slo',
  },
};
