import {
  EventSourceTypeMap,
  EventSourceType as EventSourceTypeGrpc,
} from '../grpc/grpcweb/eventsource_pb';
import { EventSourceType } from '../definitions';

export function getEventSourceLabel(
  sourceType?: EventSourceTypeMap[keyof EventSourceTypeMap],
): EventSourceType | undefined {
  switch (sourceType) {
    case EventSourceTypeGrpc.EVENT_SOURCE_CLOUDWATCH:
      return EventSourceType.CloudWatch;
    case EventSourceTypeGrpc.EVENT_SOURCE_PROMETHEUS:
      return EventSourceType.Prometheus;
    default:
      return EventSourceType.Undefined;
  }
}
