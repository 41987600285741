import styled, { css, DefaultTheme } from 'styled-components';
import { math } from 'polished';
import { breakpoints } from '../../definitions';
import { Link } from '../Link';

export type BoxProps = {
  [breakpoint: string]: DefaultTheme & {
    padding?: string;
    hidden?: boolean;
    span: string;
    filled: boolean;
    backgroundColor: string;
    minWidth?: string | number;
  };
};

const getBasisValue = (span: string): string | number => {
  switch (span) {
    case 'full':
      return '100%';
    case 'half':
      return '50%';
    case 'quarter':
      return '25%';
    case 'third':
      return '33.3333%';
    case 'twoThirds':
      return '66.6666%';
    case 'threeQuarters':
      return '75%';
    case 'fit':
      return 0;
    default:
      return span;
  }
};

const getBoxStyles = (props: BoxProps, breakpoint: keyof typeof breakpoints) => {
  const basis = getBasisValue(props?.[breakpoint]?.span);
  const width = `calc(${basis} - ${math(`${props.gutter}rem * 2`)})`;

  return css`
    ${basis !== undefined &&
    `flex-basis: ${
      basis !== 0 ? `${width}; max-width: ${width}` : `${basis}; max-width: inherit`
    };`}
    ${props?.[breakpoint]?.padding && `padding: ${props?.[breakpoint]?.padding};`}
    ${props?.[breakpoint]?.hidden && 'display: none;'}
    ${props.filled &&
    `padding: 1rem; background-color: ${
      props?.[breakpoint]?.backgroundColor || props.theme.palettes.content
    };`}
  `;
};

export const Box = styled(({ to, children, className }) =>
  to ? (
    <Link to={to} className={className}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  ),
)`
  flex: 1;
  display: block;
  margin: ${props => props.gutter}rem;

  ${props => getBoxStyles(props, 'default')};

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
      overflow: hidden;
      min-height: 0;
    `};

  ${props =>
    Object.keys(props.theme.media)
      .reverse()
      .map(
        breakpoint =>
          props.theme.media[breakpoint]`
      ${getBoxStyles(props, breakpoint)}
   `,
      )}
`;
