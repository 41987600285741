import { FunctionComponent } from 'react';
import { ComponentRefInTheList } from '../../../definitions';
import { DependencyCard } from '../SharedComponents';
import DependencyBlock from '../DependencyBlock';
import * as Styled from '../DependencyBlock/DependencyBlock.styles';

type UngrouppedBlockProps = {
  components: ComponentRefInTheList[];
  onComponentClick: (component: ComponentRefInTheList) => void;
  noSelectedComponentTitle: string;
  noDependenciesTitle: string;
  isDependencySelected: boolean;
  isDependantSelected: boolean;
  selectedComponent?: ComponentRefInTheList;
  selectedComponentId: string;
  onSetSelectComponentModalOpen: (isOpen: boolean) => void;
  onViewComponent: (componentShortname: string) => void;
  isFetching: boolean;
};

export const UngrouppedBlockView: FunctionComponent<UngrouppedBlockProps> = ({
  components,
  noSelectedComponentTitle,
  noDependenciesTitle,
  onComponentClick,
  isDependencySelected,
  isDependantSelected,
  selectedComponent,
  selectedComponentId,
  onSetSelectComponentModalOpen,
  onViewComponent,
  isFetching,
}) => {
  return (
    <DependencyBlock
      components={components}
      onViewComponent={onViewComponent}
      isDependencySelected={isDependencySelected}
      isDependantSelected={isDependantSelected}
      selectedComponent={selectedComponent}
      noDependenciesTitle={noDependenciesTitle}
      noSelectedComponentTitle={noSelectedComponentTitle}
      isFetching={isFetching}
      hasComponents={Boolean(components.length)}
      isEmpty={!components.length}
    >
      <Styled.ComponentContainer
        isDependencySelected={components.length && isDependencySelected}
        isDependantSelected={components.length && isDependantSelected}
      >
        {components.map((component: ComponentRefInTheList) => (
          <DependencyCard
            key={component.id}
            component={component}
            shortname={component.shortname}
            onNameClick={() => onComponentClick(component)}
            onViewComponent={onViewComponent}
            onSetSelectComponentModalOpen={onSetSelectComponentModalOpen}
            isDependencySelected={isDependencySelected}
            isDependantSelected={isDependantSelected}
            isSelected={selectedComponentId === component.id}
          />
        ))}
      </Styled.ComponentContainer>
    </DependencyBlock>
  );
};
