import styled from 'styled-components';

export const InfiniteRotationDiv = styled.div`
  width: auto;
  height: 100%;
  animation: ${({ theme }) => theme.effects.rotate} 1s linear infinite;
  &&& {
    > * {
      width: auto;
      height: 100%;
    }
  }

  svg {
    fill: ${({ theme }) => theme.palettes.placeholder};
  }
`;

export const LoaderContainer = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
