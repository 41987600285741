import { ApiToken, Bot, CreateTokenResponse } from '../../grpc/grpcweb/bot_pb';
import {
  ActionType,
  SequenceType,
  BotInTheList,
  BotFormData,
  Token,
  TokenInTheList,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';

export type BotsState = {
  list: BotInTheList[];
  new: BotFormData;
  selected: BotInTheList;
};

const initialState = {
  list: [],
  new: {
    shortname: '',
    description: '',
  },
  selected: {
    id: '',
    shortname: '',
    description: '',
    tokens: [],
    createdBy: '',
    createdAt: '',
    createdToken: {} as Token,
    selectedToken: {} as TokenInTheList,
  },
};

const mapBotToState = (payload: Bot): BotInTheList => {
  const bot = payload.toObject();
  return {
    createdToken: {} as Token,
    selectedToken: {} as TokenInTheList,
    shortname: bot.meta?.shortname,
    id: bot.id,
    description: bot.meta?.description?.value,
    tokens: bot.tokensList
      ? bot.tokensList?.map((token: ApiToken.AsObject) => ({
          id: token.id,
          createdBy: token.createmeta?.createdby?.displayname,
          createdAt: token.createmeta?.createdat
            ? new Date(token.createmeta?.createdat).toLocaleDateString()
            : '',
          updatedBy: token.updatemeta?.updatedby?.displayname,
          updatedAt: token.updatemeta?.updatedat
            ? new Date(token.updatemeta?.updatedat).toLocaleDateString()
            : '',
        }))
      : [],
    createdBy: bot.createmeta?.createdby?.displayname,
    createdAt: bot.createmeta?.createdat
      ? new Date(bot.createmeta?.createdat).toLocaleDateString()
      : '',
    updatedBy: bot.updatemeta?.updatedby?.displayname,
    updatedAt: bot.updatemeta?.updatedat
      ? new Date(bot.updatemeta?.updatedat).toLocaleDateString()
      : '',
  };
};

const bots = (
  state: BotsState = initialState,
  { type, payload, sequence }: ActionType,
): BotsState => {
  switch (type) {
    case actionTypes.bots.fetchedList: {
      if (sequence === SequenceType.Success) {
        const rawList = payload;
        const list = rawList?.map((bot: Bot) => mapBotToState(bot));
        return {
          ...state,
          list,
        };
      }

      return state;
    }

    case actionTypes.bots.current.createToken: {
      return {
        ...state,
        selected: {
          ...initialState.selected,
          id: payload,
          createdToken: {} as Token,
        },
      };
    }

    case actionTypes.bots.current.selectToken: {
      const payloadWithType: { tokenId: string; botId: string } = payload;
      return {
        ...state,
        selected: {
          ...initialState.selected,
          id: payloadWithType.botId,
          selectedToken: {} as TokenInTheList,
        },
      };
    }

    case actionTypes.bots.current.selectedToken: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: { tokenId: string; bot: Bot } = payload;
        const mappedBot = mapBotToState(payloadWithType.bot);
        const token = mappedBot.tokens.find(
          (token: TokenInTheList) => token.id === payloadWithType.tokenId,
        );

        if (token) {
          return {
            ...state,
            selected: {
              ...state.selected,
              ...mappedBot,
              selectedToken: {
                ...token,
              },
            },
          };
        }
        return state;
      }

      return state;
    }

    case actionTypes.bots.current.createdToken: {
      if (sequence === SequenceType.Success) {
        const tokenResponse: CreateTokenResponse = payload;
        const id = tokenResponse.getToken()?.getId();
        const token = tokenResponse.getFulltoken();

        return {
          ...state,
          selected: {
            ...state.selected,
            createdToken: {
              id,
              token,
            },
          },
        };
      }

      return state;
    }

    case actionTypes.bots.current.fetched: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: Bot = payload;
        const selectedMappedToState = mapBotToState(payloadWithType);
        return {
          ...state,
          selected: {
            ...state.selected,
            ...selectedMappedToState,
            selectedToken: {
              ...state.selected.selectedToken,
            },
            createdToken: {
              ...state.selected.createdToken,
            },
          },
        };
      }
      return state;
    }

    case actionTypes.bots.updateNew: {
      return {
        ...state,
        new: payload,
      };
    }

    case actionTypes.bots.resetNew: {
      return {
        ...state,
        new: {
          ...initialState.new,
        },
      };
    }

    case actionTypes.bots.created: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: Bot = payload;
        const selectedMappedToState = mapBotToState(payloadWithType);

        return {
          ...state,
          new: {
            ...initialState.new,
          },
          selected: selectedMappedToState,
        };
      }

      return state;
    }

    case actionTypes.bots.current.updated: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: Bot = payload;
        const selectedMappedToState = mapBotToState(payloadWithType);

        return {
          ...state,
          selected: {
            ...state.selected,
            ...selectedMappedToState,
            selectedToken: {
              ...state.selected.selectedToken,
            },
            createdToken: {
              ...state.selected.createdToken,
            },
          },
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default bots;
