import firebase from 'firebase/compat';
import { connectAuthEmulator, getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { AuthErrorType } from '../definitions';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

// const AUTHENTICATE_URL = '/authenticate';

// const DEFAULT_REDIRECT_URI = typeof window !== 'undefined' ? window.location.origin + AUTHENTICATE_URL : '';

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
};

export default class AuthenticationService extends EventEmitter {
  private storage: StorageService;

  // private readonly autoRefresh: boolean;

  // private readonly clientId: string;

  private auth: any;

  // private whoAmI: any;

  private firebaseConfig: FirebaseConfig;

  // private errorReportingService: any;
  private readonly host: string;

  private readonly authHost?: string;

  constructor(firebaseConfig: FirebaseConfig, host: string, authHost?: string) {
    super();

    this.storage = new StorageService();
    // this.autoRefresh = true; //Boolean(autoRefresh);
    this.firebaseConfig = firebaseConfig;
    this.host = host;
    this.authHost = authHost;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    // this.errorReportingService = errorReportingService;

    firebase.initializeApp(this.firebaseConfig);
    await this.storage.initialise();
    this.auth = getAuth();

    if (this.authHost) {
      // if authHost is set then it is local env
      connectAuthEmulator(this.auth, this.authHost); // this should be called only in local env when Auth Emulator is used
    }
  }

  getAuth = async () => {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user: any) => {
        const timer = setTimeout(() => {
          reject(new Error(AuthErrorType.LOGIN_REQUIRED));
        }, 1000);

        if (user) {
          clearTimeout(timer);
          resolve(user);
        }
      });
    });
  };

  getToken = async () => this.getAuth().then((user: any) => user.getIdToken(true));

  logout = async () => {
    return new Promise<any>((resolve, reject) => {
      signOut(this.auth)
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  handleAuthentication = async (loginData: {
    email: string;
    password: string;
    tenantId: string;
  }) => {
    return new Promise<any>((resolve, reject) => {
      // is prod
      if (!this.authHost) {
        this.auth.tenantId = loginData.tenantId;
      }
      // https://firebase.google.com/docs/auth/web/start#sign_in_existing_users
      signInWithEmailAndPassword(this.auth, loginData.email, loginData.password)
        .then((authResult: any) => {
          resolve(authResult);
        })
        .catch(err => {
          reject(err);
        });

      // https://firebase.google.com/docs/auth/web/start#set_an_authentication_state_observer_and_get_user_data
      //onAuthStateChanged(this.auth, user => {
      //  if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // const uid = user.uid;
      //console.log('user just logged in. User email = ' + user.email);
      //user.getIdToken().then(token => {
      //  console.log('id token = ' + token);
      //});
      //  } else {
      //console.log('user just logged out');
      //  }
      //});
    });
  };
}
