import { CombinedState, combineReducers, Reducer } from 'redux';
import { connectRouter, RouterRootState } from 'connected-react-router';
import { History } from 'history';
import alerts, { AlertsState } from './alerts';
import auth, { AuthState } from './auth';
import bots, { BotsState } from './bots';
import cache, { CacheState } from './cache';
import components, { ComponentsState } from './components';
import errors, { ErrorsState } from './errors';
import eventSources, { EventSourcesState } from './eventSources';
import flow, { FlowState } from './flow';
import loading, { LoadingState } from './loading';
import location, { LocationState } from './location';
import notifications, { NotificationsState } from './notifications';
import success, { SuccessState } from './success';
import slos, { SLOsState } from './slos';
import tags, { TagState } from './tags';
import teams, { TeamsState } from './teams';
import users, { UsersState } from './users';
import xEvents, { XEventState } from './xEvents';

export type RootState = RouterRootState & {
  alerts: AlertsState;
  auth: AuthState;
  bots: BotsState;
  cache: CacheState;
  errors: ErrorsState;
  eventSources: EventSourcesState;
  components: ComponentsState;
  flow: FlowState;
  loading: LoadingState;
  location: LocationState;
  notifications: NotificationsState;
  slos: SLOsState;
  success: SuccessState;
  tags: TagState;
  teams: TeamsState;
  users: UsersState;
  xEvents: XEventState;
};

const reducers = (history: History): Reducer<CombinedState<RootState>> =>
  combineReducers<RootState>({
    router: connectRouter(history),
    alerts,
    auth,
    bots,
    cache,
    components,
    errors,
    eventSources,
    flow,
    loading,
    location,
    notifications,
    slos,
    success,
    tags,
    teams,
    users,
    xEvents,
  });

export default reducers;
