import { FunctionComponent, ElementType } from 'react';
import { Icon } from '../Icon';
import * as Styled from './CharacterBox.styles';
import { Loader } from '../Loader';

export type CharacterBoxProps = {
  label: string;
  icon: ElementType;
  url?: string;
  description: string;
  isButton?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
};

export const CharacterBoxView: FunctionComponent<CharacterBoxProps> = ({ label, icon, url, description, isButton, onClick, isLoading }) => {
  const CharIcon = icon;
  return (
    <Styled.Container>
      <Styled.IconWrapper>
        <CharIcon />
      </Styled.IconWrapper>
      <Styled.TextContainer>
        {isButton ? (
          <Styled.LinkIcon onClick={onClick} isPending={isLoading}>
            <h3>{label}</h3>
            {isLoading ? <Styled.LoaderWrapper>
              <Loader />
            </Styled.LoaderWrapper> : <Icon.Login />}
          </Styled.LinkIcon>
        ) : (
            <Styled.LinkIcon to={url}>
              <h3>{label}</h3>
            <Icon.ExternalLink />
          </Styled.LinkIcon>
        )}
      </Styled.TextContainer>
      <p>{description}</p>
    </Styled.Container>
  );
};
