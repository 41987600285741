import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageTitle } from '../../hooks';
import {
  isUpdatingBot,
  updateExistingBot,
  getSelectedBotId,
  getSelectedBot,
  deleteBot,
  isDeletingBot,
  isFetchingBot,
  getSelectedBotShortname,
} from '../../store';
import { BotFormData } from '../../definitions';
import { UpdateBotView } from './UpdateBot.view';
import { RouteChildrenProps } from 'react-router';

type UpdateBotContainerProps = {
  onClose: () => void;
  isOpen: boolean;
} & RouteChildrenProps;

export const UpdateBotContainer: FunctionComponent<UpdateBotContainerProps> = ({
  isOpen,
  onClose,
}) => {
  usePageTitle('Update bot');
  const dispatch = useDispatch();

  const isUpdating = useSelector(isUpdatingBot);
  const isDeleting = useSelector(isDeletingBot);
  const isFetching = useSelector(isFetchingBot);

  const botData = useSelector(getSelectedBot);
  const shortName = useSelector(getSelectedBotShortname);
  const botId = useSelector(getSelectedBotId);

  const handleUpdateBot = useCallback(
    (formData: BotFormData) => {
      botId && dispatch(updateExistingBot({ formData, botId }));
    },
    [dispatch, botId],
  );

  const handleDeleteBot = useCallback(() => {
    botId && shortName && dispatch(deleteBot({ botId, shortName }));
  }, [dispatch, botId, shortName]);

  return (
    <UpdateBotView
      isOpen={isOpen}
      onClose={onClose}
      isUpdating={isUpdating}
      isDeleting={isDeleting}
      isFetching={isFetching}
      onUpdate={handleUpdateBot}
      onDelete={handleDeleteBot}
      initialValues={botData}
    />
  );
};
