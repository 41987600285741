import { FunctionComponent, MouseEvent } from 'react';
import { Icon } from '../Icon';
import Button from '../Button';
import { Appearance } from '../../definitions';

type CloseProps = {
  onClick?: (e: MouseEvent) => void;
};

export const CloseView: FunctionComponent<CloseProps> = ({ onClick, ...rest }) => (
  <Button aria-label="Close" onClick={onClick} appearance={Appearance.Icon} {...rest}>
    <Icon.Cross />
  </Button>
);
