import styled from 'styled-components';

export const Wrapper = styled.span`
  display: inline-flex;

  svg {
    width: 1.25rem;
    height: auto;
    margin-right: 0.5rem;
  }
`;
