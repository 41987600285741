import { Location } from 'history';
import { RootState } from '../reducers';

export const getPreviousLocation = (state: RootState): Location => {
  return state.location.previous as Location;
};

export const getPreviousPath = (state: RootState): string => {
  if (!state.location.previous) {
    return '';
  }

  const { pathname = '', hash = '' } = state.location.previous;

  if (pathname) {
    return `${pathname}${hash}`;
  }

  return '';
};
