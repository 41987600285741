import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { TagValue } from '../../grpc/grpcweb/tag_pb';
import { usePageTitle } from '../../hooks';
import {
  getSelectedTag,
  deleteTag,
  isFetchingTagList,
  fetchTagByKey,
  isUpdatingTag,
  isDeletingTag,
  updateTag,
  addValueToTag,
  updatedTagSuccesfully,
  deleteTagValue,
  updateTagValue,
  isAddingNewValueToTag,
  isDeletingTagValue,
  isUpdatingTagValue,
  updatedTagValueSuccesfully,
} from '../../store';
import { Tag, TagFormData } from '../../definitions';
import { EditTagView } from './EditTag.view';

type CreateTagContainerProps = {
  visible: boolean;
  onHide: () => void;
} & RouteChildrenProps;

export const EditTagContainer: FunctionComponent<CreateTagContainerProps> = ({
  visible,
  onHide,
  match,
}) => {
  const { tagKey } = match?.params as {
    tagKey: Tag['tagKey'];
  };
  usePageTitle('Edit tag');

  const [newValueErrorMessage, setNewValueErrorMessage] = useState<string | undefined>(undefined);
  const [existingValueErrorMessage, setExistingValueErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(true);

  const dispatch = useDispatch();

  const selectedTag = useSelector(getSelectedTag);
  const isFetching = useSelector(isFetchingTagList);
  const isUpdating = useSelector(isUpdatingTag);
  const isDeleting = useSelector(isDeletingTag);
  const isAddingNewValue = useSelector(isAddingNewValueToTag);
  const isDeletingValue = useSelector(isDeletingTagValue);
  const isUpdatingValue = useSelector(isUpdatingTagValue);

  const updatedSuccesfully = useSelector(updatedTagSuccesfully);
  const updatedValueSuccesfully = useSelector(updatedTagValueSuccesfully);

  const handleClearAllErrors = () => {
    setNewValueErrorMessage(undefined);
    setExistingValueErrorMessage(undefined);
  };

  useEffect(() => {
    if (tagKey && selectedTag.tagKey !== tagKey) {
      dispatch(fetchTagByKey(tagKey));
    }
  }, [dispatch, tagKey, selectedTag.tagKey]);

  useEffect(() => {
    if (updatedSuccesfully) {
      handleClearAllErrors();
      setClickedSubmit(false);
    }
  }, [updatedSuccesfully]);

  const initialValues: TagFormData = useMemo(() => {
    return {
      tagKey: selectedTag.tagKey || '',
      values: selectedTag.values || [],
      description: selectedTag.description,
    };
  }, [selectedTag]);

  const handleUpdateTag = useCallback(
    (formData: TagFormData) => {
      dispatch(updateTag({ formData, tagId: selectedTag.keyId }));
    },
    [dispatch, selectedTag.keyId],
  );

  const handleDeleteTag = useCallback(
    (tagKey: string) => {
      dispatch(deleteTag({ tagKey, tagId: selectedTag.keyId }));
    },
    [dispatch, selectedTag.keyId],
  );

  const handleReset = useCallback(() => {
    setClickedSubmit(false);
  }, []);

  const handleAddValueToTag = useCallback(
    (value: string) => {
      handleClearAllErrors();
      const hasDuplication = Boolean(
        initialValues.values.filter(val => val.value === value).length,
      );

      if (hasDuplication) {
        setNewValueErrorMessage(`Value must be unique. Tag value ${value} already exists.`);
        return true;
      } else if (!value) {
        setNewValueErrorMessage(`Value can't be an empty string.`);
        return true;
      } else {
        dispatch(addValueToTag({ value, tagId: selectedTag.keyId }));
      }
    },
    [dispatch, selectedTag.keyId, initialValues.values],
  );

  const handleRemoveValue = useCallback(
    (value: TagValue.AsObject) => {
      handleClearAllErrors();
      dispatch(deleteTagValue({ value, tagId: selectedTag.keyId }));
    },
    [dispatch, selectedTag.keyId],
  );

  const handleUpdateValue = useCallback(
    (value: TagValue.AsObject, prevValue: TagValue.AsObject) => {
      handleClearAllErrors();

      const hasDuplication = Boolean(
        initialValues.values.filter(val => val.value === value.value).length,
      );
      if (prevValue.value === value.value) {
        return false;
      } else if (hasDuplication) {
        setExistingValueErrorMessage(
          `Value must be unique. Tag value ${value.value} already exists.`,
        );
        return true;
      } else if (!value.value) {
        setExistingValueErrorMessage(
          `Value can't be an empty string. Please use "Remove" button if you want to delete it instead.`,
        );
        return true;
      } else {
        dispatch(updateTagValue({ value, tagId: selectedTag.keyId }));
      }
    },
    [dispatch, selectedTag.keyId, initialValues.values],
  );

  return (
    <EditTagView
      isUpdating={isUpdating}
      isFetching={isFetching}
      isDeleting={isDeleting}
      isAddingNewValue={isAddingNewValue}
      isDeletingValue={isDeletingValue}
      isUpdatingValue={isUpdatingValue}
      initialValues={initialValues}
      updatedValueSuccesfully={updatedValueSuccesfully}
      visible={visible}
      onHide={onHide}
      onReset={handleReset}
      onDeleteTag={handleDeleteTag}
      onAddValueToTag={handleAddValueToTag}
      tagValues={initialValues.values}
      existingValueErrorMessage={existingValueErrorMessage}
      newValueErrorMessage={newValueErrorMessage}
      onUpdateValue={handleUpdateValue}
      onRemoveValue={handleRemoveValue}
      onClearAllErrors={handleClearAllErrors}
      onSubmit={handleUpdateTag}
      clickedSubmit={clickedSubmit}
      onSetClickedSubmit={(clicked: boolean) => setClickedSubmit(clicked)}
    />
  );
};
