import { all, takeLatest, select, put, AllEffect } from 'redux-saga/effects';
import {
  CreateBotRequest,
  UpdateBotRequest,
  DeleteBotRequest,
  CreateTokenRequest,
  DeleteTokenRequest,
  BotMeta,
} from '../../grpc/grpcweb/bot_pb';
import { TextItem } from '../../grpc/grpccommon/common_pb';
import services from '../../services';
import {
  SequenceType,
  request,
  ActionType,
  AlertType,
  BotFormData,
  paths,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';
import {
  getAccessToken,
  getFetchBotListError,
  getCreateBotError,
  getUpdateBotError,
  getFetchBotError,
  getDeleteBotError,
  getCreateBotTokenError,
  getDeleteBotTokenError,
  getSelectTokenError,
} from '../selectors';
import { showAlert, fetchBotList, clearSuccess } from '../actions';
import { push } from 'connected-react-router';

function* fetchList(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch bot list.',
      }),
    );
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.fetchedList },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .fetchList(token)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchBotListError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchOne(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch a bot.',
      }),
    );
  }

  const botShortname: string = action.payload;

  if (!botShortname) {
    return;
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.current.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .fetchOneBot(token, botShortname)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchBotError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* selectToken(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch a bot.',
      }),
    );
  }

  const { botId, tokenId }: { botId: string; tokenId: string } = action.payload;

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.current.selectedToken },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .fetchBotById(token, botId)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve({ bot: res, tokenId });
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getSelectTokenError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* createNewBot(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to create a bot.',
      }),
    );
  }

  const formData: BotFormData = action.payload;
  const { shortname, description } = formData;

  const botMeta = new BotMeta();
  shortname && botMeta.setShortname(shortname);

  if (description) {
    const textItem = new TextItem();
    textItem.setValue(description);
    botMeta.setDescription(textItem);
  }

  const createBotRequest = new CreateBotRequest();
  createBotRequest.setMeta(botMeta);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.created },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .createNewBot(token, createBotRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(fetchBotList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.shortname} bot successfully created.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getCreateBotError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* createToken(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to create a token.',
      }),
    );
  }

  const botId: string = action.payload;

  const createTokenRequest = new CreateTokenRequest();
  createTokenRequest.setBotid(botId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.current.createdToken },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .createNewToken(token, createTokenRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchBotList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `New API token successfully created and assigned to the bot.`,
      }),
    );
    yield put(clearSuccess());
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getCreateBotTokenError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* updateBot(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update the bot.',
      }),
    );
  }

  const { formData, botId }: { formData: BotFormData; botId: string } = action.payload;
  const { description } = formData;

  const updateBotRequest = new UpdateBotRequest();
  updateBotRequest.setBotid(botId);

  if (description) {
    const textItem = new TextItem();
    textItem.setType(1);
    textItem.setValue(description);
    updateBotRequest.setDescription(textItem);
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.current.updated },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .updateBot(token, updateBotRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.shortname} bot successfully updated.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateBotError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteBot(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete the bot.',
      }),
    );
  }

  const { botId, shortName }: { botId: string; shortName: string } = action.payload;

  const deleteBotRequest = new DeleteBotRequest();
  deleteBotRequest.setBotid(botId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.current.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .deleteBot(token, deleteBotRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchBotList());
    yield put(push(paths.bots));
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${shortName} bot successfully deleted.`,
      }),
    );
    yield put(clearSuccess());
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteBotError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteToken(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete the token.',
      }),
    );
  }

  const { botId, tokenId }: { botId: string; tokenId: string } = action.payload;

  const deleteTokenRequest = new DeleteTokenRequest();
  deleteTokenRequest.setBotid(botId);
  deleteTokenRequest.setTokenid(tokenId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.bots.current.deletedToken },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ botsService }) =>
          botsService
            .deleteToken(token, deleteTokenRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchBotList());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `API token successfully deleted.`,
      }),
    );
    yield put(clearSuccess());
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteBotTokenError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.bots.fetchList, fetchList)]);
  yield all([takeLatest(actionTypes.bots.current.fetch, fetchOne)]);
  yield all([takeLatest(actionTypes.bots.create, createNewBot)]);
  yield all([takeLatest(actionTypes.bots.current.update, updateBot)]);
  yield all([takeLatest(actionTypes.bots.current.delete, deleteBot)]);
  yield all([takeLatest(actionTypes.bots.current.createToken, createToken)]);
  yield all([takeLatest(actionTypes.bots.current.deleteToken, deleteToken)]);
  yield all([takeLatest(actionTypes.bots.current.selectToken, selectToken)]);
}
