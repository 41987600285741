type EventCallback = (...args: any) => void;

export default class EventEmitter {
  private readonly events: { [eventName: string]: Set<EventCallback> };

  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName: string): Set<EventCallback> {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set();
    }

    return this.events[eventName];
  }

  on(eventName: string, fn: EventCallback): void {
    this._getEventListByName(eventName).add(fn);
  }

  emit(eventName: string, ...args: any[]): void {
    this._getEventListByName(eventName).forEach(fn => {
      fn.apply(this, args);
    });
  }

  off(eventName: string, fn: EventCallback): void {
    this._getEventListByName(eventName).delete(fn);
  }
}
