import styled from 'styled-components';

export const CopyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -1rem;
  padding-bottom: 1rem;
  z-index: ${props => props.theme.zIndexes.modal};
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
`;
