import { ElementType } from 'react';
import { ReactComponent as Access } from './Access.svg';
import { ReactComponent as AddTeam } from './AddTeam.svg';
import { ReactComponent as AddUser } from './AddUser.svg';
import { ReactComponent as Alert } from './Alert.svg';
import { ReactComponent as Analytics } from './Analytics.svg';
import { ReactComponent as ArrowLeft } from './ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import { ReactComponent as ArrowUp } from './ArrowUp.svg';
import { ReactComponent as Astronaut } from './Astronaut.svg';
import { ReactComponent as Book } from './Book.svg';
import { ReactComponent as Bot } from './Bot.svg';
import { ReactComponent as Build } from './Build.svg';
import { ReactComponent as Caution } from './Caution.svg';
import { ReactComponent as Chart } from './Chart.svg';
import { ReactComponent as Check } from './Check.svg';
import { ReactComponent as CheckRound } from './CheckRound.svg';
import { ReactComponent as Chevron } from './Chevron.svg';
import { ReactComponent as Chip } from './Chip.svg';
import { ReactComponent as Cloudwatch } from './Cloudwatch.svg';
import { ReactComponent as CloudWatchSquare } from './CloudWatchSquare.svg';
import { ReactComponent as Collapse } from './Collapse.svg';
import { ReactComponent as Comment } from './Comment.svg';
import { ReactComponent as Component } from './Component.svg';
import { ReactComponent as Copy } from './Copy.svg';
import { ReactComponent as Create } from './Create.svg';
import { ReactComponent as Cross } from './Cross.svg';
import { ReactComponent as CrossLarge } from './CrossLarge.svg';
import { ReactComponent as CrossRound } from './CrossRound.svg';
import { ReactComponent as CustomerSupport } from './CustomerSupport.svg';
import { ReactComponent as Cycle } from './Cycle.svg';
import { ReactComponent as Dashboard } from './Dashboard.svg';
import { ReactComponent as DataDog } from './Datadog.svg';
import { ReactComponent as Demo } from './Demo.svg';
import { ReactComponent as Dependency } from './Dependency.svg';
import { ReactComponent as Discord } from './Discord.svg';
import { ReactComponent as Doctor } from './Doctor.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { ReactComponent as Elastic } from './Elastic.svg';
import { ReactComponent as EmailFilled } from './EmailFilled.svg';
import { ReactComponent as EmailText } from './EmailText.svg';
import { ReactComponent as Emoji } from './Emoji.svg';
import { ReactComponent as ExternalLink } from './ExternalLink.svg';
import { ReactComponent as Eye } from './Eye.svg';
import { ReactComponent as Facebook } from './Facebook.svg';
import { ReactComponent as Feed } from './Feed.svg';
import { ReactComponent as Filter } from './Filter.svg';
import { ReactComponent as FilterOn } from './FilterOn.svg';
import { ReactComponent as Graph } from './Graph.svg';
import { ReactComponent as Graphite } from './Graphite.svg';
import { ReactComponent as Hamburger } from './Hamburger.svg';
import { ReactComponent as Help } from './Help.svg';
import { ReactComponent as Home } from './Home.svg';
import { ReactComponent as Hourglass } from './Hourglass.svg';
import { ReactComponent as Info } from './Info.svg';
import { ReactComponent as Instagram } from './Instagram.svg';
import { ReactComponent as Integration } from './Integration.svg';
import { ReactComponent as Launch } from './Launch.svg';
import { ReactComponent as Link } from './Link.svg';
import { ReactComponent as Linkedin } from './Linkedin.svg';
import { ReactComponent as Lock } from './Lock.svg';
import { ReactComponent as Login } from './Login.svg';
import { ReactComponent as Logo } from './Logo.svg';
import { ReactComponent as LogoWithText } from './LogoWithText.svg';
import { ReactComponent as NewRelic } from './NewRelic.svg';
import { ReactComponent as Notification } from './Notification.svg';
import { ReactComponent as NotificationOn } from './NotificationOn.svg';
import { ReactComponent as Organisation } from './Organisation.svg';
import { ReactComponent as Opsgenie } from './Opsgenie.svg';
import { ReactComponent as Paint } from './Paint.svg';
import { ReactComponent as Pagerduty } from './Pagerduty.svg';
import { ReactComponent as PieChart } from './PieChart.svg';
import { ReactComponent as Plug } from './Plug.svg';
import { ReactComponent as Plus } from './Plus.svg';
import { ReactComponent as Presentation } from './Presentation.svg';
import { ReactComponent as Prometheus } from './Prometheus.svg';
import { ReactComponent as PrometheusSquare } from './PrometheusSquare.svg';
import { ReactComponent as Reset } from './Reset.svg';
import { ReactComponent as Robot } from './Robot.svg';
import { ReactComponent as Rocket } from './Rocket.svg';
import { ReactComponent as Save } from './Save.svg';
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as SignalFX } from './SignalFX.svg';
import { ReactComponent as Slack } from './Slack.svg';
import { ReactComponent as SmallChevron } from './SmallChevron.svg';
import { ReactComponent as Spinner } from './Spinner.svg';
import { ReactComponent as Subscribe } from './Subscribe.svg';
import { ReactComponent as Tag } from './Tag.svg';
import { ReactComponent as Team } from './Team.svg';
import { ReactComponent as TeamAvatar } from './TeamAvatar.svg';
import { ReactComponent as ThreeDots } from './ThreeDots.svg';
import { ReactComponent as TickCircle } from './TickCircle.svg';
import { ReactComponent as Tools } from './Tools.svg';
import { ReactComponent as Trash } from './Trash.svg';
import { ReactComponent as Trophy } from './Trophy.svg';
import { ReactComponent as Try } from './Try.svg';
import { ReactComponent as Twitter } from './Twitter.svg';
import { ReactComponent as Unlink } from './Unlink.svg';
import { ReactComponent as Url } from './Url.svg';
import { ReactComponent as User } from './User.svg';
import { ReactComponent as Users } from './Users.svg';
import { ReactComponent as WarningTriangle } from './WarningTriangle.svg';
import { ReactComponent as Wingman } from './Wingman.svg';
import { ReactComponent as YouTube } from './Youtube.svg';

export const Icon: { [key: string]: ElementType } = {
  Access,
  AddTeam,
  AddUser,
  Alert,
  Analytics,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Astronaut,
  Bot,
  Book,
  Build,
  Caution,
  Chart,
  CheckRound,
  Check,
  Chevron,
  Chip,
  Cloudwatch,
  CloudWatchSquare,
  Collapse,
  Comment,
  Component,
  Copy,
  Create,
  Cross,
  CrossLarge,
  CrossRound,
  CustomerSupport,
  Cycle,
  Dashboard,
  DataDog,
  Demo,
  Dependency,
  Discord,
  Doctor,
  Edit,
  Elastic,
  EmailFilled,
  EmailText,
  Emoji,
  ExternalLink,
  Eye,
  Facebook,
  Feed,
  Filter,
  FilterOn,
  Graph,
  Graphite,
  Hamburger,
  Help,
  Home,
  Hourglass,
  Info,
  Instagram,
  Integration,
  Launch,
  Link,
  Linkedin,
  Lock,
  Login,
  Logo,
  LogoWithText,
  NewRelic,
  Notification,
  NotificationOn,
  Opsgenie,
  Organisation,
  Paint,
  PieChart,
  Pagerduty,
  Plug,
  Plus,
  Presentation,
  Prometheus,
  PrometheusSquare,
  Reset,
  Robot,
  Rocket,
  Save,
  Search,
  SmallChevron,
  SignalFX,
  Slack,
  Spinner,
  Subscribe,
  Tag,
  Team,
  TeamAvatar,
  ThreeDots,
  TickCircle,
  Tools,
  Trash,
  Trophy,
  Try,
  Twitter,
  Unlink,
  Url,
  User,
  Users,
  WarningTriangle,
  Wingman,
  YouTube,
};
