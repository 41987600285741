import { User, UserObject } from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { isLoading } from './loading';
import { getErrorMessage } from './errors';
import { RootState } from '../reducers';

export const getAllUsers = (state: RootState): User[] => {
  return state.users.list;
};

export const getSelectedUserState = (state: RootState): Record<string, boolean> => {
  return state.users.selectedUserState;
};

export const getNumberOfSelectedUsers = (state: RootState): number => {
  const userIds = Object.keys(state.users.selectedUserState).filter(
    (userId: string) => state.users.selectedUserState[userId] === true,
  );
  return userIds.length;
};

export const getUser = (state: RootState): UserObject => {
  return state.users.current;
};

export const getTenantName = (state: RootState): string => {
  return state.users.current.tenantname;
};

export const isFetchingUsers = (state: RootState): boolean => {
  return isLoading(state, actionTypes.users.fetch);
};

export const getFetchUsersError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.users.fetch);
};

export const isFetchingUser = (state: RootState): boolean => {
  return isLoading(state, actionTypes.users.current.fetch);
};

export const getFetchUserError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.users.current.fetch);
};

export const isUserUpdating = (state: RootState): boolean => {
  return isLoading(state, actionTypes.users.current.update);
};

export const isRemovingUser = (state: RootState): boolean => {
  return isLoading(state, actionTypes.users.current.remove);
};

export const getUpdateUserError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.users.current.update);
};

export const isUserReady = (state: RootState): boolean => {
  return state.users.isReady;
};

export const isEmailVerified = (state: RootState): UserObject['emailVerified'] => {
  return state.users.current.emailVerified;
};

export const getTotalNumberOfUsers = (state: RootState): number => {
  return state.users.totalNumber;
};

export const getUserListNextPageToken = (state: RootState): string | undefined => {
  return state.users.nextPageToken;
};

export const getUserListPrevPageToken = (state: RootState): string | undefined => {
  return state.users.prevPageToken;
};

export const getUserListCurrPageToken = (state: RootState): string | undefined => {
  return state.users.currPageToken;
};

export const isFetchingUserListNextPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.users.fetchNextPage);
};

export const isFetchingUserListPrevPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.users.fetchPrevPage);
};

export const getFetchUserListNextPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.users.fetchNextPage);
};

export const getFetchUserListPrevPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.users.fetchPrevPage);
};
