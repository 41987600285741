import { all, takeLatest, select, put, AllEffect, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import {
  ComponentMetadata,
  CreateComponentRequest,
  UpdateComponentRequest,
  // SloTargetEdit,
  GetComponentRequest,
  GetComponentByShortnameRequest,
  ListComponentsOptions,
  DeleteComponentRequest,
  AddDependenciesRequest,
  AddDependentsRequest,
  RemoveDependencyRequest,
  RemoveDependantRequest,
  ComponentListRelationsRequest,
  AddComponentLinkRequest,
  UpdateComponentLinkRequest,
  RemComponentLinkRequest,
  ListMyComponentsRequest,
  ListWithNoRelationsRequest,
  GetComponentDetailsForPopupRequest,
  FilterByTags,
  ListByFilterPageRequest,
  FilterByTeams,
  FilterByComponentTypes,
  // AddCommentRequest,
  // CommentTarget,
  // TextType,
} from '../../grpc/grpcweb/component_pb';
import { LinkMeta } from '../../grpc/grpcweb/link_pb';
import { Avatar, PageRequest, TextItem, TextType } from '../../grpc/grpccommon/common_pb';
import services from '../../services';
import {
  SequenceType,
  request,
  ActionType,
  AlertType,
  CreateComponentFormData,
  EditComponentFormData,
  AddLinkFormData,
  UpdateLinkFormData,
  paths,
} from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { COMPONENT_LIST_PAGE_SIZE } from '../constants/pageSize';
import {
  getAccessToken,
  // getFetchComponentCommentsError,
  // getAddComponentCommentError,
} from '../selectors';
import {
  getComponentListNextPageToken,
  getFetchComponentListNextPageError,
  getFetchComponentListPrevPageError,
  getComponentListPrevPageToken,
  getFetchComponentListError,
  getCreateComponentError,
  getFetchComponentTypesError,
  getFetchComponentByShortnameError,
  getFetchComponentDependenciesError,
  getLinkComponentDependenciesError,
  getLinkComponentDependentsError,
  getEditComponentError,
  getFetchComponentByIdError,
  getDeleteComponentError,
  getUnlinkComponentDependantError,
  getUnlinkComponentDependencyError,
  getAddComponentLinkError,
  getUpdateComponentLinkError,
  getDeleteComponentLinkError,
  getFetchMyComponentsError,
  getComponentListCurrPageToken,
  getFetchStandAloneComponentListError,
  getFetchStandAloneComponentListNextPageError,
  getFetchStandAloneComponentListPrevPageError,
  getStandAloneComponentListNextPageToken,
  getStandAloneComponentListPrevPageToken,
  getStandAloneComponentListCurrPageToken,
  getFetchComponentPopupDetailsError,
} from './../selectors/components';
import {
  showAlert,
  fetchComponentList,
  clearSuccess,
  fetchComponentDependencies,
  FetchComponentListProps,
  // fetchComponentComments
} from '../actions';
import { RootState } from '../reducers';

function* fetchList(
  action: ActionType,
  successAction: string,
  errorGetter: (state: RootState) => string,
  pageToken?: string,
) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch component list.',
      }),
    );
  }

  const {
    pageSize,
    includeTeamData,
    filterByTags,
    filterByTeam,
    filterByType,
  }: FetchComponentListProps = action.payload;

  const fetchComponentsRequest = new ListByFilterPageRequest();
  const pageData = new PageRequest();
  pageData.setPagesize(pageSize || COMPONENT_LIST_PAGE_SIZE);
  pageToken && pageData.setPagetoken(pageToken);
  fetchComponentsRequest.setPagerequest(pageData);

  const listOptions = new ListComponentsOptions();
  listOptions.setIncludeteamdetails(Boolean(includeTeamData));
  fetchComponentsRequest.setOptions(listOptions);

  if (filterByTags) {
    const tagOptions = new FilterByTags();
    filterByTags.keyList && tagOptions.setKeyidsList(filterByTags.keyList);
    filterByTags.valueList && tagOptions.setValueidsList(filterByTags.valueList);
    fetchComponentsRequest.setTags(tagOptions);
  }

  if (filterByTeam) {
    const teamOptions = new FilterByTeams();
    teamOptions.setTeamidsList(filterByTeam);
    fetchComponentsRequest.setTeams(teamOptions);
  }

  if (filterByType) {
    const typeOptions = new FilterByComponentTypes();
    typeOptions.setTypeidsList(filterByType);
    fetchComponentsRequest.setTypes(typeOptions);
  }

  const { sequence } = yield request(
    { rootAction: action, type: successAction },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchFilteredListByPage(token, fetchComponentsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(errorGetter);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchAllComponents(action: ActionType) {
  const currPageToken = yield select(getComponentListCurrPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.components.fetchedList,
    getFetchComponentListError,
    currPageToken,
  );
}

function* fetchListNextPage(action: ActionType) {
  const nextPageToken = yield select(getComponentListNextPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.components.fetchedNextPage,
    getFetchComponentListNextPageError,
    nextPageToken,
  );
}

function* fetchListPrevPage(action: ActionType) {
  const prevPageToken = yield select(getComponentListPrevPageToken);

  yield call(
    fetchList,
    action,
    actionTypes.components.fetchedPrevPage,
    getFetchComponentListPrevPageError,
    prevPageToken,
  );
}

function* fetchMyComponents(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch my components.',
      }),
    );
  }

  const myComponentsRequest = new ListMyComponentsRequest();
  const options = new ListComponentsOptions();
  options.setIncludeteamdetails(true);
  myComponentsRequest.setOptions(options);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.fetchedMy },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchMyComponents(token, myComponentsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchMyComponentsError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchStandAloneList(
  action: ActionType,
  successAction: string,
  errorGetter: (state: RootState) => string,
  pageToken?: string,
) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch stand-alone component list.',
      }),
    );
  }

  const pageSize = action.payload;

  const fetchStandAloneComponentsRequest = new ListWithNoRelationsRequest();
  const pageData = new PageRequest();
  pageData.setPagesize(pageSize || COMPONENT_LIST_PAGE_SIZE);
  pageToken && pageData.setPagetoken(pageToken);
  fetchStandAloneComponentsRequest.setPagerequest(pageData);

  const listOptions = new ListComponentsOptions();
  fetchStandAloneComponentsRequest.setOptions(listOptions);

  const { sequence } = yield request(
    { rootAction: action, type: successAction },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchStandAloneListByPage(token, fetchStandAloneComponentsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(errorGetter);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchAllStandAloneComponents(action: ActionType) {
  const currPageToken = yield select(getStandAloneComponentListCurrPageToken);

  yield call(
    fetchStandAloneList,
    action,
    actionTypes.components.standAlone.fetchedList,
    getFetchStandAloneComponentListError,
    currPageToken,
  );
}

function* fetchStandAloneListNextPage(action: ActionType) {
  const nextPageToken = yield select(getStandAloneComponentListNextPageToken);

  yield call(
    fetchStandAloneList,
    action,
    actionTypes.components.standAlone.fetchedNextPage,
    getFetchStandAloneComponentListNextPageError,
    nextPageToken,
  );
}

function* fetchStandAloneListPrevPage(action: ActionType) {
  const prevPageToken = yield select(getStandAloneComponentListPrevPageToken);

  yield call(
    fetchStandAloneList,
    action,
    actionTypes.components.standAlone.fetchedPrevPage,
    getFetchStandAloneComponentListPrevPageError,
    prevPageToken,
  );
}

function* fetchTypes(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch SLO component types.',
      }),
    );
  }

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.fetchedTypes },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchComponentTypes(token)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchComponentTypesError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* createComponent(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to create a component.',
      }),
    );
  }

  const formData: CreateComponentFormData = action.payload;
  const description = new TextItem();
  description.setValue(formData.description);
  description.setType(TextType.TEXT_TYPE_PLAIN);

  const componentMetadata = new ComponentMetadata();
  componentMetadata.setDescription(description);
  componentMetadata.setDisplayname(formData.displayName);
  componentMetadata.setShortname(formData.shortName);
  componentMetadata.setType(formData.componentType);
  formData.ownerTeam && componentMetadata.setOwnerteamid(formData.ownerTeam?.id);

  const createComponentRequest = new CreateComponentRequest();
  createComponentRequest.setMeta(componentMetadata);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.created },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .createComponent(token, createComponentRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchComponentList({ includeTeamData: true }));
    yield put(clearSuccess());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.displayName || formData.shortName} component successfully created!`,
      }),
    );
    yield put(push(paths.components));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getCreateComponentError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* editComponent(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update the component.',
      }),
    );
  }

  const { formData, id }: { formData: EditComponentFormData; id: string } = action.payload;
  const componentMetadata = new ComponentMetadata();

  if (formData.description) {
    const description = new TextItem();
    description.setValue(formData.description);
    description.setType(TextType.TEXT_TYPE_PLAIN);
    componentMetadata.setDescription(description);
  }

  formData.displayName && componentMetadata.setDisplayname(formData.displayName);
  formData.shortName && componentMetadata.setShortname(formData.shortName);
  formData.componentType && componentMetadata.setType(formData.componentType);
  formData.ownerTeam && componentMetadata.setOwnerteamid(formData.ownerTeam.id);

  const updateComponentRequest = new UpdateComponentRequest();
  updateComponentRequest.setMeta(componentMetadata);
  updateComponentRequest.setId(id);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.edited },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .editComponent(token, updateComponentRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              if (res) {
                return resolve(res);
              }
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${formData.displayName || formData.shortName} component updated successfully.`,
      }),
    );
    yield put(push(generatePath(paths.component, { componentShortname: formData.shortName })));
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getEditComponentError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchComponentByShortname(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch the component.',
      }),
    );
  }

  const componentShortname: string = action.payload;
  const getComponentRequest = new GetComponentByShortnameRequest();
  getComponentRequest.setShortname(componentShortname);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.fetchedByShortname },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchComponentByShortname(token, getComponentRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchComponentByShortnameError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteComponent(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete the component.',
      }),
    );
  }

  const { componentId, name }: { componentId: string; name: string } = action.payload;
  const deleteComponentRequest = new DeleteComponentRequest();
  deleteComponentRequest.setComponentid(componentId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .deleteComponent(token, deleteComponentRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(push(generatePath(paths.components)));
    yield put(clearSuccess());
    yield put(
      showAlert({
        type: AlertType.Success,
        message: `${name} component successfully deleted.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteComponentError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchComponentById(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch the component.',
      }),
    );
  }

  const componentId: string = action.payload;
  const getComponentRequest = new GetComponentRequest();
  getComponentRequest.setId(componentId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.fetchedById },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchComponent(token, getComponentRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchComponentByIdError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchComments(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch component comments.',
      }),
    );
  }

  // const componentId: string = action.payload;

  // const { sequence } = yield request(
  //   { rootAction: action, type: actionTypes.components.comments.fetched},
  //   () =>
  //     new Promise((resolve, reject) => {
  //       services().then(({ componentService }) =>
  //         componentService
  //           .fetchComments(token, componentId)
  //           .catch((error: any) => reject(error))
  //           .then(res => {
  //             return resolve(res);
  //           }),
  //       );
  //     }),
  // );

  // if (sequence === SequenceType.Error) {
  //   const errorMessage = yield select(getFetchComponentCommentsError);
  //   if (errorMessage) {
  //     yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
  //   }
  // }
}

function* addComment(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to add component comment.',
      }),
    );
  }

  // const { componentId, comment } = action.payload;

  // const addCommentRequest = new AddCommentRequest();
  // addCommentRequest.setTargetid(componentId);
  // addCommentRequest.setTarget(CommentTarget.TARGET_ACTIVITY);

  // if (comment) {
  //   const commentItem = new TextItem();
  //   commentItem.setValue(comment);
  //   commentItem.setType(TextType.TEXT_TYPE_PLAIN);
  //   addCommentRequest.setValue(commentItem);
  // }

  // const { sequence } = yield request(
  //   { rootAction: action, type: actionTypes.components.dependencies.linked },
  //   () =>
  //     new Promise((resolve, reject) => {
  //       services().then(({ componentService }) =>
  //         componentService
  //           .addComment(token, addCommentRequest)
  //           .catch((error: any) => reject(error))
  //           .then(res => {
  //             return resolve(res);
  //           }),
  //       );
  //     }),
  // );

  // if (sequence === SequenceType.Success) {
  //   yield put(fetchComponentComments(componentId));
  // } else if (sequence === SequenceType.Error) {
  //   const errorMessage = yield select(getAddComponentCommentError);
  //   if (errorMessage) {
  //     yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
  //   }
  // }
}

function* fetchDependencies(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch component dependencies.',
      }),
    );
  }

  const componentId: string = action.payload;

  const componentListRelationsRequest = new ComponentListRelationsRequest();
  const options: ListComponentsOptions = new ListComponentsOptions();
  options.setIncludeteamdetails(true);
  componentListRelationsRequest.setComponentid(componentId);
  componentListRelationsRequest.setOptions(options);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.dependencies.fetched },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchDependencies(token, componentListRelationsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchComponentDependenciesError);

    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* linkDependencies(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to link component dependencies.',
      }),
    );
  }

  const { componentId, dependencyIds }: { componentId: string; dependencyIds: string[] } =
    action.payload;

  if (!dependencyIds.length) {
    return;
  }

  const addDependenciesRequest: AddDependenciesRequest = new AddDependenciesRequest();
  addDependenciesRequest.setDependenciesidsList(dependencyIds);
  addDependenciesRequest.setComponentid(componentId);
  const listOptions = new ListComponentsOptions();
  listOptions.setIncludeteamdetails(false);
  addDependenciesRequest.setListoptions(listOptions);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.dependencies.linkedDependencies },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .setDependencies(token, addDependenciesRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchComponentDependencies(componentId));
    yield put(clearSuccess());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Component dependenc${
          dependencyIds.length > 1 ? 'ies' : 'y'
        } linked successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getLinkComponentDependenciesError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* linkDependents(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to link component dependents.',
      }),
    );
  }

  const { componentId, dependentIds }: { componentId: string; dependentIds: string[] } =
    action.payload;

  if (!dependentIds.length) {
    return;
  }

  const addDependentsRequest: AddDependentsRequest = new AddDependentsRequest();
  addDependentsRequest.setDependentidsList(dependentIds);
  addDependentsRequest.setComponentid(componentId);
  const listOptions = new ListComponentsOptions();
  listOptions.setIncludeteamdetails(false);
  addDependentsRequest.setListoptions(listOptions);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.dependencies.linkedDependents },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .setDependents(token, addDependentsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchComponentDependencies(componentId));
    yield put(clearSuccess());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Component depend${dependentIds.length > 1 ? 'ents' : 'ant'} linked successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getLinkComponentDependentsError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* unlinkDependency(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to unlink component dependency.',
      }),
    );
  }

  const { componentId, dependencyId }: { componentId: string; dependencyId: string } =
    action.payload;

  if (!dependencyId) {
    return;
  }

  const removeDependencyRequest: RemoveDependencyRequest = new RemoveDependencyRequest();
  removeDependencyRequest.setDependencycomponentid(dependencyId);
  removeDependencyRequest.setComponentid(componentId);
  const listOptions = new ListComponentsOptions();
  listOptions.setIncludeteamdetails(false);
  removeDependencyRequest.setListoptions(listOptions);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.dependencies.unlinkedDependency },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .unlinkDependency(token, removeDependencyRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchComponentDependencies(componentId));
    yield put(clearSuccess());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Component dependency unlinked successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUnlinkComponentDependencyError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* unlinkDependant(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to unlink component dependant.',
      }),
    );
  }

  const { componentId, dependantId }: { componentId: string; dependantId: string } = action.payload;

  if (!dependantId) {
    return;
  }

  const removeDependantRequest: RemoveDependantRequest = new RemoveDependantRequest();
  removeDependantRequest.setDependantcomponentid(dependantId);
  removeDependantRequest.setComponentid(componentId);
  const listOptions = new ListComponentsOptions();
  listOptions.setIncludeteamdetails(false);
  removeDependantRequest.setListoptions(listOptions);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.dependencies.unlinkedDependant },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .unlinkDependant(token, removeDependantRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(fetchComponentDependencies(componentId));
    yield put(clearSuccess());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Component dependant unlinked successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUnlinkComponentDependantError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* addLink(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to add component link.',
      }),
    );
  }

  const { componentId, formData }: { componentId: string; formData: AddLinkFormData } =
    action.payload;

  const addLinkRequest: AddComponentLinkRequest = new AddComponentLinkRequest();
  addLinkRequest.setComponentid(componentId);

  const linkMeta = new LinkMeta();
  const avatar = new Avatar();
  formData.avatarUrl ? avatar.setUri(formData.avatarUrl) : avatar.setUri('avatar');
  linkMeta.setAvatar(avatar);
  formData.displayname && linkMeta.setDisplayname(formData.displayname);
  formData.url && linkMeta.setUrl(formData.url);

  addLinkRequest.setLinkmeta(linkMeta);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.links.added },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .addLink(token, addLinkRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Link added successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getAddComponentLinkError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* updateLink(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to update component link.',
      }),
    );
  }

  const { componentId, formData }: { componentId: string; formData: UpdateLinkFormData } =
    action.payload;

  if (!formData.id) {
    return;
  }

  const updateLinkRequest: UpdateComponentLinkRequest = new UpdateComponentLinkRequest();
  updateLinkRequest.setComponentid(componentId);
  updateLinkRequest.setLinkid(formData.id);

  const linkMeta = new LinkMeta();
  const avatar = new Avatar();
  formData.avatarUrl && avatar.setUri(formData.avatarUrl);
  linkMeta.setAvatar(avatar);
  linkMeta.setDisplayname(formData.displayname || '');
  formData.url && linkMeta.setUrl(formData.url);

  updateLinkRequest.setLinkmeta(linkMeta);

  const updateMask = new FieldMask();
  updateMask.setPathsList(['avatar.uri', 'displayName', 'url']);
  updateLinkRequest.setUpdatemask(updateMask);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.links.edited },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .updateLink(token, updateLinkRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Link updated successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getUpdateComponentLinkError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* deleteLink(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to delete component link.',
      }),
    );
  }

  const { componentId, linkId }: { componentId: string; linkId: string } = action.payload;

  const deleteLinkrequest: RemComponentLinkRequest = new RemComponentLinkRequest();
  deleteLinkrequest.setComponentid(componentId);
  deleteLinkrequest.setLinkid(linkId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.links.deleted },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .deleteLink(token, deleteLinkrequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Success) {
    yield put(clearSuccess());

    yield put(
      showAlert({
        type: AlertType.Success,
        message: `Link deleted successfully.`,
      }),
    );
  } else if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getDeleteComponentLinkError);
    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

function* fetchComponentPopupDetails(action: ActionType) {
  const token = yield select(getAccessToken);

  if (!token) {
    yield put(
      showAlert({
        type: AlertType.Danger,
        message: 'No auth token provided to fetch component popup details.',
      }),
    );
  }

  const componentId: string = action.payload;

  const componentPopupDetailsRequest = new GetComponentDetailsForPopupRequest();
  componentPopupDetailsRequest.setComponentid(componentId);

  const { sequence } = yield request(
    { rootAction: action, type: actionTypes.components.current.popup.fetchedDetails },
    () =>
      new Promise((resolve, reject) => {
        services().then(({ componentService }) =>
          componentService
            .fetchDetailsForPopup(token, componentPopupDetailsRequest)
            .catch((error: any) => reject(error))
            .then(res => {
              return resolve(res);
            }),
        );
      }),
  );

  if (sequence === SequenceType.Error) {
    const errorMessage = yield select(getFetchComponentPopupDetailsError);

    if (errorMessage) {
      yield put(showAlert({ type: AlertType.Danger, message: errorMessage }));
    }
  }
}

export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([takeLatest(actionTypes.components.fetchList, fetchAllComponents)]);
  yield all([takeLatest(actionTypes.components.fetchNextPage, fetchListNextPage)]);
  yield all([takeLatest(actionTypes.components.fetchPrevPage, fetchListPrevPage)]);
  yield all([takeLatest(actionTypes.components.fetchMy, fetchMyComponents)]);

  yield all([
    takeLatest(actionTypes.components.standAlone.fetchList, fetchAllStandAloneComponents),
  ]);
  yield all([
    takeLatest(actionTypes.components.standAlone.fetchNextPage, fetchStandAloneListNextPage),
  ]);
  yield all([
    takeLatest(actionTypes.components.standAlone.fetchPrevPage, fetchStandAloneListPrevPage),
  ]);

  yield all([takeLatest(actionTypes.components.fetchTypes, fetchTypes)]);
  yield all([takeLatest(actionTypes.components.create, createComponent)]);
  yield all([takeLatest(actionTypes.components.current.delete, deleteComponent)]);
  yield all([takeLatest(actionTypes.components.current.edit, editComponent)]);
  yield all([
    takeLatest(actionTypes.components.current.fetchByShortname, fetchComponentByShortname),
  ]);
  yield all([takeLatest(actionTypes.components.current.fetchById, fetchComponentById)]);
  yield all([takeLatest(actionTypes.components.current.dependencies.fetch, fetchDependencies)]);
  yield all([
    takeLatest(actionTypes.components.current.dependencies.linkDependencies, linkDependencies),
  ]);
  yield all([
    takeLatest(actionTypes.components.current.dependencies.linkDependents, linkDependents),
  ]);
  yield all([
    takeLatest(actionTypes.components.current.dependencies.unlinkDependency, unlinkDependency),
  ]);
  yield all([
    takeLatest(actionTypes.components.current.dependencies.unlinkDependant, unlinkDependant),
  ]);
  yield all([takeLatest(actionTypes.components.current.links.add, addLink)]);
  yield all([takeLatest(actionTypes.components.current.links.delete, deleteLink)]);
  yield all([takeLatest(actionTypes.components.current.links.edit, updateLink)]);
  yield all([takeLatest(actionTypes.components.current.comments.add, addComment)]);
  yield all([takeLatest(actionTypes.components.current.comments.fetch, fetchComments)]);
  yield all([
    takeLatest(actionTypes.components.current.popup.fetchDetails, fetchComponentPopupDetails),
  ]);
}
