import styled, { css } from 'styled-components';
export * from '../Input/Input.styles';

type FieldProps = {
  hasLabel?: boolean;
  invalid?: boolean;
  valid?: boolean;
  active?: boolean;
  hasValue?: boolean;
  touched?: boolean;
  readOnly?: boolean;
  showRedBorderOnUnfocus: boolean;
  maxWidth?: string;
};

export const Textarea = styled.textarea<FieldProps>`
  outline: none;
  resize: vertical;
  min-height: 8rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.palettes.border};
  color: ${({ theme }) => theme.palettes.main};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.effects.inputShadow};
  font-size: 0.875rem;
  line-height: 1.5rem;
  position: relative;
  width: 100%;
  max-width: ${props => props.maxWidth || 'auto'};
  flex: 1 1 auto;
  &:focus {
    outline: 0;
  }

  ::placeholder {
    color: ${({ theme }) => theme.palettes.placeholder};
  }

  ${props =>
    props.touched &&
    props.invalid &&
    props.showRedBorderOnUnfocus &&
    css`
      border-color: ${({ theme }) => theme.palettes.error};
    `}

  ${props =>
    props.valid &&
    props.hasValue &&
    !props.readOnly &&
    css`
      border-color: ${props => props.theme.palettes.mutedMain};
    `}

    ${props =>
    props.active &&
    css`
      border-color: ${props.theme.palettes.secondaryHighlight};
    `}
`;
