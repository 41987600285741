import styled from 'styled-components';
import { AvatarAppearance } from '../../../definitions';

type ActionTextProps = {
  appearance?: AvatarAppearance;
};

export const ActionText = styled.span<ActionTextProps>`
  font-size: 1rem;
  color: ${({ theme }) => theme.palettes.inverse};
`;
