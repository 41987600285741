import styled from 'styled-components';
import { motion } from 'framer-motion';

type WrapperProps = {
  color: string;
};

export const Wrapper = styled(motion.div)<WrapperProps>`
  padding: 0.5rem;
  background-color: ${props => props.color};
  color: ${({ theme }) => theme.palettes.inverse};
  min-width: 5rem;
  border-radius: 0.5rem;
  text-align: center;
  z-index: ${({ theme }) => theme.zIndexes.tooltip};
  max-width: 30rem;
`;

export const Trigger = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`;
