import styled from 'styled-components';

export const ComponentDropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palettes.placeholder};
    padding-top: 0.25rem;
  }
  div {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
`;

export const Label = styled.p`
  font-size: 0.875rem;
`;
