import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router';
import { ActionType, FlowStepObject } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export type FlowState = {
  id: string | null;
  index: number;
  previousIndex: number;
  returnPath: string;
  history: number[];
  steps: FlowStepObject[];
  completed: boolean;
};

const initialState: FlowState = {
  id: null,
  index: -1,
  previousIndex: -1,
  returnPath: '',
  history: [],
  steps: [],
  completed: false,
};

const flow = (state: FlowState = initialState, { type, payload }: ActionType): FlowState => {
  const currentStep = state.steps[state.index];
  const history =
    currentStep && currentStep.history === false
      ? [...state.history]
      : [...state.history, state.index];
  const nextIndex = state.index + 1;
  const getIndex = (uri: string) => {
    const index = state.steps.findIndex(
      ({ path }) => path && matchPath(uri, { path, exact: true })?.isExact,
    );

    if (index === -1) {
      return 0;
    }

    return index;
  };

  switch (type) {
    case actionTypes.flow.unregister:
      return initialState;

    case actionTypes.flow.registered: {
      return {
        ...state,
        ...payload,
        history: [],
      };
    }

    case actionTypes.flow.setStep:
      return {
        ...state,
        history,
        previousIndex: state.index,
        index:
          typeof payload === 'number' ? payload : state.steps.findIndex(({ id }) => id === payload),
      };

    case actionTypes.flow.skipStep:
      return {
        ...state,
        history,
      };

    case actionTypes.flow.previousStep: {
      let previousIndex = state.index - 1;
      const previousStep = state.steps[previousIndex];

      if (state.history.length) {
        previousIndex = state.history[state.history.length - 1];
      } else if (
        previousStep &&
        previousStep.parent &&
        currentStep.parent !== previousStep.parent
      ) {
        // Jump to the start of the flow so we aren't switching to a step half way through a journey (i.e. insync within onboarding)
        previousIndex = getIndex(previousStep.parent);
      }

      return {
        ...state,
        previousIndex: state.index,
        index: previousIndex,
        history: state.history.slice(0, -1),
      };
    }

    case actionTypes.flow.nextStep:
      return {
        ...state,
        history,
        previousIndex: state.index,
        index: nextIndex,
      };

    case LOCATION_CHANGE: {
      if (state.completed) {
        return initialState;
      }

      if (currentStep && !currentStep.path) {
        return state;
      }

      return {
        ...state,
        previousIndex: state.index,
        index: getIndex(payload.location.pathname + payload.location.hash),
      };
    }

    case actionTypes.flow.complete:
      return {
        ...state,
        completed: true,
      };

    case actionTypes.users.current.remove:
    case actionTypes.auth.loggedOut:
      return initialState;

    default:
      return state;
  }
};

export default flow;
