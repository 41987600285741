import { FunctionComponent } from 'react';
import { ComponentRefInTheList } from '../../definitions';
import ComponentList from '../../lists/ComponentList';
import TableMeta from '../../components/TableMeta';
import { Radio } from '../../components/BulkSelect';

type SelectComponentTableProps = {
  isFetching: boolean;
  componentList: ComponentRefInTheList[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfComponents: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  onSelectComponent: (component: ComponentRefInTheList) => void;
  onClearSelectedComponent: () => void;
  disabledComponentIds: Set<string>;
  currentComponentId?: string;
  selectedComponentId?: string;
  selectedComponentName?: string;
  currentComponentName?: string;
};

export const SelectComponentTableView: FunctionComponent<SelectComponentTableProps> = ({
  isFetching,
  currentComponentId,
  componentList,
  selectedComponentId,
  onNextPage,
  onPrevPage,
  onSelectComponent,
  numberOfComponents,
  isPrevPageDisabled,
  isNextPageDisabled,
  onClearSelectedComponent,
  selectedComponentName,
  currentComponentName,
}) => {
  const SelectBox = (component: ComponentRefInTheList) => (
    <Radio
      item={component}
      selectedItemId={selectedComponentId}
      currentItemId={currentComponentId}
      onChange={() => onSelectComponent(component)}
    />
  );
  const getStatusTitle = (): string => {
    if (selectedComponentId && selectedComponentName) {
      if (selectedComponentId === currentComponentId) {
        return `Selected component: ${selectedComponentName}`;
      }
      return `Component to select: ${selectedComponentName}`;
    }

    if (currentComponentId) {
      return `Component to select: ${currentComponentName}`;
    }

    return 'No component selected';
  };

  return (
    <>
      <TableMeta title={getStatusTitle()} onActionClick={onClearSelectedComponent} />
      <ComponentList
        componentList={componentList}
        actionTitle=""
        isSelectable={true}
        isFetching={isFetching}
        numberOfComponents={numberOfComponents > 0 ? numberOfComponents - 1 : 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
        action={SelectBox}
      />
    </>
  );
};
