import { useState, FunctionComponent } from 'react';
import { Checkbox } from '../../Form/Checkbox';

export type BulkSelectCheckboxProps = {
  id: string;
  disabledIdMap: Set<string>;
  onChange: (newState: Record<string, boolean>) => void;
  selectedState: Record<string, boolean>;
};

export const BulkSelectCheckboxView: FunctionComponent<BulkSelectCheckboxProps> = ({
  onChange,
  disabledIdMap,
  selectedState,
  id,
}) => {
  const isDisabled = disabledIdMap.has(id);
  const isSelected = Boolean(selectedState[id]);
  const [isChecked, setIsChecked] = useState(isSelected);
  const handleCheckboxChange = () => {
    if (!isDisabled) {
      onChange({ [id]: !isChecked });
      return setIsChecked(prev => !prev);
    }
  };

  return (
    <Checkbox
      type="checkbox"
      isChecked={isChecked}
      isDisabled={isDisabled}
      onChange={() => handleCheckboxChange()}
    ></Checkbox>
  );
};
