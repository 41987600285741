import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { AvatarAppearance } from '../../definitions';

type AvatarButtonProps = {
  borderColor?: string;
  appearance: AvatarAppearance;
  isDisabled?: boolean;
  hasBorder: boolean;
};

export const Avatar = styled.button<AvatarButtonProps>`
  width: 2.5rem;
  height: 2.5rem;
  background: ${({ theme }) => theme.palettes.placeholder};
  border-radius: 100%;
  flex-basis: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0.15rem solid
    ${props =>
      props.hasBorder ? props.borderColor ?? props.theme.palettes.inverse : 'transparent'};
  z-index: 1;

  &:hover {
    background: ${({ theme }) => darken(0.15, theme.palettes.placeholder)};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ theme }) => theme.palettes.inverse};
  }

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
      &:hover {
        background: ${({ theme }) => theme.palettes.placeholder};
      }
    `}

  ${props =>
    props.appearance === AvatarAppearance.Large &&
    css`
      width: 3.5rem;
      height: 3.5rem;
      flex-basis: 3.5rem;

      svg {
        width: 2.25rem;
        height: 2.25rem;
      }
    `}

      ${props =>
    props.appearance === AvatarAppearance.Small &&
    css`
      width: 1.5rem;
      height: 1.5rem;
      flex-basis: 1.5rem;
      border: ${props.hasBorder ? '0.1rem' : 0} solid
        ${props.borderColor ?? props.theme.palettes.inverse};
      svg {
        width: 1rem;
        height: 1rem;
      }
    `}
`;
