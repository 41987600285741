import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import { Appearance, FlattenedTeam, FlattenedTeamBatch } from '../../definitions';
import { Icon } from '../../components/Icon';
import AddTeamTable from '../../tables/AddTeamTable';

type SetOwnerProps = {
  onClose: () => void;
  isOpen: boolean;
  onSetTeam: () => void;
  isFetchingTeams: boolean;
  teamList: FlattenedTeamBatch[];
  onTeamNextPage: () => void;
  onTeamPrevPage: () => void;
  numberOfTeams: number;
  isTeamPrevPageDisabled: boolean;
  isTeamNextPageDisabled: boolean;
  selectedTeamId?: string;
  selectedTeamShortname?: string;
  selectedTeamDisplayName?: string;
  currentOwnerTeam?: FlattenedTeam;
  onSelectTeam: (team: FlattenedTeamBatch) => void;
  onClearSelectedTeam: () => void;
};

export const SetOwnerView: FunctionComponent<SetOwnerProps> = ({
  children,
  onClose,
  onSetTeam,
  isFetchingTeams,
  teamList,
  onTeamNextPage,
  onTeamPrevPage,
  numberOfTeams,
  isTeamPrevPageDisabled,
  isTeamNextPageDisabled,
  selectedTeamId,
  selectedTeamShortname,
  selectedTeamDisplayName,
  currentOwnerTeam,
  onSelectTeam,
  onClearSelectedTeam,
  ...rest
}) => {
  return (
    <Modal
      onClose={onClose}
      title="Assign component owner team"
      shouldUseSmartPositioning={false}
      maxWidth="50rem"
      {...rest}
    >
      <AddTeamTable
        selectedTeamId={selectedTeamId}
        selectedTeamName={selectedTeamDisplayName || selectedTeamShortname}
        isFetching={isFetchingTeams}
        teamList={teamList}
        onNextPage={onTeamNextPage}
        onPrevPage={onTeamPrevPage}
        numberOfTeams={numberOfTeams}
        isPrevPageDisabled={isTeamPrevPageDisabled}
        isNextPageDisabled={isTeamNextPageDisabled}
        onSelectTeam={onSelectTeam}
        onClearSelectedTeam={onClearSelectedTeam}
        currentTeamId={currentOwnerTeam?.id}
        currentTeamName={currentOwnerTeam?.displayname || currentOwnerTeam?.shortname}
      />
      <Modal.Footer>
        <Modal.Action appearance={Appearance.PrimaryWithIcon} onClick={onClose}>
          <Icon.Cross />
          <span>Cancel</span>
        </Modal.Action>
        <Modal.Action
          appearance={Appearance.PrimaryWithIcon}
          isHighlighted={true}
          isDisabled={!selectedTeamId || selectedTeamId === currentOwnerTeam?.id}
          onClick={onSetTeam}
        >
          <Icon.AddTeam />
          <span>Select team</span>
        </Modal.Action>
      </Modal.Footer>
    </Modal>
  );
};
