import { FunctionComponent } from 'react';
import { Field } from 'react-final-form';
import { validation, parsers } from '../../definitions';
import { Input, FormLayout, Textarea } from '../../components/Form';

type BotFormProps = {
  isNewBot?: boolean;
  clickedSubmit?: boolean;
};

export const BotFormView: FunctionComponent<BotFormProps> = ({ isNewBot, clickedSubmit }) => {
  return (
    <FormLayout.FieldSet>
      <Field
        id="shortname"
        name="shortname"
        label="Shortname"
        component={Input}
        validate={value => validation.required(value)}
        showValidation={clickedSubmit}
        format={parsers.trim}
        formatOnBlur={true}
        disabled={!isNewBot}
        maxLength="70"
        description="The shortname is a unique identifier that will be used as part of the bot URL. It can have a maximum length of 50 characters and may only contain the following symbols: [a-z0-9], '_', and '-'. Once created it cannot be changed."
      />
      <Field
        id="description"
        name="description"
        label="Description"
        component={Textarea}
        showValidation={clickedSubmit}
        format={parsers.trim}
        formatOnBlur={true}
        isOptional={true}
        maxLength="2000"
        description="Optional bot description. Use this field to provide information about the bot purpose, permissions and any other necessary and helpful details."
      />
    </FormLayout.FieldSet>
  );
};
