import { ActionType } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const showAlert = (payload = {}): ActionType => ({
  type: actionTypes.alerts.show,
  payload,
});

export const hideAlert = (payload = {}): ActionType => ({
  type: actionTypes.alerts.hide,
  payload,
});
