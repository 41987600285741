import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { usePageTitle } from '../../hooks';
import { fetchSLOList, getSLOList, isFetchingSLOList } from '../../store';
import { paths } from '../../definitions';
import { SloListView } from './SloList.view';

export const SloListContainer: FunctionComponent = () => {
  usePageTitle('SLO list');

  const dispatch = useDispatch();
  const isFetchingSLOs = useSelector(isFetchingSLOList);
  const sloList = useSelector(getSLOList);

  const handleViewSLO = useCallback(
    sloShortName => {
      sloShortName && dispatch(push(generatePath(paths.slo, { sloShortName })));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchSLOList());
  }, [dispatch]);

  return (
    <SloListView sloList={sloList} onViewSLO={handleViewSLO} isFetchingSLOs={isFetchingSLOs} />
  );
};
