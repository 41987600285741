import { TagValue } from '../../grpc/grpcweb/tag_pb';
import { ActionType, TagFormData, FormStateType, Tag } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const createTag = (payload: TagFormData): ActionType => ({
  type: actionTypes.tags.create,
  payload,
});

export const updateNewTag = (payload: FormStateType | TagFormData): ActionType => ({
  type: actionTypes.tags.updateNew,
  payload,
});

export const updateNewTagValues = (payload: TagValue.AsObject[]): ActionType => ({
  type: actionTypes.tags.updateNewValues,
  payload,
});

export const resetNewTag = (payload = {}): ActionType => ({
  type: actionTypes.tags.resetNew,
  payload,
});

export const fetchTagByKey = (tagKey: string): ActionType => ({
  type: actionTypes.tags.current.fetchByKey,
  payload: tagKey,
});

export const fetchTagByKeyAndValue = (payload: { keyId: string; valueId: string }): ActionType => ({
  type: actionTypes.tags.current.fetchByKeyAndValue,
  payload,
});

export const fetchTag = (tagId: string): ActionType => ({
  type: actionTypes.tags.current.fetch,
  payload: tagId,
});

export const fetchAllTagsForSearch = (pageSize?: number): ActionType => ({
  type: actionTypes.tags.fetchAll,
  payload: pageSize,
});

export const fetchTagList = (pageSize?: number): ActionType => ({
  type: actionTypes.tags.fetchList,
  payload: pageSize,
});

export const fetchNextTagListPage = (pageSize?: number): ActionType => ({
  type: actionTypes.tags.fetchNextPage,
  payload: pageSize,
});

export const fetchPrevTagListPage = (pageSize?: number): ActionType => ({
  type: actionTypes.tags.fetchPrevPage,
  payload: pageSize,
});

export const updateTag = (payload: { formData: TagFormData; tagId?: string }): ActionType => ({
  type: actionTypes.tags.current.update,
  payload,
});

export const addValueToTag = (payload: { value: string; tagId?: string }): ActionType => ({
  type: actionTypes.tags.current.values.create,
  payload,
});

export const updateTagValue = (payload: {
  value: TagValue.AsObject;
  tagId?: string;
}): ActionType => ({
  type: actionTypes.tags.current.values.update,
  payload,
});

export const deleteTagValue = (payload: {
  value: TagValue.AsObject;
  tagId?: string;
}): ActionType => ({
  type: actionTypes.tags.current.values.delete,
  payload,
});

export const saveSelectedTag = (payload: Tag): ActionType => ({
  type: actionTypes.tags.current.select,
  payload,
});

export const clearSelectedTag = (payload = {}): ActionType => ({
  type: actionTypes.tags.current.clear,
  payload,
});

export const deleteTag = (payload: { tagKey: string; tagId?: string }): ActionType => ({
  type: actionTypes.tags.current.delete,
  payload,
});

export const linkTagToComponent = (payload: {
  valueId?: string;
  tagId?: string;
  componentId: string;
}): ActionType => ({
  type: actionTypes.tags.components.link,
  payload,
});

export const unlinkTagFromComponent = (payload: {
  tagId: string;
  componentId: string;
  valueId: string;
}): ActionType => ({
  type: actionTypes.tags.components.unlink,
  payload,
});
