import { grpc } from '@improbable-eng/grpc-web';
import { UserService as UsersService } from '../grpc/grpcweb/user_pb_service';
import {
  GetUserConfigRequest,
  GetUserConfigResponse,
  ListUsersRequest,
  ListUsersResponse,
} from '../grpc/grpcweb/user_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class UserService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  fetchList = async (RctToken: string, listUsersRequest: ListUsersRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(UsersService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListUsersResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch user list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: listUsersRequest,
      });
    });
  };

  fetchUser = async (RctToken: string) => {
    const fetchUserRequest = new GetUserConfigRequest();

    return new Promise((resolve, reject) => {
      grpc.unary(UsersService.GetUserConfig, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetUserConfigResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch user details failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: fetchUserRequest,
      });
    });
  };
}
