import { FunctionComponent, useCallback, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { usePageTitle } from '../../hooks';
import {
  fetchTeamByShortname,
  getSelectedTeam,
  isFetchingTeam,
  isRemovingUserFromTheTeam,
  removeUserFromTheTeam,
  getSelectedTeamId,
} from '../../store';
import { TeamView } from './Team.view';
import { FlattenedTeamBatch } from '../../definitions';

type TeamContainerProps = RouteChildrenProps;

export const TeamContainer: FunctionComponent<TeamContainerProps> = ({ match }) => {
  usePageTitle('Team');
  const dispatch = useDispatch();

  const isFetching = useSelector(isFetchingTeam);
  const isRemovingUser = useSelector(isRemovingUserFromTheTeam);

  const team = useSelector(getSelectedTeam);
  const teamId = useSelector(getSelectedTeamId);

  const { teamShortname } = match?.params as {
    teamShortname: FlattenedTeamBatch['shortname'];
  };

  useEffect(() => {
    teamShortname && dispatch(fetchTeamByShortname(teamShortname));
  }, [dispatch, teamShortname]);

  const handleRemoveUser = useCallback(
    userId => {
      teamId && dispatch(removeUserFromTheTeam({ userId, teamId }));
    },
    [dispatch, teamId],
  );

  return (
    <TeamView
      isFetchingTeam={isFetching}
      team={team}
      onRemoveUser={handleRemoveUser}
      isRemovingUser={isRemovingUser}
    />
  );
};
