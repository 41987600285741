// package: rct.web
// file: grpcweb/slo.proto

var grpcweb_slo_pb = require("../grpcweb/slo_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var SloService = (function () {
  function SloService() {}
  SloService.serviceName = "rct.web.SloService";
  return SloService;
}());

SloService.Create = {
  methodName: "Create",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.CreateSloRequest,
  responseType: grpcweb_slo_pb.CreateSloResponse
};

SloService.List = {
  methodName: "List",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.ListSloRequest,
  responseType: grpcweb_slo_pb.ListSloResponse
};

SloService.Get = {
  methodName: "Get",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.GetSloRequest,
  responseType: grpcweb_slo_pb.GetSloResponse
};

SloService.GetHistory = {
  methodName: "GetHistory",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.GetSloHistoryRequest,
  responseType: grpcweb_slo_pb.GetSloHistoryResponse
};

SloService.Delete = {
  methodName: "Delete",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.DeleteSloRequest,
  responseType: grpcweb_slo_pb.DeleteSloResponse
};

SloService.Update = {
  methodName: "Update",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.UpdateSloRequest,
  responseType: grpcweb_slo_pb.UpdateSloResponse
};

SloService.Launch = {
  methodName: "Launch",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.LaunchSloRequest,
  responseType: grpcweb_slo_pb.LaunchSloResponse
};

SloService.ListActivities = {
  methodName: "ListActivities",
  service: SloService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_slo_pb.ListActivitiesRequest,
  responseType: grpcweb_slo_pb.ListActivitiesResponse
};

exports.SloService = SloService;

function SloServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SloServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SloServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SloServiceClient.prototype.get = function get(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.Get, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SloServiceClient.prototype.getHistory = function getHistory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.GetHistory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SloServiceClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SloServiceClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SloServiceClient.prototype.launch = function launch(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.Launch, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SloServiceClient.prototype.listActivities = function listActivities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SloService.ListActivities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.SloServiceClient = SloServiceClient;

