import { ErrorMessages } from '../../definitions';
import { RootState } from '../reducers';

export const getErrorMessage = (state: RootState, actionType: string): string => {
  return state.errors[actionType]?.message || '';
};

export const getErrorDetails = (state: RootState, actionType: string): string => {
  return state.errors[actionType]?.details || '';
};

export const getFieldErrorMessages = (state: RootState, actionType: string): ErrorMessages => {
  return state.errors[actionType]?.fields || {};
};

export const getErrorType = (state: RootState, actionType: string): string => {
  return state.errors[actionType]?.type || '';
};
