// source: grpcweb/bot.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var grpccommon_common_pb = require('../grpccommon/common_pb.js');
goog.object.extend(proto, grpccommon_common_pb);
goog.exportSymbol('proto.rct.web.ApiToken', null, global);
goog.exportSymbol('proto.rct.web.Bot', null, global);
goog.exportSymbol('proto.rct.web.BotMeta', null, global);
goog.exportSymbol('proto.rct.web.CreateBotRequest', null, global);
goog.exportSymbol('proto.rct.web.CreateBotResponse', null, global);
goog.exportSymbol('proto.rct.web.CreateTokenRequest', null, global);
goog.exportSymbol('proto.rct.web.CreateTokenResponse', null, global);
goog.exportSymbol('proto.rct.web.DeleteBotRequest', null, global);
goog.exportSymbol('proto.rct.web.DeleteBotResponse', null, global);
goog.exportSymbol('proto.rct.web.DeleteTokenRequest', null, global);
goog.exportSymbol('proto.rct.web.DeleteTokenResponse', null, global);
goog.exportSymbol('proto.rct.web.GetBotByIdRequest', null, global);
goog.exportSymbol('proto.rct.web.GetBotByIdResponse', null, global);
goog.exportSymbol('proto.rct.web.GetBotByShortNameRequest', null, global);
goog.exportSymbol('proto.rct.web.GetBotByShortNameResponse', null, global);
goog.exportSymbol('proto.rct.web.ListBotRequest', null, global);
goog.exportSymbol('proto.rct.web.ListBotResponse', null, global);
goog.exportSymbol('proto.rct.web.UpdateBotRequest', null, global);
goog.exportSymbol('proto.rct.web.UpdateBotResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ApiToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ApiToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ApiToken.displayName = 'proto.rct.web.ApiToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.Bot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.Bot.repeatedFields_, null);
};
goog.inherits(proto.rct.web.Bot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.Bot.displayName = 'proto.rct.web.Bot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.BotMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.BotMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.BotMeta.displayName = 'proto.rct.web.BotMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateBotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateBotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateBotRequest.displayName = 'proto.rct.web.CreateBotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateBotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateBotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateBotResponse.displayName = 'proto.rct.web.CreateBotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteBotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteBotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteBotRequest.displayName = 'proto.rct.web.DeleteBotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteBotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteBotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteBotResponse.displayName = 'proto.rct.web.DeleteBotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateBotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateBotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateBotRequest.displayName = 'proto.rct.web.UpdateBotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.UpdateBotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.UpdateBotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.UpdateBotResponse.displayName = 'proto.rct.web.UpdateBotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListBotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListBotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListBotRequest.displayName = 'proto.rct.web.ListBotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListBotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ListBotResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ListBotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListBotResponse.displayName = 'proto.rct.web.ListBotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateTokenRequest.displayName = 'proto.rct.web.CreateTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateTokenResponse.displayName = 'proto.rct.web.CreateTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteTokenRequest.displayName = 'proto.rct.web.DeleteTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.DeleteTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.DeleteTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.DeleteTokenResponse.displayName = 'proto.rct.web.DeleteTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetBotByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetBotByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetBotByIdRequest.displayName = 'proto.rct.web.GetBotByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetBotByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetBotByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetBotByIdResponse.displayName = 'proto.rct.web.GetBotByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetBotByShortNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetBotByShortNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetBotByShortNameRequest.displayName = 'proto.rct.web.GetBotByShortNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.GetBotByShortNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.GetBotByShortNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.GetBotByShortNameResponse.displayName = 'proto.rct.web.GetBotByShortNameResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ApiToken.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ApiToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ApiToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ApiToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createmeta: (f = msg.getCreatemeta()) && grpccommon_common_pb.CreateMeta.toObject(includeInstance, f),
    updatemeta: (f = msg.getUpdatemeta()) && grpccommon_common_pb.UpdateMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ApiToken}
 */
proto.rct.web.ApiToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ApiToken;
  return proto.rct.web.ApiToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ApiToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ApiToken}
 */
proto.rct.web.ApiToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new grpccommon_common_pb.CreateMeta;
      reader.readMessage(value,grpccommon_common_pb.CreateMeta.deserializeBinaryFromReader);
      msg.setCreatemeta(value);
      break;
    case 5:
      var value = new grpccommon_common_pb.UpdateMeta;
      reader.readMessage(value,grpccommon_common_pb.UpdateMeta.deserializeBinaryFromReader);
      msg.setUpdatemeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ApiToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ApiToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ApiToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ApiToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatemeta();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      grpccommon_common_pb.CreateMeta.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      grpccommon_common_pb.UpdateMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.ApiToken.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ApiToken} returns this
 */
proto.rct.web.ApiToken.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional rct.common.CreateMeta createMeta = 4;
 * @return {?proto.rct.common.CreateMeta}
 */
proto.rct.web.ApiToken.prototype.getCreatemeta = function() {
  return /** @type{?proto.rct.common.CreateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.CreateMeta, 4));
};


/**
 * @param {?proto.rct.common.CreateMeta|undefined} value
 * @return {!proto.rct.web.ApiToken} returns this
*/
proto.rct.web.ApiToken.prototype.setCreatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ApiToken} returns this
 */
proto.rct.web.ApiToken.prototype.clearCreatemeta = function() {
  return this.setCreatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ApiToken.prototype.hasCreatemeta = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional rct.common.UpdateMeta updateMeta = 5;
 * @return {?proto.rct.common.UpdateMeta}
 */
proto.rct.web.ApiToken.prototype.getUpdatemeta = function() {
  return /** @type{?proto.rct.common.UpdateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.UpdateMeta, 5));
};


/**
 * @param {?proto.rct.common.UpdateMeta|undefined} value
 * @return {!proto.rct.web.ApiToken} returns this
*/
proto.rct.web.ApiToken.prototype.setUpdatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ApiToken} returns this
 */
proto.rct.web.ApiToken.prototype.clearUpdatemeta = function() {
  return this.setUpdatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ApiToken.prototype.hasUpdatemeta = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.Bot.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.Bot.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.Bot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.Bot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Bot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meta: (f = msg.getMeta()) && proto.rct.web.BotMeta.toObject(includeInstance, f),
    tokensList: jspb.Message.toObjectList(msg.getTokensList(),
    proto.rct.web.ApiToken.toObject, includeInstance),
    createmeta: (f = msg.getCreatemeta()) && grpccommon_common_pb.CreateMeta.toObject(includeInstance, f),
    updatemeta: (f = msg.getUpdatemeta()) && grpccommon_common_pb.UpdateMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.Bot}
 */
proto.rct.web.Bot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.Bot;
  return proto.rct.web.Bot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.Bot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.Bot}
 */
proto.rct.web.Bot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.BotMeta;
      reader.readMessage(value,proto.rct.web.BotMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 3:
      var value = new proto.rct.web.ApiToken;
      reader.readMessage(value,proto.rct.web.ApiToken.deserializeBinaryFromReader);
      msg.addTokens(value);
      break;
    case 4:
      var value = new grpccommon_common_pb.CreateMeta;
      reader.readMessage(value,grpccommon_common_pb.CreateMeta.deserializeBinaryFromReader);
      msg.setCreatemeta(value);
      break;
    case 5:
      var value = new grpccommon_common_pb.UpdateMeta;
      reader.readMessage(value,grpccommon_common_pb.UpdateMeta.deserializeBinaryFromReader);
      msg.setUpdatemeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.Bot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.Bot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.Bot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.Bot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.BotMeta.serializeBinaryToWriter
    );
  }
  f = message.getTokensList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.rct.web.ApiToken.serializeBinaryToWriter
    );
  }
  f = message.getCreatemeta();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      grpccommon_common_pb.CreateMeta.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemeta();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      grpccommon_common_pb.UpdateMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.Bot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.Bot} returns this
 */
proto.rct.web.Bot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BotMeta meta = 2;
 * @return {?proto.rct.web.BotMeta}
 */
proto.rct.web.Bot.prototype.getMeta = function() {
  return /** @type{?proto.rct.web.BotMeta} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.BotMeta, 2));
};


/**
 * @param {?proto.rct.web.BotMeta|undefined} value
 * @return {!proto.rct.web.Bot} returns this
*/
proto.rct.web.Bot.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Bot} returns this
 */
proto.rct.web.Bot.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Bot.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ApiToken tokens = 3;
 * @return {!Array<!proto.rct.web.ApiToken>}
 */
proto.rct.web.Bot.prototype.getTokensList = function() {
  return /** @type{!Array<!proto.rct.web.ApiToken>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.ApiToken, 3));
};


/**
 * @param {!Array<!proto.rct.web.ApiToken>} value
 * @return {!proto.rct.web.Bot} returns this
*/
proto.rct.web.Bot.prototype.setTokensList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.rct.web.ApiToken=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.ApiToken}
 */
proto.rct.web.Bot.prototype.addTokens = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.rct.web.ApiToken, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.Bot} returns this
 */
proto.rct.web.Bot.prototype.clearTokensList = function() {
  return this.setTokensList([]);
};


/**
 * optional rct.common.CreateMeta createMeta = 4;
 * @return {?proto.rct.common.CreateMeta}
 */
proto.rct.web.Bot.prototype.getCreatemeta = function() {
  return /** @type{?proto.rct.common.CreateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.CreateMeta, 4));
};


/**
 * @param {?proto.rct.common.CreateMeta|undefined} value
 * @return {!proto.rct.web.Bot} returns this
*/
proto.rct.web.Bot.prototype.setCreatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Bot} returns this
 */
proto.rct.web.Bot.prototype.clearCreatemeta = function() {
  return this.setCreatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Bot.prototype.hasCreatemeta = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional rct.common.UpdateMeta updateMeta = 5;
 * @return {?proto.rct.common.UpdateMeta}
 */
proto.rct.web.Bot.prototype.getUpdatemeta = function() {
  return /** @type{?proto.rct.common.UpdateMeta} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.UpdateMeta, 5));
};


/**
 * @param {?proto.rct.common.UpdateMeta|undefined} value
 * @return {!proto.rct.web.Bot} returns this
*/
proto.rct.web.Bot.prototype.setUpdatemeta = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.Bot} returns this
 */
proto.rct.web.Bot.prototype.clearUpdatemeta = function() {
  return this.setUpdatemeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.Bot.prototype.hasUpdatemeta = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.BotMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.BotMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.BotMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.BotMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: (f = msg.getDescription()) && grpccommon_common_pb.TextItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.BotMeta}
 */
proto.rct.web.BotMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.BotMeta;
  return proto.rct.web.BotMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.BotMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.BotMeta}
 */
proto.rct.web.BotMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    case 2:
      var value = new grpccommon_common_pb.TextItem;
      reader.readMessage(value,grpccommon_common_pb.TextItem.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.BotMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.BotMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.BotMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.BotMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpccommon_common_pb.TextItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string shortName = 1;
 * @return {string}
 */
proto.rct.web.BotMeta.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.BotMeta} returns this
 */
proto.rct.web.BotMeta.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional rct.common.TextItem description = 2;
 * @return {?proto.rct.common.TextItem}
 */
proto.rct.web.BotMeta.prototype.getDescription = function() {
  return /** @type{?proto.rct.common.TextItem} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.TextItem, 2));
};


/**
 * @param {?proto.rct.common.TextItem|undefined} value
 * @return {!proto.rct.web.BotMeta} returns this
*/
proto.rct.web.BotMeta.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.BotMeta} returns this
 */
proto.rct.web.BotMeta.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.BotMeta.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateBotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateBotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateBotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateBotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && proto.rct.web.BotMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateBotRequest}
 */
proto.rct.web.CreateBotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateBotRequest;
  return proto.rct.web.CreateBotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateBotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateBotRequest}
 */
proto.rct.web.CreateBotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.BotMeta;
      reader.readMessage(value,proto.rct.web.BotMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateBotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateBotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateBotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateBotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.BotMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional BotMeta meta = 1;
 * @return {?proto.rct.web.BotMeta}
 */
proto.rct.web.CreateBotRequest.prototype.getMeta = function() {
  return /** @type{?proto.rct.web.BotMeta} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.BotMeta, 1));
};


/**
 * @param {?proto.rct.web.BotMeta|undefined} value
 * @return {!proto.rct.web.CreateBotRequest} returns this
*/
proto.rct.web.CreateBotRequest.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateBotRequest} returns this
 */
proto.rct.web.CreateBotRequest.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateBotRequest.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateBotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateBotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateBotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateBotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bot: (f = msg.getBot()) && proto.rct.web.Bot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateBotResponse}
 */
proto.rct.web.CreateBotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateBotResponse;
  return proto.rct.web.CreateBotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateBotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateBotResponse}
 */
proto.rct.web.CreateBotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Bot;
      reader.readMessage(value,proto.rct.web.Bot.deserializeBinaryFromReader);
      msg.setBot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateBotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateBotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateBotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateBotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.Bot.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bot bot = 1;
 * @return {?proto.rct.web.Bot}
 */
proto.rct.web.CreateBotResponse.prototype.getBot = function() {
  return /** @type{?proto.rct.web.Bot} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Bot, 1));
};


/**
 * @param {?proto.rct.web.Bot|undefined} value
 * @return {!proto.rct.web.CreateBotResponse} returns this
*/
proto.rct.web.CreateBotResponse.prototype.setBot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateBotResponse} returns this
 */
proto.rct.web.CreateBotResponse.prototype.clearBot = function() {
  return this.setBot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateBotResponse.prototype.hasBot = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteBotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteBotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteBotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteBotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    botid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteBotRequest}
 */
proto.rct.web.DeleteBotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteBotRequest;
  return proto.rct.web.DeleteBotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteBotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteBotRequest}
 */
proto.rct.web.DeleteBotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBotid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteBotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteBotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteBotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteBotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBotid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string botId = 1;
 * @return {string}
 */
proto.rct.web.DeleteBotRequest.prototype.getBotid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.DeleteBotRequest} returns this
 */
proto.rct.web.DeleteBotRequest.prototype.setBotid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteBotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteBotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteBotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteBotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bot: (f = msg.getBot()) && proto.rct.web.Bot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteBotResponse}
 */
proto.rct.web.DeleteBotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteBotResponse;
  return proto.rct.web.DeleteBotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteBotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteBotResponse}
 */
proto.rct.web.DeleteBotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Bot;
      reader.readMessage(value,proto.rct.web.Bot.deserializeBinaryFromReader);
      msg.setBot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteBotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteBotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteBotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteBotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.Bot.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bot bot = 1;
 * @return {?proto.rct.web.Bot}
 */
proto.rct.web.DeleteBotResponse.prototype.getBot = function() {
  return /** @type{?proto.rct.web.Bot} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Bot, 1));
};


/**
 * @param {?proto.rct.web.Bot|undefined} value
 * @return {!proto.rct.web.DeleteBotResponse} returns this
*/
proto.rct.web.DeleteBotResponse.prototype.setBot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.DeleteBotResponse} returns this
 */
proto.rct.web.DeleteBotResponse.prototype.clearBot = function() {
  return this.setBot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.DeleteBotResponse.prototype.hasBot = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateBotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateBotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateBotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateBotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    botid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: (f = msg.getDescription()) && grpccommon_common_pb.TextItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateBotRequest}
 */
proto.rct.web.UpdateBotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateBotRequest;
  return proto.rct.web.UpdateBotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateBotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateBotRequest}
 */
proto.rct.web.UpdateBotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBotid(value);
      break;
    case 2:
      var value = new grpccommon_common_pb.TextItem;
      reader.readMessage(value,grpccommon_common_pb.TextItem.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateBotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateBotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateBotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateBotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBotid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpccommon_common_pb.TextItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string botId = 1;
 * @return {string}
 */
proto.rct.web.UpdateBotRequest.prototype.getBotid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.UpdateBotRequest} returns this
 */
proto.rct.web.UpdateBotRequest.prototype.setBotid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional rct.common.TextItem description = 2;
 * @return {?proto.rct.common.TextItem}
 */
proto.rct.web.UpdateBotRequest.prototype.getDescription = function() {
  return /** @type{?proto.rct.common.TextItem} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.TextItem, 2));
};


/**
 * @param {?proto.rct.common.TextItem|undefined} value
 * @return {!proto.rct.web.UpdateBotRequest} returns this
*/
proto.rct.web.UpdateBotRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateBotRequest} returns this
 */
proto.rct.web.UpdateBotRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateBotRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.UpdateBotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.UpdateBotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.UpdateBotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateBotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bot: (f = msg.getBot()) && proto.rct.web.Bot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.UpdateBotResponse}
 */
proto.rct.web.UpdateBotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.UpdateBotResponse;
  return proto.rct.web.UpdateBotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.UpdateBotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.UpdateBotResponse}
 */
proto.rct.web.UpdateBotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Bot;
      reader.readMessage(value,proto.rct.web.Bot.deserializeBinaryFromReader);
      msg.setBot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.UpdateBotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.UpdateBotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.UpdateBotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.UpdateBotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.Bot.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bot bot = 1;
 * @return {?proto.rct.web.Bot}
 */
proto.rct.web.UpdateBotResponse.prototype.getBot = function() {
  return /** @type{?proto.rct.web.Bot} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Bot, 1));
};


/**
 * @param {?proto.rct.web.Bot|undefined} value
 * @return {!proto.rct.web.UpdateBotResponse} returns this
*/
proto.rct.web.UpdateBotResponse.prototype.setBot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.UpdateBotResponse} returns this
 */
proto.rct.web.UpdateBotResponse.prototype.clearBot = function() {
  return this.setBot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.UpdateBotResponse.prototype.hasBot = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListBotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListBotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListBotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListBotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListBotRequest}
 */
proto.rct.web.ListBotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListBotRequest;
  return proto.rct.web.ListBotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListBotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListBotRequest}
 */
proto.rct.web.ListBotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListBotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListBotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListBotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListBotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ListBotResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListBotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListBotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListBotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListBotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    botsList: jspb.Message.toObjectList(msg.getBotsList(),
    proto.rct.web.Bot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListBotResponse}
 */
proto.rct.web.ListBotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListBotResponse;
  return proto.rct.web.ListBotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListBotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListBotResponse}
 */
proto.rct.web.ListBotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Bot;
      reader.readMessage(value,proto.rct.web.Bot.deserializeBinaryFromReader);
      msg.addBots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListBotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListBotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListBotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListBotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.Bot.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Bot bots = 1;
 * @return {!Array<!proto.rct.web.Bot>}
 */
proto.rct.web.ListBotResponse.prototype.getBotsList = function() {
  return /** @type{!Array<!proto.rct.web.Bot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.Bot, 1));
};


/**
 * @param {!Array<!proto.rct.web.Bot>} value
 * @return {!proto.rct.web.ListBotResponse} returns this
*/
proto.rct.web.ListBotResponse.prototype.setBotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.Bot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.Bot}
 */
proto.rct.web.ListBotResponse.prototype.addBots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.Bot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ListBotResponse} returns this
 */
proto.rct.web.ListBotResponse.prototype.clearBotsList = function() {
  return this.setBotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    botid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateTokenRequest}
 */
proto.rct.web.CreateTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateTokenRequest;
  return proto.rct.web.CreateTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateTokenRequest}
 */
proto.rct.web.CreateTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBotid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBotid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string botId = 1;
 * @return {string}
 */
proto.rct.web.CreateTokenRequest.prototype.getBotid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CreateTokenRequest} returns this
 */
proto.rct.web.CreateTokenRequest.prototype.setBotid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fulltoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: (f = msg.getToken()) && proto.rct.web.ApiToken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateTokenResponse}
 */
proto.rct.web.CreateTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateTokenResponse;
  return proto.rct.web.CreateTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateTokenResponse}
 */
proto.rct.web.CreateTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFulltoken(value);
      break;
    case 2:
      var value = new proto.rct.web.ApiToken;
      reader.readMessage(value,proto.rct.web.ApiToken.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFulltoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.ApiToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fullToken = 1;
 * @return {string}
 */
proto.rct.web.CreateTokenResponse.prototype.getFulltoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CreateTokenResponse} returns this
 */
proto.rct.web.CreateTokenResponse.prototype.setFulltoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ApiToken token = 2;
 * @return {?proto.rct.web.ApiToken}
 */
proto.rct.web.CreateTokenResponse.prototype.getToken = function() {
  return /** @type{?proto.rct.web.ApiToken} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ApiToken, 2));
};


/**
 * @param {?proto.rct.web.ApiToken|undefined} value
 * @return {!proto.rct.web.CreateTokenResponse} returns this
*/
proto.rct.web.CreateTokenResponse.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateTokenResponse} returns this
 */
proto.rct.web.CreateTokenResponse.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateTokenResponse.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    botid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tokenid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteTokenRequest}
 */
proto.rct.web.DeleteTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteTokenRequest;
  return proto.rct.web.DeleteTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteTokenRequest}
 */
proto.rct.web.DeleteTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBotid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBotid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTokenid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string botId = 1;
 * @return {string}
 */
proto.rct.web.DeleteTokenRequest.prototype.getBotid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.DeleteTokenRequest} returns this
 */
proto.rct.web.DeleteTokenRequest.prototype.setBotid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tokenId = 2;
 * @return {string}
 */
proto.rct.web.DeleteTokenRequest.prototype.getTokenid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.DeleteTokenRequest} returns this
 */
proto.rct.web.DeleteTokenRequest.prototype.setTokenid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.DeleteTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.DeleteTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.DeleteTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: (f = msg.getToken()) && proto.rct.web.ApiToken.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.DeleteTokenResponse}
 */
proto.rct.web.DeleteTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.DeleteTokenResponse;
  return proto.rct.web.DeleteTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.DeleteTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.DeleteTokenResponse}
 */
proto.rct.web.DeleteTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.ApiToken;
      reader.readMessage(value,proto.rct.web.ApiToken.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.DeleteTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.DeleteTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.DeleteTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.DeleteTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.ApiToken.serializeBinaryToWriter
    );
  }
};


/**
 * optional ApiToken token = 1;
 * @return {?proto.rct.web.ApiToken}
 */
proto.rct.web.DeleteTokenResponse.prototype.getToken = function() {
  return /** @type{?proto.rct.web.ApiToken} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.ApiToken, 1));
};


/**
 * @param {?proto.rct.web.ApiToken|undefined} value
 * @return {!proto.rct.web.DeleteTokenResponse} returns this
*/
proto.rct.web.DeleteTokenResponse.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.DeleteTokenResponse} returns this
 */
proto.rct.web.DeleteTokenResponse.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.DeleteTokenResponse.prototype.hasToken = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetBotByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetBotByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetBotByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    botid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetBotByIdRequest}
 */
proto.rct.web.GetBotByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetBotByIdRequest;
  return proto.rct.web.GetBotByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetBotByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetBotByIdRequest}
 */
proto.rct.web.GetBotByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBotid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetBotByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetBotByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetBotByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBotid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string botId = 1;
 * @return {string}
 */
proto.rct.web.GetBotByIdRequest.prototype.getBotid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetBotByIdRequest} returns this
 */
proto.rct.web.GetBotByIdRequest.prototype.setBotid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetBotByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetBotByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetBotByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bot: (f = msg.getBot()) && proto.rct.web.Bot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetBotByIdResponse}
 */
proto.rct.web.GetBotByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetBotByIdResponse;
  return proto.rct.web.GetBotByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetBotByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetBotByIdResponse}
 */
proto.rct.web.GetBotByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Bot;
      reader.readMessage(value,proto.rct.web.Bot.deserializeBinaryFromReader);
      msg.setBot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetBotByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetBotByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetBotByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.Bot.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bot bot = 1;
 * @return {?proto.rct.web.Bot}
 */
proto.rct.web.GetBotByIdResponse.prototype.getBot = function() {
  return /** @type{?proto.rct.web.Bot} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Bot, 1));
};


/**
 * @param {?proto.rct.web.Bot|undefined} value
 * @return {!proto.rct.web.GetBotByIdResponse} returns this
*/
proto.rct.web.GetBotByIdResponse.prototype.setBot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetBotByIdResponse} returns this
 */
proto.rct.web.GetBotByIdResponse.prototype.clearBot = function() {
  return this.setBot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetBotByIdResponse.prototype.hasBot = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetBotByShortNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetBotByShortNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetBotByShortNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByShortNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetBotByShortNameRequest}
 */
proto.rct.web.GetBotByShortNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetBotByShortNameRequest;
  return proto.rct.web.GetBotByShortNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetBotByShortNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetBotByShortNameRequest}
 */
proto.rct.web.GetBotByShortNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetBotByShortNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetBotByShortNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetBotByShortNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByShortNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string shortName = 1;
 * @return {string}
 */
proto.rct.web.GetBotByShortNameRequest.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.GetBotByShortNameRequest} returns this
 */
proto.rct.web.GetBotByShortNameRequest.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.GetBotByShortNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.GetBotByShortNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.GetBotByShortNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByShortNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bot: (f = msg.getBot()) && proto.rct.web.Bot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.GetBotByShortNameResponse}
 */
proto.rct.web.GetBotByShortNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.GetBotByShortNameResponse;
  return proto.rct.web.GetBotByShortNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.GetBotByShortNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.GetBotByShortNameResponse}
 */
proto.rct.web.GetBotByShortNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.Bot;
      reader.readMessage(value,proto.rct.web.Bot.deserializeBinaryFromReader);
      msg.setBot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.GetBotByShortNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.GetBotByShortNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.GetBotByShortNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.GetBotByShortNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.Bot.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bot bot = 1;
 * @return {?proto.rct.web.Bot}
 */
proto.rct.web.GetBotByShortNameResponse.prototype.getBot = function() {
  return /** @type{?proto.rct.web.Bot} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.Bot, 1));
};


/**
 * @param {?proto.rct.web.Bot|undefined} value
 * @return {!proto.rct.web.GetBotByShortNameResponse} returns this
*/
proto.rct.web.GetBotByShortNameResponse.prototype.setBot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.GetBotByShortNameResponse} returns this
 */
proto.rct.web.GetBotByShortNameResponse.prototype.clearBot = function() {
  return this.setBot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.GetBotByShortNameResponse.prototype.hasBot = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.rct.web);
