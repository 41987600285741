import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import { TagView } from '../../grpc/grpcweb/tag_pb';
import actionTypes from '../constants/actionTypes';
import {
  SloComponent,
  CommentData,
  FlattenedTeam,
  ComponentRefInTheList,
  CreateComponentFormData,
  Link,
  ComponentInTheList,
  XEventInTheList,
} from '../../definitions';
import { isLoading } from './loading';
import { RootState } from '../reducers';
import { getErrorMessage } from './errors';
import { wasSuccessful } from './success';

export const getSelectedComponent = (state: RootState): SloComponent => {
  return state.components.selected;
};

export const getSavedNewComponent = (state: RootState): CreateComponentFormData => {
  return state.components.new;
};

export const getSelectedComponentId = (state: RootState): SloComponent['id'] => {
  return state.components.selected.id;
};

export const getSelectedComponentType = (state: RootState): SloComponent['type'] => {
  return state.components.selected.type;
};

export const getSelectedComponentDisplayName = (state: RootState): SloComponent['displayName'] => {
  return state.components.selected.displayName;
};

export const getSelectedComponentShortname = (state: RootState): SloComponent['shortname'] => {
  return state.components.selected.shortname;
};

export const getSelectedComponentDescription = (state: RootState): SloComponent['description'] => {
  return state.components.selected.description;
};

export const getSelectedComponentOwnerTeam = (state: RootState): FlattenedTeam | undefined => {
  return state.components.selected.ownerTeam;
};

export const getSelectedComponentTags = (state: RootState): TagView.AsObject[] => {
  return state.components.selected.tags;
};

export const isFetchingComponentByShortname = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.fetchByShortname);
};

export const getFetchComponentByShortnameError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.fetchByShortname);
};

export const getFetchComponentByIdError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.fetchById);
};

// export const getSLOListForComponent = (state: RootState): SloFormData => {
//   return state.components.selected.slo;
// };

export const isCreatingComponent = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.create);
};

export const isDeletingComponent = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.delete);
};

export const getCreateComponentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.create);
};

export const getDeleteComponentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.delete);
};

export const createdComponentSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.create);
};

export const deletedComponentSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.current.delete);
};

export const isEditingComponent = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.edit);
};

export const getEditComponentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.edit);
};

export const editedComponentSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.current.edit);
};

// SLOS //

export const getSLOListForComponent = (state: RootState): SloComponent['sloList'] => {
  return state.components.selected.sloList;
};

export const isFetchingSLOListforComponent = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.fetchSLOs);
};

export const getFetchSLOListforComponentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.fetchSLOs);
};

// TYPES //

export const getComponentTypes = (state: RootState): CatalogItemView.AsObject[] => {
  return state.components.types;
};

export const isFetchingComponentTypes = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.fetchTypes);
};

export const getFetchComponentTypesError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.fetchTypes);
};

// COMMENTS //

export const getComponentComments = (state: RootState): CommentData[] => {
  return state.components.selected.comments;
};

export const isFetchingComponentComments = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.comments.fetch);
};

export const getFetchComponentCommentsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.comments.fetch);
};

export const isAddingComponentComment = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.comments.fetch);
};

export const getAddComponentCommentError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.comments.add);
};

// DEPENDENCIES //

export const getSelectedComponentDependencyIds = (state: RootState): Set<string> => {
  const dependencies = state.components.selected.dependencies;
  const dependencyIds: Set<string> = new Set();
  dependencies.forEach((dependency: ComponentRefInTheList) => dependencyIds.add(dependency.id));
  return dependencyIds;
};

export const getSelectedComponentDependentIds = (state: RootState): Set<string> => {
  const dependencies = state.components.selected.dependents;
  const dependencyIds: Set<string> = new Set();
  dependencies.forEach((dependency: ComponentRefInTheList) => dependencyIds.add(dependency.id));
  return dependencyIds;
};

export const getSelectedDependencyState = (state: RootState): Record<string, boolean> => {
  return state.components.selected.selectedDependencyState;
};

export const getNumberOfSelectedDependencies = (state: RootState): number => {
  const componentIds = Object.keys(state.components.selected.selectedDependencyState).filter(
    (componentId: string) =>
      state.components.selected.selectedDependencyState[componentId] === true,
  );
  return componentIds.length;
};

export const isFetchingComponentDependencies = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.dependencies.fetch);
};

export const getFetchComponentDependenciesError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.dependencies.fetch);
};

export const getComponentDependencies = (state: RootState): ComponentRefInTheList[] => {
  return state.components.selected.dependencies;
};

export const getComponentDependents = (state: RootState): ComponentRefInTheList[] => {
  return state.components.selected.dependents;
};

export const isLinkingComponentDependencies = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.dependencies.linkDependencies);
};

export const isLinkingComponentDependents = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.dependencies.linkDependents);
};

export const isUnlinkingComponentDependency = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.dependencies.unlinkDependency);
};

export const isUnlinkingComponentDependant = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.dependencies.unlinkDependant);
};

export const linkedDependenciesSuccessFully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.current.dependencies.linkDependencies);
};

export const linkedDependentsSuccessFully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.current.dependencies.linkDependents);
};

export const getLinkComponentDependenciesError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.dependencies.linkDependencies);
};

export const getLinkComponentDependentsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.dependencies.linkDependents);
};

export const getUnlinkComponentDependencyError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.dependencies.unlinkDependency);
};

export const getUnlinkComponentDependantError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.dependencies.unlinkDependant);
};

// LIST //

export const getComponentList = (state: RootState): ComponentRefInTheList[] => {
  return state.components.list;
};

export const isFetchingComponentList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.fetchList);
};

export const getFetchComponentListError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.fetchList);
};

export const getTotalNumberOfComponents = (state: RootState): number => {
  return state.components.totalNumber;
};

export const getComponentListNextPageToken = (state: RootState): string | undefined => {
  return state.components.nextPageToken;
};

export const getComponentListPrevPageToken = (state: RootState): string | undefined => {
  return state.components.prevPageToken;
};

export const getComponentListCurrPageToken = (state: RootState): string | undefined => {
  return state.components.currPageToken;
};

export const isFetchingComponentListNextPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.fetchNextPage);
};

export const isFetchingComponentListPrevPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.fetchPrevPage);
};

export const getFetchComponentListNextPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.fetchNextPage);
};

export const getFetchComponentListPrevPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.fetchPrevPage);
};

// STAND ALONE //

export const getStandAloneComponentList = (state: RootState): ComponentRefInTheList[] => {
  return state.components.standAlone.list;
};

export const isFetchingStandAloneComponentList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.standAlone.fetchList);
};

export const getFetchStandAloneComponentListError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.standAlone.fetchList);
};

export const getTotalNumberOfStandAloneComponents = (state: RootState): number => {
  return state.components.standAlone.totalNumber;
};

export const getStandAloneComponentListNextPageToken = (state: RootState): string | undefined => {
  return state.components.standAlone.nextPageToken;
};

export const getStandAloneComponentListPrevPageToken = (state: RootState): string | undefined => {
  return state.components.standAlone.prevPageToken;
};

export const getStandAloneComponentListCurrPageToken = (state: RootState): string | undefined => {
  return state.components.standAlone.currPageToken;
};

export const isFetchingStandAloneComponentListNextPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.standAlone.fetchNextPage);
};

export const isFetchingStandAloneComponentListPrevPage = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.standAlone.fetchPrevPage);
};

export const getFetchStandAloneComponentListNextPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.standAlone.fetchNextPage);
};

export const getFetchStandAloneComponentListPrevPageError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.standAlone.fetchPrevPage);
};

// MY //

export const getMyComponents = (state: RootState): ComponentRefInTheList[] => {
  return state.components.my;
};

export const getNumberOfMyComponents = (state: RootState): number => {
  return state.components.my.length;
};

export const isFetchingMyComponents = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.fetchMy);
};

export const getFetchMyComponentsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.fetchMy);
};

// LINKS //

export const getComponentLinks = (state: RootState): Link[] => {
  return state.components.selected.links;
};

export const isAddingLinkToComponent = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.links.add);
};

export const getAddComponentLinkError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.links.add);
};

export const addedComponentLinkSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.current.links.add);
};

export const isUpdatingComponentLink = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.links.edit);
};

export const getUpdateComponentLinkError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.links.edit);
};

export const updatedComponentLinkSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.current.links.edit);
};

export const isDeletingComponentLink = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.links.delete);
};

export const getDeleteComponentLinkError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.links.delete);
};

export const deletedComponentLinkSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.components.current.links.delete);
};

// POPUP //

export const getComponentDetailsForPopup = (state: RootState): ComponentInTheList => {
  return state.components.popupDetails.component;
};

export const getXEventDetailsForPopup = (state: RootState): XEventInTheList[] => {
  return state.components.popupDetails.xEvents;
};

export const isFetchingComponentPopupDetails = (state: RootState): boolean => {
  return isLoading(state, actionTypes.components.current.popup.fetchDetails);
};

export const getFetchComponentPopupDetailsError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.components.current.popup.fetchDetails);
};
