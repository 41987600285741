import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteChildrenProps, generatePath } from 'react-router';
import { usePageTitle } from '../../hooks';
import {
  fetchMyComponents,
  getMyComponents,
  getNumberOfMyComponents,
  isFetchingMyComponents,
  getTeamMap,
} from '../../store';
import { ComponentRefInTheList, paths } from '../../definitions';
import { DashboardView } from './Dashboard.view';

type DashboardContainerProps = {
  isCreateDrawerOpen?: boolean;
} & RouteChildrenProps;

export const DashboardContainer: FunctionComponent<DashboardContainerProps> = ({
  isCreateDrawerOpen,
  ...rest
}) => {
  const dispatch = useDispatch();
  usePageTitle('Dahsboard');

  const isLoading = useSelector(isFetchingMyComponents);
  const componentList = useSelector(getMyComponents);
  const numberOfComponents = useSelector(getNumberOfMyComponents);
  const teamMap = useSelector(getTeamMap);

  const [isCreateDrawerVisible, setIsCreateDrawerVisible] = useState(false);

  useEffect(() => {
    if (isCreateDrawerOpen) {
      setIsCreateDrawerVisible(true);
    } else {
      setIsCreateDrawerVisible(false);
    }
  }, [isCreateDrawerOpen]);

  const handleViewComponent = useCallback(
    (component: ComponentRefInTheList) => {
      component.shortname &&
        dispatch(push(generatePath(paths.component, { componentShortname: component.shortname })));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchMyComponents());
  }, [dispatch]);

  const handleOpenCreateComponent = useCallback(() => {
    dispatch(push(generatePath(paths.createComponentFromDashboard)));
  }, [dispatch]);

  const handleCloseCreateComponent = useCallback(() => {
    dispatch(push(generatePath(paths.dashboard)));
  }, [dispatch]);

  return (
    <DashboardView
      componentList={componentList}
      onViewComponent={handleViewComponent}
      isFetchingComponents={isLoading}
      isCreateDrawerVisible={isCreateDrawerVisible}
      onOpenCreateComponent={handleOpenCreateComponent}
      onCloseCreateComponent={handleCloseCreateComponent}
      numberOfComponents={numberOfComponents}
      teamMap={teamMap}
      {...rest}
    />
  );
};
