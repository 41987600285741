import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import AddLinkForm from '../../forms/Link';
import { UpdateLinkFormData, Appearance, Link, theme } from '../../definitions';
import { Icon } from '../../components/Icon';
import { FormLayout } from '../../components/Form';
import Button from '../../components/Button';
import PopConfirm from '../../components/PopConfirm';

type EditLinkProps = {
  onClose: () => void;
  isOpen: boolean;
  link?: Link;
  isDeleting: boolean;
  isUpdating: boolean;
  onDeleteLink: (linkId: string) => void;
  onUpdateLink: (formData: UpdateLinkFormData) => void;
};

export const EditLinkView: FunctionComponent<EditLinkProps> = ({
  children,
  onClose,
  link,
  isDeleting,
  isUpdating,
  onDeleteLink,
  onUpdateLink,
  ...rest
}) => {
  const DeleteLinkButton: FunctionComponent<{ linkId: string }> = ({ linkId }) => (
    <PopConfirm
      title={`Are you sure you want to delete the link?`}
      onConfirm={() => onDeleteLink(linkId)}
      isConfirming={isDeleting}
      isDisabled={isUpdating}
      contentText="Please note that after confirming it will be permanently deleted."
      confirmColor={theme.palettes.error}
    >
      <Button
        isDisabled={isUpdating}
        isPending={isDeleting}
        appearance={Appearance.PrimaryWithIcon}
      >
        <Icon.Trash />
        <span>Delete</span>
      </Button>
    </PopConfirm>
  );

  if (!link) {
    return null;
  }

  return (
    <Modal onClose={onClose} title="Update link" {...rest}>
      <FormLayout isModal={true}>
        <AddLinkForm
          onSubmit={onUpdateLink}
          initialValues={link}
          isDisabled={isDeleting}
          isLoading={isUpdating}
          action="Update"
          onCancel={onClose}
          icon={<Icon.Save />}
          extraAction={<DeleteLinkButton linkId={link.id} />}
        />
      </FormLayout>
    </Modal>
  );
};
