import styled from 'styled-components';
import { darken } from 'polished';
import { theme } from '../../definitions';

export const Container = styled.div`
  display: inline-flex;
  min-width: 10.5rem;
  margin: 1.5rem 0 0 0;
`;

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: theme.palettes.main,
    background: state.isSelected ? theme.palettes.mute : theme.palettes.inverse,
    padding: '0.5rem 0.75rem',
    fontSize: '0.875rem',
    '&:hover': {
      background: theme.palettes.mute,
    },
    '&:active': {
      background: theme.palettes.mute,
    },
  }),
  container: (provided: any) => ({
    ...provided,
    display: 'flex',
    width: '100%',
    minHeight: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: theme.zIndexes.select,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0 0.75rem ',
    fontSize: '0.875rem',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
    cursor: 'pointer',
    background: 'none',
    boxShadow: theme.effects.borderShadow,
    borderRadius: '0.5rem',
    '&:hover': {
      border: `1px solid ${
        state.isFocused
          ? theme.palettes.secondaryHighlight
          : state.hasValue
          ? theme.palettes.mutedMain
          : theme.palettes.border
      }`,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const treeSelectCustomStyles = {
  ...customStyles,
  option: (provided: any, state: any) => ({
    ...provided,
    color: theme.palettes.main,
    background: state.isSelected ? theme.palettes.mute : theme.palettes.inverse,
    padding: '0.25rem 0.25rem 0.25rem 1rem ',
    fontSize: '0.875rem',
    '&:hover': {
      background: theme.palettes.mute,
    },
    '&:active': {
      background: theme.palettes.mute,
    },
  }),
};

export const FluidRow = styled.div`
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
`;

export const SelectLabel = styled.label<{ isFloating?: boolean }>`
  left: 0.75em;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;
  top: ${props => (props.isFloating ? '-1rem' : '30%')};
  font-size: ${props => (props.isFloating ? `0.75rem` : `0.875rem`)};
  color: ${props =>
    props.isFloating ? props.theme.palettes.secondaryHighlight : props.theme.colors.main};
  opacity: ${props => (props.isFloating ? 1 : 0.65)};
  z-index: 1;
`;

export const FilterMenu = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
`;

export const FilterMeta = styled.div`
  display: flex;
  gap: 0.25rem;
  span {
    font-size: 0.875rem;
    line-height: 1.25;
  }
`;

export const Divider = styled.span`
  margin-left: 0.75rem;
  color: ${({ theme }) => theme.palettes.placeholder};
`;

export const FilterTriggger = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  gap: 0.5rem;
  display: flex;
  height: 100%;
  align-items: center;
  color: ${props => props.theme.palettes.main};

  svg {
    width: 1.25rem;
    height: 1.25rem;
    stroke: ${props => props.theme.palettes.mutedMain};
    fill: ${props => (props.isActive ? darken(0.2, props.theme.palettes.inverse) : 'none')};
  }

  &:hover {
    color: ${props => props.theme.palettes.mutedMain};
    svg {
      stroke: ${props => props.theme.palettes.mutedMain};
      fill: ${props => (props.isActive ? darken(0.2, props.theme.palettes.inverse) : 'none')};
    }
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Filter = styled.div`
  margin-bottom: 1.5rem;
`;

export const TreeSelectParentContainer = styled.div<{ isExpanded: boolean; hasChildren: boolean }>`
  display: flex;
  gap: 0.5rem;
  cursor: ${props => (props.hasChildren ? 'pointer' : 'default')};
  padding: 0.5rem;
  background: ${props => props.theme.palettes.inverse};
  &:active: {
    background: ${props => props.theme.palettes.mute};
  }
`;

export const TreeSelectChildContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const IconWrapper = styled.div<{ isExpanded: boolean }>`
  display: inline-flex;
  transform: ${props => (props.isExpanded ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 1.25rem;
  svg {
    width: 1.25rem;
    height: auto;
    fill: ${props =>
      props.isExpanded ? props.theme.palettes.main : props.theme.palettes.placeholder};
  }
`;
