import { FunctionComponent, ElementType } from 'react';
import * as Styled from './MetaTable.styles';

type IMetaTableView = FunctionComponent & {
  SmallColumn: ElementType;
  BigColumn: ElementType;
};

export const MetaTableView: IMetaTableView = ({ children }) => {
  return <Styled.TableRow>{children}</Styled.TableRow>;
};

MetaTableView.SmallColumn = Styled.SmallColumn;
MetaTableView.BigColumn = Styled.BigColumn;
