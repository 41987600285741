import { FunctionComponent } from 'react';
import * as Styled from './Divider.styles';

export type DividerProps = {
  isHorizontal?: boolean;
  width?: string;
  margin?: string;
  disableVW?: boolean;
};
export const DividerView: FunctionComponent<DividerProps> = ({
  isHorizontal = true,
  width,
  disableVW = false,
  margin,
}) => {
  if (isHorizontal) return <Styled.HorizontalDivider width={width} margin={margin} disableVW={disableVW} />;

  return <Styled.VerticalDivider disableVW={disableVW} />;
};
