import { FunctionComponent, useEffect, useState, ReactElement } from 'react';
import { Arrow, useLayer } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import { AvatarType } from '../../definitions';
import AvatarWithTitle from '../AvatarWithTitle';
import * as Styled from './Widget.styles';
import { Loader } from '../Loader';

export type WidgetProps = {
  avatar?: string;
  name: string;
  actions?: ReactElement[];
  avatarType?: AvatarType;
  content?: ReactElement;
  shouldStackActions?: boolean;
  openCallback?: () => void;
  isLoadingContent?: boolean;
};

export const WidgetView: FunctionComponent<WidgetProps> = ({
  children,
  avatar,
  name,
  actions,
  avatarType,
  content,
  shouldStackActions,
  openCallback,
  isLoadingContent = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [setToClose, setSetToClose] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSetToClose(false);
  };

  const handleTriggerClick = () => {
    if (!isOpen && openCallback) {
      openCallback();
    }

    setOpen(!isOpen);
  };

  useEffect(() => {
    if (setToClose) {
      handleClose();
    }
  }, [setToClose]);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onParentClose: handleClose, // close the menu when the parent closes
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  return (
    <>
      <div {...triggerProps} onClick={handleTriggerClick}>
        {children}
      </div>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <Styled.Header>
                {avatarType ? (
                  <AvatarWithTitle name={name} avatarSrc={avatar} avatarType={avatarType} />
                ) : (
                  <Styled.Name>{name}</Styled.Name>
                )}
              </Styled.Header>
              {isLoadingContent ? (
                <Loader.Container>
                  <Loader />
                </Loader.Container>
              ) : (
                content && <Styled.Content>{content}</Styled.Content>
              )}
              {actions && (
                <Styled.ActionWrapper shouldStackActions={shouldStackActions}>
                  {actions}
                </Styled.ActionWrapper>
              )}
              <Arrow {...arrowProps} />
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
