import { FunctionComponent, useState } from 'react';
import { ComponentInTheList, ComponentRefInTheList, TeamMap } from '../../../definitions';
import LinkDependencyModal from '../../../modals/LinkDependency';
import DependencySection from './DependencySection';

type DependencySectionProps = {
  onViewComponent: (component: ComponentRefInTheList) => void;
  isLoadingDependencies: boolean;
  dependencies: ComponentRefInTheList[];
  dependents: ComponentRefInTheList[];
  onLinkDependencies: (dependencyIds: string[]) => void;
  onLinkDependents: (dependentIds: string[]) => void;
  isLinkingDependencies: boolean;
  isLinkingDependents: boolean;
  componentDependencyIds: Set<string>;
  componentDependentIds: Set<string>;
  onUnlinkDependency: (componentId: string) => void;
  onUnlinkDependant: (componentId: string) => void;
  isUnlinkingDependency: boolean;
  isUnlinkingDependant: boolean;
  teamMap: TeamMap;
  component: ComponentInTheList;
};

export const DependenciesView: FunctionComponent<DependencySectionProps> = ({
  onViewComponent,
  isLoadingDependencies,
  dependencies,
  dependents,
  onLinkDependencies,
  onLinkDependents,
  isLinkingDependencies,
  isLinkingDependents,
  componentDependencyIds,
  componentDependentIds,
  onUnlinkDependant,
  onUnlinkDependency,
  isUnlinkingDependency,
  isUnlinkingDependant,
  teamMap,
  component,
}) => {
  const [isLinkDependentsModalOpen, setIsLinkDependentsModalOpen] = useState(false);
  const [isLinkDependenciesModalOpen, setIsLinkDependenciesModalOpen] = useState(false);

  return (
    <>
      <DependencySection
        isLoading={isLoadingDependencies}
        components={dependents}
        title="Dependents"
        onOpenModal={() => setIsLinkDependentsModalOpen(true)}
        description={`List of components which depend on ${
          component.displayName || component.shortname || ''
        } component. The term 'dependents' refers to other components that relies on the selected component to function correctly.`}
        modalTriggerTitle="Link dependents"
        emptyStateText="This component doesn't have any dependents. Start by linking one."
        onViewComponent={onViewComponent}
        unlinkActionTitle="Unlink dependant"
        onUnlinkClick={onUnlinkDependant}
        isUnlinking={isUnlinkingDependant}
        teamMap={teamMap}
      />
      <DependencySection
        isLoading={isLoadingDependencies}
        components={dependencies}
        title="Dependencies"
        onOpenModal={() => setIsLinkDependenciesModalOpen(true)}
        description={`List of components which ${
          component.displayName || component.shortname || ''
        } component depends on. The term 'dependencies' refers to other components that selected component relies on to function correctly.`}
        modalTriggerTitle="Link dependencies"
        emptyStateText="This component doesn't have any dependencies. Start by linking one."
        onViewComponent={onViewComponent}
        unlinkActionTitle="Unlink dependency"
        onUnlinkClick={onUnlinkDependency}
        isUnlinking={isUnlinkingDependency}
        teamMap={teamMap}
      />

      <LinkDependencyModal
        isOpen={isLinkDependentsModalOpen}
        onClose={() => setIsLinkDependentsModalOpen(false)}
        componentId={component.id}
        componentName={component.displayName || component.shortname || ''}
        isLinking={isLinkingDependents}
        onLink={onLinkDependents}
        disabledComponentIds={componentDependentIds}
        title="Link dependents"
        getActionTitle={(numberOfSelectedComponents: number) =>
          `Link ${numberOfSelectedComponents ? numberOfSelectedComponents : ''} depend${
            numberOfSelectedComponents > 1 ? 'ents' : 'ant'
          }`
        }
      />
      <LinkDependencyModal
        isOpen={isLinkDependenciesModalOpen}
        onClose={() => setIsLinkDependenciesModalOpen(false)}
        componentId={component.id}
        componentName={component.displayName || component.shortname || ''}
        isLinking={isLinkingDependencies}
        onLink={onLinkDependencies}
        disabledComponentIds={componentDependencyIds}
        title="Link dependencies"
        getActionTitle={(numberOfSelectedComponents: number) =>
          `Link ${numberOfSelectedComponents ? numberOfSelectedComponents : ''} dependenc${
            numberOfSelectedComponents > 1 ? 'ies' : 'y'
          }`
        }
      />
    </>
  );
};
