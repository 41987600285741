import isAfter from 'date-fns/isAfter';

export type SerializableValue = any;

export type ExpiryFormat = string | Date | number;

export type StorageDriver = {
  getItem(key: string): string | null;
  removeItem(key: string): void;
  setItem(key: string, value: string): void;
};

export default class StorageService {
  private driver: StorageDriver;

  version: number | undefined;

  prefix: string | undefined;

  private readonly memoryStorage: {
    [key: string]: { value: SerializableValue; expiry: ExpiryFormat } | null;
  };

  private isSupported: boolean;

  constructor(driver?: StorageDriver, version?: number, prefix?: string) {
    this.driver = driver || window.localStorage;
    this.version = version;
    this.prefix = prefix;
    this.memoryStorage = {};
    this.isSupported = true;
  }

  /**
   * Test if storage is supported by this device
   */
  async initialise(): Promise<void> {
    try {
      const key = '__storage_test__';

      await this.driver.setItem(key, key);
      await this.driver.removeItem(key);
    } catch (e) {
      this.isSupported = false;
    }
  }

  /**
   * Formatted storage item name
   * @param name
   * @returns {string}
   */
  getKey(name: string): string {
    return [this.prefix, name, this.version].filter(Boolean).join(':');
  }

  /**
   * Creates or updates a storage item as a JSON object. Optionally include an expiry
   * timestamp to invalidate the value after a certain time.
   * @param {string} name
   * @param {string|number|array|object} value
   * @param {number|string} expiry date string or timestamp
   */
  async set(
    name: string,
    value: SerializableValue,
    expiry: ExpiryFormat = '',
  ): Promise<SerializableValue> {
    const key = this.getKey(name);

    if (this.isSupported) {
      await this.driver.setItem(
        key,
        JSON.stringify({
          value,
          expiry,
        }),
      );
    } else {
      this.memoryStorage[key] = { value, expiry };
    }

    return value;
  }

  /**
   * Retrieve value from storage driver.
   * If item is expired, null is returned and item is removed.
   * @param {string} name
   * @param {string} field
   * @returns {*}
   */
  async get(name?: string, field = 'value'): Promise<SerializableValue> {
    if (!name) {
      return null;
    }

    const key = this.getKey(name);

    try {
      let item;

      if (!this.isSupported) {
        item = this.memoryStorage[key];
      } else {
        const value = (await this.driver.getItem(key)) as string;
        item = JSON.parse(value);
      }

      if (!item) {
        return null;
      }

      if (item.expiry && isAfter(new Date(), new Date(item.expiry))) {
        await this.remove(name);
        return null;
      }

      return item?.[field];
    } catch (e) {
      // If there's an exception its because the local storage
      // value is not a valid JSON object anymore so clean it up
      await this.remove(name);

      return null;
    }
  }

  /**
   * Retrieve the expiry time of storage value
   * @param {string} name
   * @returns {*}
   */
  async getExpiry(name?: string): Promise<ExpiryFormat> {
    return this.get(name, 'expiry');
  }

  /**
   * Updates existing storage item. Array and objects are merged.
   * @param {string} name
   * @param {string|number|array|object} value
   * @param {number|string} expiry date string or timestamp
   */
  async update(
    name: string,
    value: SerializableValue,
    expiry: string | Date | number = '',
  ): Promise<SerializableValue> {
    const prevValue = await this.get(name);
    const prevExpiry = await this.getExpiry(name);

    if (!prevValue || typeof prevValue === 'string' || typeof prevValue === 'number') {
      return this.set(name, value, expiry || prevExpiry);
    }

    if (Array.isArray(prevValue)) {
      return this.set(name, prevValue.concat(value), expiry || prevExpiry);
    }

    if (typeof prevValue === 'object') {
      return this.set(name, { ...prevValue, ...value }, expiry || prevExpiry);
    }

    throw new Error('Invalid value type.');
  }

  /**
   * Remove storage item.
   * @param {string} name
   */
  async remove(name: string): Promise<void> {
    const key = this.getKey(name);

    if (this.isSupported) {
      await this.driver.removeItem(key);
    } else {
      this.memoryStorage[key] = null;
    }
  }
}
