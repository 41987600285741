import styled from 'styled-components';

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 1.5rem;
`;
