import { grpc } from '@improbable-eng/grpc-web';
import { NotificationService as NotificationsService } from '../grpc/grpcweb/notification_pb_service';
import {
  ListNotificationsRequest,
  ListNotificationsResponse,
  MarkAllNotificationsAsReadRequest,
  MarkAllNotificationsAsReadResponse,
} from './../grpc/grpcweb/notification_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class NotificationService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  getNotificationList = async (token: string) => {
    const request = new ListNotificationsRequest();
    return new Promise((resolve, reject) => {
      grpc.unary(NotificationsService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken: token }),
        onEnd: (out: grpc.UnaryOutput<ListNotificationsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getNotificationsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch notifications failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  clearAllNotifications = async (
    token: string,
    markAsReadRequest: MarkAllNotificationsAsReadRequest,
  ) => {
    const request: MarkAllNotificationsAsReadRequest = markAsReadRequest;
    return new Promise((resolve, reject) => {
      grpc.unary(NotificationsService.MarkAllAsRead, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken: token }),
        onEnd: (out: grpc.UnaryOutput<MarkAllNotificationsAsReadResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.clearNotificationsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'mark notifications as read failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
