import { css, keyframes } from 'styled-components';
import { colors } from './colors';
import { darken, rgba } from 'polished';
import { breakpoints } from './breakpoints';

const palettes: { [key: string]: string } = {
  inverse: colors.snowflake,
  main: colors.space,
  blend: colors.blend,
  mutedMain: colors.mrgray,
  highlight: colors.gold,
  secondaryHighlight: colors.elsa,
  border: colors.silver,
  headerBorder: darken(0.05, colors.frost),
  mute: colors.frost,
  placeholder: colors.elephant,
  error: colors.strawberry,
  success: colors.lawn,
  warning: colors.gold,
  info: colors.elsa,
  background: colors.frost,
  headerBackground: rgba(colors.space, 0.1),
};

export const theme = {
  breakpoints,
  colors,
  palettes,
  effects: {
    modalShadow: `0 6px 79px rgba(14,14,30,.1), 0 1px 2px rgba(10,20,33,.1), 0 6px 24px rgba(10,20,33,.16)`,
    navShadow: `0 4px 5px -2.5px rgb(0 0 0 / 20%), 0 4px 8px 1px rgb(0 0 0 / 14%), 0 3px 8px 1.5px rgb(0 0 0 / 12%)`,
    delicateShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px`,
    leftShadow: `-2px 0 3px -1px rgba(60, 64, 67, 0.15)`,
    boldShadow: `rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px`,
    backgroundShadow: `radial-gradient(40% 60% at top left, ${palettes.highlight}, ${palettes.main})`,
    borderShadow: `0 0 #0000, 0 0 #0000, 0px 1px 2px rgba(0,0,0,.1)`,
    textBorder: `-0.5rem 0 ${colors.aubergine}, 0 0.5rem ${colors.aubergine}, 0.5rem 0 ${colors.aubergine}, 0 -0.5rem ${colors.aubergine}`,
    transition: `all 300ms ease-out`,
    launchBackground: `linear-gradient(180deg, ${palettes.inverse} 49%, ${palettes.main} 100%)`,
    shadowBottom: `inset 0 -10px 8px -8px rgb(200 200 200 / 1)`,
    shadowTop: `inset 0 10px 8px -8px rgb(200 200 200 / 1)`,
    inputShadow: `0 1px 2px 0 rgba(0,0,0,.039)`,
    fadeIn: keyframes`
      from { opacity: 0; }
      to { opacity: 1 };
    `,
    rotate: keyframes`
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    `,
  },
  zIndexes: {
    select: 2,
    topBar: 11,
    drawer: 8,
    moto: 1,
    sideBar: 9,
    drawerOverlay: 7,
    alert: 17,
    modal: 13,
    loader: 20,
    loaderOverlay: 19,
    modalOverlay: 12,
    confirmationPopup: 14,
    tooltip: 15,
  },
  constants: {
    topbarHeight: '4rem',
    mediumSidebarWidth: '4rem',
    largeSidebarWidth: '16rem',
    maxWidth: '160rem',
    metaPanelWidth: '22rem',
  },
  fontSize: '0.875rem',
  fontFamily:
    "'Inter', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  media: Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
      css`
        @media (min-width: ${breakpoints[label]}px) {
          ${css(literals, ...placeholders)};
        }
      `.join('');
    return acc;
  }, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>),
};
