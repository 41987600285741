/**
 * Check min value length
 * @param {number} min
 * @return {function}
 */
export const minLength =
  (min: number) =>
  (value: string): string | undefined =>
    min && value && value.length < min ? `Must be at least ${min} characters.` : undefined;

/**
 * Check max value length
 * @param {number} max
 * @return {function}
 */
export const maxLength =
  (max: number) =>
  (value: string): string | undefined =>
    max && value && value.length > max ? `Must be no more than ${max} characters.` : undefined;

/**
 * Check value matches defined length
 * @param length
 * @returns {function(*=): *}
 */
export const exactLength =
  (length: number) =>
  (value: string): string | undefined =>
    length && value && String(value).length !== length
      ? `Must be exactly ${length} characters.`
      : undefined;
