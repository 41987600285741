import { FunctionComponent } from 'react';
import Button from '../../components/Button';
import SloCard from '../../components/SloCard';
import { Loader } from '../../components/Loader';
import { Empty } from '../../components/Empty';
import { Card } from '../../components/Card';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { Layout } from '../../components/Layout';
import { Icon } from '../../components/Icon';
import { Appearance, paths, SloInTheList } from '../../definitions';

type SloListViewProps = {
  sloList: SloInTheList[];
  onViewSLO: (shortName?: string) => void;
  isFetchingSLOs: boolean;
};

export const SloListView: FunctionComponent<SloListViewProps> = ({
  sloList,
  onViewSLO,
  isFetchingSLOs,
}) => {
  const CreateSloButton = () => (
    <Button
      to={paths.drawer.createSlo}
      appearance={Appearance.PrimaryWithIconCollapsible}
      aria-label="Create SLO"
      title="Create SLO"
    >
      <Icon.Plus />
      <span>Create SLO</span>
    </Button>
  );

  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>SLO</Layout.HeaderTitle>
      <Layout.HeaderActions>
        <CreateSloButton />
      </Layout.HeaderActions>
    </Layout.Header>
  );

  return (
    <AuthenticatedLayout header={Header}>
      <>
        {isFetchingSLOs ? (
          <Loader.Container>
            <Loader />
          </Loader.Container>
        ) : sloList.length ? (
          <Card.CardContainer>
            {sloList.map((slo: SloInTheList) => (
              <Card.CardWrapper key={slo.id}>
                <SloCard onViewSLO={onViewSLO} slo={slo} />
              </Card.CardWrapper>
            ))}
          </Card.CardContainer>
        ) : (
          <Empty text="There are no SLOs. Start by creating one.">
            <CreateSloButton />
          </Empty>
        )}
      </>
    </AuthenticatedLayout>
  );
};
