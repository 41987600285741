import { FunctionComponent } from 'react';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import { Drawer } from '../../components/Drawer';
import Button from '../../components/Button';
import PopConfirm from '../../components/PopConfirm';
import { ColorTheme, DrawerPosition, Appearance, EditComponentFormData } from '../../definitions';
import { Icon } from '../../components/Icon';
import ComponentForm from '../../forms/ComponentForm';

type UpdateComponentProps = {
  isEditing: boolean;
  onEditComponent: (formData: EditComponentFormData) => void;
  isFetching: boolean;
  componentTypes: CatalogItemView.AsObject[];
  initialValues: EditComponentFormData;
  onDeleteComponent: () => void;
  isDeleting: boolean;
  visible: boolean;
  onHide: () => void;
};

export const UpdateComponentView: FunctionComponent<UpdateComponentProps> = ({
  onEditComponent,
  isEditing,
  isFetching,
  componentTypes,
  initialValues,
  onDeleteComponent,
  isDeleting,
  onHide,
  visible,
}) => {
  const DeleteComponentButton = () => (
    <Button
      isPending={isDeleting}
      isDisabled={isFetching || isEditing}
      appearance={Appearance.PrimaryWithIcon}
    >
      <Icon.Trash />
      <span>Delete</span>
    </Button>
  );

  return (
    <Drawer
      visible={visible}
      onHideMenu={onHide}
      position={DrawerPosition.Right}
      fullScreenOnSmall={true}
      colorTheme={ColorTheme.Light}
      title="Update component"
    >
      <ComponentForm
        isFetching={isFetching}
        isDeleting={isDeleting}
        isSubmitting={isEditing}
        submitButtonText="Update"
        onSubmit={onEditComponent}
        componentTypes={componentTypes}
        initialValues={initialValues}
        onClose={onHide}
        submitButtonIcon={<Icon.Save />}
        isShortnameDisabled={true}
        extraAction={
          <PopConfirm
            title={`Are you sure you want to delete the component?`}
            onConfirm={onDeleteComponent}
            isConfirming={isDeleting}
            isDisabled={isFetching || isEditing}
            contentText="Please note that after confirming it will be permanently deleted. You will not be
                able to restore the deleted component."
          >
            <DeleteComponentButton />
          </PopConfirm>
        }
      />
    </Drawer>
  );
};
