import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import {
  isCreatingComponent,
  createComponent,
  isFetchingComponentTypes,
  fetchComponentTypes,
  getComponentTypes,
  updateNewComponent,
  getSavedNewComponent,
} from '../../store';
import { usePageTitle } from '../../hooks';
import { CreateComponentFormData, EditComponentFormData, FormStateType } from '../../definitions';
import { CreateComponentView } from './CreateComponent.view';

type CreateComponentContainerProps = {
  visible: boolean;
  onHide: () => void;
} & RouteChildrenProps;

export const CreateComponentContainer: FunctionComponent<CreateComponentContainerProps> = ({
  visible,
  onHide,
}) => {
  const dispatch = useDispatch();
  usePageTitle('Create component');

  const isCreating = useSelector(isCreatingComponent);
  const isFetching = useSelector(isFetchingComponentTypes);

  const componentTypes = useSelector(getComponentTypes);
  const savedFormData = useSelector(getSavedNewComponent);

  const handleCreateComponent = useCallback(
    (formData: CreateComponentFormData | EditComponentFormData) => {
      dispatch(createComponent(formData));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchComponentTypes());
  }, [dispatch]);

  const handleUpdateComponentState = useCallback(
    (formData: FormStateType) => {
      dispatch(updateNewComponent(formData));
    },
    [dispatch],
  );

  return (
    <CreateComponentView
      isCreating={isCreating}
      isFetching={isFetching}
      componentTypes={componentTypes}
      onCreateComponent={handleCreateComponent}
      visible={visible}
      onHide={onHide}
      onUpdateComponentState={handleUpdateComponentState}
      savedFormData={savedFormData}
    />
  );
};
