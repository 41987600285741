import styled, { css } from 'styled-components';
import { darken, rgba } from 'polished';
import { Link as NavigationLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Icon } from '../Icon';

type ButtonItemProps = {
  isPopupTrigger?: boolean;
};

type LinkProps = {
  isActive: boolean;
  isMediumLayout?: boolean;
};

export const ResponsiveNavLogin = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-bottom: 2rem;
  flex-direction: column;
`;

export const Item = styled.div<{ isMediumLayout?: boolean }>`
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1.25rem;

  svg {
    width: ${props => (props.isMediumLayout ? '1.5rem' : '1.25rem')};
    height: auto;
  }
`;

export const ButtonItem = styled.div<ButtonItemProps>`
  margin: 0.5rem 0.75rem;
  cursor: pointer;
  line-height: 1.25rem;
  padding: 0.375rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.5rem;
  background: ${props => (props.isPopupTrigger ? props.theme.palettes.blend : 'none')};
  color: ${props => darken(0.2, props.theme.palettes.inverse)};
  border: ${props => (props.isPopupTrigger ? '0.5px solid transparent' : 'none')};
  text-decoration: none;

  &:hover {
    background: ${props => props.theme.palettes.blend};
    color: ${props => props.theme.palettes.inverse};
    border-color: ${props => props.theme.palettes.border};
    text-decoration: none;
  }

  svg {
    margin-right: 1.25rem;
    width: 1.25rem;
    height: auto;
  }
`;

export const SecondaryButtonItem = styled(ButtonItem)`
  margin: 0.25rem 0;
  padding: 0.25rem 0.375rem;
  background: none;
  color: ${props => props.theme.palettes.main};
  border: 0.5px solid transparent;
  svg {
    fill: ${props => props.theme.palettes.placeholder};
  }

  &:hover {
    background: ${props => rgba(props.theme.palettes.main, 0.05)};
    color: ${props => props.theme.palettes.main};
    border-color: ${props => props.theme.palettes.border};
    svg {
      fill: ${props => props.theme.palettes.main};
    }
  }
`;

const sharedNavLinkStyle = css<LinkProps>`
  display: flex;
  background: none;
  border-left: 0.375rem solid ${props => props.theme.palettes.main};
  color: ${props => darken(0.2, props.theme.palettes.inverse)};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background: ${props => props.theme.palettes.blend};
    border-color: ${props => !props.isActive && props.theme.palettes.blend};
    color: ${props => props.theme.palettes.inverse};
  }

  ${props =>
    props.isActive &&
    css`
      border-color: ${props => props.theme.palettes.highlight};
      background: ${props => props.theme.palettes.blend};
      color: ${props => props.theme.palettes.inverse};
    `};
`;

export const NavLink = styled(NavigationLink)<LinkProps>`
  ${sharedNavLinkStyle};
`;

export const ClickableLink = styled.div<LinkProps>`
  cursor: pointer;
  ${sharedNavLinkStyle};
  width: 100%;
`;

export const NavButton = styled.div`
  display: flex;
  background: none;
  border-left: 0.375rem solid ${props => props.theme.palettes.main};
  color: ${props => darken(0.2, props.theme.palettes.inverse)};

  &:hover {
    background: ${props => props.theme.palettes.blend};
    border-color: ${props => props.theme.palettes.blend};
    color: ${props => props.theme.palettes.inverse};
  }
`;

export const Text = styled.p`
  flex: 1;
  text-align: left;
`;

export const SecondaryItem = styled(Item)`
  padding-left: 5.5rem;
`;

export const Chevron = styled(Icon.Chevron)`
  right: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  transition: transform 200ms ease-out;
  transform: rotate(180deg);
  color: ${({ theme }) => theme.palettes.inverse};
`;

export const CollapsibleTriggerItem = styled(({ isExpanded, ...rest }) => <Item {...rest} />)`
  ${props =>
    props.isExpanded &&
    css`
      ${Chevron} {
        transform: rotate(0deg);
      }
    `}
`;

export const NavHeader = styled.div`
  height: ${props => props.theme.constants.topbarHeight};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palettes.border};
  padding: 0.75rem;
  margin-bottom: 1rem;
  svg {
    fill: ${({ theme }) => theme.palettes.inverse};
    &:hover {
      fill: ${({ theme }) => theme.palettes.highlight};
    }
  }
`;

export const AvatarWrapper = styled.div`
  svg {
    margin: 0;
    width: inherit;
    height: inherit;
  }
`;

export const MotionDiv = styled(motion.div)`
  z-index: ${({ theme }) => theme.zIndexes.confirmationPopup};
  background: ${({ theme }) => theme.palettes.inverse};
  box-shadow: ${({ theme }) => theme.effects.boldShadow};
  max-height: 20rem;
  color: ${({ theme }) => theme.palettes.main};
  width: 16rem;
  max-width: calc(100% - 1.5rem);
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${props => props.theme.palettes.border};
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const AvatarAsATrigger = styled.div`
  margin-left: 1.25rem;
`;
