import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFetchingComponentList,
  fetchComponentList,
  getComponentList,
  isFetchingComponentListNextPage,
  isFetchingComponentListPrevPage,
  isFetchingComponentDependencies,
  getTotalNumberOfComponents,
  getComponentListPrevPageToken,
  getComponentListNextPageToken,
  fetchNextComponentListPage,
  fetchPrevComponentListPage,
  getSelectedComponent,
} from '../../store';
import { SELECT_COMPONENT_PAGE_SIZE } from '../../store/constants/pageSize';
import { ComponentRefInTheList } from '../../definitions';
import { SelectComponentView } from './SelectComponent.view';

type SelectComponentContainerProps = {
  onClose: () => void;
  isOpen: boolean;
  onSelectComponent: (component: ComponentRefInTheList) => void;
};

export const SelectComponentContainer: FunctionComponent<SelectComponentContainerProps> = ({
  isOpen,
  onClose,
  onSelectComponent,
}) => {
  const dispatch = useDispatch();
  const currentComponent = useSelector(getSelectedComponent);

  const [selectedComponent, setSelectedComponent] = useState<ComponentRefInTheList | undefined>(
    currentComponent.id ? { ...currentComponent, tagList: [] } : undefined,
  );

  const isFetchingComponents = useSelector(isFetchingComponentList);
  const isFetchingNextPage = useSelector(isFetchingComponentListNextPage);
  const isFetchingPrevPage = useSelector(isFetchingComponentListPrevPage);
  const isFetchingDependencies = useSelector(isFetchingComponentDependencies);

  const componentList = useSelector(getComponentList);

  const numberOfComponents = useSelector(getTotalNumberOfComponents);
  const prevPageToken = useSelector(getComponentListPrevPageToken);
  const nextPageToken = useSelector(getComponentListNextPageToken);

  const handleSave = useCallback(() => {
    if (selectedComponent) {
      onSelectComponent(selectedComponent);
    }
    onClose();
  }, [selectedComponent, onClose, onSelectComponent]);

  const handleFetchNextPage = useCallback(() => {
    dispatch(fetchNextComponentListPage({ pageSize: SELECT_COMPONENT_PAGE_SIZE }));
  }, [dispatch]);

  const handleFetchPrevPage = useCallback(() => {
    dispatch(fetchPrevComponentListPage({ pageSize: SELECT_COMPONENT_PAGE_SIZE }));
  }, [dispatch]);

  const handleSelectComponent = (selectedComponent: ComponentRefInTheList) => {
    setSelectedComponent(selectedComponent);
  };

  const handleClearSelectedComponent = () => {
    setSelectedComponent(currentComponent.id ? { ...currentComponent, tagList: [] } : undefined);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchComponentList({ pageSize: SELECT_COMPONENT_PAGE_SIZE }));
    }
  }, [dispatch, isOpen]);

  return (
    <SelectComponentView
      onClose={onClose}
      isFetching={isFetchingComponents || isFetchingNextPage || isFetchingPrevPage}
      isLoading={isFetchingDependencies}
      componentList={componentList}
      onNextPage={handleFetchNextPage}
      onPrevPage={handleFetchPrevPage}
      numberOfComponents={numberOfComponents}
      isPrevPageDisabled={!prevPageToken}
      isNextPageDisabled={!nextPageToken}
      onSelectComponent={handleSelectComponent}
      onClearSelectedComponent={handleClearSelectedComponent}
      isOpen={isOpen}
      onSave={handleSave}
      selectedComponentName={selectedComponent?.displayName}
      currentComponentId={currentComponent.id}
      selectedComponentId={selectedComponent?.id}
      currentComponentName={currentComponent.displayName}
    />
  );
};
