import styled from 'styled-components';
import { rgba } from 'polished';
import { Close } from '../Close';
import Button from '../Button';

type ModalPopupProps = {
  biggerHeight?: number;
  smallerHeight?: number;
  maxWidth?: string;
  shouldUseSmartPositioning: boolean;
};

export const Overlay = styled.div`
  background-color: ${props => rgba(props.theme.palettes.main, 0.25)};
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndexes.modalOverlay};
  animation: ${({ theme }) => theme.effects.fadeIn} 0.5s linear forwards;
`;

export const ModalWrapper = styled.div<{ shouldCoverPopup?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${props =>
    props.shouldCoverPopup
      ? props.theme.zIndexes.confirmationPopup + 1
      : props.theme.zIndexes.modal};
`;

export const ModalPopup = styled.div<ModalPopupProps>`
  width: 100%;
  pointer-events: auto;
  background-color: ${props => props.theme.palettes.inverse};
  color: ${props => props.theme.palettes.main};
  display: flex;
  flex-direction: column;
  box-shadow: ${props => props.theme.effects.modalShadow};
  min-height: 100vh;
  &&& {
    max-height: fit-content;
    border-radius: 1rem;
  }
  ${props => props.theme.media.medium`
    max-width: ${props.maxWidth ? props.maxWidth : '35rem'};
    min-height: auto;
    margin: ${
      props.shouldUseSmartPositioning
        ? props.biggerHeight && props.smallerHeight
          ? `${(props.biggerHeight - props.smallerHeight) / 32}rem`
          : '12.5rem'
        : '5vh'
    } 5rem;
  `}
`;

export const CloseButton = styled(Close)`
  align-self: flex-start;
`;

export const Outer = styled.div`
  display: flex;
  flex: 1;
`;

export const Inner = styled.div<{ hasFooter: boolean; hasTitle: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  flex: 1;
  position: relative;
  padding: 1rem;
  background: ${props => props.theme.palettes.background};
  border-bottom-left-radius: ${props => (props.hasFooter ? 0 : '1rem')};
  border-bottom-right-radius: ${props => (props.hasFooter ? 0 : '1rem')};
  border-top-left-radius: ${props => (props.hasTitle ? 0 : '1rem')};
  border-top-right-radius: ${props => (props.hasTitle ? 0 : '1rem')};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${props => props.theme.palettes.headerBorder};
`;

export const Footer = styled(Header)`
  border-bottom: none;
  background: ${props => props.theme.palettes.inverse};
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${props => props.theme.palettes.headerBorder};
  border-radius: 0 0 1rem 1rem;
  gap: 1rem;
`;

export const InnerFooter = styled(Footer)`
  &&& {
    margin: 0 -1rem -1rem -1rem;
    width: calc(100% + 2rem);
  }
`;

export const Action = styled(Button)``;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const PreFooterSpacer = styled.div`
  display: flex;
  flex: 1;
`;
