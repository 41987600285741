import { useEffect, useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useMeasure(): [
  { ref?: React.RefObject<HTMLDivElement> },
  { top: number; left: number; width: number; height: number },
] {
  const ref = useRef<HTMLDivElement>(null);
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(
    // @ts-ignore: Unreachable code error
    () => new ResizeObserver(([entry]) => set(entry.contentRect)),
  );

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current);
    }

    return () => ro.disconnect();
  }, [ro]);

  return [{ ref }, bounds];
}
