import { FunctionComponent, useState, MutableRefObject } from 'react';
import { MediaContext } from '../../contexts';
import { useMedia } from '../../hooks';
import { TopbarMode } from '../../definitions';
import Topbar from '../../components/Topbar';
import Overlay from '../../components/Overlay';
import { Sidebar } from '../../components/Sidebar';
import { navigationRoutes } from './navigationRoutes';
import * as Styled from './LandingLayout.styles';

type LandingLayoutProps = {
  isLoggedIn?: boolean;
  onLogout: () => void;
  refs: Record<string, MutableRefObject<any>>;
  activePath?: string;
  onSetActivePath: (path: string) => void;
};

export const LandingLayoutView: FunctionComponent<LandingLayoutProps> = ({
  children,
  isLoggedIn,
  onLogout,
  refs,
  onSetActivePath,
  activePath,
  ...rest
}) => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const isMediumLayout = useMedia({ medium: true }, false);
  const isLargeLayout = useMedia({ large: true }, false);

  const handleFocusOnPath = (path: string) => {
    refs[path].current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    onSetActivePath(path);
    setSidebarVisibility(false);
  };

  return (
    <MediaContext.Provider value={{ isLargeLayout, isMediumLayout }}>
      <Styled.Wrapper>
        <Sidebar
          onHide={() => setSidebarVisibility(false)}
          isVisible={isSidebarVisible}
          isLoggedIn={!!isLoggedIn}
          onLogout={onLogout}
          navigationRoutes={navigationRoutes}
          onItemClick={handleFocusOnPath}
          activePath={activePath}
          {...rest}
        />
        <div onClick={() => isSidebarVisible && setSidebarVisibility(false)}>
          <Topbar
            isSidebarVisible={isSidebarVisible}
            routes={navigationRoutes}
            mode={TopbarMode.Landing}
            setSidebarVisible={setSidebarVisibility}
            isLoggedIn={isLoggedIn}
            onItemClick={handleFocusOnPath}
            onLogout={onLogout}
            activePath={activePath}
            {...rest}
          />
          {children}
          <Overlay isVisible={isSidebarVisible} />
        </div>
      </Styled.Wrapper>
    </MediaContext.Provider>
  );
};
