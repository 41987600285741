import styled, { css } from 'styled-components';

export const Bounds = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: wrap;
  margin: -${props => props.gutter}rem;

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
      min-height: 0;
      overflow: hidden;
    `};
`;
