// package: rct.web
// file: grpcweb/eventsource.proto

var grpcweb_eventsource_pb = require("../grpcweb/eventsource_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var EventSourceService = (function () {
  function EventSourceService() {}
  EventSourceService.serviceName = "rct.web.EventSourceService";
  return EventSourceService;
}());

EventSourceService.Create = {
  methodName: "Create",
  service: EventSourceService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_eventsource_pb.CreateEventSourceRequest,
  responseType: grpcweb_eventsource_pb.CreateEventSourceResponse
};

EventSourceService.List = {
  methodName: "List",
  service: EventSourceService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_eventsource_pb.ListEventSourceRequest,
  responseType: grpcweb_eventsource_pb.ListEventSourceResponse
};

EventSourceService.Get = {
  methodName: "Get",
  service: EventSourceService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_eventsource_pb.GetEventSourceRequest,
  responseType: grpcweb_eventsource_pb.GetEventSourceResponse
};

EventSourceService.Delete = {
  methodName: "Delete",
  service: EventSourceService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_eventsource_pb.DeleteEventSourceRequest,
  responseType: grpcweb_eventsource_pb.DeleteEventSourceResponse
};

EventSourceService.Update = {
  methodName: "Update",
  service: EventSourceService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_eventsource_pb.UpdateEventSourceRequest,
  responseType: grpcweb_eventsource_pb.UpdateEventSourceResponse
};

exports.EventSourceService = EventSourceService;

function EventSourceServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

EventSourceServiceClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventSourceService.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EventSourceServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventSourceService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EventSourceServiceClient.prototype.get = function get(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventSourceService.Get, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EventSourceServiceClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventSourceService.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

EventSourceServiceClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(EventSourceService.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.EventSourceServiceClient = EventSourceServiceClient;

