import { SloState } from '../constants';

export const getSloState = (val: number, target: number): SloState => {
  if (val === -1 || !val) {
    return SloState.NotAvailable;
  }
  if (val <= target) {
    return SloState.Fail;
  }
  if (val > target) {
    return SloState.Success;
  }
  return SloState.NotAvailable;
};
