// source: grpcweb/xevent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var grpccommon_common_pb = require('../grpccommon/common_pb.js');
goog.object.extend(proto, grpccommon_common_pb);
goog.exportSymbol('proto.rct.web.ListEventsByComponentRequest', null, global);
goog.exportSymbol('proto.rct.web.ListEventsByComponentResponse', null, global);
goog.exportSymbol('proto.rct.web.XEvent', null, global);
goog.exportSymbol('proto.rct.web.XEvent.MetaField', null, global);
goog.exportSymbol('proto.rct.web.XEvent.Sentiment', null, global);
goog.exportSymbol('proto.rct.web.XEventBatch', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.XEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.XEvent.repeatedFields_, null);
};
goog.inherits(proto.rct.web.XEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.XEvent.displayName = 'proto.rct.web.XEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.XEvent.MetaField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.XEvent.MetaField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.XEvent.MetaField.displayName = 'proto.rct.web.XEvent.MetaField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.XEventBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.XEventBatch.repeatedFields_, null);
};
goog.inherits(proto.rct.web.XEventBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.XEventBatch.displayName = 'proto.rct.web.XEventBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListEventsByComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListEventsByComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListEventsByComponentRequest.displayName = 'proto.rct.web.ListEventsByComponentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListEventsByComponentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListEventsByComponentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListEventsByComponentResponse.displayName = 'proto.rct.web.ListEventsByComponentResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.XEvent.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.XEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.XEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.XEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.XEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startedat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sentiment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    metafieldsList: jspb.Message.toObjectList(msg.getMetafieldsList(),
    proto.rct.web.XEvent.MetaField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.XEvent}
 */
proto.rct.web.XEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.XEvent;
  return proto.rct.web.XEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.XEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.XEvent}
 */
proto.rct.web.XEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.rct.web.XEvent.Sentiment} */ (reader.readEnum());
      msg.setSentiment(value);
      break;
    case 8:
      var value = new proto.rct.web.XEvent.MetaField;
      reader.readMessage(value,proto.rct.web.XEvent.MetaField.deserializeBinaryFromReader);
      msg.addMetafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.XEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.XEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.XEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.XEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartedat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSentiment();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMetafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.rct.web.XEvent.MetaField.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.rct.web.XEvent.Sentiment = {
  SENTIMENT_UNSPECIFIED: 0,
  SENTIMENT_NEUTRAL: 1,
  SENTIMENT_INFO: 2,
  SENTIMENT_SUCCESS: 3,
  SENTIMENT_WARNING: 4,
  SENTIMENT_ERROR: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.XEvent.MetaField.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.XEvent.MetaField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.XEvent.MetaField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.XEvent.MetaField.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.XEvent.MetaField}
 */
proto.rct.web.XEvent.MetaField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.XEvent.MetaField;
  return proto.rct.web.XEvent.MetaField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.XEvent.MetaField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.XEvent.MetaField}
 */
proto.rct.web.XEvent.MetaField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.XEvent.MetaField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.XEvent.MetaField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.XEvent.MetaField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.XEvent.MetaField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.rct.web.XEvent.MetaField.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.XEvent.MetaField} returns this
 */
proto.rct.web.XEvent.MetaField.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.rct.web.XEvent.MetaField.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.XEvent.MetaField} returns this
 */
proto.rct.web.XEvent.MetaField.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.XEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.rct.web.XEvent.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.rct.web.XEvent.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.rct.web.XEvent.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 startedAt = 5;
 * @return {number}
 */
proto.rct.web.XEvent.prototype.getStartedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.setStartedat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.rct.web.XEvent.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Sentiment sentiment = 7;
 * @return {!proto.rct.web.XEvent.Sentiment}
 */
proto.rct.web.XEvent.prototype.getSentiment = function() {
  return /** @type {!proto.rct.web.XEvent.Sentiment} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.rct.web.XEvent.Sentiment} value
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.setSentiment = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated MetaField metaFields = 8;
 * @return {!Array<!proto.rct.web.XEvent.MetaField>}
 */
proto.rct.web.XEvent.prototype.getMetafieldsList = function() {
  return /** @type{!Array<!proto.rct.web.XEvent.MetaField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.XEvent.MetaField, 8));
};


/**
 * @param {!Array<!proto.rct.web.XEvent.MetaField>} value
 * @return {!proto.rct.web.XEvent} returns this
*/
proto.rct.web.XEvent.prototype.setMetafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.rct.web.XEvent.MetaField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.XEvent.MetaField}
 */
proto.rct.web.XEvent.prototype.addMetafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.rct.web.XEvent.MetaField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.XEvent} returns this
 */
proto.rct.web.XEvent.prototype.clearMetafieldsList = function() {
  return this.setMetafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.XEventBatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.XEventBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.XEventBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.XEventBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.XEventBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.rct.web.XEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.XEventBatch}
 */
proto.rct.web.XEventBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.XEventBatch;
  return proto.rct.web.XEventBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.XEventBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.XEventBatch}
 */
proto.rct.web.XEventBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.XEvent;
      reader.readMessage(value,proto.rct.web.XEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.XEventBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.XEventBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.XEventBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.XEventBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.XEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated XEvent events = 1;
 * @return {!Array<!proto.rct.web.XEvent>}
 */
proto.rct.web.XEventBatch.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.rct.web.XEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.XEvent, 1));
};


/**
 * @param {!Array<!proto.rct.web.XEvent>} value
 * @return {!proto.rct.web.XEventBatch} returns this
*/
proto.rct.web.XEventBatch.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.XEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.XEvent}
 */
proto.rct.web.XEventBatch.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.XEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.XEventBatch} returns this
 */
proto.rct.web.XEventBatch.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListEventsByComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListEventsByComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListEventsByComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventsByComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagerequest: (f = msg.getPagerequest()) && grpccommon_common_pb.PageRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListEventsByComponentRequest}
 */
proto.rct.web.ListEventsByComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListEventsByComponentRequest;
  return proto.rct.web.ListEventsByComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListEventsByComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListEventsByComponentRequest}
 */
proto.rct.web.ListEventsByComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponentid(value);
      break;
    case 2:
      var value = new grpccommon_common_pb.PageRequest;
      reader.readMessage(value,grpccommon_common_pb.PageRequest.deserializeBinaryFromReader);
      msg.setPagerequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListEventsByComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListEventsByComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListEventsByComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventsByComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagerequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpccommon_common_pb.PageRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string componentId = 1;
 * @return {string}
 */
proto.rct.web.ListEventsByComponentRequest.prototype.getComponentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.ListEventsByComponentRequest} returns this
 */
proto.rct.web.ListEventsByComponentRequest.prototype.setComponentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional rct.common.PageRequest pageRequest = 2;
 * @return {?proto.rct.common.PageRequest}
 */
proto.rct.web.ListEventsByComponentRequest.prototype.getPagerequest = function() {
  return /** @type{?proto.rct.common.PageRequest} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageRequest, 2));
};


/**
 * @param {?proto.rct.common.PageRequest|undefined} value
 * @return {!proto.rct.web.ListEventsByComponentRequest} returns this
*/
proto.rct.web.ListEventsByComponentRequest.prototype.setPagerequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListEventsByComponentRequest} returns this
 */
proto.rct.web.ListEventsByComponentRequest.prototype.clearPagerequest = function() {
  return this.setPagerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListEventsByComponentRequest.prototype.hasPagerequest = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListEventsByComponentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListEventsByComponentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListEventsByComponentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventsByComponentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batch: (f = msg.getBatch()) && proto.rct.web.XEventBatch.toObject(includeInstance, f),
    pageresponse: (f = msg.getPageresponse()) && grpccommon_common_pb.PageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListEventsByComponentResponse}
 */
proto.rct.web.ListEventsByComponentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListEventsByComponentResponse;
  return proto.rct.web.ListEventsByComponentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListEventsByComponentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListEventsByComponentResponse}
 */
proto.rct.web.ListEventsByComponentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.XEventBatch;
      reader.readMessage(value,proto.rct.web.XEventBatch.deserializeBinaryFromReader);
      msg.setBatch(value);
      break;
    case 2:
      var value = new grpccommon_common_pb.PageResponse;
      reader.readMessage(value,grpccommon_common_pb.PageResponse.deserializeBinaryFromReader);
      msg.setPageresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListEventsByComponentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListEventsByComponentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListEventsByComponentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListEventsByComponentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.XEventBatch.serializeBinaryToWriter
    );
  }
  f = message.getPageresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      grpccommon_common_pb.PageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional XEventBatch batch = 1;
 * @return {?proto.rct.web.XEventBatch}
 */
proto.rct.web.ListEventsByComponentResponse.prototype.getBatch = function() {
  return /** @type{?proto.rct.web.XEventBatch} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.XEventBatch, 1));
};


/**
 * @param {?proto.rct.web.XEventBatch|undefined} value
 * @return {!proto.rct.web.ListEventsByComponentResponse} returns this
*/
proto.rct.web.ListEventsByComponentResponse.prototype.setBatch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListEventsByComponentResponse} returns this
 */
proto.rct.web.ListEventsByComponentResponse.prototype.clearBatch = function() {
  return this.setBatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListEventsByComponentResponse.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional rct.common.PageResponse pageResponse = 2;
 * @return {?proto.rct.common.PageResponse}
 */
proto.rct.web.ListEventsByComponentResponse.prototype.getPageresponse = function() {
  return /** @type{?proto.rct.common.PageResponse} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.PageResponse, 2));
};


/**
 * @param {?proto.rct.common.PageResponse|undefined} value
 * @return {!proto.rct.web.ListEventsByComponentResponse} returns this
*/
proto.rct.web.ListEventsByComponentResponse.prototype.setPageresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.ListEventsByComponentResponse} returns this
 */
proto.rct.web.ListEventsByComponentResponse.prototype.clearPageresponse = function() {
  return this.setPageresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.ListEventsByComponentResponse.prototype.hasPageresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.rct.web);
