import { FunctionComponent, useState, useEffect, useRef, useMemo, ReactNode } from 'react';
import { useScrollLock, useWindowDimensions } from '../../hooks';
import * as Styled from './Modal.styles';

type ModalProps = {
  onClose?: () => void;
  isCloseVisible?: boolean;
  title?: string;
  maxWidth?: string;
  footer?: ReactNode;
  shouldUseSmartPositioning: boolean;
};

export const ModalView: FunctionComponent<ModalProps> = ({
  children,
  onClose = () => {},
  isCloseVisible = true,
  title,
  maxWidth,
  footer,
  shouldUseSmartPositioning = true,
}) => {
  useScrollLock();

  const [modalHeight, setModalHeight] = useState<number>(0);
  const { height } = useWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      setModalHeight(ref.current?.clientHeight);
    }
  }, [ref]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onClose]);

  const biggerHeight = useMemo(
    () => (height && modalHeight ? (height > modalHeight ? height : modalHeight) : undefined),
    [height, modalHeight],
  );
  const smallerHeight = useMemo(
    () => (height && modalHeight ? (height < modalHeight ? height : modalHeight) : undefined),
    [height, modalHeight],
  );
  return (
    <Styled.ModalPopup
      aria-modal="true"
      role="dialog"
      ref={ref}
      biggerHeight={biggerHeight}
      smallerHeight={smallerHeight}
      maxWidth={maxWidth}
      shouldUseSmartPositioning={shouldUseSmartPositioning}
    >
      {title && (
        <Styled.Header>
          <h3>{title}</h3>
          {isCloseVisible && <Styled.CloseButton onClick={onClose} />}
        </Styled.Header>
      )}
      <Styled.Outer>
        <Styled.Inner hasFooter={!!footer} hasTitle={!!title}>
          {children}
        </Styled.Inner>
      </Styled.Outer>
      {footer && <Styled.Footer>{footer}</Styled.Footer>}
    </Styled.ModalPopup>
  );
};
