import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import AddLinkForm from '../../forms/Link';
import { AddLinkFormData } from '../../definitions';
import { Icon } from '../../components/Icon';
import { FormLayout } from '../../components/Form';

type AddLinkProps = {
  onClose: () => void;
  isOpen: boolean;
  isAdding: boolean;
  onAddLink: (formData: AddLinkFormData) => void;
};

export const AddLinkView: FunctionComponent<AddLinkProps> = ({
  children,
  onClose,
  onAddLink,
  isAdding,
  ...rest
}) => {
  return (
    <Modal onClose={onClose} title="Add link" {...rest}>
      <FormLayout isModal={true}>
        <AddLinkForm
          onSubmit={onAddLink}
          initialValues={{}}
          isLoading={isAdding}
          action="Add"
          onCancel={onClose}
          icon={<Icon.Create />}
        />
      </FormLayout>
    </Modal>
  );
};
