import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CommentSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Meta = styled.p``;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;
`;

export const Divider = styled.span`
  margin: 0 0.375rem;
`;

export const AvatarWrapper = styled.div`
  flex-basis: 2.5rem;
  margin-right: 1rem;
`;
