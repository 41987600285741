import { FunctionComponent, InputHTMLAttributes, useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TagValue } from '../../../grpc/grpcweb/tag_pb';
import { Appearance, theme } from '../../../definitions';
import { Icon } from '../../../components/Icon';
import Button from '../../../components/Button';
import PopConfirm from '../../../components/PopConfirm';
import NewValue from '../NewValue';
import * as Styled from './ExistingValueInput.styles';

type ExistingValueInputProps = InputHTMLAttributes<HTMLInputElement> &
  FieldRenderProps<string> & {
    remoteErrorMessage?: string;
    showValidation?: boolean;
    onRemoveValue: (value: TagValue.AsObject) => void;
    onUpdateValue: (value: TagValue.AsObject, prevValue: TagValue.AsObject) => boolean;
    onClearError: () => void;
    tagValue: TagValue.AsObject;
    errorMessage?: string;
    maxWidth?: string;
    isUpdating: boolean;
    isDeleting: boolean;
    updatedValueSuccesfully: boolean;
    isCreateForm: boolean;
  };

export const ExistingValueInputView: FunctionComponent<ExistingValueInputProps> = ({
  meta,
  input,
  remoteErrorMessage,
  showValidation,
  errorMessage,
  onRemoveValue,
  onUpdateValue,
  onClearError,
  tagValue,
  maxWidth,
  isUpdating,
  isDeleting,
  updatedValueSuccesfully,
  isCreateForm,
}) => {
  const [value, setValue] = useState(tagValue);
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasUpdateError, setHasUpdateError] = useState(false);

  useEffect(() => {
    if (updatedValueSuccesfully && !hasUpdateError) {
      setIsEditMode(false);
    }
  }, [updatedValueSuccesfully, hasUpdateError]);

  const RemoveValueButton: FunctionComponent<{ value: TagValue.AsObject }> = ({ value }) => (
    <Button
      onClick={() => {
        onRemoveValue(value);
      }}
      appearance={Appearance.Icon}
      title="Remove"
      isPending={isDeleting}
      isDisabled={isUpdating}
    >
      <Icon.Trash />
    </Button>
  );

  const DeleteValueButton: FunctionComponent<{ value: TagValue.AsObject }> = ({ value }) => (
    <PopConfirm
      title={`Are you sure you want to delete the ${value.value} value?`}
      onConfirm={() => onRemoveValue(value)}
      isConfirming={isDeleting}
      isDisabled={isDeleting}
      contentText="The tag value cannot be deleted if it has been assigned to any entity."
      confirmColor={theme.palettes.error}
    >
      <Button
        appearance={Appearance.Icon}
        title="Delete"
        isPending={isDeleting}
        isDisabled={isUpdating}
      >
        <Icon.Trash />
      </Button>
    </PopConfirm>
  );

  const UpdateTagButton: FunctionComponent = () => (
    <Button
      onClick={() => {
        setIsEditMode(true);
      }}
      appearance={Appearance.Icon}
      title="Edit"
      isDisabled={isDeleting}
    >
      <Icon.Edit />
    </Button>
  );

  const CancelButton: FunctionComponent = () => (
    <Button
      onClick={() => {
        onClearError();
        setValue(tagValue);
        setIsEditMode(false);
      }}
      appearance={Appearance.PrimaryWithIcon}
    >
      <Icon.Cross />
      <span>Cancel</span>
    </Button>
  );

  const handleUpdate = (value: string, id?: string) => {
    const hasError = onUpdateValue({ value, id: id || '' }, tagValue);
    setHasUpdateError(hasError);
  };

  const handleSetValue = (value: string, id?: string) => {
    setValue({ value, id: id || '' });
  };

  if (isEditMode) {
    return (
      <Styled.Wrapper>
        <NewValue
          maxWidth={maxWidth}
          remoteErrorMessage={remoteErrorMessage}
          input={input}
          meta={meta}
          showValidation={showValidation}
          onSave={handleUpdate}
          errorMessage={errorMessage}
          tagValue={value.value}
          valueId={value.id}
          onSetValue={(value: string, valueId?: string) => handleSetValue(value, valueId)}
          actionTitle="Save"
          isLoading={isUpdating}
        >
          <CancelButton />
        </NewValue>
      </Styled.Wrapper>
    );
  }
  return (
    <Styled.Row maxWidth={maxWidth}>
      <Styled.CurrentValuesContainer>{value.value}</Styled.CurrentValuesContainer>
      <Styled.NoGapRow>
        {isCreateForm ? <RemoveValueButton value={value} /> : <DeleteValueButton value={value} />}
        <UpdateTagButton />
      </Styled.NoGapRow>
    </Styled.Row>
  );
};
