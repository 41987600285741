import { FunctionComponent, ElementType, useContext } from 'react';
import { Appearance } from '../../definitions';
import { MediaContext } from '../../contexts';
import { Icon } from '../Icon';
import * as Styled from './MetaPanel.styles';

type MetaPanelProps = {
  isCollapsed: boolean;
  onSetIsCollapsed: (newValue: boolean) => void;
  panelTriggerTooltip: string;
};

type IMetaPanel = FunctionComponent<MetaPanelProps> & {
  Header: ElementType;
  Content: ElementType;
};

export const MetaPanelView: IMetaPanel = ({
  children,
  isCollapsed,
  panelTriggerTooltip,
  onSetIsCollapsed,
}) => {
  const { isMediumLayout } = useContext(MediaContext);

  return (
    <Styled.Container isCollapsed={!isMediumLayout && isCollapsed}>
      {!isMediumLayout && (
        <Styled.PanelTrigger
          title={panelTriggerTooltip}
          isCollapsed={isCollapsed}
          appearance={Appearance.Icon}
          onClick={() => onSetIsCollapsed && onSetIsCollapsed(!isCollapsed)}
        >
          <Icon.Chevron />
        </Styled.PanelTrigger>
      )}
      <Styled.Panel isCollapsed={isCollapsed}>{children}</Styled.Panel>
    </Styled.Container>
  );
};

MetaPanelView.Header = Styled.Header;
MetaPanelView.Content = Styled.Content;
