import { FunctionComponent, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAuthenticated, logout } from '../../store';
import { usePageTitle } from '../../hooks';
import { NotFoundView } from './NotFound.view';

export const NotFoundViewContainer: FunctionComponent = () => {
  usePageTitle('Page not found');

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isAuthenticated);
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  return <NotFoundView isLoggedIn={isLoggedIn} onLogout={handleLogout} />;
};
