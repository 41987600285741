import { LOCATION_CHANGE } from 'connected-react-router';
import { Location } from 'history';
import { ActionType } from '../../definitions';

export type LocationState = {
  previous?: Location;
  current?: Location;
};

const initialState = {
  previous: undefined,
  current: undefined,
};

const location = (
  state: LocationState = initialState,
  { type, payload }: ActionType,
): LocationState => {
  switch (type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        previous: state.current,
        current: payload.location,
      };
    }

    default:
      return state;
  }
};

export default location;
