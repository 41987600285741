import { FunctionComponent, InputHTMLAttributes } from 'react';
import * as Styled from './RadioButtonGroup.styles';

export type RadioButtonGroupProps = InputHTMLAttributes<HTMLInputElement> & {};

export type RadioButtonItemProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  isDisabled?: boolean;
  isChecked: boolean;
  onClick: () => void;
};

export const RadioButtonItemView: FunctionComponent<RadioButtonItemProps> = ({
  label,
  isDisabled = false,
  isChecked,
  onClick,
}) => {
  return (
    <Styled.Wrapper checked={isChecked} disabled={isDisabled}>
      <Styled.RadioButton disabled={isDisabled} isChecked={isChecked} onClick={onClick}>
        {label}
      </Styled.RadioButton>
    </Styled.Wrapper>
  );
};

export const RadioButtonGroupView: FunctionComponent<RadioButtonGroupProps> = ({ children }) => {
  return <Styled.Container>{children}</Styled.Container>;
};
