import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSavedNewEventSource,
  updateNewEventSource,
  createdEventSourceSuccessfully,
  isCreatingEventSource,
  createEventSource,
  resetNewEventSource,
} from '../../store';
import {
  CreateSourceFormDataWithAuthType,
  FormStateType,
  EditSourceFormData,
} from '../../definitions';
import { CreateEventSourceView } from './CreateEventSource.view';

type CreateEventSourceContainerProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const CreateEventSourceContainer: FunctionComponent<CreateEventSourceContainerProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isCreating = useSelector(isCreatingEventSource);
  const finishedCreating = useSelector(createdEventSourceSuccessfully);
  const savedFormData = useSelector(getSavedNewEventSource);

  const handleCreateEventSource = useCallback(
    (formData: CreateSourceFormDataWithAuthType | EditSourceFormData) => {
      dispatch(createEventSource(formData));
    },
    [dispatch],
  );

  const handleUpdateEventSourceState = useCallback(
    (formData: FormStateType) => {
      dispatch(updateNewEventSource(formData));
    },
    [dispatch],
  );

  const handleResetEventSourceState = useCallback(() => {
    dispatch(resetNewEventSource());
  }, [dispatch]);

  useEffect(() => {
    if (finishedCreating) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedCreating]);

  return (
    <CreateEventSourceView
      isOpen={isOpen}
      onClose={onClose}
      isCreating={isCreating}
      onCreateEventSource={handleCreateEventSource}
      initialValues={savedFormData}
      onUpdateEventSourceState={handleUpdateEventSourceState}
      onResetState={handleResetEventSourceState}
    />
  );
};
