import { ElementType } from 'react';
import { Icon } from '../../components/Icon';
import { paths } from '../../definitions';

export type Fact = {
  label: string;
  text: string;
  icon: ElementType;
};

export type Integration = {
  tooltip: string;
  link?: string;
  icon: ElementType;
};
export const facts: Array<Fact> = [
  {
    label: 'Engineering Culture',
    text: 'Bring business, product and engineering on the same page and help balance work between features and reliability',
    icon: Icon.Trophy,
  },
  {
    label: 'SLO Lifecycle',
    text: 'Define, share, measure, monitor, calibrate, repeat!',
    icon: Icon.Cycle,
  },
  {
    label: 'SLO History',
    text: 'Review how SLO and Error Budgets change over time via powerful visualizations',
    icon: Icon.Graph,
  },
  {
    label: 'Data Sources',
    text: 'Continuously pull metrics from different Monitoring Systems',
    icon: Icon.Integration,
  },
  {
    label: 'Anomaly Detection',
    text: 'Keep track of Error Budget burns and SLO breaches',
    icon: Icon.Doctor,
  },
  {
    label: 'Overlay Events',
    text: 'Push events from external systems and match them against Error Budget burns or SLO breaches',
    icon: Icon.Analytics,
  },
  {
    label: 'Smart Wingman',
    text: 'Built-in expert system will guide you and help defining healthy SLO',
    icon: Icon.Wingman,
  },
  {
    label: 'Notifications',
    text: 'Never miss important events and proactively react on anomalies and changes',
    icon: Icon.Alert,
  },
  {
    label: 'REST API and Webhooks',
    text: 'Build custom integrations and automate workflows',
    icon: Icon.Tools,
  },
  {
    label: 'Reports',
    text: 'Get 360 view on SLO state, slice and dice by any dimension to get insights on dependencies, bottlenecks and trends',
    icon: Icon.PieChart,
  },
  {
    label: 'Tagging',
    text: 'Use our powerful tagging system to organize and control SLOs in the best possible way that fits your needs',
    icon: Icon.Paint,
  },
  {
    label: 'Customer Support',
    text: 'We are always here and ready to help',
    icon: Icon.CustomerSupport,
  },
];

export const integrations: Array<Integration> = [
  {
    tooltip: 'Datadog',
    link: paths.integrations.datadog,
    icon: Icon.DataDog,
  },
  {
    tooltip: 'SignalFx',
    link: paths.integrations.signalFx,
    icon: Icon.SignalFX,
  },
  {
    tooltip: 'New Relic',
    link: paths.integrations.newRelic,
    icon: Icon.NewRelic,
  },
  {
    tooltip: 'Prometheus',
    link: paths.integrations.prometheus,
    icon: Icon.Prometheus,
  },
  {
    tooltip: 'Graphite',
    link: paths.integrations.graphite,
    icon: Icon.Graphite,
  },
  {
    tooltip: 'Elasticsearch',
    link: paths.integrations.elasticSearch,
    icon: Icon.Elastic,
  },
  {
    tooltip: 'Amazon CloudWatch',
    link: paths.integrations.amazonCloudWatch,
    icon: Icon.Cloudwatch,
  },
];

export const tools: Array<Integration> = [
  {
    tooltip: 'Opsgenie',
    link: paths.integrations.opsgenie,
    icon: Icon.Opsgenie,
  },
  {
    tooltip: 'Slack',
    link: paths.integrations.slack,
    icon: Icon.Slack,
  },
  {
    tooltip: 'PagerDuty',
    link: paths.integrations.pagerDuty,
    icon: Icon.Pagerduty,
  },
  {
    tooltip: 'Any email provider',
    icon: Icon.EmailText,
  },
];