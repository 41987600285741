import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import TeamForm from '../../forms/Team';
import { TeamFormData, FormStateType } from '../../definitions';
import { FormLayout } from '../../components/Form';

type CreateTeamProps = {
  onClose: () => void;
  onResetState: () => void;
  isOpen: boolean;
  isCreating: boolean;
  onCreateTeam: (formData: TeamFormData) => void;
  initialValues: TeamFormData;
  onUpdateTeamState: (formState: FormStateType) => void;
};

export const CreateTeamView: FunctionComponent<CreateTeamProps> = ({
  children,
  onClose,
  onCreateTeam,
  isCreating,
  initialValues,
  onUpdateTeamState,
  onResetState,
  ...rest
}) => {
  const handleCancel = () => {
    onResetState();
    onClose();
  };

  return (
    <Modal onClose={onClose} title="Create team" {...rest}>
      <FormLayout isModal={true}>
        <TeamForm
          onSubmit={onCreateTeam}
          initialValues={initialValues}
          isLoading={isCreating}
          onUpdateTeamState={onUpdateTeamState}
          action="Create"
          onCancel={handleCancel}
          isNewTeam={true}
        />
      </FormLayout>
    </Modal>
  );
};
