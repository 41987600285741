import { FunctionComponent } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Appearance, TeamEnrichedComponentRefInTheList } from '../../definitions';
import { Loader } from '../../components/Loader';
import { Empty } from '../../components/Empty';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import CreateComponent from '../CreateComponent';
import Button from '../../components/Button';
import { Layout } from '../../components/Layout';
import { Icon } from '../../components/Icon';
import ComponentList from '../../lists/ComponentList';
import ComponentListFilterMenu from './Filters';

type ComponentListProps = {
  onViewComponent: (shortName?: string) => void;
  isFetchingComponents: boolean;
  componentList: TeamEnrichedComponentRefInTheList[];
  numberOfComponents: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  isCreateDrawerVisible: boolean;
  onCloseCreateComponent: () => void;
  onOpenCreateComponent: () => void;
  filterByType: string[] | undefined;
  filterByTeam: string[] | undefined;
  onClearAllFilters: () => void;
  filterByTag: { keyList: string[] | undefined; valueList: string[] | undefined };
  typeFilterQueryString: string;
  teamFilterQueryString: string;
  tagFilterQueryString: string;
} & RouteChildrenProps;

export const ComponentListView: FunctionComponent<ComponentListProps> = ({
  onViewComponent,
  isFetchingComponents,
  componentList,
  numberOfComponents,
  isNextPageDisabled,
  isPrevPageDisabled,
  onNextPage,
  onPrevPage,
  isCreateDrawerVisible,
  onCloseCreateComponent,
  onOpenCreateComponent,
  onClearAllFilters,
  filterByType,
  filterByTeam,
  filterByTag,
  typeFilterQueryString,
  teamFilterQueryString,
  tagFilterQueryString,
  ...rest
}) => {
  const CreateComponentAction: FunctionComponent<{ isCollapsible?: boolean }> = ({
    isCollapsible,
  }) => (
    <Button
      onClick={onOpenCreateComponent}
      appearance={
        isCollapsible ? Appearance.PrimaryWithIconCollapsible : Appearance.PrimaryWithIcon
      }
    >
      <Icon.Plus />
      <span>Create component</span>
    </Button>
  );

  const Header = () => (
    <Layout.Header>
      <Layout.HeaderTitle>Components</Layout.HeaderTitle>
      <Layout.HeaderActions>
        <CreateComponentAction isCollapsible={true} />
      </Layout.HeaderActions>
    </Layout.Header>
  );

  return (
    <>
      <AuthenticatedLayout header={Header}>
        <ComponentListFilterMenu
          isFetchingComponentList={isFetchingComponents}
          filterByType={filterByType}
          filterByTeam={filterByTeam}
          filterByTag={filterByTag}
          typeFilterQueryString={typeFilterQueryString}
          teamFilterQueryString={teamFilterQueryString}
          tagFilterQueryString={tagFilterQueryString}
          onClearAllFilters={onClearAllFilters}
          {...rest}
        />
        {isFetchingComponents ? (
          <Loader.Container>
            <Loader />
          </Loader.Container>
        ) : componentList.length ? (
          <ComponentList
            componentList={componentList}
            isFetching={isFetchingComponents}
            numberOfComponents={numberOfComponents}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            isPrevPageDisabled={isPrevPageDisabled}
            isNextPageDisabled={isNextPageDisabled}
            onViewComponent={onViewComponent}
            isTeamDataIncluded={true}
          />
        ) : (
          <Empty text="There are no components. Start by creating one.">
            <CreateComponentAction />
          </Empty>
        )}
      </AuthenticatedLayout>

      <CreateComponent visible={isCreateDrawerVisible} onHide={onCloseCreateComponent} {...rest} />
    </>
  );
};
