/**
 * Check min value
 * @param {number} min
 * @return {function}
 */
export const min =
  (min: number) =>
  (value: number): string | undefined =>
    min && value && value < min ? `Minimal value is ${min}.` : undefined;

/**
 * Check max value length
 * @param {number} max
 * @return {function}
 */
export const max =
  (max: number) =>
  (value: number): string | undefined =>
    max && value && value > max ? `Maximal value is ${max}.` : undefined;
