import { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { paths } from './definitions';
import { Theme } from './components/Theme';
import { DrawerRoute } from './components/DrawerRoute';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import { Alert } from './components/Alert';
import LoginScene from './pages/Login';
import NotFound from './pages/NotFound';
import Landing from './pages/Landing';
import CreateSlo from './pages/CreateSlo';
import ComponentList from './pages/ComponentList';
import Dashboard from './pages/Dashboard';
import Slo from './pages/Slo';
import EventSources from './pages/EventSources';
import SloList from './pages/SloList';
import Component from './pages/Component';
import Teams from './pages/Teams';
import Team from './pages/Team';
import Users from './pages/Users';
import Bots from './pages/Bots';
import Relations from './pages/Relations';
import Tags from './pages/TagList';

const App: FunctionComponent = () => {
  return (
    <Theme>
      <Alert />
      <Switch>
        <Route path={paths.landing} component={Landing} exact={true} />
        <AuthenticatedRoute
          exact={true}
          path={paths.createComponent}
          component={ComponentList}
          componentProps={{ isCreateDrawerOpen: true }}
          waitForUser={true}
          title="Create Component"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.createComponentFromDashboard}
          component={Dashboard}
          componentProps={{ isCreateDrawerOpen: true }}
          waitForUser={true}
          title="Create Component"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.editSlo}
          component={Slo}
          componentProps={{ isEditDrawerOpen: true }}
          waitForUser={true}
          title="Update SLO"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.updateComponent}
          component={Component}
          componentProps={{ isUpdateDrawerOpen: true }}
          waitForUser={true}
          title="Update Component"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.bot}
          component={Bots}
          componentProps={{ isEditDrawerOpen: true }}
          waitForUser={true}
          title="Edit Bot"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.components}
          component={ComponentList}
          waitForUser={true}
          title="Component List"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.editTag}
          componentProps={{ isEditDrawerOpen: true }}
          component={Tags}
          waitForUser={true}
          title="Edit Tag"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.createTag}
          component={Tags}
          componentProps={{ isCreateDrawerOpen: true }}
          waitForUser={true}
          title="Create Tag"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.tags}
          component={Tags}
          waitForUser={true}
          title="Tag List"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.dashboard}
          component={Dashboard}
          waitForUser={true}
          title="Home - Dashboard"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.relations}
          component={Relations}
          waitForUser={true}
          title="Relations"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.relationsSelectedComponent}
          component={Relations}
          waitForUser={true}
          title="Relations"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.slo}
          component={Slo}
          waitForUser={true}
          title="SLO details"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.eventSources}
          component={EventSources}
          waitForUser={true}
          title="Event Sources"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.component}
          component={Component}
          waitForUser={true}
          title="Component details"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.sloList}
          component={SloList}
          waitForUser={true}
          title="SLO List"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.team}
          component={Team}
          waitForUser={true}
          title="Team"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.teams}
          component={Teams}
          waitForUser={true}
          title="Team List"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.users}
          component={Users}
          waitForUser={true}
          title="User List"
        />
        <AuthenticatedRoute
          exact={true}
          path={paths.bots}
          component={Bots}
          waitForUser={true}
          title="Bots"
        />
        <Route path={paths.authenticate} exact={true} component={LoginScene} />
        <Route component={NotFound} />
      </Switch>
      <DrawerRoute path={paths.drawer.createSlo} component={CreateSlo} />
    </Theme>
  );
};

export default App;
