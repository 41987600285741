import { FunctionComponent } from 'react';
import * as Styled from './Image.styles';
import arrow from './arrow.png';
import astronaut from './astronaut.png';
import evgeny from './evgeny.png';
import flag from './flag.png';
import leo from './leo.png';
import loader from './loader.gif';
import logo from './logo.png';
import mila from './mila.png';
import nadia from './nadia.png';
import notFound from './notfound.png';
import serzh from './serzh.png';
import stars from './stars.png';
import tree from './tree.png';
import whiteLoader from './whiteLoader.gif';

export const images: { [key: string]: string } = {
  arrow,
  astronaut,
  evgeny,
  flag,
  leo,
  loader,
  logo,
  mila,
  nadia,
  notFound,
  serzh,
  stars,
  tree,
  whiteLoader,
};

type ImageProps = {
  name: string;
  alt?: string;
  background?: boolean;
};

export const ImageView: FunctionComponent<ImageProps> = ({
  name,
  background = false,
  alt,
  ...rest
}) => {
  if (background) {
    return <Styled.BackgroundImage style={{ backgroundImage: `url(${images[name]})` }} {...rest} />;
  }

  return (
    <Styled.Container src={images[name]} role="presentation" alt={alt ? alt : name} {...rest} />
  );
};
