import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

export const Group = styled.div`
  margin-bottom: 2rem;
  h4 {
    margin-bottom: 1rem;
  }
`;
