import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import { CreateBotForm } from '../../forms/Bot';
import { BotFormData, FormStateType } from '../../definitions';
import { FormLayout } from '../../components/Form';

type CreateBotViewProps = {
  onClose: () => void;
  onResetState: () => void;
  isOpen: boolean;
  isCreating: boolean;
  onCreate: (formData: BotFormData) => void;
  initialValues: BotFormData;
  onUpdateState: (formState: FormStateType) => void;
};

export const CreateBotView: FunctionComponent<CreateBotViewProps> = ({
  children,
  onClose,
  onCreate,
  isCreating,
  initialValues,
  onUpdateState,
  onResetState,
  ...rest
}) => {
  const handleCancel = () => {
    onResetState();
    onClose();
  };

  return (
    <Modal onClose={onClose} title="Create bot" {...rest}>
      <FormLayout isModal={true}>
        <CreateBotForm
          onSubmit={onCreate}
          initialValues={initialValues}
          isLoading={isCreating}
          onUpdateState={onUpdateState}
          onCancel={handleCancel}
          isNewBot={true}
        />
      </FormLayout>
    </Modal>
  );
};
