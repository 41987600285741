import { Icon } from '../../components/Icon';
import { paths, Route } from '../../definitions';

export const navigationRoutes: Array<Route> = [
  {
    label: 'Dashboard',
    path: paths.dashboard,
    icon: Icon.Dashboard,
  },
  {
    label: 'Components',
    path: paths.components,
    icon: Icon.Component,
  },
  {
    label: 'Relations',
    path: paths.relations,
    icon: Icon.Dependency,
  },
  {
    label: 'Tags',
    path: paths.tags,
    icon: Icon.Tag,
  },
  {
    label: 'SLO',
    path: paths.sloList,
    icon: Icon.Presentation,
  },
  {
    label: 'Event sources',
    path: paths.eventSources,
    icon: Icon.Plug,
  },
  {
    label: 'Teams',
    path: paths.teams,
    icon: Icon.Team,
  },
  {
    label: 'Users',
    path: paths.users,
    icon: Icon.Users,
  },
  {
    label: 'Bots',
    path: paths.bots,
    icon: Icon.Bot,
  },
];
