import { FunctionComponent, useMemo } from 'react';
import {
  FilterOption,
  TeamMap,
  Tags,
  TreeFilterOption,
  MappedTreeSelectOptions,
} from '../../../definitions';
import { CatalogItemView } from '../../../grpc/grpcweb/catalog_item_pb';
import FilterMenu from '../../../components/FilterMenu';

type ComponentListFiltersProps = {
  isFetchingComponentList: boolean;
  onSetFilterByTeam: (teamIds: string[]) => void;
  onSetFilterByType: (typeIds: string[]) => void;
  onClearAllFilters: () => void;
  componentTypes: CatalogItemView.AsObject[];
  selectedTypes?: string[];
  selectedTeams?: string[];
  isFetchingTypes: boolean;
  teamMap: TeamMap;
  numberOfActiveFilters: number;
  isFetchingTags: boolean;
  tagMap: Tags;
  onSetFilterByTag: (mappedOptions: MappedTreeSelectOptions) => void;
  selectedTags: { keyList: string[] | undefined; valueList: string[] | undefined };
};

export const ComponentListFiltersView: FunctionComponent<ComponentListFiltersProps> = ({
  isFetchingComponentList,
  onSetFilterByTeam,
  onSetFilterByType,
  componentTypes,
  selectedTypes = [],
  isFetchingTypes,
  selectedTeams = [],
  numberOfActiveFilters,
  onClearAllFilters,
  tagMap,
  isFetchingTags,
  onSetFilterByTag,
  selectedTags,
  teamMap,
}) => {
  const componentTypesOptions: FilterOption[] = useMemo(
    () =>
      componentTypes.map(type => ({
        value: type.id,
        label: type.metadata?.displayname || '',
      })),
    [componentTypes],
  );

  const componentTeamOptions: FilterOption[] = useMemo(
    () =>
      Object.values(teamMap).map(team => ({
        value: team.id,
        label: team.displayname || team.shortname || '',
      })),
    [teamMap],
  );

  const tagOptions: TreeFilterOption[] = useMemo(() => {
    return Object.values(tagMap).map(tag => ({
      value: tag.keyId || '',
      label: tag.tagKey || '',
      childrenOptions: tag.values.map(value => ({
        value: value.id || '',
        label: `${tag.tagKey}: ${value.value}` || '',
        childrenOptions: [],
        parentValue: tag.keyId || '',
        parentLabel: tag.tagKey || '',
      })),
    }));
  }, [tagMap]);

  return (
    <FilterMenu numberOfActiveFilters={numberOfActiveFilters} onClearAllFilters={onClearAllFilters}>
      <FilterMenu.Filter
        options={componentTypesOptions}
        onFilter={onSetFilterByType}
        selectedOptions={selectedTypes}
        isFetching={isFetchingTypes}
        label="Component type"
      />
      <FilterMenu.Filter
        options={componentTeamOptions}
        onFilter={onSetFilterByTeam}
        selectedOptions={selectedTeams}
        isFetching={isFetchingComponentList}
        label="Team"
      />
      <FilterMenu.TreeFilter
        options={tagOptions}
        onFilter={onSetFilterByTag}
        selectedOptions={selectedTags}
        isFetching={isFetchingTags}
        label="Tag"
      />
    </FilterMenu>
  );
};
