import { FunctionComponent } from 'react';
import { Icon } from '../Icon';
import { BannerAppearance } from '../../definitions';
import * as Styled from './Banner.styles';

export type BannerProps = {
  appearance?: BannerAppearance;
};

const getIcon = (type: BannerAppearance) => {
  switch (type) {
    case BannerAppearance.Danger:
      return <Icon.CrossRound />;
    case BannerAppearance.Warning:
      return <Icon.Caution />;
    case BannerAppearance.Info:
      return <Icon.Help />;
    default:
      return <Icon.CheckRound />;
  }
};

export const BannerView: FunctionComponent<BannerProps> = ({
  children,
  appearance = BannerAppearance.Info,
}) => (
  <Styled.WhiteBackground>
    <Styled.Container appearance={appearance}>
      {getIcon(appearance)}
      <Styled.ChildrenConatiner>{children}</Styled.ChildrenConatiner>
    </Styled.Container>
  </Styled.WhiteBackground>
);
