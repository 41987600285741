import { FunctionComponent } from 'react';
import { Modal } from '../../components/Modal';
import { Appearance, User } from '../../definitions';
import { Icon } from '../../components/Icon';
import AddUserTable from '../../tables/AddUserTable';

type AddMemberProps = {
  onClose: () => void;
  isOpen: boolean;
  isAdding: boolean;
  onAddUsers: () => void;
  isFetching: boolean;
  disabledUserIds: Set<string>;
  userList: User[];
  onNextPage: () => void;
  onPrevPage: () => void;
  numberOfUsers: number;
  isPrevPageDisabled: boolean;
  isNextPageDisabled: boolean;
  selectedUserState: Record<string, boolean>;
  onCheckUserCheckbox: (user: Record<string, boolean>) => void;
  numberOfSelectedUsers: number;
  onClearSelectedUsers: () => void;
};

export const AddMemberView: FunctionComponent<AddMemberProps> = ({
  children,
  selectedUserState,
  onClose,
  onAddUsers,
  isAdding,
  isFetching,
  disabledUserIds,
  userList,
  onNextPage,
  onPrevPage,
  numberOfUsers,
  isPrevPageDisabled,
  isNextPageDisabled,
  onCheckUserCheckbox,
  numberOfSelectedUsers,
  onClearSelectedUsers,
  ...rest
}) => {
  return (
    <Modal
      onClose={onClose}
      title="Add team members"
      shouldUseSmartPositioning={false}
      maxWidth="50rem"
      {...rest}
    >
      <AddUserTable
        selectedUserState={selectedUserState}
        isFetching={isFetching}
        disabledUserIds={disabledUserIds}
        userList={userList}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        numberOfUsers={numberOfUsers}
        isPrevPageDisabled={isPrevPageDisabled}
        isNextPageDisabled={isNextPageDisabled}
        onCheckUserCheckbox={onCheckUserCheckbox}
        numberOfSelectedUsers={numberOfSelectedUsers}
        onClearSelectedUsers={onClearSelectedUsers}
      />
      <Modal.Footer>
        <Modal.Action
          appearance={Appearance.PrimaryWithIcon}
          isDisabled={isAdding}
          onClick={onClose}
        >
          <Icon.Cross />
          <span>Cancel</span>
        </Modal.Action>
        <Modal.Action
          appearance={Appearance.PrimaryWithIcon}
          isHighlighted={true}
          isDisabled={!numberOfSelectedUsers}
          onClick={onAddUsers}
          isPending={isAdding}
        >
          <Icon.AddUser />
          <span>
            Add {numberOfSelectedUsers ? numberOfSelectedUsers : ''} member
            {numberOfSelectedUsers > 1 ? 's' : ''}
          </span>
        </Modal.Action>
      </Modal.Footer>
    </Modal>
  );
};
