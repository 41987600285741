import { FunctionComponent, useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteChildrenProps, generatePath } from 'react-router';
import { usePageTitle, useQueryParams } from '../../hooks';
import {
  getComponentList,
  fetchComponentList,
  isFetchingComponentList,
  fetchNextComponentListPage,
  isFetchingComponentListNextPage,
  isFetchingComponentListPrevPage,
  getTotalNumberOfComponents,
  getComponentListPrevPageToken,
  getComponentListNextPageToken,
  fetchPrevComponentListPage,
  getTeamMap,
} from '../../store';
import {
  ComponentRefInTheList,
  TeamEnrichedComponentRefInTheList,
  paths,
  getFilterQueryString,
  Filters,
  getFilter,
} from '../../definitions';
import { ComponentListView } from './ComponentList.view';

type ComponentListContainerProps = {
  isCreateDrawerOpen?: boolean;
} & RouteChildrenProps;

export const ComponentListContainer: FunctionComponent<ComponentListContainerProps> = ({
  isCreateDrawerOpen,
  ...rest
}) => {
  const dispatch = useDispatch();
  usePageTitle('Component list');
  const query = useQueryParams();

  const isFetchingComponents = useSelector(isFetchingComponentList);
  const isFetchingNextPage = useSelector(isFetchingComponentListNextPage);
  const isFetchingPrevPage = useSelector(isFetchingComponentListPrevPage);

  const componentList = useSelector(getComponentList);
  const numberOfComponents = useSelector(getTotalNumberOfComponents);
  const prevPageToken = useSelector(getComponentListPrevPageToken);
  const nextPageToken = useSelector(getComponentListNextPageToken);

  const teamMap = useSelector(getTeamMap);

  const componentListWithTeam: TeamEnrichedComponentRefInTheList[] = componentList.map(
    (component: ComponentRefInTheList) => ({
      ...component,
      team: component.ownerTeamId ? teamMap[component.ownerTeamId] : undefined,
    }),
  );

  const [isCreateDrawerVisible, setIsCreateDrawerVisible] = useState(false);

  useEffect(() => {
    if (isCreateDrawerOpen) {
      setIsCreateDrawerVisible(true);
    } else {
      setIsCreateDrawerVisible(false);
    }
  }, [isCreateDrawerOpen]);

  const handleViewComponent = useCallback(
    componentShortname => {
      componentShortname && dispatch(push(generatePath(paths.component, { componentShortname })));
    },
    [dispatch],
  );

  const filterByType: string[] | undefined = useMemo(() => getFilter(Filters.Type, query), [query]);
  const filterByTeam: string[] | undefined = useMemo(() => getFilter(Filters.Team, query), [query]);
  const keyList: string[] | undefined = useMemo(() => getFilter(Filters.TagKey, query), [query]);
  const valueList: string[] | undefined = useMemo(
    () => getFilter(Filters.TagValue, query),
    [query],
  );
  const filterByTag: { keyList: string[] | undefined; valueList: string[] | undefined } = useMemo(
    () => ({ keyList, valueList }),
    [keyList, valueList],
  );

  const typeFilterQueryString: string = useMemo(
    () => getFilterQueryString(Filters.Type, filterByType),
    [filterByType],
  );

  const teamFilterQueryString: string = useMemo(
    () => getFilterQueryString(Filters.Team, filterByTeam),
    [filterByTeam],
  );

  const tagFilterQueryString: string = useMemo(() => {
    if (keyList) {
      return `${getFilterQueryString(Filters.TagKey, keyList)}&${getFilterQueryString(
        Filters.TagValue,
        valueList,
      )}`;
    }
    return '';
  }, [keyList, valueList]);

  useEffect(() => {
    dispatch(
      fetchComponentList({
        includeTeamData: true,
        filterByTeam,
        filterByType,
        filterByTags:
          keyList && valueList && Boolean(keyList.length)
            ? {
                keyList,
                valueList,
              }
            : undefined,
      }),
    );
  }, [dispatch, filterByTeam, filterByType, keyList, valueList]);

  const handleFetchNextPage = useCallback(() => {
    dispatch(fetchNextComponentListPage({ includeTeamData: true }));
  }, [dispatch]);

  const handleFetchPrevPage = useCallback(() => {
    dispatch(fetchPrevComponentListPage({ includeTeamData: true }));
  }, [dispatch]);

  const handleOpenCreateComponent = useCallback(() => {
    dispatch(push(generatePath(paths.createComponent)));
  }, [dispatch]);

  const handleCloseCreateComponent = useCallback(() => {
    dispatch(push(generatePath(paths.components)));
  }, [dispatch]);

  const handleClearAllFilters = useCallback(() => {
    dispatch(push(paths.components));
  }, [dispatch]);

  return (
    <ComponentListView
      componentList={componentListWithTeam}
      onViewComponent={handleViewComponent}
      isFetchingComponents={isFetchingComponents || isFetchingNextPage || isFetchingPrevPage}
      onNextPage={handleFetchNextPage}
      onPrevPage={handleFetchPrevPage}
      numberOfComponents={numberOfComponents}
      isPrevPageDisabled={!prevPageToken}
      isNextPageDisabled={!nextPageToken}
      isCreateDrawerVisible={isCreateDrawerVisible}
      onOpenCreateComponent={handleOpenCreateComponent}
      onCloseCreateComponent={handleCloseCreateComponent}
      typeFilterQueryString={typeFilterQueryString}
      teamFilterQueryString={teamFilterQueryString}
      tagFilterQueryString={tagFilterQueryString}
      filterByType={filterByType}
      filterByTeam={filterByTeam}
      filterByTag={filterByTag}
      onClearAllFilters={handleClearAllFilters}
      {...rest}
    />
  );
};
