import styled from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.palettes.main};
  color: ${({ theme }) => theme.palettes.inverse};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  ${props => props.theme.media.medium`
    flex-direction: row;
  `}
`;

export const LoginButton = styled(Button)`
  width: 100%;
  margin: 1rem 0;
  svg {
    height: 1.25rem;
    width: 1.25rem;
    margin: 0 0.75em 0 0;
  }
`;

export const Caption = styled.div`
  font-size: 0.75rem;
  margin-top: 1rem;

  a, button {
    font-size: 0.75rem;
  }
`;
