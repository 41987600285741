import styled from 'styled-components';

export const OwnerTeamColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TeamAvatar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const EmptyTeam = styled.div<{ isValid: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border: 2px dashed
    ${props => (props.isValid ? props.theme.palettes.border : props.theme.palettes.error)};
  border-radius: 0.5rem;

  p {
    color: ${({ theme }) => theme.palettes.placeholder};
  }
`;
