import { FunctionComponent } from 'react';
import { TagValue } from '../../grpc/grpcweb/tag_pb';
import { Drawer } from '../../components/Drawer';
import TagForm from '../../forms/TagForm';
import { DrawerPosition, FormStateType, TagFormData, ColorTheme } from '../../definitions';

type CreateTagProps = {
  isCreating: boolean;
  isFetching: boolean;
  onReset: () => void;
  savedFormData: TagFormData;
  onUpdateTagState: (formState: FormStateType | TagFormData) => void;
  visible: boolean;
  onHide: () => void;
  onUpdateValues: (newValues: TagValue.AsObject[]) => void;
  tagValues: TagValue.AsObject[];
  onSubmit: (formData: TagFormData) => void;
  existingValueErrorMessage?: string;
  newValueErrorMessage?: string;
  onUpdateValue: (value: TagValue.AsObject, prevValue: TagValue.AsObject) => void;
  onRemoveValue: (value: TagValue.AsObject) => void;
  onCreateNewValue: (newValue: string) => void;
  onClearAllErrors: () => void;
  clickedSubmit: boolean;
  onSetClickedSubmit: (clicked: boolean) => void;
};

export const CreateTagView: FunctionComponent<CreateTagProps> = ({
  isCreating,
  savedFormData,
  visible,
  onHide,
  ...rest
}) => {
  return (
    <Drawer
      visible={visible}
      onHideMenu={onHide}
      position={DrawerPosition.Right}
      fullScreenOnSmall={true}
      colorTheme={ColorTheme.Light}
      title="Create tag"
    >
      <TagForm
        initialValues={savedFormData}
        isLoading={isCreating}
        action="Create"
        onCancel={onHide}
        isCreateForm={true}
        {...rest}
      />
    </Drawer>
  );
};
