import alpha from './alpha';
import alphanumeric from './alphanumeric';
import color from './color';
import decimal from './decimal';
import number from './number';
import slug from './slug';
import trim from './trim';
import url from './url';

const parsers = {
  alpha,
  alphanumeric,
  color,
  decimal,
  number,
  slug,
  trim,
  url,
};

export default parsers;
