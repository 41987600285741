import { URLSearchParams } from 'url';
import { Filters } from '../constants/Filters';

export const getFilter = (filterName: Filters, query: URLSearchParams): string[] | undefined => {
  let filters: string[] | undefined = undefined;
  let currentFilter = query.get(filterName) || undefined;
  if (currentFilter) {
    filters = [currentFilter];
    let index = 1;
    while (!!currentFilter) {
      currentFilter = query.get(`${filterName}${index}`) || undefined;
      currentFilter && filters.push(currentFilter);
      index++;
    }
  }

  return filters;
};

export const getFilterQueryString = (filterName: Filters, filterArray?: string[]) => {
  if (filterArray) {
    return filterArray.reduce((acc: string, typeId: string, indx) => {
      return `${acc ? `${acc}&` : ''}${filterName}${indx > 0 ? indx : ''}=${typeId}`;
    }, '');
  } else {
    return '';
  }
};

export const buildQuery = (params: string[]) => {
  return params.reduce((acc: string, param: string) => {
    if (param && !acc) {
      return `?${param}`;
    } else if (param) {
      return `${acc}&${param}`;
    } else {
      return acc;
    }
  }, '');
};
