import { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import { usePortal } from '../../hooks';
import { Loader } from '../../components/Loader';
import * as Styled from './Loading.styles';

type LoadingProps = {
  title?: string;
  hasOverlay?: boolean;
};

export const LoadingView: FunctionComponent<LoadingProps> = ({
  title,
  hasOverlay = false,
  ...rest
}) => {
  const target = usePortal('loader');
  const content = (
    <Styled.Wrapper overlay={hasOverlay} {...rest}>
      <Loader.Container>
        <Loader />
      </Loader.Container>
      <Styled.Text>{title || 'Loading...'}</Styled.Text>
    </Styled.Wrapper>
  );

  if (hasOverlay) {
    return createPortal(content, target);
  }

  return content;
};
