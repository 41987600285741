import styled, { css } from 'styled-components';
import { darken, lighten, rgba } from 'polished';

type WrapperProps = {
  color?: string;
  isCompact?: boolean;
};

const HorizontalSection = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0;
  padding: 0.75rem;
`;

export const UpperSection = styled(HorizontalSection)`
  background-color: ${({ theme }) => theme.palettes.headerBackground};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  align-items: center;
`;

export const MidSection = styled(HorizontalSection)`
  flex-grow: 1;
  padding-bottom: 0.5rem;
`;

export const LowerSection = styled(HorizontalSection)``;

const VerticalSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: flex-start;
  min-width: 0;
`;

export const Number = styled(VerticalSection)`
  align-items: flex-end;
  font-size: 1.375rem;
  color: ${({ theme }) => theme.palettes.placeholder};
  flex-shrink: 0;
  flex-grow: 1;
  padding-left: 0.5rem;
`;

export const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  align-items: center;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    margin: 0;
    color: inherit;
    font-size: 0.875rem;
    cursor: pointer;
    color: ${props => props.theme.palettes.secondaryHighlight};
    display: inline-block;
    &:hover {
      text-decoration: underline;
      color: ${props => darken(0.1, props.theme.palettes.secondaryHighlight)};
    }
  }
`;

export const OwnerContainer = styled.div`
  margin-left: 0.5rem;
`;

export const Wrapper = styled.div<WrapperProps>`
  flex: 1;
  text-align: left;
  border-radius: 0.5rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => lighten(0.25, theme.palettes.inverse)};
  position: relative;

  ${props =>
    props.color &&
    css`
      border-color: ${props.color};
      background: ${rgba(props.color, 0.04)};
      ${Number} {
        color: ${props.color};
      }
    `}

  ${UpperSection} {
    flex-basis: 3rem;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const NewTagWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const CardWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.effects.borderShadow};
  border: 1px solid ${({ theme }) => lighten(0.15, theme.palettes.border)};
  border-radius: 0.5rem;
`;

export const CardContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(10em, 20em));
`;
