import { FunctionComponent, useRef } from 'react';
import { AvatarType, AvatarAppearance, theme } from '../../definitions';
import { useIsEllipsisActive } from '../../hooks';
import Avatar from '../Avatar';
import Tooltip from '../Tooltip';
import * as Styled from './AvatarWithTitle.styles';

type AvatarWithTitleViewProps = {
  name: string;
  avatarSrc?: string;
  avatarType?: AvatarType;
  avatarAppearance?: AvatarAppearance;
  onNameClick?: () => void;
  title?: string;
  hasBorder?: boolean;
};

export const AvatarWithTitleView: FunctionComponent<AvatarWithTitleViewProps> = ({
  name,
  avatarSrc,
  avatarType = AvatarType.Team,
  onNameClick,
  avatarAppearance,
  title,
  hasBorder = true,
}) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const shouldDisplayTooltip = useIsEllipsisActive(ref);

  return (
    <Styled.AvatarWrapper
      isNameClickable={Boolean(onNameClick)}
      isSmall={avatarAppearance === AvatarAppearance.Small}
    >
      <Avatar
        avatarSrc={avatarSrc}
        name={name}
        avatarType={avatarType}
        appearance={avatarAppearance}
        hasBorder={hasBorder}
      />
      {shouldDisplayTooltip ? (
        <Tooltip content={name} color={theme.palettes.main}>
          <h5 ref={ref} onClick={onNameClick} title={title}>
            {name}
          </h5>
        </Tooltip>
      ) : (
        <h5 ref={ref} onClick={onNameClick} title={title}>
          {name}
        </h5>
      )}
    </Styled.AvatarWrapper>
  );
};
