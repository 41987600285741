import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { generatePath, RouteChildrenProps } from 'react-router';
import {
  Appearance,
  colors,
  CommentData,
  EditSloFormData,
  getSLOActivityDataForList,
  paths,
  PublishCommentFormData,
  SLOActivity,
  SLOHistoryPoint,
} from '../../definitions';
import { getSloStatusLabel } from '../../grpcMappings';
import { Activity, SloStatus } from '../../grpc/grpcweb/slo_pb';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { Layout } from '../../components/Layout';
import { Icon } from '../../components/Icon';
import Overlay from '../../components/Overlay';
import Button from '../../components/Button';
import CommentSection from '../../components/CommentSection';
import { Breadcrumbs, buildBreadcrumb } from '../../components/Breadcrumbs';
import List from '../../components/List';
import EditSlo from '../EditSlo';
import * as Styled from './Slo.styles';

type SloProps = {
  slo: EditSloFormData;
  isEditDrawerVisible?: boolean;
  onCloseEditSlo: () => void;
  onOpenEditSlo: () => void;
  onSloLaunch: () => void;
  onPublishComment: (comment: PublishCommentFormData) => void;
  displayName?: string;
  description?: string;
  isLaunchingSLO: boolean;
  isLoadingHistory: boolean;
  isLoadingActivity: boolean;
  isLoadingSloComments: boolean;
  isPublishingSloComment: boolean;
  sloHistory: SLOHistoryPoint[];
  sloActivity: SLOActivity[];
  sloComments: CommentData[];
  componentDisplayName?: string;
  componentShortname?: string;
} & RouteChildrenProps;

export const SloView: FunctionComponent<SloProps> = ({
  slo,
  onCloseEditSlo,
  onOpenEditSlo,
  onSloLaunch,
  onPublishComment,
  displayName,
  description,
  isEditDrawerVisible = false,
  isLaunchingSLO,
  sloHistory,
  isLoadingActivity,
  isLoadingHistory,
  isLoadingSloComments,
  isPublishingSloComment,
  sloActivity,
  sloComments,
  componentDisplayName,
  componentShortname,
  ...rest
}) => {
  const [minYDomain, setMinYDomain] = useState(0);
  const [maxYDomain, setMaxYDomain] = useState(100);

  useEffect(() => {
    const sloValueMap = sloHistory.map(historyPoint => historyPoint.sloValue);
    const min = Math.min(...sloValueMap);
    const max = Math.max(...sloValueMap);
    if (max > -1000 && min > -1000) {
      setMinYDomain(min !== -1 ? Math.floor(min) : 0);
      setMaxYDomain(max !== -1 ? Math.ceil(max) : 100);
    }
  }, [sloHistory]);

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: {
    active: boolean;
    payload: Array<any>;
    label: string;
  }) => {
    if (active && payload && payload.length) {
      const activity = payload[0]?.payload?.activity;
      return (
        <Styled.TooltipContainer className="custom-tooltip">
          <h6>{`${label} : ${payload[0].value !== -1 ? `${payload[0].value}%` : 'N/A'}`}</h6>
          {activity && activity.map((act: string) => <p key={act}>{act}</p>)}
        </Styled.TooltipContainer>
      );
    }

    return null;
  };

  const EditSloButton: FunctionComponent = () => (
    <Button onClick={onOpenEditSlo} appearance={Appearance.PrimaryWithIconCollapsible}>
      <Icon.Edit />
      <span>Update SLO</span>
    </Button>
  );

  const getSLOActions = (): Array<ReactNode> => {
    let sloActions: Array<ReactNode> = [<EditSloButton key="editSlo" />];
    if (slo.status === SloStatus.SLO_STATUS_DRAFT || slo.status === SloStatus.SLO_STATUS_PAUSED) {
      const LaunchSLOButton: FunctionComponent = () => (
        <Button
          onClick={onSloLaunch}
          isPending={isLaunchingSLO}
          appearance={Appearance.PrimaryWithIconCollapsible}
        >
          <Icon.Rocket />
          <span>Launch SLO</span>
        </Button>
      );
      sloActions.push(<LaunchSLOButton key="launchSlo" />);
    }
    return sloActions;
  };

  const activitiesForList = useMemo(() => {
    return sloActivity.map((activity: SLOActivity) => {
      const { type } = activity;
      const changedFrom =
        type === Activity.Type.TYPE_SLO_STATUS_CHANGED
          ? getSloStatusLabel(activity.sloStatusChanged?.prevstatus)
          : activity.sloObjectiveChanged?.prevobjective || 0;
      const changedTo =
        type === Activity.Type.TYPE_SLO_STATUS_CHANGED
          ? getSloStatusLabel(activity.sloStatusChanged?.currentstatus)
          : activity.sloObjectiveChanged?.currentobjective || 0;
      const activityDataForList = getSLOActivityDataForList(activity, type, changedFrom, changedTo);
      return activityDataForList;
    });
  }, [sloActivity]);

  const getBreadcrumbs = () => {
    return [
      buildBreadcrumb({ name: 'Components', path: paths.components, icon: Icon.Home }),
      buildBreadcrumb({
        name: componentDisplayName || componentShortname || '',
        path: componentShortname
          ? generatePath(paths.component, { componentShortname })
          : paths.sloList,
      }),
      buildBreadcrumb({
        name: slo.displayName || slo.shortName || '',
        path: paths.sloList,
        isCurrent: true,
      }),
    ];
  };

  const Header = () => (
    <Layout.Header>
      <Breadcrumbs items={getBreadcrumbs()} />
      <Layout.HeaderActions>{getSLOActions()}</Layout.HeaderActions>
    </Layout.Header>
  );

  return (
    <>
      <AuthenticatedLayout header={Header}>
        {description && <Layout.SubHeading>{description}</Layout.SubHeading>}
        <Layout.Heading
          icon={<Icon.Chart />}
          subheading="The event graph represents the percentage of bad events out of the total number of events. Hover over the data points to view more details."
        >
          Events
        </Layout.Heading>
        <Layout.Section height="30rem">
          <ResponsiveContainer>
            <ComposedChart
              width={500}
              height={400}
              data={sloHistory}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="startString"
                label={{ value: 'Time', position: 'insideBottomRight', offset: 0 }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                stroke="#8884d8"
                type="number"
                domain={[minYDomain, maxYDomain]}
                label={{ value: 'SLO', angle: 90, position: 'insideRight' }}
              />
              {/* @ts-ignore */}
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar yAxisId="right" dataKey="sloValue" name="SLO" barSize={20}>
                {sloHistory.map((point: SLOHistoryPoint) => (
                  <Cell fill={point.activity ? colors.gold : colors.elsa} key={point.sloValue} />
                ))}
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Layout.Section>
        <Layout.Heading
          icon={<Icon.Comment />}
          subheading="Users can leave their comments here regarding SLO, its events, and activities."
        >
          Comments
        </Layout.Heading>
        <Layout.Section>
          <CommentSection
            onPublish={onPublishComment}
            comments={sloComments}
            isLoading={isLoadingSloComments}
            isPublishing={isPublishingSloComment}
          />
        </Layout.Section>

        <Layout.Heading
          icon={<Icon.Feed />}
          subheading="The activity stream represents all changes, such as SLO breaches, recovery, changes in objectives or status, as well as information about the change author and time."
        >
          Activity stream
        </Layout.Heading>
        <Layout.Section>
          <List
            isLoading={isLoadingActivity || isLoadingHistory}
            items={activitiesForList}
            columns={['time', 'type', 'from', 'to', 'changedBy']}
            hideOnSmall={['from', 'changedBy']}
            itemKey="time"
            emptyText="SLO doesn't have any activity yet."
          />
        </Layout.Section>
        <EditSlo visible={isEditDrawerVisible} onHide={onCloseEditSlo} {...rest} />
        <Overlay onClick={onCloseEditSlo} isVisible={isEditDrawerVisible} />
      </AuthenticatedLayout>
    </>
  );
};
