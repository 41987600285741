import { BotInTheList, BotFormData, Token, TokenInTheList } from '../../definitions';
import actionTypes from '../constants/actionTypes';
import { wasSuccessful } from './success';
import { isLoading } from './loading';
import { RootState } from '../reducers';
import { getErrorMessage } from './errors';

export const getBotList = (state: RootState): BotInTheList[] => {
  return state.bots.list;
};

export const getSelectedBotToken = (state: RootState): TokenInTheList => {
  return state.bots.selected.selectedToken;
};

export const getSelectedBotTokenId = (state: RootState): string | undefined => {
  return state.bots.selected.selectedToken.id;
};

export const isFetchingBotList = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.fetchList);
};

export const getFetchBotListError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.fetchList);
};

export const isFetchingBot = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.current.fetch);
};

export const getFetchBotError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.current.fetch);
};

export const isSelectingBotToken = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.current.selectToken);
};

export const getSelectTokenError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.current.selectToken);
};

export const getSelectedBot = (state: RootState): BotInTheList => {
  return state.bots.selected;
};

export const getCreatedBotToken = (state: RootState): Token => {
  return state.bots.selected.createdToken;
};

export const getSelectedBotId = (state: RootState): string | undefined => {
  return state.bots.selected.id;
};

export const getSelectedBotShortname = (state: RootState): string | undefined => {
  return state.bots.selected.shortname;
};

export const getCreatedTokenId = (state: RootState): string | undefined => {
  return state.bots.selected.createdToken.id;
};

export const getSavedNewBot = (state: RootState): BotFormData => {
  return state.bots.new;
};

export const isCreatingBot = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.create);
};

export const getCreateBotError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.create);
};

export const createdBotSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.bots.create);
};

export const isUpdatingBot = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.current.update);
};

export const getUpdateBotError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.current.update);
};

export const updatedBotSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.bots.current.update);
};

export const getDeleteBotError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.current.delete);
};

export const isDeletingBot = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.current.delete);
};

export const deletedBotSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.bots.current.delete);
};

export const getDeleteBotTokenError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.current.deleteToken);
};

export const isDeletingBotToken = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.current.deleteToken);
};

export const deletedBotTokenSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.bots.current.deleteToken);
};

export const getCreateBotTokenError = (state: RootState): string => {
  return getErrorMessage(state, actionTypes.bots.current.createToken);
};

export const isCreatingBotToken = (state: RootState): boolean => {
  return isLoading(state, actionTypes.bots.current.createToken);
};

export const createdBotTokenSuccessfully = (state: RootState): boolean => {
  return wasSuccessful(state, actionTypes.bots.current.createToken);
};
