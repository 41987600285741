import { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAlertItems, hideAlert } from '../../store';
import { AlertObject } from '../../definitions';
import { AlertView } from './Alert.view';

export type AlertProps = {
  items?: AlertObject[];
  onClose?: (item: AlertObject) => void;
};

export const AlertContainer: FunctionComponent<AlertProps> = props => {
  const dispatch = useDispatch();
  const alerts = useSelector(getAlertItems);
  const handleClose = useCallback(
    payload => {
      dispatch(hideAlert(payload));
    },
    [dispatch],
  );

  return <AlertView onClose={handleClose} items={alerts} {...props} />;
};
