import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFetchingComponentList,
  fetchComponentList,
  getComponentList,
  getComponentDependencies,
  getComponentDependents,
  isFetchingComponentDependencies,
  clearSelectedDependencies,
  isFetchingComponentListNextPage,
  isFetchingComponentListPrevPage,
  getNumberOfSelectedDependencies,
  getSelectedDependencyState,
  getTotalNumberOfComponents,
  getComponentListPrevPageToken,
  getComponentListNextPageToken,
  fetchNextComponentListPage,
  fetchPrevComponentListPage,
  saveSelectedDependencyIds,
  linkedDependenciesSuccessFully,
  linkedDependentsSuccessFully,
} from '../../store';
import { DEPENDENCY_COMPONENT_PAGE_SIZE } from '../../store/constants/pageSize';
import { ComponentRefInTheListWithDependencyState, DependencyState } from '../../definitions';
import { LinkDependencyView } from './LinkDependency.view';

type LinkDependencyContainerProps = {
  onClose: () => void;
  isOpen: boolean;
  componentName?: string;
  componentId: string;
  isLinking: boolean;
  onLink: (dependencyIds: string[]) => void;
  disabledComponentIds: Set<string>;
  title: string;
  getActionTitle: (numberOfSelectedComponents: number) => string;
};

export const LinkDependencyContainer: FunctionComponent<LinkDependencyContainerProps> = ({
  isOpen,
  onClose,
  componentName,
  componentId,
  onLink,
  ...rest
}) => {
  const dispatch = useDispatch();

  const isFetchingDependencies = useSelector(isFetchingComponentDependencies);
  const isFetchingComponents = useSelector(isFetchingComponentList);
  const isFetchingNextPage = useSelector(isFetchingComponentListNextPage);
  const isFetchingPrevPage = useSelector(isFetchingComponentListPrevPage);

  const componentList = useSelector(getComponentList);
  const dependencies = useSelector(getComponentDependencies);
  const dependents = useSelector(getComponentDependents);

  const finishedLinkingDependencies = useSelector(linkedDependenciesSuccessFully);
  const finishedLinkingDependents = useSelector(linkedDependentsSuccessFully);

  const numberOfSelectedDependencies = useSelector(getNumberOfSelectedDependencies);
  const selectedDependencyState = useSelector(getSelectedDependencyState);

  const numberOfComponents = useSelector(getTotalNumberOfComponents);
  const prevPageToken = useSelector(getComponentListPrevPageToken);
  const nextPageToken = useSelector(getComponentListNextPageToken);

  const handleLink = useCallback(() => {
    const componentIds = Object.keys(selectedDependencyState).filter((componentId: string) => {
      return selectedDependencyState[componentId] === true;
    });

    onLink(componentIds);
  }, [selectedDependencyState, onLink]);

  const handleFetchNextPage = useCallback(() => {
    dispatch(fetchNextComponentListPage({ pageSize: DEPENDENCY_COMPONENT_PAGE_SIZE }));
  }, [dispatch]);

  const handleFetchPrevPage = useCallback(() => {
    dispatch(fetchPrevComponentListPage({ pageSize: DEPENDENCY_COMPONENT_PAGE_SIZE }));
  }, [dispatch]);

  const handleCheckComponentCheckbox = useCallback(
    (selectedComponent: Record<string, boolean>) => {
      dispatch(saveSelectedDependencyIds(selectedComponent));
    },
    [dispatch],
  );

  const handleClearSelectedComponents = useCallback(() => {
    dispatch(clearSelectedDependencies());
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchComponentList({ pageSize: DEPENDENCY_COMPONENT_PAGE_SIZE }));
      dispatch(clearSelectedDependencies());
    }
  }, [dispatch, isOpen]);

  const getComponentsToLink = (): ComponentRefInTheListWithDependencyState[] => {
    const dependencyIds = new Set();
    const dependentIds = new Set();
    dependencies.forEach(dependency => dependencyIds.add(dependency.id));
    dependents.forEach(dependant => dependentIds.add(dependant.id));

    const filteredComponents = componentList.filter(dependency => dependency.id !== componentId);

    return filteredComponents.map(component => {
      const dependency = {
        ...component,
      };
      if (dependencyIds.has(component.id)) {
        return {
          ...dependency,
          dependencyState: DependencyState.Dependency,
        };
      } else if (dependentIds.has(component.id)) {
        return {
          ...dependency,
          dependencyState: DependencyState.Dependant,
        };
      }
      return dependency;
    });
  };

  useEffect(() => {
    if (finishedLinkingDependencies || finishedLinkingDependents) {
      onClose();
    }
  }, [onClose, finishedLinkingDependencies, finishedLinkingDependents]);

  return (
    <LinkDependencyView
      onClose={onClose}
      isFetching={
        isFetchingComponents || isFetchingDependencies || isFetchingNextPage || isFetchingPrevPage
      }
      componentName={componentName}
      componentList={getComponentsToLink()}
      componentId={componentId}
      onNextPage={handleFetchNextPage}
      onPrevPage={handleFetchPrevPage}
      numberOfComponents={numberOfComponents}
      isPrevPageDisabled={!prevPageToken}
      isNextPageDisabled={!nextPageToken}
      onCheckComponentCheckbox={handleCheckComponentCheckbox}
      numberOfSelectedComponents={numberOfSelectedDependencies}
      onClearSelectedComponents={handleClearSelectedComponents}
      selectedDependencyState={selectedDependencyState}
      isOpen={isOpen}
      onLink={handleLink}
      {...rest}
    />
  );
};
