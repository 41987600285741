import { FunctionComponent, useState } from 'react';
import { Layout } from '../../components/Layout';
import List from '../../components/List';
import Button from '../../components/Button';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import { FlattenedTeam, paths, Appearance, User, AvatarType } from '../../definitions';
import { Breadcrumbs, buildBreadcrumb } from '../../components/Breadcrumbs';
import { Icon } from '../../components/Icon';
import AddMemberModal from '../../modals/AddMember';
import UpdateTeamModal from '../../modals/UpdateTeam';
import PopConfirm from '../../components/PopConfirm';
import AvatarWithTitle from '../../components/AvatarWithTitle';

type TeamViewProps = {
  isFetchingTeam: boolean;
  team: FlattenedTeam;
  onRemoveUser: (userId: string) => void;
  isRemovingUser: boolean;
};

export const TeamView: FunctionComponent<TeamViewProps> = ({
  team,
  isFetchingTeam,
  onRemoveUser,
  isRemovingUser,
}) => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isUpdateTeamModalOpen, setIsUpdateTeamModalOpen] = useState(false);

  const { displayname, description, users, shortname } = team;

  const Header = () => (
    <Layout.Header>
      <Breadcrumbs
        items={[
          buildBreadcrumb({ name: 'Teams', path: paths.teams, icon: Icon.Team }),
          buildBreadcrumb({
            name: displayname || shortname || '',
            path: paths.team,
            isCurrent: true,
          }),
        ]}
      />
      <Layout.HeaderActions>
        <Button
          appearance={Appearance.PrimaryWithIconCollapsible}
          onClick={() => setIsAddUserModalOpen(true)}
        >
          <Icon.AddUser />
          <span>Add members</span>
        </Button>
        <Button
          appearance={Appearance.PrimaryWithIconCollapsible}
          onClick={() => setIsUpdateTeamModalOpen(true)}
        >
          <Icon.Edit />
          <span>Update team</span>
        </Button>
      </Layout.HeaderActions>
    </Layout.Header>
  );

  const DeleteMember = (user: User) => (
    <PopConfirm
      onConfirm={() => onRemoveUser(user.id)}
      title={`Are you sure you want to delete ${user.username} from the team?`}
      contentText="The user will lose access to the team resources. The action is reversable by adding user back to the team."
    >
      <Button appearance={Appearance.Icon} isPending={isRemovingUser}>
        <Icon.Trash />
      </Button>
    </PopConfirm>
  );

  const UserAvatar: FunctionComponent<User> = ({ avatar, username }) => (
    <AvatarWithTitle name={username} avatarType={AvatarType.User} avatarSrc={avatar?.uri} />
  );

  return (
    <>
      <AuthenticatedLayout header={Header}>
        <Layout.SubHeading>{description}</Layout.SubHeading>
        <Layout.Heading
          icon={<Icon.Team />}
          subheading="Team members are users assigned to the selected team. Please note that a team with assigned members cannot be deleted."
        >
          Members
        </Layout.Heading>
        <Layout.Section>
          <List
            items={users}
            columnTitles={['User', 'Email', 'Full name', '']}
            columns={[UserAvatar, 'email', 'fullname', DeleteMember]}
            hideOnSmall={['fullname']}
            itemKey="id"
            isLoading={isFetchingTeam}
            emptyText="This team doesn't have any members yet."
          />
        </Layout.Section>

        <AddMemberModal isOpen={isAddUserModalOpen} onClose={() => setIsAddUserModalOpen(false)} />
        <UpdateTeamModal
          isOpen={isUpdateTeamModalOpen}
          onClose={() => setIsUpdateTeamModalOpen(false)}
        />
      </AuthenticatedLayout>
    </>
  );
};
