import { FunctionComponent, ElementType, ReactElement } from 'react';
import {
  Appearance,
  AvatarAppearance,
  ComponentInTheList,
  ComponentRefInTheList,
  TagAppearance,
} from '../../definitions';
import { Tag } from '../Tag';
import { AvatarType } from '../../definitions';
import { Icon } from '../Icon';
import AvatarWithTitle from '../AvatarWithTitle';
import ComponentWidget from '../ComponentWidget';
import * as Styled from './ComponentCard.styles';

export type ComponentCardProps = {
  component: ComponentInTheList | ComponentRefInTheList;
  onNameClick: () => void;
  widgetActions?: ReactElement[];
  nameClickTitle?: string;
  componentId: string;
};

type ICard = FunctionComponent<ComponentCardProps> & {
  Container: ElementType;
  Wrapper: ElementType;
};

export const ComponentCardView: ICard = ({
  onNameClick,
  component,
  widgetActions,
  nameClickTitle,
  componentId,
}) => {
  const { displayName, avatar, shortname, type } = component;
  const title = displayName || shortname || '';

  return (
    <Styled.Wrapper>
      <Styled.TagWrapper>
        <Tag appearance={TagAppearance.Badge} value={type || ''} />
        <ComponentWidget name={title} actions={widgetActions} type={type} componentId={componentId}>
          <Styled.MoreButton appearance={Appearance.Icon} title="More">
            <Icon.ThreeDots />
          </Styled.MoreButton>
        </ComponentWidget>
      </Styled.TagWrapper>
      <AvatarWithTitle
        avatarSrc={avatar}
        name={title}
        avatarType={AvatarType.Component}
        avatarAppearance={AvatarAppearance.Small}
        onNameClick={onNameClick}
        title={nameClickTitle || 'Open component'}
      />
    </Styled.Wrapper>
  );
};

ComponentCardView.Container = Styled.CardContainer;
ComponentCardView.Wrapper = Styled.CardWrapper;
