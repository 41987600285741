import { FunctionComponent, useState, ReactNode } from 'react';
import { Form, Field } from 'react-final-form';
import { validation, parsers, FormStateType, TeamFormData, Appearance } from '../../definitions';
import { Input, FormLayout, FormAutoSave, Textarea } from '../../components/Form';
import Button from '../../components/Button';
import { Icon } from '../../components/Icon';

type TeamFormProps = {
  isLoading: boolean;
  onSubmit: (formData: TeamFormData) => void;
  onCancel: () => void;
  initialValues: TeamFormData;
  onUpdateTeamState?: (formState: FormStateType) => void;
  action: string;
  isDisabled?: boolean;
  isNewTeam?: boolean;
  extraAction?: ReactNode;
  isDeleting?: boolean;
};

export const TeamFormView: FunctionComponent<TeamFormProps> = ({
  onSubmit,
  isLoading,
  initialValues,
  onUpdateTeamState = () => {},
  action,
  onCancel,
  isDisabled = false,
  isNewTeam,
  extraAction,
  isDeleting,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

  return (
    <Form
      autoComplete="off"
      onSubmit={(values: TeamFormData) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, valid, values, form }) => {
        const onSubmitClick = (
          event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
        ) => {
          setClickedSubmit(true);
          handleSubmit(event);
        };

        const handleCancel = () => {
          onUpdateTeamState({} as any);
          form.reset({});
          onCancel();
        };

        return (
          <>
            <FormLayout.FieldSet>
              <Field
                id="shortname"
                name="shortname"
                label="Shortname"
                component={Input}
                validate={value => validation.required(value)}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                disabled={!isNewTeam}
                maxLength="70"
                description="The shortname is a unique identifier that will be used as part of the team URL."
              />
              <Field
                id="displayname"
                name="displayname"
                label="Display name"
                component={Input}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                isOptional={true}
                maxLength="120"
                description="A display name is an identifier used throughout the platform to reference a particular team."
              />
              <Field
                id="description"
                name="description"
                label="Description"
                component={Textarea}
                showValidation={clickedSubmit}
                format={parsers.trim}
                formatOnBlur={true}
                isOptional={true}
                maxLength="2000"
                description="Use this field to provide information about the team mission, members, owned components and any other necessary and helpful details."
              />
            </FormLayout.FieldSet>
            <FormAutoSave updateFormState={onUpdateTeamState} />
            <FormLayout.ModalPreFooterSpacer />
            <FormLayout.ModalFooter>
              <Button
                onClick={handleCancel}
                isDisabled={isLoading || isDeleting}
                appearance={Appearance.PrimaryWithIcon}
              >
                <Icon.Cross />
                <span>Cancel</span>
              </Button>
              {extraAction}
              <FormLayout.ModalSubmit
                onClick={onSubmitClick}
                isPending={isLoading}
                isDisabled={isDisabled || isDeleting || (!isNewTeam && pristine)}
                appearance={Appearance.PrimaryWithIcon}
                isHighlighted={true}
              >
                <Icon.Edit />
                <span>{action}</span>
              </FormLayout.ModalSubmit>
            </FormLayout.ModalFooter>
          </>
        );
      }}
    />
  );
};
