import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { usePageTitle } from '../../hooks';
import {
  isCreatingSLO,
  createSLO,
  getComponentList,
  isFetchingComponentList,
  fetchComponentList,
  isFetchingSLOTypes,
  getSLOTypes,
  fetchSLOTypes,
  getSavedNewSLO,
  updateNewSLO,
  isFetchingEventSourceList,
  getEventSourceList,
  fetchEventSourceList,
} from '../../store';
import { SloFormData, FormStateType, paths } from '../../definitions';
import { CreateSloView } from './CreateSlo.view';

type CreateSloContainerProps = {};

export const CreateSloContainer: FunctionComponent<CreateSloContainerProps> = () => {
  usePageTitle('Create SLO');

  const dispatch = useDispatch();

  const componentList = useSelector(getComponentList);
  const eventSourceList = useSelector(getEventSourceList);
  const sloTypes = useSelector(getSLOTypes);
  const savedFormData = useSelector(getSavedNewSLO);

  const isCreatingSlo = useSelector(isCreatingSLO);
  const isLoadingComponentList = useSelector(isFetchingComponentList);
  const isLoadingEventSource = useSelector(isFetchingEventSourceList);
  const isLoadingSLOTypes = useSelector(isFetchingSLOTypes);

  const handleCreateSLO = useCallback(
    (formData: SloFormData) => {
      const componentId = savedFormData.sloComponentId;
      dispatch(createSLO({ formData, componentId }));
    },
    [dispatch, savedFormData.sloComponentId],
  );

  const handleUpdateSLOState = useCallback(
    (formData: FormStateType) => {
      dispatch(updateNewSLO(formData));
    },
    [dispatch],
  );

  const handleCloseDrawer = () => dispatch(push(paths.sloList));

  useEffect(() => {
    dispatch(fetchComponentList({}));
    dispatch(fetchSLOTypes());
    dispatch(fetchEventSourceList());
  }, [dispatch]);

  return (
    <CreateSloView
      onCreateSlo={handleCreateSLO}
      isLoading={isCreatingSlo}
      isLoadingComponentList={isLoadingComponentList}
      isLoadingSLOTypes={isLoadingSLOTypes}
      isLoadingEventSource={isLoadingEventSource}
      componentList={componentList}
      eventSourceList={eventSourceList}
      sloTypes={sloTypes}
      savedFormData={savedFormData}
      onUpdateSLOState={handleUpdateSLOState}
      isDisabled={isLoadingComponentList || isLoadingSLOTypes || isLoadingEventSource}
      onCancel={handleCloseDrawer}
    />
  );
};
