import { FunctionComponent } from 'react';
import { isExternalUrl } from '../../../definitions';
import { Icon } from '../../Icon';
import * as Styled from '../Sidebar.styles';

type SecondaryItemProps = {
  path?: string;
  title?: string;
  onItemClick?: () => void;
};

export const SecondaryItem: FunctionComponent<SecondaryItemProps> = ({
  path,
  title,
  onItemClick,
  ...rest
}) => {
  const isExternalLink = isExternalUrl(path);

  const handleItemClick = () => {
    if (!isExternalLink) return onItemClick;
    return undefined;
  };

  return (
    <Styled.SecondaryItem onClick={handleItemClick} {...rest}>
      <Styled.Text>{title}</Styled.Text>
      {isExternalLink && <Icon.ExternalLink />}
    </Styled.SecondaryItem>
  );
};
