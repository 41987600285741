import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useLayer } from 'react-laag';
import { AnimatePresence } from 'framer-motion';
import Button from '../Button';
import { Icon } from '../Icon';
import { Notification as NotificationType } from '../../grpc/grpcweb/notification_pb';
import Notification from '../Notification';
import { Close } from '../Close';
import { Empty } from '../Empty';
import { Loader } from '../Loader';
import { Appearance, colors } from '../../definitions';
import { useScrollWithShadow } from '../../hooks';
import * as Styled from './NotificationPopup.styles';

export type NotificationPopupProps = {
  notifications: NotificationType.AsObject[];
  isLoading: boolean;
  isClearing: boolean;
  onClear: (dateStamp: number) => void;
};

export const NotificationPopupView: FunctionComponent<NotificationPopupProps> = ({
  notifications,
  isLoading,
  isClearing,
  onClear,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [setToClose, setSetToClose] = useState(false);

  const scrollRef = useRef(null);
  const containerRef = useRef(null);

  const { isBorderBottom, isBorderTop, onScrollHandler } = useScrollWithShadow(
    scrollRef,
    containerRef,
  );

  const handleClear = () => {
    const dateStamps = notifications.map(
      (notififcation: NotificationType.AsObject) => notififcation.createdat,
    );
    const latestDate = Math.max.apply(null, dateStamps);
    onClear(latestDate);
  };

  const handleClose = () => {
    setOpen(false);
    setSetToClose(false);
  };

  useEffect(() => {
    if (setToClose && !isClearing) {
      handleClose();
    }
  }, [setToClose, isClearing]);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: handleClose, // close the menu when the user clicks outside
    onDisappear: handleClose, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'bottom-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
  });

  return (
    <>
      <Styled.TriggerWrapper
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        hasNotifications={!!notifications.length}
      >
        {notifications.length ? <Icon.NotificationOn /> : <Icon.Notification />}
      </Styled.TriggerWrapper>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Styled.MotionDiv {...layerProps}>
              <Styled.CloseWrapper>
                <Close onClick={() => setOpen(false)} />
              </Styled.CloseWrapper>
              <Styled.Header>Notifications</Styled.Header>
              <div ref={containerRef}>
                <Styled.ScrollContainer
                  onScroll={onScrollHandler}
                  isBorderBottom={isBorderBottom}
                  isBorderTop={isBorderTop}
                >
                  <div ref={scrollRef}>
                    {isLoading ? (
                      <Styled.StateManagerWrapper>
                        <Loader.Container>
                          <Loader />
                        </Loader.Container>
                      </Styled.StateManagerWrapper>
                    ) : notifications.length ? (
                      notifications.map((notification: NotificationType.AsObject) => (
                        <Notification notification={notification} key={notification.id} />
                      ))
                    ) : (
                      <Styled.StateManagerWrapper>
                        <Empty text="You're up to date. No unread notifications." />
                      </Styled.StateManagerWrapper>
                    )}
                  </div>
                </Styled.ScrollContainer>
              </div>
              <Styled.MarkAsReadButton>
                <Button
                  appearance={Appearance.Link}
                  color={colors.elsa}
                  isDisabled={!notifications.length || isLoading}
                  onClick={handleClear}
                  isPending={isClearing}
                >
                  Clear
                </Button>
              </Styled.MarkAsReadButton>
            </Styled.MotionDiv>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
