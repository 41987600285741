import {
  ActionType,
  CommentData,
  EditSloFormData,
  getSLOActivityData,
  SequenceType,
  SLOActivity,
  SloFormData,
  SLOHistoryPoint,
  SloInTheList,
} from '../../definitions';
import {
  Activity,
  ErrorBudgetMethod,
  SloHistoryRecord,
  SloStatus,
  SloView,
  TimeWindowType,
  TimeWindowUnit,
} from '../../grpc/grpcweb/slo_pb';
import { Comment } from '../../grpc/grpcweb/comment_pb';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import actionTypes from '../constants/actionTypes';
import { getSloStatusLabel } from '../../grpcMappings';

export type SLOsState = {
  list: SloInTheList[];
  types: CatalogItemView.AsObject[];
  selected: EditSloFormData;
  new: SloFormData;
  selectedSLOHistory: SLOHistoryPoint[];
  selectedSLOActivity: SLOActivity[];
  selectedSLOComments: CommentData[];
};

const initialState = {
  list: [],
  types: [],
  selected: {
    id: '',
    sloComponentId: '',
    displayName: '',
    shortName: '',
    description: '',
    type: '',
    timeWindowType: TimeWindowType.TIME_WINDOW_TYPE_ROLLING.toString(),
    timeUnit: TimeWindowUnit.TIME_WINDOW_UNIT_UNSPECIFIED,
    errorBudgetMethod: ErrorBudgetMethod.ERROR_BUDGET_METHOD_OCCURRENCES,
    timeCount: undefined,
    startTime: '',
    objectiveDisplayName: '',
    objectiveValue: 0,
    badQueryEventSource: '',
    totalQueryEventSource: '',
    badQuery: [''],
    totalQuery: [''],
    status: SloStatus.SLO_STATUS_UNSPECIFIED,
  },
  new: {
    sloComponentId: '',
    displayName: '',
    shortName: '',
    description: '',
    type: '',
    timeWindowType: TimeWindowType.TIME_WINDOW_TYPE_ROLLING.toString(),
    timeUnit: TimeWindowUnit.TIME_WINDOW_UNIT_UNSPECIFIED,
    errorBudgetMethod: ErrorBudgetMethod.ERROR_BUDGET_METHOD_OCCURRENCES,
    timeCount: undefined,
    startTime: '',
    objectiveDisplayName: '',
    objectiveValue: 0,
    badQueryEventSource: '',
    totalQueryEventSource: '',
    badQuery: [''],
    totalQuery: [''],
    status: SloStatus.SLO_STATUS_UNSPECIFIED,
  },
  selectedSLOHistory: [],
  selectedSLOActivity: [],
  selectedSLOComments: [],
};

const slos = (
  state: SLOsState = initialState,
  { type, payload, sequence }: ActionType,
): SLOsState => {
  switch (type) {
    case actionTypes.slos.fetchedList: {
      if (sequence === SequenceType.Success) {
        const rawList = payload;
        const originalList = rawList?.map((slo: SloView) => slo.toObject());
        const list = originalList?.map(
          (slo: SloView.AsObject): SloInTheList => ({
            displayname: slo.metadata?.displayname,
            shortname: slo.metadata?.shortname,
            objective: slo.metadata?.objective,
            status: slo.status,
            description: slo.metadata?.description?.value,
            errorbudgetmethod: slo.metadata?.errorbudgetmethod,
            id: slo.id,
            slotargetid: slo.metadata?.componentid,
            currentslovalue: slo.currentslovalue,
            type: slo.type?.metadata?.displayname,
            timeWindowType: slo.metadata?.timewindow?.type,
            timeWindowUnit: slo.metadata?.timewindow?.unit,
            timeWindow: slo.metadata?.timewindow?.count,
          }),
        );
        return {
          ...state,
          list,
        };
      }

      return state;
    }

    case actionTypes.slos.fetchedHistory: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: SloHistoryRecord[] = payload;
        const sloHistory = payloadWithType.map((point: SloHistoryRecord) => {
          const dataPoint = point.toObject();
          return {
            start: new Date(dataPoint.startms),
            end: new Date(dataPoint.endms),
            startString: new Date(dataPoint.startms).toLocaleString(),
            endString: new Date(dataPoint.endms).toLocaleString(),
            sloValue: dataPoint.slovalue,
          };
        });
        return {
          ...state,
          selectedSLOHistory: sloHistory,
        };
      }

      return state;
    }

    case actionTypes.slos.comments.fetched: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: Comment[] = payload;
        const sloComments = payloadWithType.map((comment: Comment) => {
          const data = comment.toObject();
          return {
            addedAt: new Date(data.addedat),
            id: data.id,
            value: data.value?.value,
            targetId: data.targetid,
            author: data.author?.fullname ?? 'unknown',
          };
        });
        return {
          ...state,
          selectedSLOComments: sloComments,
        };
      }

      return state;
    }

    case actionTypes.slos.activity.fetched: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: Activity[] = payload;
        const activityStream: SLOActivity[] = payloadWithType.map((event: Activity) => {
          const activityEvent = event.toObject();
          return {
            id: activityEvent.id,
            sloBreached: activityEvent.slobreachstart,
            sloRecovered: activityEvent.slobreachend,
            sloId: activityEvent.sloid,
            sloObjectiveChanged: activityEvent.sloobjectivechanged,
            sloStatusChanged: activityEvent.slostatuschanged,
            tenantId: activityEvent.tenantid,
            triggeredAt: new Date(activityEvent.triggeredat),
            triggeredAtString: new Date(activityEvent.triggeredat).toLocaleString(),
            triggeredBy: activityEvent.triggeredby,
            triggeredByUser: activityEvent.triggeredbyuser,
            type: activityEvent.type,
          };
        });

        let mappedHistory = state.selectedSLOHistory;
        if (mappedHistory.length && activityStream.length) {
          let index = 0;

          while (index < activityStream.length) {
            const changedHistory = mappedHistory.map(point => {
              if (
                point.end >= activityStream[index].triggeredAt &&
                point.start < activityStream[index].triggeredAt
              ) {
                const sloStatusChangedFrom = activityStream[index].sloStatusChanged?.prevstatus;
                const sloStatusChangedTo = activityStream[index].sloStatusChanged?.currentstatus;
                const newActivity = getSLOActivityData(
                  activityStream[index],
                  activityStream[index].type,
                  getSloStatusLabel(sloStatusChangedFrom),
                  getSloStatusLabel(sloStatusChangedTo),
                );
                const activity = newActivity
                  ? point.activity
                    ? [...point.activity, newActivity]
                    : [newActivity]
                  : point.activity;
                const newPoint = { ...point, activity };
                return newPoint;
              } else {
                return point;
              }
            });
            mappedHistory = changedHistory;
            index++;
          }
        }

        return {
          ...state,
          selectedSLOActivity: activityStream,
          selectedSLOHistory: mappedHistory,
        };
      }

      return state;
    }

    case actionTypes.slos.fetchedTypes: {
      if (sequence === SequenceType.Success) {
        const rawList = payload;
        const types = rawList?.map((type: CatalogItemView) => type.toObject());
        return {
          ...state,
          types,
        };
      }

      return state;
    }

    case actionTypes.slos.created: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          new: initialState.new,
        };
      }

      return state;
    }

    case actionTypes.slos.updated:
    case actionTypes.slos.launched: {
      if (sequence === SequenceType.Success) {
        return {
          ...state,
          selected: initialState.selected,
        };
      }

      return state;
    }

    case actionTypes.slos.updateNew: {
      return {
        ...state,
        new: payload,
      };
    }

    case actionTypes.slos.setComponent: {
      return {
        ...state,
        new: {
          ...state.new,
          sloComponentId: payload,
        },
      };
    }

    case actionTypes.slos.fetchedOne: {
      if (sequence === SequenceType.Success) {
        const payloadWithType: SloView = payload;
        const slo = payloadWithType.toObject();
        const selectedMappedToState = {
          displayName: slo.metadata?.displayname || '',
          shortName: slo.metadata?.shortname,
          description: slo.metadata?.description?.value,
          errorBudgetMethod: slo.metadata?.errorbudgetmethod,
          id: slo.id,
          sloComponentId: slo.metadata?.componentid,
          type: slo.type?.id,
          timeWindowType: slo.metadata?.timewindow?.type.toString(),
          timeUnit: slo.metadata?.timewindow?.unit,
          timeCount: slo.metadata?.timewindow?.count,
          startTime: slo.metadata?.timewindow?.starttime
            ? new Date(slo.metadata?.timewindow?.starttime).toJSON().slice(0, 10)
            : undefined,
          objectiveDisplayName: slo.metadata?.objective?.displayname,
          objectiveValue: slo.metadata?.objective?.value,
          badQuery: slo.metadata?.objective?.badeventquery?.queriesList || [''],
          badQueryEventSource: slo.metadata?.objective?.badeventquery?.eventsourceid,
          totalQuery: slo.metadata?.objective?.totaleventquery?.queriesList || [''],
          totalQueryEventSource: slo.metadata?.objective?.totaleventquery?.eventsourceid,
          status: slo.status,
        };

        return {
          ...state,
          selected: selectedMappedToState,
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default slos;
