import { grpc } from '@improbable-eng/grpc-web';
import { TeamService } from '../grpc/grpcweb/team_pb_service';
import {
  ListTeamsResponse,
  ListTeamsRequest,
  GetTeamByShortNameRequest,
  GetTeamByShortNameResponse,
  AddMembersRequest,
  AddMembersResponse,
  DeleteMemberRequest,
  DeleteMemberResponse,
  CreateTeamRequest,
  CreateTeamResponse,
  UpdateTeamRequest,
  UpdateTeamResponse,
  GetTeamByIdRequest,
  GetTeamByIdResponse,
  DeleteTeamRequest,
  DeleteTeamResponse,
} from '../grpc/grpcweb/team_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class TeamsService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  createTeam = async (RctToken: string, createTeamRequest: CreateTeamRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.Create, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<CreateTeamResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTeam());
          } else {
            reject(out.statusMessage);
            console.log(
              'create team failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: createTeamRequest,
      });
    });
  };

  updateTeam = async (RctToken: string, updateTeamRequest: UpdateTeamRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.Update, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateTeamResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTeam());
          } else {
            reject(out.statusMessage);
            console.log(
              'update team failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: updateTeamRequest,
      });
    });
  };

  deleteTeam = async (RctToken: string, deleteTeamrequest: DeleteTeamRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.Delete, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteTeamResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTeam());
          } else {
            reject(out.statusMessage);
            console.log(
              'delete team failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: deleteTeamrequest,
      });
    });
  };

  fetchList = async (RctToken: string, listTeamsRequest: ListTeamsRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListTeamsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve({ batch: out.message.getBatch(), page: out.message.getPageresponse() });
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch team list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: listTeamsRequest,
      });
    });
  };

  fetchTeam = async (RctToken: string, listTeamRequest: GetTeamByShortNameRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.GetByShortName, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetTeamByShortNameResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTeam());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch team failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: listTeamRequest,
      });
    });
  };

  fetchTeamById = async (RctToken: string, listTeamRequest: GetTeamByIdRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.GetById, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetTeamByIdResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTeam());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch team failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: listTeamRequest,
      });
    });
  };

  addUsers = async (RctToken: string, addMemberRequest: AddMembersRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.AddMembers, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<AddMembersResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTeam());
          } else {
            reject(out.statusMessage);
            console.log(
              'add member to the team failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: addMemberRequest,
      });
    });
  };

  removeUser = async (RctToken: string, addMemberRequest: DeleteMemberRequest) => {
    return new Promise((resolve, reject) => {
      grpc.unary(TeamService.DeleteMember, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteMemberResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTeam());
          } else {
            reject(out.statusMessage);
            console.log(
              'add member to the team failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request: addMemberRequest,
      });
    });
  };
}
