import styled, { css } from 'styled-components';

type MessageProps = {
  type: string;
  stack?: boolean;
  isHidden: boolean;
};

export const StatusIcon = styled.span`
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  flex-shrink: 0;
`;

export const Message = styled.div<MessageProps>`
  width: 100%;
  display: flex;
  visibility: ${props => (props.isHidden ? 'hidden' : 'default')};
  align-items: center;
  min-height: 2rem;
  text-align: left;
  justify-content: inherit;
  font-size: 0.75rem;
  color: ${props => props.type === 'error' && props.theme.palettes.error};
  color: ${props => props.type === 'pending' && props.theme.palettes.main};
  color: ${props => props.type === 'success' && props.theme.palettes.secondaryHighlight};

  ${props =>
    props.stack &&
    css`
      flex-direction: column;
      text-align: center;

      ${StatusIcon} {
        width: 2em;
        height: 2em;
        margin-bottom: 0.5em;
        margin-right: 0;

        svg {
          fill: currentColor;
          width: 100%;
          height: 100%;
        }
      }
    `}

  ${props => props.theme.media.medium`
    min-height: 2rem;
  `}
`;
