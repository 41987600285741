import styled, { css } from 'styled-components';
import { darken } from 'polished';

type HeaderProps = {
  small?: boolean;
};

type WrapperProps = {
  isSidebarPersistentOnMedium: boolean;
};

type HeadingRowProps = {
  hasSubheading?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - ${({ theme }) => theme.constants.topbarHeight});
  margin-top: ${({ theme }) => theme.constants.topbarHeight};
  margin-left: 0;
  color: ${({ theme }) => theme.palettes.main};
  text-align: left;
  transition: all 200ms ease-out;

  ${props => `
    background: ${darken(0.025, props.theme.palettes.inverse)};
  `};

  ${props => props.theme.media.medium`
  ${
    props.isSidebarPersistentOnMedium === true &&
    css`
      margin-top: 0;
      margin-left: ${props.theme.constants.mediumSidebarWidth};
      width: calc(100% - ${props.theme.constants.mediumSidebarWidth});
      min-height: 100vh;
    `
  }
  `};

  ${props => props.theme.media.large`
  ${
    props.isSidebarPersistentOnMedium === true &&
    css`
      margin-top: 0;
      margin-left: ${props.theme.constants.largeSidebarWidth};
      width: calc(100% - ${props.theme.constants.largeSidebarWidth});
      min-height: 100vh;
    `
  }
  `};
`;

const containerStyles = css`
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: calc(100vh - ${({ theme }) => theme.constants.topbarHeight});
`;

const containerPadding = css`
  padding: 1rem 1.5rem;
`;

export const Container = styled.div`
  ${containerStyles};
  flex: 1;
  flex-direction: row;
  position: relative;
`;

export const ContainerInner = styled.div`
  max-width: calc(${props => props.theme.constants.maxWidth} - 3rem);
  flex-direction: column;
  width: 100%;
  ${containerPadding};
  padding-bottom: 3rem;
`;

export const Header = styled.header<HeaderProps>`
  ${containerStyles};
  background: ${({ theme }) => theme.palettes.inverse};
  min-height: 5.5rem;
  border-bottom: 1px solid ${props => props.theme.palettes.headerBorder};
  ${containerPadding};
`;

export const HeaderInner = styled.div`
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: calc(${props => props.theme.constants.maxWidth} - 3rem);
`;

export const Section = styled.div<{ height?: string }>`
  text-align: inherit;
  background: ${({ theme }) => theme.palettes.inverse};
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.effects.borderShadow};
  display: flex;
  flex-direction: column;
  height: ${props => props.height || 'auto'};
  margin-bottom: 1rem;
  width: 100%;
`;

export const Heading = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: end;

  h2 {
    line-height: 0.75;
  }

  svg {
    height: 1rem;
    width: auto;
    align-self: flex-end;
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

export const HeadingRow = styled.div<HeadingRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${props => (props.hasSubheading ? '0.75rem' : '1rem')};
  min-height: 2.3125rem;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const SubHeading = styled.p`
  color: ${({ theme }) => theme.palettes.mutedMain};
  margin-bottom: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  max-width: 64rem;
`;

export const ActionWrapper = styled.div`
  display: flex;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const HeaderTitle = styled.h1``;

export const SectionHeading = styled.h4`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  svg {
    height: 1.5rem;
    width: auto;
  }
`;
