import { FunctionComponent, ReactNode, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { TimeWindowType, TimeWindowUnit } from '../../grpc/grpcweb/slo_pb';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import { Icon } from '../../components/Icon';
import { Layout } from '../../components/Layout';
import {
  validation,
  parsers,
  Appearance,
  SloFormData,
  FormStateType,
  EventSourcesInTheList,
  ComponentRefInTheList,
  ComponentInTheDropdown,
  EditSloFormData,
} from '../../definitions';
import {
  Input,
  Textarea,
  Select,
  FormLayout,
  Radio,
  FormAutoSave,
  InputArray,
} from '../../components/Form';
import ComponentSelectOption from '../../components/ComponentSelectOption';
import { useTimeWindowUnitType, getEventSourceLabel } from '../../grpcMappings';
import { CreateNewSourceButton } from './CustomComponents';
import EventSourceDropdownItem from '../EventSourceDropdownItem';
import Button from '../../components/Button';

type SloFormProps = {
  onSubmitSlo: (formData: SloFormData) => void;
  componentList: ComponentRefInTheList[];
  isLoading: boolean;
  isDisabled: boolean;
  isLoadingComponentList: boolean;
  isLoadingSLOTypes: boolean;
  isLoadingEventSource: boolean;
  eventSourceList: EventSourcesInTheList[];
  sloTypes: CatalogItemView.AsObject[];
  initialValues: EditSloFormData | SloFormData;
  onUpdateSLOState?: (formState: FormStateType) => void;
  onCancel: () => void;
  submitButtonText: string;
  extraAction?: ReactNode;
  submitButtonIcon?: ReactNode;
};

export const SloFormView: FunctionComponent<SloFormProps> = ({
  onSubmitSlo,
  isLoading,
  isDisabled,
  isLoadingComponentList,
  componentList,
  isLoadingSLOTypes,
  sloTypes,
  initialValues,
  onUpdateSLOState,
  onCancel,
  eventSourceList,
  isLoadingEventSource,
  submitButtonText,
  extraAction,
  submitButtonIcon,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);

  const componentsOptions: ComponentInTheDropdown[] = useMemo(
    () =>
      componentList.map(component => ({
        value: component.id,
        label: component.displayName,
        type: component.type,
      })),
    [componentList],
  );

  const sloTypesOptions = useMemo(
    () => sloTypes.map(type => ({ value: type.id, label: type.metadata?.displayname })),
    [sloTypes],
  );

  const eventSourceOptions = useMemo(
    () =>
      eventSourceList.map(source => ({
        value: source.id,
        label: source.displayName,
        type: getEventSourceLabel(source.sourceType),
      })),
    [eventSourceList],
  );

  const timeWindowOptions = [
    {
      value: TimeWindowUnit.TIME_WINDOW_UNIT_HOUR,
      label: useTimeWindowUnitType(TimeWindowUnit.TIME_WINDOW_UNIT_HOUR),
    },
    {
      value: TimeWindowUnit.TIME_WINDOW_UNIT_DAY,
      label: useTimeWindowUnitType(TimeWindowUnit.TIME_WINDOW_UNIT_DAY),
    },
  ];

  return (
    <FormLayout>
      <Form
        onSubmit={(values: SloFormData) => {
          onSubmitSlo(values);
        }}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
        render={({
          handleSubmit,
          pristine,
          valid,
          values,
          form: {
            mutators: { push },
          },
          form,
        }) => {
          const onSubmitClick = (
            event: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
          ) => {
            setClickedSubmit(true);
            handleSubmit(event);
          };
          return (
            <>
              <Layout.Section>
                <Layout.SectionHeading>Component</Layout.SectionHeading>
                <FormLayout.Row>
                  <Field
                    id="sloComponentId"
                    name="sloComponentId"
                    component={Select}
                    validate={value =>
                      validation.required(value, 'Select component or create new one.')
                    }
                    placeholder="Select SLO component"
                    options={componentsOptions}
                    showValidation={clickedSubmit}
                    isLoadingOptions={isLoadingComponentList}
                    onReset={() => form.change('sloComponentId', undefined)}
                    description="Select the most appropriate type to describe your capability."
                    formatOptionLabel={ComponentSelectOption}
                    reservedSpace="3.375rem"
                  ></Field>
                </FormLayout.Row>
              </Layout.Section>
              <Layout.Section>
                <Layout.SectionHeading>Metadata</Layout.SectionHeading>
                <FormLayout.Row>
                  <Field
                    id="displayName"
                    name="displayName"
                    label="Display name"
                    component={Input}
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    format={parsers.trim}
                    formatOnBlur={true}
                    maxLength="120"
                    description="The display name should be unique and representative of the capability that the SLO stands for, e.g., 'User can log in.'"
                  />
                  <Field
                    id="shortName"
                    name="shortName"
                    label="Shortname"
                    component={Input}
                    validate={value => validation.required(value)}
                    // prefix="https://andromeda.rocketslo.com/slo/"
                    format={parsers.trim}
                    maxLength="50"
                    formatOnBlur={true}
                    showValidation={clickedSubmit}
                    description="The SLO shortname is a unique identifier that will be used as part of the SLO URL."
                  />
                </FormLayout.Row>
                <FormLayout.Row>
                  <Field
                    id="description"
                    name="description"
                    label="Description"
                    component={Textarea}
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    format={parsers.trim}
                    formatOnBlur={true}
                    maxLength="2000"
                    description="Use this field to provide information about the SLO characteristics and any necessary and helpful details about the capability."
                  />
                </FormLayout.Row>
                <FormLayout.Row>
                  <Field
                    id="type"
                    name="type"
                    label="Type"
                    component={Select}
                    placeholder="Select type"
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    onReset={() => form.change('type', undefined)}
                    isLoadingOptions={isLoadingSLOTypes}
                    description="Select the most appropriate type to describe your capability."
                    options={sloTypesOptions}
                  />
                </FormLayout.Row>
              </Layout.Section>
              <Layout.Section>
                <Layout.SectionHeading>Objectives</Layout.SectionHeading>
                <FormLayout.Row>
                  <Field
                    id="objectiveDisplayName"
                    name="objectiveDisplayName"
                    label="Display name"
                    component={Input}
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    format={parsers.trim}
                    formatOnBlur={true}
                    maxLength="120"
                    description="Objective display name should be unique and representative of the desired objective target and time period (e.g., '99% requests < 10ms')."
                  />
                  <Field
                    id="objectiveValue"
                    name="objectiveValue"
                    label="Target value"
                    component={Input}
                    postfix="%"
                    parse={parsers.decimal}
                    type="number"
                    validate={validation.compose(
                      value => validation.required(value),
                      validation.max(100),
                      validation.min(0),
                    )}
                    showValidation={clickedSubmit}
                    maxLength="3"
                    description="The target value is a percentage representing the desired proportion of successful events against the total number of events."
                  />
                </FormLayout.Row>
                <FormLayout.Row>
                  <Field
                    id="badQueryEventSource"
                    name="badQueryEventSource"
                    label="Bad query event source"
                    component={Select}
                    validate={value => validation.required(value)}
                    placeholder="Select event source"
                    isLoadingOptions={isLoadingEventSource}
                    showValidation={clickedSubmit}
                    onReset={() => form.change('badQueryEventSource', undefined)}
                    description="Select an event source that will be used to fetch bad events. Bad events are events that are considered unwanted or erroneous. If you don't see a desired source in the list, create a new one."
                    options={eventSourceOptions}
                    formatOptionLabel={EventSourceDropdownItem}
                  >
                    <CreateNewSourceButton />
                  </Field>
                </FormLayout.Row>
                <FieldArray name="badQuery">
                  {({ fields }) => {
                    return fields.map((query, index) => {
                      const description =
                        index === 0
                          ? 'Add a query to fetch bad events from the event source. This query should return the number of events that are considered unwanted or erroneous.'
                          : undefined;
                      return (
                        <InputArray
                          index={index}
                          key={index}
                          name={query}
                          description={description}
                          showValidation={clickedSubmit}
                          component={Textarea}
                          id={`badQuery${index}`}
                          label={`Bad query ${index + 1}`}
                          fields={fields}
                        />
                      );
                    });
                  }}
                </FieldArray>
                <InputArray.PushArrayItemButton push={push} name="badQuery">
                  Bad query
                </InputArray.PushArrayItemButton>

                <FormLayout.Row>
                  <Field
                    id="totalQueryEventSource"
                    name="totalQueryEventSource"
                    label="Total query event source"
                    placeholder="Select event source"
                    component={Select}
                    validate={value => validation.required(value)}
                    isLoadingOptions={isLoadingEventSource}
                    showValidation={clickedSubmit}
                    onReset={() => form.change('totalQueryEventSource', undefined)}
                    description="Select an event source that will be used to fetch the total number of events. If you don't see a desired source in the list, create a new one."
                    options={eventSourceOptions}
                    formatOptionLabel={EventSourceDropdownItem}
                  >
                    <CreateNewSourceButton />
                  </Field>
                </FormLayout.Row>
                <FieldArray name="totalQuery">
                  {({ fields }) => {
                    return fields.map((name, index) => {
                      const description =
                        index === 0
                          ? 'Add a query to fetch all events from the event source. This query should return the total number of events, including both bad and good events, and will be used to calculate the SLO towards the set target.'
                          : undefined;
                      return (
                        <InputArray
                          index={index}
                          key={index}
                          name={name}
                          description={description}
                          showValidation={clickedSubmit}
                          id={`totalQuery${index}`}
                          component={Textarea}
                          label={`Total Query ${index + 1}`}
                          fields={fields}
                        />
                      );
                    });
                  }}
                </FieldArray>
                <InputArray.PushArrayItemButton push={push} name="totalQuery">
                  Total query
                </InputArray.PushArrayItemButton>
              </Layout.Section>
              <Layout.Section>
                <Layout.SectionHeading>Time Window</Layout.SectionHeading>
                <FormLayout.Row>
                  <Radio.Container description="The time window type is used to calculate the SLO and SLO budget over a specified time period. The rolling window automatically shifts forward with the passage of time, while the static time window is based strictly on the day/month/year frame.">
                    <Field
                      id="timeWindowType"
                      name="timeWindowType"
                      label="Rolling"
                      value={TimeWindowType.TIME_WINDOW_TYPE_ROLLING.toString()}
                      type="radio"
                      component={Radio}
                      showValidation={clickedSubmit}
                    />
                    <Field
                      id="timeWindowType"
                      name="timeWindowType"
                      label="Static"
                      isDisabled={true}
                      value={TimeWindowType.TIME_WINDOW_TYPE_STATIC.toString()}
                      type="radio"
                      component={Radio}
                      showValidation={clickedSubmit}
                    />
                  </Radio.Container>
                </FormLayout.Row>
                <FormLayout.Row>
                  <Field
                    id="timeCount"
                    name="timeCount"
                    label="Count"
                    component={Input}
                    parse={parsers.number}
                    type="number"
                    maxLength="3"
                    validate={validation.compose(
                      value => validation.required(value),
                      validation.max(365),
                      validation.min(1),
                    )}
                    showValidation={clickedSubmit}
                    description="Select the number of time units (hours/days) to apply the time window. For example, a 2-hour rolling window will calculate the SLO for each 2-hour period, starting from the current point and moving backward."
                  />
                  <Field
                    id="timeUnit"
                    name="timeUnit"
                    label="Unit"
                    placeholder="Select time unit"
                    component={Select}
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    onReset={() => form.change('timeUnit', undefined)}
                    description="Select the desired time units (hours/days) to apply the time window. For example, a 2-hour rolling window will calculate the SLO for each 2-hour period, starting from the current point and moving backward."
                    options={timeWindowOptions}
                  />
                </FormLayout.Row>
                <FormLayout.Row>
                  <Field
                    id="startTime"
                    name="startTime"
                    label="Start time"
                    component={Input}
                    type="date"
                    validate={value => validation.required(value)}
                    showValidation={clickedSubmit}
                    formatOnBlur={true}
                    description="Pick a date from which the SLO metrics will be calculated from."
                  />
                </FormLayout.Row>
              </Layout.Section>
              {/* <Layout.Section>
              <Layout.SectionHeading isSubtle={true}>Error Budget Method</Layout.SectionHeading>
              <FormLayout.Row>
                <Field
                  id="errorBudgetMethod"
                  name="errorBudgetMethod"
                  placeholder="Method"
                  label="Method"
                  component={Select}
                  validate={value => validation.required(value)}
                  showValidation={clickedSubmit}
                  onReset={() => form.change('errorBudgetMethod', undefined)}
                  tooltip="Select the most appropriate type to describe your capability"
                >
                  <option value="occurrences">occurrences</option>
                  <option value="timeslices">timeslices</option>
                </Field>
              </FormLayout.Row>
            </Layout.Section> */}
              <FormLayout.ActionWrapper>
                <Button
                  onClick={onCancel}
                  isDisabled={isLoading}
                  appearance={Appearance.PrimaryWithIcon}
                >
                  <Icon.Collapse />
                  <span>Cancel</span>
                </Button>
                {extraAction}
                <FormLayout.Submit
                  onClick={onSubmitClick}
                  isPending={isLoading}
                  isDisabled={isDisabled}
                  appearance={submitButtonIcon ? Appearance.PrimaryWithIcon : Appearance.Primary}
                  isHighlighted={true}
                >
                  {submitButtonIcon && submitButtonIcon}
                  <span>{submitButtonText}</span>
                </FormLayout.Submit>
              </FormLayout.ActionWrapper>
              {onUpdateSLOState && <FormAutoSave updateFormState={onUpdateSLOState} />}
            </>
          );
        }}
      />
    </FormLayout>
  );
};
