import { FunctionComponent, InputHTMLAttributes, ElementType } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { colors } from '../../../definitions';
import { InputTooltip } from '../InputTooltip';
import * as Styled from './Radio.styles';

export type RadioProps = InputHTMLAttributes<HTMLInputElement> &
  FieldRenderProps<string> & {
    label?: string;
    remoteErrorMessage?: string;
    validMessage?: string;
    showValidation?: boolean;
    tooltip?: string;
    isDisabled?: boolean;
    validationMessage?: string;
    isOptional?: boolean;
  };

type RadioContainerProps = {
  tooltip?: string;
  title?: string;
  isOptional?: boolean;
};

type IRadio = FunctionComponent<RadioProps> & {
  Container: ElementType;
  Label: ElementType;
};

export const RadioView: IRadio = ({
  className,
  label,
  input,
  meta,
  remoteErrorMessage,
  readOnly,
  validMessage,
  showValidation = false,
  isDisabled = false,
  id,
  ...rest
}) => {
  const errorMessage =
    (meta.touched && !meta.pristine && meta.error) ||
    (!meta.dirtySinceLastSubmit && (meta.submitError || remoteErrorMessage));

  const showValidMessage =
    !readOnly && meta.valid && !meta.pristine && validMessage && !errorMessage;

  const validationMessage = showValidMessage ? (
    <Styled.Message type="success" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
      {validMessage}
    </Styled.Message>
  ) : (
    <Styled.Message type="error" isHidden={!showValidation} aria-describedby={id ? id : 'none'}>
      {errorMessage}
    </Styled.Message>
  );

  return (
    <Styled.Column>
      <Styled.Container>
        <Styled.Wrapper
          className={className}
          checked={input.checked}
          disabled={isDisabled}
          {...meta}
        >
          <Styled.Input {...input} type="radio" disabled={isDisabled} id={id} {...rest} />
          <Styled.Box />
          {label && <Styled.Value color={colors.text}>{label}</Styled.Value>}
        </Styled.Wrapper>
      </Styled.Container>
      <Styled.MessageWrapper>{validationMessage}</Styled.MessageWrapper>
    </Styled.Column>
  );
};

const RadioContainerView: FunctionComponent<RadioContainerProps> = ({
  tooltip,
  children,
  title,
  isOptional,
}) => {
  return (
    <Styled.RadioContainer>
      {title && (
        <Styled.Label>
          {title}
          {isOptional && <Styled.Optional>(optional)</Styled.Optional>}
        </Styled.Label>
      )}
      <Styled.RadioWrapper>
        {children}
        {tooltip && <InputTooltip content={tooltip} />}
      </Styled.RadioWrapper>
    </Styled.RadioContainer>
  );
};

RadioView.Container = RadioContainerView;
RadioView.Label = Styled.Label;
