// source: grpcweb/catalog_item.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var grpccommon_common_pb = require('../grpccommon/common_pb.js');
goog.object.extend(proto, grpccommon_common_pb);
goog.exportSymbol('proto.rct.web.CatalogItemCreate', null, global);
goog.exportSymbol('proto.rct.web.CatalogItemMetadata', null, global);
goog.exportSymbol('proto.rct.web.CatalogItemView', null, global);
goog.exportSymbol('proto.rct.web.CreateCatalogItemRequest', null, global);
goog.exportSymbol('proto.rct.web.CreateCatalogItemResponse', null, global);
goog.exportSymbol('proto.rct.web.ListCatalogItemsRequest', null, global);
goog.exportSymbol('proto.rct.web.ListCatalogItemsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateCatalogItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateCatalogItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateCatalogItemRequest.displayName = 'proto.rct.web.CreateCatalogItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CreateCatalogItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CreateCatalogItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CreateCatalogItemResponse.displayName = 'proto.rct.web.CreateCatalogItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListCatalogItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.ListCatalogItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListCatalogItemsRequest.displayName = 'proto.rct.web.ListCatalogItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.ListCatalogItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rct.web.ListCatalogItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.rct.web.ListCatalogItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.ListCatalogItemsResponse.displayName = 'proto.rct.web.ListCatalogItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CatalogItemMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CatalogItemMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CatalogItemMetadata.displayName = 'proto.rct.web.CatalogItemMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CatalogItemCreate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CatalogItemCreate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CatalogItemCreate.displayName = 'proto.rct.web.CatalogItemCreate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rct.web.CatalogItemView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rct.web.CatalogItemView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rct.web.CatalogItemView.displayName = 'proto.rct.web.CatalogItemView';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateCatalogItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateCatalogItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateCatalogItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateCatalogItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    catalogitem: (f = msg.getCatalogitem()) && proto.rct.web.CatalogItemCreate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateCatalogItemRequest}
 */
proto.rct.web.CreateCatalogItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateCatalogItemRequest;
  return proto.rct.web.CreateCatalogItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateCatalogItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateCatalogItemRequest}
 */
proto.rct.web.CreateCatalogItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.CatalogItemCreate;
      reader.readMessage(value,proto.rct.web.CatalogItemCreate.deserializeBinaryFromReader);
      msg.setCatalogitem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateCatalogItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateCatalogItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateCatalogItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateCatalogItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCatalogitem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.CatalogItemCreate.serializeBinaryToWriter
    );
  }
};


/**
 * optional CatalogItemCreate catalogItem = 1;
 * @return {?proto.rct.web.CatalogItemCreate}
 */
proto.rct.web.CreateCatalogItemRequest.prototype.getCatalogitem = function() {
  return /** @type{?proto.rct.web.CatalogItemCreate} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CatalogItemCreate, 1));
};


/**
 * @param {?proto.rct.web.CatalogItemCreate|undefined} value
 * @return {!proto.rct.web.CreateCatalogItemRequest} returns this
*/
proto.rct.web.CreateCatalogItemRequest.prototype.setCatalogitem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateCatalogItemRequest} returns this
 */
proto.rct.web.CreateCatalogItemRequest.prototype.clearCatalogitem = function() {
  return this.setCatalogitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateCatalogItemRequest.prototype.hasCatalogitem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CreateCatalogItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CreateCatalogItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CreateCatalogItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateCatalogItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    catalogitem: (f = msg.getCatalogitem()) && proto.rct.web.CatalogItemView.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CreateCatalogItemResponse}
 */
proto.rct.web.CreateCatalogItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CreateCatalogItemResponse;
  return proto.rct.web.CreateCatalogItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CreateCatalogItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CreateCatalogItemResponse}
 */
proto.rct.web.CreateCatalogItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.CatalogItemView;
      reader.readMessage(value,proto.rct.web.CatalogItemView.deserializeBinaryFromReader);
      msg.setCatalogitem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CreateCatalogItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CreateCatalogItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CreateCatalogItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CreateCatalogItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCatalogitem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.CatalogItemView.serializeBinaryToWriter
    );
  }
};


/**
 * optional CatalogItemView catalogItem = 1;
 * @return {?proto.rct.web.CatalogItemView}
 */
proto.rct.web.CreateCatalogItemResponse.prototype.getCatalogitem = function() {
  return /** @type{?proto.rct.web.CatalogItemView} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CatalogItemView, 1));
};


/**
 * @param {?proto.rct.web.CatalogItemView|undefined} value
 * @return {!proto.rct.web.CreateCatalogItemResponse} returns this
*/
proto.rct.web.CreateCatalogItemResponse.prototype.setCatalogitem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CreateCatalogItemResponse} returns this
 */
proto.rct.web.CreateCatalogItemResponse.prototype.clearCatalogitem = function() {
  return this.setCatalogitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CreateCatalogItemResponse.prototype.hasCatalogitem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListCatalogItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListCatalogItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListCatalogItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListCatalogItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListCatalogItemsRequest}
 */
proto.rct.web.ListCatalogItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListCatalogItemsRequest;
  return proto.rct.web.ListCatalogItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListCatalogItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListCatalogItemsRequest}
 */
proto.rct.web.ListCatalogItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.rct.common.CatalogItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListCatalogItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListCatalogItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListCatalogItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListCatalogItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional rct.common.CatalogItemType type = 1;
 * @return {!proto.rct.common.CatalogItemType}
 */
proto.rct.web.ListCatalogItemsRequest.prototype.getType = function() {
  return /** @type {!proto.rct.common.CatalogItemType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.rct.common.CatalogItemType} value
 * @return {!proto.rct.web.ListCatalogItemsRequest} returns this
 */
proto.rct.web.ListCatalogItemsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rct.web.ListCatalogItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.ListCatalogItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.ListCatalogItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.ListCatalogItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListCatalogItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    catalogitemsList: jspb.Message.toObjectList(msg.getCatalogitemsList(),
    proto.rct.web.CatalogItemView.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.ListCatalogItemsResponse}
 */
proto.rct.web.ListCatalogItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.ListCatalogItemsResponse;
  return proto.rct.web.ListCatalogItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.ListCatalogItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.ListCatalogItemsResponse}
 */
proto.rct.web.ListCatalogItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.CatalogItemView;
      reader.readMessage(value,proto.rct.web.CatalogItemView.deserializeBinaryFromReader);
      msg.addCatalogitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.ListCatalogItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.ListCatalogItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.ListCatalogItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.ListCatalogItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCatalogitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.rct.web.CatalogItemView.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CatalogItemView catalogItems = 1;
 * @return {!Array<!proto.rct.web.CatalogItemView>}
 */
proto.rct.web.ListCatalogItemsResponse.prototype.getCatalogitemsList = function() {
  return /** @type{!Array<!proto.rct.web.CatalogItemView>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.rct.web.CatalogItemView, 1));
};


/**
 * @param {!Array<!proto.rct.web.CatalogItemView>} value
 * @return {!proto.rct.web.ListCatalogItemsResponse} returns this
*/
proto.rct.web.ListCatalogItemsResponse.prototype.setCatalogitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rct.web.CatalogItemView=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rct.web.CatalogItemView}
 */
proto.rct.web.ListCatalogItemsResponse.prototype.addCatalogitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rct.web.CatalogItemView, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rct.web.ListCatalogItemsResponse} returns this
 */
proto.rct.web.ListCatalogItemsResponse.prototype.clearCatalogitemsList = function() {
  return this.setCatalogitemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CatalogItemMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CatalogItemMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CatalogItemMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CatalogItemMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: (f = msg.getDescription()) && grpccommon_common_pb.TextItem.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CatalogItemMetadata}
 */
proto.rct.web.CatalogItemMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CatalogItemMetadata;
  return proto.rct.web.CatalogItemMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CatalogItemMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CatalogItemMetadata}
 */
proto.rct.web.CatalogItemMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new grpccommon_common_pb.TextItem;
      reader.readMessage(value,grpccommon_common_pb.TextItem.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.rct.common.CatalogItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CatalogItemMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CatalogItemMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CatalogItemMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CatalogItemMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      grpccommon_common_pb.TextItem.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string shortName = 1;
 * @return {string}
 */
proto.rct.web.CatalogItemMetadata.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CatalogItemMetadata} returns this
 */
proto.rct.web.CatalogItemMetadata.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string displayName = 2;
 * @return {string}
 */
proto.rct.web.CatalogItemMetadata.prototype.getDisplayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CatalogItemMetadata} returns this
 */
proto.rct.web.CatalogItemMetadata.prototype.setDisplayname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.rct.web.CatalogItemMetadata.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rct.web.CatalogItemMetadata} returns this
 */
proto.rct.web.CatalogItemMetadata.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional rct.common.TextItem description = 4;
 * @return {?proto.rct.common.TextItem}
 */
proto.rct.web.CatalogItemMetadata.prototype.getDescription = function() {
  return /** @type{?proto.rct.common.TextItem} */ (
    jspb.Message.getWrapperField(this, grpccommon_common_pb.TextItem, 4));
};


/**
 * @param {?proto.rct.common.TextItem|undefined} value
 * @return {!proto.rct.web.CatalogItemMetadata} returns this
*/
proto.rct.web.CatalogItemMetadata.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CatalogItemMetadata} returns this
 */
proto.rct.web.CatalogItemMetadata.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CatalogItemMetadata.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional rct.common.CatalogItemType type = 5;
 * @return {!proto.rct.common.CatalogItemType}
 */
proto.rct.web.CatalogItemMetadata.prototype.getType = function() {
  return /** @type {!proto.rct.common.CatalogItemType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.rct.common.CatalogItemType} value
 * @return {!proto.rct.web.CatalogItemMetadata} returns this
 */
proto.rct.web.CatalogItemMetadata.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CatalogItemCreate.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CatalogItemCreate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CatalogItemCreate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CatalogItemCreate.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && proto.rct.web.CatalogItemMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CatalogItemCreate}
 */
proto.rct.web.CatalogItemCreate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CatalogItemCreate;
  return proto.rct.web.CatalogItemCreate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CatalogItemCreate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CatalogItemCreate}
 */
proto.rct.web.CatalogItemCreate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.rct.web.CatalogItemMetadata;
      reader.readMessage(value,proto.rct.web.CatalogItemMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CatalogItemCreate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CatalogItemCreate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CatalogItemCreate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CatalogItemCreate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.rct.web.CatalogItemMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional CatalogItemMetadata metadata = 1;
 * @return {?proto.rct.web.CatalogItemMetadata}
 */
proto.rct.web.CatalogItemCreate.prototype.getMetadata = function() {
  return /** @type{?proto.rct.web.CatalogItemMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CatalogItemMetadata, 1));
};


/**
 * @param {?proto.rct.web.CatalogItemMetadata|undefined} value
 * @return {!proto.rct.web.CatalogItemCreate} returns this
*/
proto.rct.web.CatalogItemCreate.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CatalogItemCreate} returns this
 */
proto.rct.web.CatalogItemCreate.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CatalogItemCreate.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rct.web.CatalogItemView.prototype.toObject = function(opt_includeInstance) {
  return proto.rct.web.CatalogItemView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rct.web.CatalogItemView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CatalogItemView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.rct.web.CatalogItemMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rct.web.CatalogItemView}
 */
proto.rct.web.CatalogItemView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rct.web.CatalogItemView;
  return proto.rct.web.CatalogItemView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rct.web.CatalogItemView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rct.web.CatalogItemView}
 */
proto.rct.web.CatalogItemView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.rct.web.CatalogItemMetadata;
      reader.readMessage(value,proto.rct.web.CatalogItemMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rct.web.CatalogItemView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rct.web.CatalogItemView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rct.web.CatalogItemView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rct.web.CatalogItemView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.rct.web.CatalogItemMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rct.web.CatalogItemView.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rct.web.CatalogItemView} returns this
 */
proto.rct.web.CatalogItemView.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CatalogItemMetadata metadata = 2;
 * @return {?proto.rct.web.CatalogItemMetadata}
 */
proto.rct.web.CatalogItemView.prototype.getMetadata = function() {
  return /** @type{?proto.rct.web.CatalogItemMetadata} */ (
    jspb.Message.getWrapperField(this, proto.rct.web.CatalogItemMetadata, 2));
};


/**
 * @param {?proto.rct.web.CatalogItemMetadata|undefined} value
 * @return {!proto.rct.web.CatalogItemView} returns this
*/
proto.rct.web.CatalogItemView.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rct.web.CatalogItemView} returns this
 */
proto.rct.web.CatalogItemView.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rct.web.CatalogItemView.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.rct.web);
