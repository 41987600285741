import { FunctionComponent, useMemo } from 'react';
import { Tag } from '../Tag';
import { Card, CardStyles } from '../Card';
import {
  SloState,
  TagState,
  colors,
  TimeWindowType,
  TimeWindowUnitType,
  SloInTheList,
  getSloState,
} from '../../definitions';
import { SloStatusMap, SloStatus } from '../../grpc/grpcweb/slo_pb';
import { getSloStatusLabel, useTimeWindowUnitType, useTimeWindowType } from '../../grpcMappings';

export type SloCardProps = {
  slo: SloInTheList;
  onViewSLO: (shortName?: string) => void;
};

export const SloCardView: FunctionComponent<SloCardProps> = ({ onViewSLO, slo }) => {
  const {
    displayname,
    shortname,
    currentslovalue,
    status,
    type,
    timeWindowType,
    timeWindowUnit,
    timeWindow,
  } = slo;
  const target = slo.objective?.value;

  const sloStatus = getSloStatusLabel(status);
  const timeUnit = useTimeWindowUnitType(timeWindowUnit);
  const timeType = useTimeWindowType(timeWindowType);

  const state = getSloState(currentslovalue, target);

  const mapStatusToTagAppearance = useMemo(
    () =>
      (status: SloStatusMap[keyof SloStatusMap]): TagState | undefined => {
        if (status === SloStatus.SLO_STATUS_ACTIVE) {
          return undefined;
        }
        if (status === SloStatus.SLO_STATUS_FAILING) {
          return TagState.Warning;
        } else {
          return TagState.Disabled;
        }
      },
    [],
  );

  const mapObjectiveToTagAppearance = useMemo(
    () =>
      (state: SloState): TagState | undefined => {
        if (state === SloState.Fail) {
          return TagState.Warning;
        } else {
          return undefined;
        }
      },
    [],
  );

  const getCardColor = (state: SloState): string | undefined => {
    switch (state) {
      case SloState.Success:
        return colors.lawn;
      case SloState.Fail:
        return colors.strawberry;
      default:
        return undefined;
    }
  };

  return (
    <Card
      title={displayname}
      color={getCardColor(state)}
      upperSectionChildren={
        <CardStyles.Number>
          <span>{state === SloState.NotAvailable ? 'n/a' : `${currentslovalue}%`}</span>
        </CardStyles.Number>
      }
      lowerSectionChildren={
        <CardStyles.TagWrapper>
          {sloStatus && (
            <Tag tagKey="status" value={sloStatus} state={mapStatusToTagAppearance(status)} />
          )}
          {type && <Tag value={type} tagKey="type" />}
          {timeWindowType &&
            timeType !== TimeWindowType.Unspecified &&
            timeUnit !== TimeWindowUnitType.Unspecified &&
            timeWindowUnit &&
            timeWindow && <Tag tagKey={timeType} value={`${timeWindow}${timeUnit[0]}`} />}
          {target && (
            <Tag value={target} tagKey="objective" state={mapObjectiveToTagAppearance(state)} />
          )}
        </CardStyles.TagWrapper>
      }
      onEditClick={() => shortname && onViewSLO(shortname)}
    />
  );
};
