import { grpc } from '@improbable-eng/grpc-web';
import { TagsService } from '../grpc/grpcweb/tag_pb_service';
import {
  ListTagsRequest,
  ListTagsResponse,
  CreateTagRequest,
  AddTagValueResponse,
  AddTagValueRequest,
  LinkTagRequest,
  LinkTagResponse,
  CreateTagResponse,
  UpdateTagRequest,
  UpdateTagResponse,
  UpdateTagValueRequest,
  UpdateTagValueResponse,
  DeleteTagValueRequest,
  DeleteTagValueResponse,
  DeleteTagRequest,
  DeleteTagResponse,
  GetTagByKeyRequest,
  GetTagByKeyResponse,
  ListAllTagsRequest,
  ListAllTagsResponse,
  UnlinkTagRequest,
  UnlinkTagResponse,
  GetTagRequest,
  GetTagResponse,
  GetTagByKeyValueRequest,
  GetTagByKeyValueResponse,
} from '../grpc/grpcweb/tag_pb';
import EventEmitter from './helpers/EventEmitter';
import StorageService from './StorageService';
import { InitialiseProps } from './BaseService';

export default class TagService extends EventEmitter {
  private storage: StorageService;
  private readonly host: string;

  // private readonly clientId: string;

  // private errorReportingService: any;

  constructor(host: string) {
    super();
    this.storage = new StorageService();
    this.host = host;
  }

  async initialise({ errorReportingService }: InitialiseProps): Promise<void> {
    await this.storage.initialise();
  }

  fetchTag = async (RctToken: string, fetchTagRequest: GetTagRequest) => {
    const request: GetTagRequest = fetchTagRequest;

    return new Promise((resolve, reject) => {
      grpc.unary(TagsService.Get, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetTagResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTag());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch tag failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchByKey = async (RctToken: string, fetchTagRequest: GetTagByKeyRequest) => {
    const request: GetTagByKeyRequest = fetchTagRequest;

    return new Promise((resolve, reject) => {
      grpc.unary(TagsService.GetByKey, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetTagByKeyResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTag());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch tag by key failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchByKeyAndValue = async (RctToken: string, fetchTagRequest: GetTagByKeyValueRequest) => {
    const request: GetTagByKeyValueRequest = fetchTagRequest;

    return new Promise((resolve, reject) => {
      grpc.unary(TagsService.GetByKeyValue, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<GetTagByKeyValueResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch tag by key value failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchAllForSearch = async (RctToken: string) => {
    const request: ListAllTagsRequest = new ListAllTagsRequest();

    return new Promise((resolve, reject) => {
      grpc.unary(TagsService.ListAll, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListAllTagsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTagsList());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch all tags failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  fetchList = async (RctToken: string, listTagsRequest: ListTagsRequest) => {
    const request: ListTagsRequest = listTagsRequest;

    return new Promise((resolve, reject) => {
      grpc.unary(TagsService.List, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<ListTagsResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.toObject());
          } else {
            reject(out.statusMessage);
            console.log(
              'fetch tag list failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  createNewTag = async (RctToken: string, createTagRequest: CreateTagRequest) => {
    return new Promise((resolve, reject) => {
      const request: CreateTagRequest = createTagRequest;
      grpc.unary(TagsService.Create, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<CreateTagResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTag());
          } else {
            reject(out.statusMessage);
            console.log(
              'create new tag failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  addValueToTag = async (RctToken: string, addTagValueRequest: AddTagValueRequest) => {
    return new Promise((resolve, reject) => {
      const request: AddTagValueRequest = addTagValueRequest;

      grpc.unary(TagsService.AddValue, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<AddTagValueResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTag());
          } else {
            reject(out.statusMessage);
            console.log(
              'add new value to a tag failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  updateTagValue = async (RctToken: string, updateValueRequest: UpdateTagValueRequest) => {
    return new Promise((resolve, reject) => {
      const request: UpdateTagValueRequest = updateValueRequest;

      grpc.unary(TagsService.UpdateValue, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateTagValueResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTag());
          } else {
            reject(out.statusMessage);
            console.log(
              'update tag value failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteTagValue = async (RctToken: string, deleteValueRequest: DeleteTagValueRequest) => {
    return new Promise((resolve, reject) => {
      const request: DeleteTagValueRequest = deleteValueRequest;

      grpc.unary(TagsService.DeleteValue, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteTagValueResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTag());
          } else {
            reject(out.statusMessage);
            console.log(
              'delete tag value failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  updateTag = async (RctToken: string, updateTagRequest: UpdateTagRequest) => {
    return new Promise((resolve, reject) => {
      const request: UpdateTagRequest = updateTagRequest;

      grpc.unary(TagsService.Update, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UpdateTagResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message.getTag());
          } else {
            reject(out.statusMessage);
            console.log(
              'update tag failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  deleteTag = async (RctToken: string, deleteTagRequest: DeleteTagRequest) => {
    return new Promise((resolve, reject) => {
      const request: DeleteTagRequest = deleteTagRequest;

      grpc.unary(TagsService.Delete, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<DeleteTagResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'delete tag failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  linkTag = async (RctToken: string, linkTagRequest: LinkTagRequest) => {
    return new Promise((resolve, reject) => {
      const request: LinkTagRequest = linkTagRequest;
      grpc.unary(TagsService.Link, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<LinkTagResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'link tag failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };

  unlinkTag = async (RctToken: string, unlinkTagRequest: UnlinkTagRequest) => {
    return new Promise((resolve, reject) => {
      const request: UnlinkTagRequest = unlinkTagRequest;
      grpc.unary(TagsService.Unlink, {
        host: this.host,
        debug: true,
        metadata: new grpc.Metadata({ RctToken }),
        onEnd: (out: grpc.UnaryOutput<UnlinkTagResponse>) => {
          if (out.status === grpc.Code.OK && out.message) {
            return resolve(out.message);
          } else {
            reject(out.statusMessage);
            console.log(
              'unlink tag failed',
              out.status,
              out.message,
              out.trailers,
              out.statusMessage,
            );
          }
        },
        request,
      });
    });
  };
}
