import { FunctionComponent } from 'react';
import { CatalogItemView } from '../../grpc/grpcweb/catalog_item_pb';
import {
  SloFormData,
  FormStateType,
  EventSourcesInTheList,
  ComponentRefInTheList,
} from '../../definitions';
import SloForm from '../../forms/SloForm';
import { Icon } from '../../components/Icon';

type CreateSloProps = {
  onCreateSlo: (formData: SloFormData) => void;
  componentList: ComponentRefInTheList[];
  isLoading: boolean;
  isDisabled: boolean;
  isLoadingComponentList: boolean;
  isLoadingSLOTypes: boolean;
  isLoadingEventSource: boolean;
  eventSourceList: EventSourcesInTheList[];
  sloTypes: CatalogItemView.AsObject[];
  savedFormData: SloFormData;
  onUpdateSLOState: (formState: FormStateType) => void;
  onCancel: () => void;
};

export const CreateSloView: FunctionComponent<CreateSloProps> = ({
  onCreateSlo,
  isLoading,
  isDisabled,
  isLoadingComponentList,
  componentList,
  isLoadingSLOTypes,
  sloTypes,
  savedFormData,
  onUpdateSLOState,
  eventSourceList,
  isLoadingEventSource,
  onCancel,
}) => {
  return (
    <SloForm
      onSubmitSlo={onCreateSlo}
      componentList={componentList}
      onUpdateSLOState={onUpdateSLOState}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isLoadingComponentList={isLoadingComponentList}
      isLoadingSLOTypes={isLoadingSLOTypes}
      sloTypes={sloTypes}
      initialValues={savedFormData}
      eventSourceList={eventSourceList}
      isLoadingEventSource={isLoadingEventSource}
      submitButtonText="Create SLO"
      submitButtonIcon={<Icon.Create />}
      onCancel={onCancel}
    />
  );
};
