// package: rct.web
// file: grpcweb/notification.proto

var grpcweb_notification_pb = require("../grpcweb/notification_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var NotificationService = (function () {
  function NotificationService() {}
  NotificationService.serviceName = "rct.web.NotificationService";
  return NotificationService;
}());

NotificationService.List = {
  methodName: "List",
  service: NotificationService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_notification_pb.ListNotificationsRequest,
  responseType: grpcweb_notification_pb.ListNotificationsResponse
};

NotificationService.MarkAllAsRead = {
  methodName: "MarkAllAsRead",
  service: NotificationService,
  requestStream: false,
  responseStream: false,
  requestType: grpcweb_notification_pb.MarkAllNotificationsAsReadRequest,
  responseType: grpcweb_notification_pb.MarkAllNotificationsAsReadResponse
};

exports.NotificationService = NotificationService;

function NotificationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

NotificationServiceClient.prototype.list = function list(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationService.List, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

NotificationServiceClient.prototype.markAllAsRead = function markAllAsRead(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(NotificationService.MarkAllAsRead, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.NotificationServiceClient = NotificationServiceClient;

