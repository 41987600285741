import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  margin-left: 0.5rem;
  display: flex;
  svg {
    width: 1.25rem;
    height: auto;
    cursor: pointer;
    fill: ${({ theme }) => theme.palettes.border};
    &:hover {
      fill: ${({ theme }) => theme.palettes.secondaryHighlight};
    }
  }
`;
