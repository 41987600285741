import { ActionType, FormStateType, FlattenedTeamBatch, TeamFormData } from '../../definitions';
import actionTypes from '../constants/actionTypes';

export const createTeam = (payload = {}): ActionType => ({
  type: actionTypes.teams.create,
  payload,
});

export const selectTeam = (payload: FlattenedTeamBatch): ActionType => ({
  type: actionTypes.teams.current.select,
  payload,
});

export const clearSelectedTeam = (payload = {}): ActionType => ({
  type: actionTypes.teams.current.clear,
  payload,
});

export const updateTeam = (payload: { formData: TeamFormData; teamId: string }): ActionType => ({
  type: actionTypes.teams.current.update,
  payload,
});

export const deleteTeam = (teamId: string): ActionType => ({
  type: actionTypes.teams.current.delete,
  payload: teamId,
});

export const updateNewTeam = (payload: FormStateType): ActionType => ({
  type: actionTypes.teams.updateNew,
  payload,
});

export const resetNewTeamState = (payload = {}): ActionType => ({
  type: actionTypes.teams.resetNew,
  payload,
});

export const fetchTeamList = (pageSize?: number): ActionType => ({
  type: actionTypes.teams.fetchList,
  payload: pageSize,
});

export const fetchNextTeamListPage = (pageSize?: number): ActionType => ({
  type: actionTypes.teams.fetchNextPage,
  payload: pageSize,
});

export const fetchPrevTeamListPage = (pageSize?: number): ActionType => ({
  type: actionTypes.teams.fetchPrevPage,
  payload: pageSize,
});

export const fetchTeamByShortname = (payload: string): ActionType => ({
  type: actionTypes.teams.current.fetch,
  payload,
});

export const fetchTeamById = (teamId: string): ActionType => ({
  type: actionTypes.teams.current.fetchById,
  payload: teamId,
});

export const addUsersToTheTeam = (payload: { userIds: string[]; teamId: string }): ActionType => ({
  type: actionTypes.teams.current.addUsers,
  payload,
});

export const removeUserFromTheTeam = (payload: { userId: string; teamId: string }): ActionType => ({
  type: actionTypes.teams.current.removeUser,
  payload,
});
