import { FunctionComponent, MouseEvent } from 'react';
import { isExternalUrl, ClickTarget } from '../../definitions';
import * as Styled from './Link.styles';

export type LinkProps = {
  className?: string;
  to?: string;
  onClick?: (e: MouseEvent, target: ClickTarget) => void;
  isPending?: boolean;
  isDisabled?: boolean;
  color?: string;
  title?: string;
};

export const LinkView: FunctionComponent<LinkProps> = ({
  children,
  to,
  onClick = () => {},
  isPending = false,
  isDisabled,
  color,
  title,
  ...rest
}) => {
  if (to) {
    if (isExternalUrl(to)) {
      return (
        <Styled.Anchor
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          isPending={isPending}
          color={color}
          disabled={isDisabled}
          title={title}
          {...rest}
        >
          {children}
        </Styled.Anchor>
      );
    }

    return (
      <Styled.RouterLink
        to={to}
        onClick={(e: MouseEvent) => {
          if (!pending && onClick) {
            onClick(e, ClickTarget.Link);
          }
        }}
        isPending={isPending}
        color={color}
        disabled={isDisabled}
        title={title}
        {...rest}
      >
        {children}
      </Styled.RouterLink>
    );
  }

  return (
    <Styled.Button
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        if (!isPending && onClick) {
          onClick(e, ClickTarget.Button);
        }
      }}
      isPending={isPending}
      color={color}
      title={title}
      {...rest}
    >
      {children}
    </Styled.Button>
  );
};
