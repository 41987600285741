const actionTypes = {
  alerts: {
    show: 'alerts/show',
    hide: 'alerts/hide',
  },
  auth: {
    initialise: 'auth/initialise',
    initialiseWithDemo: 'auth/initialiseWithDemo',
    completeAuth: 'auth/completeAuth',
    check: 'auth/check',
    checked: 'auth/checked',
    redirect: 'auth/redirect',
    complete: 'auth/complete',
    logout: 'auth/logout',
    loggedOut: 'auth/loggedOut',
  },
  bots: {
    fetchList: 'bots/fetchList',
    fetchedList: 'bots/fetchedList',
    updateNew: 'bots/updateNew',
    resetNew: 'bots/resetNew',
    create: 'bots/create',
    created: 'bots/created',
    current: {
      fetch: 'bots/current/fetch',
      fetched: 'bots/current/fetched',
      update: 'bots/current/update',
      updated: 'bots/current/updated',
      delete: 'bots/current/delete',
      deleted: 'bots/current/deleted',
      createToken: 'bots/current/createToken',
      createdToken: 'bots/current/createdToken',
      selectToken: 'bots/current/selectToken',
      selectedToken: 'bots/current/selectedToken',
      deleteToken: 'bots/current/deleteToken',
      deletedToken: 'bots/current/deletedToken',
    },
  },
  components: {
    fetchList: 'components/fetchList',
    fetchedList: 'components/fetchedList',
    fetchNextPage: 'components/fetchNextPage',
    fetchedNextPage: 'components/fetchedNextPage',
    fetchPrevPage: 'components/fetchPrevPage',
    fetchedPrevPage: 'components/fetchedPrevPage',
    fetchMy: 'components/fetchMy',
    fetchedMy: 'components/fetchedMy',
    fetchTypes: 'components/fetchTypes',
    fetchedTypes: 'components/fetchedTypes',
    create: 'components/create',
    created: 'components/created',
    updateNew: 'components/updateNew',
    standAlone: {
      fetchList: 'components/standAlone/fetchList',
      fetchedList: 'components/standAlone/fetchedList',
      fetchNextPage: 'components/standAlone/fetchNextPage',
      fetchedNextPage: 'components/standAlone/fetchedNextPage',
      fetchPrevPage: 'components/standAlone/fetchPrevPage',
      fetchedPrevPage: 'components/standAlone/fetchedPrevPage',
    },
    current: {
      select: 'components/current/select',
      fetchByShortname: 'components/current/fetchByShortname',
      fetchedByShortname: 'components/current/fetchedByShortname',
      fetchById: 'components/current/fetchById',
      fetchedById: 'components/current/fetchedById',
      fetchSLOs: 'components/current/fetchSLOs',
      fetchedSLOs: 'components/current/fetchedSLOs',
      delete: 'components/current/delete',
      deleted: 'components/current/deleted',
      edit: 'components/current/edit',
      edited: 'components/current/edited',
      comments: {
        fetch: 'components/current/comments/fetch',
        fetched: 'components/current/comments/fetched',
        add: 'components/current/comments/add',
        added: 'components/current/comments/added',
      },
      dependencies: {
        fetch: 'components/current/dependencies/fetch',
        fetched: 'components/current/dependencies/fetched',
        linkDependents: 'components/current/dependencies/linkDependents',
        linkedDependents: 'components/current/dependencies/linkedDependents',
        linkDependencies: 'components/current/dependencies/linkDependencies',
        linkedDependencies: 'components/current/dependencies/linkedDependencies',
        unlinkDependency: 'components/current/dependencies/unlinkDependency',
        unlinkedDependency: 'components/current/dependencies/unlinkedDependency',
        unlinkDependant: 'components/current/dependencies/unlinkDependant',
        unlinkedDependant: 'components/current/dependencies/unlinkedDependant',
        select: 'components/current/dependencies/select',
        clear: 'components/current/dependencies/clear',
      },
      links: {
        add: 'components/current/links/add',
        added: 'components/current/links/added',
        edit: 'components/current/links/edit',
        edited: 'components/current/links/edited',
        delete: 'components/current/links/delete',
        deleted: 'components/current/links/deleted',
      },
      popup: {
        fetchDetails: 'components/current/popup/fetchDetails',
        fetchedDetails: 'components/current/popup/fetchedDetails',
      },
    },
  },
  errors: {
    clear: 'errors/clear',
  },
  eventSources: {
    fetchList: 'eventSources/fetchList',
    fetchedList: 'eventSources/fetchedList',
    updateNew: 'eventSources/updateNew',
    resetNew: 'eventSources/resetNew',
    create: 'eventSources/create',
    created: 'eventSources/created',
    update: 'eventSources/update',
    updated: 'eventSources/updated',
    fetchOne: 'eventSources/fetchOne',
    fetchedOne: 'eventSources/fetchedOne',
    delete: 'eventSources/delete',
    deleted: 'eventSources/deleted',
  },
  flow: {
    register: 'flow/register',
    registered: 'flow/registered',
    unregister: 'flow/unregister',
    setStep: 'flow/setStep',
    nextStep: 'flow/nextStep',
    previousStep: 'flow/previousStep',
    skipStep: 'flow/skipStep',
    complete: 'flow/complete',
  },
  location: {
    previous: 'location/previous',
    goBack: 'location/goBack',
  },
  modal: {
    close: 'modal/close',
  },
  notifications: {
    fetch: 'notifications/fetch',
    fetched: 'notifications/fetched',
    markAsRead: 'notifications/markAsRead',
    markedAsRead: 'notifications/markedAsRead',
  },
  slos: {
    fetchList: 'slos/fetchList',
    fetchedList: 'slos/fetchedList',
    fetchOne: 'slos/fetchOne',
    fetchedOne: 'slos/fetchedOne',
    fetchHistory: 'slos/fetchHistory',
    fetchedHistory: 'slos/fetchedHistory',
    create: 'slos/create',
    created: 'slos/created',
    fetchTypes: 'slos/fetchTypes',
    fetchedTypes: 'slos/fetchedTypes',
    updateNew: 'slos/updateNew',
    updatedNew: 'slos/updatedNew',
    update: 'slos/update',
    updated: 'slos/updated',
    launch: 'slos/launch',
    launched: 'slos/launched',
    delete: 'slos/delete',
    deleted: 'slos/deleted',
    setComponent: 'slos/setComponent',
    comments: {
      fetch: 'slos/comments/fetch',
      fetched: 'slos/comments/fetched',
      add: 'slos/comments/add',
      added: 'slos/comments/added',
    },
    activity: {
      fetch: 'slos/activity/fetch',
      fetched: 'slos/activity/fetched',
      fetchComments: 'slos/activity/fetchComments',
      fetchedComments: 'slos/activity/fetchedComments',
    },
  },
  successes: {
    clear: 'successes/clear',
  },
  tags: {
    create: 'tags/create',
    created: 'tags/created',
    updateNew: 'tags/updateNew',
    updateNewValues: 'tags/updateNewValues',
    resetNew: 'tags/resetNew',
    fetchAll: 'tags/fetchAll',
    fetchedAll: 'tags/fetchedAll',
    fetchList: 'tags/fetchList',
    fetchedList: 'tags/fetchedList',
    fetchNextPage: 'tags/fetchNextPage',
    fetchedNextPage: 'tags/fetchedNextPage',
    fetchPrevPage: 'tags/fetchPrevPage',
    fetchedPrevPage: 'tags/fetchedPrevPage',
    current: {
      fetchByKey: 'tags/current/fetchByKey',
      fetchedByKey: 'tags/current/fetchedByKey',
      fetchByKeyAndValue: 'tags/current/fetchByKeyAndValue',
      fetchedByKeyAndValue: 'tags/current/fetchedByKeyAndValue',
      fetch: 'tags/current/fetch',
      fetched: 'tags/current/fetched',
      select: 'tags/current/select',
      clear: 'tags/current/clear',
      update: 'tags/current/update',
      updated: 'tags/current/updated',
      delete: 'tags/current/delete',
      deleted: 'tags/current/deleted',
      values: {
        update: 'tags/current/values/update',
        updated: 'tags/current/values/updated',
        delete: 'tags/current/values/delete',
        deleted: 'tags/current/values/deleted',
        create: 'tags/current/values/create',
        created: 'tags/current/values/created',
      },
    },
    components: {
      link: 'tags/components/link',
      linked: 'tags/components/linked',
      unlink: 'tags/components/unlink',
      unlinked: 'tags/components/unlinked',
    },
    slos: {
      link: 'tags/slos/link',
      linked: 'tags/slos/linked',
    },
  },
  teams: {
    create: 'teams/create',
    created: 'teams/created',
    updateNew: 'teams/updateNew',
    resetNew: 'teams/resetNew',
    fetchList: 'teams/fetchList',
    fetchedList: 'teams/fetchedList',
    fetchNextPage: 'teams/fetchNextPage',
    fetchedNextPage: 'teams/fetchedNextPage',
    fetchPrevPage: 'teams/fetchPrevPage',
    fetchedPrevPage: 'teams/fetchedPrevPage',
    current: {
      select: 'teams/current/select',
      clear: 'teams/current/clear',
      fetch: 'teams/current/fetch',
      fetched: 'teams/current/fetched',
      fetchById: 'teams/current/fetchById',
      fetchedById: 'teams/current/fetchedById',
      update: 'teams/current/update',
      updated: 'teams/current/updated',
      delete: 'teams/current/delete',
      deleted: 'teams/current/deleted',
      addUsers: 'teams/current/addUsers',
      addedUsers: 'teams/current/addedUsers',
      removeUser: 'teams/current/removeUser',
      removedUser: 'teams/current/removedUser',
    },
  },
  users: {
    fetch: 'users/fetch',
    fetched: 'users/fetched',
    fetchNextPage: 'users/fetchNextPage',
    fetchedNextPage: 'users/fetchedNextPage',
    fetchPrevPage: 'users/fetchPrevPage',
    fetchedPrevPage: 'users/fetchedPrevPage',
    selectUserId: 'users/current/selectUserId',
    clearSelectedUsers: 'users/current/clearSelectedUsers',
    current: {
      ready: 'users/current/ready',
      update: 'users/current/update',
      updated: 'users/current/updated',
      fetch: 'users/current/fetch',
      fetched: 'users/current/fetched',
      remove: 'users/current/remove',
      removed: 'users/current/removed',
    },
  },
  xEvents: {
    fetchList: 'xEvents/fetchList',
    fetchedList: 'xEvents/fetchedList',
    fetchNextPage: 'xEvents/fetchNextPage',
    fetchedNextPage: 'xEvents/fetchedNextPage',
    fetchPrevPage: 'xEvents/fetchPrevPage',
    fetchedPrevPage: 'xEvents/fetchedPrevPage',
  },
};

export default actionTypes;
