import styled, { css } from 'styled-components';
import { Link } from '../Link';

export const Container = styled.div`
  display: inline-flex;
  height: 2rem;
`;

export const Crumb = styled(Link)`
  color: ${({ theme }) => theme.palettes.placeholder};
  align-items: flex-end;

  &:hover {
    &&& {
      color: ${({ theme }) => theme.palettes.main};
    }
  }

  ${props =>
    props.isDisabled &&
    css`
      color: ${({ theme }) => theme.palettes.main};
    `}
`;

export const Wrapper = styled.div`
  display: inline-flex;
  white-space: nowrap !important;
  max-width: 10rem;
  align-items: center;
  svg {
    width: 1em;
    height: 1em;
    margin-right: 0.25rem;
  }
  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    margin: 0;
    display: inline-block;
  }
`;

export const Divider = styled.span`
  margin: 0 0.5rem;
  color: ${({ theme }) => theme.palettes.placeholder};
`;
