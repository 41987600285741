import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { RouteChildrenProps, generatePath } from 'react-router';
import { ComponentMetadata } from '../../grpc/grpcweb/component_pb';
import { usePageTitle, useQueryParams } from '../../hooks';
import {
  getTotalNumberOfComponents,
  getTeamMap,
  getSelectedComponent,
  getComponentDependencies,
  getComponentDependents,
  isFetchingComponentDependencies,
  fetchComponentDependencies,
  fetchComponentByShortname,
  isFetchingComponentByShortname,
  fetchComponentList,
  getAllTagForSearch,
  isFetchingAllTagsForSearch,
  fetchAllTagsForSearch,
  fetchStandAloneComponents,
  fetchNextStandAloneComponentListPage,
  fetchPrevStandAloneComponentListPage,
  isFetchingStandAloneComponentList,
  isFetchingStandAloneComponentListNextPage,
  isFetchingStandAloneComponentListPrevPage,
  getTotalNumberOfStandAloneComponents,
  getStandAloneComponentListPrevPageToken,
  getStandAloneComponentListNextPageToken,
  getStandAloneComponentList,
} from '../../store';
import { ComponentRefInTheList, paths, RelationCluster } from '../../definitions';
import { COMPONENT_LIST_PAGE_SIZE } from '../../store/constants/pageSize';
import { RelationsView } from './Relations.view';

type RelationsContainerProps = {
  isCreateDrawerOpen?: boolean;
} & RouteChildrenProps;

export const RelationsContainer: FunctionComponent<RelationsContainerProps> = ({
  isCreateDrawerOpen,
  match,
  ...rest
}) => {
  usePageTitle('Relations');
  const dispatch = useDispatch();

  const [isCreateDrawerVisible, setIsCreateDrawerVisible] = useState(false);

  const selectedComponent = useSelector(getSelectedComponent);

  const isFetchingComponent = useSelector(isFetchingComponentByShortname);
  const isFetchingDependencies = useSelector(isFetchingComponentDependencies);

  const numberOfComponents = useSelector(getTotalNumberOfComponents);
  const teamMap = useSelector(getTeamMap);
  const tagList = useSelector(getAllTagForSearch);
  const allTags = Object.keys(tagList);

  const dependencies = useSelector(getComponentDependencies);
  const dependents = useSelector(getComponentDependents);

  const query = useQueryParams();
  const clusterBy = (query.get('clusterBy') as RelationCluster) || RelationCluster.None;
  const selectedTagKey = query.get('tagKey') || undefined;
  const isFetchingTags = useSelector(isFetchingAllTagsForSearch);

  const isFetchingStandAloneComponents = useSelector(isFetchingStandAloneComponentList);
  const isFetchingStandAloneNextPage = useSelector(isFetchingStandAloneComponentListNextPage);
  const isFetchingStandAlonePrevPage = useSelector(isFetchingStandAloneComponentListPrevPage);
  const standAloneComponentList = useSelector(getStandAloneComponentList);
  const numberOfStandAloneComponents = useSelector(getTotalNumberOfStandAloneComponents);
  const componentListPrevPageToken = useSelector(getStandAloneComponentListPrevPageToken);
  const componentListNextPageToken = useSelector(getStandAloneComponentListNextPageToken);

  const { selectedComponentShortname } = match?.params as {
    selectedComponentShortname: ComponentMetadata.AsObject['shortname'];
  };

  useEffect(() => {
    if (selectedComponentShortname) {
      dispatch(fetchComponentByShortname(selectedComponentShortname));
    }
  }, [dispatch, selectedComponentShortname]);

  useEffect(() => {
    if (selectedComponent?.id) {
      dispatch(fetchComponentDependencies(selectedComponent.id));
    }
  }, [dispatch, selectedComponent.id]);

  useEffect(() => {
    // we need to know number of components
    dispatch(fetchComponentList({ pageSize: 1 }));
    dispatch(fetchStandAloneComponents(COMPONENT_LIST_PAGE_SIZE));
  }, [dispatch]);

  useEffect(() => {
    if (isCreateDrawerOpen) {
      setIsCreateDrawerVisible(true);
    } else {
      setIsCreateDrawerVisible(false);
    }
  }, [isCreateDrawerOpen]);

  const handleSetClusterBy = useCallback(
    (relationCluster: RelationCluster) => {
      if (selectedComponentShortname) {
        dispatch(
          push(
            `${generatePath(paths.relationsSelectedComponent, {
              selectedComponentShortname,
            })}?clusterBy=${relationCluster}${selectedTagKey ? `&tagKey=${selectedTagKey}` : ''}`,
          ),
        );
      } else {
        dispatch(
          push(
            `${paths.relations}?clusterBy=${relationCluster}${
              selectedTagKey ? `&tagKey=${selectedTagKey}` : ''
            }`,
          ),
        );
      }
    },
    [dispatch, selectedTagKey, selectedComponentShortname],
  );

  const handleSetSelectedTagKey = useCallback(
    (tagKey: string | undefined) => {
      if (selectedComponentShortname) {
        dispatch(
          push(
            `${generatePath(paths.relationsSelectedComponent, {
              selectedComponentShortname,
            })}?clusterBy=${RelationCluster.Tag}&tagKey=${tagKey}`,
          ),
        );
      } else {
        dispatch(push(`${paths.relations}?clusterBy=${RelationCluster.Tag}&tagKey=${tagKey}`));
      }
    },
    [selectedComponentShortname, dispatch],
  );

  const handleResetClusterByTag = useCallback(() => {
    if (selectedComponentShortname) {
      dispatch(
        push(
          `${generatePath(paths.relationsSelectedComponent, {
            selectedComponentShortname,
          })}?clusterBy=${RelationCluster.None}`,
        ),
      );
    } else {
      dispatch(push(`${paths.relations}?clusterBy=${RelationCluster.None}`));
    }
  }, [dispatch, selectedComponentShortname]);

  const handleViewComponent = useCallback(
    (componentShortname?: string) => {
      componentShortname && dispatch(push(generatePath(paths.component, { componentShortname })));
    },
    [dispatch],
  );

  const handleSelectComponent = useCallback(
    (component: ComponentRefInTheList) => {
      if (component.shortname) {
        const searchParams = clusterBy
          ? `?clusterBy=${clusterBy}${selectedTagKey ? `&tagKey=${selectedTagKey}` : ''}`
          : '';
        dispatch(
          push(
            `${generatePath(paths.relationsSelectedComponent, {
              selectedComponentShortname: component.shortname,
            })}${searchParams}`,
          ),
        );
      }
    },
    [dispatch, clusterBy, selectedTagKey],
  );

  const handleOpenCreateComponent = useCallback(() => {
    dispatch(push(paths.createComponentFromDashboard));
  }, [dispatch]);

  const handleCloseCreateComponent = useCallback(() => {
    dispatch(push(paths.dashboard));
  }, [dispatch]);

  const handleFetchTags = useCallback(() => {
    dispatch(fetchAllTagsForSearch());
  }, [dispatch]);

  const handleFetchStandAloneNextPage = useCallback(() => {
    dispatch(fetchNextStandAloneComponentListPage(COMPONENT_LIST_PAGE_SIZE));
  }, [dispatch]);

  const handleFetchStandAlonePrevPage = useCallback(() => {
    dispatch(fetchPrevStandAloneComponentListPage(COMPONENT_LIST_PAGE_SIZE));
  }, [dispatch]);

  return (
    <RelationsView
      onViewComponent={handleViewComponent}
      isFetchingComponent={isFetchingComponent}
      isFetchingDependencies={isFetchingDependencies}
      isCreateDrawerVisible={isCreateDrawerVisible}
      onOpenCreateComponent={handleOpenCreateComponent}
      onCloseCreateComponent={handleCloseCreateComponent}
      onSelectComponent={handleSelectComponent}
      numberOfComponents={numberOfComponents}
      teamMap={teamMap}
      selectedComponent={
        selectedComponentShortname ? { ...selectedComponent, tagList: [] } : undefined
      }
      dependencies={selectedComponentShortname ? dependencies : []}
      dependents={selectedComponentShortname ? dependents : []}
      match={match}
      selectedTagKey={selectedTagKey}
      clusterBy={clusterBy}
      onSetClusterBy={handleSetClusterBy}
      onSetSelectedTagKey={handleSetSelectedTagKey}
      onResetClusterByTag={handleResetClusterByTag}
      allTags={allTags}
      onFetchTags={handleFetchTags}
      isFetchingTags={isFetchingTags}
      numberOfStandAloneComponents={numberOfStandAloneComponents}
      isFetchingStandAloneComponents={
        isFetchingStandAloneComponents ||
        isFetchingStandAloneNextPage ||
        isFetchingStandAlonePrevPage
      }
      isStandAlonePrevPageDisabled={!componentListPrevPageToken}
      isStandAloneNextPageDisabled={!componentListNextPageToken}
      standAloneComponentList={standAloneComponentList}
      onFetchStandAloneNextPage={handleFetchStandAloneNextPage}
      onFetchStandAlonePrevPage={handleFetchStandAlonePrevPage}
      {...rest}
    />
  );
};
