import { FunctionComponent } from 'react';
import { Field } from 'react-final-form';
import { validation, parsers, PrometheusAuthType } from '../../definitions';
import { Input, Radio } from '../../components/Form';

type PrometheusFormProps = {
  authenticationType?: string;
  clickedSubmit: boolean;
};

const PrometheusFormView: FunctionComponent<PrometheusFormProps> = ({
  authenticationType,
  clickedSubmit,
}) => {
  return (
    <>
      <Radio.Container title="Authentication Type" tooltip="Pick rolling window to...">
        <Field
          id="authenticationType"
          name="authenticationType"
          placeholder="Basic"
          label="Basic"
          value={PrometheusAuthType.Basic.toString()}
          type="radio"
          component={Radio}
          showValidation={clickedSubmit}
        />
        <Field
          id="authenticationType"
          name="authenticationType"
          placeholder="Unspecified"
          label="Unspecified"
          value={PrometheusAuthType.Unspecified.toString()}
          type="radio"
          component={Radio}
          showValidation={clickedSubmit}
          isDisabled={true}
        />
      </Radio.Container>
      {authenticationType === PrometheusAuthType.Basic && (
        <>
          <Field
            id="url"
            name="url"
            placeholder="Url"
            label="Url"
            component={Input}
            validate={validation.compose(value => validation.required(value), validation.url)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            maxLength="120"
            tooltip="Display Name should be unique and represent the capability SLO stands for, ex. 'User can login'"
          />
          <Field
            id="username"
            name="username"
            placeholder="Username"
            label="Username"
            component={Input}
            validate={value => validation.required(value)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            autoComplete="new-username"
            maxLength="120"
            tooltip="Display Name should be unique and represent the capability SLO stands for, ex. 'User can login'"
          />
          <Field
            id="password"
            name="password"
            placeholder="Password"
            label="Password"
            component={Input}
            validate={value => validation.required(value)}
            showValidation={clickedSubmit}
            format={parsers.trim}
            formatOnBlur={true}
            autoComplete="new-password"
            type="password"
            maxLength="120"
            tooltip="Display Name should be unique and represent the capability SLO stands for, ex. 'User can login'"
          />
        </>
      )}
    </>
  );
};

export default PrometheusFormView;
