import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Route } from '../../../definitions';
import { Item } from '../Items';
import * as Styled from '../Sidebar.styles';

type NavigationLinkProps = Route & {
  onItemClick?: (path: string) => void;
  activePath?: string;
};

export const NavigationLinkView: FunctionComponent<NavigationLinkProps> = ({
  path,
  label,
  icon,
  activePath,
  onItemClick,
}) => {
  const PathIcon = icon;
  const location = useLocation();

  if (!onItemClick) {
    const delimiter = '/';
    const str = location.pathname;
    const start = 1;
    const tokens = str.split(delimiter).slice(start);
    const strippedPath = `/${tokens[0]}`;

    return (
      <Styled.NavLink key={path} to={path} isActive={strippedPath === path}>
        <Item icon={PathIcon} title={label} />
      </Styled.NavLink>
    );
  }

  return (
    <Styled.ClickableLink key={path} isActive={activePath === path}>
      <Item icon={PathIcon} title={label} onItemClick={onItemClick} path={path} />
    </Styled.ClickableLink>
  );
};
