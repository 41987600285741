import styled, { css } from 'styled-components';
import { AvatarAppearance } from '../../definitions';

type ContainerProps = {
  borderColor?: string;
};

type WrapperProps = {
  appearance?: AvatarAppearance;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  div:not(:first-child) {
    margin-left: -0.75rem;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  ${Container} {
    width: 2.5rem;
    height: 2.5rem;
  }

  ${props =>
    props.appearance === AvatarAppearance.Large &&
    css`
      width: 3.5rem;
      height: 3.5rem;
      ${Container} {
        width: 3.5rem;
        height: 3.5rem;
      }
    `}

  ${props =>
    props.appearance === AvatarAppearance.Small &&
    css`
      width: 1.5rem;
      height: 1.5rem;
      ${Container} {
        width: 1.5rem;
        height: 1.5rem;
      }
    `}
`;
