import { useRef, FunctionComponent, ElementType } from 'react';
import { TagAppearance, TagState, colors } from '../../definitions';
import { useIsEllipsisActive } from '../../hooks';
import Tooltip from '../Tooltip';
import { RemoveTagButton } from './TagComponents.view';
import * as Styled from './Tag.styles';

type ITag = FunctionComponent<TagProps> & {
  CustomContent: ElementType<CustomContentProps>;
};

export type TagProps = {
  appearance?: TagAppearance;
  onRemove?: () => void;
  isRemoving?: boolean;
  state?: TagState;
  tooltip?: string;
  tagKey?: string;
  value: string;
  isContentClickable?: boolean;
  removePopupTitle?: string;
  removePopupText?: string;
};

export type CustomContentProps = {
  appearance?: TagAppearance;
  state?: TagState;
  tooltip?: string;
  tagKey?: string;
  value: string;
  isContentClickable?: boolean;
};

const getColour = (state: TagState): string => {
  switch (state) {
    case TagState.Warning:
      return colors.gold;
    case TagState.Success:
      return colors.lawn;
    case TagState.Danger:
      return colors.strawberry;
    case TagState.Disabled:
      return colors.silver;
    default:
      return colors.elsa;
  }
};

const TagText: FunctionComponent<CustomContentProps> = ({
  state = TagState.Info,
  tooltip,
  tagKey,
  value,
  appearance = TagAppearance.Tag,
  isContentClickable = false,
}) => {
  const refValue = useRef<HTMLDivElement>(null);
  const refType = useRef<HTMLDivElement>(null);
  const hasValueEllipsis = useIsEllipsisActive(refValue);
  const hasTypeEllipsis = useIsEllipsisActive(refType);
  const shouldDisplayTooltip = hasValueEllipsis || hasTypeEllipsis;

  return (
    <Styled.TagTextContainer
      appearance={appearance}
      colour={getColour(state)}
      isContentClickable={isContentClickable}
    >
      {tagKey && (
        <Styled.TagKey ref={refType} appearance={appearance}>
          {shouldDisplayTooltip ? (
            <Tooltip
              content={tooltip || tagKey ? `${tagKey}: ${value}` : value}
              color={colors.space}
            >
              <p ref={refType}>{`${tagKey}: `}</p>
            </Tooltip>
          ) : (
            <p ref={refType}>{`${tagKey}: `}</p>
          )}
        </Styled.TagKey>
      )}
      <Styled.Text appearance={appearance}>
        {shouldDisplayTooltip ? (
          <Tooltip content={tooltip || tagKey ? `${tagKey}: ${value}` : value} color={colors.space}>
            <p ref={refValue}>{value}</p>
          </Tooltip>
        ) : (
          <p ref={refValue}>{value}</p>
        )}
      </Styled.Text>
    </Styled.TagTextContainer>
  );
};

export const TagView: ITag = ({
  onRemove,
  isRemoving,
  state = TagState.Info,
  appearance = TagAppearance.Tag,
  children,
  removePopupTitle,
  removePopupText,
  ...rest
}) => {
  const isRemovable = Boolean(onRemove);

  return (
    <Styled.Wrapper appearance={appearance}>
      <Styled.WhiteBackground appearance={appearance}>
        <Styled.Content colour={getColour(state)} appearance={appearance} isRemovable={isRemovable}>
          {children || <TagText {...rest} appearance={appearance} />}
          {isRemovable && (
            <RemoveTagButton
              isRemoving={isRemoving}
              onRemove={onRemove}
              title={removePopupTitle}
              contentText={removePopupText}
            />
          )}
        </Styled.Content>
      </Styled.WhiteBackground>
    </Styled.Wrapper>
  );
};

TagView.CustomContent = TagText;
