import styled, { css } from 'styled-components';
import ComponentCard from '../../../components/ComponentCard';
import { Empty } from '../../../components/Empty';

type ComponentContainerProps = {
  isDependencySelected?: boolean;
  isDependantSelected?: boolean;
  isSelected?: boolean;
};

type BlockProps = {
  isGroup?: boolean;
  isEmpty?: boolean;
};

export const sharedFlexStyle = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Block = styled.div<BlockProps>`
  ${sharedFlexStyle};
  min-height: 15rem;
  max-width: ${props => (props.isEmpty ? '25rem' : 'auto')};
  width: 100%;
  gap: 1rem;
  justify-content: ${props => (props.isGroup ? 'flex-start' : 'center')};
`;

export const ComponentContainer = styled(ComponentCard.Container)<ComponentContainerProps>`
  width: 100%;
  justify-content: center;
  align-content: center;
  gap: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  transition: margin-top 0s, margin-bottom 0s;
  opacity: 1;

  ${props =>
    props.isDependencySelected &&
    css`
      margin-top: -10rem;
      margin-bottom: 10rem;
      transition: margin-top 0.6s, margin-bottom 0.6s;
    `};

  ${props =>
    props.isDependantSelected &&
    css`
      margin-top: 10rem;
      margin-bottom: -10rem;
      transition: margin-top 0.6s, margin-bottom 0.6s;
    `};
`;

export const EmptyText = styled(Empty)<ComponentContainerProps>`
  transition: opacity 0s;
  opacity: 1;
  ${props =>
    (props.isDependencySelected || props.isDependantSelected) &&
    css`
      opacity: 0;
      transition: opacity 0.5s;
    `};
`;

export const ComponentCardWrapper = styled(ComponentCard.Wrapper)<ComponentContainerProps>`
  transition: opacity 0s;
  ${props =>
    (props.isDependencySelected || props.isDependantSelected) &&
    css`
      opacity: 0;
      transition: opacity 0.5s;
    `};

  ${props =>
    props.isSelected &&
    css`
      opacity: 1;
    `};
`;

export const LoaderWrapper = styled.div`
  ${sharedFlexStyle};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  h4 {
    text-align: center;
  }
`;
