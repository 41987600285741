import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateComponentLink,
  deleteComponentLink,
  isUpdatingComponentLink,
  isDeletingComponentLink,
  updatedComponentLinkSuccessfully,
  deletedComponentLinkSuccessfully,
} from '../../store';
import { UpdateLinkFormData, Link } from '../../definitions';
import { EditLinkView } from './EditLink.view';

type EditLinkContainerProps = {
  onClose: () => void;
  isOpen: boolean;
  componentId: string;
  link?: Link;
};

export const EditLinkContainer: FunctionComponent<EditLinkContainerProps> = ({
  isOpen,
  onClose,
  componentId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isDeleting = useSelector(isDeletingComponentLink);
  const isUpdating = useSelector(isUpdatingComponentLink);

  const finishedDeleting = useSelector(deletedComponentLinkSuccessfully);
  const finishedUpdating = useSelector(updatedComponentLinkSuccessfully);

  const handleDeleteLink = useCallback(
    (linkId: string) => {
      dispatch(deleteComponentLink({ componentId, linkId }));
    },
    [dispatch, componentId],
  );

  const handleUpdateLink = useCallback(
    (formData: UpdateLinkFormData) => {
      dispatch(updateComponentLink({ componentId, formData }));
    },
    [dispatch, componentId],
  );

  useEffect(() => {
    if (finishedDeleting || finishedUpdating) {
      onClose();
    }
    // don't put onClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedUpdating, finishedDeleting]);

  return (
    <EditLinkView
      isOpen={isOpen}
      onClose={onClose}
      isDeleting={isDeleting}
      isUpdating={isUpdating}
      onDeleteLink={handleDeleteLink}
      onUpdateLink={handleUpdateLink}
      {...rest}
    />
  );
};
